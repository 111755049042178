import React from 'react'
import './Input.css'

export default function Select({ children, label = '', value = '', onChange = null, style = {}, required = false, list = null, customParameters = [], width = '', type = 'text', disabled = false }) {
    return (
        <div
            style={width ? { ...style, width } : style}
            className={`group`}>
            <select
                className={"input valid"}
                value={value ? value : ''}
                onChange={e => onChange ? onChange(...customParameters, e.target.value) : null}
                list={list}
                type={type}
                required={required}
                disabled={disabled}
            >
                {children}
            </select>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{label}{required && <span className='required-star'>*</span>}</label>
        </div>
    )
}
