import env from './env'

export const appConstante = {
  servers: {
    //prod
    PANORAMA_ENDPOINT: env.production.PANORAMA_ENDPOINT,
    ANALYSE_ENDPOINT: env.production.ANALYSE_ENDPOINT,
    FA_CBDP_ENDPOINT: env.production.FA_CBDP_ENDPOINT,
    MSL_ENDPOINT: env.production.MSL_ENDPOINT,
    LLFC_ENDPOINT: env.production.LLFC_ENDPOINT,

    // dev
    // PANORAMA_ENDPOINT: env.dev.PANORAMA_ENDPOINT,
    // ANALYSE_ENDPOINT: env.dev.ANALYSE_ENDPOINT,
    // FA_CBDP_ENDPOINT: env.dev.FA_CBDP_ENDPOINT,
    // MSL_ENDPOINT: env.dev.MSL_ENDPOINT,
    // LLFC_ENDPOINT: env.dev.LLFC_ENDPOINT,
  },

  access: {
    FA_CBDP_ACCESS: "5632Il284fdsf",
    ANALYSE_ACCESS: "5632Il284fdsf",
    MSL_KEY: "jklgfd545gf44GQ45DSFQG66RYESD6ZEgg66erh",
    LLFC_KEY: "jklgfd545gf44GQ45DSFQG66RYESD6ZEgg66erh",
  },

  urls: {
    ANALYSE_ACCOUNT: "https://analyse-llfc.fr/mon-compte/",
    MSL_URL_ACCOUNT: "https://marqueblanchecbd.fr/my-space-llfc-pro-account",
  },

  pipedrive: {
    //ORGA
    CA_TOTAL: "ff6be12d2a3b6494cef56fe5411730c2faee3d77",
    CA_2023: "7b32b3f3811d30ee61598a6182b8f59416539564",
    CA_2022: "563c5dc2285d8d292a93f6ad29a309532677c973",
    CA_2021: "55773308ebd372b875a73a7ee099f1f3ddf3b40b",
    CA_ANALYSE: "a1a5a3fef206caf03125651f3974d5d0fa018949",
    LAST_ORDER_DATE: "631b6d39148515f37357f2fa3004b593dd9da531",
    NB_TOTAL_ANALYSES: "8792d106147d3659d03f1c49c5d8288c4f884a39",
    ORG_DELIVERY_ADRESS: "710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8",

    //DEAL
    MEAN_PRICE_ANALYSES: "0a8fa0c7f4d7dc470fb92d25a096f5040f61fab3",
    DEAL_DELIVERY_ADRESS: "882c1972b932518a53a2ba497117a98b7efc98d6",
    CATEGORIE: "1d13aed4ebadbe6531afa3850f75234d4b9223a0",
    CHECKOUT_URL: "083c6b85db7a7b60f271271d3329eb3a7a3997a1",
    INVOICE_NUMBER: "cc3bddb9b08ee9ccfeded5485cf582f311558f1c",
    DEALPOLE: "849d3dab1c177a19ac717e862848408f0dfb541c",
    PAYMENT_TERMS: "09fb39f27867cda67db347ae5cfdac0b3a808afe",
    EXPIRY_DATE: "59dbcea855be12fd2db371592cf71189c9f6372b",
    COMMENT: "adf931444dfdca63b729630f904142ca4acf5093",
    CHARGING_POLE: "849d3dab1c177a19ac717e862848408f0dfb541c",

    organizations: {
      CFC: 458,
      CMCMRS: 226,
    }
  },

  pennylane: {
    IDENTIFIANT_PENNYALNE: "a68ca62f0aee305694a2848754aab8463d370764",
  },

  parameters: {
    CAN_EDIT: 2,
    CAN_READ: 1,
    LOGO_URL: "https://labofrancaisduchanvre.com/wp-content/uploads/2023/06/Groupe-74132.png",
  },
}

if (window.location.href.includes('app.labofrancaisduchanvre.com')) {
  appConstante.servers = {
    ...appConstante.servers,
    PANORAMA_ENDPOINT: env.production.PANORAMA_ENDPOINT,
    ANALYSE_ENDPOINT: env.production.ANALYSE_ENDPOINT,
    FA_CBDP_ENDPOINT: env.production.FA_CBDP_ENDPOINT,
    MSL_ENDPOINT: env.production.MSL_ENDPOINT,
    LLFC_ENDPOINT: env.production.LLFC_ENDPOINT,
  }
}



export const formatedState = {
  plan: "Planifié",
  ignored: "Ignoré",
  sended: "Expédié",
  in_stock: "En stock, En attente d'expédition",
  to_plan: "A planifier",
  to_send: "En attente d'expédition",
  complete: "Terminé",
}

export const productDefaultQuantity =
  [
    "1", "2", "3", "5", "7", "10", "25", "50", "75",
    "100", "150", "200", "250", "300", "400", "500", "600", "750",
    "1000", "1250", "1500", "1750", "2000", "2500", "3000", "4000",
    "5000", "6000", "7000", "8000", "9000", "10000", "11000", "15000", "20000"
  ]

// before API endpoint
export const categories = [
  {
    id: 1,
    name: "Matière première",
    path: "matieres-premieres",
    fields: [
      {
        slug: "type_product",
        name: "Type de produit",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "certification",
        name: "Certification",
        type: "text",
        required: false,
        filter: false,
      },
      {
        slug: "unit_of_measure",
        name: "Unité de mesure",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "density",
        name: "Densité",
        type: "number",
        required: false,
        filter: false,
      },
      {
        slug: "description",
        name: "Détails",
        type: "text",
        required: false,
        filter: false,
      },
      {
        slug: "cbd_concentration",
        name: "Concentration en actif visée (%)",
        type: "number",
        required: false,
        filter: false,
      },
    ],
  },
  {
    id: 2,
    name: "Packaging primaire",
    path: "packagings-primaires",
    fields: [
      {
        slug: "type_packaging",
        name: "Type de packaging",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "color",
        name: "Couleur",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "volume",
        name: "Volume",
        type: "number",
        required: true,
        filter: true,
      },
      {
        slug: "unit_of_measure",
        name: "Unité de mesure",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "matter",
        name: "Matière",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "ring",
        name: "Bague",
        type: "text",
        required: false,
        filter: false,
      },
      {
        slug: "description",
        name: "Détails",
        type: "text",
        required: false,
        filter: false,
      },
    ],
  },
  {
    id: 3,
    name: "Packaging secondaire",
    path: "packagings-secondaires",
    fields: [
      {
        slug: "type_packaging",
        name: "Type de packaging",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "type_product",
        name: "Type de produit",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "cbd_concentration",
        name: "Concentration en CBD (%)",
        type: "number",
        required: false,
        filter: false,
      },
      {
        slug: "cbd_quantity",
        name: "Quantité de CBD",
        type: "text",
        required: false,
        filter: false,
      },
      {
        slug: "taste",
        name: "Gout / référence",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "brand_id",
        name: "Marque",
        type: "number",
        required: true,
        filter: true,
      },
      {
        slug: "volume",
        name: "Volume",
        type: "number",
        required: true,
        filter: false,
      },
      {
        slug: "unit_of_measure",
        name: "Unité de mesure",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "description",
        name: "Détails",
        type: "text",
        required: false,
        filter: false,
      },
    ],
  },
  {
    id: 4,
    name: "Produit fini",
    path: "produits-finis",
    fields: [
      {
        slug: "type_product",
        name: "Type de produit",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "volume",
        name: "Volume/poids/unités",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "density",
        name: "Densité",
        type: "number",
        required: false,
        filter: false,
      },
      {
        slug: "unit_of_measure",
        name: "Unité de mesure",
        type: "text",
        required: true,
        filter: false,
      },
      {
        slug: "cbd_concentration",
        name: "Concentration en Cannabinoïde (%)",
        type: "number",
        required: false,
        filter: false,
      },
      {
        slug: "cbd_quantity",
        name: "Quantité de cannabinoïde (mg)",
        type: "text",
        required: false,
        filter: false,
      },
      {
        slug: "taste",
        name: "Gout / référence",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "brand_id",
        name: "Marque",
        type: "number",
        required: true,
        filter: true,
      },
      {
        slug: "description",
        name: "Détails",
        type: "text",
        required: false,
        filter: false,
      },
    ],
  },
  {
    id: 5,
    name: "Consommables",
    path: "consommables",
    fields: [
      {
        slug: "type_product",
        name: "Type de produit",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "taste",
        name: "Type de consommable (Expéditions, logistiques...)",
        type: "text",
        required: true,
        filter: true,
      },
      {
        slug: "description",
        name: "Détails",
        type: "text",
        required: false,
        filter: false,
      },
    ],
  },
]


/**
 * 
 * @param {string} startUnitOfMeasure kg | g | L | mL
 * @param {string} endUnitOfMeasure kg | g | L | mL
 * @returns {number} retourne le facteur de conversion. Attention, la densité n'est pas prise en compte !
 */
export const getConversionUnitFactor = (startUnitOfMeasure, endUnitOfMeasure) => {
  try {
    return conversion[startUnitOfMeasure.toLowerCase()][endUnitOfMeasure.toLowerCase()]
  } catch (e) {
    // console.error(
    //   "parameter startUnitOfMeasure or endUnitOfMeasure is invalid : \n",
    //   "startUnitOfMeasure :", startUnitOfMeasure, "\n",
    //   "endUnitOfMeasure : ", endUnitOfMeasure
    // )
    return 1
  }
}

const conversion = {
  'kg':
  {
    'ml': 1000,
    'l': 1,
    'g': 1000,
    'kg': 1,
  },
  'g':
  {
    'ml': 1,
    'l': 0.001,
    'g': 1,
    'kg': 0.001,
  },
  'l':
  {
    'ml': 1000,
    'l': 1,
    'g': 1000,
    'kg': 1,
  },
  'ml':
  {
    'ml': 1,
    'l': 0.001,
    'g': 1,
    'kg': 0.001,
  }
}

export const priceParameters = {
  mininute_par_litre_tisane: 1.2,
  min_to_hour: 60
  ,
  cout_par_poste: [
    {
      name: 'Hervé',
      price: 23,
    }, {
      name: "Alain",
      price: 14.2
    },
    {
      name: "Romain",
      price: 12
    }
  ],

  product_type: [
    {
      label: "Huile (sublinguale, massage, ...)",
      value: 'huile',

      l_bulk_max: 25,
      minutes_per_l: 3.5,
      price_per_bulk: 18,

      options: [
        {
          label: "production",
          tps_pour_1000: 7.5,
          temps_start: 40,
          unit_parameters: {
            unit_of_measure: 'mL',
            quantity: 1,
            qty_resset: 25000, //mL
          },
          coup_start: 5,
          options: [],
        },
        {
          label: "Conditionnement",
          tps_pour_1000: 180,
          temps_start: 5,
          coup_start: 5,
          options: [],
        },
        {
          label: "Etiquetage",
          tps_pour_1000: 318.32,
          temps_start: 25,
          coup_start: 5,
          options: [],
        },
        {
          label: "Mise en boîte",
          tps_pour_1000: 266.67,
          temps_start: 10,
          coup_start: 5,
          options: [
            {
              label: "Boite standard",
              unit_price: 0,
            },
            {
              label: "Boite volumineuse",
              unit_price: 0.04,
            }
          ]
        },
        {
          label: "Colisage",
          tps_pour_1000: 84,
          temps_start: 0,
          coup_start: 9,
          options: [],
        },
      ],
    },
    {
      label: "Tisane",
      value: "tisane",

      options: [
        {
          label: "Infusion du CBD",
          tps_pour_1000: 48,
          temps_start: 25,
          coup_start: 5,
          options: [],
        },
        {
          label: "Mélange des plantes",
          tps_pour_1000: 36,
          temps_start: 15,
          coup_start: 5,
          options: [],
        },
        {
          label: "Mise en infusettes",
          tps_pour_1000: 0,
          temps_start: 0,
          coup_start: 0.105,
          external: true,
          unit_parameters: {
            unit_of_measure: null,
            quantity: 1,
            qty_resset: 1, //C'est 0.105 euros par infusette
            qty_per_unit: 1
          },
          options: [],
        },
        {
          label: "Conditionnement",
          tps_pour_1000: 1194.21,
          temps_start: 15,
          coup_start: 5,
          options: [],
        },

        {
          label: "Colisage",
          tps_pour_1000: 84,
          temps_start: 0,
          coup_start: 9,
          options: [],
        },
      ],
    },
    {
      label: "Bonbons",
      value: "bonbons",

      options: [
        {
          label: "Broyage du CBD",
          tps_pour_1000: 8.5, //En considérant un bonbon à 10mg de CBD
          temps_start: 0,
          coup_start: 10,
          options: [],
        },
        {
          label: "Expédition du CBD",
          tps_pour_1000: 0,
          temps_start: 20,
          coup_start: 10, //On ne vas pas commencer à prendre en compte le poids du CBD
          options: [],
        },
        {
          label: "Conditionnement",
          tps_pour_1000: 782.81,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
        {
          label: "Etiquetage",
          tps_pour_1000: 388.66,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
        {
          label: "Colisage",
          tps_pour_1000: 98.24,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
      ],
    },
    {
      label: "Gélules",
      value: "gelule",

      options: [
        {
          label: "Expédition MP",
          tps_pour_1000: 5,
          temps_start: 5,
          unit_parameters: {
            unit_of_measure: 'g',
            quantity: 1,
            qty_resset: 10000, //change de catégorie de prix pour 10 kg
          },
          coup_start: 7,
          options: [],
        },
        {
          label: "Mise en gélule",
          tps_pour_1000: 0,
          temps_start: 0,
          coup_start: 18.40,
          external: true,
          unit_parameters: {
            unit_of_measure: null,
            quantity: 1,
            qty_resset: 1000, //C'est 18.40 euros le 1000 chez ADN
            qty_per_unit: 60
          },
          options: [],
        },
        {
          label: "Conditionnement",
          tps_pour_1000: 782.81,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
        {
          label: "Etiquetage",
          tps_pour_1000: 388.66,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
        {
          label: "Colisage",
          tps_pour_1000: 98.24,
          temps_start: 0,
          coup_start: 5,
          options: [],
        },
      ],
    }

  ],
}

export const isTypeHuile = (type) => {
  return type === "Huile sublinguale FULL"
    || type === "Huile sublinguale isolat"
    || type === "Huile sublinguale"
    || type === "HS Chien"
    || type === "HS Chat"
    || type === "HS Petits animaux"
    || type === "Huile de massage"
}

export const getStockUnitOfMeasure = (article) => {
  return article
    ? (article.category === 'Matière première' || !article.category)
      ? article.unit_of_measure
      : "unité(s)"
    : ''
}

export const productFields = [
  { slug: "supplier", name: "Fournisseur", type: "text", required: true },
  {
    slug: "restocking_time",
    name: "Temps de réassort (semaine)",
    type: "number",
    required: false,
  },
  {
    slug: "article_reference",
    name: "Référence article",
    type: "text",
    required: false,
  }
]

export const formatDealProductStatus = (status) => {
  const translateState = {
    plan: "Planifié",
    ignored: "Ignoré",
    in_stock: "En stock, En attente d'expédition",
    to_plan: "A planifier",
    to_send: "En attente d'expédition",
    sended: "Expédié",
  }
  return translateState[status] ? translateState[status] : null
}
