import React, { useContext, useState } from 'react'
import ArticleFields from './Shared/ArticleFields';
import { appConstante } from 'appConstante';
import { formatFormDataFromObject, useCurrentAppPermission } from 'Functions';
import { NotificationContext } from 'Context/NotificationContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import { PopupContext } from 'Context/PopupContext';
import { useCallApi } from 'Functions';

export default function ArticleUpdate({ article, setter, column = false }) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)
    const currentAppPermission = useCurrentAppPermission()
    const isDisabled = currentAppPermission('erp') <= appConstante.parameters.CAN_EDIT

    const callApi = useCallApi()
    const [newArticle, setNewArticle] = useState({ ...article })
    const sendNewArticle = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            title: "Mise à jour de l'article...",
            content: <MiniLoader white={true} />,
            infinit: true
        })
        const formatedArticle = formatFormDataFromObject(newArticle)

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article/${newArticle.id}`, { method: 'put' }, null, formatedArticle)
            .then(res => {
                setter(res.data.data)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: "Article mis à jour",
                    infinit: false
                })
            })
            .catch(error => {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: error.response.data.message,
                    infinit: false
                })
            })
        e.preventDefault();
    }

    return (
        <div className={column ? "flex column" : ''}>
            <form onSubmit={(e) => sendNewArticle(e)}>
                <ArticleFields article={newArticle} setter={setNewArticle} column={column} />
                {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                    ? <button className='m-t-10 strong-button' disabled={isDisabled}>Mettre à jour</button>
                    : null}
            </form>
        </div>
    )
}

