import React from 'react'

export default function NoSubscription({ userInfos }) {
    return (
        <>
            <h4>Nombre d'analyse restantes</h4>
            <table className='beautiful-array m-b-25'>
                <thead>
                    <tr>
                        <th>Type d'analyses</th>
                        <th>Analyse(s) restante(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(userInfos.credits_analyses).map(([key, value], index) => (
                        value || value === 0
                            ? <tr key={index}>
                                <td>{key.replace(/<br\/>/g, ' / ')}</td>
                                <td>{value}</td>
                            </tr>
                            : null
                    ))}
                </tbody>
            </table>
        </>
    )
}
