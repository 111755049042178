import React, { useState } from 'react'

export default function AttributeOption({ option }) {

  const [selectedOptionDescripton, setSelectedOptionDescripton] = useState()

  return (
    <div style={{ borderTop: "1px solid rgba(0,0,0,0.4)", borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
      <p className='left'>{option.description}</p>
      <div className='flex input'>
        <label>{option.name}</label>
        <select onChange={e => { setSelectedOptionDescripton(e.target.value) }}>
          <option>Choisissez votre {option.name}</option>
          {option.values.map((opt, index) => (
            <option key={index} value={opt.description}>{opt.value}</option>
          ))}
        </select>
      </div>
      {selectedOptionDescripton && (
        <p className='right'>
          {selectedOptionDescripton.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      )}    </div>
  )
}
