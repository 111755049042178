import React, { useState } from 'react'
import { useCurrentAppPermission } from 'Functions'
import { appConstante } from 'appConstante'
import AccordionTable from 'Components/AccordionTable/AccordionTable'

export default function OrdersTable({ ordersArray, updateOrders, sendNumRefMails, sendOrderMails }) {

    const currentAppPermission = useCurrentAppPermission();

    const [numRefArray, setNumRefArray] = useState([])

    const totalSelectedResults = numRefArray && numRefArray.length
    const totalResults = ordersArray?.reduce((total, currentOrder) => total + currentOrder?.products.length, 0)

    // UPDATE NUMREF ARRAY //
    const updateNumRefArray = (numRef) => {
        if (numRefArray.includes(numRef)) {
            setNumRefArray(numRefArray.filter(num => num !== numRef))
        } else {
            setNumRefArray([...numRefArray, numRef])
        }
    }

    return (
        <>
            <AccordionTable
                columns={[
                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                        ? { title: 'Envoyer' }
                        : null,
                    { title: "ID commande" },
                    { title: "Mail", colSpan: 2 },
                    { title: "Date" },
                    { title: "Résultats à envoyer" },
                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                        ? { title: "Archiver" }
                        : false,
                ]}
                datas={ordersArray
                    .map(order => {
                        return {
                            mainLine: [
                                currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                                    ? {
                                        element: (
                                            <div>
                                                <button
                                                    className={`light-button ${order.order.archive && 'disabled'}`}
                                                    onClick={() => sendOrderMails(order.order.ID)}
                                                    disabled={order.order.archive}
                                                >Envoyer
                                                </button>
                                            </div>
                                        )
                                    }
                                    : null,
                                { element: (<div>{order.order.ID}</div>) },
                                { element: (<div>{order.customer.mail}</div>), colSpan: 2 },
                                { element: (<div>{new Date(order.order.date).toLocaleDateString()}</div>) },
                                { element: (<div>{order.products.length}</div>) },
                                currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                                    ? {
                                        element: (
                                            <div>
                                                <input className="erp-check" type="checkbox" checked={order.order.archive} onChange={e => updateOrders(order.order.ID, e.target.checked)} />
                                            </div>
                                        )
                                    }
                                    : null,
                            ],
                            subLines: [
                                [
                                    { element: (<div></div>) },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                                        ? { element: (<div className='strong-2'>À envoyer</div>) }
                                        : null,
                                    { element: (<div className='strong-2'>Réference interne</div>) },
                                    { element: (<div className='strong-2'>Analyse effectuée</div>), colSpan: 2 },
                                    { element: (<div className='strong-2'>Date de réception du produit</div>) },
                                    { element: (<div className='strong-2'>Type de produit</div>) },
                                ],
                                ...order.products.map(product => {
                                    const productOrderId = order.order.products.find(prod => prod.product_order_id == product.product_order_id)
                                    const analyseType = productOrderId
                                        ? Object.entries(productOrderId.meta).map(([key, value], index) => <li key={index}>{key} : {value}</li>)
                                        : "Erreur de récupération du type d'analyse"
                                    return ([
                                        { element: (<></>) },
                                        currentAppPermission('analyses') > appConstante.parameters.CAN_READ
                                            ? {
                                                element: (<><input
                                                    className={`erp-check ${order.order.archive && 'disabled'}`}
                                                    type='checkbox'
                                                    checked={numRefArray.includes(product.num_reference)}
                                                    onChange={() => updateNumRefArray(product.num_reference)}
                                                    disabled={order.order.archive}>
                                                </input></>)
                                            }
                                            : null,
                                        { element: (<>{product.num_reference}</>) },
                                        { element: (<div style={{ whiteSpace: 'nowrap' }} className='left'>{analyseType}</div>), colSpan: 2 },
                                        { element: (<>{new Date(product.date_reception).toLocaleDateString()}</>) },
                                        { element: (<>{product.type_produit}</>) },
                                    ])
                                })
                            ]
                        }
                    })}
            />
            {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                <button className='light-button m-t-25' onClick={() => sendNumRefMails(numRefArray)}>
                    Envoyer ({totalSelectedResults} / {totalResults} résultats cochés)
                </button>
            }
        </>
    )
}
