import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import { useCallApi } from 'Functions'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import HistoryFields from './HistoryFields'
import { emptyHistory } from 'AppContainer/ERP/ERPConstantes'

export default function UpdateHistory({ history, setter }) {

    const callApi = useCallApi()
    const [newHistory, setNewHistory] = useState({
        ...emptyHistory,
        ...history,
        prvQuantity: history.quantity * -1,
        history_type: history.quantity < 0 ? 'remove' : 'add'
    })

    const { addNotificationContent } = useContext(NotificationContext)


    const sendHistory = e => {
        e.preventDefault()
        const historyToSend = newHistory
        if (newHistory.history_type === 'remove') {
            historyToSend.quantity = newHistory.quantity * -1   //Passage en quantité négative
            historyToSend.batch_id = newHistory.batch_id
        }
        if (newHistory.history_type === 'add') {
            historyToSend.quantity = newHistory.quantity
            historyToSend.price = newHistory.price
            historyToSend.expiration_date = newHistory.expiration_date
            if (!newHistory.batch_id) { //Création d'un nouveau batch
                historyToSend.new_batch = {
                    product_id: newHistory.product_id,
                    batch_number: newHistory.batch_number
                }
            } else {
                historyToSend.batch_id = newHistory.batch_id
            }
        }
        addNotificationContent({
            title: 'Mise à jour en cours',
            content: <MiniLoader />,
        })

        delete historyToSend.article
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/history/${newHistory.id}`, { method: 'put' }, null, historyToSend)
            .then(res => {
                setter && setter(res.data.data)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "L'historique a été mis à jour",
                    infinit: false
                })
            })
            .catch(err => {
                console.log({ err });
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })
            })
    }

    return (
        <>
            <h2>Mise à jour de l'historique</h2>
            {newHistory && (
                <form onSubmit={e => sendHistory(e)}>
                    <HistoryFields
                        history={newHistory}
                        setter={setNewHistory}
                    />
                    <button className='strong-button m-t-25 m-b-25'>Mettre à jour</button>
                </form>
            )}
        </>
    )
}
