import React, { useState, useEffect, useRef, useContext } from 'react'
import { productDefaultQuantity } from 'appConstante'
import { computePrice, useCallMSLApi } from 'Functions'
import './PackagingInProduct.css'
import axios from 'axios'
// import { CartContext } from '../../../Context/CartContext.js'

export default function PackagingInProduct({ classNameProps, product, packaging, selectedOptions, selectedVariation }) {

    // const { addCartContent } = useContext(CartContext)

    const [parameters, setParameters] = useState(false)
    const [priceCategory, setPriceCategory] = useState(false)
    const [productQty, setProductQty] = useState(0)
    const [productPrice, setProductPrice] = useState("...")
    const [totalPrice, setTotalPrice] = useState(0)
    const quantitySelector = useRef()
    const [unValilidPackaging, setUnValilidPackaging] = useState(true)

    const callMSLApi = useCallMSLApi()

    useEffect(() => {
        const controller = new AbortController()
        if (packaging.price_category_id) {
            callMSLApi(`category/${packaging.price_category_id}`, { signal: controller.signal })
                .then(res => {
                    setPriceCategory(res.data.result)
                    setUnValilidPackaging(false)
                })
                .catch(error => setUnValilidPackaging(true))
        }

        return () => {
            controller.abort()
        }
    }, [packaging.price_category_id])

    useEffect(() => {
        setParameters({
            pMin: Number(packaging.min_price),
            pMax: Number(packaging.max_price),
            qMin: Number(packaging.min_qty),
            qMax: Number(packaging.min_price_qty),
        })
        setProductQty(Number(packaging.min_qty))
    }, [])

    useEffect(() => {
        if (parameters && priceCategory) {
            if (productQty < parameters.qMin) {
                setProductQty(parameters.qMin)
            } else {
                setProductPrice(computePrice(productQty, parameters, priceCategory).unitPrice)
                setTotalPrice(computePrice(productQty, parameters, priceCategory).totalPrice)
            }
        }
    }, [parameters, priceCategory, productQty])

    const ScrollQ = (value, el) => {
        let yCord = el.scrollLeft
        el.scroll({
            top: 0,
            left: yCord + value,
            behavior: 'smooth'
        });
    }

    const updateQty = qty => {
        const newQty = parseInt(qty)
        if (parameters) {
            if (newQty > parameters.qMin) {
                setProductQty(newQty)
            } else {
                setProductQty(parameters.qMin)
            }
        }
    }

    const changeQty = qty => {
        updateQty(productQty + qty)
    }

    if (!unValilidPackaging) {
        return (
            <div className={`packaging-quantity-price-section ${classNameProps ? 'green-bg' : ''}`}>
                <h4 className='m-0'>{packaging.type}</h4>
                <div className='packaging-description'>{packaging.description_customer}</div>
                {parameters && (
                    <div className='flex gap-1'>
                        <div className='quantity-price-indicator'>
                            <h4 className='m-0'>Quantité minimum: {parameters.qMin}</h4>
                            <div className='global-qty-price-section'>
                                <div className="qty-price-section current-qty-price">
                                    <div className='flex-center flex-start gap-1'>
                                        <div onClick={() => changeQty(-50)} id="mns-btn" className="input-number-btn mns-btn">-</div>
                                        <input className={`product-qty ${classNameProps ? 'green-bg' : ''}`} type="number" value={productQty} onInput={e => updateQty(e.target.value)} step="50" />
                                        <div onClick={() => changeQty(50)} id="pls-btn" className="input-number-btn pls-btn">+</div>
                                    </div>

                                    <div className="add-to-cart-section">
                                        {/* <button onClick={() => addCartContent(product, productQty, priceCategory, parameters, selectedVariation, selectedOptions, packaging, packaging.description_customer)} className="product-button-thumbnails">
                                            Ajouter à mon devis
                                        </button> */}
                                    </div>
                                </div>

                                <div className="current-unity-price-container">
                                    <div className="flex">
                                        <h4 className='m-0 primary-color'>Unité <span style={{ fontSize: "13px" }}>(HT)</span>:</h4>
                                        <div className='primary-color'>{productPrice}€</div>
                                    </div>

                                    <div className="flex">
                                        <h4 id="total-price-container" className='m-0'>Total <span style={{ fontSize: "13px" }}>(HT)</span>:</h4>
                                        <div className='primary-color'>{totalPrice && totalPrice}€</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="quantity-selector">
                            <h4 className='quantity-selector-description'>Quantité associée au prix unitaire du produit</h4>
                            <div className="flex evenly">
                                <button onClick={() => ScrollQ(-133, quantitySelector.current)} className={`scroll-button ${classNameProps ? 'green-bg' : ''}`}> &#10094; </button>
                                <div className="select-qty-price-container" ref={quantitySelector}>
                                    {productDefaultQuantity.map(qty => {
                                        if (parameters && qty >= parameters.qMin && qty <= parameters.qMax) {
                                            const currentPrice = parameters && priceCategory ? computePrice(qty, parameters, priceCategory).unitPrice : "..."
                                            return (
                                                <div key={qty} className="qty-price-section flex">
                                                    <div onClick={() => updateQty(qty)} className={`qty-button ${classNameProps ? 'white-bg' : ''}`}>{qty}</div>
                                                    <div className="unity-price">{currentPrice}€</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <button onClick={() => ScrollQ(133, quantitySelector.current)} className={`scroll-button ${classNameProps ? 'green-bg' : ''}`}> &#10095; </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
}
