import React, { createContext, useState, useEffect, useContext } from "react"
import { useMsal } from "@azure/msal-react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { appConstante } from "appConstante"
import { NotificationContext } from "./NotificationContext"

/**
 * use getToken to get user token
 */
export const UserInfosContexte = createContext()

function UserInfosContexteProvider(props) {
  const [userInfo, setUserInfo] = useState()
  const [avaibleApps, setAvaibleApps] = useState()
  const { instance, inProgress, accounts } = useMsal()
  const accessTokenRequest = {
    scopes: ["user.read"],
    account: accounts[0],
  }

  const navigate = useNavigate()
  const { addNotificationContent } = useContext(NotificationContext)
  const getToken = () =>
    new Promise((resolve, reject) => {
      try {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((token) => {
            localStorage.setItem("accessToken", JSON.stringify(token));
            resolve(token.accessToken)
          })
          .catch((error) => {
            localStorage.removeItem("accessToken");
            if (error.errorCode === "no_account_error") {
              navigate("/mon-compte")
              addNotificationContent({
                title: "Erreur",
                content: "Votre session à expiré, merci de vous reconnecter",
                error: true,
                infinit: false,
              })
            } else {
              if (error.name !== "CanceledError") {
                navigate("/mon-compte")
                location.reload();
                // addNotificationContent({
                //   title: "Erreur",
                //   content: "Votre session à expiré, merci de vous reconnecter",
                //   error: true,
                //   infinit: false,
                // })
                reject(error)
              }
            }
          })
      } catch (e) {
        reject(e)
      }
    })

  useEffect(() => {
    const controller = new AbortController()
    getToken().then((token) => {
      const APIoptions = {
        method: "get",
        headers: { Authorization: token },
        signal: controller.signal,
        url: `${appConstante.servers.PANORAMA_ENDPOINT}/user/me`,
      }
      axios(APIoptions)
        .then((res) => {
          setUserInfo(res.data.data)
          setAvaibleApps(res.data.data.applications)
        })
        .catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            console.log(error)
            addNotificationContent({
              error: true,
              title: "Erreur",
              content: "Le serveur présente un problème, contactez le pôle IT",
            })
          }
        })
    })
    return () => {
      controller.abort()
    }
  }, [instance, accounts, inProgress])

  return (
    <UserInfosContexte.Provider value={{ getToken, userInfo, avaibleApps }}>
      {props.children}
    </UserInfosContexte.Provider>
  )
}

export default UserInfosContexteProvider
