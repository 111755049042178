import React, { createContext, useState } from "react"

export const LoaderContext = createContext()

function LoaderContextProvider(props) {
  const [showLoader, toggleLoader] = useState(false)

  return (
    <LoaderContext.Provider value={{ showLoader, toggleLoader }}>
      {props.children}
    </LoaderContext.Provider>
  )
}

export default LoaderContextProvider
