import React, { useEffect } from 'react';
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar';
import { useNavigate } from 'react-router-dom';

export default function PipedriveScript() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/erp/parametre-pipedrive/mise-a-jour-pipedrive"); //Sous onglet par défaut
  }, [])
  return (
    <>
      <SubNavBar
        menu={[
          { path: 'erp/parametre-pipedrive/mise-a-jour-pipedrive', name: 'Actualisation du CA par client' }
        ]}
      />
    </>
  );
}