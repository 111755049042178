import React, { createContext, useState, useEffect, useContext } from "react"
import { appConstante } from "appConstante"
import { formatFormDataFromObject, useCallApi } from "Functions"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"

export const ArticleContext = createContext()
export default function ArticleContextProvider(props) {

    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()
    const [articles, setArticles] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        getArticles(controller.signal)

        return () => {
            controller.abort()
        }
    }, [])

    const getArticles = (signal = null) => {
        setArticles(null)
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/articles`, { method: "get" }, signal, { limit: 0 })
            .then(res => setArticles(res.data.data?.map(article => ({ ...article, dataType: "raw" }))))
            .catch(error => console.log(error))
    }

    const getArticle = (id, dataType = 'advanced') => {
        return new Promise((resolve, reject) => {
            const article = articles && articles.find(a => a.id == id)
            if (article && article.dataType === dataType) {
                resolve(article)
            } else {
                callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article/${id}`, { method: "get" }, null, { data: dataType })
                    .then(res => {
                        resolve({ ...res.data.data, dataType: dataType })
                    })
                    .catch(error => {
                        console.log(`erreur récupération /article/${id}`, error)
                        reject(error)
                    })
            }
        })
    }

    const postArticle = (article, e = null) => {
        return new Promise((resolve, reject) => {
            e.preventDefault();
            addNotificationContent({
                error: false,
                title: "Création de l'article...",
                content: <MiniLoader />,
                infinit: true
            })

            const formatedArticle = formatFormDataFromObject(article)

            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article`, { method: 'post' }, null, formatedArticle, e)
                .then(res => {
                    resolve(res.data.data)
                    setArticles(prv => ([...prv, res.data.data]))
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: `Article ${res.data.data.name} créé`,
                        infinit: false
                    })
                })
                .catch(error => {
                    console.log(`erreur de création de l'article`, error)
                    reject(error)
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: `Il y à eu une erreur lors de la création de l\'article (${error.response?.data?.message})`,
                        infinit: false
                    })
                })
        })
    }

    const getBatch = (id) => {
        return new Promise((resolve, reject) => {
            callApi(
                `${appConstante.servers.PANORAMA_ENDPOINT}/erp/batch/${id}`, { method: "get" }, null)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.log(`erreur récupération /batch/${id}`, error)
                    reject(error)
                })
        })
    }

    const postBatch = (batch) => {
        return new Promise((resolve, reject) => {
            callApi(
                `${appConstante.servers.PANORAMA_ENDPOINT}/erp/batch`, { method: "post" }, null, batch)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.log(`erreur de création du batch`, error)
                    reject(error)
                })
        })
    }

    /************************************************************/
    /************************** HELPERS *************************/

    const formatArticleName = (article) => {
        return `${article.name}`
    }


    return (
        <ArticleContext.Provider value={{ articles, getArticles, getArticle, postArticle, postBatch, getBatch, formatArticleName }}>
            {props.children}
        </ArticleContext.Provider>
    )
}
