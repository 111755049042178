import React, { useEffect } from 'react'
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar'
import { useNavigate, useLocation } from 'react-router-dom'

export default function Analyses() {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        location.pathname === "/cbd-protect" && navigate("/cbd-protect/bulks") //Sous onglet par défaut
    }, [location.pathname])

    return (
        <>
            <SubNavBar menu={[
                { path: "/cbd-protect/bulks", name: "Bulks" },
                { path: "/cbd-protect/products", name: "Batchs" },
            ]} />
        </>
    )
}