import React, { useContext } from "react"
import axios from "axios"
import { appConstante } from "appConstante"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { NotificationContext } from "Context/NotificationContext"
import { UserInfosContexte } from "Context/UserInfosContexte"
import { DependenciesContext } from "Context/DependenciesContext"
import { MslAccessContext } from "Context/Commercial/MslAccessContext"
import { openDB } from 'idb';
import { AnalyseAccessContext } from "Context/Analyse/AnalyseAccessContext"

export const formatCommentsProducts = (comments) => {
  if (comments) {
    let formatedComments = comments
      .split("<br>")
      .map((comment) => comment.replace(/(<([^>]+)>)/gi, "").replace('\\', ''))

    if (formatedComments.length === 1) {
      formatedComments = comments
        .split("<br/>")
        .map((comment) => comment.replace(/(<([^>]+)>)/gi, "").replace('\\', ''))
    }
    if (formatedComments.length === 1) {
      formatedComments = comments
        .split("<br />")
        .map((comment) => comment.replace(/(<([^>]+)>)/gi, "").replace('\\', ''))
    }
    if (formatedComments.length === 1) {
      formatedComments = comments
        .split("<div>")
        .map((comment) => comment.replace(/(<([^>]+)>)/gi, "").replace('\\', ''))
    }
    if (formatedComments.length === 1) {
      formatedComments = comments
        .split("\n")
        .map((comment) => comment.replace(/(<([^>]+)>)/gi, "").replace('\\', ''))
    }
    const removeEmptyComments = formatedComments.filter(
      (comment) => comment !== " " && comment !== ""
    )
    return removeEmptyComments
  }
  return []
}

export const deleteEmptyObjectsField = (object) => {
  const result = Object.entries(object).filter(
    ([key, value]) => !isFalsy(value)
  )
  return Object.fromEntries(result)
}

export const isFalsy = (value: any): boolean => {
  if (value === "" || value === null || value === undefined) {
    return true
  }
  return false
}

export const changePage = (
  currentPage,
  totalPage,
  setCurrentPage,
  direction,
  value = false
) => {
  // formatage
  value = parseInt(value)
  totalPage = parseInt(totalPage)
  direction = parseInt(direction)
  if (value) {
    if (value > totalPage) {
      value = totalPage
    }
    setCurrentPage(value)
  } else if (direction === -1 && currentPage > 0) {
    setCurrentPage(currentPage + direction)
  } else if (direction === 1 && currentPage < totalPage) {
    setCurrentPage(currentPage + direction)
  }
}

export const useSendInvoiceToCustomer = () => {
  const { addNotificationContent } = useContext(NotificationContext)
  const callPennylaneApi = useCallPennylaneApi()
  /**
   *
   * @param {Int} invoiceId id de la facture à envoyer
   *
   */
  const sendInvoiceToCustomer = (invoiceId: number) => {
    addNotificationContent({
      title: "Envoie de la facture en cours",
      error: false,
      content: <MiniLoader />,
      infinit: true,
    })

    callPennylaneApi(`customer_invoices/${invoiceId}/send_by_email`, { method: "post" })
      .then((response) => {
        if (response.status === 204) {
          addNotificationContent({
            title: "Succès",
            error: false,
            content: "Envoie de la facture réussi",
            infinit: false,
          })
        } else {
          addNotificationContent({
            title: "Erreur",
            error: true,
            content: response.data.error,
            infinit: false,
          })
        }
      })
      .catch((error) => {
        addNotificationContent({
          title: "Erreur",
          error: true,
          content: error.response.data.error,
          infinit: false,
        })
      })
  }

  return sendInvoiceToCustomer
}

export const formatCustomerFromPennyLane = (customerObj) => {
  const formatedCustomer = {
    customer_type: customerObj.customer_type,
    address: customerObj.billing_address.address,
    postal_code: customerObj.billing_address.postal_code,
    city: customerObj.billing_address.city,
    country_alpha2: customerObj.billing_address.country_alpha2,
    recipient: customerObj.recipient,
    source_id: customerObj.source_id,
    emails: customerObj.emails,
    delivery_address: customerObj.delivery_address.address,
    payment_conditions: customerObj.payment_conditions,
    phone: customerObj.phone,
    vat_number: customerObj.vat_number,
    notes: customerObj.notes,
  }
  if (customerObj.customer_type === "company") {
    formatedCustomer.name = customerObj.name
    return formatedCustomer
  }
  formatedCustomer.first_name = customerObj.first_name
  formatedCustomer.last_name = customerObj.last_name
  return formatedCustomer
}

export const findDuplicates = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) !== index)

export const removeDuplicates = (arr) => [...new Set(arr)]

export const removeDuplicatesObjectInArray = (key, arr) => {
  const uniqArrayKey = []
  return arr.filter(item => {
    if (!uniqArrayKey.includes(item[key])) {
      uniqArrayKey.push(item[key])
      return true
    } else {
      return false
    }
  })
}

export const formatNumber = (value: number): string => {
  if (value || value === 0) {
    const digitOptions: Intl.NumberFormatOptions = {
      style: 'decimal',
      maximumFractionDigits: 2,
      useGrouping: true,
    };
    return value.toLocaleString('fr-FR', digitOptions)
  }
  return ''
}

/**
 * remove a property from an array of objects
 * @param {*} array 
 * @param {*} property 
 * @returns 
 */
export const removePropertyFromObjectsInArray = (array, property) => {
  return array.map((item) => {
    if (property in item) {
      const { [property]: _, ...rest } = item;
      return rest;
    }
    return item;
  });
}

export const addPropertyToObjectsInArray = (array, property, value) => {
  if (Array.isArray(array)) {
    return array.map((item) => {
      return { ...item, [property]: value };
    });
  }
}


export const findObjectInArrayByKey = (arr, key, value) => arr.find(item => item[key] === value)

export const searchValueByKeyInObjectArray = (arr, key, value) => {
  const result = arr.filter(item => item[key] === value)
  return result.length >= 1
}
export const useCallPennylaneApi = () => {
  const { getDependencies } = useContext(DependenciesContext)
  /**
   *
   * @param {String} url pipedrive URL to call
   * @param {Object} options options to pass to axions (exemple:   {method: 'get' })
   * @param {Object} data data to send in the request body
   * @returns
   */
  const callPennylaneApi = (uri, options = { method: "get" }, data = null) =>
    // return
    new Promise((resolve, reject) => {
      getDependencies().then((dependencies) => {
        const APIoptions = {
          ...options,
          headers: {
            accept: "application/json",
            authorization: dependencies.PENYLANE_KEY,
          },
          url: `${dependencies.PENYLANE_ENDPOINT}${uri}`,
        }
        if (options.method === "get" || !options.method) {
          APIoptions.params = data
        } else {
          APIoptions.data = data
        }
        axios(APIoptions)
          .then((res) => {
            resolve(res)
          })
          .catch(async (error) => {
            if (error.code !== "ERR_CANCELED") {
              reject(error)
            }
          })
      })
    })

  return callPennylaneApi
}
export const useCallMSLApi = () => {
  const { mslNonce } = useContext(MslAccessContext)

  /**
   *
   * @param {String} uri MSL uri to call
   * @param {Object} options options to pass to axions (exemple:   {method: 'get' })
   * @param {Object} data data to send in the request body
   * @returns
   */
  const callMSLApi = (uri, options = { method: "get" }, data = null) =>
    new Promise((resolve, reject) => {
      const APIoptions = {
        ...options,
        headers: {
          ...options.headers,
          'X-WP-Nonce': mslNonce
        },
        url: `${appConstante.servers.MSL_ENDPOINT}/${uri}`,
      }
      let formatedData = data
      if (data instanceof FormData) {
        formatedData.append("_wpnonce", mslNonce)
        formatedData.append("api_key", appConstante.access.MSL_KEY)
      } else {
        formatedData = {
          ...formatedData,
          _wpnonce: mslNonce,
          api_key: appConstante.access.MSL_KEY,
        }
      }
      if (options.method === "get" || !options.method) {
        APIoptions.params = formatedData
      } else {
        APIoptions.data = formatedData
      }
      axios(APIoptions)
        .then((res) => {
          resolve(res)
        })
        .catch(async (error) => {
          if (error.code !== "ERR_CANCELED") {
            reject(error)
          }
        })
    })

  return callMSLApi
}

export const useCallAnalysesApi = () => {
  const { getToken } = useContext(UserInfosContexte)

  /**
   *
   * @param {String} uri MSL uri to call
   * @param {Object} options options to pass to axions (exemple:   {method: 'get' })
   * @param {Object} data data to send in the request body
   * @returns
   */
  const callAnalysesApi = (uri: string, options: Record<string, any> | null = { method: "get" }, data: Record<string, any> | null = null, e: React.FormEvent | null = null) => {
    if ((e && e.target instanceof Element && !e.target.classList.contains("submitting")) || !e) {
      e && e.target instanceof Element && e.target.classList.add("submitting")
      return new Promise(async (resolve, reject) => {
        getToken()
          .then(token => {
            const APIoptions = {
              ...options,
              headers: {
                ...options.headers,
              },
              url: `${appConstante.servers.ANALYSE_ENDPOINT}/${uri}`,
            }
            let formatedData = data
            if (data instanceof FormData) {
              formatedData.append("token", token)
            } else {
              formatedData = {
                ...formatedData,
                token: token
              }
            }
            if (options.method === "get" || !options.method) {
              APIoptions.params = formatedData
            } else {
              APIoptions.data = formatedData
            }
            axios(APIoptions)
              .then((res) => {
                resolve(res)
              })
              .catch(async (error) => {
                if (error.code !== "ERR_CANCELED") {
                  reject(error)
                }
              })
              .finally(_ => {
                e && e.target.classList.remove("submitting")
              })
          })
      })
    } else {
      return null
    }
  }
  return callAnalysesApi
}
export const useCallCBDPApi = () => {

  /**
   *
   * @param {String} uri MSL uri to call
   * @param {Object} options options to pass to axions (exemple:   {method: 'get' })
   * @param {Object} data data to send in the request body
   * @returns
   */
  const callCBDPApi = (uri, options = { method: "get" }, data = null) =>
    new Promise((resolve, reject) => {
      const APIoptions = {
        ...options,
        url: `${appConstante.servers.FA_CBDP_ENDPOINT}/${uri}`,
      }
      let formatedData = data
      if (data instanceof FormData) {
        formatedData.append("access", appConstante.access.FA_CBDP_ACCESS)
      } else {
        formatedData = {
          ...formatedData,
          access: appConstante.access.FA_CBDP_ACCESS,
        }
      }
      if (options.method === "get" || !options.method) {
        APIoptions.params = formatedData
      } else {
        APIoptions.data = formatedData
      }
      axios(APIoptions)
        .then((res) => {
          resolve(res)
        })
        .catch(async (error) => {
          if (error.code !== "ERR_CANCELED") {
            reject(error)
          }
        })
    })
  return callCBDPApi
}

export const useCallPipedriveApi = () => {
  const { getDependencies } = useContext(DependenciesContext)
  /**
   *
   * @param {String} url pipedrive URL to call
   * @param {Object} options options to pass to axions (exemple:   {method: 'get' })
   * @param {Object} data data to send for the request
   * @returns
   */
  const callPipedriveApi = (uri, options = { method: "get" }, data = null, cache = false) =>
    // return
    new Promise(async (resolve, reject) => {
      const dependencies = await getDependencies()
      if (!dependencies) {
        console.log("wrong", dependencies);
        setTimeout(() => callPipedriveApi(uri, options, data), 250)
      } else {
        let cacheValue = false;
        if (cache) {
          cacheValue = await getInCache(`${uri}-${JSON.stringify(options)}-${JSON.stringify(data)}`)
        }
        if (cacheValue) {
          resolve(
            getInCache(`${uri}-${JSON.stringify(options)}-${JSON.stringify(data)}`)
          )
        } else {
          const APIoptions = {
            ...options,
            url: `${dependencies.PIPEDRIVE_ENDPOINT}${uri}?api_token=${dependencies.PIPEDRIVE_KEY}`,
          }
          if (options.method === "get" || !options.method) {
            APIoptions.params = data
          } else {
            APIoptions.data = data
          }
          axios(APIoptions)
            .then((res) => {
              setInCache(`${uri}-${JSON.stringify(options)}-${JSON.stringify(data)}`, res);
              resolve(res)
            })
            .catch(async (error) => {
              if (error.code !== "ERR_CANCELED") {
                if (error.response && error.response.status === 429) {
                  setTimeout(() => {
                    callPipedriveApi(uri, options, data)
                  }, 750)
                } else {
                  reject(error)
                }
              }
            })
        }
      }
    })

  return callPipedriveApi
}

async function openMyDb() {
  return openDB('panorama', 1, {
    upgrade(db) {
      db.createObjectStore('store');
    },
  });
}


async function setInCache(key, value) {
  const expirationMS = 24 * 60 * 60 * 1000; // One day in milliseconds
  const record = {
    value: value,
    expiration: new Date().getTime() + expirationMS, // Calculate expiration timestamp
  };
  const db = await openMyDb()
  db.put("store", JSON.stringify(record), key)
    .then(result => {
      return true
    })
    .catch(err => {
      console.log("Error setting data in IndexedDB", err);
      return false
    })

}

// Function to get data from IndexedDB
async function getInCache(key) {
  try {
    const db = await openMyDb()

    const preRecord = await db.get('store', key);
    if (!preRecord) {
      return null
    }
    const record = JSON.parse(preRecord)

    if (!record) {
      return null; // Item not found in IndexedDB
    }

    const currentTime = new Date().getTime();
    if (currentTime < record.expiration) {
      return record.value; // Data is still valid
    } else {
      await db.delete('store', key); // Data has expired, remove from IndexedDB
      return null;
    }
  } catch (error) {
    console.error('Error getting data from IndexedDB:', error);
    return null;
  }
}



export const useCallApi = () => {
  const { addNotificationContent } = useContext(NotificationContext)
  const { getToken } = useContext(UserInfosContexte)
  /**
   *
   * @param {string} uri (obligatoire) URL du endpoint pour la requête
   * @param {Object} options  (obligatoire) object contenant les options de la requête - {method: "get"} / {method: "post"}....
   * @param {AbortController} abortSignal (recommandé) AbortController permettant d'annuler la requête - controler.signal
   * @param {Object} data (optionnel) données à envoyer dans le corps de la requête
   */
  const callApi = async (uri: string, options: object = { method: 'get' }, abortSignal = null, data: object = {}, e = null) => {
    if ((e && !e.target.classList.contains("submitting")) || !e) {
      e && e.target.classList.add("submitting")
      return new Promise((resolve, reject) => {
        getToken().then((token) => {
          const APIoptions = {
            ...options,
            headers: { ...options.headers, Authorization: token },
            signal: abortSignal,
            url: uri,
          }
          if (!options || options.method === "get" || options.method === "GET") {
            APIoptions.params = data
          } else {
            APIoptions.data = data
          }

          axios(APIoptions)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              if (error.name !== "CanceledError") {
                if (error.response && error.response.status === 401) {
                  addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content:
                      "Vous n'ête pas authorisé à accéder à cette ressource",
                    infinit: false,
                  })
                  reject(error)
                } else {
                  reject(error)
                }
              }
            })
            .finally(_ => {
              e && e.target.classList.remove("submitting")
            })
        })
      })
    } else {
      return null
    }
  }
  return callApi
}

export const useAddErpDataToPipedriveDeal = () => {
  const callApi = useCallApi()

  const postErpDeal = (deal) => {
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal`,
      { method: "post" },
      null,
      deal
    )
  }

  const addErpDataToPipedriveDeal = (pipedriveDeal) =>
    new Promise((resolve, reject) => {
      const urlDeals = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${pipedriveDeal.id}`
      callApi(urlDeals, { method: "GET" })
        .then((res) => {
          if (res.status === 204) {
            const erpNewDeal = {
              id: pipedriveDeal.id,
              priority: 1,
            }
            postErpDeal(erpNewDeal)
            resolve({ ...pipedriveDeal, erp: erpNewDeal })
          } else {
            resolve({ ...pipedriveDeal, erp: res.data.data })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  return addErpDataToPipedriveDeal
}

export const getCurrentAppName = (locationPath) => {
  return locationPath.split('/')[1]
}

export const useCurrentAppPermission = () => {
  const { avaibleApps } = useContext(UserInfosContexte)

  const currentAppPermission = (slug) => {
    if (avaibleApps) {
      const currentApp = avaibleApps.filter((app) => app.slug === slug)
      return (currentApp && currentApp[0]) ? currentApp[0].role : 0
    }
    return 0
  }

  return currentAppPermission
}

/**
 *
 * @param {Date} date
 * @returns monday as date of the current date week
 */
export const getMonday = (date) => {
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(date.setDate(diff)).toISOString().slice(0, 10)
}

export const getSunday = (date) => {
  const day = date.getDay()
  const diff = date.getDate() + 7 - day // adjust when day is sunday
  return new Date(date.setDate(diff)).toISOString().slice(0, 10)
}

export const getNextSunday = (date) => {
  const nextWeek = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  return getSunday(nextWeek);
}

export const getPreviousSunday = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day - 7; // on soustrait 7 jours pour obtenir la semaine précédente
  return new Date(date.setDate(diff)).toISOString().slice(0, 10);
};

export const formatInputValue = (value) => value || ""

export const formatSession = (session) => ({
  ...session,
  start: new Date(session.start).toISOString(),
  end: new Date(session.end).toISOString(),
})

export const formatDateToInput = (date) => {
  const formatedDate = new Date(date)
  formatedDate.setHours(formatedDate.getHours() - (formatedDate.getTimezoneOffset() / 60))
  return formatedDate.toISOString().slice(0, 10)
}

export const formatDateTimeToInput = (date) => {
  const formatedDate = new Date(date)
  formatedDate.setHours(formatedDate.getHours() - (formatedDate.getTimezoneOffset() / 60))
  return formatedDate.toISOString().slice(0, 19)
}

export const upElement = (arr, i, setter) => {
  if (i > 0) {
    [arr[i], arr[i - 1]] = [arr[i - 1], arr[i]];
  }
  setter([...arr])
}

export const downElement = (arr, i, setter) => {
  if (i + 1 < arr.length) {
    [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]];
  }
  setter([...arr])
}

export const computePrice = (qty, parameters, category, selectedOptions = []) => {
  let f;
  let a;
  let b;
  let unitPrice = 0;
  if (category.function == "Convexe") {
    f = Number(category.adjustment_factor)
    a = ((parameters.pMin - parameters.pMax) * parameters.qMax ** f) * (parameters.qMin ** f / (parameters.qMin ** f - parameters.qMax ** f));
    b = parameters.pMax - a / parameters.qMin ** f;
    unitPrice = (a / ((qty) ** f) + b);
  } else if (category.function == "Affine") {
    a = (parameters.pMin - parameters.pMax) / (parameters.qMax - parameters.qMin);     // Pente de la courbe -> formule pour atteindre qMAX au prix minimum
    b = parameters.pMax - a * parameters.qMin;
    unitPrice = a * qty + b;
  }

  if (unitPrice > parameters.pMax) {
    unitPrice = parameters.pMax;
  }
  if (unitPrice < parameters.pMin) {  //Prix plancher après le prix minimum atteind
    unitPrice = parameters.pMin;
  }
  const basedPrice = unitPrice

  /* AJOUT DU PRIX DES OPTIONS */
  selectedOptions.map(option => {
    if (option.value.price != 0) {
      if (option.value.type === 0) {
        unitPrice = unitPrice + parseFloat(option.value.price)
      } else { //percent
        unitPrice = unitPrice + (basedPrice * Number(option.value.price) / 100).toFixed(2) * 1
      }
    }
  })
  const totalPrice = unitPrice.toFixed(2) * qty;
  const prices = {
    'unitPrice': unitPrice.toFixed(2) * 1,
    'totalPrice': totalPrice.toFixed(2) * 1,
    'basedPriceUnit': basedPrice,
  }
  return prices;
}

export const convertObjectToFormData = (object, formData = new FormData(), parentKey = '') => {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      // Vérifier si la valeur est un fichier (File ou Blob)
      if (value instanceof File || value instanceof Blob) {
        formData.append(formKey, value);
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        convertObjectToFormData(value, formData, formKey);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const arrayKey = `${formKey}[${index}]`;
          if (item instanceof File || item instanceof Blob) {
            formData.append(arrayKey, item);
          } else if (typeof item === 'object') {
            convertObjectToFormData(item, formData, arrayKey);
          } else {
            formData.append(arrayKey, item);
          }
        });
      } else {
        formData.append(formKey, value);
      }
    }
  }
  return formData;
};

export const formatFormDataFromObject = (object) => {
  let formData = new FormData();
  for (const key in object) {
    if (object[key] === null || object[key] === undefined) {
      formData.append(key, "");
    } else if (typeof (object[key]) === 'object' && !(object[key] instanceof File)) {
      formData.append(key, JSON.stringify(object[key]));
    } else {
      formData.append(key, object[key]);
    }
  }
  return formData;
}

/**
 * 
 * @param {string} unitOfMeasure kg | g | L | mL
 * @returns {string} masse |volume retourne le type de l'unité de mesure
 */
export const getUnitMeasureType = (unitOfMeasure: string): string => {
  const conversion: Record<string, string> = {
    'kg': "masse",
    'g': "masse",
    'mg': "masse",
    'l': 'volume',
    'ml': 'volume',
  }

  try {
    return conversion[unitOfMeasure.toLowerCase()]
  } catch {
    // console.error(
    //   "parameter unitOfMeasure is invalid : \n",
    //   "unitOfMeasure :", unitOfMeasure,
    // )
    return ""
  }
}


export const isString = value => typeof value === 'string';

export const isNumber = value => {
  if (isString(value)) {
    const formatedValue = value.replace(',', '.');
    return parseFloat(formatedValue) == formatedValue
      && formatedValue.charAt(formatedValue.length - 1) !== '.'
      && formatedValue.charAt(formatedValue.length - 1) !== '0'
  }
  if (typeof value === 'number') {
    return true;
  }
  return false;
};

export const formatDateForInput = (date = new Date()) => new Date(date).toISOString().split('T')[0]

export function getColorFromNumber(number: number) {
  const hue = (Math.pow(number, 2) * 1) % 360; // Utilisation d'une valeur constante pour la cohérence
  return `hsl(${hue}, 90%, 30%)`; // Utilisation d'un modèle de couleur HSL pour obtenir des couleurs distinctes
}

export const isAnalyseDeal = (deal: object) => {
  return deal.pipeline_id === 10
}

export function isDateInLastMonths(date: Date, months: Number): boolean {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const targetMonth = date.getMonth();
  const targetYear = date.getFullYear();

  // Calculer le mois limite en fonction du nombre de mois spécifié
  const limitDate = new Date();
  limitDate.setMonth(currentMonth - months);
  const limitMonth = limitDate.getMonth();
  const limitYear = limitDate.getFullYear();

  // Vérifier si la date est dans la plage spécifiée
  return (
    (targetYear > limitYear || (targetYear === limitYear && targetMonth >= limitMonth)) &&
    (targetYear < currentYear || (targetYear === currentYear && targetMonth <= currentMonth))
  );
}

export function isCurrentMonth(date: Date) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const targetMonth = date.getMonth();
  const targetYear = date.getFullYear();

  return targetYear === currentYear && targetMonth === currentMonth;
}


export const utf8decode = (text: string) => {
  return text.replace("Ã©", "é");
}