import React, { useContext } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import { NotificationContext } from 'Context/NotificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'


export default function DeleteMovement({ movement, filterMovement }) {

    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)

    const handleSubmit = (e) => {
        e.preventDefault()
        addNotificationContent({
            title: 'Suppression en cours',
            content: <MiniLoader />,
        })

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-history/${movement.id}`, { method: 'delete' }, null)
            .then(() => {
                filterMovement(movement.id)
                showPopup(false)
                addNotificationContent({
                    title: "Succès",
                    error: false,
                    content: "Le mouvement a été supprimé",
                    infinit: false,
                })
            })
            .catch((error) => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: error.response.message,
                    infinit: false
                })
            })

    }
    return (
        <div>
            <h2>Supprimer le mouvement {movement.name}</h2>
            <p>Attention, cette suppression est irréversible.</p>
            <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
                <button
                    type="button"
                    onClick={() => showPopup(false)}
                    className="erp-button erp-big-button"
                >
                    Annuler
                </button>
                <button
                    className="erp-button erp-big-button error-strong-button"
                    style={{ display: "block", margin: "auto" }}
                    onClick={e => handleSubmit(e)}
                >
                    Supprimer
                </button>
            </div>
        </div>
    )
}
