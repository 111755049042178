import React, { useState, useEffect, useContext } from 'react'
import AddUser from 'Components/_COMMERCIAL/Users/AddUser'
import UpdateUser from 'Components/_COMMERCIAL/Users/UpdateUser'
import Datalist from 'Components/Dataliste/Dataliste'
import { UsersContext } from 'Context/Commercial/Users'
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'
import { PopupContext } from 'Context/PopupContext'
import { appConstante } from 'appConstante'

export default function ComptesClients() {
  const { users, setUsers } = useContext(UsersContext)
  const { organizations } = useContext(OrganizationsContext)
  const { addPopupContent } = useContext(PopupContext)

  const [selectedOrgId, setSelectedOrgId] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState(false)

  const showAddUser = () => {
    addPopupContent(<AddUser addUser={addUser} />)
  }

  const addUser = newUser => {
    setUsers([newUser, ...users])
  }
  const updateUser = (updatedUser) => {
    setUsers(users.map(user => user.ID === updatedUser.ID ? updatedUser : user))
  }

  const showUpdateUser = (user) => {
    addPopupContent(<UpdateUser user={user} updateUsers={updateUser} />, "800px")
  }

  return (
    <>
      <button onClick={() => showAddUser()} className='m-l-0 strong-button'>Ajouter un utilisateur</button>
      <div className='m-b-25 flex-start gap-1'>
        <div>
          <Datalist
            width='300px'
            placeholder="Sélectionnez une organisation"
            items={organizations}
            onSelect={setSelectedOrgId}
          />
        </div>
        <div>
          {users
            ? <Datalist
              width='300px'
              items={users.map(user => ({ ...user, id: user.ID, value: user.user_email }))}
              onSelect={setSelectedEmail}
              placeholder="Sélectionnez une adresse email"
            />
            : null
          }
        </div>
      </div>

      <table className='beautiful-array'>
        <thead>
          <tr>
            <th></th>
            <th>Adresse email de connexion</th>
            <th>Organization Pipedrive</th>
            <th>Date d'inscription</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {organizations && users &&
            users
              .filter(user => selectedOrgId
                ? user.pipedrive_id == selectedOrgId
                : true)
              .filter(user => selectedEmail
                ? user.ID == selectedEmail
                : true)
              .map(user => {
                const organization = organizations.filter(
                  (organization) => organization.id == user.pipedrive_id
                )
                return (
                  <tr key={user.ID}>
                    <td><button className="light-button" onClick={() => showUpdateUser(user)}>Modifier</button></td>
                    <td>{user.user_email}</td>
                    <td>{organization.length ? organization[0].name : "Le compte n'est pas lié à Pipedrive"}</td>
                    <td>{new Date(user.user_registered).toLocaleDateString('fr')}</td>
                    <td>
                      <a
                        href={`${appConstante.urls.MSL_URL_ACCOUNT}?user_id=${user.ID}`}
                        className='light-button'
                        target='_blank'
                      >
                        Voir le compte
                      </a>
                    </td>
                  </tr>
                )
              })
          }

        </tbody>
      </table>
    </>
  )
}
