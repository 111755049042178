import React, { useState, useEffect, useContext } from "react"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import "./createInvoice.css"
import { NotificationContext } from "Context/NotificationContext"
import {
  formatCommentsProducts,
  deleteEmptyObjectsField,
  useSendInvoiceToCustomer,
  formatCustomerFromPennyLane,
  useCallPipedriveApi,
  useCallPennylaneApi,
  formatNumber
} from "Functions"
import { PopupContext } from "Context/PopupContext"
import InvoiceDetails from "AppContainer/Invoices/InvoiceDetails/InvoiceDetails"
import { appConstante } from "appConstante"
import InvoiceParameters from "./InvoiceParameters"
import InvoiceCustomer from "./InvoiceCustomer"
import InvoiceProducts from "./InvoiceProducts"

export default function CreateInvoice({ dealId, refresh }) {
  const [deal, setDeal] = useState(false)
  const [productsPennylane, setProductsPennylane] = useState(false)
  const [organisationPipedrive, setOrganisationPipedrive] = useState(false)
  const [personnePipedrive, setPersonnePipedrive] = useState(false)
  const [organisationPennylane, setOrganisationPennylane] = useState(false)
  const [createOrganisationPennylane, setCreateOrganisationPennylane] =
    useState(false)
  const [pipedriveCustomerInfo, setPipedriveCustomerInfo] = useState(false)
  const [invoiceParameters, setInvoiceParameters] = useState(false)

  const { addNotificationContent } = useContext(NotificationContext)
  const { showPopup, showPopup2, addPopupContent2 } = useContext(PopupContext)
  const sendInvoiceToCustomer = useSendInvoiceToCustomer()

  const callPipedriveApi = useCallPipedriveApi()
  const callPennylaneApi = useCallPennylaneApi()

  // [PIPEDRIVE] RECUPERATION DES INFORMATIONS DE L'ORGANISATION OU DE LA PERSONNE SI NON RATACHE A UNE ORGANISATION (COMME POUR QUELQUES ANALYSES)
  useEffect(() => {

    callPipedriveApi(`deals/${dealId}`)
      .then((res) => {
        setDeal(res.data.data)
        const orgId = res.data.data.org_id
          ? res.data.data.org_id.value
          : false
        const personId =
          !orgId && res.data.data.person_id
            ? res.data.data.person_id.value
            : false
        if (orgId) {
          // récupération du client sur pennylane
          callPipedriveApi(`organizations/${orgId}`)
            .then((res) => {
              setOrganisationPipedrive(res.data.data)
            })
        } else {
          addNotificationContent({
            error: true,
            title: "Erreur",
            content: "Aucune organisation n'est attaché au deal. Consulter le devis sur Pipedrive",
            infinit: false,
          })
          showPopup(false)
        }
      })
  }, [])

  // [PIPEDRIVE] RECUPERATION DES PRODUITS LIES AU DEAL
  useEffect(() => {
    if (deal) {
      const productsTVA = deal["36003ea40004b0374789f1fea7482a1aefdce487"]
        ? JSON.parse(deal["36003ea40004b0374789f1fea7482a1aefdce487"], true)
        : false
      const convertTVA = {
        5.5: ["FR_55", "5.5%", 0.055],
        20: ["FR_200", "20%", 0.2],
        0.9: ["FR_09", "0.9%", 0.009],
        2.1: ["FR_21", "2.1%", 0.021],
        8.5: ["FR_85", "8.5%", 0.085],
        10: ["FR_100", "10%", 0.1],
        13: ["FR_130", "13%", 0.13],
        0: ["exempt", "0%", 0],
      }
      callPipedriveApi(`deals/${dealId}/products`)
        .then((res) => {
          setProductsPennylane(
            res.data.data.map((productPipedrive) => {
              const tax =
                productsTVA && productsTVA[productPipedrive.id]
                  ? convertTVA[productsTVA[productPipedrive.id]]
                  : convertTVA[productPipedrive.tax]

              const discount = productPipedrive.discount_type === "percentage"
                ? productPipedrive.discount / 100
                : (
                  ((productPipedrive.item_price - (productPipedrive.sum / productPipedrive.quantity)) /
                    productPipedrive.item_price)
                ).toFixed(5) * 1

              const unitPriceTTC =
                tax[2]
                  ? (productPipedrive.item_price * (1 - discount) * (1 + tax[2]))
                  : productPipedrive.item_price * (1 - discount)

              console.log(productPipedrive);
              return {
                label: productPipedrive.name,
                quantity: productPipedrive.quantity,
                unitPrice: unitPriceTTC,
                price_no_tax:
                  productPipedrive.tax_method !== "inclusive"
                    ? productPipedrive.item_price
                    : (
                      productPipedrive.item_price /
                      (1 + productPipedrive.tax / 100)
                    )
                // : productPipedrive.item_price
                ,
                currency_amount: (unitPriceTTC * productPipedrive.quantity).toFixed(2) * 1,
                plan_item_number: "",
                unit: "unité",
                vat_rate: tax[0],
                discount: productPipedrive.discount_type === "percentage"
                  ? productPipedrive.discount / 100
                  : productPipedrive.discount / 100,
                formated_vat: tax[1],
                id: productPipedrive.id,
                description: productPipedrive.comments
              }
            })
          )
        })
    }
  }, [deal])

  // PARAMETRES DE FACTURES
  useEffect(() => {
    const loadData = async () => {
      if (!deal[appConstante.pennylane.IDENTIFIANT_PENNYALNE]) {
        // PAR DEFAULT DEPUIS LE DEAL
        setInvoiceParameters({
          date: new Date().toISOString().split("T")[0],
          deadline: "",
          pdf_invoice_free_text: deal.adf931444dfdca63b729630f904142ca4acf5093,
          pdf_invoice_subject: `Facture relative au devis n°${dealId}`,
          draft: false,
          special_mention: deal["09fb39f27867cda67db347ae5cfdac0b3a808afe"]
            ? deal["09fb39f27867cda67db347ae5cfdac0b3a808afe"]
            : organisationPipedrive["25b164c96d293be04d4a018be5ea306c56c0b4dd"],
        })
      } else {
        // RECUPERATION DES INFORMATIONS SI LA FACTURES EXISTE
        callPennylaneApi(`customer_invoices/${deal[appConstante.pennylane.IDENTIFIANT_PENNYALNE]}`)
          .then((response) => {
            const { invoice } = response.data
            setInvoiceParameters({
              date: invoice.date,
              deadline: invoice.deadline,
              pdf_invoice_free_text: invoice.pdf_invoice_free_text,
              pdf_invoice_subject: invoice.pdf_invoice_subject,
              draft: false,
              special_mention: invoice.special_mention.replace(
                "Conditions de paiements: ",
                ""
              ),
            })
          })
      }
    }
    deal && loadData()
  }, [deal])

  // [pennylane] RECUPERATION DES INFORMATIONS DE FACTURATIONS DEPUIS PENNYLANE X PIPEDRIVE
  useEffect(() => {
    if (organisationPipedrive) {
      callPennylaneApi(`/customers/${organisationPipedrive.id}`)

        .then((res) => {
          setCreateOrganisationPennylane(false)
          setOrganisationPennylane(
            formatCustomerFromPennyLane(res.data.customer)
          )
          setPipedriveCustomerInfo({
            customer_type: "company",
            emails: [
              organisationPipedrive["4aa1a579257cc2458b8f510398459556acd3b884"],
            ],
            name: organisationPipedrive.name,
            payment_conditions:
              organisationPipedrive["25b164c96d293be04d4a018be5ea306c56c0b4dd"],
            source_id: organisationPipedrive.id.toString(),
            postal_code: organisationPipedrive.address_postal_code,
            recipient: deal.person_id ? deal.person_id.name : "",
            notes:
              organisationPipedrive.b1cca64d70088183432f84efabf24fc5b7520d50,
            city: organisationPipedrive.address_locality,
            address:
              organisationPipedrive.address_formatted_address &&
              organisationPipedrive.address_formatted_address.split(",")[0],
            country_alpha2:
              organisationPipedrive.address_country === "France" ? "FR" : "",
            delivery_address: deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              ? deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              : organisationPipedrive[
              "710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"
              ],
            phone:
              organisationPipedrive["05fe424d8c2707c195849c283d12370c062fbb2f"],
          })
        })
        .catch((err) => {
          setCreateOrganisationPennylane(true)
          console.log(organisationPipedrive);
          setOrganisationPennylane({
            customer_type: "company",
            emails: [
              organisationPipedrive["4aa1a579257cc2458b8f510398459556acd3b884"],
            ],
            name: organisationPipedrive.name,
            payment_conditions: "custom",
            source_id: organisationPipedrive.id.toString(),
            postal_code: organisationPipedrive.address_postal_code,
            recipient: deal.person_id ? deal.person_id.name : "",
            notes:
              organisationPipedrive.b1cca64d70088183432f84efabf24fc5b7520d50,
            city: organisationPipedrive.address_locality,
            address:
              organisationPipedrive.address &&
              organisationPipedrive.address.split(",")[0],
            country_alpha2:
              organisationPipedrive.address_country === "France" ? "FR" : "",
            delivery_address: deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              ? deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              : organisationPipedrive[
              "710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"
              ],
            phone:
              organisationPipedrive["05fe424d8c2707c195849c283d12370c062fbb2f"],
          })
        })
    } else if (personnePipedrive) {
      callPennylaneApi(`customers/${personnePipedrive.id}`)
        .then((res) => {
          setOrganisationPennylane(
            formatCustomerFromPennyLane(res.data.customer)
          )
          setPipedriveCustomerInfo({
            customer_type: "individual",
            first_name: personnePipedrive.first_name,
            last_name: personnePipedrive.last_name,
            address:
              personnePipedrive.postal_address_formatted_address &&
              personnePipedrive.postal_address_formatted_address.split(",")[0],
            postal_code: personnePipedrive.postal_address_postal_code,
            city: personnePipedrive.postal_address_locality,
            country_alpha2:
              personnePipedrive.postal_address_country === "France" ? "FR" : "",
            recipient: personnePipedrive.name,
            source_id: personnePipedrive.id.toString(),
            emails: [
              personnePipedrive.primary_email[
              "4aa1a579257cc2458b8f510398459556acd3b884"
              ],
            ],
            delivery_address: deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              ? deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              : personnePipedrive.postal_address_formatted_address,
            payment_conditions: "custom",
            phone: personnePipedrive.phone[0].value,
            vat_number: "",
            notes: "",
          })
        })
        .catch((err) => {
          setCreateOrganisationPennylane(true)
          setOrganisationPennylane({
            customer_type: "individual",
            first_name: personnePipedrive.first_name,
            last_name: personnePipedrive.last_name,
            address:
              personnePipedrive.postal_address_formatted_address &&
              personnePipedrive.postal_address_formatted_address.split(",")[0],
            postal_code: personnePipedrive.postal_address_postal_code,
            city: personnePipedrive.postal_address_locality,
            country_alpha2:
              personnePipedrive.postal_address_country === "France" ? "FR" : "",
            recipient: personnePipedrive.name,
            source_id: personnePipedrive.id.toString(),
            emails: [personnePipedrive.primary_email],
            delivery_address: deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              ? deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
              : personnePipedrive.postal_address_formatted_address,
            payment_conditions: "custom",
            phone: personnePipedrive.phone[0].value,
            vat_number: "",
            notes: "",
          })
        })
    }
  }, [organisationPipedrive, personnePipedrive])

  // AFFICHAGE DE LA POPUP DE CONFIRMATION
  const confirmCreateInvoice = (e) => {
    e.preventDefault()
    if (invoiceParameters.draft) {
      sendInvoice()
    } else {
      addPopupContent2(
        <>
          <h2>Confirmation de la création de votre facure</h2>
          <p>
            Attention, une fois créé, la facture ne peux plus être modifié !
          </p>
          <div className="flex">
            <button
              type="button"
              onClick={() => showPopup2(false)}
              className="erp-button erp-big-button error-strong-button"
            >
              Annuler
            </button>
            <button
              className="erp-button erp-big-button"
              style={{ display: "block", margin: "auto" }}
              onClick={() => {
                showPopup2(false)
                sendInvoice()
              }}
            >
              Créer
            </button>
          </div>
        </>
      )
    }
  }

  // [PENNYLANE] ENVOIE DE LA FACTURE A PENNYLANE
  const sendInvoice = () => {
    addNotificationContent({
      title: invoiceParameters.draft
        ? "Création du brouillon en cours"
        : "Création de la facture en cours",
      error: false,
      content: <MiniLoader />,
      infinit: true,
    })
    const formatedInvoiceParameters = deleteEmptyObjectsField(invoiceParameters)
    formatedInvoiceParameters.special_mention &&
      (formatedInvoiceParameters.special_mention = `Condition de paiement: ${formatedInvoiceParameters.special_mention}`)

    const data = {
      invoice: {
        customer: { source_id: organisationPennylane.source_id },
        line_items: productsPennylane
          .filter(product => product.quantity)
          .map((product) =>
            deleteEmptyObjectsField({
              label: product.label,
              quantity: product.quantity,
              currency_amount: parseFloat(product.currency_amount),
              plan_item_number: product.plan_item_number,
              unit: product.unit,
              vat_rate: product.vat_rate,
              description: product.description,
            })
          ),
        ...deleteEmptyObjectsField(formatedInvoiceParameters),
      },
      create_customer: false,
      update_customer: false,
      create_products: false,
    }

    callPennylaneApi('customer_invoices', { method: "post" }, data)
      .then((res) => {
        addNotificationContent({
          title: invoiceParameters.draft ? "Brouillon créé" : "Facture créée",
          error: false,
          content: invoiceParameters.draft
            ? "Le brouillon viens d'être créé avec succès"
            : "La facture viens d'être créée avec succès",
          infinit: false,
        })
        addInvoiceToPipedrive(
          dealId,
          res.data.invoice.invoice_number,
          res.data.invoice.id,
          res.data.invoice.file_url
        )
      })
      .catch((error) => {
        error.response &&
          addNotificationContent({
            error: true,
            title: "Erreur",
            content: error.response.data.error,
            infinit: false,
          })
      })
  }

  // [PIPEDRIVE] Ajout des informations de la facture au deal sur pipedrive // possibilité d'envoyer par mail
  const addInvoiceToPipedrive = (dealId, invoiceNumber, invoiceId, fileUrl) => {
    const data = {
      a68ca62f0aee305694a2848754aab8463d370764: invoiceId,
      cc3bddb9b08ee9ccfeded5485cf582f311558f1c: invoiceNumber,
      "083c6b85db7a7b60f271271d3329eb3a7a3997a1": fileUrl,
    }
    callPipedriveApi(`deals/${dealId}`, { method: "put" }, data)
      .then((res) => {
        !invoiceParameters.draft && confirmSendInvoiceToCustomer(invoiceId)
        showPopup(false)
        refresh && refresh()
      })
  }

  const confirmSendInvoiceToCustomer = (invoiceId) => {
    addPopupContent2(
      <>
        <h2>Envoyer la facture par mail</h2>
        <p>
          Vous avez la possibilité d'envoyer la facture par mail au client (
          {organisationPennylane.emails[0]})<br />
          Il vous est également possible de l'envoyer plus tard depuis l'onglet
          'mes factures'
        </p>
        <div className="flex">
          <button
            type="button"
            onClick={() => showPopup2(false)}
            className="erp-button erp-big-button error-strong-button"
          >
            Ne pas envoyer
          </button>
          <button
            className="erp-button erp-big-button"
            style={{ display: "block", margin: "auto" }}
            onClick={() => {
              sendInvoiceToCustomer(invoiceId)
              showPopup2(false)
            }}
          >
            Envoyer
          </button>
        </div>
      </>
    )
  }

  if (deal && deal.cc3bddb9b08ee9ccfeded5485cf582f311558f1c) {
    return (
      <div id="invoice-main-container">
        <h2>
          Facture du devis n° {dealId} ({deal && deal.title})
        </h2>
        {/* CUSTOMER PART */}
        {organisationPennylane ? (
          <InvoiceCustomer
            organisationPennylane={organisationPennylane}
            createOrganisationPennylane={createOrganisationPennylane}
            setOrganisationPennylane={setOrganisationPennylane}
            setCreateOrganisationPennylane={setCreateOrganisationPennylane}
            pipedriveCustomerInfo={pipedriveCustomerInfo}
          />
        ) : (
          <MiniLoader />
        )}

        <h3 style={{ marginTop: "25px" }}>Informations de facturation</h3>
        <p>
          Ce devis à déjà fait l'objet d'une facturation, vous ne pouvez pas le
          facturer une deuxième fois
        </p>
        <div style={{ maxWidth: "500px", margin: "auto" }}>
          <div className="flex">
            <div>Numéro de facture: </div>
            <div>{deal.cc3bddb9b08ee9ccfeded5485cf582f311558f1c}</div>
          </div>
          <div className="flex">
            <div>Identifiant Pennylane: </div>
            <div>{deal.a68ca62f0aee305694a2848754aab8463d370764}</div>
          </div>
          <div className="flex">
            <div>Fichier: </div>
            <div>
              <a
                href={deal["083c6b85db7a7b60f271271d3329eb3a7a3997a1"]}
                target="_blank"
                rel="noreferrer"
              >
                Voir la facture
              </a>
            </div>
          </div>
          <div className="flex">
            {deal.a68ca62f0aee305694a2848754aab8463d370764 && (
              <>
                <button
                  className="strong-button"
                  onClick={() => {
                    addPopupContent2(
                      <InvoiceDetails
                        invoiceId={
                          deal.a68ca62f0aee305694a2848754aab8463d370764
                        }
                      />
                    )
                  }}
                >
                  Voir les détails de facturation
                </button>
                <button
                  className="strong-button"
                  onClick={() =>
                    sendInvoiceToCustomer(
                      deal.a68ca62f0aee305694a2848754aab8463d370764
                    )
                  }
                >
                  Renvoyer par mail
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
  if (deal && deal.status !== "won") {
    return (
      <div id="invoice-main-container">
        <h2>Facture du devis n° {dealId}</h2>
        <p>Ce devis n'a pas été validé par le client</p>
      </div>
    )
  }

  return (
    <div id="invoice-main-container">
      <h2>
        Création d'une facture pour le devis n° {dealId} ({deal && deal.title})
      </h2>
      {/* CUSTOMER PART */}
      {organisationPennylane ? (
        <InvoiceCustomer
          organisationPennylane={organisationPennylane}
          createOrganisationPennylane={createOrganisationPennylane}
          setOrganisationPennylane={setOrganisationPennylane}
          setCreateOrganisationPennylane={setCreateOrganisationPennylane}
          pipedriveCustomerInfo={pipedriveCustomerInfo}
        />
      ) : (
        <MiniLoader />
      )}

      <form id="create-invoice" onSubmit={(e) => confirmCreateInvoice(e)}>
        {/* PRODUCT PART */}
        {organisationPennylane &&
          productsPennylane &&
          !createOrganisationPennylane && (
            <InvoiceProducts
              products={productsPennylane}
              setProducts={setProductsPennylane}
              organisationPennylane={organisationPennylane}
            />
          )}

        {/* INVOICE PART */}
        {invoiceParameters &&
          organisationPennylane &&
          !createOrganisationPennylane && (
            <InvoiceParameters
              invoiceParameters={invoiceParameters}
              setInvoiceParameters={setInvoiceParameters}
              won_time={deal.won_time}
            />
          )}

        {invoiceParameters &&
          organisationPennylane &&
          !createOrganisationPennylane && (
            <div className="flex m-t-25 justify-center gap-2">
              <label className="flex column">
                Brouillon
                <select
                  value={invoiceParameters.draft}
                  onChange={e => setInvoiceParameters(prvParam => ({ ...prvParam, draft: e.target.value === "true" }))}>
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </select>
              </label>
              <button className="strong-button m-0">{invoiceParameters.draft ? "Enregister le brouillon" : "Créer la facture"}</button>
            </div>
          )}
      </form>
    </div>
  )
}
