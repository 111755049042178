import React, { useState, useEffect, useContext, useRef } from "react"
import { PopupContext } from "Context/PopupContext"
import { useCallApi } from "Functions"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import "./eventDetail.css"
import ValidedStep from "Components/_ERP/PlanDeal/Steps/ValidedStep"
import ValidedSession from "Components/_ERP/PlanDeal/Sessions/ValidedSession"
import { appConstante } from "appConstante"
import { PlanningContext } from "Context/ERP/PlanningContext"
import { useCurrentAppPermission } from 'Functions'
import ConsultProductSpecification from "AppContainer/ERP/SpecificationsAndRecipe/ProductSpecification/ConsultProductSpecification"
import CreateProductionSheets from "AppContainer/ERP/SpecificationsAndRecipe/ProductionSheets/CreateProductionSheets"
import ConsultProductionSheets from "AppContainer/ERP/SpecificationsAndRecipe/ProductionSheets/ConsultProductionSheets"
import SelectProductSpecification from "../ProductSpecification/SelectProductSpecification"
import { DealProductsContext } from "Context/ERP/deal/DealProduct"
import DealProductDetails from "../PlanDeal/DealProduct/DealProductDetails"


export default function EventDetail({ event }) {
  const callApi = useCallApi()
  const currentAppPermission = useCurrentAppPermission()

  const { showPopup } = useContext(PopupContext)
  const {
    getSession,
    updateSession,
    deletePlanningStep,
    deleteSession
  } = useContext(PlanningContext)

  const { putDealProduct } = useContext(DealProductsContext)

  const [shownEvent, setShownEvent] = useState(event)
  const [planningName, setPlanningName] = useState(event.planning_name ? event.planning_name : event.title)
  const [showProdSheetForm, setShowProdSheetForm] = useState(false)
  const [sessionKey, setSessionKey] = useState(0)

  const updateEvent = () => {
    getSession(shownEvent.id)
      .then(session => {
        setShownEvent(session)
        updateSession(session)
      })
  }

  useEffect(() => {
    updateEvent() //actualisation de l'event au chargement de la page si il y a eu du nouveau
  }, [])

  const createProductionSheet = () => {
    updateEvent()
    setShowProdSheetForm(false)
  }

  /* SESSION FUNCTIONS */
  const updateSessionFunction = (updatedEvent) => {
    setSessionKey(prevKey => prevKey + 1)
    setShownEvent({ ...event, ...updatedEvent })
  }

  const deleteSessionFunction = (id) => {
    showPopup(false)
    deleteSession(id)
  }

  const deleteStepFunction = (id) => {
    deletePlanningStep(id)
    showPopup(false)
  }

  const timeoutHandle = useRef(0)
  const updatePlanningName = (name) => {
    setPlanningName(name)
    clearTimeout(timeoutHandle.current)
    timeoutHandle.current = setTimeout(() => {
      callApi(
        `${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${shownEvent.id}`,
        { method: "put" },
        null,
        { planning_name: name }
      ).then(() => refresh && refresh(true, false, false))
    }, 750)
  }

  const toggleCreateProdSheet = () => {
    setShowProdSheetForm(!showProdSheetForm)
  }

  const [productSpectificationSteps, setProductSpecificationSteps] = useState(null)

  useEffect(() => {
    if (shownEvent.step?.name) {
      if (shownEvent.step.name.toUpperCase() === 'PRODUCTION') {
        setProductSpecificationSteps({ recipe: true })
      } else if (shownEvent.step.name.toUpperCase() === 'CONDITIONNEMENT') {
        setProductSpecificationSteps({ primaryConditionning: true })
      } else if (shownEvent.step.name.toUpperCase() === 'ETIQUETAGE') {
        setProductSpecificationSteps({ secondaryConditionning: true })
      } else if (shownEvent.step.name.toUpperCase() === 'MISE EN BOITE'
        || shownEvent.step.name.toLowerCase() === "mise en boîte") {
        setProductSpecificationSteps({ secondaryConditionning: true })
      }
    }
  }, [])

  const addProductSpecification = (id) => {
    if (id && confirm('Mettre à jour la spécification produits ?')) {
      putDealProduct({ id: shownEvent.product.id, product_specification_id: id })
        .then(res_ => updateEvent())
    }
  }

  return (
    <>
      <h2>
        {" "}
        <input
          className="title-text-input"
          onInput={(e) => updatePlanningName(e.target.value)}
          value={planningName}
        />
      </h2>
      {planningName !== shownEvent.title && (
        <div className="under-title">{shownEvent.title}</div>
      )}
      <h3>Produit concerné: {shownEvent.product && shownEvent.product.name}</h3>
      <div id="product-comments">
        {shownEvent.product
          ? <div
            style={{
              fontSize: "14px",
              color: "rgb(80,80,80)",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{ __html: shownEvent.product.comments }} />
          : null}
      </div>

      <DealProductDetails id={shownEvent?.product?.id} />

      {!showProdSheetForm
        ? <div className="flex start m-b-40 m-t-25 gap-1">
          <div key={sessionKey} className="half-m-width">
            <ValidedStep
              stepId={shownEvent.step?.id}
              deleteStepFunction={deleteStepFunction}
              openInfos={true}
            />
          </div>
          <div className="half-m-width">
            <ValidedSession
              session={shownEvent}
              showCreatePS={false}
              updateSessionFunction={updateSessionFunction}
              deleteSessionFunction={deleteSessionFunction}
              openInfos={true}
            />
          </div>
        </div>
        : null
      }


      {
        currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
          ? <>
            {shownEvent.product?.product_specification_id && !shownEvent?.production_sheet
              ? <button
                className={`${!showProdSheetForm ? "strong-button" : "center-block light-button error m-t-25"} m-b-50`}
                onClick={e => toggleCreateProdSheet(e)}>
                {showProdSheetForm ? 'Annuler' : 'Créer une fiche de prod'}
              </button>
              : null
            }
          </>
          : null
      }

      {shownEvent?.production_sheet
        ? <ConsultProductionSheets id={shownEvent.production_sheet.id} />
        : <>
          {shownEvent.product && shownEvent.product.product_specification_id
            ? !showProdSheetForm && productSpectificationSteps
              ? <div className="m-t-25">
                <ConsultProductSpecification
                  id={shownEvent.product.product_specification_id}
                  defaultSteps={productSpectificationSteps}
                  canEdit={false}
                />
              </div>
              : null
            : <>
              <h4 className="center m-b-25">Il n'y pas de spécification produit pour cette production</h4>
              <SelectProductSpecification
                setter={addProductSpecification}
              />
            </>
          }
        </>
      }

      {showProdSheetForm
        ? <div className="m-t-25">
          <CreateProductionSheets sessionId={shownEvent.id} onCreate={createProductionSheet} />
        </div>
        : null
      }
    </>
  )
}
