import React, { useContext, useEffect, useState } from 'react'
import BatchsIngredient from 'AppContainer/ERP/SpecificationsAndRecipe/Recipe/shared/Ingredient/Batch/BatchsIngredient'
import { formatNumber, getUnitMeasureType, isNumber } from 'Functions';
import './ingredientItem.css'
import set from 'lodash/set.js';
import { RecipeContext } from 'Context/ERP/RecipeContext';
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet';
import Datalist from 'Components/Dataliste/Dataliste';
import { ArticleContext } from 'Context/ERP/ArticleContext';
import { NotificationContext } from 'Context/NotificationContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import OpenableComponent from 'Components/GENERAL/OpenableComponent/OpenableComponent';

export default function IngredientItem({
    passedIngredient,
    setRecipe,
    style,
}) {
    const [ingredient, setIngredient] = useState({
        ...passedIngredient,
        info: {},
        cannabinoids: passedIngredient.type === "cannabinoids" ? "CBD" : null,
    })

    useEffect(() => {
        if (passedIngredient?.article?.density && !ingredient?.info?.density) {
            updateIngredientPropriety('info.density', passedIngredient.article.density)
        } else if (ingredient.infusion !== 0 && ingredient.quantity_type === "masse" || ingredient.quantity_type === "masse/masse") {
            updateIngredientPropriety('info.density', 1)
        }
    }, [passedIngredient?.article?.density])

    useEffect(() => {
        if (ingredient?.article?.density) {
            updateIngredientPropriety('info.density', ingredient.article.density)
        } else if (ingredient.quantity_type === "masse" || ingredient.quantity_type === "masse/masse") {
            updateIngredientPropriety('info.density', 1)
        }
    }, [ingredient?.article])

    const {
        productionSheet,
    } = useContext(ProductionSheetContext)

    const {
        getIngredientQuantityVariation,
        percentQtyToRealQty,
        getRealQuantity,
        checkCannabinoids,
    } = useContext(RecipeContext)

    const { articles, getArticle } = useContext(ArticleContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const updateIngredientPropriety = (keyPath, value) => {
        setIngredient(prvIngredient => {
            const newIngredient = prvIngredient
            set(newIngredient, keyPath, value);
            return newIngredient;
        })
    }

    useEffect(() => {
        if (productionSheet.creating && ingredient) {
            setIngredient(prvIngredient => ({
                ...prvIngredient,
                info: {
                    ...prvIngredient.info,
                    absolute_mass_quantity: percentQtyToRealQty(ingredient),
                    marged_absolute_mass_quantity: getRealQuantity(ingredient),
                    quantity_variation: getIngredientQuantityVariation(ingredient),
                }
            }))
        }
    }, [
        ingredient.quantity,
        ingredient.article_id,
        ingredient.batchs,
        ingredient.info?.density,
        ingredient.article?.density,
        productionSheet.parameters.quantity_to_produce,
        productionSheet.parameters.marge_to_add,
        productionSheet.parameters.marge_to_add_for_infusion,
        productionSheet.article?.density,
    ])
    useEffect(() => {
        if (ingredient.type === "cannabinoids" && productionSheet?.article?.cbd_concentration && productionSheet.creating && ingredient.fixed_concentration === false) {
            updateIngredientPropriety('quantity', productionSheet.article.cbd_concentration)
        }

    }, [productionSheet?.article?.cbd_concentration, productionSheet.creating])

    const formatQuantityToAdd = (batch = null) => {
        if (batch.type === 'cannabinoids') {
            if (batch && batch.selected_cannabinoid) {
                return ((ingredient.info.marged_absolute_mass_quantity / (batch.selected_cannabinoid?.mean / 100)) * (1 - getTotalPercentBatchsQty(batch) / 100)).toFixed(2) * 1
            } if (batch && !batch.selected_cannabinoid && !batch.datas?.batch_number) {
                return `Vous devez selectionner un n° de lot`
            } else {
                return `Pas de résultats pour ce lot`
            }
        } else {
            return (ingredient.info.marged_absolute_mass_quantity * (1 - getTotalPercentBatchsQty(batch) / 100)).toFixed(4) * 1
        }
    }

    const getTotalPercentBatchsQty = (batch) => {
        let totalPercentBatchsRealQty = 0
        ingredient.batchs.forEach((b, index) => {
            totalPercentBatchsRealQty += batch.index > index ? b.percent_real_quantity : 0
        })
        return totalPercentBatchsRealQty
    }

    const addBatchNumber = () => {
        setIngredient(prvIngredient => ({
            ...prvIngredient,
            batchs: [
                ...prvIngredient.batchs,
                {
                    batch_id: null,
                    real_quantity: "",
                }
            ]
        })
        )
    }

    useEffect(() => {
        if (ingredient?.type && productionSheet.creating) {
            setRecipe(previousRecipe => {
                const newRecipe = { ...previousRecipe }
                newRecipe[ingredient.type] = newRecipe[ingredient.type].map((recipeIngredient, index) =>
                    index === ingredient.index
                        ? ingredient
                        : recipeIngredient
                )
                return newRecipe
            })
        }
    }, [ingredient])

    const showChangeIngredient = () => {
        if (confirm("Attention, ne changer d'ingrédient que si vous êtes sur de ce que vous faites !")) {
            setIngredient(prvIngredient => ({
                ...prvIngredient,
                changeIngredient: true,
                batchs: []
            }))
        }
    }

    const changeIngredient = async (id) => {
        if (id) {
            addNotificationContent({
                title: 'Chargement du nouvel ingredient',
                content: <MiniLoader />,
                infinit: true,
            })
            const newArticle = await getArticle(id)
            setIngredient(prvIngredient => ({
                ...prvIngredient,
                changeIngredient: false,
                batchs: [{}],
                info: {},
                article: newArticle,
                article_id: id
            }))
            addNotificationContent({
                content: "Nouvel ingrédient chargé"
            })
        }
    }

    const changeCannabinoid = (cannabinoid) => {
        if (cannabinoid) {
            setIngredient(prevIngredient => {
                let newIngredient = { ...prevIngredient };
                newIngredient.cannabinoids = cannabinoid
                newIngredient.batchs = newIngredient.batchs.map((batch) => {
                    if (batch.batch_id && batch.batch_id != '') {
                        return {
                            ...batch,
                            selected_cannabinoid: batch.results ? batch.results[cannabinoid] : null
                        };
                    } else {
                        return batch;
                    }
                });
                return newIngredient;
            });
        }
    }

    if (productionSheet.creating && ingredient?.type === "bases" && !checkCannabinoids()) {
        return <h5>Vous devez d'abord remplir la section des cannabinoides</h5>
    } else {
        return (
            <div className='flex column center' style={style ? style : {}}>
                <div className='m-b-25 full-width list-item-ingredient'>
                    <div className='flex column gap-1'>
                        {productionSheet.creating && !ingredient.article.density && !ingredient.info.density && (ingredient.infusion !== 0 && ingredient.quantity_type === "masse")
                            ? <div className='m-t-10 m-b-10' style={{ color: 'red' }}>Attention ! Cet ingrédient n'a pas encore de densité dans la base de donnée<br />
                                Veuillez la rajouter manuellement pour obtenir la quantité juste :
                            </div>
                            : null
                        }
                        <div className='flex column gap-1'>
                            <div className='strong-2 flex'>
                                <div className='left column'>
                                    {ingredient.article.name}
                                    {productionSheet.creating
                                        ? <>
                                            {ingredient.changeIngredient
                                                ? <Datalist
                                                    placeholder={'Sélectionnez un ingrédient'}
                                                    items={articles
                                                        .filter(article => article.category !== "Produit fini" && article.category !== "Packaging secondaire")
                                                        .filter(article => ingredient.type === "cannabinoids" ? article.to_be_analyzed : true)
                                                        .map(article => ({ id: article.id, value: article.name }))
                                                    }
                                                    onSelect={changeIngredient}
                                                    width='300px'
                                                />
                                                : <button
                                                    type='button'
                                                    className='m-t-10'
                                                    onClick={_ => showChangeIngredient()}
                                                >changer d'ingrédient
                                                </button>}

                                        </>
                                        : null
                                    }
                                </div>
                                <div className='strong-2'>
                                    {ingredient.fixed_concentration || ingredient.type !== "cannabinoids"
                                        ? `${ingredient.quantity} %`
                                        : <>
                                            {productionSheet.creating && productionSheet?.article?.cbd_concentration
                                                ? `${productionSheet.article.cbd_concentration} %`
                                                : 'En fonction du produit fini'
                                            }
                                        </>
                                    }{
                                        ingredient.quantity_type
                                            ? ingredient.quantity_type !== "masse/masse"
                                                ? ` (de ${ingredient.quantity_type}/${productionSheet?.article?.unit_of_measure
                                                    ? getUnitMeasureType(productionSheet?.article?.unit_of_measure)
                                                    : "UPF"})`
                                                : ` (de ${ingredient.quantity_type})`
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='left m-t-10'>Quantité totale en stock : {ingredient.article.stock} {ingredient.article.unit_of_measure}</div>
                            {ingredient.article.stock
                                ? <OpenableComponent>
                                    {ingredient.article.products.map((product, index) => {
                                        return (
                                            <div key={index}>
                                                {product.history.map((batch, index) => {
                                                    if (batch.quantity > 0) {
                                                        return (
                                                            <div key={index} className='m-t-25 left'>
                                                                <div>N° lot : {batch.batch_number}</div>
                                                                <div>Qté : {batch.quantity} {ingredient.article.unit_of_measure}</div>
                                                                <div>DDM : {new Date(batch.ddm).toLocaleDateString()}</div>
                                                                <div>Fournisseur : {batch.product_supplier}</div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )
                                    })}
                                </OpenableComponent>
                                : null}
                        </div>
                        {productionSheet.creating &&
                            <>
                                {(ingredient.infusion !== 0 && ingredient.quantity_type === "volume")
                                    ? <>
                                        <div className={(!ingredient.article.density) ? 'flex center priority-input-container' : 'flex center'}>
                                            <label className={!ingredient.article.density ? 'm-r-10 priority-label' : 'm-r-10'}>
                                                densité
                                            </label>
                                            <input
                                                className={!ingredient.article.density ? 'priority-input' : null}
                                                type='number'
                                                value={ingredient.info.density ?? ""}
                                                style={{ width: '80px' }}
                                                onInput={e => setIngredient(prvIngredient =>
                                                ({
                                                    ...prvIngredient,
                                                    info: { ...prvIngredient.info, density: e.target.value }
                                                }))}
                                                step={0.01}
                                                disabled={ingredient.article.density}
                                            >
                                            </input>
                                        </div>
                                    </>
                                    : null
                                }

                                <>
                                    {ingredient.type === "cannabinoids" && ingredient.batchs[0]?.batch_id
                                        ? <div className='flex center'
                                            style={{
                                                padding: '5px',
                                                borderRadius: '5px',
                                                border:
                                                    isNumber(formatQuantityToAdd(ingredient.batchs[0])) && isFinite(formatQuantityToAdd(ingredient.batchs[0]))
                                                        ? '1px solid #918edd' : '2px solid #ff4f5e',
                                            }}>
                                            <label className='left'>Considérer les analyses du :</label>
                                            <select value={ingredient.cannabinoids} onChange={e => changeCannabinoid(e.target.value)}>
                                                <option value="CBD">CBD</option>
                                                <option value="CBN">CBN</option>
                                                <option value="CBG">CBG</option>
                                                <option value="CBC">CBC</option>
                                                <option value="THC">THC</option>
                                            </select>
                                        </div>
                                        : null
                                    }
                                    <div className='flex center'>
                                        <div className=' m-r-10'>Δ quantité : </div>
                                        <div className='strong-2'>{ingredient.info.quantity_variation || ingredient.info.quantity_variation === 0 ? ingredient.info.quantity_variation : '...'}g</div>
                                    </div>
                                    {<>
                                        {ingredient.batchs.map((batch, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className='flex-center'>
                                                        <div className='m-r-10 left'>quantité à ajouter pour le lot {batch.datas && batch.datas.batch_number} (g) : </div>
                                                        <div
                                                            className='strong-2'
                                                            style={{
                                                                padding: '12px',
                                                                backgroundColor: isNumber(formatQuantityToAdd(batch)) && isFinite(formatQuantityToAdd(batch)) ? '#918edd' : '#ff4f5e',
                                                                color: 'white',
                                                                borderRadius: '5px'
                                                            }}>
                                                            {formatNumber(formatQuantityToAdd(batch))} g
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                    }
                                </>

                                <div className='column gap-1'>
                                    {ingredient && ingredient.batchs.map((batch, index) =>
                                        <BatchsIngredient
                                            key={index}
                                            passedBatch={{
                                                ...batch,
                                                index: index,
                                                type: ingredient.type,
                                                cannabinoids: ingredient.cannabinoids,
                                                ingredient_total_mass: ingredient.info.marged_absolute_mass_quantity
                                            }}
                                            setIngredient={setIngredient}
                                            ingredient={ingredient}
                                        />
                                    )}
                                    <div className='column'>
                                        <button className='light-button m-t-10 center-block' onClick={() => addBatchNumber()} type='button'>Ajouter un numéro de lot</button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div >
        )
    }
}
