import React, { useState, useContext, useEffect } from "react"
import { PopupContext } from "Context/PopupContext"
import {
  useCallPipedriveApi,
  useCallApi,
} from "Functions"
import { appConstante, formatedState } from "appConstante"

import MiniLoader from "Components/MiniLoader/MiniLoader"
import IncludeProduct from "./IncludeProduct"
import IgnoreProduct from "./IgnoreProduct"
import SetProductInStock from "./SetProductInStock"
import PlanProductForm from "./PlanProductForm"

export default function PlanDeal({ dealId, selectInfo }) {
  /* Contexte */
  const callApi = useCallApi()
  const callPipedrive = useCallPipedriveApi()
  const { addPopupContent2 } = useContext(PopupContext)

  /* CONST */
  const [pipedriveDealProducts, setPipedriveDealProducts] = useState(false)
  const [dealProducts, setDealProducts] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState()

  /** ***************** product from PIPEDRIVE ****************** */
  useEffect(() => {
    const controller = new AbortController()
    callPipedrive(`deals/${dealId}/products`, {
      signal: controller.signal,
    }).then((res) => {
      setPipedriveDealProducts(res.data.data ? res.data.data : [])
    })

    return () => {
      controller.abort()
    }
  }, [])

  /** ***************** produt from ERP  ****************** */
  useEffect(() => {
    const controller = new AbortController()
    if (pipedriveDealProducts) {
      Promise.all(
        pipedriveDealProducts.map((pipedriveProduct) =>
          getErpProduct(controller, pipedriveProduct.id, dealId)
        )
      ).then((erpProducts) => {
        setDealProducts(
          erpProducts.map((erpProduct, index) => ({
            ...pipedriveDealProducts[index],
            erp: erpProduct,
          }))
        )
      })
    }
    return () => {
      controller.abort()
    }
  }, [pipedriveDealProducts])

  const postErpProduct = (product) => {
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product`,
      { method: "post" },
      null,
      product
    )
  }

  const getErpProduct = (controller, id, dealId) =>
    new Promise((resolve, reject) => {
      const urlProducts = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${id}`
      callApi(urlProducts, { method: "get" }, controller.signal).then((res) => {
        if (res.status === 204) {
          const product = {
            id,
            deal_id: dealId,
            status: "to_plan",
            in_stock_quantity: 0,
            ignored: 0,
          }
          postErpProduct(product)
          resolve(product)
        } else {
          resolve(res.data.data ? res.data.data : [])
        }
      })
    })

  /** ************************************************************************* */

  const updateProductInProducts = (newErpProduct) => {
    setDealProducts(
      dealProducts.map((dealProduct) =>
        dealProduct.id === newErpProduct.id
          ? { ...dealProduct, erp: newErpProduct }
          : dealProduct
      )
    )
  }
  const planDealProduct = (product) => {
    setSelectedProduct(product)
    // Bizare...
    document.getElementById("planning-section").style.minHeight = "750px"
    document
      .getElementById("planning-section")
      .scrollIntoView({ behavior: "smooth", block: "end" })
    document.getElementById("planning-section").style.minHeight = "unset"
  }

  const setInStockProduct = (product) => {
    addPopupContent2(
      <SetProductInStock setter={updateProductInProducts} product={product} />
    )
  }

  const ignoreProduct = (product) => {
    addPopupContent2(
      <IgnoreProduct setter={updateProductInProducts} product={product} />
    )
  }

  const includeProduct = (product) => {
    addPopupContent2(
      <IncludeProduct setter={updateProductInProducts} product={product} />
    )
  }

  return (
    <>
      <h2>Planifier la production du devis {dealId}</h2>
      {!dealProducts ? (
        <MiniLoader />
      ) : (
        <>
          {dealProducts.length > 0 ? (
            <table style={{ width: "98%" }}>
              <thead>
                <tr style={{ height: "40px" }}>
                  <th style={{ textAlign: "left" }}>Nom du produit</th>
                  <th>
                    Quantité
                    <br />
                    initiale
                  </th>
                  <th>
                    Quantité
                    <br />
                    en stock
                  </th>
                  <th>Status</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {dealProducts.map((product, index) => (
                  <tr
                    key={index}
                    style={{
                      opacity: product.erp.ignored === 1 ? 0.6 : 1,
                      backgroundColor:
                        product.erp.status === "plan" ||
                          product.erp.status === "in_stock" ||
                          product.erp.status === "sended" ||
                          product.erp.status === "to_send"
                          ? "rgba(166, 214, 144, 0.25)"
                          : "",
                    }}
                  >
                    <td>
                      <div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            textAlign: "left",
                          }}
                        >
                          {product.name} {product.erp.ignored ? "(Ignoré)" : null}
                        </div>
                        {product.comments
                          ? <div
                            className="comment-deal-detail"
                            dangerouslySetInnerHTML={{ __html: product.comments }} />
                          : null}
                      </div>
                    </td>
                    <td className="center nowrap">{product.quantity}</td>
                    <td className="center nowrap">
                      {product.erp.in_stock_quantity
                        ? product.erp.in_stock_quantity
                        : 0}
                    </td>
                    <td className="center nowrap">
                      {formatedState[product.erp.status]}
                    </td>
                    <td className="center nowrap">
                      <button
                        className="light-button"
                        disabled={product.erp.ignored}
                        onClick={() => planDealProduct(product)}
                      >
                        Planifier
                      </button>
                    </td>
                    <td className="center nowrap">
                      <button
                        className="light-button"
                        disabled={product.erp.ignored}
                        onClick={() => setInStockProduct(product)}
                      >
                        En stock
                      </button>
                    </td>
                    <td>
                      <input
                        className="erp-check"
                        type="checkbox"
                        checked={!product.erp.ignored}
                        onChange={() =>
                          product.erp.ignored
                            ? includeProduct(product)
                            : ignoreProduct(product)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h5>Ce devis n'a pas de produit</h5>
          )}
        </>
      )}
      <div style={{ marginTop: "20px" }} id="planning-section">
        {selectedProduct && (
          <PlanProductForm
            passedProduct={selectedProduct}
            selectInfo={selectInfo}
            dealId={dealId}
          />
        )}
      </div>
    </>
  )
}
