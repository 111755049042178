import React, { useState, useContext } from 'react'
import UserFields from './UserFields'
import { useCallMSLApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function UpdateUser({ user, updateUsers }) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)
    const callMSLApi = useCallMSLApi()
    const [updatedUser, setUpdatedUser] = useState(
        {
            ID: user.ID,
            login: user.user_login,
            email: user.user_email,
            password: '',
            pipedrive_id: parseInt(user.pipedrive_id),
        })

    const updateUser = (key, value) => {
        setUpdatedUser({ ...updatedUser, [key]: value })
    }

    const updateUserBackend = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            title: "Mise à jour en cours",
            content: <MiniLoader />,
        })
        callMSLApi(`wp_user/${updatedUser.ID}`, { method: 'PUT' }, updatedUser)
            .then(res => {
                updateUsers({ ...updatedUser, ...res.data.data })
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: 'Mise à jour réussie',
                })
            })
            .catch(error => {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: error.response.data.message,
                })
                console.log({ error });
            })
    }

    return (
        <>
            <h2>Mettre à jour l'utilisateur</h2>
            <form onSubmit={e => updateUserBackend(e)}>
                <UserFields user={updatedUser} updateUserFunction={updateUser} add={false} />
                <button className='strong-button center-block m-b-25 m-t-25'>Mettre à jour</button>
            </form>
        </>
    )
}
