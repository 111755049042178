export const msalConfig = {
  auth: {
    clientId: "ac3757d0-bc22-441c-b081-6ee31ef19233",
    authority:
      "https://login.microsoftonline.com/788dbe32-4053-495b-b780-fba37c1b770e",
    redirectUI: "http://localhost:3000/login",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
}

export const loginRequest = {
  scope: ["User.Read"],
}

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}
