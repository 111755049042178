import React from 'react'
import InputSmall from 'Components/FormElement/SmallInput'

export default function Field({ label, value, setter, field, variables, type, required, disabled = false, border = false }) {
    return (
        <>
            <div className='flex input gap-1'>
                <InputSmall
                    label={label}
                    customParameters={[field]}
                    value={value ? value : ""}
                    setter={setter}
                    type={type ? type : "text"}
                    step={0.0001}
                    required={required}
                    border={border}
                    style={{ width: "100%" }}
                    datalistId={field}
                    disabled={disabled}
                />
                {/* <label>{label}{required && <span style={{ color: 'rgb(180,0,0)' }}>*</span>}</label>
                <input
                    list={field}
                    value={value ? value : ""}
                    onInput={e => setter(field, e.target.value)}
                    type={type ? type : "text"}
                    step={0.0001} required={required}
                /> */}
                <datalist id={field}>
                    {variables && variables.map(variable => (
                        <option key={variable.id}>{variable.value}</option>
                    ))}
                </datalist>
            </div>
        </>
    )
}
