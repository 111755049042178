import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallApi, isNumber } from 'Functions';
import { appConstante, getStockUnitOfMeasure } from 'appConstante';
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet';
import { PopupContext } from 'Context/PopupContext';
import set from 'lodash/set.js';
import MidLoader from 'Components/MidLoader/MidLoader';
import UpdateParametersPS from './UpdateParametersPS';
import ConsultParametersPS from './ConsultParametersPS';
import Comments from 'Components/GENERAL/Comments/Comments';
import SelectBulk from 'Components/_CBDP/Bulk/SelectBulk';
import SelectBatch from 'Components/_CBDP/Batch/SelectBatch';
import BulkDetailsSmall from 'Components/_CBDP/Bulk/BulkDetailsSmall';
import BatchDetailsSmall from 'Components/_CBDP/Batch/BatchDetailsSmall';
import UpdateHistory from 'AppContainer/ERP/Stocks/ImportsAndExport/History/UpdateHistory';
import ConsultFiles from 'Components/GENERAL/Files/ConsultFiles';

export default function ConsultProductionSheets({ id }) {

    const callApi = useCallApi()
    const { updateProductionSheet } = useContext(ProductionSheetContext)
    const { showPopup, addPopupContent2 } = useContext(PopupContext)

    const params = useParams()
    const [paramsId, setParamsId] = useState(id);
    const [productionSheet, setProductionSheet] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        params.id && setParamsId(params.id)
    }, [params.id])

    useEffect(() => {
        if (!isUpdating) {
            setProductionSheet(null)
            const controller = new AbortController();
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet/${paramsId} `, { method: 'get' }, controller.signal)
                .then(res => {
                    setProductionSheet(res.data.data)
                })
                .catch((error) => {
                    console.log(error)
                })
            return () => {
                controller.abort()
            }
        }
    }, [paramsId, isUpdating])

    const sendUpdatedPS = async (updatingPS) => {
        updateProductionSheet(updatingPS)
            .then(updatedPS => {
                setProductionSheet(updatedPS)
                showPopup(false)
            })
    }
    // const Navigate = useNavigate()
    // const gotToUpdatePS = () => {
    //     Navigate(`/erp/specif-and-recipe/production-sheets/edit/${paramsId}`)
    //     showPopup(false)
    // }

    const updateParametersKey = (keyPath, value) => {
        setProductionSheet(prevProductionSheet => {
            const newProductionSheet = { ...prevProductionSheet }
            set(newProductionSheet, `parameters.${keyPath}`, isNumber(value) ? parseFloat(value) : value)
            return newProductionSheet
        })
    }

    const updatePSKey = (keyPath, value) => {
        setProductionSheet(prevProductionSheet => {
            const newProductionSheet = { ...prevProductionSheet }
            set(newProductionSheet, `${keyPath}`, isNumber(value) ? parseFloat(value) : (value ? value : null))
            return newProductionSheet
        })
    }

    const updatePSHistory = (newHistory) => {
        setProductionSheet(pvsPS => ({
            ...pvsPS,
            history: pvsPS.history.map(histo =>
                histo.id === newHistory.id
                    ? {
                        ...newHistory,
                        article_id: newHistory.batch.article.id
                    }
                    : histo
            )
        }))
    }

    return (
        <>
            {
                productionSheet
                    ? <div
                    // style={{ padding: '30px 50px' }}
                    >
                        <h3 className='m-b-10'>Fiche de production n°{productionSheet.id} : {productionSheet.session.title}</h3>
                        <div className='flex gap-2'>
                            <div className='flex-1'>
                                <div className='flex gap-1'>
                                    {
                                        productionSheet.parameters
                                            ? isUpdating
                                                ? <UpdateParametersPS
                                                    parameters={productionSheet.parameters}
                                                    setter={updateParametersKey}
                                                />
                                                : <ConsultParametersPS
                                                    parameters={productionSheet.parameters}
                                                />
                                            : null
                                    }
                                    <div>
                                        <div className='flex-center gap-1  m-b-10'>
                                            <div>Date de début :</div>
                                            <div className='strong-2'>
                                                {new Date(productionSheet.session.start).toLocaleString('fr-FR')}
                                            </div>
                                        </div>
                                        <div className='flex-center gap-1 m-b-25'>
                                            <div>Date de fin :</div>
                                            <div className='strong-2'>
                                                {new Date(productionSheet.session.end).toLocaleString('fr-FR')}
                                            </div>
                                        </div>
                                        {productionSheet.bulk_id
                                            ? <div className='m-b-10 '>
                                                <div className='strong-2'>N° de bulk</div>
                                                <BulkDetailsSmall id={productionSheet.bulk_id} />
                                            </div>
                                            : null
                                        }
                                        {productionSheet.batch_id
                                            ? <div className='m-b-10 '>
                                                <div className='strong-2'>N° de lot</div>
                                                <BatchDetailsSmall id={productionSheet.batch_id} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>

                                {productionSheet.conditioning?.primary?.length
                                    ? <>
                                        <h4 className='m-b-0'>Packagings primaires</h4>
                                        {ProductionSheetProductArray(productionSheet.conditioning.primary)}
                                    </>
                                    : null
                                }
                                {productionSheet.conditioning?.secondary?.length
                                    ? <>
                                        <h4 className='m-b-0'>Packagings secondaires</h4>
                                        {ProductionSheetProductArray(productionSheet.conditioning.secondary)}
                                    </>
                                    : null
                                }
                                {productionSheet.parameters?.steps?.recipe
                                    ? <>
                                        <h5 className='m-b-10 m-t-10'>Production</h5>
                                        {productionSheet?.recipe?.cannabinoids?.length
                                            ? <>
                                                <h4 className='m-t-0 m-b-10'>cannabinoïdes</h4>
                                                {ProductionSheetIngredientsArray(productionSheet.recipe.cannabinoids)}
                                            </>
                                            : null
                                        }
                                        {productionSheet?.recipe?.bases?.length
                                            ? <>
                                                <h4 className='m-b-10'>bases</h4>
                                                {ProductionSheetIngredientsArray(productionSheet.recipe.bases)}
                                            </>
                                            : null
                                        }
                                        {productionSheet?.recipe?.other_ingredients?.length
                                            ? <>
                                                <h4 className='m-b-10'>Autres ingrédients</h4>
                                                {ProductionSheetIngredientsArray(productionSheet.recipe.other_ingredients)}
                                            </>
                                            : null
                                        }
                                    </>
                                    : null
                                }
                                {
                                    isUpdating
                                        ? <div className='flex'>
                                            <div className='full-width'>
                                                <SelectBulk
                                                    onSelect={updatePSKey}
                                                    customParameters={['bulk_id']}
                                                    width='100%'
                                                    defaultId={productionSheet.bulk_id}
                                                    required={false}
                                                />
                                            </div>
                                            <SelectBatch
                                                width='100%'
                                                setter={updatePSKey}
                                                customParameters={['batch_id']}
                                                defaultId={productionSheet.batch_id}
                                                required={false}
                                            />
                                        </div>
                                        : null
                                }
                                {productionSheet?.id
                                    ? <>
                                        <div className='m-t-10 m-b-25'>
                                            <ConsultFiles target={"productionSheet"} targetId={productionSheet.id} />
                                        </div>

                                        <div className='m-t-10 m-b-25'>
                                            <Comments target={"ProductionSheet"} target_id={productionSheet.id} />
                                        </div>
                                    </>
                                    : null
                                }


                                {/** IGNORRRRRRRRRRRRRRRRRRRRRRRRREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEED */}
                                {
                                    productionSheet.history && productionSheet.history.length
                                        ? <>
                                            <h5 className='m-b-10 m-t-10'>Mouvements de stock</h5>
                                            <table className="beautiful-array m-b-25">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Article</th>
                                                        <th>Fournisseur</th>
                                                        <th>N° de lot</th>
                                                        <th>Quantité</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productionSheet.history.map((histo, index) => {

                                                        const formatedHisto = {
                                                            ...histo,
                                                            article_id: histo.batch.article.id
                                                        }

                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <button
                                                                        className='light-button show-on-hover'
                                                                        onClick={() => addPopupContent2(
                                                                            <UpdateHistory
                                                                                history={formatedHisto}
                                                                                setter={updatePSHistory}
                                                                            />)}
                                                                    >
                                                                        Modifier
                                                                    </button>
                                                                </td>
                                                                <td>{formatedHisto.batch.article.name}</td>
                                                                <td>{formatedHisto.batch.product_supplier}</td>
                                                                <td>{formatedHisto.batch.batch_number}</td>
                                                                <td>
                                                                    {formatedHisto.quantity} {getStockUnitOfMeasure(formatedHisto.batch.article)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                        : null
                                    // : <div className='strong-2 center'>Pas d'historiques pour ces articles</div>
                                }
                            </div>
                        </div>
                    </div>
                    : <MidLoader />
            }
            <div className='flex justify-center gap-1 m-t-10'>
                {paramsId && !isUpdating
                    ? <button
                        className='strong-button m-0'
                        onClick={() => setIsUpdating(true)}
                    >
                        Modifier
                    </button>
                    : null}
                {isUpdating
                    ? <div className='flex gap-1'>
                        <button
                            className='light-button'
                            onClick={() => setIsUpdating(false)}
                        >
                            Annuler les changements
                        </button>
                        <button
                            className='strong-button'
                            onClick={() => sendUpdatedPS(productionSheet)}
                        >
                            Valider
                        </button>
                    </div>
                    : null}
            </div>
        </>
    )
}

function ProductionSheetIngredientsArray(ingredientsArray) {
    return (
        <table className="beautiful-array m-b-25">
            <thead>
                <tr>
                    <th>Article</th>
                    <th>Fournisseur</th>
                    <th>Numéro de lot</th>
                    <th>densité</th>
                    <th>Quantité utilisé</th>
                </tr>
            </thead>
            <tbody>
                {ingredientsArray.map((ingredient) => {
                    return ingredient?.batchs?.map((batch, i) => {
                        return (
                            <tr key={i} >
                                <td>{batch?.data?.article?.name}</td>
                                <td>{batch?.data?.product_supplier}</td>
                                <td>{batch?.data?.batch_number}</td>
                                <td>{ingredient?.info?.density === 1 ? "-" : ingredient?.info?.density}</td>
                                <td>{batch?.real_quantity} (g)</td>
                            </tr>
                        )
                    })
                })}
            </tbody>
        </table>
    )
}
function ProductionSheetProductArray(productArray) {
    return (
        <table className="beautiful-array m-b-25">
            <thead>
                <tr>
                    <th>Article</th>
                    <th>Fournisseur</th>
                    <th>Numéro de lot</th>
                    <th>Quantité utilisée</th>
                </tr>
            </thead>
            <tbody>
                {productArray.map((product, index) => {
                    return (
                        <tr key={index} >
                            <td>{product?.data?.article?.name}</td>
                            <td>{product?.data.product_supplier}</td>
                            <td>{product?.data.batch_number}</td>
                            <td>{product?.quantity}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}