import React, { useEffect, useState } from 'react'
import Select from 'Components/FormElement/Select'
import DeleteButton from 'Components/DeleteButton/DeleteButton'

export default function DeliverySheetArticleItem({ passedBatch, availableBatch, setter, deleteBatch }) {

    const [batch, setBatch] = useState(null)

    useEffect(() => {
        setBatch({
            ...passedBatch,
            id: passedBatch.id
                ? passedBatch.id
                : availableBatch.length
                    ? availableBatch[0].id
                    : ''
        })
    }, [])

    useEffect(() => {
        if (batch) {
            setter && setter(batch)
        }
    }, [batch])

    const updateBatch = (key, value) => {
        setBatch(pvs => ({ ...pvs, [key]: value ? parseInt(value) : '' }))
    }

    return (
        <>
            {batch
                ? <>
                    <div className='flex-start gap-1 m-b-10'>
                        <div className='flex-center left'>
                            <Select
                                label="N° lot"
                                width="450px"
                                value={batch.id}
                                onChange={updateBatch}
                                customParameters={['id']}
                            >
                                <option value=''>Selectionner un batch</option>
                                {availableBatch
                                    .map((batch, index) => (
                                        <option value={batch.id} key={index}>
                                            [{batch.product_supplier}] {batch.batch_number} ( quantité : {batch.quantity} {batch.ddm && `- DDM : ${new Date(batch.ddm).toLocaleDateString()}`})
                                        </option>
                                    ))}
                            </Select>
                            <div>
                                <div className="strong-2 m-t-10">Quantité</div>
                                <input
                                    type="number"
                                    className="center small-input"
                                    onInput={(e) => updateBatch('quantity', e.target.value)}
                                    value={batch.quantity}
                                />
                            </div>
                            {batch.index
                                ? <div className='m-t-25'>
                                    <DeleteButton
                                        alwaysDisplay={true}
                                        callBack={deleteBatch}
                                        parameters={[batch.frontId]}
                                    />
                                </div>
                                : null}
                        </div>
                    </div>
                    {availableBatch.length &&
                        batch.quantity > (batch.prv_quantity + availableBatch.find(avaiBatch => avaiBatch.id == batch.id)?.quantity ?? 0)
                        ? <div className='red m-b-10'>Attention ! La quantité en stock est inférieure à la quantité que vous souhaitez indiquer</div>
                        : null}
                </>
                : null}
        </>
    )
}