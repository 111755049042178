import { addMargeToLabels, getDensityForm } from 'AppContainer/ERP/ERPConstantes'
import OpenableComponent from 'Components/GENERAL/OpenableComponent/OpenableComponent'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import { formatNumber, getUnitMeasureType } from 'Functions'
import { getConversionUnitFactor, getStockUnitOfMeasure } from 'appConstante'
import React, { useContext, useEffect, useRef, useState } from 'react'
import set from 'lodash/set.js';
import SelectArticles from 'Components/_ERP/SelectArticles'
import './productSpecificationArticle.css'
import { PopupContext } from 'Context/PopupContext'
import { emptyParameters } from '../ProductSpectificationDetails'
/**
 * 
 * @param {Article} defaultArticle vous pouvez passer directement l'article si vous l'avez déjà chargé chez le parent. Obligatoire si vous n'avz pas passer articleId
 * @param {Number} articleId vous pouvez passer l'id de l'article pour charger les informations. Obligatoire si vous n'avz pas passer defaultArticle
 * @param {Parameters} parameters vous pouvez passer des paramètres sous format 
 * {quantity: Number, //quantité du produit
    article: {
        quantity: number | string
        unit_of_measure: string
    },
    marge: Number
    type: "recipe" | "packaging"
    recipe: {
        quantity: number | string
        real_quantity: number | string
        category: "bases" | "cannabinoids" | "other_ingredients"
        concentration: number //utilisé pour les actifs / cannabinoids
    }
}
    pour l'affichage relatif des quantités requises pour la production
 * @returns 
 */

export default function ProductSpectificationArticle({ defaultArticle = null, articleId = null, parameters = emptyParameters, index = 0, setter = null, deleter = null }) {

    const { getArticle } = useContext(ArticleContext)
    const { addPopupContent2, showPopup2 } = useContext(PopupContext)

    const [article, setArticle] = useState({ ...defaultArticle ? defaultArticle : {} })
    const [details, setDetails] = useState(article.showDetails ?? false)
    const unityOfMeasure = useRef(

    )

    useEffect(() => {
        updateArticleProperty(
            'unit_of_measure',
            article.type === "primary_conditioning" || article.type === "secondary_conditioning"
                ? "unité"
                : article?.unit_of_measure
        )
    }, [])

    useEffect(() => {
        if (article.name) {
            updateArticleProperty(
                'real_quantity',
                computeRealQuantity()
            )
        }
    }, [
        article.quantity,
        article.unit_of_measure,
        article.density,
        article.cbd_concentration,
        parameters?.totalQuantityWithoutBases,
        parameters?.article?.quantity,
        parameters?.article?.unit_of_measure,
    ])

    const computeRealQuantity = () => {
        if (article.unit_of_measure) {
            if (article.type === "cannabinoids") {
                return parseFloat(article.quantity) / (parseFloat(article.cbd_concentration) / 100)
            } else if (article.type === "other_ingredients" && parameters?.article?.unit_of_measure) {
                const densityForm = getDensityForm(
                    getUnitMeasureType(parameters?.article?.unit_of_measure),
                    article.quantity_type
                        ? article.quantity_type
                        : 'masse',
                )
                let quantity = article.quantity ? article.quantity : 0;
                const density = article?.density ? article.density : 1
                return densityForm(quantity, density)
            } else if (article.type === "bases" && parameters.totalQuantityWithoutBases !== null) {
                return parseFloat(article.quantity) * (1 - parameters.totalQuantityWithoutBases)
            }
        }
    }

    useEffect(() => {
        if (articleId) {
            getArticle(articleId)
                .then(article => {
                    setArticle(_ => ({ ...article }))
                })
        }
    }, [articleId])

    // console.log(article);

    useEffect(() => {
        if (parameters?.article?.unit_of_measure) {
            if (parameters?.type === 'recipe' && article?.real_quantity !== null && article.unit_of_measure) {
                let productionQuantity = parseFloat(article.real_quantity)
                if (article.infusion === 1) {
                    productionQuantity = (productionQuantity / 100) * parseFloat(parameters.quantity) * parseFloat(parameters.article.quantity) * 1.18 //marge par défaut
                } else {
                    let densityForm = article.type === "cannabinoids"
                        ? getDensityForm(getUnitMeasureType(article.unit_of_measure), 'masse')
                        : getDensityForm(getUnitMeasureType(article.unit_of_measure), getUnitMeasureType(parameters.article.unit_of_measure))
                    productionQuantity = (densityForm(productionQuantity, article.density) / 100) * parseFloat(parameters.quantity) * parseFloat(parameters.article.quantity)
                }
                productionQuantity = productionQuantity * getConversionUnitFactor(parameters.article.unit_of_measure, article.unit_of_measure)
                productionQuantity = productionQuantity * (1 + parseFloat(parameters.marge) / 100)
                setArticle(prvArticle => ({ ...prvArticle, productionQuantity: productionQuantity }))
            } else if (parameters?.type !== 'recipe') {
                if (article.type_packaging === "Etiquettes") {
                    setArticle(prvArticle => ({ ...prvArticle, productionQuantity: addMargeToLabels(parameters.quantity) }))
                } else {
                    if (article.type === "base" || article.type === "cannabinoids" || article.type === "other_ingredients") {
                        setArticle(prvArticle => ({ ...prvArticle, productionQuantity: parameters.quantity * getConversionUnitFactor(parameters.article.unit_of_measure, getStockUnitOfMeasure(article)) * parameters.article.quantity }))
                    } else {
                        setArticle(prvArticle => ({ ...prvArticle, productionQuantity: parameters.quantity }))
                    }
                }
            }
        }
    }, [
        article?.real_quantity,
        article?.density,
        article.unit_of_measure,
        parameters?.type,
        parameters?.totalQuantityWithoutBases,
        parameters?.quantity,
        parameters?.marge,
        parameters?.article?.quantity,
        parameters?.article?.unit_of_measure,
    ])

    useEffect(() => {
        defaultArticle = article
        setter(index, article)
    }, [article])

    useEffect(() => {
        setArticle(prv => ({ ...prv, showDtails: details }))
    }, [details])

    useEffect(() => {
        if ((article.productionQuantity || article.productionQuantity === 0) && article.price) {
            const computePrice = parseFloat(article.customPrice ?? article.price) * parseFloat(article.productionQuantity)
            setArticle(prvArticle => ({ ...prvArticle, productionPrice: computePrice }))
        }
    }, [article.productionQuantity, article.price])

    const updateArticleProperty = (keyPath, value) => {
        setArticle(prv => {
            const newArticle = prv
            set(newArticle, keyPath, value);
            return { ...newArticle };
        })
    }

    const selectArticle = (id) => {
        if (id) {
            getArticle(id).then(article => {
                setArticle(prv => ({ ...prv, ...article }))
            })
        }
    }

    const deleteMaterial = (index) => {
        window.confirm("Êtes vous sur de vouloir retirer cet article ?") && deleter(index)
    }

    const changeArticle = (index) => {
        setArticle(prv => ({ type: prv.type, quantity: prv.quantity, quantity_type: prv.quantity_type }))
    }

    const createNewArticle = () => {
        addPopupContent2(<div className='flex gap-1 m-t-25 m-b-25'>
            <button
                onClick={_ => formatNewArticle()}
                style={{ height: "100px" }}
                className='light-button flex-1'
            >Créer un article uniquement pour cette matrice de prix
            </button>
        </div>)
    }

    const formatNewArticle = () => {
        setArticle(prv => ({
            ...prv,
            name: 'Nouvelle Article',
            category: 'Matière première',
            unit_of_measure: "kg",
            price: 1,
            density: 1,
            new: true,
        }))
        setDetails(true)
        showPopup2(false)
    }

    return (
        <div key={index}>
            <div className='list-item-ingredient relative'>
                {article.name || article.new
                    ? <>
                        <div className='m-b-10'>
                            <div className='strong-2 flex gap-1'>
                                {article.id
                                    ? article.name
                                    : <input
                                        style={{
                                            width: '100%',
                                            height: '20px',

                                        }}
                                        placeholder="Nom de l'article"
                                        className='strong-2 invisible-input interactive-input left'
                                        value={article.name}
                                        onInput={e => updateArticleProperty('name', e.target.value)}
                                        disabled={!parameters.editable}
                                    />

                                }

                                {parameters?.type === "recipe"
                                    ? <div style={{ whiteSpace: 'nowrap' }}>
                                        <input
                                            className='strong-2 small-input-2 invisible-input interactive-input right'
                                            value={article?.quantity ?? ""}
                                            onInput={e => updateArticleProperty('quantity', e.target.value)}
                                            type={'number'}
                                            disabled={!parameters.editable}
                                        />%
                                        {article?.quantity_type && article.type === "other_ingredients"
                                            ? <>
                                                <select
                                                    style={{
                                                        height: "20px",
                                                        width: '60px',
                                                        appearance: "none"
                                                    }}
                                                    onChange={e => updateArticleProperty('quantity_type', e.target.value)}
                                                    className='m-l-10 invisible-input interactive-input strong-2'
                                                    value={article.quantity_type}>
                                                    <option value={"masse"}>masse</option>
                                                    <option value={"volume"}>volume</option>
                                                </select>
                                            </>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                            {
                                details
                                    ? <>
                                        {article?.real_quantity || article?.real_quantity === 0
                                            ? <div className='subtext'>quantité réelle : {formatNumber(article.real_quantity)}%</div>
                                            : null
                                        }
                                        {article?.type === "cannabinoids"
                                            ? <div className='subtext m-b-1'>concentration en actif (%) :
                                                <input
                                                    className='subtext small-input-2 invisible-input interactive-input left'
                                                    value={article.cbd_concentration}
                                                    onInput={e => updateArticleProperty('cbd_concentration', e.target.value)}
                                                    type={'number'}
                                                    max={100}
                                                    step={1}
                                                    disabled={!parameters.editable}
                                                    min={0}
                                                /></div>
                                            : null
                                        }
                                        {
                                            parameters?.type === "recipe"
                                                ? <div className={`subtext m-b-1 ${article.density ? '' : 'error'}`}>
                                                    densité : <input
                                                        className='subtext small-input-2 invisible-input interactive-input left'
                                                        value={article.density ?? ""}
                                                        onInput={e => updateArticleProperty('density', e.target.value)}
                                                        type={'number'}
                                                        max={10}
                                                        step={0.1}
                                                        min={0}
                                                        disabled={!parameters.editable}
                                                    />
                                                </div>
                                                : null
                                        }
                                        {article?.type !== "primary_conditioning" && article?.type !== "secondary_conditioning"
                                            ? <div className='subtext m-b-1'>
                                                unité de mesure : <select
                                                    className='small-select-2 invisible-input interactive-input'
                                                    style={{ width: "45px" }}
                                                    value={getStockUnitOfMeasure(article) ?? article.unit_of_measure}
                                                    onChange={e => updateArticleProperty('unit_of_measure', e.target.value)}
                                                >
                                                    <option value="g">g</option>
                                                    <option value="kg">kg</option>
                                                    <option value="mL">mL</option>
                                                    <option value="l">L</option>
                                                </select>
                                            </div>
                                            : null}

                                        <div className='subtext m-b-1'>
                                            prix/{article.unit_of_measure} : <input
                                                className='subtext small-input-2 invisible-input interactive-input left'
                                                value={article.price ?? ""}
                                                onInput={e => updateArticleProperty('price', e.target.value)}
                                                type={'number'}
                                                max={1}
                                                step={0.1}
                                                min={0}
                                                disabled={!parameters.editable}
                                            />€
                                        </div>
                                    </>
                                    : null}

                        </div>
                        {article.id
                            ? <div className={`${!article.stock && 'error'} left`}>Qté totale en stock : {article.stock} {article.unit_of_measure}</div>
                            : null}
                        {!details && article.productionQuantity
                            ? <div className={`${article.stock - article.productionQuantity < 0 && 'error'}`}>Qté pour prod : {formatNumber(article.productionQuantity)} {article.unit_of_measure}</div>
                            : null
                        }
                        {!details && parameters?.show_pricing
                            ? <div className={`${!article?.productionPrice && 'error'}`}>Prix pour prod : {article?.productionPrice ? `${formatNumber(article.productionPrice)}€` : 'inconnu'}</div>
                            : null
                        }
                        {
                            (article.productionQuantity || article.productionQuantity === 0) && details
                                ? <div className='list-item-bg'>
                                    <div className='center'>Infos approximées de production</div>
                                    <hr className='m-t-0' />
                                    <div className='flex space-around'>
                                        <div className={`${article.stock - article.productionQuantity < 0 && 'error'} center flex-1`}>Quantité <br />{formatNumber(article.productionQuantity)} {article.unit_of_measure}</div>
                                        <hr className='m-0' />
                                        <div className='center flex-1'>Prix <br />{(article.productionPrice || article.productionPrice === 0) ? `${formatNumber(article.productionPrice)}€` : "non défini"}</div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            parameters.editable && details
                                ? <div className='flex gap-1 m-t-10'>
                                    <button
                                        className='flex-1'
                                        onClick={e => changeArticle(index)}>
                                        changer
                                    </button>
                                    {deleter
                                        ? <button
                                            onClick={e => deleteMaterial(index)}
                                            className='error flex-1'>
                                            supprimer
                                        </button>
                                        : null
                                    }
                                </div>
                                : null
                        }
                        {
                            <div
                                onClick={e => setDetails(prv => !prv)}
                                className='make-it-editable'>{
                                    details ? "Masquer" : "Détails"
                                }</div>
                        }
                        {article.stock && details
                            ? <OpenableComponent>
                                {article.products?.map((product, index) => {
                                    return (
                                        <div key={index}>
                                            {product.history.map((batch, index) => {
                                                if (batch.quantity > 0) {
                                                    return (
                                                        <div key={index} className='m-t-25 left'>
                                                            <div>N° lot : {batch.batch_number}</div>
                                                            <div>Qté : {batch.quantity} {getStockUnitOfMeasure(article)}</div>
                                                            <div>DDM : {batch.ddm ? new Date(batch.ddm).toLocaleDateString() : "non définie"}</div>
                                                            <div>Fournisseur : {batch.product_supplier}</div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                })}
                            </OpenableComponent>
                            : null
                        }
                    </>
                    : <div className='column center gap-1'>
                        < SelectArticles
                            // category={article.type}
                            onSelect={selectArticle}
                            refresh={false}
                        />
                        OU
                        <div className='flex full-width'>
                            <button
                                onClick={_ => createNewArticle()}
                                className='flex-1'>Créer un article
                            </button>
                            <button
                                onClick={e => deleteMaterial(index)}
                                // style={{ height: "100px" }}
                                className='error flex-1'>
                                Annuler
                            </button>
                        </div>

                    </div>
                }
            </div >
        </div>
    )
}
