import React, { useContext, useState } from 'react'
import set from 'lodash/set.js';
import { NotificationContext } from 'Context/NotificationContext';
import { useCallCBDPApi } from 'Functions';
import { PopupContext } from 'Context/PopupContext';
import BatchFields from './BatchFields';

export default function UpdateBatch({ batch, setter }) {

    const callCBDPApi = useCallCBDPApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)

    const [updatedBatch, setUpdatedBatch] = useState({
        ID: parseInt(batch.ID) || batch.ID,
        visible_id: batch.visible_id,
        bulk_id: parseInt(batch.bulk_id) || batch.bulk_id,
        packaging_date: batch.packaging_date,
        produced_quantity: parseInt(batch.produced_quantity) || batch.produced_quantity,
        cbdp: parseInt(batch.cbdp),
        article: batch.article,
    })

    const setBatchKey = (keyPath, value) => {
        setUpdatedBatch(prvBatch => {
            const newBatch = prvBatch
            set(prvBatch, `${keyPath}`, value)
            return { ...newBatch }
        })
    }

    const sendUpdatedBatch = async (e) => {
        e.preventDefault()

        addNotificationContent({
            error: false,
            content: "modification en cours",
        })

        // WARNING ! This 'POST' is a 'PUT' //
        callCBDPApi(`v2/batch/${updatedBatch.ID}`, { method: "post" }, updatedBatch)
            .then(res => {
                setter(prvBatchs => {
                    return prvBatchs.map(batch => parseInt(batch.ID) === parseInt(updatedBatch.ID)
                        ? { ...res.data.data, article: updatedBatch.article }
                        : batch
                    )
                })
                showPopup(false)
                addNotificationContent({ content: `Le batch a été modifié` })
            })
            .catch(error => console.log(`erreur modification /batch/${updatedBatch.ID}`, error))
    }

    return (
        <form onSubmit={e => sendUpdatedBatch(e)}>
            <h3>Modification du batch n° {updatedBatch.ID}</h3>
            <BatchFields
                batch={updatedBatch}
                setter={setBatchKey}
            />
            <button className='strong-button'>Modifier</button>
        </form>
    )
}