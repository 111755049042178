import React, { useEffect, useContext, useState } from 'react'
import Datalist from 'Components/Dataliste/Dataliste'
import { useCallApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import { appConstante } from 'appConstante'
import MiniLoader from "Components/MiniLoader/MiniLoader"

/**
 * 
 * @param {Function} setter callback to set brand ID
 * @param {Array} customParameters (optional) Custum paramaters added on the onSelect function  
 * @param {String} width (optional) width of the all element (label + input)
 * @returns 
 */
export default function SelectBrand({ defaultBrandId = null, setter, customParameters = [], placeholder = 'Sélectionner une marque', width, style }) {
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()
    const [brands, setBrands] = useState(null)
    const [defaultBrandValue, setDefaultBrandValue] = useState(false)

    useEffect(() => {
        if (brands && defaultBrandId) {
            const defaultBrand = brands.find(brand => brand.id === defaultBrandId);
            defaultBrand && setDefaultBrandValue(defaultBrand.value)
            defaultBrand && setter(...customParameters, defaultBrand.id)
        }
    }, [brands])


    useEffect(() => {
        const controller = new AbortController();
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/brands`, { method: 'get' }, controller.signal, {
            limit: 500,
            page: 1,
        })
            .then(res => {
                setBrands(res.data.data.map(brand => ({ id: brand.id, value: brand.name })))
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: 'Nous rencontrons une erreur avec nos serveurs',
                    infinit: false,
                })
            })
        return () => {
            controller.abort()
        }
    }, [])

    return (
        <>
            {brands
                ? <Datalist
                    style={style}
                    value={defaultBrandValue ? defaultBrandValue : ''}
                    placeholder={placeholder}
                    items={brands}
                    onSelect={setter}
                    customParameters={customParameters}
                    width={width}
                    required={true}
                />
                : <MiniLoader />
            }
        </>
    )
}
