import React, { useContext, useEffect, useState } from 'react'
import { useCallAnalysesApi } from 'Functions'
import { AnalyseAccessContext } from 'Context/Analyse/AnalyseAccessContext'
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { SamplesContext } from 'Context/Analyse/SamplesContext'
import AnalyseFields from 'AppContainer/ANALYSES/AnalysesItem/AnalyseFields'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import UpdateProduct from '../Product/UpdateProduct'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function UpdateAnalyse({ analyse, setter }) {

    const { analyseNonce } = useContext(AnalyseAccessContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { getSample } = useContext(SamplesContext)
    const { showPopup } = useContext(PopupContext)
    const callAnalysesApi = useCallAnalysesApi()
    const [updatedAnalyse, setUpdatedAnalyse] = useState(analyse)
    const [currentSample, setCurrentSample] = useState(null)

    const sendUpdatedAnalyse = (e) => {
        e.preventDefault()
        const controller = new AbortController()
        if (analyseNonce) {
            addNotificationContent({
                title: 'Mise à jour en cours',
                content: <MiniLoader />,
                infinit: true,
            })
            callAnalysesApi(`analyse/${analyse.ID}`, { method: "put", signal: controller.signal }, updatedAnalyse)
                .then(res => {
                    setter(psStateAnalyses => {
                        const newStateAnalyses = psStateAnalyses.map(analyse => {
                            if (analyse.ID === res.data.data.ID) {
                                return res.data.data
                            } else {
                                return analyse
                            }
                        })
                        return newStateAnalyses
                    })
                    showPopup(false)
                    addNotificationContent({
                        error: false,
                        title: 'Succès',
                        content: "L'analyse a été modifiée",
                        infinit: false
                    })
                })
                .catch(err => {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: err.res.message,
                        infinit: false
                    })
                })
        }
        return () => {
            controller.abort()
        }
    }

    useEffect(() => {
        if (updatedAnalyse.num_reference) {
            getSample(updatedAnalyse.num_reference)
                .then((response) => {
                    setCurrentSample(response)
                })
        }
    }, [updatedAnalyse])

    return (
        <div>
            <h2>Modifier une analyse</h2>
            <form className='m-b-25' onSubmit={e => sendUpdatedAnalyse(e)}>
                <AnalyseFields
                    analyse={updatedAnalyse}
                    setter={setUpdatedAnalyse}
                />
                <div>
                    <button className='strong-button'>Modifier l'analyse</button>
                </div>
            </form>
            {updatedAnalyse?.num_reference
                ? currentSample
                    ? <UpdateProduct
                        product={currentSample}
                    />
                    : <MidLoader />
                : null
            }
        </div>
    )
}
