import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ThirdNavBar from 'Components/NavBar/ThirdNavBar/ThirdNavBar'

export default function HomeProductionSheet() {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    location.pathname === '/erp/specif-and-recipe/production-sheets' && navigate(`/erp/specif-and-recipe/production-sheets/my-production-sheets`) //Sous onglet par défaut
  }, [location.pathname])
  return (
    <ThirdNavBar menu={[
      { link: "/erp/specif-and-recipe/production-sheets/my-production-sheets", name: "Consulter", access: true },
      { link: "/erp/specif-and-recipe/production-sheets/add-production-sheets", name: "Ajouter", access: true },
    ]} />
  )
}