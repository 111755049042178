import React, { useState, useEffect } from 'react';
import { EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import './TextEditor.css'

/**
 * 
 * @param {string} value state de la valeur de l'éditeur
 * @param {Function} onChange Pour mettre à jour la valeur du state lors de l'édition.
 * @returns 
 */
export default function TextEditor({ value = '', setter, setterToHTML = true, customParameters = [], placeholder = '', style = {}, className = "" }) {

    // const [editorState, setEditorState] = useState(() =>
    //     EditorState.createEmpty()
    // );


    const [editorState, setEditorState] = useState(() => {
        const contentState = ContentState.createFromBlockArray(
            convertFromHTML(value ?? "")
        );
        return EditorState.createWithContent(contentState);
    });

    useEffect(() => {
        if (value === '') {
            setEditorState(() => {
                const contentState =
                    ContentState.createFromBlockArray(convertFromHTML(""))
                return EditorState.createWithContent(contentState);
            })
        }
    }, [value])

    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        const content = setterToHTML
            ? stateToHTML(editorState.getCurrentContent())
            : editorState.getCurrentContent().getPlainText();
        setter && setter(...customParameters, content);
    };


    const onHandleKeyBindings = (e) => {
        if (e.keyCode === 9) {
            setEditorState(RichUtils.onTab(e, editorState, 4));
        } else {
            return getDefaultKeyBinding(e);
        }
    };


    const toolbarOptions = {
        options: ['inline', 'list'],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
    };

    return (
        <div style={style} className={className}>
            <Editor
                // toolbarOnFocus
                toolbar={toolbarOptions}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                onTab={onHandleKeyBindings}
                placeholder={placeholder}
                style={{ height: "100%" }}
            />
        </div>

    )

}
