import React, { useState, useContext, useEffect, useRef } from "react"
import { PopupContext } from "Context/PopupContext"
import DeliverySheet from "Components/_ERP/DeliverySheet/DeliverySheet"
import { NotificationContext } from "Context/NotificationContext"
import { appConstante } from "appConstante"
import { removeDuplicates, useCallApi, useCallPipedriveApi } from "Functions"
import MidLoader from "Components/MidLoader/MidLoader"
import Pagination from "Components/Pagination/Pagination"
import { useCurrentAppPermission } from 'Functions';
import SelectOrganization from "Components/_ERP/Brands/SelectOrganization"
import Input from "Components/FormElement/Input"
import CreateDeliverySheet from "Components/_ERP/DeliverySheet/CreateDeliverySheet"
import SearchDeal from "../SearchDeal/SearchDeal"

export default function DeliverySheets() {
  const callApi = useCallApi()
  const callPipedriveApi = useCallPipedriveApi()
  /** * CONST *** */
  const [erpDeliverySheets, setErpDeliverySheets] = useState(null)
  const [allDealsIdInPage, setAllDealsIdInPage] = useState(null)
  const [searchedDealId, setSearchedDealId] = useState('')
  const [error, setError] = useState(null)
  const [deliverySheetId, setDeliverySheetId] = useState('')
  const [organizationId, setOrganizationId] = useState('')
  const currentAppPermission = useCurrentAppPermission()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)

  const timeoutHandle = useRef()

  /* CONTEXT */
  const { addPopupContent, showPopup } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)

  useEffect(() => {
    const controller = new AbortController()
    const delay = deliverySheetId || searchedDealId ? 400 : 0
    clearTimeout(timeoutHandle.current)
    timeoutHandle.current = setTimeout(() => {
      setFormatederpDeliverySheets(false)
      let url
      if (deliverySheetId) {
        url = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/delivery-sheet/${deliverySheetId}`
      } else if (searchedDealId) {
        url = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${searchedDealId}/delivery-sheets`
      } else {
        url = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/delivery-sheets`
      }
      callApi(url, { method: "get" }, controller.signal, { page: currentPage, organizationId: organizationId })
        .then((res) => {
          const loadedDeliveredSheets = res.data.data
            ? Array.isArray(res.data.data) ? res.data.data : [res.data.data]
            : []
          setErpDeliverySheets(loadedDeliveredSheets)
          setAllDealsIdInPage(
            removeDuplicates(
              loadedDeliveredSheets.map((deliverySheet) => deliverySheet.deal_id)
            )
          )
          setTotalPage(res.data.total_pages)
        })
        .catch((er) => { console.log({ er }); setError("Une erreur est survenue") })
    }, delay)

    return () => {
      controller.abort()
    }
  }, [searchedDealId, deliverySheetId, currentPage, organizationId])

  const [formatederpDeliverySheets, setFormatederpDeliverySheets] =
    useState(false)
  useEffect(() => {
    allDealsIdInPage &&
      Promise.all(
        allDealsIdInPage.map((dealId) => getPipedriveDealInfos(dealId))
      ).then((pipedriveDealsInfo) => {
        setFormatederpDeliverySheets(
          erpDeliverySheets.map((deliverySheet) => {
            const deal = pipedriveDealsInfo.filter(
              (deal) => deal.id === deliverySheet.deal_id
            )
            return {
              ...deliverySheet,
              deal_name: deal[0].title,
              organisation_name: deal[0].org_id ? deal[0].org_id.name : "",
            }
          })
        )
      })
  }, [allDealsIdInPage])

  const getPipedriveDealInfos = (deal_id) =>
    new Promise((resolve, reject) => {
      callPipedriveApi(`deals/${deal_id}`).then((res) => {
        resolve(res.data.data)
      })
    })

  /* PAGINATION */
  const showDeliverySheet = (e, deliverySheet) => {
    if (e.target.id !== "delete-delivery-sheet" && e.target.id !== "update-delivery-sheet") {
      addPopupContent(<DeliverySheet deliverySheet={deliverySheet} />, "1220px")
    }
  }

  const showDeleteDelivery = (deliverySheet) => {
    addPopupContent(
      <>
        <h2>Suppression de bon de livraison n°{deliverySheet.id}</h2>
        <p className="center">Attention, cette action est irréversible</p>
        <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
          <button
            type="button"
            onClick={() => showPopup(false)}
            className="erp-button erp-big-button"
          >
            Annuler
          </button>
          <button
            className="erp-button erp-big-button error-strong-button"
            style={{ display: "block", margin: "auto" }}
            onClick={() => deleteDelivery(deliverySheet)}
          >
            Supprimer
          </button>
        </div>
      </>
    )
  }

  const deleteDelivery = (deliverySheet) => {
    const url = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/delivery-sheet/${deliverySheet.id}`
    showPopup(false)
    callApi(url, { method: "delete" })
      .then((res) => {
        setFormatederpDeliverySheets(
          formatederpDeliverySheets.filter(
            (delivery) => delivery.id !== deliverySheet.id
          )
        )
      })
      .catch((error) => {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: error.response.data.message,
          infinit: false,
        })
      })
  }

  const updateDL = (DL) => {
    setFormatederpDeliverySheets(pvs => pvs.map(pvsDL => pvsDL.id === DL.id ? DL : pvsDL))
  }

  return !error ? (
    <>
      <div className="flex-start end m-b-25 gap-1">
        <Input
          style={{ maxWidth: "250px", height: "40px" }}
          value={deliverySheetId}
          label="N° de bon"
          onInput={setDeliverySheetId}
        />
        <Input
          style={{ maxWidth: "250px", height: "40px" }}
          value={searchedDealId}
          label="N° de devis"
          onInput={setSearchedDealId}
        />
        <SelectOrganization
          setter={setOrganizationId}
          width={'300px'}
        />
      </div>

      {formatederpDeliverySheets
        ? (<>
          {formatederpDeliverySheets.length > 0 ? (
            <>
              <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                setCurrentPage={setCurrentPage}
              />
              <table className="beautiful-array">
                <thead>
                  <tr>
                    <th></th>
                    <th>Numéro</th>
                    <th>Numéro de devis</th>
                    <th>Deal</th>
                    <th>Entreprise</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {formatederpDeliverySheets.map((deliverySheet) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={deliverySheet.id}
                      onClick={(e) => showDeliverySheet(e, deliverySheet)}
                    >
                      <td>
                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT && deliverySheet.parameters?.version == 2 &&
                          <button
                            id="update-delivery-sheet"
                            className="light-button"
                            onClick={() => addPopupContent(
                              <CreateDeliverySheet
                                passedDeliverySheet={deliverySheet}
                                updateDL={updateDL}
                              />,
                              "1220px")}>
                            Modifier
                          </button>
                        }
                      </td>
                      <td>{deliverySheet.id}</td>
                      <td>{deliverySheet.deal_id}</td>
                      <td>{deliverySheet.deal_name}</td>
                      <td>{deliverySheet.organisation_name}</td>
                      <td>
                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                          <button
                            id="delete-delivery-sheet"
                            className="erp-close-button"
                            onClick={() => showDeleteDelivery(deliverySheet)}
                          >
                            &#x274C;
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <h5>Aucun bon de livraison</h5>
          )}
        </>
        ) : (
          <MidLoader />
        )
      }
    </>
  ) : (
    <h5>{error}</h5>
  )
}
