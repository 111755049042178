import { formatNumber } from 'Functions'
import { getConversionUnitFactor, priceParameters } from 'appConstante'
import React, { useEffect, useState } from 'react'
import set from 'lodash/set.js';
import Input from 'Components/FormElement/SmallInput';

export default function PriceList({ parameters, pricing, setterParameters }) {

    const [prixMO, setPrixMO] = useState(null)

    const [typeProductParameters, setTypeProductParameters] = useState(null)

    useEffect(() => {
        if (typeProductParameters) {
            const totalPriceOptions = typeProductParameters.options.reduce((total, option) => {
                return !option.ignore
                    ? total + computeOptionPrice(option)
                    : total
            }, 0)
            setPrixMO(totalPriceOptions)
        }
    }, [typeProductParameters, parameters])

    useEffect(() => {
        if (parameters?.article?.type_product) {
            const typeProductParameters = priceParameters.product_type.find(product_type => product_type.value === parameters.article.type_product)
            if (typeProductParameters) {
                setTypeProductParameters(typeProductParameters)
            }
        }
    }, [parameters?.article?.type_product])

    const updateTypeProductParametersKey = (keyPath, value) => {
        setTypeProductParameters(prv => {
            const parameters = prv
            set(parameters, keyPath, value);
            return { ...parameters };
        })
    }

    const computeOptionTime = (option) => {

        let time_start = option.temps_start

        // console.log(parameters);

        if (option.unit_parameters && option.unit_parameters.qty_resset) {
            const unit_per_qty = option.unit_parameters?.qty_per_unit
                ? parseFloat(option.unit_parameters.qty_per_unit)
                : parseFloat(parameters.article.quantity)
                
            const quantity = computeQuantityFromOption(option) * unit_per_qty

            console.log(option.label, quantity);

            time_start = time_start * (Math.floor((quantity - 1) / option.unit_parameters.qty_resset) + 1)
        }

        const time = option.tps_pour_1000
            ? ((((parseFloat(parameters.quantity) / 1000) * (option.tps_pour_1000)) + time_start) / 60)
            : (time_start / 60)
        return time
    }

    const computeOptionPrice = (option) => {
        if (option.unit_parameters) {

            const unit_per_qty = option.unit_parameters?.qty_per_unit
                ? parseFloat(option.unit_parameters.qty_per_unit)
                : parseFloat(parameters.article.quantity)

            const quantity = computeQuantityFromOption(option) * unit_per_qty

            let time = computeOptionTime(option)

            return (time * option.poste_price) + (option.coup_start * (Math.floor((quantity - 1) / option.unit_parameters.qty_resset) + 1))

        } else {
            return (computeOptionTime(option) * parseFloat(option.poste_price)) + option.coup_start
        }
    }

    const computeQuantityFromOption = (option) => {
        let conversion_factor_preparation = getConversionUnitFactor(
            parameters.article.unit_of_measure,
            option.unit_parameters.unit_of_measure
        )
        return parameters.quantity * conversion_factor_preparation * option.unit_parameters.quantity
    }

    function convertDecimalToHoursMinutes(decimalHours) {
        var hours = Math.floor(decimalHours);
        var minutes = Math.round((decimalHours - hours) * 60);
        return parseFloat(`${hours}.${minutes}`)
    }

    return (
        <div>
            {parameters?.article?.type_product && parameters?.quantity && parameters?.article?.quantity
                ? <>
                    <h4>Grille tarifaire</h4>
                    <div className='flex-start gap-1 m-t-25 m-b-25'>
                        {typeProductParameters
                            ? typeProductParameters.options.map((option, index) => {
                                if (!option.poste_price) {
                                    updateTypeProductParametersKey(`options[${index}].poste_price`, priceParameters.cout_par_poste[0].price)
                                }

                                const hour_time = computeOptionTime(option)
                                const price = computeOptionPrice(option)

                                return <div key={index} className='flex-1 price-matrix-option flex column' style={option.ignore ? { opacity: "0.6" } : {}}>
                                    <label className='flex-center flex-start'>
                                        <input
                                            checked={!option.ignore}
                                            onChange={e => updateTypeProductParametersKey(`options[${index}].ignore`, !option.ignore)}
                                            type="checkbox"
                                            className='erp-check'
                                        />
                                        {option.label}
                                    </label>
                                    <>
                                        {!option.external
                                            ? <select
                                                value={option.poste_price}
                                                onChange={e => updateTypeProductParametersKey(`options[${index}].poste_price`, parseFloat(e.target.value))}
                                                style={{ width: "140px" }}
                                            >
                                                {priceParameters.cout_par_poste
                                                    .map((poste, index) => <option key={index} value={poste.price}>{`${poste.name} (${poste.price}€/h)`}</option>)
                                                }
                                            </select>
                                            : <div>Etape effectuée par un prestataire externe</div>
                                        }
                                        <div className='flex-center flex-start gap-1'>
                                            {option?.unit_parameters?.qty_per_unit === "" || option?.unit_parameters?.qty_per_unit
                                                ? <Input label={"Quantité par unité"}
                                                    value={option.unit_parameters.qty_per_unit}
                                                    setter={updateTypeProductParametersKey}
                                                    customParameters={[`options[${index}].unit_parameters.qty_per_unit`]}
                                                />
                                                : null}
                                            {(option?.coup_start === "" || option?.coup_start) && option?.unit_parameters?.qty_per_unit
                                                ? <Input label={"prix par unité"}
                                                    value={option.coup_start}
                                                    setter={updateTypeProductParametersKey}
                                                    customParameters={[`options[${index}].coup_start`]}
                                                />
                                                : null}
                                        </div>
                                        {option?.tps_pour_1000 || option?.tps_pour_1000 === 0 || option?.tps_pour_1000 === ''
                                            ? <Input label={"temps pour 1000"}
                                                value={option.tps_pour_1000}
                                                setter={updateTypeProductParametersKey}
                                                customParameters={[`options[${index}].tps_pour_1000`]}
                                            />
                                            : null}
                                        <div className='flex-center m-t-10 strong-2'>
                                            <div>Durée estimée : </div>
                                            {/* <div>{formatNumber(hour_time)}h</div> */}
                                            <div>{`${convertDecimalToHoursMinutes(hour_time)}`.split(".")[0]}h{`${convertDecimalToHoursMinutes(hour_time)}`.split(".")[1]
                                                ? `${`${convertDecimalToHoursMinutes(hour_time)}`.split(".")[1]}min`
                                                : null}</div>
                                        </div>
                                        <div className='flex-center m-t-10 strong-2'>
                                            <div>Coût estimé : </div>
                                            <div>{formatNumber(price)}€</div>
                                        </div>
                                    </>
                                </div>
                            })
                            : null
                        }
                    </div>
                    {
                        prixMO !== null && pricing.materials_tot !== null && pricing.materials_unit !== null
                            ? <table className='full-width m-b-25'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='center'>Matières premières</th>
                                        <th className='center'>Main d'oeuvre</th>
                                        <th className='center'>Prix de production</th>
                                        <th className='center'>Prix de vente</th>
                                    </tr>
                                </thead>
                                <tbody className='center'>
                                    <tr>
                                        <td>Prix unitaire</td>
                                        <td>{formatNumber(pricing.materials_unit)}€</td>
                                        <td>{formatNumber(prixMO / parseFloat(parameters.quantity))}€</td>
                                        <td>{formatNumber((prixMO / parseFloat(parameters.quantity)) + pricing.materials_unit)}€</td>
                                        <td className='strong-2'>
                                            {formatNumber(((prixMO / parseFloat(parameters.quantity)) + pricing.materials_unit) * parseFloat(parameters.sale_marge))}€
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Prix total</td>
                                        <td>{formatNumber(pricing.materials_tot)}€</td>
                                        <td>{formatNumber(prixMO)}€</td>
                                        <td>{formatNumber((pricing.materials_tot + prixMO))}€</td>
                                        <td className='strong-2'>
                                            {formatNumber(((pricing.materials_tot + prixMO) * parseFloat(parameters.sale_marge)))}€
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            : null
                    }

                </>
                : null}
        </div>
    )
}
