import React from 'react'
import Commercial from './Commercial'
import ComptesClients from './ComptesClients/ComptesClients'
import Catalogue from './Catalogue/Catalogue'
import CategoriesDePrix from './CategoriesDePrix/CategoriesDePrix'
import ProduitsPersonnalises from './ProduitsPersonnalises/ProduitsPersonnalises'
import SendDeal from './EnvoiDevis/SendDeal'
import NewMatriceDePrix from './MatriceDePrix/NewMatriceDePrix'

export const CommercialRoutes = [
    {
        path: "/commercial", element: <Commercial />, subRoutes: [
            { path: "/commercial/matrice-de-prix", element: <NewMatriceDePrix /> },
            { path: "/commercial/comptes-clients", element: <ComptesClients /> },
            { path: "/commercial/categories-prix", element: <CategoriesDePrix /> },
            { path: "/commercial/catalogue", element: <Catalogue /> },
            { path: "/commercial/produits-personalisés", element: <ProduitsPersonnalises /> },
            { path: "/commercial/envoi-devis", element: <SendDeal /> },
            { path: "/commercial/envoi-devis/:id", element: <SendDeal /> },
        ]
    }
]