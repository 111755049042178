import React, { useContext, useEffect, useState } from 'react'
import './AddCertificatePDF.css'
import { formatFormDataFromObject, useCallAnalysesApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function AddCertifactePDF({ analyse, setter }) {

    /**
     1) Uploader avec l'inut un fichier PDF
     2) Déclencher automatiquement un appel API avec le fichier à un nouveau Endpoint "upload-file"
        -> params (args): (files), prefix_name ("numéro de référence interne"), path ("analyses")
        -> Sécurité: la classique avec Microsft
        -> Objectif : prendre le fichier, le ranger dans le path avec le nom avec le préfix et retourner l'URL (si succès)
     3) Récupérer l'URL et le foutre dans le setter de analyses dans la propriété (certificate_urls)
        -> Attention certificate URL est un tableau de string (d'url)
     */

    const callAnalysesApi = useCallAnalysesApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [pdfFile, setPdfFile] = useState(null)

    const handleFileChange = (e) => {
        setPdfFile(e.target.files[0])
    }

    useEffect(() => {
        if (pdfFile && pdfFile.name && pdfFile.size) {
            sendCertificateFile()
        }
    }, [pdfFile])

    const sendCertificateFile = () => {
        const controller = new AbortController()

        const formatedDataFile = {
            files: pdfFile,
            prefix_name: analyse.num_reference,
            path: `analyses/${analyse.num_reference}/`
        }
        addNotificationContent({
            title: "Récupération du fichier",
            content: <MiniLoader />,
            infinit: true,
        })
        callAnalysesApi('upload-file', { method: "POST", signal: controller.signal }, formatFormDataFromObject(formatedDataFile))
            .then(res => {
                setter(res.data.file_url, 'certificate_urls')
                addNotificationContent({
                    content: "Fichier récupéré avec succès",
                    infinit: false,
                })
            })
            .catch(error => {
                console.log(error);
                addNotificationContent({
                    content: error.response.message ?? "Erreur lors de la récupération de votre fichier",
                    infinit: false,
                    error: true
                })
            })
    }

    return (
        <form className='flex column center m-b-10'>
            <input
                id='pdfInput'
                type="file"
                accept=".pdf"
                onChange={e => handleFileChange(e)}
            />
            <div className='flex-center'>
                <button className='light-button m-t-10 m-b-10' type='button' onClick={() => document.getElementById('pdfInput').click()}>
                    Charger un certificat (.pdf)
                </button>
            </div>
            {analyse.certificate_urls[analyse.certificate_urls.length - 1]
                ? <>
                    {pdfFile?.name
                        ? <div>Nom du fichier : {pdfFile.name}</div>
                        : null}
                    <iframe src={analyse.certificate_urls[analyse.certificate_urls.length - 1]} width="100%" height="700"></iframe>
                </>
                : null}
        </form>
    )
}
