import React, { createContext, useState, useEffect, useContext } from "react"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { NotificationContext } from "Context/NotificationContext"

export const DealProductsContext = createContext()
export default function DealProductsContextProvider(props) {
    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)

    const putDealProduct = (dealProduct) => {
        return new Promise((resolve, reject) => {
            // addNotificationContent({
            //     title: "Mise à jour...",
            //     content: <MiniLoader />,
            //     infinit: true,
            // })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${dealProduct.id}`, { method: 'put' }, null, dealProduct)
                .then(res => {
                    // addNotificationContent({
                    //     title: "Succès",
                    //     content: "Mise à jour éffectuée",
                    // })
                    resolve(res.data.data)
                })
                .catch((error) => {
                    console.log(error)
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: "Une erreur s'est produite",
                    })
                    reject(error?.response?.data)
                })
        }
        )
    }
    const getDealProduct = (id) => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${id}`, { method: 'get' })
                .then(res => {
                    resolve(res.data.data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error?.response?.data)
                })
        })
    }

    return (
        <DealProductsContext.Provider value={{ putDealProduct, getDealProduct }}>
            {props.children}
        </DealProductsContext.Provider>
    )
}
