import React, { useState, useEffect, useContext } from 'react'
import SelectPriceCategory from '../../PriceCategory/SelectPriceCategory'
import './validePackaging.css'
import { PopupContext } from 'Context/PopupContext'

export default function ValidePackagingParameters({ catalogue, packaging, updateCurrentPackaging, deleteCurrentPackaging, index }) {
  const updatePackaging = (key, value) => {
    updateCurrentPackaging(index, key, value)
  }

  const UpdatePriceCategoryId = (id) => {
    updatePackaging("price_category_id", id)
  }

  const [gabaritSRC, setGabaritSRC] = useState(packaging.template_url)

  const renderInput = (file) => {
    var reader = new FileReader(file);
    reader.onload = function (e) {
      setGabaritSRC(e.target.result)
      updateCurrentPackaging(index, "template_url", e.target.result)
      updateCurrentPackaging(index, "file", file)
    }
    reader.readAsDataURL(file); // convert to base64 string
  }

  return (
    <div className='valide-packaging'>
      {!catalogue && (
        <div className='m-b-10'>
          <label >
            <input type="checkbox" checked={packaging.use_reference ? true : false} onChange={() => updatePackaging('use_reference', packaging.use_reference ? 0 : 1)} />
            <span> Conserver le packaging du produit parent par défaut (auto update)</span>
          </label>
        </div>
      )}
      <div style={{ opacity: (!catalogue && packaging.use_reference) ? 0.6 : 1 }}>
        <div className='flex input'>
          <label>Type: </label>
          <select value={packaging.type} onChange={e => updatePackaging('type', e.target.value)} required>
            <option hidden value=''>-- Type de packaging --</option>
            <option value='Boîte'>Boîte</option>
            <option value='Etiquette'>Etiquette</option>
            <option value='Doypack'>Doypack</option>
          </select>
          <div onClick={() => deleteCurrentPackaging(index)} className="delete-packaging">✕</div>
        </div>
        <div className='column'>
          <label>Prix: </label>
          <div className='flex input'>
            <input style={{ width: "100px" }} required type='number' step='0.01' min='0' placeholder='Prix maximum' value={packaging.max_price} onInput={e => updatePackaging("max_price", e.target.value)} />
            <input style={{ width: "100px" }} required type='number' step='0.01' min='0' placeholder='Prix minimum' value={packaging.min_price} onInput={e => updatePackaging("min_price", e.target.value)} />
            <SelectPriceCategory defaultValue={packaging.price_category_id} setter={UpdatePriceCategoryId} />
          </div>
        </div>
        <div className='column'>
          <label>Quantité: </label>
          <div className='flex input'>
            <input style={{ width: "100px" }} required type='number' step='1' min='0' placeholder='Quantité minimum' value={packaging.min_qty} onInput={e => updatePackaging("min_qty", e.target.value)} />
            <input style={{ width: "100px" }} required type='number' step='1' min='0' placeholder='Quantité pour atteindre le prix minimum' value={packaging.min_price_qty} onInput={e => updatePackaging("min_price_qty", e.target.value)} />
          </div>
        </div>
        <div>
          <label>Dimensions: </label>
          <div className='flex'>
            <div className='input'>
              <input style={{ width: "100px" }} required type='number' step='0.1' min='0' placeholder='Hauteur (mm)' value={packaging.height} onInput={e => updatePackaging("height", e.target.value)} />
            </div>
            <div className='input'>
              <input style={{ width: "100px" }} required type='number' step='0.1' min='0' placeholder='Largeur (mm)' value={packaging.width} onInput={e => updatePackaging("width", e.target.value)} />
            </div>
            {packaging.type != 'Etiquette' && (
              <div className='input'>
                <input style={{ width: "100px" }} required ype='number' step='0.1' min='0' placeholder='Profondeur (mm)' value={packaging.depth} onInput={e => updatePackaging("depth", e.target.value)} />
              </div>
            )}
          </div>
        </div>
        <div className='flex'>
          <div className='column half-m-width'>
            <label>Description (clients):  </label>
            <textarea placeholder='Pélliculagen vernissage, type de carton...' value={packaging.description_customer ?? ""} onInput={e => updatePackaging('description_customer', e.target.value)} />
          </div>
          <div className='column half-m-width'>
            <label>Description (commerciaux):  </label>
            <textarea placeholder="Lien d'achat, diamètre du mandrin, sens d'enroulement..." value={packaging.description_admin ?? ""} onInput={e => updatePackaging('description_admin', e.target.value)} />
          </div>
        </div>
        <div className="gabarit-picture">
          {packaging.template_url && (
            <embed style={{ borderRadius: "5px", height: '750px', width: '100%' }} src={packaging.template_url} />
          )}
          <label className="add-gabary-button">
            <input type="file" accept=".pdf"
              value={""}
              onChange={(e) => { renderInput(e.target.files[0]); updatePackaging("gabarit", e.target.files[0]); }} />
            {!packaging.template_url ? ("Ajouter un gabarit") : ("changer le gabarit")}
          </label>
        </div>
      </div>
    </div>
  )
}
