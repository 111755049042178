import React, { createContext, useState, useEffect, ReactNode } from "react"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"

export interface ProductSpecificationInterface {
    id: number
    recipe: {
        bases: [any],
        cannabinoids: [any],
        other_ingredients: [any]
    },
    recipe_id: number | string | null,
    conditioning: {
        primary: [any],
        secondary: [any]
    }
    dataType: "raw" | "advanced";
    article: any,
    article_id: Number,
}
interface ProductSpecificationContextProps {
    getProductSpecification: (id: number | null) => Promise<any>,
    productSpecifications: [ProductSpecificationInterface] | null,
    addProdcutSpecification: (newProductSpecification: ProductSpecificationInterface) => void,
}

export const ProductSpecificationContext = createContext<ProductSpecificationContextProps | undefined>(undefined);

export default function ProductSpecificationContextProvider(props: { children: ReactNode }) {
    const callApi = useCallApi()
    const [productSpecifications, setProductSpecifications] = useState<[ProductSpecificationInterface] | null>(null)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        const controller = new AbortController();
        setProductSpecifications(_ => null)
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/products-specifications`, { method: 'get' }, controller.signal, { limit: 0 })
            .then(res => {
                res.data?.data && setProductSpecifications(_ => res.data.data.map(ps => ({ ...ps, dataType: 'raw' })))
            })
            .catch(err => {
                console.log(err);
            })
        return () => {
            controller.abort()
        }
    }, [reload])

    const getProductSpecification = (id: number | null, dataType: string = 'advanced') => {
        return new Promise((resolve, reject) => {
            if (!id) {
                resolve(null)
            } else {
                const productSpe = productSpecifications?.find((ps: ProductSpecificationInterface) => ps.id == id)
                if (productSpe && productSpe.dataType === dataType) {
                    resolve(productSpe)
                } else {
                    callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification/${id}`, { method: 'get' })
                        .then(res => {
                            setProductSpecifications((productSpecifications: [ProductSpecificationInterface] | null) => {
                                return productSpecifications
                                    ? productSpecifications.map((ps: ProductSpecificationInterface) => (ps.id === id ? res.data.data : ps))
                                    : null;
                            });
                            resolve(res.data.data)
                        })
                        .catch(error => {
                            console.log(`erreur récupération /product-specification/${id}`, error)
                            reject(error)
                        })
                }
            }
        })
    }
    const addProdcutSpecification = (newProductSpecification: ProductSpecification) => {
        setProductSpecifications(prevPSs => [...prevPSs, newProductSpecification])
    }

    const reloadProductSpecification = () => setReload(prvPS => !prvPS)

    return (
        <ProductSpecificationContext.Provider value={{ productSpecifications, getProductSpecification, addProdcutSpecification, reloadProductSpecification }}>
            {props.children}
        </ProductSpecificationContext.Provider>

    )
}
