import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCurrentAppPermission } from 'Functions'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import { AnalysesContext } from 'Context/Analyse/AnalysesContext'
import { BulkContext } from 'Context/CBDProtect/Bulk'
import { Link } from 'react-router-dom'
import Input from 'Components/FormElement/Input'
import MidLoader from 'Components/MidLoader/MidLoader'
import CreateBulkBtn from 'Components/_CBDP/Bulk/CreateBulkBtn'
import UpdateBulk from 'Components/_CBDP/Bulk/UpdateBulk'
import Pagination from 'Components/Pagination/Pagination'

export default function Bulks() {

    const currentAppPermission = useCurrentAppPermission()
    const { addPopupContent } = useContext(PopupContext)
    const { getAnalyses } = useContext(AnalysesContext)
    const { getBulksV2, getBulk } = useContext(BulkContext)
    const [bulks, setBulks] = useState(null)
    const [formatedBulks, setFormatedBulks] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [searchBulkId, setSearchBulkId] = useState('')
    const setTimeoutId = useRef()

    const addAnalysesToBulks = async () => {
        let analyseIds = []

        bulks.forEach(bulk => {
            bulk.analyses.forEach(analyseId => analyseIds.push(analyseId))
        })

        const analyses = await getAnalyses({ ids: JSON.stringify(analyseIds) })
        const bulkWithAnalyses = bulks.map(bulk => {
            const currentBulkAnalyses = analyses.filter(analyse => parseInt(analyse.bulk_id) === parseInt(bulk.ID))
            return { ...bulk, analyses_datas: currentBulkAnalyses }
        })

        setFormatedBulks(bulkWithAnalyses)
    }

    useEffect(() => {
        setBulks(null)
        setFormatedBulks(null)
        const controller = new AbortController()

        clearTimeout(setTimeoutId.current)
        setTimeoutId.current = setTimeout(async () => {

            if (searchBulkId) {
                getBulk(searchBulkId)
                    .then(async bulk => {
                        setBulks(Array.isArray(bulk) ? bulk : [bulk])
                        setTotalPage(1)
                        setCurrentPage(1)
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                            setBulks([])
                        }
                        console.log(error)
                    })
            } else {
                getBulksV2(currentPage, controller)
                    .then(async res => {
                        setBulks(res.data)
                        setTotalPage(res.total_pages)
                        res.total_pages === 0 && setCurrentPage(1)
                        currentPage > res.total_pages && res.total_pages > 0 && setCurrentPage(res.total_pages)
                    })
            }

        }, searchBulkId ? 500 : 0)

        return () => {
            controller.abort()
        }
    }, [currentPage, searchBulkId])

    useEffect(() => {
        if (bulks) {
            addAnalysesToBulks()
        }
    }, [bulks])

    const addCreatedBulk = (newBulk) => {
        console.log(newBulk);
        setBulks(prvBulks => [newBulk, ...prvBulks])
    }

    return (
        <div>
            <CreateBulkBtn setter={addCreatedBulk} />
            <div className='m-b-10'>
                <Input
                    label={'N° de bulk'}
                    value={searchBulkId}
                    onInput={setSearchBulkId}
                    style={{ width: '300px' }}
                    type='number'
                />
            </div>
            <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPage={totalPage}
            />
            {
                formatedBulks
                    ? formatedBulks.length
                        ? <table className='beautiful-array'>
                            <thead>
                                <tr>
                                    {currentAppPermission('cbd-protect') > appConstante.parameters.CAN_READ &&
                                        <th></th>
                                    }
                                    <th></th>
                                    <th>N° Bulk</th>
                                    <th>Type de produit</th>
                                    <th>Date de création</th>
                                    <th>DDM</th>
                                    <th>Nombre d'analyses</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formatedBulks
                                    .map((bulk, index) => {
                                        return (
                                            <tr key={index} className={bulk.analyses.length > 0 ? '' : 'not-analized'}>
                                                {currentAppPermission('cbd-protect') > appConstante.parameters.CAN_READ &&
                                                    <td>
                                                        <button
                                                            className='light-button'
                                                            onClick={() => addPopupContent(<UpdateBulk bulk={bulk} setter={setFormatedBulks} />, "800px")}
                                                        >
                                                            Modifier
                                                        </button>
                                                    </td>
                                                }
                                                {bulk.analyses_datas && bulk.analyses_datas.length
                                                    ?
                                                    // bulk.analyses_datas.map(analyse => {
                                                    //     return (
                                                    <td>
                                                        <Link
                                                            to={bulk.analyses_datas[0].certificate_url}
                                                            target='_blank'
                                                        >
                                                            Résultats PDF
                                                        </Link>
                                                    </td>
                                                    //     )
                                                    // })
                                                    : <td>non analysé</td>}
                                                <td>{bulk.ID}</td>
                                                <td>{bulk.product}</td>
                                                <td>{new Date(bulk.created_date).toLocaleDateString()}</td>
                                                <td>{new Date(bulk.ddm).toLocaleDateString('fr-FR', { month: '2-digit', year: 'numeric' })}</td>
                                                <td>{bulk.analyses && bulk.analyses.length}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        : <h5>Aucun bulk {`${searchBulkId ? 'correspondant à votre recherche' : 'à afficher'}`}</h5>
                    : <MidLoader />
            }
        </div >
    )
}
