import React, { useEffect } from 'react'
import { useNavigate, Outlet, useLocation } from "react-router-dom"
import NavBar from 'Components/NavBar/NavBar';
import { appConstante } from 'appConstante';
import { useCurrentAppPermission } from 'Functions';

export default function Home() {
    const currentAppPermission = useCurrentAppPermission()
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        location.pathname === '/erp' && navigate("/erp/stock"); //Sous onglet par défaut
    }, [location.pathname])

    return (
        <>
            <div className='flex'>
                <NavBar menu={
                    [
                        { to: "/erp/stock", name: "Gestion des stocks" },
                        { to: "/erp/production", name: "Gestion de la production" },
                        { to: "/erp/specif-and-recipe", name: "Spécifications et Recettes" },
                        { to: "/erp/parametre-pipedrive", name: "Paramétrage Pipedrive" },
                    ]
                } />
                {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                    <img className='pointer'
                        height='30px' width='30px'
                        src={'/images/parameters.png'}
                        alt="Parameters"
                        onClick={() => navigate("/erp/parameters")}
                    />
                }
            </div>
            <Outlet />
        </>
    )
}