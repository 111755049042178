import React, { useEffect } from 'react'
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar'
import { useNavigate, useLocation } from 'react-router-dom'

export default function Commercial() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    location.pathname === "/commercial" && navigate("/commercial/catalogue") //Sous onglet par défaut
  }, [location.pathname])

  return (
    <>
      <SubNavBar menu={[
        { path: "/commercial/matrice-de-prix", name: "Matrice de prix" },
        { path: "/commercial/categories-prix", name: "Catégories de prix" },
        { path: "/commercial/comptes-clients", name: "Comptes clients" },
        { path: "/commercial/catalogue", name: "Catalogue" },
        { path: "/commercial/produits-personalisés", name: "Produits Personalisés" },
        { path: "/commercial/envoi-devis", name: "Envoi devis" },
      ]} />
    </>
  )
}
