import React, { createContext, useState, useEffect, useContext } from "react"
import { useCallPipedriveApi } from "Functions"
import { DependenciesContext } from "Context/DependenciesContext"

export const OrganizationsContext = createContext()

function OrganizationsContextProvider(props) {
  const callPipedriveApi = useCallPipedriveApi()
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    const controller = new AbortController()

    getOrganizations(true, controller.signal)

    return () => {
      controller.abort()
    }
  }, [])

  const getOrganizations = async (cache = true, signal = null) => {
    let allOrganizationsLoaded = false
    let start = 0
    const formatedorganizations = []
    const resultPerPage = 500
    setOrganizations([])
    while (!allOrganizationsLoaded) {
      const res = await callPipedriveApi(
        "organizations",
        { method: "get", signal: signal },
        {
          limit: resultPerPage,
          start: start * resultPerPage,
          sort: "name ASC",
        },
        cache
      )
      res.data.data &&
        formatedorganizations.push(
          ...res.data.data.map((organization) => ({
            ...organization,
            id: organization.id,
            value: organization.name,
          }))
        )
      if (res.data.additional_data.pagination.more_items_in_collection) {
        start++
      } else {
        allOrganizationsLoaded = true
      }
    }
    setOrganizations(formatedorganizations)
  }

  const getOrganizationFromId = (id, cache = true) =>
    new Promise((resolve, reject) => {
      let organizationsFiltered = false
      if (cache) {
        organizationsFiltered = organizations.find(
          (organization) => organization.id === id
        )
      }
      if (organizationsFiltered) {
        resolve(organizationsFiltered)
      } else {
        callPipedriveApi(`organizations/${id}`, { method: 'get' }, null, cache)
          .then((res) => resolve(res.data.data))
          .catch((error) => resolve(null))
      }
    })

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        getOrganizationFromId,
        getOrganizations
      }}
    >
      {props.children}
    </OrganizationsContext.Provider>
  )
}

export default OrganizationsContextProvider
