import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { useCallApi } from 'Functions'
import { appConstante, categories } from 'appConstante'
import React, { useState, useContext } from 'react'

export default function ExportArticles({setLoadingExport}) {

    const callApi = useCallApi()

    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)

    const [categoryToExport, setCategoryToExport] = useState('')


    const exportArticles = () => {
        addNotificationContent({
            succes: true,
            title: "Export pris en compte",
            content: "Vous pouvez continuer à naviguer sur le site, votre fichier se téléchargera prochainement"
        })
        setLoadingExport(true)
        // Créez une nouvelle instance EventSource pour écouter les mises à jour de progression.

        // getToken()
        //     .then(token => {
        //         const eventSource = new EventSource(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/export`, {
        //             headers: {
        //                 Authorization: token
        //             }
        //         });

        //         eventSource.onmessage = (event) => {
        //             console.log(event);
        //             // const progress = parseFloat(event.data);
        //             // // Mettez à jour votre barre de progression sur le frontend avec la valeur de 'progress'.
        //             // // Par exemple, vous pouvez utiliser cette valeur pour mettre à jour une barre de progression HTML.
        //             // updateProgressBar(progress);
        //         };
        //     })

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/export`,
            {
                method: 'GET', responseType: "blob",
                headers: {
                    'Content-Type': 'text/csv; charset=utf-8'
                }
            }
            , null, { category: categoryToExport })
            .then(response => {
                setLoadingExport(false)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${categoryToExport}-${new Date().toLocaleDateString('fr-FR')}.csv`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                setLoadingExport(false)
                console.log(error);
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: `${error.response.message} Nous avons rencontré une erreur lors de l'export`
                })
            })
    }

    return (
        <div className='flex-center'>
            <p>Sélectionner une catégorie à exporter :</p>
            <select
                value={categoryToExport}
                onChange={e => setCategoryToExport(_ => e.target.value)}
                className='m-l-10'
            >
                <option value={''}>Toute les catégories</option>
                {categories.map(category => (
                    <option value={category.name} key={category.id}>{category.name}</option>
                ))}
            </select>
            <button
                className='strong-button'
                onClick={() => { exportArticles(); showPopup(false) }}>
                Exporter
            </button>
        </div>
    )
}
