import React, { useEffect } from 'react'
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar'
import { useNavigate, useLocation } from 'react-router-dom'

export default function Analyses() {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        location.pathname === "/analyses" && navigate("/analyses/product-reception") //Sous onglet par défaut
    }, [location.pathname])

    return (
        <>
            <SubNavBar menu={[
                { path: "/analyses/comptes-clients", name: "Comptes clients" },
                { path: "/analyses/deals", name: "Devis d'analyses" },
                { path: "/analyses/product-reception", name: "Réception produit" },
                { path: "/analyses/bulk", name: "Bulk" },
                { path: "/analyses/matieres-premieres", name: "Matières premières" },
                { path: "/analyses/analyses", name: "Analyses" },
                { path: "/analyses/resultats-mails", name: "Résultats - mails" },
                { path: "/analyses/recherche", name: "Recherche" },
            ]} />
        </>
    )
}
