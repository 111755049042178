import React, { useContext } from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from 'Context/NotificationContext'
import { useCallApi, utf8decode } from 'Functions'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { appConstante } from 'appConstante'

export default function DeleteFile({ file, deleteFile }) {

    const { showPopup, showPopup2 } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()

    const removeFile = () => {
        addNotificationContent({
            title: 'Suppression en cours',
            content: <MiniLoader />,
        })
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/file/${file.id}`, { method: 'delete' })
            .then(res => {
                deleteFile(file.id)
                showPopup(false)
                showPopup2(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "Le fichier a été supprimée",
                    infinit: false
                })
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.res.message,
                    infinit: false
                })
            })
    }
    return (
        <>
            <h2>Supprimer le fichier {(utf8decode(file.url.split('/')[file.url.split('/').length - 1]))}</h2>
            <p>Attention, cette suppression est irréversible. Les données associés ne serons plus accessibles</p>
            <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
                <button
                    type="button"
                    onClick={() => showPopup(false)}
                    className="erp-button erp-big-button"
                >
                    Annuler
                </button>
                <button
                    className="erp-button erp-big-button error-strong-button"
                    style={{ display: "block", margin: "auto" }}
                    onClick={() => removeFile()}
                >
                    Supprimer
                </button>
            </div>
        </>
    )
}
