import React, { useContext, useState } from 'react'
import { NotificationContext } from 'Context/NotificationContext'
import { BatchContext } from 'Context/CBDProtect/Batch'
import { formatDateToInput } from 'Functions'
import Input from 'Components/FormElement/Input'
import SelectBulk from '../Bulk/SelectBulk'
import SelectArticles from '../../_ERP/SelectArticles'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import Select from 'Components/FormElement/Select'
import { PopupContext } from 'Context/PopupContext'

export default function CreateBatch({ defaultArticleId, setter }) {
    const { getArticle } = useContext(ArticleContext)
    const { CBDPpostBatch } = useContext(BatchContext)
    const { postBatch } = useContext(ArticleContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)

    const [selectedArticle, setSelectedArticle] = useState(null)
    const [productId, setProductId] = useState(null)

    const [batch, setBatch] = useState({
        'bulk-id': '',
        'article-id': '',
        'packaging-date': formatDateToInput(new Date()),
        'produced-quantity': '',
        'cbdp': 0,
    })

    const updateBatch = (key, value) => setBatch(prvBatch => ({ ...prvBatch, [key]: value }))

    const selectArticle = (articleId) => {
        updateBatch("article-id", articleId)
        articleId
            ? getArticle(articleId)
                .then(article => {
                    setSelectedArticle(article)
                    setProductId(article?.products[0] && article.products[0].id)
                })
            : setSelectedArticle(null)
    }

    const createBatch = (e) => {
        e.preventDefault()
        CBDPpostBatch(batch)
            .then(async batchRes => {
                await postBatch({ batch_number: batchRes.visible_id, product_id: productId })
                setter(batchRes)
            })
            .catch(err => {
                console.log(err)
                addNotificationContent({
                    error: true,
                    content: "Erreur lors de la création du batch"
                })
            })
    }

    return (
        <form
            className='column gap-1'
            onSubmit={e => createBatch(e)}
        >
            <h3>Création d'un n° de lot</h3>
            <SelectBulk
                onSelect={updateBatch}
                customParameters={['bulk-id']}
                width='1000px'
            />
            <SelectArticles
                onSelect={selectArticle}
                defaultId={defaultArticleId}
                category={"Produit fini"}
                width='1000px'
            />
            {selectedArticle
                ? <div className='flex end'>
                    <Select label='Fournisseur' required={true}
                        value={productId} onChange={setProductId}
                    >
                        {selectedArticle.products.map(supplier => <option key={supplier.id} value={supplier.id}>{supplier.supplier}</option>)}
                    </Select>
                </div>
                : null}
            <div className='flex-center gap-1'>
                <div>
                    <Input
                        label='Quantité cible'
                        type='number'
                        value={batch['produced-quantity']}
                        onInput={updateBatch}
                        customParameters={['produced-quantity']}
                        step={1}
                    />
                </div>
                <label className='flex-center gap-1 m-t-25'>
                    <span>À référencer sur le site</span>
                    <input type='checkbox' className='erp-check' checked={batch.cbdp} onChange={e => updateBatch('cbdp', e.target.checked === true ? 1 : 0)} />
                </label>
            </div>
            <button disabled={!selectedArticle} className='strong-button m-t-25'>
                Créer le n° de lot
            </button>
        </form >
    )
}
