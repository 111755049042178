import React, { useState, useEffect, useContext } from 'react'
import { removeDuplicates, useCallMSLApi } from "Functions"
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { MslAccessContext } from 'Context/Commercial/MslAccessContext'
import LastNavBar from 'Components/NavBar/LastNavBar/LastNavBar'
import MidLoader from 'Components/MidLoader/MidLoader'
import ProductThumbnail from 'Components/_COMMERCIAL/Product/ProductThumbnail'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { CatalogueContext } from 'Context/Commercial/CatalogueContext'
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'
import Datalist from 'Components/Dataliste/Dataliste'
import AddProduct from 'Components/_COMMERCIAL/Product/AddProduct'
import { CustomProductsContext } from 'Context/Commercial/CustomProductsContext'


export default function ProduitsPersonnalises() {

    const { addPopupContent } = useContext(PopupContext)

    const [avaibleCategories, setAvaibleCategories] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null)

    const { catalogueProducts } = useContext(CatalogueContext)
    const {
        customProducts,
        draft,
        setDraft,
        organizationId,
        setOrganizationId,
        productReferenceId,
        setProductReferenceId,
    } = useContext(CustomProductsContext)
    const { organizations } = useContext(OrganizationsContext)


    useEffect(() => {
        if (customProducts) {
            setAvaibleCategories(
                removeDuplicates(
                    customProducts
                        .map(product => product.category)
                        .sort()
                )
            )
        }
    }, [customProducts])

    const addProduct = () => {
        addPopupContent(
            <AddProduct catalogue={false} />,
            '1200px'
        )
    }

    return (
        <>
            <button onClick={() => addProduct()} className='strong-button m-l-0' type="button">Ajouter un produit personnalisé</button>
            <div className='flex-basis m-b-25'>
                <div className='flex-center'>
                    {organizations ? (
                        <div className='flex-center input half-m-width'>
                            <Datalist
                                placeholder="Organisation"
                                width='200px'
                                items={organizations}
                                onSelect={setOrganizationId}
                                defaultId={organizationId}
                            />
                        </div>
                    ) : <MiniLoader />}
                    {catalogueProducts ? (
                        <div className='flex-center input half-m-width'>
                            <Datalist
                                placeholder="produit du catalogue"
                                width='250px'
                                items={catalogueProducts}
                                onSelect={setProductReferenceId}
                                defaultId={productReferenceId}
                            />
                        </div>
                    ) : <MiniLoader />}
                </div>
                <div className='input'>
                    <label className='flex-center gap-1'>
                        <input type="checkbox" className='erp-check' checked={draft} onChange={(e) => setDraft(e.target.checked)} />
                        <span>Afficher les produits en brouillon</span>
                    </label>
                </div>
            </div>
            {!customProducts
                ? <MidLoader />
                : <>
                    {avaibleCategories
                        ? avaibleCategories.length > 0
                            ? <LastNavBar
                                items={
                                    [
                                        { label: 'Tous', active: true, onClick: setCurrentCategory, value: null },
                                        ...avaibleCategories.map((category, index) => ({
                                            label: category, active: false, onClick: setCurrentCategory, value: category
                                        }))
                                    ]
                                }
                            />
                            : <h5>Aucun produit personnalisé</h5>
                        : <MiniLoader />
                    }
                    <div className='flex flex center flex-start wrap gap-2'>
                        {customProducts
                            .filter(product => currentCategory ? product.category === currentCategory : true)
                            .filter(product => organizationId ? product.organization_id === organizationId : true)
                            .filter(product => productReferenceId ? product.reference_id === productReferenceId : true)
                            .map(product =>
                                <React.Fragment key={product.id}>
                                    <ProductThumbnail product={product} />
                                </React.Fragment>
                            )}
                    </div>
                </>
            }
        </>
    )
}
