import React, { useState, useEffect, useContext } from 'react'
import Input from 'Components/FormElement/Input';
import { useCallApi } from 'Functions';
import { appConstante } from 'appConstante';
import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import { PopupContext } from 'Context/PopupContext';
import { NotificationContext } from 'Context/NotificationContext'
import './AddOrder.css';
import DeleteButton from 'Components/DeleteButton/DeleteButton';

export default function AddOrder({ selectedCategory, addNewOrder }) {

    const [order, setOrder] = useState({})
    const [suppliers, setSuppliers] = useState(null)
    const [products, setProducts] = useState(null)

    const [selectedProducts, setSelectedProducts] = useState([])

    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()

    useEffect(() => {
        const controller = new AbortController();
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/suppliers`, { method: 'get' }, controller.signal, { category: selectedCategory })
            .then(res => setSuppliers(res.data.data))
            .catch(error => console.log(error))

        return () => {
            controller.abort()
        }
    }, [selectedCategory])


    useEffect(() => {
        const controller = new AbortController();
        if (order.supplier) {
            setProducts(null)
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/supplier-products`, { method: 'get' }, controller.signal, {
                supplier: order.supplier,
            })
                .then(res => {
                    setProducts(res.data.data)
                })
                .catch(err => {
                    console.log({ err });
                })
        }

        return () => {
            controller.abort()
        }
    }, [order.supplier])

    const addProductInOrder = () => {
        setSelectedProducts([...selectedProducts, { product_id: '', price: '', quantity: '' }])
    }

    const deteleteOrderProduct = indexProduct => {
        setSelectedProducts([...selectedProducts.filter((product, index) => index !== indexProduct)])
    }


    const updateOrderKey = (key, value) => {
        setOrder({ ...order, [key]: value })
    }

    const updateOrderProduct = (indexProduct, key, value) => {
        setSelectedProducts(selectedProducts.map((product, index) =>
            indexProduct === index
                ? { ...product, [key]: value }
                : product
        ))
    }

    useEffect(() => {
        setOrder({ ...order, delivery_date: null, date: null })
        if (selectedProducts[0] && selectedProducts[0].product_id) {
            const today = new Date();
            const product = products.find(product => product.id == selectedProducts[0].product_id)
            if (product) {
                const weeksLater = new Date(today.getTime() + (product.restocking_time * 7 * 24 * 60 * 60 * 1000));
                setOrder({ ...order, delivery_date: weeksLater.toISOString().slice(0, 10), date: today.toISOString().slice(0, 10) })
            }
        }
    }, [selectedProducts[0] && selectedProducts[0].product_id])

    const sendOrder = (e) => {
        e.preventDefault();
        addNotificationContent({
            title: 'Ajout en cours',
            content: <MiniLoader />,
        })
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-history/`, { method: 'post' }, null, { ...order, type: "Manuel", products: selectedProducts }, e)
            .then(res => {
                addNewOrder(res.data.data)
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "Commande ajoutée",
                    infinit: false
                })
            }).catch((err) => {
                console.log(err);
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })

            });

        return () => {
            controller.abort()
        }

    }
    return (
        <>
            <h2>Ajouter une commande</h2>
            <form onSubmit={e => sendOrder(e)}>
                <div className='flex'>
                    <div className='half-m-width'>
                        <Datalist
                            onSelect={updateOrderKey}
                            items={suppliers && suppliers.map(supplier => ({ id: supplier.supplier, value: supplier.supplier }))}
                            placeholder='Fournisseurs'
                            customParameters={["supplier"]}
                            required={true}
                            className='half-m-width'
                        />

                        {order.supplier ?
                            products ? selectedProducts.map((product, index) => {
                                const currenProduct = products.find(productFromMap => productFromMap.id === product.product_id)
                                return (
                                    <div className='flex-center' key={index}>
                                        <DeleteButton
                                            alwaysDisplay={true}
                                            callBack={deteleteOrderProduct}
                                            parameters={[index]}
                                        />
                                        <div className='order-product'>
                                            <Datalist
                                                onSelect={updateOrderProduct}
                                                items={products.map(product => ({ id: product.id, value: product.article ? product.article.name : "" }))}
                                                placeholder='Produits'
                                                customParameters={[index, "product_id"]}
                                                required={true}
                                                className='half-m-width'
                                                value={currenProduct ? currenProduct.article.name : ''}
                                            />

                                            <div className='flex-center'>
                                                <Input type='number' min='0' step='0.0001' value={product.quantity}
                                                    onInput={updateOrderProduct} customParameters={[index, 'quantity']}
                                                    label={`Quantité`} required={true}
                                                />
                                                <Input type='number' min='0' step='0.0001' value={product.price}
                                                    onInput={updateOrderProduct} label='Prix (€)' customParameters={[index, "price"]} required={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            ) : <MiniLoader />
                            : null
                        }

                        {order.supplier && <button type='button' onClick={() => addProductInOrder()} className='light-button center-block m-t-25'>Ajouter un produit à la commande</button>}
                    </div>
                    <div className='half-m-width'>

                        <Input type='date' value={selectedProducts[0] && selectedProducts[0].product_id ? order.date : ""}
                            onInput={updateOrderKey}
                            label="Date de la commande" customParameters={['date']}
                            required={true}
                        />
                        <Input type='date' value={selectedProducts[0] && selectedProducts[0].product_id ? order.delivery_date : ""}
                            onInput={updateOrderKey}
                            label="Date de livraison" customParameters={['delivery_date']}
                            required={true}
                        />
                        <Input label='Commentaire' value={order.comment} onInput={updateOrderKey} customParameters={['comment']} />
                    </div>
                </div>
                <button className='strong-button m-b-15'>Ajouter</button>
            </form>
        </>
    )
}