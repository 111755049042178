import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import Skeleton from 'Components/Skeleton/Skeleton'
import { BatchContext } from 'Context/CBDProtect/Batch'
import React, { useEffect, useContext } from 'react'

export default function SelectBatch({
    refresh = true,
    placeholder = 'Sélectionner un batch',
    width = '300px',
    required = true,
    setter,
    customParameters,
    style,
    value = '',
    defaultId = null,
}) {

    /******************************************************/
    //dépendences
    const { batchs, getBatchs, formatBatchName } = useContext(BatchContext)

    return (
        <div style={{ width: width }} className='flex end gap-1'>
            {batchs
                ? <>
                    <Datalist
                        defaultId={defaultId}
                        placeholder={placeholder}
                        items={batchs.map(batch => ({ id: parseInt(batch.ID), value: formatBatchName(batch) }))}
                        onSelect={setter}
                        customParameters={customParameters}
                        width={width}
                        required={required}
                        style={style}
                        value={value}
                    />
                    {refresh
                        ? <button
                            type='button'
                            onClick={_ => getBatchs()} className='light-button'>actualiser</button>
                        : null
                    }
                </>
                : <Skeleton height={'50px'} />
            }
        </div>
    )
}
