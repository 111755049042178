import React from 'react'
import './variation.css';
import { emptyVariationValue } from './variationHelpers';
import SelectPriceCategory from 'Components/_COMMERCIAL/PriceCategory/SelectPriceCategory';
import { downElement, upElement } from 'Functions';


export default function VariationFields({ catalogue, variation, setVariation }) {

    const updateVariation = (key, value) => {
        (!variation.use_reference || key === "use_reference")
            && setVariation(variation => {
                return { ...variation, [key]: value }
            })
    }

    const addValue = () => {
        !variation.use_reference && setVariation(variation => {
            return { ...variation, values: [...variation.values, emptyVariationValue] }
        })
    }


    const updateValue = (index, key, value) => {
        console.log(value)
        !variation.use_reference && setVariation(variation => {
            return {
                ...variation,
                values: [
                    ...variation.values.map((v, idx) =>
                        idx === index
                            ? { ...v, [key]: value }
                            : v
                    )
                ]
            }
        })
    }
    const deleteValue = (index, name = "") => {
        !variation.use_reference && window.confirm(`Supprimer la variation ${name}?`)
            && setVariation(variation => {
                return {
                    ...variation,
                    values: [
                        ...variation.values.filter((_, idx) => idx !== index)
                    ]
                }
            })
    }

    const reOrderVariations = (values) => {
        !variation.use_reference && setVariation(product => {
            return {
                ...product,
                values: values
            }
        })
    }

    return (
        <div style={{ opacity: variation.use_reference ? 0.6 : 1 }}>
            {!catalogue && (
                <div className='m-b-10'>
                    <label >
                        <input type="checkbox" checked={variation.use_reference ? true : false} onChange={() => updateVariation('use_reference', variation.use_reference ? 0 : 1)} />
                        <span> Copier les variations du parent (auto update)</span>
                    </label>
                </div>
            )}
            <div className='flex m-b-10'>
                <label>Nom de la variation</label>
                <input placeholder='Gout...' required value={variation.name} onInput={e => updateVariation('name', e.target.value)} />
            </div>
            <div className='column'>
                <label className='left'>Description de la variation</label>
                <textarea defaultValue={variation.description ?? ''} onInput={e => updateVariation("description", e.target.value)} />
            </div>
            <div className='flex column gap-1 m-t-10'>
                {variation.values && Array.isArray(variation.values) &&
                    variation.values.map((v, index) =>
                        <div className='flex-center  gap-1' key={index} style={{ opacity: variation.use_reference || v.use_reference ? 0.6 : 1 }}>
                            <div className='flex column' style={{ marginRight: "5px" }}>
                                <div onClick={() => upElement(variation.values, index, reOrderVariations)} className='m-b-10 pointer'>▲</div>
                                <div onClick={() => downElement(variation.values, index, reOrderVariations)} className='pointer'>▼</div>
                            </div>
                            <div className="variation-value">
                                {!catalogue && (
                                    <div className='m-b-10'>
                                        <label >
                                            <input type="checkbox" disabled={variation.use_reference}
                                                checked={variation.use_reference || v.use_reference ? true : false}
                                                onChange={() => updateValue(index, 'use_reference', v.use_reference ? 0 : 1)}
                                            />
                                            <span> Garder les paramètres du parent (auto update)</span>
                                        </label>
                                    </div>
                                )}
                                <div className='delete-value' onClick={() => deleteValue(index, v.value)}>×</div>
                                <div className='flex gap-2'>
                                    <div>
                                        <div className='flex column m-b-10'>
                                            <label>Valeur</label>
                                            <input className='half-m-width' placeholder='Menthe' required value={v.value} onInput={e => updateValue(index, 'value', e.target.value)} />
                                        </div>

                                        <div className='flex column m-b-10'>
                                            <label>Fonction du prix</label>
                                            <SelectPriceCategory defaultValue={v.price_category_id} setter={updateValue} customParams={[index, 'price_category_id']} />
                                        </div>
                                        <div className='column'>
                                            <label className='left'>Description de la valeur</label>
                                            <textarea value={v.description ?? ""} onInput={e => updateValue(index, 'description', e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex-center m-b-10'>
                                            <label>Prix maximum</label>
                                            <input required min="0" step={0.001} type='number' placeholder='20' value={v.max_price} onChange={e => updateValue(index, 'max_price', e.target.value)} />
                                        </div>

                                        <div className='flex-center m-b-10'>
                                            <label>Prix minimum</label>
                                            <input required min="0" step={0.001} type='number' placeholder='20' value={v.min_price} onChange={e => updateValue(index, 'min_price', e.target.value)} />
                                        </div>

                                        <div className='flex-center m-b-10'>
                                            <label>Quantité minimum</label>
                                            <input required min="0" step={0.001} type='number' placeholder='20' value={v.min_qty} onChange={e => updateValue(index, 'min_qty', e.target.value)} />
                                        </div>

                                        <div className='flex-center m-b-10'>
                                            <label>Quantité pour prix min</label>
                                            <input required min="0" step={0.001} type='number' placeholder='20' value={v.min_price_qty} onChange={e => updateValue(index, 'min_price_qty', e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    )}
                {variation.use_reference && !catalogue
                    ? null
                    : <button onClick={() => addValue()} type='button' className='light-button m-t-10 center-block'>+</button>
                }
            </div>
        </div>
    )
}
