import React, { useState, useContext, useEffect, useRef } from 'react'
import { NotificationContext } from 'Context/NotificationContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import { convertObjectToFormData, deleteEmptyObjectsField, useCallMSLApi } from "Functions";
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'

import Datalist from 'Components/Dataliste/Dataliste';

import ProductFields from './ProductFields';
import { useCheckVariation } from './Attributes/Variation/variationHelpers';
import { emptyProduct } from './productHelpers';
import { CatalogueContext } from 'Context/Commercial/CatalogueContext';
import { CustomProductsContext } from 'Context/Commercial/CustomProductsContext';

export default function UpdateProduct({ productToUpdate }) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { organizations } = useContext(OrganizationsContext)
    const { setCustomProducts } = useContext(CustomProductsContext)
    const { setCatalogueProducts } = useContext(CatalogueContext)

    const setOrganizationId = (id) => {
        setProduct(product => ({
            ...product,
            organization_id: id
        }))
    }

    const checkVariation = useCheckVariation();

    const setProductReferenceId = (id) => {
        setProduct(product => ({
            ...product,
            reference_id: id
        }))
    }
    const [showForm, setShowForm] = useState(false)

    const callMSLApi = useCallMSLApi()

    const [product, setProduct] = useState({ ...emptyProduct(productToUpdate.catalogue), ...deleteEmptyObjectsField(productToUpdate) })

    const createProduct = e => {
        e.preventDefault()

        if (product.type == 2) {
            if (!checkVariation(product.variation)) {
                return
            }
        }
        addNotificationContent({
            error: false,
            title: "Mise à jour en cours...",
            content: <MiniLoader />,
            infinit: true,
        })

        const formData = convertObjectToFormData(product)

        const config = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
            }
        }

        callMSLApi(`product`, config, formData)
            .then(res => {
                if (res.data.success) {
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: "Le produit a été mis à jour",
                    })
                } else {
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: res.data.message,
                    })
                }
                product.catalogue
                    ? setCatalogueProducts(prvProducts => prvProducts.map(p => p.id === product.id ? res.data.product : p))
                    : setCustomProducts(prvProducts => prvProducts.map(p => p.id === product.id ? res.data.product : p))
            })
            .catch(error => {
                console.log({ error });
                addNotificationContent({
                    error: true,
                    title: "Impossible de mettre à jour le produit",
                    content: error?.response?.data?.message ?? "essayé de recharger la page",
                })
            })
    }

    /* CHARGEMENT DES INFOS SI PAS CATALOGUE */
    const [productReference, setProductReference] = useState(false)

    const { catalogueProducts } = useContext(CatalogueContext)

    useEffect(() => {
        if (catalogueProducts) {
            setProductReference(catalogueProducts.map(product => {
                return ({
                    ...product,
                    value: product.name
                })
            }))
        }
    }, [catalogueProducts]);


    /* GESTION DE L'AFFICHAGE DU FORMULAIRE */
    useEffect(() => {
        if (!product.catalogue) {
            if (product.organization_id && product.reference_id) {
                setShowForm(true)
            } else {
                setShowForm(false)
            }
        } else {
            setShowForm(true)
        }
    }, [product.organization_id, product.reference_id])

    return (
        <>
            {productReference && productReference.length < 1 ? (
                <h5>Aucun produit actuellement disponible dans le catalogue</h5>
            ) : (
                <>
                    {!product.catalogue
                        ?
                        <div className='flex flex-center'>
                            {organizations ? (
                                <div className='flex flex-center input half-m-width'>
                                    <Datalist
                                        placeholder="Organisation:"
                                        width='100%'
                                        items={organizations}
                                        onSelect={setOrganizationId}
                                        defaultId={product.organization_id && product.organization_id}
                                    />
                                </div>
                            ) : <MiniLoader />}
                            {productReference ? (
                                <div className='flex flex-center input half-m-width'>
                                    <Datalist
                                        placeholder="Produit de référence:"
                                        width='100%'
                                        items={productReference}
                                        onSelect={setProductReferenceId}
                                        defaultId={product.reference_id && product.reference_id}
                                    />
                                </div>
                            ) : <MiniLoader />}
                        </div>
                        : null
                    }
                </>
            )}
            {showForm && (
                <>
                    <h3 className='center'>Mise à jour d'un produit {product.catalogue ? " du catalogue" : " personalisé"}</h3>

                    <form id="sumit-product" onSubmit={e => createProduct(e)}>
                        <ProductFields product={product} setProduct={setProduct} />
                    </form>
                    <h4 className='center m-b-25'>Création</h4>
                    <div className='flex-center' style={{ maxWidth: "400px", margin: "0 auto 25px auto" }}>
                        <select value={product.state} onChange={e => setProduct(product => ({ ...product, state: e.target.value }))}>
                            <option value="0"> Brouillon </option>
                            <option value="1"> Publié</option>
                        </select>
                        <button form='sumit-product' className='strong-button center-block'>Mettre à jour</button>
                    </div>
                </>
            )
            }
        </>
    )
}


