import React from 'react';
import OrganizationsContextProvider from 'Context/ERP/OrganizationsContext';
import ErpBatchContextProvider from 'Context/ERP/ErpBatchContext';
import AnalyseAccessContextProvider from './AnalyseAccessContext';
import AnalysesContextProvider from './AnalysesContext';
import OrdersContextProvider from './OrdersContext';
import UsersContextProvider from './UsersContext';
import SamplesContextProvider from './SamplesContext';

export default function AnalyseContextContainer(props) {
    return (
        <AnalyseAccessContextProvider>
            <AnalysesContextProvider>
                <OrdersContextProvider>
                    <UsersContextProvider>
                        <ErpBatchContextProvider>
                            <OrganizationsContextProvider>
                                <SamplesContextProvider>
                                    {props.children}
                                </SamplesContextProvider>
                            </OrganizationsContextProvider>
                        </ErpBatchContextProvider>
                    </UsersContextProvider>
                </OrdersContextProvider>
            </AnalysesContextProvider>
        </AnalyseAccessContextProvider>
    );
}

