import React, { useState, useEffect, useContext, useRef } from "react"
import { NotificationContext } from "Context/NotificationContext"
import ValidedSession from "./Sessions/ValidedSession"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { useCallApi } from "Functions"
import { appConstante } from "appConstante"
import Input from "Components/FormElement/Input"
import ValidedStep from "./Steps/ValidedStep"
import { PlanningContext } from "Context/ERP/PlanningContext"
import SelectProductSpecification from "../ProductSpecification/SelectProductSpecification"
import { DealProductsContext } from "Context/ERP/deal/DealProduct"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CreateSession from "./Sessions/CreateSession"
import OpenableComponent from "Components/GENERAL/OpenableComponent/OpenableComponent"
import ProductSpectificationDetails from "../ProductSpecification/ProductSpectificationDetails"

export default function PlanProductForm({ passedProduct, dealId, selectInfo }) {
  const today = new Date()
  const callApi = useCallApi()
  const [error, setError] = useState(null)
  /* CONTEXT */
  const { addNotificationContent } = useContext(NotificationContext)
  const {
    addPlanningStep,
    addPlanningArrayStep,
    createStep,
    putStep
  } = useContext(PlanningContext)

  const { putDealProduct } = useContext(DealProductsContext)

  /** ********************** STEP ********************** */

  const [product, setProduct] = useState(passedProduct ? passedProduct : {})

  useEffect(() => {
    setProduct(passedProduct)
    setValidedSteps(false)
    setValidedSessions(false)
    setNewStep({
      name: "",
      todo_quantity: passedProduct.quantity,
      comment: "",
    })
  }, [passedProduct])

  const [newStep, setNewStep] = useState({
    name: "",
    todo_quantity: product.quantity,
    comment: "",
  })

  const updateNewStep = (key, value) => {
    setNewStep({ ...newStep, [key]: value })
  }

  const [validedSteps, setValidedSteps] = useState(false)
  const [rereshStep, toogleSteps] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}/steps`,
      { method: "get" },
      controller.signal
    )
      .then((res) => {
        addPlanningArrayStep(res.data.data)
        setValidedSteps(res.data.data ? res.data.data : [])
      })
      .catch((error) => {
        console.log({ error });
        setError("Une erreur est survenue lors du chargement des étapes de productions")
      })
    return () => {
      controller.abort()
    }
  }, [product, rereshStep])

  const updateStep = (newStep) => {
    setValidedSteps(steps => steps.map((step) => (step.id === newStep.id ? newStep : step)))
  }

  const removeStep = (id) => {
    setValidedSteps(steps => steps.filter((step) => step.id !== id))
  }

  const addStepFromForm = (e) => {
    e.preventDefault()
    const step = {
      ...newStep,
      product_id: product.id,
      deal_id: dealId,
    }
    createStep(step)
      .then((step) => {
        setValidedSteps([...validedSteps, step])
        setNewStep({
          name: "",
          todo_quantity: product.quantity,
          comment: "",
        })
      })
  }

  /** ********************** SESSION ********************** */


  useEffect(() => {
    const controller = new AbortController()
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}/sessions`,
      { method: "get" },
      controller.signal
    )
      .then((res) => {
        setValidedSessions(res.data.data ? res.data.data : [])
      })
      .catch(() =>
        setError(
          "Une erreur est survenue lors du chargement des sessions de productions"
        )
      )
    return () => {
      controller.abort()
    }
  }, [product])

  const [validedSessions, setValidedSessions] = useState(null)

  const updateSession = (newSession) => {
    setValidedSessions(() =>
      validedSessions.map((session) =>
        session.id === newSession.id ? { ...session, ...newSession } : session
      )
    )
    updateStep(newSession.step)
  }

  const deleteSession = (sessionId) => {
    setValidedSessions(prvSessions => prvSessions.filter((session) => session.id !== sessionId))
    toogleSteps(!rereshStep)
  }

  const addSession = (session) => {
    setValidedSessions(prvSessions => ([...prvSessions, session]))
    toogleSteps(!rereshStep)
  }

  /** *** PARAMETRE DE L'INSTANCIATION DE TOUTE LES ETAPES ***** */
  const [initialSteps, setInitialSteps] = useState([
    { id: 0, create: true, name: "Production", quantity: product.quantity },
    {
      id: 1,
      create: true,
      name: "Conditionnement",
      quantity: product.quantity,
    },
    { id: 2, create: true, name: "Etiquetage", quantity: product.quantity },
    { id: 3, create: true, name: "Mise en boîte", quantity: product.quantity },
    { id: 4, create: true, name: "Colisage", quantity: product.quantity },
  ])

  const updateInitialSteps = (stepId, key, value) => {
    setInitialSteps(
      initialSteps.map((step) => {
        if (step.id === stepId) {
          return { ...step, [key]: value }
        }
        return step
      })
    )
  }

  const createAllSteps = async (e) => {
    e.preventDefault()
    addNotificationContent({
      title: "Création des étapes en cours",
      content: <MiniLoader />,
      infinit: true,
    })

    const newSteps = initialSteps
      .filter((step) => step.create)
      .map((step) => createStepObject(step.name, step.quantity))

    const stepsPromises = newSteps.map((step, index) => createStep({ ...step, order: index }))
    Promise.all(stepsPromises)
      .then(newvalidedSteps => {
        console.log(newvalidedSteps);
        setValidedSteps([...newvalidedSteps])
      })
      .then(() => {
        addNotificationContent({
          content: 'Les étapes ont bien étés ajoutés'
        })
      })
  }


  const createStepObject = (stepName, stepQuantity) => {
    const step = {
      name: stepName,
      product_id: product.id,
      todo_quantity: stepQuantity,
      not_todo_quantity: product.quantity - stepQuantity,
      deal_id: dealId,
      plan_quantity: 0,
    }
    return step
  }

  const handleDragEnd = (result) => {
    console.log(result);
    if (!result.destination) {
      return; // Le glisser-déposer a été annulé, ne faites rien
    }

    const reorderedSteps = [...validedSteps];
    const [movedStep] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, movedStep);

    // Mettez à jour l'état de vos étapes réorganisées
    reorderedSteps.map((step, index) => {
      console.log(index, validedSteps[index].order);
      index === validedSteps[index].order
        ? null
        : putStep({ ...step, order: index })
    })
    setValidedSteps(_ => reorderedSteps);
  }

  const setProductSpecificationId = (id) => {
    if (id !== null && product.erp.product_specification_id !== id) {
      putDealProduct({ id: product.id, product_specification_id: id === "" ? null : id })
      setProduct(prvProduct => {
        prvProduct.erp.product_specification_id = id
        return prvProduct
      })
    }
  }

  return (
    <div key={product.id}>
      {product
        ? <>
          <h3>
            Planification pour le produit : {product.name} (quantité:
            {product.quantity})
          </h3>
          <div className="flex-center full-width">
            <div id="product-comments">
              {product.comments
                ? <div
                  style={{
                    fontSize: "14px",
                    color: "rgb(80,80,80)",
                    textAlign: "left",
                  }}
                  dangerouslySetInnerHTML={{ __html: product.comments }} />
                : null}
            </div>
            {product.erp
              ? <div className="half-width">
                <SelectProductSpecification
                  defaultValue={""}
                  defaultId={product.erp.product_specification_id}
                  setter={setProductSpecificationId} />
              </div>
              : null
            }
          </div>
          {
            product.erp?.product_specification_id
            && <>
              <h4 className="center m-b-0 m-t-10">Voir les informations sur la spécification produit</h4>
              <OpenableComponent>
                <ProductSpectificationDetails
                  productSpecificationId={product.erp?.product_specification_id}
                  defaultParameters={{ show_pricing: false, editable: false, quantity: product.quantity }}
                />
              </OpenableComponent>
            </>
          }
          {/* {
            product.erp.product_specification_id
            && <ConsultProductSpecification
              setProductSpecificationId={product.erp.product_specification_id}
            />
          } */}
          <p>
            Déclarer les différentes étapes à réaliser pour la production de
            l'article. Vous pourrez ensuite planifier dans le temps ces étapes avec
            autant de sessions de productions qui vous semblent nécéssaire.
          </p>
          {error ? (
            <h5>{error}</h5>
          ) : (
            <div className="flex">
              <div style={{ width: "48%" }}>
                <h2>Etapes de productions</h2>
                {!validedSteps ? (
                  <MiniLoader />
                ) : (
                  <>
                    {validedSteps.length === 0 ? (
                      <>
                        <p>
                          Vous avez la possibilité d'instancier les étapes
                          suivantes:{" "}
                        </p>
                        <form
                          style={{ maxWidth: "500px", margin: "0 auto 20px auto" }}
                          onSubmit={(e) => createAllSteps(e)}
                        >
                          {initialSteps.map((step) => (
                            <div key={step.id} className="flex-center">
                              <label className="flex-start half-m-width">
                                <input
                                  className="erp-check"
                                  type="checkbox"
                                  checked={step.create}
                                  onChange={(e) =>
                                    updateInitialSteps(
                                      step.id,
                                      "create",
                                      e.target.checked
                                    )
                                  }
                                />
                                {step.name}
                              </label>
                              <div className="half-m-width">
                                <Input
                                  type="number"
                                  label="Quantité"
                                  value={step.quantity}
                                  onInput={updateInitialSteps}
                                  customParameters={[step.id, "quantity"]}
                                  disabled={!step.create}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          ))}
                          <button className="light-button m-t-25 center-block">
                            Créer les étapes
                          </button>
                        </form>
                      </>
                    ) : (
                      <>
                        <div>
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="validedSteps">
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                  {validedSteps.map((currentStep, index) => (
                                    <Draggable key={currentStep.id} draggableId={`${currentStep.id}`} index={index}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          id={currentStep.id}
                                        >
                                          <div className="m-b-15 drag-n-drop-icon">
                                            <ValidedStep
                                              updateStepFunction={updateStep}
                                              deleteStepFunction={removeStep}
                                              product={product}
                                              stepId={currentStep.id}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder} {/* Ajoutez cette ligne pour le placeholder */}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                        {/* {validedSteps.map((currentStep) => (
                      <div className="m-b-15" key={currentStep.id}>
                        <ValidedStep
                          updateStepFunction={updateStep}
                          deleteStepFunction={removeStep}
                          product={product}
                          step={currentStep}
                        />
                      </div>
                    ))} */}
                        <form
                          onSubmit={(e) => addStepFromForm(e)}
                          className="list-item full-width p-10 p-l-25 p-r-25"
                        >
                          <h5 className='m-0 m-b-10'>Créer une étape</h5>
                          <div className="flex input">
                            <label>Nom de l'étape:</label>
                            <input
                              value={newStep.name}
                              onInput={(e) => {
                                updateNewStep("name", e.target.value)
                              }}
                              type="text"
                              min="1"
                              required
                              placeholder="Etiquettage"
                            />
                          </div>
                          <div className="flex input left">
                            <label>
                              Quantité total souhaité
                              <br />
                              pour cette étape:
                            </label>
                            <input
                              type="number"
                              value={newStep.todo_quantity}
                              onInput={(e) => {
                                updateNewStep("todo_quantity", e.target.value)
                              }}
                              required
                            />
                          </div>
                          <div className="flex input">
                            <label>Remarque:</label>
                            <input
                              type="textarea"
                              value={newStep.comment}
                              onInput={(e) => {
                                updateNewStep("comment", e.target.value)
                              }}
                            />
                          </div>
                          <button className="strong-button">Créer l'étape</button>
                        </form>
                      </>
                    )}
                  </>
                )}
              </div>
              <div style={{ width: "48%" }}>
                <h2>Sessions de productions</h2>
                {!validedSessions ? (
                  <MiniLoader />
                ) : (
                  <>
                    <div className="m-b-25 full-width">
                      {validedSteps.length > 0 ? (
                        <CreateSession
                          steps={validedSteps}
                          setter={addSession}
                          defaultSession={selectInfo
                            ? {
                              step_id: selectInfo.step.id,
                              indicative_quantity: selectInfo.step?.todo_quantity - selectInfo.step?.plan_quantity,
                              start: selectInfo.startStr?.split("+")[0],
                              end: selectInfo.endStr?.split("+")[0],
                            }
                            : {}}
                        />
                      ) : (
                        <p>
                          Vous n'avez pas encore définis d'étape de production pour ce
                          produit
                        </p>
                      )}
                    </div>
                    {validedSessions.map((currentSession) => (
                      <div key={currentSession.id} className='m-b-25'>
                        <ValidedSession
                          updateSessionFunction={updateSession}
                          deleteSessionFunction={deleteSession}
                          steps={validedSteps}
                          session={currentSession}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div >
          )
          }
        </>
        : null
      }
    </div >
  )
}
