import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import ProductSpecificationFields from './Shared/ProductSpecificationFields'
import MidLoader from 'Components/MidLoader/MidLoader'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function EditProductSpecification() {
    let { id } = useParams();
    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [updateSpecificationProduct, setUpdateSpecificationProduct] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification/${id}`, { method: 'get' }, controller.signal)
            .then(res => {
                setUpdateSpecificationProduct(res.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
        return () => {
            controller.abort()
        }
    }, [id])
    
    const sendProductSpecification = (e) => {
        addNotificationContent({
            title: 'Modification en cours',
            content: <MiniLoader />,
            infinit: true
        })
        const data = {
            ...updateSpecificationProduct,
            conditioning: {
                primary: updateSpecificationProduct.conditioning.primary.map(pack => pack.id),
                secondary: updateSpecificationProduct.conditioning.secondary.map(pack => pack.id)
            }
        }
        delete data.article
        delete data.recipe

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification/${updateSpecificationProduct.id}`, { method: 'put' }, null, { ...data })
            .then(res => {
                addNotificationContent({
                    title: "Succès",
                    error: false,
                    content: "Mise à jour éffectuée",
                    infinit: false,
                })
            })
            .catch((error) => {
                console.log(error)
                addNotificationContent({
                    title: "Erreur",
                    error: false,
                    content: "Une erreur s'est produite",
                    infinit: false,
                })
            })
    }

    return (
        <>
            {!updateSpecificationProduct
                ? <MidLoader />
                : <> <h2 className="flex justify-center m-b-25" >Mise à jour d'une Spécification produit : {id}</h2>
                    <> <ProductSpecificationFields
                        productSpecification={updateSpecificationProduct}
                        setProductSpecification={setUpdateSpecificationProduct}
                    />
                        <button className='strong-button' style={{ marginTop: "40px" }} onClick={e => sendProductSpecification(e)}> Modifier la spécification client</button>
                    </>
                </>
            }
        </>
    )
}
