import React, { useContext, useEffect, useState } from 'react'
import { appConstante } from 'appConstante'
import { useCallApi, useCurrentAppPermission } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import { AnalysesContext } from 'Context/Analyse/AnalysesContext'
import { PopupContext } from 'Context/PopupContext'
import { ErpBatchContext } from 'Context/ERP/ErpBatchContext'
import AccordionTable from 'Components/AccordionTable/AccordionTable'
import MidLoader from 'Components/MidLoader/MidLoader'
import Pagination from 'Components/Pagination/Pagination'
import AddAnalyses from 'Components/_ANALYSE/Analyse/AddAnalyses'

export default function RawMaterials() {

    const callApi = useCallApi()
    const currentAppPermission = useCurrentAppPermission();
    const { addNotificationContent } = useContext(NotificationContext)
    const { addPopupContent } = useContext(PopupContext)
    const { getAnalyse } = useContext(AnalysesContext)
    const { updateBatch } = useContext(ErpBatchContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [onlyNotAnalyzed, setOnlyNotAnalyzed] = useState(true)
    const [onlyAvailable, setOnlyAvailable] = useState(true)
    const [batchs, setBatchs] = useState([])
    const [formatedBatchs, setFormatedBatchs] = useState([])

    useEffect(() => {
        setBatchs(null)
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/batchs`, { method: "get" }, controller.signal, {
            page: currentPage,
            to_analyze: 1,
            limit: 10,
            not_analyzed: onlyNotAnalyzed ? 1 : 0,
            only_available: onlyAvailable ? 1 : 0,
        })
            .then(res => {
                setBatchs(res.data.data)
                setTotalPage(res.data.total_pages)
                res.data.total_pages === 0 && setCurrentPage(1)
                currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: 'Nous rencontrons une erreur avec nos serveurs',
                    infinit: false,
                })
            })
        return () => {
            controller.abort()
        }
    }, [onlyNotAnalyzed, onlyAvailable, currentPage])

    useEffect(() => {
        const controller = new AbortController()

        setFormatedBatchs(null)
        const loadData = async () => {
            if (batchs) {
                const allBatchs = await Promise.all(batchs.map(async (batch) => {
                    const analysesFromBatchs = await Promise.all(
                        batch.analyses.map(async (analyse) => await getAnalyse(parseInt(analyse), controller.signal))
                    )
                    return {
                        ...batch,
                        analyses: analysesFromBatchs
                    }
                }))
                setFormatedBatchs(allBatchs.flat())
            }
        }

        if (batchs) {
            loadData()
        }

        return () => {
            controller.abort()
        }
    }, [batchs])

    const addAnalyseToBatch = (analyseData) => {
        setBatchs(prevBatchState => {
            const newBatchState = prevBatchState.map(batch => {
                if (batch.id === analyseData.batch_id) {
                    const newBatch = { ...batch, analyses: batch.analyses ? [...batch.analyses, analyseData.ID.toString()] : [analyseData.ID.toString()] }
                    updateBatch({ id: batch.id, add_analyse_id: analyseData.ID })
                    return newBatch
                } else {
                    return batch
                }
            })
            return newBatchState
        })
    }

    const getMostRecentDate = (history) => {
        return history.reduce((acc, current) => {
            if (!acc || new Date(current.date) > new Date(acc.date)) {
                return current
            }
            return acc
        }, null)
    }

    return (
        <>
            <div className='flex-start gap-1'>
                <div className='input'>
                    <label className='flex flex-center'>
                        <input
                            className='erp-check m-r-10'
                            type="checkbox"
                            checked={onlyNotAnalyzed}
                            onChange={e => setOnlyNotAnalyzed(e.target.checked)}
                        />
                        Seulement les n° lot non analysés
                    </label>
                </div>
                <div className='input'>
                    <label className='flex flex-center'>
                        <input
                            className='erp-check m-r-10'
                            type="checkbox"
                            checked={onlyAvailable}
                            onChange={e => setOnlyAvailable(e.target.checked)}
                        />
                        Seulement les lots disponibles
                    </label>
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPage={totalPage}
            />
            {formatedBatchs
                ? formatedBatchs.length
                    ? <AccordionTable
                        columns={[
                            currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                            { title: null, style: { textAlign: "center" }, colSpan: 1 },
                            { title: "Article", style: { textAlign: "center" }, colSpan: 2 },
                            { title: "Fournisseur", style: { textAlign: "center" }, colSpan: 1 },
                            { title: "N° lot", colSpan: 1 },
                            { title: "Historique", colSpan: 1 },
                            { title: "Nombre d'analyses", colSpan: 1 },
                        ]}
                        datas={
                            formatedBatchs.map((batch, index) => {
                                const mostRecentHistory = getMostRecentDate(batch.history);
                                return {
                                    mainLine: [
                                        currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                        {
                                            element:
                                                <button
                                                    className='light-button'
                                                    onClick={() => addPopupContent(<AddAnalyses origin={{ batch_id: batch.id }} setter={addAnalyseToBatch} />, "1220px")}>Analyser
                                                </button>
                                        },
                                        { element: <>{batch.article?.name}</>, colSpan: 2 },
                                        { element: <>{batch.batch_number}</> },
                                        { element: <>{batch.product_supplier}</> },
                                        { element: <>{new Date(mostRecentHistory.date).toLocaleDateString()}</> },
                                        { element: <>{batch.analyses ? batch.analyses.length : 0}</> }
                                    ],
                                    subLines: batch.analyses && batch.analyses.length && batch.analyses.some(analyse => analyse.results)
                                        ? batch.analyses.map((analyse) => {
                                            return [{
                                                element: (<a target="_blank" href={analyse.certificate_urls}>Voir</a>)
                                            },
                                            ...Object.entries(analyse.results).map(([key, value]) => ({
                                                element: <div><div className='strong-2'>{key}</div>{value}</div>,
                                            }))]
                                        })
                                        : [[{
                                            element: (<h4>Il n'y a pas encore de résultats pour ce produit</h4>), colSpan: 8
                                        }]]
                                }
                            })
                        }
                    />
                    : <h5>Aucun numéro de lot correspondant à votre recherche</h5>
                : <MidLoader />
            }
        </>
    )
}
