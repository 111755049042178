import React, { useEffect, useState, useContext } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { useParams } from 'react-router-dom'
import { NotificationContext } from 'Context/NotificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet'
import ConsultProductSpecification from '../ProductSpecification/ConsultProductSpecification'

export default function EditProductionSheets() {

    const params = useParams()
    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [loading, setLoading] = useState(true)
    let id = params.id

    const {
        emptyProcutionSheet,
        productionSheet,
        setProductionSheet,
        updateProductionSheetPropriety,
    } = useContext(ProductionSheetContext)


    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet/${id}`, { method: 'get' }, controller.signal)
            .then(res => {
                setLoading(false)
                setProductionSheet(prvPS => ({ ...prvPS, ...res.data.data }))
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
        return () => {
            controller.abort()
        }
    }, [id])


    useEffect(() => {
        updateProductionSheetPropriety('creating', true)
        return () => {
            setProductionSheet(emptyProcutionSheet)
        }
    }, [])

    const addUpdatedProductionSheet = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            title: "Mise à jour de la fiche de prod...",
            content: <MiniLoader />,
            infinit: true
        })
        const uri = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet/${productionSheet.id}`
        const options = { method: 'put' }
        callApi(uri, options, null, productionSheet)
            .then(res => {
                addNotificationContent({
                    title: "Succès",
                    error: false,
                    content: "Mise à jour effectuée",
                    infinit: false,
                })
            })
            .catch((error) => {
                console.log(error)
                addNotificationContent({
                    title: "Erreur",
                    error: true,
                    content: "Une erreur s'est produite",
                    infinit: false,
                })
            })
    }



    return (
        <>
            <> <h2 className="flex justify-center m-b-25" >Mise à jour d'une fiche production : </h2>
                {loading
                    ? <MiniLoader />
                    : <>
                        <ConsultProductSpecification
                            defaultProductSpecification={productionSheet}
                            defaultSteps={productionSheet.steps}
                        />
                    </>
                }
            </>
        </>
    )
}
