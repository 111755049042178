import React from 'react'

import PipedriveScript from "AppContainer/PIPEDRIVE/PipedriveScript"
import UpdateCAPerClient from "AppContainer/PIPEDRIVE/PipedriveScript/UpdateCAPerClient"
import Production from "AppContainer/ERP/ERpRoutesModules/Production/Production"
import NewDeal from "AppContainer/ERP/Production/OrderToPlan/NewDeal"
import PlanningEvents from "AppContainer/ERP/Production/PlanningProd/PlanningEvents"
import DealToSend from "AppContainer/ERP/Production/DealToSend/DealToSend"
import DeliverySheets from "AppContainer/ERP/Production/DeliverySheets/DeliverySheets"
import Stocks from "AppContainer/ERP/ERpRoutesModules/Stocks/Stocks"
import Products from "AppContainer/ERP/ERpRoutesModules/Stocks/Products"
import ImportsAndExport from "AppContainer/ERP/Stocks/ImportsAndExport/ImportsAndExport"
import Order from "AppContainer/ERP/Stocks/Order/OrderList"
import Home from "AppContainer/ERP/ERpRoutesModules/Home"
import Recipes from "AppContainer/ERP/SpecificationsAndRecipe/Recipe/RecipesList"

import { categories } from "appConstante"
import Brands from './Stocks/Brands/BrandsList'
import Parameters from './Parameters/Parameters'
import MouvementsEnCours from './Stocks/Movements/MovementsInProgress'
import SearchDeal from './Production/SearchDeal/SearchDeal'

import CreateProductSpecification from './SpecificationsAndRecipe/ProductSpecification/CreateProductSpecification'
import SpecificationAndRecipe from './ERpRoutesModules/SpecificationAndRecipe/SpecificationAndRecipe'
import Recipe from './ERpRoutesModules/SpecificationAndRecipe/Recipe'
import AddRecipe from './SpecificationsAndRecipe/Recipe/AddRecipe'
import EditRecipe from './SpecificationsAndRecipe/Recipe/EditRecipe'
import ConsultRecipe from './SpecificationsAndRecipe/Recipe/ConsultRecipe'
import EditProductSpecification from './SpecificationsAndRecipe/ProductSpecification/EditProductSpecification'
import ConsultProductSpecification from './SpecificationsAndRecipe/ProductSpecification/ConsultProductSpecification'
import ProductSpecifications from './SpecificationsAndRecipe/ProductSpecification/ProductSpecificationsList'
import SpecificationProduct from './ERpRoutesModules/SpecificationAndRecipe/SpecificationProduct'
import SurplusStock from './Stocks/SurplusStock/SurplusStock'
import HomeProductionSheet from './ERpRoutesModules/ProductionSheet/HomeProductionSheet'
import ProductionSheets from './SpecificationsAndRecipe/ProductionSheets/ProductionSheetsList'
import EditProductionSheets from './SpecificationsAndRecipe/ProductionSheets/EditProductionSheets'
import CreateProductionSheets from './SpecificationsAndRecipe/ProductionSheets/CreateProductionSheets'
import ConsultProductionSheets from './SpecificationsAndRecipe/ProductionSheets/ConsultProductionSheets'

export const ErpRoutes = [
    {
        path: "/erp", element: <Home />, subRoutes: [
            { path: "/erp/parameters", element: <Parameters /> },
            {
                path: "/erp/stock", element: <Stocks />, subRoutes:
                    [
                        { path: "/erp/stock/marques", element: <Brands /> },
                        ...categories.map(cat =>
                        ({
                            path: `/erp/stock/${cat.path}`, element: <Products path={`/erp/stock/${cat.path}`} />, subRoutes: [
                                { path: `/erp/stock/${cat.path}/import-export`, element: <ImportsAndExport articleCategory={cat.name} /> },
                                { path: `/erp/stock/${cat.path}/commande`, element: <Order category={cat.name} /> },
                                { path: `/erp/stock/${cat.path}/stock-a-ecouler`, element: <SurplusStock category={cat.name} /> },

                            ]
                        }),
                        ),
                        { path: "/erp/stock/mouvements-en-cours", element: <MouvementsEnCours /> },
                    ]
            },
            {
                path: "/erp/production", element: <Production />, subRoutes: [
                    { path: "/erp/production/commandes", element: <NewDeal /> },
                    { path: "/erp/production/planning", element: <PlanningEvents /> },
                    { path: "/erp/production/production-a-envoyer", element: <DealToSend /> },
                    { path: "/erp/production/bons-de-livraisons", element: <DeliverySheets /> },
                    { path: "/erp/production/recherche", element: <SearchDeal /> }
                ]
            },
            {
                path: "/erp/specif-and-recipe", element: <SpecificationAndRecipe />, subRoutes: [
                    {
                        path: "/erp/specif-and-recipe/specifications-product", element: <SpecificationProduct />, subRoutes: [
                            { path: "/erp/specif-and-recipe/specifications-product/my-specifications-product", element: <ProductSpecifications /> },
                            { path: "/erp/specif-and-recipe/specifications-product/add-specifications-product", element: <CreateProductSpecification /> },
                            { path: "/erp/specif-and-recipe/specifications-product/edit/:id", element: <EditProductSpecification /> },
                            { path: "/erp/specif-and-recipe/specifications-product/consult/:id", element: <ConsultProductSpecification /> },
                            { path: "/erp/specif-and-recipe/specifications-product/:id", element: <></> }, //Affichage en lecture d'une recette
                        ]
                    },
                    {
                        path: "/erp/specif-and-recipe/production-sheets", element: <HomeProductionSheet />, subRoutes: [
                            { path: "/erp/specif-and-recipe/production-sheets/my-production-sheets", element: <ProductionSheets /> },
                            { path: "/erp/specif-and-recipe/production-sheets/add-production-sheets", element: <CreateProductionSheets /> },
                            { path: "/erp/specif-and-recipe/production-sheets/edit/:id", element: <EditProductionSheets /> },
                            { path: "/erp/specif-and-recipe/production-sheets/consult/:id", element: <ConsultProductionSheets /> },
                            { path: "/erp/specif-and-recipe/production-sheets/:id", element: <></> }, //Affichage en lecture d'une recette
                        ]
                    },
                    {
                        path: "/erp/specif-and-recipe/recipes", element: <Recipe />, subRoutes: [
                            { path: "/erp/specif-and-recipe/recipes/my-recipes", element: <Recipes /> },
                            { path: "/erp/specif-and-recipe/recipes/add-recipe", element: <AddRecipe /> },
                            { path: "/erp/specif-and-recipe/recipes/edit/:id", element: <EditRecipe /> },
                            { path: "/erp/specif-and-recipe/recipes/consult/:id", element: <ConsultRecipe /> },
                            { path: "/erp/specif-and-recipe/recipes/:id", element: <></> }, //Affichage en lecture d'une recette
                        ]
                    },
                ]
            },
            {
                path: "/erp/parametre-pipedrive", element: <PipedriveScript />, subRoutes: [
                    { path: "/erp/parametre-pipedrive/mise-a-jour-pipedrive", element: <UpdateCAPerClient /> },
                ]
            },
        ]
    },
]
