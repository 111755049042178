import Skeleton from 'Components/Skeleton/Skeleton'
import { BulkContext } from 'Context/CBDProtect/Bulk'
import React, { useContext, useEffect, useState } from 'react'

export default function BulkDetailsSmall({ bulk = null, id = null }) {

    const { getBulk, formatBulkName } = useContext(BulkContext)
    const [currentBulk, setCurrentBulk] = useState(bulk)


    useEffect(() => {
        if (id) {
            getBulk(id, true)
                .then(bulk => setCurrentBulk(bulk))
        }
    }, [id])

    if (!bulk && !id) {
        return (null)
    }

    return (
        <div>
            {
                currentBulk
                    ? <div className="">
                        <div className="strong-2"> {formatBulkName(currentBulk)}</div>
                        {
                            currentBulk.analyses?.map(analyse => analyse.results
                                ? <div className="flex gap-1" key={analyse.ID}>
                                    {Object.entries(analyse.results)
                                        .map(([key, value]) =>
                                            <div key={key} className='flex column center'>
                                                <div className=''>{key}</div>
                                                <div>{value}</div>
                                            </div>
                                        )
                                    }
                                </div>
                                : null
                            )
                        }
                    </div>
                    : <Skeleton height={'40px'} />
            }
        </div>
    )
}
