import { NotificationContext } from 'Context/NotificationContext'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import React, { useContext, createContext } from 'react'

export const ErpBatchContext = createContext()
export default function BatchContext(props) {

    const callApi = useCallApi()

    const { addNotificationContent } = useContext(NotificationContext)

    const getERPBatch = async (batchId) => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/batch/${batchId}`, { method: "get" })
                .then(res => {
                    resolve(res.data.data)
                })
        })
    }

    const updateBatch = async (updatedBatch) => {
        return new Promise((resolve, reject) => {
            addNotificationContent({
                error: false,
                content: "modification en cours",
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/batch/${updatedBatch.id}`, { method: "put" }, null, updatedBatch)
                .then(res => {
                    resolve(res.data.data)
                    addNotificationContent({
                        content: `Le numéro de lot a été modifié`
                    })
                })
        })
    }

    const deleteBatch = async (batchId) => {
        return new Promise((resolve, reject) => {
            addNotificationContent({
                error: false,
                content: "suppression en cours",
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/batch/${batchId}`, { method: "delete" })
                .then(res => {
                    resolve(res.data.data)
                    addNotificationContent({
                        content: `Le numéro de lot a été supprimé`
                    })
                })
        })
    }

    return (
        <ErpBatchContext.Provider value={{ getERPBatch, updateBatch, deleteBatch }}>
            {props.children}
        </ErpBatchContext.Provider>
    )
}
