import React, { useState, useEffect, useContext } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { useCurrentAppPermission } from 'Functions'
import Pagination from 'Components/Pagination/Pagination'
import UpdateMovement from './ValidMovement'
import { PopupContext } from 'Context/PopupContext'
import DeleteButton from 'Components/DeleteButton/DeleteButton';
import DeleteMovement from './DeleteMovement'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function MovementsInPogress() {

    const callApi = useCallApi()
    const currentAppPermission = useCurrentAppPermission()
    const { addPopupContent } = useContext(PopupContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [movements, setMovements] = useState(null)

    const filterMovement = (id) => {
        setMovements(movements.filter(movement => movement.id !== id))
    }

    const showDeleteMovementPopup = (movement) => {
        addPopupContent(<DeleteMovement movement={movement} filterMovement={filterMovement} />)
    }

    useEffect(() => {
        const controller = new AbortController();

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-histories`, { method: 'GET' }, controller.signal, { limit: 10, page: currentPage })
            .then(res => {
                setMovements(res.data.data)
                setTotalPage(res.data.total_pages)
                res.data.total_pages === 0 && setCurrentPage(1)
                currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
            })
            .catch(error => {
               console.log({error});
            })
        return () => {
            controller.abort()
        }
    }, [currentPage])



    return (
        <>
            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />

            { movements 
            ? (movements.length > 0
                        ? (
                            <table className='beautiful-array'>
                                <thead>
                                    <tr>
                                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                            <th></th>
                                        }
                                        <th>Article</th>
                                        <th>Informations</th>
                                        <th>Fournisseur</th>
                                        <th>Quantité</th>
                                        <th>Date</th>
                                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                            <th></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    { movements.map(movement =>
                                        <tr key={movement.id}>
                                            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                                <td>
                                                    <button onClick={
                                                        () => addPopupContent(<UpdateMovement movement={movement} filterMovement={filterMovement} />)
                                                    }>Valider
                                                    </button></td>
                                            }
                                            <td>
                                                <div className="strong-1">{movement.name}</div>
                                            </td>
                                            <td>
                                                <div className="strong-1">{movement.comment}</div>
                                            </td>
                                            <td>
                                                <div className="strong-1">{movement.supplier}</div>
                                            </td>
                                            <td>
                                                <div className="strong-1">{movement.quantity}</div>
                                            </td>
                                            <td>
                                                <div className="strong-1">{new Date(movement.date).toLocaleDateString('FR')}</div>
                                            </td>
                                            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                                < td >
                                                    <DeleteButton
                                                        callBack={showDeleteMovementPopup}
                                                        parameters={[movement]}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
    
                        )
                        : 
                       ( <h5>Aucun mouvement à venir ! </h5>)
            )
            : (<MidLoader/>)

            }
        </>
    )
}
