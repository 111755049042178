import React, { } from 'react'

export default function BulkFields({ bulk, setter }) {

    return (
        <div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>Date de production</label>
                </div>
                <input
                    type="date"
                    value={bulk.created_date ?? ''}
                    onInput={e => setter('created_date', e.target.value)}
                />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>Type de produit</label>
                </div>
                <input
                    type="text"
                    value={bulk.product ?? ''}
                    onInput={e => setter('product', e.target.value)}
                />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>DDM</label>
                </div>
                <input
                    type="date"
                    value={bulk.ddm ?? ''}
                    onInput={e => setter('ddm', e.target.value)}
                />
            </div>
        </div>
    )
}