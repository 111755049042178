import React, { useState, useContext } from 'react'
import { PopupContext } from 'Context/PopupContext'
import SelectPriceCategory from '../../PriceCategory/SelectPriceCategory'
import './packaging.css'

export default function AddPackagingParameters({ addNewPackaging }) {
    const { showPopup2 } = useContext(PopupContext)

    const addPackaging = e => {
        e.preventDefault()
        addNewPackaging(packaging)
        showPopup2(false)
    }

    const [packaging, setPackaging] = useState({
        "type": "",
        "max_price": "",
        "min_price": "",
        "price_category_id": "",
        "min_qty": "",
        "min_price_qty": "",
        "height": "",
        "width": "",
        "depth": "",
        "template_url": ""
    })

    const [gabaritSRC, setGabaritSRC] = useState()

    const updatePackaging = (key, value) => {
        setPackaging(prvPackaging => ({ ...prvPackaging, [key]: value }))
    }

    const UpdatePriceCategoryId = (id) => {
        updatePackaging("price_category_id", id)
    }

    const renderInput = (file) => {
        var reader = new FileReader(file);
        reader.onload = function (e) {
            setGabaritSRC(e.target.result)
            setPackaging(prvPackaging => ({
                ...prvPackaging,
                template_url: e.target.result,
                file: file
            }))
            updatePackaging("template_url", e.target.result)
        }
        reader.readAsDataURL(file); // convert to base64 string
    }

    return (
        <>
            <h2>Ajouter un packaging</h2>
            <form onSubmit={e => addPackaging(e)}>
                <div className='flex input'>
                    <label>Type: </label>
                    <select value={packaging.type} onChange={e => updatePackaging('type', e.target.value)} required>
                        <option hidden value=''>-- Type de packaging --</option>
                        <option value='Boîte'>Boîte</option>
                        <option value='Etiquette'>Etiquette</option>
                        <option value='Doypack'>Doypack</option>
                    </select>
                </div>
                <div className='column'>
                    <label>Prix: </label>
                    <div className='flex input'>
                        <input required type='number' step='0.01' min='0' placeholder='Prix maximum' value={packaging.max_price} onInput={e => updatePackaging("max_price", e.target.value)} />
                        <input required type='number' step='0.01' min='0' placeholder='Prix minimum' value={packaging.min_price} onInput={e => updatePackaging("min_price", e.target.value)} />
                        <SelectPriceCategory setter={UpdatePriceCategoryId} />
                    </div>
                </div>
                <div className='column'>
                    <label>Quantité: </label>
                    <div className='flex input'>
                        <input required type='number' step='1' min='0' placeholder='Quantité minimum' value={packaging.min_qty} onInput={e => updatePackaging("min_qty", e.target.value)} />
                        <input required type='number' step='1' min='0' placeholder='Quantité pour atteindre le prix minimum' value={packaging.min_price_qty} onInput={e => updatePackaging("min_price_qty", e.target.value)} />
                    </div>
                </div>
                <div>
                    <label>Dimensions: </label>
                    <div className='flex'>
                        <div className='input'>
                            <input required type='number' step='0.1' min='0' placeholder='Hauteur (mm)' value={packaging.height} onInput={e => updatePackaging("height", e.target.value)} />
                        </div>
                        <div className='input'>
                            <input required type='number' step='0.1' min='0' placeholder='Largeur (mm)' value={packaging.width} onInput={e => updatePackaging("width", e.target.value)} />
                        </div>
                        {packaging.type != 'Etiquette' && (
                            <div className='input'>
                                <input required ype='number' step='0.1' min='0' placeholder='Profondeur (mm)' value={packaging.depth} onInput={e => updatePackaging("depth", e.target.value)} />
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex'>
                    <div className='column half-m-width'>
                        <label>Description (clients):  </label>
                        <textarea placeholder='Pélliculagen vernissage, type de carton...' value={packaging.description_customer} onInput={e => updatePackaging('description_customer', e.target.value)} />
                    </div>
                    <div className='column half-m-width'>
                        <label>Description (commerciaux):  </label>
                        <textarea placeholder="Lien d'achat, diamètre du mandrin, sens d'enroulement..." value={packaging.description_admin} onInput={e => updatePackaging('description_admin', e.target.value)} />
                    </div>
                </div>
                <div className="gabarit-picture">
                    {gabaritSRC && (
                        <embed style={{ borderRadius: "5px", height: '750px', width: '100%' }} src={gabaritSRC} />
                    )}
                    <label className="add-gabary-button">
                        <input type="file" accept=".pdf"
                            value={""}
                            onChange={(e) => { renderInput(e.target.files[0]) }} />
                        Ajouter un gabarit
                    </label>
                </div>
                <button className='m-t-25 m-b-25 center-block light-button'>Ajouter le packaging au produit</button>
            </form>
        </>
    )
}
