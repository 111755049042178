import React, { useState, useEffect } from 'react';
import { emptyVariation } from './variationHelpers';
import VariationFields from './VariationFields';
import './variation.css';

export default function AddVariation({ catalogue, productVariation, setProductVariation }) {

    const [variation, setVariation] = useState({ ...emptyVariation, ...productVariation })


    useEffect(() => {
        setProductVariation(variation)
    }, [variation])

    return (
        <VariationFields catalogue={catalogue} variation={variation} setVariation={setVariation} />
    )
}
