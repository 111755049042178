import React, { useContext, useEffect, useState } from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from 'Context/NotificationContext'
import { useCallAnalysesApi } from 'Functions'
import { emptyProduct } from 'AppContainer/ANALYSES/analysesConstantes'
import ProductFields from 'Components/_ANALYSE/Product/ProductFields'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { OrdersContext } from 'Context/Analyse/OrdersContext'

export default function AddProduct({ product, setter }) {

    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { updateOrderFromId } = useContext(OrdersContext)
    const callAnalysesApi = useCallAnalysesApi()
    const [newProduct, setNewProduct] = useState({})

    useEffect(() => {
        if (product) {
            const parentProduct = { ...product }
            delete parentProduct.etat
            setNewProduct(parentProduct)
        } else {
            setNewProduct(emptyProduct)
        }
    }, [emptyProduct, product])

    const sendProduct = (e) => {
        e.preventDefault()
        const controller = new AbortController()
        addNotificationContent({
            title: 'Ajout en cours',
            content: <MiniLoader />,
        })
        callAnalysesApi(`products`, { method: "post", signal: controller.signal }, newProduct, e)
            .then((res) => {
                showPopup(false)
                if (res.data.success) {
                    setter(prevProductsArray => {
                        const newProductsArray = [res.data.data, ...prevProductsArray]
                        return newProductsArray
                    })
                    updateOrderFromId(res.data.data.id_commande)

                    addNotificationContent({
                        error: false,
                        title: 'Succès',
                        content: "Le produit a été réceptionné",
                        infinit: false
                    })
                }
                else {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: res.data.message ?? 'Une erreur a eu lieu lors de la sauvegarde',
                        infinit: false
                    })
                }
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message ?? 'Une erreur a eu lieu lors de la sauvegarde',
                    infinit: false
                })
            })
        return () => {
            controller.abort()
        }
    }

    return (
        <div>
            {product
                ? <h2>Duplication d'un produit</h2>
                : <h2>Réception d'un produit </h2>
            }
            <form onSubmit={e => sendProduct(e)}>
                <ProductFields
                    product={newProduct}
                    setter={setNewProduct}
                    isNewProduct={true}
                    isDuplicate={product ? true : false}
                />
                <div>
                    <button className='strong-button'>Ajouter un produit</button>
                </div>
            </form>
        </div>
    )
}
