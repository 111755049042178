import React, { useContext, useState, useEffect, useRef } from 'react'
import { formatNumber, isNumber, useCallPipedriveApi } from 'Functions'
import { appConstante } from 'appConstante'
import { DealContext } from 'Context/Commercial/DealContext'
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'
import { PopupContext } from 'Context/PopupContext'
import { useParams } from 'react-router-dom'
import Input from 'Components/FormElement/Input'
import MidLoader from 'Components/MidLoader/MidLoader'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import set from 'lodash/set.js';
import './sendDeal.css'
import SendDealMail from './SendDealMail'
import TextEditor from 'Components/GENERAL/Form/TextEditor/TextEditor'
import OpenableComponent from 'Components/GENERAL/OpenableComponent/OpenableComponent'
import ProductSpectificationDetails from 'Components/_ERP/ProductSpecification/ProductSpectificationDetails'
import SelectProductSpecification from 'Components/_ERP/ProductSpecification/SelectProductSpecification'

export default function SendDeal() {

    let { id } = useParams()

    const callPipedriveApi = useCallPipedriveApi()
    const { getOrganizationFromId } = useContext(OrganizationsContext)
    const { getDealFromId, updateDeal, getDealLink } = useContext(DealContext)
    const { addPopupContent } = useContext(PopupContext)
    const [organization, setOrganization] = useState()
    const [dealId, setDealId] = useState('')
    const [selectedDeal, setSelectedDeal] = useState(null)
    const [totalPrices, setTotalPrices] = useState({
        montantHT: 0,
        montantTTC: 0,
        TVATot: 0,
    })

    const currentDate = new Date()
    const typingTimeout = useRef(0)

    const getSelectedDeal = (dealId) => {

        clearTimeout(typingTimeout.current)

        setSelectedDeal(_ => null)

        typingTimeout.current =
            setTimeout(() => {
                getDealFromId(dealId)
                    .then(deal => {
                        deal && callPipedriveApi(`deals/${dealId}/products`)
                            .then(res =>
                                setSelectedDeal(_ => (
                                    {
                                        ...deal,
                                        [appConstante.pipedrive.CHARGING_POLE]:
                                            deal[appConstante.pipedrive.CHARGING_POLE]
                                                ? deal[appConstante.pipedrive.CHARGING_POLE].split(',')
                                                : [],
                                        deal_products: res.data.data ?? []
                                    }
                                ))
                            )
                    })
            }, 400)
    }

    const getCurrentOrganization = async (orgId) => {
        const currentOrga = await getOrganizationFromId(orgId, false)
        setOrganization(currentOrga)
    }

    const copyDealLink = (deal_id) => {
        getDealLink(deal_id)
            .then(dealLink => window.prompt("Copy to clipboard: Ctrl+C, Enter", dealLink))
    }
    const goToDealPage = (deal_id) => {
        getDealLink(deal_id)
            .then(dealLink => window.open(`${dealLink}&commercial=true`))
    }

    useEffect(() => {
        if (dealId) {
            getSelectedDeal(dealId)
        } else if (id) {
            getSelectedDeal(id)
        }
    }, [dealId, id])

    useEffect(() => {
        if (selectedDeal?.org_id?.value) {
            getCurrentOrganization(selectedDeal.org_id.value)
        }
    }, [selectedDeal?.org_id?.value])

    useEffect(() => {
        if (selectedDeal) {
            const montantHT = selectedDeal.deal_products?.reduce((acc, current) => {
                return acc += current.HTtot ?? current.sum
            }, 0)
            const montantTTC = selectedDeal.deal_products?.reduce((acc, current) => {
                return acc += current.TTCtot ?? current.sum * (1 + (current.tax / 100))
            }, 0)
            const splitedTVA = {}
            selectedDeal.deal_products?.forEach(product => {
                const HT = product.HTtot ?? product.sum
                const TTC = product.TTCtot ?? product.sum * (1 + (product.tax / 100))
                if (splitedTVA[`${product.tax}%`]) {
                    splitedTVA[`${product.tax}%`] += TTC - HT
                } else {
                    splitedTVA[`${product.tax}%`] = TTC - HT
                }
            });

            setTotalPrices({
                montantHT: montantHT,
                montantTTC: montantTTC,
                montantReduction: selectedDeal.deal_products?.reduce((acc, current) => {
                    return acc += (parseFloat(current.quantity) * parseFloat(current.item_price)) - current.HTtot ?? current.sum
                }, 0),
                splitedTVA: splitedTVA
            })
        }
    }, [selectedDeal])

    const updateDealKey = (keyPath, value, poleType) => {

        let formatedValue = value

        if (keyPath === appConstante.pipedrive.CHARGING_POLE) {
            let PoleArray = selectedDeal[appConstante.pipedrive.CHARGING_POLE]

            formatedValue = value
                ? [...PoleArray, poleType]
                : PoleArray.filter(pole => pole !== poleType)
        }

        setSelectedDeal(psDeal => {
            const newDeal = { ...psDeal }
            set(newDeal, keyPath, formatedValue)
            return newDeal
        })
    }

    const updateDealProducts = (index, keyPath, value) => {

        // Update input and select values (price, discount, qty, tax)
        setSelectedDeal(psDeal => {
            const newDeal = { ...psDeal }

            let formatedValue = value

            if (keyPath === 'discount_type' || keyPath === 'comments' || typeof value !== 'string') {
                formatedValue = value
            } else {
                formatedValue = value.replace(',', '.')
            }

            set(newDeal, `deal_products[${index}].${keyPath}`, formatedValue)
            return newDeal
        })

        // Update total values
        setSelectedDeal(psDeal => {
            const newDeal = { ...psDeal }

            const currentProduct = selectedDeal.deal_products[index]

            const HTtot = currentProduct.discount_type === 'percentage'
                ? (parseFloat(currentProduct.quantity) * parseFloat(currentProduct.item_price)) * (1 - (parseFloat(currentProduct.discount) / 100))
                : (parseFloat(currentProduct.quantity) * parseFloat(currentProduct.item_price)) - parseFloat(currentProduct.discount)

            const TTCtot = HTtot * (1 + parseFloat(currentProduct.tax) / 100)

            set(newDeal, `deal_products[${index}].HTtot`, HTtot)
            set(newDeal, `deal_products[${index}].TTCtot`, TTCtot)
            return newDeal
        })
    }

    return (
        <div className='send-item-component'>
            {!id &&
                <div className='m-b-25'>
                    <Input
                        type='number'
                        label='Indiquez un n° de devis'
                        value={dealId}
                        onInput={setDealId}
                        step='1'
                    />
                </div>}

            {dealId || id
                ? selectedDeal
                    ? selectedDeal.person_id
                        ? <div>
                            <h3>{selectedDeal.org_name} deal ({selectedDeal.id})</h3>
                            <div className='flex gap-2'>
                                <div className='flex-1'>
                                    <h4>Commercial</h4>
                                    <div className='flex column gap-1'>
                                        <div className='flex-center'>
                                            <div className='strong-2'>Commercial</div>
                                            <div>{selectedDeal.user_id?.name}</div>
                                        </div>
                                        <div className='flex-center'>
                                            <div className='strong-2'>Mail</div>
                                            <div>{selectedDeal.user_id.email}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <h4>Client</h4>
                                    <div className='flex column gap-1'>
                                        <div className='flex-center'>
                                            <div className='strong-2'>Organisation</div>
                                            <div>{selectedDeal.org_name}</div>
                                        </div>
                                        <div className='flex-center'>
                                            <div className='strong-2'>Contact</div>
                                            <div>{selectedDeal.person_name}</div>
                                        </div>
                                        <div className='flex-center'>
                                            <div className='strong-2'>Mail</div>
                                            <div className={`${selectedDeal.person_id.email[0]?.value ? "" : "error"}`}>
                                                {
                                                    selectedDeal.person_id.email[0]?.value
                                                        ? selectedDeal.person_id.email[0]?.value
                                                        : "Merci d'ajouter un mail à la personne sur Pipedrive"
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className='strong-2'>Adresse de facturation</div>
                                            <div className='full-width'>{selectedDeal.org_id?.address ?? "Pas d'adresse de facturation"}</div>
                                        </div>
                                        <div>
                                            <div className='strong-2'>Adresse de livraison</div>
                                            <input type="text" className='full-width' value={selectedDeal[appConstante.pipedrive.DEAL_DELIVERY_ADRESS]} onChange={e => updateDealKey(appConstante.pipedrive.DEAL_DELIVERY_ADRESS, e.target.value)} />
                                        </div>
                                        <div>
                                            <div className='strong-2'>Adresse de livraison de l'organisation</div>
                                            {organization && <div>{organization[appConstante.pipedrive.ORG_DELIVERY_ADRESS]}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {selectedDeal.deal_products
                                ? selectedDeal.deal_products.length
                                    ? <div className='m-t-25'>
                                        <table className="beautiful-array array-no-hover p-5">
                                            <thead>
                                                <tr>
                                                    <th className='left'>Produits</th>
                                                    <th>Prix HT</th>
                                                    <th>Quantité</th>
                                                    <th>Remise</th>
                                                    <th>Total HT</th>
                                                    <th>TVA</th>
                                                    <th>Total TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedDeal.deal_products.map((product, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr key={index} className='border-bottom-none' >
                                                                {/* style={{ backgroundColor: index % 2 === 1 ? '#fff' : "#fff" }} */}
                                                                <td className='left strong-2 p-l-10'>{product.name}</td>
                                                                <td>
                                                                    <input type='number' style={{ width: "50px" }} onInput={e => updateDealProducts(index, "item_price", e.target.value)} value={product.item_price} step={1} />
                                                                </td>
                                                                <td>
                                                                    <input type='number' style={{ width: "50px" }} onInput={e => updateDealProducts(index, "quantity", e.target.value)} value={product.quantity} step={1} />
                                                                </td>
                                                                <td>
                                                                    <div className='discount-select-section'>
                                                                        <input type='number' style={{ width: "50px" }} onInput={e => updateDealProducts(index, "discount", e.target.value)} value={product.discount} step={1} />
                                                                        <select style={{ width: "50px" }} onChange={e => updateDealProducts(index, "discount_type", e.target.value)} value={product.discount_type}>
                                                                            <option value="percentage">%</option>
                                                                            <option value="amount">Eur</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>{product.HTtot || product.HTtot === 0 ? product.HTtot?.toFixed(2) * 1 : product.sum}</td>
                                                                <td>
                                                                    <select style={{ width: "50px" }} value={product.tax} onChange={e => updateDealProducts(index, "tax", e.target.value)}>
                                                                        <option value={0}>0</option>
                                                                        <option value={5.5}>5.5</option>
                                                                        <option value={8.5}>8.5</option>
                                                                        <option value={20}>20</option>
                                                                    </select>
                                                                </td>
                                                                <td>{product.TTCtot || product.TTCtot === 0 ? product.TTCtot?.toFixed(2) * 1 : (product.sum * (1 - (product.tax / 100))).toFixed(2) * 1}</td>
                                                            </tr>
                                                            <tr className='border-top-none'>
                                                                <td colSpan="7" className='left p-l-10'>
                                                                    <TextEditor
                                                                        value={product.comments ?? ''}
                                                                        setter={(updateDealProducts)}
                                                                        customParameters={[index, "comments"]}
                                                                        placeholder={'Ajouter un commentaire au produit...'}
                                                                        className='m-b-10'
                                                                    />
                                                                    <OpenableComponent
                                                                        openLabel={"Afficher la spécification produit"}
                                                                        closeLabel={"Masquer la spécification produit"}
                                                                        position='left'
                                                                    >
                                                                        <div className='depth-effect'>
                                                                            <div className='m-b-10'>
                                                                                <SelectProductSpecification
                                                                                    setter={updateDealProducts}
                                                                                    customParameters={[index, "specificationProductId"]}
                                                                                    reload={false}
                                                                                />
                                                                            </div>
                                                                            {product.specificationProductId
                                                                                && <ProductSpectificationDetails
                                                                                    productSpecificationId={product.specificationProductId}
                                                                                    defaultParameters={{ show_pricing: true, editable: true, quantity: product.quantity }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </OpenableComponent>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div className='m-t-25 m-b-25 half-width total-prices-section'>
                                            <div className='m-b-10'>
                                                <div className='flex-center'>
                                                    <div className='strong-2'>Montant total (HT)</div>
                                                    <div className='strong-2'>{totalPrices?.montantHT ? formatNumber(totalPrices?.montantHT) : '...'}€</div>
                                                </div>
                                                {totalPrices?.montantReduction
                                                    ? <div className='m-t-10 flex center'>
                                                        <span>Dont réduction : </span> <span>{formatNumber(totalPrices.montantReduction)}€</span></div>
                                                    : null
                                                }
                                            </div>
                                            <div className='m-b-10'>
                                                <div className='flex-center'>
                                                    <div className='strong-2'>TVA totale</div>
                                                    <div className='strong-2'>{totalPrices?.montantTTC && totalPrices?.montantHT ? formatNumber(totalPrices?.montantTTC - totalPrices?.montantHT) : '...'}€</div>
                                                </div>
                                                <div className='subtext m-t-10'>
                                                    {totalPrices.splitedTVA
                                                        ? Object.entries(totalPrices.splitedTVA).map(([key, value], index) => {
                                                            if (value) {
                                                                return <div className="flex-center" key={index}>
                                                                    <div>TVA {key} : </div> <div>{formatNumber(value)}€</div>
                                                                </div>
                                                            }
                                                        })
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex-center m-b-10'>
                                                <div className='strong-2'>Montant total (TTC)</div>
                                                <div className='strong-2'>{totalPrices?.montantTTC ? formatNumber(totalPrices?.montantTTC) : '...'}€</div>
                                            </div>
                                        </div>
                                    </div>
                                    : <h5> Il n'y a pas de produits dans ce devis</h5>
                                : <MiniLoader />}
                            <div className='m-b-25'>
                                <div className='strong-2 m-b-10'>Pôle(s) en charge du devis</div>
                                {/* <select
                                    className='full-width'
                                    value={selectedDeal[appConstante.pipedrive.CHARGING_POLE]}
                                    onChange={e => updateDealKey(appConstante.pipedrive.CHARGING_POLE, e.target.value)}
                                >
                                    <option value="260">Production</option>
                                    <option value="261">Réglementaire</option>
                                    <option value="262">Analyse</option>
                                </select> */}
                                <div className='flex-start gap-1'>
                                    <div className='input'>
                                        <label className='flex flex-center'>
                                            <input
                                                className='erp-check m-r-10'
                                                type='checkbox'
                                                checked={selectedDeal[appConstante.pipedrive.CHARGING_POLE]?.includes('260') ?? false}
                                                onChange={e => updateDealKey(appConstante.pipedrive.CHARGING_POLE, e.target.checked, '260')}
                                            />
                                            Production
                                        </label>
                                    </div>
                                    <div className='input'>
                                        <label className='flex flex-center'>
                                            <input
                                                className='erp-check m-r-10'
                                                type='checkbox'
                                                checked={selectedDeal[appConstante.pipedrive.CHARGING_POLE]?.includes('261') ?? false}
                                                onChange={e => updateDealKey(appConstante.pipedrive.CHARGING_POLE, e.target.checked, '261')}
                                            />
                                            Réglementaire
                                        </label>
                                    </div>
                                    <div className='input'>
                                        <label className='flex flex-center'>
                                            <input
                                                className='erp-check m-r-10'
                                                type='checkbox'
                                                checked={selectedDeal[appConstante.pipedrive.CHARGING_POLE]?.includes('262') ?? false}
                                                onChange={e => updateDealKey(appConstante.pipedrive.CHARGING_POLE, e.target.checked, '262')}
                                            />
                                            Analyse
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='m-b-25'>
                                <div className='strong-2'>Commentaires</div>
                                <textarea type="text" className='full-width' value={selectedDeal[appConstante.pipedrive.COMMENT]} onChange={e => updateDealKey(appConstante.pipedrive.COMMENT, e.target.value)} />
                            </div>
                            <div className='m-b-25'>
                                <div className='strong-2'>Conditions de paiement</div>
                                <textarea type="text" className='full-width' value={selectedDeal[appConstante.pipedrive.PAYMENT_TERMS]} onChange={e => updateDealKey(appConstante.pipedrive.PAYMENT_TERMS, e.target.value)} />
                            </div>
                            <div className='m-b-25'>
                                <div className='strong-2'>Date de validité</div>
                                <input type="date" className='full-width' value={selectedDeal[appConstante.pipedrive.EXPIRY_DATE]} onInput={e => updateDealKey(appConstante.pipedrive.EXPIRY_DATE, e.target.value)} />
                            </div>
                            {selectedDeal &&
                                (new Date(selectedDeal[appConstante.pipedrive.EXPIRY_DATE]) < currentDate)
                                && <div className='m-b-25 red'>Attention ! La date de validité du devis est passée. Veuillez la changer.</div>}
                            <div className='flex-start gap-1 m-b-25'>
                                <button className='strong-button full-width' onClick={() => updateDeal({
                                    ...selectedDeal,
                                    totalTTC: totalPrices.montantTTC,
                                    totalHT: totalPrices.montantHT,
                                    totalDiscount: totalPrices.montantReduction,
                                    totalTVA: totalPrices.montantTTC - totalPrices.montantHT,
                                    splitedTVA: totalPrices.splitedTVA
                                })}>Mettre à jour le devis</button>
                            </div>
                            <div className='m-b-10'>
                                <button className='light-button full-width' onClick={() => addPopupContent(<SendDealMail deal={selectedDeal} />, "800px")}>Envoyer le devis</button>
                            </div>
                            <div className='m-b-10'>
                                <button onClick={_ => goToDealPage(selectedDeal.id)}
                                    className='light-button full-width'>Voir la page d'acceptation du devis</button>
                            </div>
                            <div className='m-b-10'>
                                <button type='button' onClick={_ => copyDealLink(selectedDeal.id)} className='light-button full-width'>Copier le lien d'acceptation du devis</button>
                            </div>
                        </div>
                        : <h5>Votre devis doit être associé à une personne sur Pipedrive</h5>
                    : <MidLoader />
                : null
            }
        </div >
    )
}
