import { ProductSpecificationContext, ProductSpecificationInterface } from 'Context/ERP/ProductSpecificationContext'
import React, { useContext, useEffect, useState } from 'react'
import ProductSpectificationArticle from './ProductSpectificationArticle'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { Parameters, emptyParameters } from '../ProductSpectificationDetails'
import { getDensityForm } from 'AppContainer/ERP/ERPConstantes'
import { formatNumber, getUnitMeasureType } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import RawMaterials from 'AppContainer/ANALYSES/RawMaterialsItem/RawMaterials'

interface Props {
    productSpecificationId: Number,
    parameters: Parameters | null
    defaultRawMaterials: null | Array<any>
    setterPricing: Function | null
    setterRawMaterials: Function | null
}

interface Ingredient {
    article: any
    quantity_type: string
    quantity: Number
}

/**
 * 
 * @param {Number} productSpecificationId  identifiant de la spécification produit
 * @param {Parameters} parameters parameters permettant d'afficher des informations supplémentaires comme la quantité par article nécéssaire
 * @returns {React.JSX.Element}
 */

export default function ProductSpecificationArticles({
    productSpecificationId,
    parameters = emptyParameters,
    defaultRawMaterials = null,
    setterPricing = null,
    setterRawMaterials = null
}: Props): React.JSX.Element {

    const { getProductSpecification } = useContext(ProductSpecificationContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const [rawMaterials, setRawMaterials] = useState<Array<any>>([])

    useEffect(() => {
        if (defaultRawMaterials) {
            setRawMaterials(_ => defaultRawMaterials)
        }
    }, [])

    useEffect(() => {
        if (productSpecificationId) {
            getProductSpecification(productSpecificationId)
                .then((productSpecification: ProductSpecificationInterface) => {
                    !rawMaterials.length &&
                        setRawMaterials(_ => {
                            const bases = productSpecification.recipe?.bases
                                ? productSpecification.recipe.bases.map(article => ({ ...article.article, ...article, type: "bases" }))
                                : []
                            const cannabinoids = productSpecification.recipe?.cannabinoids
                                ? productSpecification.recipe.cannabinoids.map(
                                    article => ({
                                        ...article.article,
                                        ...article,
                                        cbd_concentration: parseFloat(article.article.cbd_concentration ?? 90),
                                        type: "cannabinoids",
                                        quantity: parseFloat(productSpecification?.article?.cbd_concentration)
                                    }))
                                : []
                            const other_ingredients = productSpecification.recipe?.other_ingredients
                                ? productSpecification.recipe.other_ingredients?.map(article => ({ ...article.article, ...article, type: "other_ingredients" }))
                                : []
                            const primary = productSpecification.conditioning?.primary
                                ? productSpecification.conditioning.primary.map(article => ({ ...article, type: "primary_conditioning" }))
                                : []
                            const secondary = productSpecification.conditioning?.secondary
                                ? productSpecification.conditioning.secondary.map(article => ({ ...article, type: "secondary_conditioning" }))
                                : []

                            return [
                                ...bases,
                                ...cannabinoids,
                                ...other_ingredients,
                                ...primary,
                                ...secondary,
                            ]
                        })
                })
        }
    }, [productSpecificationId])

    const computetotalQuantityWithoutBases = () => {
        let total = 0
        if (parameters?.article?.unit_of_measure) {
            rawMaterials?.forEach(ingredient => {
                if (ingredient.type === "other_ingredients" && ingredient.unit_of_measure) {
                    total += computeOtherIngredientVL(ingredient) / 100

                } else if (ingredient.type === "cannabinoids" && ingredient.unit_of_measure) {
                    const quantity = computeIngredientVL(ingredient)
                    total += quantity / parseFloat(ingredient.cbd_concentration)
                }
            })
        }
        return total
    }

    const computeOtherIngredientVL = (ingredient: Ingredient) => {
        if (ingredient.infusion === 1) {
            return 0
        }

        const densityForm = getDensityForm(
            ingredient.quantity_type
                ? ingredient.quantity_type
                : 'masse',
            getUnitMeasureType(parameters?.article.unit_of_measure)
        )
        let quantity = ingredient.quantity ? ingredient.quantity : 0;
        const density = ingredient?.density ? ingredient.density : 1
        return densityForm(quantity, density)
    }

    const computeIngredientVL = (ingredient: Ingredient) => {
        const densityForm = getDensityForm(
            getUnitMeasureType(ingredient.unit_of_measure),
            ingredient.type === "cannabinoids"
                ? "masse"
                : getUnitMeasureType(parameters?.article.unit_of_measure)
        )
        const quantity = ingredient.quantity ? parseFloat(ingredient.quantity) : 0;
        const density = ingredient?.density ? parseFloat(ingredient.density) : 1
        return densityForm(quantity, density)
    }

    const updateIngredient = (index: number, ingredient: any) => {
        setRawMaterials(prvInfos => {
            const newInfos = prvInfos
            newInfos[index] = ingredient

            const totalPrice = newInfos.reduce((acc, ingredient) => {
                return acc + (ingredient.productionPrice ? ingredient.productionPrice : 0)
            }, 0)

            setterRawMaterials && setterRawMaterials(newInfos)

            totalPrice && setterPricing && setterPricing(prvPricings => ({
                ...prvPricings,
                materials_tot: totalPrice,
                materials_unit: totalPrice / parseFloat(parameters?.quantity)
            }))

            const total = newInfos.reduce((acc, ingredient) => {
                return acc + (ingredient.type === 'bases' ? parseFloat(ingredient.quantity) : 0)
            }, 0)

            if (total) {
                total !== 100
                    ? addNotificationContent({
                        content: "La somme de la quantitée des bases doit être égale à 100",
                        error: true,
                        infinit: true
                    })
                    : addNotificationContent({})
            }

            return newInfos;
        })
    }

    const addMaterial = (type: string) => {
        const quantity = type === 'bases'
            ? rawMaterials.filter(material => material.type === 'bases').reduce((acc, current) => acc - parseFloat(current.quantity), 100)
            : 0

        setRawMaterials(prv => {
            return [
                ...prv,
                {
                    type: type,
                    quantity: quantity,
                    quantity_type: 'masse',
                    showDetails: true,
                }
            ]
        })
    }

    const deleteMaterial = (index: number) => {
        setRawMaterials(prv => {
            return [...prv.filter((_, idx: number) => idx !== index)]
        })
    }

    return (
        <div>
            {rawMaterials && parameters?.loaded && parameters?.article?.unit_of_measure
                ? <div>
                    <div className='flex wrap gap-1'>
                        <div className='full-width'>
                            <h4 className='center m-b-0'>Recette</h4>
                            <div className='flex gap-1'>
                                <div className='flex-1'>
                                    <div className='center m-t-10'><strong>Bases</strong></div>
                                    {rawMaterials?.map((article, index) => {
                                        return article.type === "bases"
                                            ? <ProductSpectificationArticle
                                                key={article.id}
                                                defaultArticle={article}
                                                parameters={{
                                                    ...parameters,
                                                    type: 'recipe',
                                                    totalQuantityWithoutBases: computetotalQuantityWithoutBases()
                                                }}
                                                index={index}
                                                setter={updateIngredient}
                                                deleter={deleteMaterial}
                                            />
                                            : null
                                    }
                                    )}
                                    {parameters?.editable
                                        ? <button
                                            className='light-button center-block m-t-10'
                                            onClick={e => addMaterial("bases")}
                                        >Ajouter une base</button>
                                        : null}
                                </div>
                                <div className='flex-1'>
                                    <div className='center m-t-10'>
                                        <strong>Cannabinoïdes</strong>
                                    </div>
                                    {rawMaterials?.map((article, index) => {
                                        if (article.type === "cannabinoids") {
                                            const quantity = parameters.article.cbd_concentration && productSpecificationId
                                                ? parseFloat(parameters.article.cbd_concentration)
                                                : parseFloat(article.quantity)

                                            return <ProductSpectificationArticle
                                                key={article.id}
                                                defaultArticle={{
                                                    ...article,
                                                    quantity: quantity,
                                                }}
                                                parameters={{
                                                    ...parameters,
                                                    type: 'recipe',
                                                }}
                                                index={index}
                                                setter={updateIngredient}
                                                deleter={deleteMaterial}
                                            />
                                        }
                                    }
                                    )}
                                    {parameters?.editable
                                        ? <button
                                            className='light-button center-block m-t-10'
                                            onClick={e => addMaterial("cannabinoids")}
                                        >Ajouter un actif</button>
                                        : null}
                                </div>
                                <div className='flex-1'>
                                    <div className='center m-t-10'><strong>Autres ingrédients</strong></div>
                                    {rawMaterials?.map((article, index) => {
                                        return article.type === "other_ingredients"
                                            ? <ProductSpectificationArticle
                                                key={article.id}
                                                defaultArticle={article}
                                                parameters={{
                                                    ...parameters,
                                                    type: 'recipe',
                                                }}
                                                index={index}
                                                setter={updateIngredient}
                                                deleter={deleteMaterial}
                                            />
                                            : null
                                    }
                                    )}
                                    {parameters?.editable
                                        ? <button
                                            className='light-button center-block m-t-10'
                                            onClick={e => addMaterial("other_ingredients")}
                                        >Ajouter un ingrédient</button>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <h4 className='center m-b-10'>Packaging primaire</h4>
                            {rawMaterials?.map((article, index) => {
                                return article.type === "primary_conditioning"
                                    ? <ProductSpectificationArticle
                                        key={article.id}
                                        defaultArticle={article}
                                        parameters={parameters}
                                        index={index}
                                        setter={updateIngredient}
                                        deleter={deleteMaterial}
                                    />
                                    : null
                            }
                            )}
                            {parameters?.editable
                                ? <button
                                    className='light-button center-block m-t-10'
                                    onClick={e => addMaterial("primary_conditioning")}
                                >Ajouter un packaging primaire</button>
                                : null}
                        </div>
                        <div className='flex-1'>
                            <h4 className='center m-b-10'>Packaging secondaire</h4>
                            {rawMaterials?.map((article, index) => {
                                return article.type === "secondary_conditioning"
                                    ? <ProductSpectificationArticle
                                        key={article.id}
                                        defaultArticle={article}
                                        parameters={parameters}
                                        index={index}
                                        setter={updateIngredient}
                                        deleter={deleteMaterial}
                                    />
                                    : null
                            }
                            )}
                            {parameters?.editable
                                ? <button
                                    className='light-button center-block m-t-10'
                                    onClick={e => addMaterial("secondary_conditioning")}
                                >Ajouter un packaging secondaire</button>
                                : null}
                        </div>
                    </div>
                </div >
                : <MiniLoader />
            }
        </div>
    )
}
