import React, { useState, useContext } from 'react'
import OptionFields from './OptionFields'
import { useCheckOption } from './optionHelpers'

export default function UpdateOption({ optionIndex, parentOption, updateParentOption }) {

    const [option, setOption] = useState(parentOption)

    const checkOption = useCheckOption();

    const updateCurrentOption = () => {
        if (checkOption(option)) {
            updateParentOption(optionIndex, option)
        }
    }

    return (
        <div className="form-option-container" >
            <OptionFields option={option} setOption={setOption} />
            <button onClick={() => updateCurrentOption()} type='button' className='strong-button center-block m-t-25'>Mettre à jour</button>
        </div>
    )
}
