import React, { useEffect, useContext, useState } from 'react';
import Datalist from 'Components/Dataliste/Dataliste';
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';

/**
 * 
 * @param {Function} setter callback to set organization ID
 * @param {Int} defaultOrganizationId default organization ID to show
 * @param {Array} customParameters (optional) Custum paramaters added on the onSelect function  
 * @param {String} width (optional) width of the all element (label + input)
 * @returns 
 */
export default function SelectOrganization({
    defaultOrganizationId = null,
    setter,
    customParameters = [],
    placeholder = 'Sélectionner une organisation',
    width,
    style,
    updateOrganizationsFromPipedrive = false,
    disabled = false
}) {

    const { organizations, getOrganizations } = useContext(OrganizationsContext)
    const [defaultOrganizationValue, setDefaultOrganizationValue] = useState(null);
    useEffect(() => {
        if (organizations && defaultOrganizationId) {
            const defaultOrganization = organizations.find(organization => organization.id === defaultOrganizationId);
            defaultOrganization && setDefaultOrganizationValue(defaultOrganization.value)
            defaultOrganization && setter(...customParameters, defaultOrganization.id)
        }
    }, [organizations])

    if (updateOrganizationsFromPipedrive === true) {
        return (
            <div style={{ width: width }} className='flex end gap-1'>
                <Datalist
                    value={defaultOrganizationValue ? defaultOrganizationValue : ''}
                    placeholder={placeholder}
                    items={organizations}
                    onSelect={setter}
                    customParameters={customParameters}
                    width={width}
                    required={true}
                    style={style}
                    disabled={disabled}
                />
                {organizations
                    ? <button
                        type='button'
                        onClick={_ => getOrganizations(false)} className='light-button'>actualiser</button>
                    : <MiniLoader />
                }
            </div>
        )
    } else {
        return (
            <Datalist
                value={defaultOrganizationValue ? defaultOrganizationValue : ''}
                placeholder={placeholder}
                items={organizations}
                onSelect={setter}
                customParameters={customParameters}
                width={width}
                required={true}
                style={style}
                disabled={disabled}
            />
        )
    }
}
