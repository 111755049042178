import React, { useState, useContext } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import './CreateProductSpecification.css'
import ProductSpecificationFields from 'AppContainer/ERP/SpecificationsAndRecipe/ProductSpecification/Shared/ProductSpecificationFields'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { useNavigate } from 'react-router-dom'
import { ProductSpecificationContext } from 'Context/ERP/ProductSpecificationContext'

export default function CreateProductSpecification({ defaultSpectif = {} }) {

  const callApi = useCallApi()
  const navigate = useNavigate()

  const { addNotificationContent } = useContext(NotificationContext)
  const { addProdcutSpecification } = useContext(ProductSpecificationContext)

  const emptyProductSpecification = {
    article_id: null,
    recipe_id: null,
    conditioning: {
      primary: [],
      secondary: [],
    },
    ...defaultSpectif
  }
  const [specificationProduct, setSpecificationProduct] = useState(emptyProductSpecification)

  const addProductSpecification = (e) => {
    e.preventDefault()
    addNotificationContent({
      error: false,
      title: "Création de la spécification produit",
      content: <MiniLoader />,
      infinit: true
    })
    const data = {
      ...specificationProduct,
      recipe_id: specificationProduct.recipe_id ? specificationProduct.recipe_id : null,
      conditioning: {
        primary: specificationProduct.conditioning.primary.map(pack => pack.id),
        secondary: specificationProduct.conditioning.secondary.map(pack => pack.id)
      }
    }

    callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification`, { method: 'post' }, null, { ...data }, e)
      .then((res) => {
        addNotificationContent({
          title: "Succès",
          error: false,
          content: "La spécification produit a été ajoutée",
          infinit: false,
        })
        addProdcutSpecification(res.data)
        navigate('/erp/specif-and-recipe/specifications-product/my-specifications-product')
      })
      .catch((error) => {
        console.log(error)
        addNotificationContent({
          title: "Erreur",
          error: false,
          content: "Une erreur s'est produite",
          infinit: false,
        })
      })
  }
  return (
    <>
      <h2 className="flex justify-center m-b-25" >Création d'une Spécification produit</h2>
      <form onSubmit={addProductSpecification}>
        <>
          <ProductSpecificationFields
            productSpecification={specificationProduct}
            setProductSpecification={setSpecificationProduct}
          />
          <button className='strong-button' style={{ marginTop: "40px" }} > Ajouter la spécification client</button>
        </>
      </form>
    </>
  )
}
