import React from 'react'
import './Input.css'
import { isNumber } from 'Functions'

export default function Input({
    label = '',
    value = '',
    onInput = null,
    style = {},
    required = false,
    disabled = false,
    list = null,
    customParameters = [],
    type = 'text',
    min = '0',
    step = '0.0001',
    max = null,
    onKeyDown = null
}) {
    let inputClass = 'input'
    inputClass += (value || type === 'date') ? ' valid' : ''

    return (
        <div style={style} className={disabled ? "group disabled" : "group"}>
            <input
                className={inputClass}
                value={value}
                onInput={e => onInput
                    ? onInput(...customParameters,
                        type === 'number'
                            ? isNumber(e.target.value)
                                ? parseFloat(e.target.value.replace(',', '.'))
                                : e.target.value
                            : e.target.value
                    ) : null}
                required={required}
                list={list} disabled={disabled}
                min={min} max={max} step={step}
                type={type}
                onKeyDown={onKeyDown && onKeyDown}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{label}{required && <span className='required-star'>*</span>}</label>
            {type !== 'date' && <div className="cancel-select" onClick={() => !disabled ? onInput(...customParameters, '') : null}>✕</div>}
        </div>
    )
}
