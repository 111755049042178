import React, { useEffect, useState, useRef, useContext } from 'react'
import { useCallApi } from 'Functions'
import { useNavigate, useParams } from 'react-router-dom'
import { appConstante } from 'appConstante'
import MidLoader from 'Components/MidLoader/MidLoader'
import IngredientItem from 'AppContainer/ERP/SpecificationsAndRecipe/Recipe/shared/Ingredient/IngredientItem'
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet'

export default function ConsultRecipe({ defaultRecipe }) {

    const id = useRef(null)

    const Navigate = useNavigate()
    const callApi = useCallApi()
    const [recipe, setRecipe] = useState(null)
    const [showIngredientItems, setShowIngredientItems] = useState(false)

    const params = useParams()
    useEffect(() => {
        if (params.id && !defaultRecipe) {
            id.current = params.id
        }
    }, [params])


    const {
        productionSheet,
        updateProductionSheetPropriety,
    } = useContext(ProductionSheetContext)


    useEffect(() => {
        if (productionSheet.creating) {
            setRecipe({ ...productionSheet.recipe })
        } else if (defaultRecipe) {
            setRecipe({
                ...defaultRecipe,
            })
        }
    }, [productionSheet.creating])

    useEffect(() => {
        if (productionSheet.creating && recipe) {
            updateProductionSheetPropriety('recipe', recipe)
        }
    }, [recipe, productionSheet.creating])

    useEffect(() => {
        const controller = new AbortController()
        if (id.current) {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe/${id.current}`, { method: 'get' }, controller.signal)
                .then(res => {
                    setRecipe(res.data.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        return () => {
            controller.abort()
        }
    }, [id.current])

    useEffect(() => {
        // productionSheet.recipe.other_ingredients.find(ingr => ingr.infusion === 2)
        if (productionSheet.recipe.other_ingredients.find(ingr => ingr.infusion)) {
            productionSheet.parameters.marge_to_add
                && productionSheet.parameters.marge_to_add_for_infusion && setShowIngredientItems(true)
        } else {
            productionSheet.parameters.marge_to_add && setShowIngredientItems(true)
        }
    }, [productionSheet.parameters.marge_to_add, productionSheet.parameters.marge_to_add_for_infusion])

    // CAROUSEL //
    const goToUpdateRecipe = () => {
        Navigate(`/erp/specif-and-recipe/recipes/edit/${id.current}`)
    }

    return (
        <div>
            {productionSheet.article && (!productionSheet.article.volume || !productionSheet.article.unit_of_measure)
                ? <h5>
                    L'article n'est pas bien paramétré pour afficher la recette (volume ou unité de mesure manquant)
                </h5>

                : !recipe
                    ? <MidLoader />
                    : <>
                        {
                            productionSheet.creating
                                ? null
                                : <h2 className='center'>Recette : {recipe.name}</h2>
                        }
                        <div className='flex wrap'>
                            <div className='half-m-width'>
                                <h4>Informations générales</h4>
                                <div className='m-b-10'>
                                    {recipe.name
                                        ? <div className='flex-start'>
                                            <div className='strong-2 m-r-10'>Nom de la recette : </div>
                                            <div className='right'>{recipe.name}</div>
                                        </div>
                                        : null}
                                    {recipe.description
                                        ? <div>
                                            <div className='strong-2 m-r-10'>Description : </div>
                                            <div>{recipe.description}</div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className='half-m-width'>
                                {recipe.steps.length
                                    ? <>
                                        <h4 className='m-t-25'>Étapes</h4>
                                        <div className='flex-basis flex-start column gap-2'>
                                            {recipe.steps.map((step, index) => <div key={index} className={'full-width'}>
                                                <div className='flex-start'>
                                                    <div className='strong-2 m-r-10'>Étape : </div>
                                                    <div>{step.name}</div>
                                                </div>
                                                <div className='flex-start'>
                                                    <div className='strong-2 m-r-10'>Description : </div>
                                                    <div>{step.description}</div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </>
                                    : null
                                }

                                {productionSheet.creating &&
                                    <div className='full-width'>
                                        <h4 className='m-t-25'>Paramètres</h4>
                                        {/* <div className='flex-center gap-1 m-b-10'>
                                                <label className='strong-2'>Date de création : </label>
                                                <input type='date' value={recipe.parameters.date} onInput={e => uptadeRecipeProperty('parameters.date', e.target.value)}></input>
                                            </div> */}
                                        <div className={`flex-center m-b-10 gap-1 ${productionSheet.parameters.marge_to_add ? null : 'priority-input-container'}`}>
                                            <div>
                                                <label className='strong-2'>marge de la qté à ajouter (en %) : </label>
                                                <div className='subtext'>Marge de la quantité total à ajouter</div>
                                                <li className='subtext'>baume : 5%</li>
                                                <li className='subtext'>liquide : 2%</li>
                                            </div>
                                            <input
                                                className='strong-2'
                                                style={{ width: '80px', height: '30px' }}
                                                type='number'
                                                placeholder='2'
                                                value={productionSheet.parameters.marge_to_add}
                                                onInput={e => updateProductionSheetPropriety('parameters.marge_to_add', e.target.value)}>
                                            </input>
                                        </div>
                                        <div className={`flex-center m-b-10 gap-1`}>
                                            <div>
                                                <label className='strong-2'>Desité de produit finis (mesuré ou estimé) : </label>
                                                <div className='subtext'>La densité du produit finis est obligatoire si vous utilisez des actifs en masse/masse pour un produit finis en volume</div>
                                            </div>
                                            <input
                                                className='strong-2'
                                                style={{ width: '80px', height: '30px' }}
                                                type='number'
                                                placeholder='1'
                                                value={productionSheet?.article.density}
                                                step={0.00001}
                                                min={0.7}
                                                max={2}
                                                onInput={e => updateProductionSheetPropriety('article.density', e.target.value)}>
                                            </input>
                                        </div>
                                        {productionSheet.recipe.other_ingredients.find(ingredient => ingredient.infusion)
                                            ? <div className={`flex-center gap-1 ${productionSheet.parameters.marge_to_add_for_infusion ? null : 'priority-input-container'}`}>
                                                <div>
                                                    <label className='strong-2'>marge de la qté à ajouter pour l'infusion (en %) : </label>
                                                    <div className='subtext'>Marge de la quantité à ajouter pour les produits qui seront infusés puis filtrés</div>
                                                </div>
                                                <input
                                                    className='strong-2'
                                                    style={{ width: '80px', height: '30px' }}
                                                    type='number'
                                                    placeholder='15'
                                                    value={productionSheet.parameters.marge_to_add_for_infusion}
                                                    onInput={e => updateProductionSheetPropriety('parameters.marge_to_add_for_infusion', e.target.value)}>
                                                </input>
                                            </div>
                                            : null
                                        }
                                    </div>
                                }
                            </div>
                        </div >

                        {showIngredientItems || !productionSheet.creating
                            ? <div>
                                <h4 className='m-t-25'>Ingrédients</h4>
                                <div className='flex wrap'>
                                    <div className='half-m-width'>
                                        {
                                            recipe.cannabinoids.length > 0
                                                ? <>
                                                    <h4 className='m-b-10 center' >Cannabinoides</h4>
                                                    {recipe.cannabinoids.map((ingredient, index) =>
                                                        <IngredientItem
                                                            key={index}
                                                            setRecipe={setRecipe}
                                                            passedIngredient={{ ...ingredient, index: index, type: 'cannabinoids' }}
                                                            recipeArticle={productionSheet.article}
                                                            recipe={recipe}
                                                        />
                                                    )}
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className='half-m-width'>
                                        {
                                            recipe.bases.length > 0
                                                ? <>
                                                    <h4 className='m-b-10 center' >Base</h4>
                                                    {recipe.bases.map((ingredient, index) =>
                                                        <IngredientItem
                                                            key={index}
                                                            setRecipe={setRecipe}
                                                            passedIngredient={{ ...ingredient, index: index, type: 'bases' }}
                                                            recipeArticle={productionSheet.article}
                                                            recipe={recipe}
                                                        />
                                                    )
                                                    }
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className='full-width'>
                                    {recipe.other_ingredients.length > 0
                                        ? <>
                                            <h4 className='m-b-10 center' >Autres ingrédients</h4>
                                            <div className='flex wrap'>
                                                {recipe.other_ingredients.map((ingredient, index) =>
                                                    <IngredientItem
                                                        key={index}
                                                        setRecipe={setRecipe}
                                                        passedIngredient={{ ...ingredient, index: index, type: 'other_ingredients' }}
                                                        recipeArticle={productionSheet.article}
                                                        recipe={recipe}
                                                        style={{ width: '48%' }}
                                                    />
                                                )}
                                            </div>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                            : <h5>Ajouter une marge à votre production</h5>
                        }
                        {id.current
                            ? <button
                                className='strong-button mt-50'
                                onClick={e => goToUpdateRecipe()}
                            >Modifier la recette</button>
                            : null
                        }
                    </>
            }
        </div >
    )
}
