import React, { useContext, useEffect } from "react"
import "./Overlay.css"
import { PopupContext } from "Context/PopupContext.js"
import DeleteButton from "Components/DeleteButton/DeleteButton"

export default function Overlay(props) {
  /* CONTEXT */
  const { showPopup, togglePopup, popupContent } = useContext(PopupContext)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        showPopup(false);
      }
    };

    // Add event listener for keydown events
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showPopup]);

  return (
    <>
      {togglePopup
        ? <div onClick={_ => { showPopup(false) }} className="close-overlay">
          <DeleteButton color={`${popupContent.fullWidth ? 'black' : "white"}`} callBack={showPopup} alwaysDisplay={true} parameters={[false]} />
        </div>
        : null}
      <div className={togglePopup ? "overlay" : "overlay hidden-overlapping-element"}>
        {props.children}
      </div>
    </>
  )
}
