import React, { useEffect, useState } from 'react'
import ResultsFields from './ResultsFields';
import AddCertifactePDF from 'Components/_ANALYSE/Analyse/AddCertifactePDF';
import { ableToAddResults, cannabinoidsResultFields } from '../analysesConstantes';

export default function AnalyseFields({ analyse, setter }) {

    const [forceAddCertificate, toggleForceAddCertificate] = useState(false)

    const updateAnalyseKey = (value, key, subkey) => {
        let updatedAnalyse = { ...analyse }

        const operations = {
            'results': () => {
                updatedAnalyse.results = { ...analyse.results, [subkey]: value ? Number(value) : value }
                if (updatedAnalyse.produit) {
                    const currentCannabinoid = cannabinoidsResultFields.find(canna => canna.key === subkey)
                    if (currentCannabinoid.hasOwnProperty('legalThreshold') && Number(value) > currentCannabinoid.legalThreshold)
                        updatedAnalyse.produit.emplacement = 'Congélateur'
                }
                return updatedAnalyse
            },
            'produit': () => {
                updatedAnalyse.produit = { ...analyse.produit, [subkey]: value }
                return updatedAnalyse
            },
            'humidity': () => {
                updatedAnalyse[key] = value ? Number(value) : value
                return updatedAnalyse
            },
            'certificate_urls': () => {
                updatedAnalyse[key] = [...analyse[key], value]
                return updatedAnalyse
            },
            'default': () => {
                updatedAnalyse[key] = value
                return updatedAnalyse
            }
        }

        const operation = operations[key] || operations['default']
        updatedAnalyse = operation()
        setter(updatedAnalyse)
    }

    return (
        <div>
            <div className='flex-center'>
                {analyse.num_reference
                    ? <>
                        <div className='half-m-width'>
                            <div className='flex-center m-b-10'>
                                <label>N° de référence interne :</label>
                                <select value={analyse.num_reference} disabled>
                                    <option value="">{analyse.num_reference}</option>
                                </select>
                            </div>
                        </div>
                        <div className='half-m-width'>
                            <div className='flex-center m-b-10'>
                                <label>Emplacement de l'échantillon :</label>
                                <select value={analyse.produit.emplacement} onChange={e => updateAnalyseKey(e.target.value, 'produit', 'emplacement')}>
                                    <option value="Frigo">Frigo</option>
                                    <option value="Congélateur">Congélateur</option>
                                    <option value="Laboratoire">Laboratoire</option>
                                </select>
                            </div>
                        </div>
                    </>
                    : analyse.bulk_id
                        ? <div className='half-m-width flex-center m-b-10'>
                            <label>N° de bulk :</label>
                            <select value={analyse.bulk_id} disabled>
                                <option value="">{analyse.bulk_id}</option>
                            </select>
                        </div>
                        : analyse.batch_id
                            ? <div className='half-m-width flex-center m-b-10'>
                                <label>N° de batch :</label>
                                <select value={analyse.batch_id} disabled>
                                    <option value="">{analyse.batch_id}</option>
                                </select>
                            </div>
                            : null
                }
            </div>
            <div className='half-m-width'>
                <div className='flex-center m-b-10 m-t-10'>
                    <label>Date de l'analyse :</label>
                    <input
                        type="date"
                        value={analyse.date}
                        onChange={e => updateAnalyseKey(e.target.value, 'date')}
                        required
                    />
                </div>
            </div>
            <hr />
            {(ableToAddResults(analyse?.produit?.product_order?.product_id) || analyse.batch_id || analyse.bulk_id) && !forceAddCertificate
                ? <ResultsFields
                    analyse={analyse}
                    setter={updateAnalyseKey}
                />
                : <AddCertifactePDF
                    analyse={analyse}
                    setter={updateAnalyseKey}
                />
            }
            {!forceAddCertificate
                ? <div className='subtext'>
                    <div className='subtext'>Si vous avez obtenu une analyse sous format PDF que vosu ne pouvez pas indiquer dans le formulaire ci-dessous</div>
                    <button
                        type='button'
                        onClick={_ => toggleForceAddCertificate(prv => !prv)}
                        className='light-button'>
                        Mon analyse est un certificat PDF
                    </button>
                </div>
                : null}
        </div >
    )
}