import SelectOrganization from 'Components/_ERP/Brands/SelectOrganization'

export default function UserFields({ user, updateUserFunction, add = true }) {

  const setSelectedOrgId = (orgId) => {
    updateUserFunction("pipedrive_id", orgId)
  }

  // console.log("user", user)
  return (
    <>
      <div className='flex input'>
        <label>Identifiant</label>
        <input required={add} placeholder="Identifiant de l'utilisateur" value={user.login ?? ''} onChange={e => updateUserFunction("login", e.target.value)} />
      </div>
      <div className='flex input'>
        <label>Email</label>
        <input required={add} placeholder="Email de l'utilisateur" value={user.email ?? ''} onChange={e => updateUserFunction("email", e.target.value)} />
      </div>
      <div className='flex input'>
        <label>Mot de passe</label>
        <div className='column end'>
          <input required={add} placeholder="Mot de passe de l'utilisateur" value={user.password ?? ''} onChange={e => updateUserFunction("password", e.target.value)} />
          {!add
            ? <p className='m-t-10'>Laisser le mot de passe vide pour le conserver</p>
            : null}
        </div>
      </div>
      <div className='flex input'>
        <SelectOrganization
          updateOrganizationsFromPipedrive={true}
          defaultOrganizationId={user.pipedrive_id}
          setter={setSelectedOrgId}
          width='400px'
        />
      </div>
    </>
  )
}
