import React, { useEffect, useRef, useState } from 'react'
import { appConstante, productFields } from 'appConstante'
import Field from 'Components/_ERP/Field'
import { VariablesContext } from 'Context/ERP/VariablesContext'
import { useContext } from 'react'
import AdditionalData from '../../AdditionalData'
import InputSmall from 'Components/FormElement/SmallInput'
import { useCurrentAppPermission } from 'Functions'
import { v4 as uuidv4 } from 'uuid'

export default function ProductFields({ product, setter, column = false }) {

    const { variables } = useContext(VariablesContext)
    const currentAppPermission = useCurrentAppPermission()
    const isDisabled = currentAppPermission('erp') <= appConstante.parameters.CAN_EDIT

    const [additionnalDatas, setAdditionnalDatas] = useState([])
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setter("image", file);
            };
            reader.readAsDataURL(file);
        }
    }

    const emptyAdditionnalData = useRef({ label: "", value: "", id: uuidv4() })


    useEffect(() => {
        if (product.additional_datas) {
            typeof product.additional_datas === 'object'
                ? setAdditionnalDatas(product.additional_datas)
                : Array.isArray(JSON.parse(product.additional_datas))
                    ? setAdditionnalDatas(JSON.parse(product.additional_datas).map(data => ({ ...data, id: uuidv4() })))
                    : setAdditionnalDatas([])
        } else {
            setAdditionnalDatas([])
        }
    }, [product.additional_datas])

    const addAdditionnalInformation = () => {
        const newData = { ...emptyAdditionnalData.current, id: uuidv4() }
        setter('additional_datas', [...additionnalDatas, newData])
        emptyAdditionnalData.current = { label: "", value: "", id: uuidv4() } // Update the ref with a new UUID for the next addition
    }

    // Update AdditionalData
    const updateAdditionnalInformation = (key, index, value) => {
        setter('additional_datas', additionnalDatas.map((data, idx) =>
            idx === index
                ? { ...data, [key]: value }
                : data
        ))
    }
    // Delete AdditionalData
    const deleteCutomAdditionalDataProduct = (index) => {
        setter('additional_datas', additionnalDatas.filter((_, idx) => idx !== index))
    }

    return (
        <div className={column ? 'flex column justify-stretch' : "flex gap-1 justify-stretch"}>
            <div className='full-width'>
                {column
                    ? ''
                    : <h3>Informations produits</h3>
                }
                {productFields.map(field => (
                    <Field
                        label={field.name}
                        value={product[field.slug]}
                        setter={setter}
                        field={field.slug}
                        variables={variables.filter(variable => (variable.field === field.slug))}
                        type={field.type}
                        required={field.required}
                        key={field.slug}
                        border={column ? false : true}
                        disabled={isDisabled}
                    />
                ))}
            </div>
            <div className='full-width'>
                <div className='flex column full-height'>
                    <div>
                        {column
                            ? ''
                            : <h3>Informations additionnelles</h3>
                        }
                        <div className="flex input">
                            <InputSmall
                                label='Image'
                                setter={handleImageChange}
                                type='file'
                                accept="image/*"
                                disabled={isDisabled}
                            />
                        </div>
                        {product.image && (
                            <div className="flex-end">
                                <img
                                    src={product.image}
                                    alt="image actuelle"
                                    style={{ height: 100, width: 100 }}
                                />
                            </div>
                        )}
                        {additionnalDatas
                            ? additionnalDatas.map((additionnalData, index) => {
                                console.log("????", additionnalData.id);
                                return (
                                    <div key={`${additionnalData.id}`}>
                                        <AdditionalData
                                            index={index}
                                            additionalData={additionnalData}
                                            setter={updateAdditionnalInformation}
                                            deleteFunc={deleteCutomAdditionalDataProduct}
                                        />
                                    </div>
                                )
                            })
                            : null}
                    </div>
                    {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                        ? <div>
                            <button onClick={e => addAdditionnalInformation()} type="button" className="light-button m-b-0 m-t-25">Ajouter info additionnelle</button>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}
