import React, { useEffect, useContext } from 'react';
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar';
import { useNavigate } from 'react-router-dom';
import { useCurrentAppPermission } from 'Functions';
import { useLocation } from 'react-router-dom'
import { appConstante } from 'appConstante';

export default function Production() {
  const currentAppPermission = useCurrentAppPermission()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT) {
      location.pathname==="/erp/production" && navigate("/erp/production/commandes"); //Sous onglet par défaut
    } else {
      location.pathname==="/erp/production" && navigate("/erp/production/recherche"); //Sous onglet par défaut
    }
  }, [location.pathname])
  return (
    <>
      {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT ?
        <SubNavBar
          menu={[
            { path: '/erp/production/commandes', name: 'Commandes' },
            { path: '/erp/production/planning', name: 'Planning de production' },
            { path: '/erp/production/production-a-envoyer', name: 'Productions à expédier' },
            { path: '/erp/production/bons-de-livraisons', name: 'Bons de livraison' },
            { path: '/erp/production/recherche', name: 'Recherche' }
          ]}
          /> :
          <SubNavBar
          menu={[
            { path: '/erp/production/planning', name: 'Planning de production' },
            { path: '/erp/production/bons-de-livraisons', name: 'Bons de livraison' },
            { path: '/erp/production/recherche', name: 'Recherche' }
          ]}
        />
      }
    </>
  );
}