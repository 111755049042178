import React, { useState, useContext, useEffect } from 'react'
import { categories } from 'appConstante'
import ArticleFields from './Shared/ArticleFields'
import { NotificationContext } from 'Context/NotificationContext'
import { useCallApi } from 'Functions'
import { ArticleContext } from 'Context/ERP/ArticleContext'

export default function AddArticle({ setter, defaultCategory = '' }) {

    const { postArticle } = useContext(ArticleContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const [selectedCategory, selectCategory] = useState(false)
    const [article, setArticle] = useState({})

    const callApi = useCallApi()

    const checkArticle = (article) => {
        if (article.category === 'Produit fini') {
            // if (!article.cbd_quantity && !article.cbd_concentration) {
            //     addNotificationContent({
            //         error: true,
            //         title: "Erreur",
            //         content: 'Au moins un des champs \'Concentration en CBD\' ou \'Quantité de CBD\' doit être rempli',
            //         infinit: false
            //     })
            //     return false
            // }
            if (!article.brand_id) {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: 'La marque n\'est pas entrée correctement',
                    infinit: false
                })
                return false
            }
        }
        return true
    }

    const sendNewArticle = async e => {
        if (checkArticle(article)) {
            postArticle(article, e)
                .then(res => {
                    setter && setter(res)
                })
        }
    }

    const updateArticle = (key, value) => {
        setArticle({ ...article, [key]: value })
    }

    const setCategory = category => {
        selectCategory(category)
        updateArticle('category', category)
    }

    useEffect(() => {
        setCategory(defaultCategory)
    }, [defaultCategory])

    return (
        <>
            <h2>Ajouter un nouvel article</h2>

            <form onSubmit={e => sendNewArticle(e)}>
                <select
                    style={{ width: "300px", margin: '0 auto 25px', display: 'block' }}
                    value={selectedCategory} onChange={e => setCategory(e.target.value)}>
                    <option value=''>-- Sélectionner une catégory --</option>
                    {categories.map(category => (
                        <option key={category.name} value={category.name}>{category.name}</option>
                    ))}
                </select>
                {selectedCategory && (
                    <ArticleFields article={article} setter={setArticle} />
                )}
                <button className='strong-button m-t-25 m-b-25'>Ajouter l'article</button>
            </form>
        </>
    )
}
