import React, { useEffect } from 'react'
import NavBar from 'Components/NavBar/NavBar'
import { useNavigate, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router'

export default function Dashboard() {

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        location.pathname === "/dashboard" && navigate("/dashboard/revenue") //Sous onglet par défaut
    }, [location.pathname])

    return (
        <>
            <div className='flex'>
                <NavBar menu={[
                    { name: "Chiffre d'affaire", to: "/dashboard/revenue" },
                    { name: "Analyse", to: "/dashboard/analyse" },
                ]} />
            </div >
            <Outlet />
        </>
    )
}
