import React, { useContext, useState } from 'react'
import { RecipeContext } from 'Context/ERP/RecipeContext'
import './Recipe.css'
import RecipeFields from 'AppContainer/ERP/SpecificationsAndRecipe/Recipe/shared/RecipeFields'

export default function AddRecipe({ setter }) {
    const { postRecipe } = useContext(RecipeContext)
    const emptyRecipe = {
        name: null,
        description: null,
        bases: [],
        cannabinoids: [],
        other_ingredients: [],
        steps: []
    }
    const [recipe, setRecipe] = useState(emptyRecipe)

    return (
        <>
            <h2 className="flex justify-center m-b-25" >Création d'une recette</h2>
            <form onSubmit={e => postRecipe(e, recipe, setter)} className='m-w-1220'>
                <RecipeFields recipe={recipe} setRecipe={setRecipe} />
                <button className='strong-button m-b-15 m-t-25'>Ajouter la recette</button>
            </form>
        </>
    )
}
