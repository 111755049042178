import React, { useContext } from 'react'
import { PopupContext } from 'Context/PopupContext'
import CreateBatch from './CreateBatch'

export default function CreateBatchBtn({ defaultArticleId, setter }) {

    const { addPopupContent2 } = useContext(PopupContext)

    return (
        <button type='button' className='light-button' onClick={_ => addPopupContent2(<CreateBatch defaultArticleId={defaultArticleId} setter={setter} />)}>
            Créer un batch
        </button>
    )
}
