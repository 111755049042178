import React, { useContext, useEffect } from 'react'
import Input from 'Components/FormElement/Input'
import Datalist from 'Components/Dataliste/Dataliste'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import MidLoader from 'Components/MidLoader/MidLoader'
import { NotificationContext } from 'Context/NotificationContext'
import { ArticleContext } from 'Context/ERP/ArticleContext'

export default function IngredientField({ articles, ingredient, updateRecipeIngredient, index, deleteIngredient }) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { getArticle } = useContext(ArticleContext)


    useEffect(() => {
        if (ingredient.type === 'cannabinoid' && !ingredient.hasOwnProperty('fixed_concentration'))
            updateRecipeIngredient(index, 'fixed_concentration', false)
    }, [ingredient.fixed_concentration])

    const updateQuantity = (value) => {
        if (ingredient.type === 'cannabinoid' && value > 50) {
            addNotificationContent({
                error: true,
                content: 'La concentration ne peux pas dépasser 50%',
            })
        } else {
            updateRecipeIngredient(index, 'quantity', value)
        }
    }

    const selectedArticle = articles.find(foundArticle => foundArticle.id === ingredient.article_id)

    useEffect(() => {
        if (!ingredient.article && ingredient.article_id) {
            getArticle(ingredient.article_id)
                .then(article => updateRecipeIngredient(index, 'article', article))
        }
    }, [ingredient.article_id, ingredient.article])

    useEffect(() => {
        if (!ingredient.article_id) {
            updateRecipeIngredient(index, 'article', false)
        }
    }, [ingredient.article_id])

    useEffect(() => {
        if (ingredient.type === 'otherIngredient') {
            !ingredient.hasOwnProperty('quantity_type') && updateRecipeIngredient(index, 'quantity_type', 'volume')
            !ingredient.hasOwnProperty('infusion') && updateRecipeIngredient(index, 'infusion', 0)
        }
    }, [])

    return (
        ingredient
            ? <div>
                <div className='ingredient-fields'>
                    <div>
                        <Datalist
                            defaultId={ingredient.article_id}
                            placeholder={'Sélectionnez un article'}
                            items={articles.map(article => ({ id: article.id, value: article.name }))}
                            customParameters={[index, 'article_id']}
                            onSelect={updateRecipeIngredient}
                            value={selectedArticle ? selectedArticle.name : ""}
                            required
                            width='300px'
                        />
                        <div style={{
                            padding: '5px',
                            color: ingredient?.article?.density ? 'black' : 'rgba(180,120,120)'
                        }}> {
                                ingredient?.article?.density
                                    ? `Densité:${ingredient.article.density}`
                                    : ingredient.density
                                        ? `Densité:${ingredient.article.density}`
                                        : "! Il n'y a pas de densité pour ce produit !"
                            }
                        </div>

                    </div>
                    {ingredient.type === 'cannabinoid' &&
                        <select
                            className='m-t-25'
                            value={ingredient.fixed_concentration}
                            onChange={e => updateRecipeIngredient(index, 'fixed_concentration', e.target.value === 'true')}
                        >
                            <option value={true}>Concentration fixe</option>
                            <option value={false}>Concentration variable</option>
                        </select>
                    }
                    {ingredient.type === 'cannabinoid' && ingredient.fixed_concentration || ingredient.type !== 'cannabinoid'
                        ? <div>
                            <Input
                                label={
                                    ingredient.type === 'cannabinoid'
                                        ? 'Concentration cible (%)'
                                        : ingredient.type === 'base'
                                            ? 'Répartition entre les bases (%)'
                                            : 'Quantité (%)'}
                                value={ingredient.quantity}
                                onInput={updateQuantity}
                                type='number'
                                step='0.001'
                                min="0"
                                style={{ width: '300px' }}
                                required
                            />
                        </div>
                        : null}
                    {
                        ingredient.type === 'otherIngredient'
                            ? <div className='m-t-25 flex gap-1'>
                                <select
                                    value={ingredient.quantity_type}
                                    onChange={e => updateRecipeIngredient(index, 'quantity_type', e.target.value)}>
                                    <option value={'volume'}>% de volume /UPF</option>
                                    <option value={'masse'}>% de masse / UPF</option>
                                    <option value={'masse/masse'}>% de masse / masse</option>
                                </select>
                                <select
                                    value={ingredient.infusion}
                                    onChange={e => updateRecipeIngredient(index, 'infusion', parseInt(e.target.value))}
                                >
                                    <option value={0}>Ce n'est pas à infuser</option>
                                    <option value={1}>C'est à infuser avec la base</option>
                                    <option value={2}>C'est à infuser avec le produit fini</option>
                                </select>
                            </div>
                            : null
                    }
                    <div className='self-center'>
                        <DeleteButton alwaysDisplay={true} callBack={deleteIngredient} parameters={[index]} />
                    </div>
                </div>
            </div>
            : <MidLoader />
    )
}
