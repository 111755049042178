import React, { useState, useEffect, useContext, useRef } from "react"
import { appConstante } from "appConstante"
import { PopupContext } from "Context/PopupContext"
import InvoiceDetails from "AppContainer/Invoices/InvoiceDetails/InvoiceDetails"
import { useCallPennylaneApi, useCallPipedriveApi } from "Functions"
import Input from "Components/FormElement/Input"
import MidLoader from "Components/MidLoader/MidLoader"
import Pagination from "Components/Pagination/Pagination"
import SelectOrganization from "Components/_ERP/Brands/SelectOrganization"

export default function MyInvoices() {

  const callPennylaneApi = useCallPennylaneApi()
  const callPipedriveApi = useCallPipedriveApi()

  /* Constante */
  const [invoices, setinvoices] = useState(false)
  const [customers, setCustomers] = useState(false)
  const [searchedDealId, setSearchedDealId] = useState('')
  const [selectedCustomers, setSelectedCustomers] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(false)
  const [error, setError] = useState(null)

  // const quoteId = appConstante.pennylane.IDENTIFIANT_PENNYALNE

  const timeoutHandle = useRef()

  const getInvoicesFromPennylane = (signalController = null) => {
    setinvoices(null)
    const data = {
      page: currentPage,
      per_page: "20",
      filter: getFilters(),
    }
    callPennylaneApi("customer_invoices", { method: 'get', signal: signalController }, data)
      .then((res) => {
        setinvoices(res.data.invoices)
        setTotalPage(res.data.total_pages)
      })
  }

  /* GET INVOICES  */
  useEffect(() => {
    const controller = new AbortController()
    setError(null)
    const delay = selectedInvoice ? 400 : 0
    clearTimeout(timeoutHandle.current)
    timeoutHandle.current = setTimeout(() => {
      // Delay pour éviter les requêtes infernal si recherche invoice
      getInvoicesFromPennylane(controller.signal)
    }, delay)

    return () => {
      controller.abort()
    }
  }, [currentPage, selectedCustomers, selectedInvoice])

  /* GET CUSTOMERS */
  useEffect(() => {
    const controller = new AbortController()
    callPennylaneApi("customers", { signal: controller.signal })
      .then((res) => {
        setCustomers(
          res.data.customers.map((customer) => ({
            value: customer.name,
            id: customer.source_id,
          }))
        )
      })

    return () => {
      controller.abort()
    }
  }, [])

  /* Réinitialisation de la page à 1 si modification d'un filtre */
  useEffect(() => {
    setCurrentPage(1)
  }, [selectedCustomers, selectedInvoice])

  const getFilters = () => {
    let filter = []
    if (selectedInvoice) {
      filter = [
        {
          field: "invoice_number",
          operator: "eq",
          value: selectedInvoice,
        },
      ]
    } else if (selectedCustomers) {
      filter = [
        {
          field: "customer_id",
          operator: "eq",
          value: selectedCustomers,
        },
      ]
    }
    return JSON.stringify(filter)
  }

  /* GET QUOTES */
  const getInvoiceFromDealId = (dealId, signalController = null) => {
    const controller = new AbortController()
    setinvoices(null)
    callPipedriveApi(`deals/${dealId}`, { option: signalController })
      .then((res) => {
        if (res.data && res.data.data && res.data.data[appConstante.pennylane.IDENTIFIANT_PENNYALNE]) {
          callPennylaneApi(`customer_invoices/${res.data.data[appConstante.pennylane.IDENTIFIANT_PENNYALNE]}`, { method: 'get', signal: controller.signal })
            .then((res) => {
              setinvoices([res.data.invoice])
            })
        } else {
          setinvoices([])
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError("Le devis n'existe pas")
        }
        else { setError("Une erreur est survenue") }
      })
  }

  useEffect(() => {
    const controller = new AbortController()
    setError(null)
    if (searchedDealId) {
      clearTimeout(timeoutHandle.current)
      timeoutHandle.current = setTimeout(() => {
        getInvoiceFromDealId(searchedDealId, controller.signal)
      }, 400)
    } else {
      getInvoicesFromPennylane(controller.signal)
    }

    return () => {
      controller.abort()
    }

  }, [searchedDealId])

  /* CONTEXT */
  const { addPopupContent } = useContext(PopupContext)

  return (
    <>
      <div
        className="flex"
        style={{ justifyContent: "start", marginBottom: "25px" }}
      >
        <Input
          style={{ maxWidth: "250px", marginRight: "25px" }}
          label="N° de facture"
          value={selectedInvoice}
          onInput={setSelectedInvoice}
        />
        <SelectOrganization
          style={{ maxWidth: "250px", marginRight: "25px" }}
          setter={setSelectedCustomers}

        />
        {/* <Datalist
          style={{ marginRight: "25px" }}
          placeholder="Nom de l'organisation"
          items={customers}
          onSelect={setSelectedCustomers}
        /> */}
        <Input
          style={{ maxWidth: "250px", marginRight: "25px" }}
          label="N° de devis"
          value={searchedDealId}
          onInput={setSearchedDealId}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
      />

      {error
        ? <h5>{error}</h5>
        : !invoices
          ? <MidLoader />
          : !invoices.length
            ? <h5>Aucune facture</h5>
            : <>

              <table className="beautiful-array">
                <thead>
                  <tr style={{ height: "40px" }}>
                    <th />
                    <th style={{ textAlign: "left", width: "100px" }}>Numéro</th>
                    {/* <th style={{textAlign: 'left'}}>Libélé</th> */}
                    <th style={{ textAlign: "left" }}>Client</th>
                    {/* <th>Montant HT</th> */}
                    <th>Montant TTC</th>
                    <th>Reste du</th>
                    <th>date de facturation</th>
                    <th>Status</th>
                    <th>Fichier</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    invoices.map((invoice) => {
                      let date = new Date(invoice.date)
                      date = date.toLocaleDateString("fr-FR")

                      return (
                        <tr key={invoice.id}>
                          <td>
                            <button
                              onClick={() =>
                                addPopupContent(
                                  <InvoiceDetails invoiceId={invoice.id} />,
                                  "1220px"
                                )
                              }
                            >
                              Détails
                            </button>
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {invoice.invoice_number}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {invoice.customer ? invoice.customer.name : "-"}
                          </td>
                          {/* <td style={{textAlign: 'left'}}>{invoice.label.replace("(label généré)", "")}</td> */}
                          {/* <td>{invoice.currency_amount_before_tax}</td> */}
                          <td>{invoice.currency_amount}</td>
                          <td>{invoice.remaining_amount}</td>
                          <td>{date}</td>
                          <td>{invoice.status}</td>
                          <td>
                            <a
                              target="_blank"
                              href={invoice.file_url}
                              rel="noreferrer"
                            >
                              Ouvrir
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </>
      }
    </>
  )
}
