import React, { useContext, useEffect, useState } from 'react'
import { formatNumber, removeDuplicatesObjectInArray, useCallApi, useCurrentAppPermission } from 'Functions'
import { appConstante, getStockUnitOfMeasure } from 'appConstante'
import { Chart } from "react-google-charts"
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { ErpBatchContext } from 'Context/ERP/ErpBatchContext'
import AccordionTable from 'Components/AccordionTable/AccordionTable'
import Datalist from 'Components/Dataliste/Dataliste'
import UpdateHistory from 'AppContainer/ERP/Stocks/ImportsAndExport/History/UpdateHistory'
import AddHistory from 'AppContainer/ERP/Stocks/ImportsAndExport/History/AddHistory'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import Select from 'Components/FormElement/Select'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function ConsultHistory({ article, product, setter }) {

    const { showPopup2 } = useContext(PopupContext)
    const { updateBatch, deleteBatch } = useContext(ErpBatchContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi();
    const [formatedData, setFormatedData] = useState()
    const [rawHistory, setRawHistory] = useState([])
    const [cumulativeView, setCumulativeView] = useState('false');
    const [cumulativeDataChart, setCumulativeDataChart] = useState()
    const [filteredBatchQty, setFilteredBatchQty] = useState(true)
    const [formatedHistory, setFormatedHistory] = useState()
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [dataChart, setDataChart] = useState()
    const [options, setOptions] = useState()
    const [historyStatistics, setHistoryStatistics] = useState({})

    const currentAppPermission = useCurrentAppPermission()
    const { addPopupContent2 } = useContext(PopupContext)
    const isCumulativeView = () => cumulativeView === 'true'

    const updateFormatedHistory = (newBatch) => {
        setFormatedHistory(psHistoryArr => psHistoryArr.map(batch => batch.id === newBatch.id ? { ...batch, ...newBatch } : batch))
    }

    const updateProductFromHistory = (newHistory) => {
        showPopup2(false)
        newHistory?.batch?.article?.id && setter(newHistory?.batch?.article)
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product/${newHistory?.batch?.product_id}`)
            .then(res => {
                setFormatedHistory(prvHistories =>
                    removeDuplicatesObjectInArray("id", [...res.data.data.history.map(history => ({ ...history, product: res.data.data })), ...prvHistories])
                )
            })
    }

    const getProductRawHistory = (productId, SignalController = null) => {
        return new Promise(resolve => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product/${productId}/history`, { method: 'get' }, SignalController, { group_by: 'raw' })
                .then(res => {
                    resolve(res.data.data)
                })
        })
    }

    const updateHistory = (newHistory) => {
        showPopup2(false)
        updateProductFromHistory(newHistory)
        getProductRawHistory(selectedSupplier)
            .then(graphDatas => {
                setFormatedData(formatRawHistory(graphDatas))
            })
    }

    const formatRawHistory = (graphDatas) => {
        let total = null
        let price = null
        let batchQuantity = {}
        const data = []
        graphDatas.forEach(history => {

            const quantity = parseFloat(history.quantity).toFixed(4) * 1

            total += quantity
            price = (history.price > 0) ? parseFloat(history.price) / quantity : price
            data.push([new Date(history.date), total, price])
        })
        console.log(total);
        console.log(batchQuantity);
        data.push([new Date(), total, price ? price : 0])
        return data
    }

    const updateBatchNumber = (batch) => {
        updateFormatedHistory({ ...batch, updating: !batch.updating })
        if (batch.updating) {
            const updatedBatch = { id: batch.id, batch_number: batch.batch_number }
            updateBatch(updatedBatch)
                .then(batch => {
                    updateFormatedHistory({ ...batch })
                    setter(article)
                })
                .catch(err => {
                    console.log(err)
                    addNotificationContent({
                        error: true,
                        content: "Erreur lors de la modification du numéro de lot"
                    })
                })
        }
    }

    const deleteBatchFunc = (currentBatch) => {
        window.confirm(`Êtes-vous sûr de vouloir supprimer le n° de lot ${currentBatch.batch_number} ?`)
            && deleteBatch(currentBatch.id)
                .then(res => {
                    setter(currentBatch.article)
                    setFormatedHistory(prevState => prevState.filter(batch => batch.id !== currentBatch.id))
                }
                )
                .catch(err => {
                    console.log(err)
                    addNotificationContent({
                        error: true,
                        content: "Erreur lors de la suppression"
                    })
                })
    }

    useEffect(() => {
        if (product) {
            setFormatedHistory(product.history.map(historyItem => ({ ...historyItem, product: product, updating: false })))
        } else {
            const allHistories = article.products.reduce((acc, product) =>
                acc.concat(product.history.map(historyItem => ({ ...historyItem, product: product, updating: false }))),
                []
            );
            setFormatedHistory(allHistories)
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController()

        if (article?.products && !selectedSupplier && !product?.id) {
            Promise.all(article.products.map(product => getProductRawHistory(product.id, controller.signal)))
                .then(rawHistory => {
                    console.log("rawHistory article.products", rawHistory.flat());
                    setRawHistory(rawHistory.flat());
                    setFormatedData(formatRawHistory(rawHistory.flat()))
                })
        }

        return () => {
            controller.abort()
        }
    }, [article])

    useEffect(() => {
        const controller = new AbortController()
        if (selectedSupplier) {
            getProductRawHistory(selectedSupplier, controller.signal)
                .then(rawHistory => {
                    console.log("rawHistory selectedSupplier", rawHistory);
                    setRawHistory(rawHistory);
                    setFormatedData(formatRawHistory(rawHistory))
                })
        }

        return () => {
            controller.abort()
        }
    }, [selectedSupplier])

    useEffect(() => {
        const controller = new AbortController()

        if (product?.id) {
            getProductRawHistory(product.id, controller.signal)
                .then(rawHistory => {
                    console.log("rawHistory product.id", rawHistory);
                    setRawHistory(rawHistory);
                    setFormatedData(formatRawHistory(rawHistory))
                })
        }
        return () => {
            controller.abort()
        }
    }, [product?.id])

    useEffect(() => {
        const statistics = {
            'tot_input': 0,
            'tot_output': 0,
            '1_year_cons': 0,
            '6_mounths_cons': 0,
            '3_mounths_cons': 0,
            'tot_mean_mounth_consumption': 0,
            '1_year_mean_mounth_cons': 0,
            '6_mounths_mean_mounth_cons': 0,
            '3_mounths_mean_mounth_cons': 0,
        }
        const exportMounth = {}
        const today = new Date()

        rawHistory
            .filter(history => !history.exclude_from_stats) //N'est pas exclu des stats
            .forEach(history => {
                const date = new Date(history.date)
                const key = `${date.getFullYear()}-${date.getMonth() + 1}`;

                const quantity = history.quantity
                const input = quantity > 0 ? quantity : 0
                const output = quantity < 0 ? quantity * -1 : 0

                if (!exportMounth[key]) {
                    exportMounth[key] = {
                        input: input,
                        output: output,
                        date: new Date(key),
                        key: key,
                    }
                } else {
                    exportMounth[key].input += input
                    exportMounth[key].output += output
                }

                statistics.tot_input += input
                statistics.tot_output += output

                if (date.setMonth(date.getMonth() + 3) >= today) { //3
                    statistics['3_mounths_cons'] += output
                }
                if (date.setMonth(date.getMonth() + 3) >= today) { //6
                    statistics['6_mounths_cons'] += output
                }
                if (date.setMonth(date.getMonth() + 6) >= today) {
                    statistics['1_year_cons'] += output
                }
            })

        statistics['3_mounths_mean_mounth_cons'] = statistics['3_mounths_cons'] / 3
        statistics['6_mounths_mean_mounth_cons'] = statistics['6_mounths_cons'] / 6
        statistics['1_year_mean_mounth_cons'] = statistics['1_year_cons'] / 12

        setHistoryStatistics(statistics)
        setCumulativeDataChart(Object.values(exportMounth))
    }, [rawHistory])

    useEffect(() => {
        if (formatedData) {
            console.log("formatedData", formatedData);
            setDataChart([
                [
                    { type: "date", label: "Date" },
                    "Quantité en stock",
                    "Prix moyen",
                ],
                ...formatedData.sort((a, b) => a[0] - b[0])
            ])

            setOptions({
                title: "Évolution du stock au cours de du temps",
                curveType: "none",
                legend: { position: 'top', textStyle: { fontSize: 16 } },
                animation: {
                    startup: true
                },
                seriesType: isCumulativeView() ? "bars" : 'line',
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: isCumulativeView() ? 0 : 1 }
                },
                vAxes: {
                    0: { title: `${isCumulativeView() ? 'Entrées/Sorties' : 'Stock'} (${getStockUnitOfMeasure(article)})` },
                    1: isCumulativeView() ? null : { title: "Prix (€)" }
                },
                hAxis: {
                    slantedText: true,
                    slantedTextAngle: 30
                },
                animation: {
                    duration: 250,
                    easing: 'out'
                },
                bar: {
                    groupWidth: '90%',
                    maxWidth: '100',
                    stroke: '#000',
                    strokeWidth: 1,
                },
                axes: {
                    y: {
                        stock: {
                            label: `Stock (${getStockUnitOfMeasure(article)})`,
                            viewWindow: {
                                min: 0
                            }
                        },
                        price: { label: "Prix (€)", minValue: 0 },
                    },
                    x: {
                        0: {
                            label: 'Date (mois)',
                            slantedText: true,
                            slantedTextAngle: 45,
                            titleTextStyle: {
                                color: '#333'
                            }
                        }
                    }
                },
                vAxis: {
                    viewWindow: {
                        min: 0
                    }
                }
            })
        }
    }, [formatedData, cumulativeView])

    return (
        formatedHistory
            ? formatedHistory.length && dataChart && options && historyStatistics
                ? <div className='m-b-25'>
                    {!product &&
                        <div className='flex-center'>
                            {removeDuplicatesObjectInArray("product_id", formatedHistory).length > 1
                                ? <Datalist
                                    placeholder='Filtrer par fournisseur'
                                    width='400px'
                                    items={formatedHistory
                                        && removeDuplicatesObjectInArray(
                                            "id",
                                            formatedHistory.map(product =>
                                                ({ value: product.product_supplier, id: product.product_id }))
                                        )}
                                    onSelect={setSelectedSupplier}
                                />
                                : null}
                            <div>
                                <Select
                                    value={cumulativeView}
                                    onChange={setCumulativeView}
                                    style={{ width: '300px', marginTop: 0 }}
                                >
                                    <option value={'false'}>Vue linéaire</option>
                                    <option value={'true'}>Vue par mois</option>
                                </Select>
                            </div>
                        </div>
                    }
                    <Chart
                        chartType={isCumulativeView() ? "ComboChart" : "LineChart"}
                        width="100%"
                        height="400px"
                        data={isCumulativeView()
                            ? [
                                ["Date", "Entrée", 'Sortie'],
                                ...cumulativeDataChart.map((mouvement, index) => [mouvement.date, mouvement.input, mouvement.output]),
                            ]
                            : dataChart}
                        options={options}
                    />
                    <div className='input m-t-10'>
                        <label className='flex center'>
                            <input
                                className='erp-check'
                                type='checkbox'
                                checked={filteredBatchQty}
                                onChange={(e) => setFilteredBatchQty(e.target.checked)}
                            />
                            Afficher seulement les n° lots en stock
                        </label>
                    </div>
                    <AccordionTable
                        columns={[
                            { title: "Numéro de lot", style: { textAlign: "left" }, colSpan: 2 },
                            { title: "Fournisseur", style: { textAlign: "center" }, colSpan: 2 },
                            { title: 'DDM', colSpan: 1 },
                            { title: 'Quantité', colSpan: 4 },
                            { title: 'Prix', colSpan: 2 },
                            { title: '', colSpan: 2 },
                        ]}
                        datas={formatedHistory
                            .filter(batch => selectedSupplier ? batch.product_id === selectedSupplier : true)
                            .filter(batch => filteredBatchQty ? batch.quantity > 0 : true)
                            .map((batch, index) => {
                                return {
                                    mainLine: [
                                        {
                                            element:
                                                <div className='flex justify-start gap-1'>
                                                    {batch.updating
                                                        ? <input value={batch.batch_number} onInput={e => updateFormatedHistory({ ...batch, batch_number: e.target.value })} />
                                                        : <div className='left'>
                                                            <div>{batch.batch_number}</div>
                                                            <div className='subtext'>{batch.id}</div>
                                                        </div>
                                                    }
                                                    <button className={batch.updating ? '' : 'show-on-hover'} onClick={() => updateBatchNumber({ ...batch })}>{batch.updating ? 'Valider ✅' : '✎'}</button>
                                                </div>,
                                            colSpan: 2
                                        },
                                        { element: <>{batch.product_supplier}</>, colSpan: 2 },
                                        { element: <>{batch.ddm ? new Date(batch.ddm).toLocaleDateString("fr") : null}</> },
                                        {
                                            element: <> {
                                                getStockUnitOfMeasure(article)
                                                    ? `${batch.quantity} ${getStockUnitOfMeasure(article)}`
                                                    : batch.quantity}</>, colSpan: 3
                                        },
                                        { element: <>{batch.price}</>, colSpan: 4 },
                                        { element: <DeleteButton callBack={deleteBatchFunc} parameters={[batch]} />, colSpan: 3 }
                                    ],
                                    subLines: currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                                        ? [
                                            [
                                                {
                                                    element:
                                                        <button
                                                            onClick={() =>
                                                                addPopupContent2(
                                                                    <AddHistory
                                                                        defaultHistory={{
                                                                            article_id: article.id,
                                                                            product_id: batch.product.id,
                                                                            batch_id: batch.id,
                                                                            history_type: 'remove',
                                                                        }}
                                                                        setter={updateHistory}
                                                                    />)
                                                            }
                                                            className='light-button'>Ajouter un historique</button>, colSpan: 100
                                                }
                                            ],
                                            [
                                                { element: null, colSpan: 1 },
                                                { element: (<div className='strong-2'>Date</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Quantité</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Prix d'achat</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Commentaire</div>), colSpan: 10 },
                                            ],
                                            ...batch.history
                                                .filter(history => !history.archive)
                                                .map(history => (
                                                    [
                                                        {
                                                            element:
                                                                (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                                                                    && <button onClick={() =>
                                                                        addPopupContent2(
                                                                            <UpdateHistory
                                                                                history={{
                                                                                    ...history,
                                                                                    product_id: batch.product.id,
                                                                                    article_id: article.id,
                                                                                }}
                                                                                setter={updateProductFromHistory}
                                                                            />)
                                                                    }
                                                                        className='light-button show-on-hover'>
                                                                        Modifier
                                                                    </button>), colSpan: 1
                                                        },
                                                        {
                                                            element:
                                                                <>
                                                                    {new Date(history.date).toLocaleDateString("fr")}
                                                                </>, colSpan: 1
                                                        },
                                                        {
                                                            element: <>{getStockUnitOfMeasure(article)
                                                                ? `${history.quantity} ${getStockUnitOfMeasure(article)}`
                                                                : history.quantity}</>, colSpan: 1
                                                        },
                                                        { element: <>{history.price > 0 ? `${history.price} €` : null}</>, colSpan: 1 },
                                                        { element: <>{history.comment ? history.comment : ""}</>, colSpan: 10 },
                                                    ]
                                                )),
                                        ]
                                        : [
                                            [
                                                { element: null, colSpan: 1 },
                                                { element: (<div className='strong-2'>Date</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Quantité</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>DDM</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Prix d'achat</div>), colSpan: 1 },
                                                { element: (<div className='strong-2'>Commentaire</div>), colSpan: 10 },
                                            ],
                                            ...batch.history.find(history => !history.archive)
                                                .map(history => (
                                                    [
                                                        {
                                                            element: (
                                                                currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                                                                && <button
                                                                    onClick={() =>
                                                                        addPopupContent2(
                                                                            <UpdateHistory
                                                                                history={history}
                                                                                setter={updateHistory}
                                                                            />)}
                                                                    className='light-button show-on-hover'
                                                                >Modifier</button>),
                                                            colSpan: 1
                                                        },
                                                        { element: <>{new Date(history.date).toLocaleDateString("fr")}</>, colSpan: 1 },
                                                        { element: <>{getStockUnitOfMeasure(article) ? `${history.quantity} ${getStockUnitOfMeasure(article)}` : history.quantity}</>, colSpan: 1 },
                                                        { element: <>{history.expiration_date ? new Date(history.expiration_date).toLocaleDateString("fr") : null}</>, colSpan: 1 },
                                                        { element: <>{history.price > 0 ? `${history.price} €` : null}</>, colSpan: 1 },
                                                        { element: <>{history.comment ? history.comment : ""}</>, colSpan: 4 },
                                                    ]
                                                ))
                                        ]
                                }
                            })
                        }
                    />
                    {
                        currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                        <div className='center m-t-10'>
                            <button
                                className='light-button'
                                onClick={() =>
                                    addPopupContent2(
                                        <AddHistory
                                            defaultHistory={{
                                                article_id: article.id,
                                                history_type: 'add',
                                            }}
                                            setter={updateHistory}
                                        />)}
                            >
                                Ajouter un historique
                            </button>
                        </div>
                    }
                    <div>
                        <div className='flex mediumtext list-item-bg m-t-10'>
                            <div>
                                <div className='m-b-10'>Consommation sur les 12 derniers mois :</div>
                                <li className='strong-2'>{formatNumber(historyStatistics['1_year_mean_mounth_cons'])} {getStockUnitOfMeasure(article)} en moyenne par mois </li>
                                <li className='strong-2'>{formatNumber(historyStatistics['1_year_cons'])} {getStockUnitOfMeasure(article)} au total </li>
                            </div>
                            <div>
                                <div className='m-b-10'>Consommation sur les 6 derniers mois :</div>
                                <li className='strong-2'>{formatNumber(historyStatistics['6_mounths_mean_mounth_cons'])} {getStockUnitOfMeasure(article)} en moyenne par mois </li>
                                <li className='strong-2'>{formatNumber(historyStatistics['6_mounths_cons'])} {getStockUnitOfMeasure(article)} au total </li>
                            </div>
                            <div>
                                <div className='m-b-10'>Consommation sur les 3 derniers mois :</div>
                                <li className='strong-2'>{formatNumber(historyStatistics['3_mounths_mean_mounth_cons'])} {getStockUnitOfMeasure(article)} en moyenne par mois </li>
                                <li className='strong-2'>{formatNumber(historyStatistics['3_mounths_cons'])} {getStockUnitOfMeasure(article)} au total </li>
                            </div>
                        </div>
                    </div>
                </div>
                : <h5>Pas d'historique</h5>
            : < MiniLoader />
    )
}
