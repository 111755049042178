import React, { useContext, useEffect, useState } from 'react'
import { useCallPipedriveApi, isAnalyseDeal, isDateInLastMonths, isCurrentMonth } from 'Functions'
import MidLoader from 'Components/MidLoader/MidLoader'
import { Chart } from "react-google-charts";
import '../chart.css';
import { appConstante } from 'appConstante';
import regression from 'regression';
import Select from 'Components/FormElement/Select';
import Datalist from 'Components/Dataliste/Dataliste';
import { PopupContext } from 'Context/PopupContext';
import OrganizationsDeals from 'Components/_DASHBOARD/OrganizationsDeals/OrganizationDeals';

interface Deal {
  value: number;
  won_time: string;
  pipeline_id: number;
  date: Date;
  total_ca: number;
  mb_ca: number;
  analyse_ca: number;
  key: string;
}

interface DataSummary {
  total_ca: number,
  total_mb: number,
  total_analyse: number,
  total_ca_3_mounths: number,
  total_mb_3_mounths: number,
  total_analyse_3_mounths: number,
  total_ca_6_mounths: number,
  total_mb_6_mounths: number,
  total_analyse_6_mounths: number,
}

interface OrganizationsRevenu {
  id: number,
  total_ca: number,
  mb_ca: number,
  analyse_ca: number,
  name: string,
  analyse: boolean,
  white_label: boolean,
  deals: Array<object>,
}

export default function Revenue() {

  const callPipedriveApi = useCallPipedriveApi()
  const { addPopupContent } = useContext(PopupContext)

  const [regressionValues, setRegressionValues] = useState<object | null>(null);
  const [organizationsRevenu, setOrganizationsRevenu] = useState<OrganizationsRevenu[] | null>(null);
  const [selectedOrganization, selectOrganization] = useState<OrganizationsRevenu | null>(null)
  const [cumulativeView, setCumulativeView] = useState<string>('false');

  const [showLastYearDeal, setShowLastYearDeal] = useState<boolean>(false)
  const [previousYearDeals, setPreviousYearDeal] = useState<Deal[] | null>(null);
  const [formatedLastYearDeal, setFormatedLastYearDeal] = useState<Array<Deal> | null>(null)

  const [deals, setDeals] = useState<Deal[] | null>(null);
  const [formatedDeals, setFormatedDeals] = useState<Array<Deal> | null>(null)
  const [dataSummury, setDataSummury] = useState<DataSummary | null>(null)

  const currentMonth = (new Date()).getMonth()

  const digitOptions = {
    style: 'decimal',
    maximumFractionDigits: 2, // Spécifiez le nombre de décimales souhaité
  };

  /********** HELPERS ********/
  const setSelectedOrganizationId = (id: number) => {
    const currentOganization = organizationsRevenu?.find(organization => organization.id === id)
    selectOrganization(_ => currentOganization ? currentOganization : null)
  }

  const isCumulativeView = () => cumulativeView === 'true'

  const loadDeals = async (filter_id: number) => {
    let allDealsLoaded = false
    let start = 0
    const allDeals = []
    const resultPerPage = 500

    while (!allDealsLoaded) {
      const res = await getDeals(filter_id, start, resultPerPage)
      res.data.data && allDeals.push(...res.data.data)
      if (res.data.additional_data.pagination.more_items_in_collection) {
        start++
      } else {
        allDealsLoaded = true
      }
    }
    return allDeals
  }

  const getDeals = async (filter_id: number, start: number, resultPerPage: number) => {
    return callPipedriveApi(
      'deals',
      { method: "get" },
      {
        filter_id: filter_id,
        sort: 'won_time DESC',
        limit: resultPerPage,
        start: start * resultPerPage,
      }
    );
  };

  /***************************/

  useEffect(() => {
    loadDeals(371).then(deals => setDeals(deals))
    loadDeals(374).then(deals => setPreviousYearDeal(deals))
  }, [])


  const showDealsDetails = () => {
    addPopupContent(<OrganizationsDeals defaultOrganizations={organizationsRevenu} />)
  }

  /*************************/

  useEffect(() => {
    if (formatedDeals) {
      const regressionValues = regression.linear(
        formatedDeals
          .reverse()
          .filter((_, index) => index + 1 !== formatedDeals.length)
          .map((deal, index) => [index, deal.total_ca])
      )
      setRegressionValues(regressionValues);
    }
  }, [formatedDeals])

  useEffect(() => {
    if (previousYearDeals) {
      const formatDeals = {}
      previousYearDeals.forEach(deal => {

        const date = new Date(deal.won_time);
        const key: string = `${date.getFullYear()}-${date.getMonth() + 1}`;
        const dealAnalyseCA = isAnalyseDeal(deal) ? deal.value : 0
        const dealMBCA = isAnalyseDeal(deal) ? 0 : deal.value

        if (!formatDeals[key]) {
          formatDeals[key] = {
            mb_ca: dealMBCA,
            analyse_ca: dealAnalyseCA,
            total_ca: dealMBCA + dealAnalyseCA,
            date: new Date(key),
            key: key,
          }
        } else {
          formatDeals[key].mb_ca += dealMBCA
          formatDeals[key].analyse_ca += dealAnalyseCA
          formatDeals[key].total_ca += dealMBCA + dealAnalyseCA
        }

      })

      setFormatedLastYearDeal(Object.values(formatDeals))
    }
  }, [previousYearDeals])

  useEffect(() => {
    if (deals) {
      const formatDeals = {}
      const formatOrganizationRevenue = {}

      let total_ca = 0
      let total_mb = 0
      let total_analyse = 0

      let total_ca_3_mounths = 0
      let total_mb_3_mounths = 0
      let total_analyse_3_mounths = 0

      let total_ca_6_mounths = 0
      let total_mb_6_mounths = 0
      let total_analyse_6_mounths = 0


      deals
        .forEach(deal => {
          const date = new Date(deal.won_time);
          const key: string = `${date.getFullYear()}-${date.getMonth() + 1}`;

          let dealAnalyseCA = isAnalyseDeal(deal) ? deal.value : 0
          let dealMBCA = isAnalyseDeal(deal) ? 0 : deal.value

          if (!isCurrentMonth(date)) {
            if (isDateInLastMonths(date, 3)) { //Deal since 3 mounth
              total_ca_3_mounths += dealMBCA + dealAnalyseCA
              total_mb_3_mounths += dealMBCA
              total_analyse_3_mounths += dealAnalyseCA
            }
            if (isDateInLastMonths(date, 6)) { //Deal since 6 mounth
              total_ca_6_mounths += dealMBCA + dealAnalyseCA
              total_mb_6_mounths += dealMBCA
              total_analyse_6_mounths += dealAnalyseCA
            }
          }

          if (!formatDeals[key]) {
            formatDeals[key] = {
              mb_ca: dealMBCA,
              analyse_ca: dealAnalyseCA,
              total_ca: dealMBCA + dealAnalyseCA,
              date: new Date(key),
              key: key,
            }
          } else {
            formatDeals[key].mb_ca += dealMBCA
            formatDeals[key].analyse_ca += dealAnalyseCA
            formatDeals[key].total_ca += dealMBCA + dealAnalyseCA
          }

          if (new Date(deal.won_time).getFullYear() === new Date().getFullYear()) {

            total_analyse += dealAnalyseCA
            total_mb += dealMBCA
            total_ca += dealMBCA + dealAnalyseCA

            const org_id = deal.org_id?.value
            if (org_id) {
              if (!formatOrganizationRevenue[org_id]) {
                formatOrganizationRevenue[org_id] = {
                  mb_ca: dealMBCA,
                  analyse_ca: dealAnalyseCA,
                  total_ca: dealMBCA + dealAnalyseCA,
                  deals: [deal],
                  analyse: isAnalyseDeal(deal),
                  white_label: !isAnalyseDeal(deal),
                  id: org_id,
                  name: deal.org_id?.name
                }
              } else {
                formatOrganizationRevenue[org_id].total_ca += dealMBCA + dealAnalyseCA
                formatOrganizationRevenue[org_id].mb_ca += dealMBCA
                formatOrganizationRevenue[org_id].analyse_ca += dealAnalyseCA
                formatOrganizationRevenue[org_id].deals.push(deal)
                isAnalyseDeal(deal) && (formatOrganizationRevenue[org_id].analyse = true)
                !isAnalyseDeal(deal) && (formatOrganizationRevenue[org_id].white_label = true)
              }
            } else {
              console.log(`le devis n°${deal.id} n'as pas d'organization ID`);
            }
          } else {
            return null
          }
        })
      setFormatedDeals(Object.values(formatDeals))
      setOrganizationsRevenu(Object.values(formatOrganizationRevenue))
      setDataSummury({
        total_ca: total_ca,
        total_mb: total_mb,
        total_analyse: total_analyse,

        total_ca_3_mounths: total_ca_3_mounths,
        total_mb_3_mounths: total_mb_3_mounths,
        total_analyse_3_mounths: total_analyse_3_mounths,

        total_ca_6_mounths: total_ca_6_mounths,
        total_mb_6_mounths: total_mb_6_mounths,
        total_analyse_6_mounths: total_analyse_6_mounths,
      })
    }
  }, [deals])

  return (
    <>
      {formatedDeals && regressionValues
        ?
        <div>
          <div className='flex-center gap-2'>
            <div className='full-width'>
              <Select
                value={cumulativeView}
                onChange={setCumulativeView}
                style={{ width: '300px' }}
              >
                <option value={'false'}>Vue séparé</option>
                <option value={'true'}>Vue empilé</option>
              </Select>
              <Chart
                chartType="ComboChart"
                data={[
                  isCumulativeView()
                    ? ["Date de gain", "Analyse", 'Marque blanche', 'Tendance']
                    : ["Date de gain", "Chiffre d'affaire total", 'Marque blanche', "Analyse", 'Tendance'],
                  ...(isCumulativeView()
                    ? formatedDeals.map((deal, index) => [new Date(deal.date), deal.analyse_ca, deal.mb_ca, regressionValues.points[index] ? regressionValues.points[index][1] : null])
                    : formatedDeals.map((deal, index) => [new Date(deal.date), deal.total_ca, deal.mb_ca, deal.analyse_ca, regressionValues.points[index] ? regressionValues.points[index][1] : null])),
                ]}
                width="100%"
                height="400px"
                options={{
                  title: "Chiffre d'affaire (réparti entre le CA des analyses et de la marque blanche)",
                  titleTextStyle: {
                    fontSize: 18,
                    color: '#3D553D',
                    bold: true,
                    marginBottom: 40,
                  },
                  colors: isCumulativeView()
                    ? ['#EAD7F9', '#EFF4EE', '#3D553D']
                    : ['#3D553D', '#EFF4EE', '#EAD7F9'],
                  chartArea: {
                    width: '100%',
                  },
                  legend: {
                    padding: 0,
                    position: 'top',
                    alignment: 'end',
                    textStyle: {
                      fontSize: 14,
                    },
                  },
                  isStacked: isCumulativeView(),
                  bar: {
                    groupWidth: '80%',
                    maxWidth: '100',
                    stroke: '#000',
                    strokeWidth: 1,
                  },
                  animation: {
                    duration: 250,
                    easing: 'out'
                  },
                  seriesType: "bars",
                  series: isCumulativeView()
                    ? { 2: { type: "line" } }
                    : { 3: { type: "line" } },
                }}
              />
              {showLastYearDeal && formatedLastYearDeal &&
                <Chart
                  chartType="ComboChart"
                  data={[
                    isCumulativeView()
                      ? ["Date de gain", "Analyse", 'Marque blanche']
                      : ["Date de gain", "Chiffre d'affaire total", 'Marque blanche', "Analyse"],
                    ...(isCumulativeView()
                      ? formatedLastYearDeal.map((deal, index) => [new Date(deal.date), deal.analyse_ca, deal.mb_ca])
                      : formatedLastYearDeal.map((deal, index) => [new Date(deal.date), deal.total_ca, deal.mb_ca, deal.analyse_ca])),
                  ]}
                  width="100%"
                  height="400px"
                  options={{
                    title: "Chiffre d'affaire de l'année en cours (réparti entre le CA des analyses et de la marque blanche).",
                    titleTextStyle: {
                      fontSize: 18,
                      color: '#3D553D',
                      bold: true,
                      marginBottom: 40,
                    },
                    colors: isCumulativeView()
                      ? ['#EAD7F9', '#EFF4EE', '#3D553D']
                      : ['#3D553D', '#EFF4EE', '#EAD7F9'],
                    chartArea: {
                      width: '100%',
                    },
                    legend: {
                      padding: 0,
                      position: 'top',
                      alignment: 'end',
                      textStyle: {
                        fontSize: 14,
                      },
                    },
                    isStacked: isCumulativeView(),
                    bar: {
                      groupWidth: '80%',
                      maxWidth: '100',
                      stroke: '#000',
                      strokeWidth: 1,
                    },
                    animation: {
                      duration: 250,
                      easing: 'out'
                    },
                    seriesType: "bars",
                    series: isCumulativeView()
                      ? { 2: { type: "line" } }
                      : { 3: { type: "line" } },
                  }}
                />}
              <button className='m-b-25 light-button' onClick={_ => setShowLastYearDeal(prvValue => !prvValue)}>{showLastYearDeal ? 'Masquer' : 'Afficher'} les devis de l'année dernière</button>
              <div className='subtext'>
                Données issus des devis gagné présent sur Pipedrive.
                Le chiffre d'affaire des analyses est calculé à partir des devis présent sur le pipline 'Analyse'.
                Tous les autres deal sont concidérés comme des devis marque blanche.
                La courbe des tendances est issus d'une régression linéaire basé sur l'ensemble des mois pleins de l'année en cours.
              </div>
            </div>
            <div style={{ minWidth: '300px' }}>
              {/* <h4>Données 2023:</h4> */}
              <h4>Chiffre d'affaire de l'année en cours</h4>
              <ul>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire total généré: <br />
                    <span className='strong-2'>{dataSummury.total_ca.toLocaleString('fr-FR', digitOptions)}€</span></div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire marque blanche:  <br />
                    <span className='strong-2'>
                      {dataSummury.total_mb.toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_mb / dataSummury.total_ca * 100).toLocaleString('fr-FR', digitOptions)}%)
                    </span>
                  </div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire des analyses:<br />
                    <span className='strong-2'>
                      {dataSummury.total_analyse.toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_analyse / dataSummury.total_ca * 100).toLocaleString('fr-FR', digitOptions)}%)
                    </span>
                  </div>
                </li>
                <li className='m-b-10'>Nombre de devis gagné : <span className='strong-2'>{deals?.length}</span>
                  <ul className='m-t-10'>
                    <li>Marque blanche: {deals?.filter(deal => !isAnalyseDeal(deal)).length}</li>
                    <li>Analyse: {deals?.filter(deal => isAnalyseDeal(deal)).length}</li>
                  </ul>
                </li>
                <li className='m-b-10'>Nombre d'organizations : <span className='strong-2'>{organizationsRevenu?.length}</span>
                  <ul className='m-t-10'>
                    <li>Marque blanche: {organizationsRevenu?.filter(orga => orga.white_label).length}</li>
                    <li>Analyse: {organizationsRevenu?.filter(orga => orga.analyse).length}</li>
                  </ul>
                  <button
                    className='light-button m-t-10 center-block'
                    onClick={_ => showDealsDetails()}
                  >Détails</button>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex gap-1'>
            <div>
              <h4>Moyenne depuis le début de l'année (mois en cours inclus):</h4>
              <ul>
                <li className='m-b-10'>Chiffre d'affaire généré par mois:  <span className='strong-2'>{(dataSummury.total_ca / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span></li>
                <li className='m-b-10'>Chiffre d'affaire marque blanche par mois: <span className='strong-2'>{(dataSummury.total_mb / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span></li>
                <li className='m-t-10'>Chiffre d'affaire des analyses par mois: <span className='strong-2'>{(dataSummury?.total_analyse / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span></li>
              </ul>
            </div>
            <div>
              <h4>Moyenne des 6 derniers mois pleins:</h4>
              <ul>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire généré par mois: <span className='strong-2'>{(dataSummury.total_ca_6_mounths / 6).toLocaleString('fr-FR', digitOptions)}€</span></div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire marque blanche par mois: {(dataSummury.total_mb_6_mounths / 6).toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_mb_6_mounths / dataSummury.total_ca_6_mounths * 100).toLocaleString('fr-FR', digitOptions)}%)</div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire des analyses par mois: {(dataSummury.total_analyse_6_mounths / 6).toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_analyse_6_mounths / dataSummury.total_ca_6_mounths * 100).toLocaleString('fr-FR', digitOptions)}%)</div>
                </li>
              </ul>
            </div>
            <div>
              <h4>Moyenne des 3 derniers mois pleins:</h4>
              <ul>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire généré par mois: <span className='strong-2'>{(dataSummury.total_ca_3_mounths / 3).toLocaleString('fr-FR', digitOptions)}€</span></div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire marque blanche par mois: {(dataSummury.total_mb_3_mounths / 3).toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_mb_3_mounths / dataSummury.total_ca_3_mounths * 100).toLocaleString('fr-FR', digitOptions)}%)</div>
                </li>
                <li className='m-b-10'>
                  <div>Chiffre d'affaire des analyses par mois: {(dataSummury.total_analyse_3_mounths / 3).toLocaleString('fr-FR', digitOptions)}€ ({(dataSummury.total_analyse_3_mounths / dataSummury.total_ca_3_mounths * 100).toLocaleString('fr-FR', digitOptions)}%)</div>
                </li>
              </ul>
            </div>
          </div>

          <div className='organizaions-revenue-section'>
            <Datalist
              items={organizationsRevenu?.map(organization => ({ id: organization.id, value: organization.name }))}
              onSelect={setSelectedOrganizationId}
              placeholder='Sélectionner une organisation'
              defaultId={appConstante.pipedrive.organizations.CFC}
            />
            {
              selectedOrganization
                ? <div>
                  <div style={{ padding: '1px 7px', borderRadius: '8px', backgroundColor: 'white' }}>
                    <h4>Répartition du CA par catégorie entre {selectedOrganization.name} et nos autres clients</h4>
                    <div className='flex gap-1'>
                      <div className='flex-1'>
                        <Chart
                          chartType="PieChart"
                          data={[
                            ['catégory', 'moyenne'],
                            ['% de CA autres clients', 100 - selectedOrganization.total_ca / dataSummury.total_ca * 100],
                            [`% de CA ${selectedOrganization.name}`, selectedOrganization.total_ca / dataSummury.total_ca * 100],
                          ]}
                          options={{
                            title: `Chiffre d'affaire total`,
                            subtitle: "Données obtenus depuis janvier 2023",
                            titleTextStyle: {
                              fontSize: 18, // Taille de la police du titre
                              color: '#3D553D', // Couleur du titre
                              bold: true,
                              marginBottom: 50,
                            },
                            slices: {
                              0: { color: '#EFF4EE', textStyle: { color: '#3D553D' } },
                              1: { color: '#3D553D', textStyle: { color: '#EFF4EE' } },
                            },
                            pieHole: 0.4,
                            chartArea: {
                              width: '100%',
                            },
                            animation: {
                              startup: true,
                              duration: 1000, // Durée de l'animation en millisecondes
                              easing: "out", // Type d'animation (in, out, inAndOut)
                            },
                            legend: {
                              padding: 0,
                              position: 'right',
                              textStyle: {
                                fontSize: 14
                              },
                            },
                          }}
                          width={"100%"}
                          height={"350px"}
                        />
                      </div>
                      <div className='flex-1' style={{ padding: '7px', }}>
                        <Chart
                          chartType="PieChart"
                          data={[
                            ['catégory', 'moyenne'],
                            ['% de CA autres clients', 100 - selectedOrganization.mb_ca / dataSummury.total_mb * 100],
                            [`% de CA ${selectedOrganization.name}`, selectedOrganization.mb_ca / dataSummury.total_mb * 100],
                          ]}
                          options={{
                            title: `Chiffre d'affaire marque blanche`,
                            subtitle: "Données obtenus depuis janvier 2023",
                            titleTextStyle: {
                              fontSize: 18, // Taille de la police du titre
                              color: '#3D553D', // Couleur du titre
                              bold: true,
                              marginBottom: 50,
                            },
                            slices: {
                              0: { color: '#EFF4EE', textStyle: { color: '#3D553D' } },
                              1: { color: '#3D553D', textStyle: { color: '#EFF4EE' } },
                            },
                            pieHole: 0.4,
                            chartArea: {
                              width: '100%',
                            },
                            legend: {
                              padding: 0,
                              position: 'right',
                              textStyle: {
                                fontSize: 14
                              },
                            },
                          }}
                          width={"100%"}
                          height={"350px"}
                        />
                      </div>
                      <div className='flex-1'>
                        <Chart
                          chartType="PieChart"
                          data={[
                            ['catégory', 'moyenne'],
                            ['% de CA autres clients', 100 - selectedOrganization.analyse_ca / dataSummury.total_analyse * 100],
                            [`% de CA ${selectedOrganization.name}`, selectedOrganization.analyse_ca / dataSummury.total_analyse * 100],
                          ]}
                          options={{
                            title: `Chiffre d'affaire analyse`,
                            subtitle: "Données obtenus depuis janvier 2023",
                            titleTextStyle: {
                              fontSize: 18, // Taille de la police du titre
                              color: '#3D553D', // Couleur du titre
                              bold: true,
                              marginBottom: 50,
                            },
                            slices: {
                              0: { color: '#EFF4EE', textStyle: { color: '#3D553D' } },
                              1: { color: '#3D553D', textStyle: { color: '#EFF4EE' } },
                            },
                            pieHole: 0.4,
                            chartArea: {
                              width: '100%',
                            },
                            legend: {
                              padding: 0,
                              position: 'right',
                              textStyle: {
                                fontSize: 14
                              },
                            },
                          }}
                          width={"100%"}
                          height={"350px"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-1 m-t-25 mediumtext'>
                    <div>
                      <div className='m-b-10'>
                        Chiffre d'affaire total généré pour l'année en cours: <span className='strong-2'>{selectedOrganization.total_ca.toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div className='m-b-10'>
                        Chiffre d'affaire moyen par mois (mois en cours inclus): <span className='strong-2'>{(selectedOrganization.total_ca / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div>
                        Nombre de devis gagné: <span className='strong-2'>{selectedOrganization.deals.length.toLocaleString('fr-FR', digitOptions)}</span>
                      </div>
                    </div>
                    <div>
                      <div className='m-b-10'>
                        Chiffre d'affaire marque blanche pour l'année en cours: <span className='strong-2'>{selectedOrganization.mb_ca.toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div className='m-b-10'>
                        Chiffre d'affaire moyen par mois (mois en cours inclus): <span className='strong-2'>{(selectedOrganization.mb_ca / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div>
                        Nombre de devis gagné: <span className='strong-2'>{selectedOrganization.deals.filter(deal => !isAnalyseDeal(deal)).length.toLocaleString('fr-FR', digitOptions)}</span>
                      </div>
                    </div>
                    <div>
                      <div className='m-b-10'>
                        Chiffre d'affaire analyse pour l'année en cours: <span className='strong-2'>{selectedOrganization.analyse_ca.toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div className='m-b-10'>
                        Chiffre d'affaire moyen par mois (mois en cours inclus): <span className='strong-2'>{(selectedOrganization.analyse_ca / (currentMonth + 1)).toLocaleString('fr-FR', digitOptions)}€</span>
                      </div>
                      <div>
                        Nombre de devis gagné: <span className='strong-2'>{selectedOrganization.deals.filter(deal => isAnalyseDeal(deal)).length.toLocaleString('fr-FR', digitOptions)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                : <h5>Sélectionnez une organisation pour afficher ses statistiques</h5>
            }

          </div>
        </div>
        : <MidLoader />
      }
    </>
  )
}
