import React, { useState, useEffect, useContext } from 'react'
import { useCallApi } from 'Functions';
import { useCurrentAppPermission } from 'Functions';
import { PopupContext } from 'Context/PopupContext'
import { appConstante } from 'appConstante';
import AddOrder from './AddOrder';
import MidLoader from 'Components/MidLoader/MidLoader';
import Pagination from 'Components/Pagination/Pagination'
import UpdateMovement from 'Context/ERP/PlanningContext';
import DeleteMovement from 'AppContainer/ERP/Stocks/Movements/DeleteMovement';
import DeleteButton from 'Components/DeleteButton/DeleteButton';
import UpdateOrder from './UpdateOrder';


export default function Order({ category }) {
    const callApi = useCallApi()
    const { addPopupContent } = useContext(PopupContext)
    const currentAppPermission = useCurrentAppPermission()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [orders, setOrders] = useState(null)

    const addNewOrder = (newOrders) => {
        newOrders.map(newOrder => newOrder.category === category) && setOrders([...newOrders, ...orders])
    }
    const updatedOrder = (updatedOrder) => setOrders(orders.map(order => order.id == updatedOrder.id ? updatedOrder : order))

    const filterOrders = (id) => {
        setOrders(orders.filter(order => order.id !== id))
    }
    const showDeleteMovementPopup = (order) => {
        addPopupContent(<DeleteMovement movement={order} filterMovement={filterOrders} />)
    }

    useEffect(() => {
        const controller = new AbortController();
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-histories`, { method: 'GET' }, controller.signal,
            { limit: 10, page: currentPage, category: category, positive: 1 })
            .then(res => {
                setOrders(res.data.data)
                setTotalPage(res.data.total_pages)
                res.data.total_pages === 0 && setCurrentPage(1)
                currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
            })
            .catch(error => {
                console.log({ error });
            })
        return () => {
            controller.abort()
        }
    }, [currentPage])
    return (
        <>
            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                <button className='strong-button m-l-0 m-b-25' onClick={() => addPopupContent(
                    <AddOrder selectedCategory={category} addNewOrder={addNewOrder} />)}>Ajouter une commande</button>
            }
            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
            {
                orders
                    ? orders.length > 0
                        ?
                        (<table className='beautiful-array'>
                            <thead>
                                <tr>
                                    {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                        <th></th>
                                    }
                                    {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                        <th></th>
                                    }
                                    <th>Article</th>
                                    <th>Référence Article</th>
                                    <th>Fournisseur</th>
                                    <th>Quantité</th>
                                    <th>Date</th>
                                    {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(order =>
                                    <tr key={order.id}>
                                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                            <>
                                                <td>
                                                    <button onClick={
                                                        () => addPopupContent(<UpdateMovement movement={order} filterMovement={filterOrders} />)
                                                    }>Valider
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={
                                                        () => addPopupContent(<UpdateOrder order={order} updatedOrder={updatedOrder} />)
                                                    }>Modifier
                                                    </button>
                                                </td>
                                            </>
                                        }
                                        <td>
                                            <div className="strong-1">{order.name}</div>
                                        </td>
                                        <td>
                                            <div className="strong-1">{order.article_reference}</div>
                                        </td>
                                        <td>
                                            <div className="strong-1">{order.supplier}</div>
                                        </td>
                                        <td>
                                            <div className="strong-1">{order.quantity}</div>
                                        </td>
                                        <td>
                                            <div className="strong-1">{new Date(order.date).toLocaleDateString('FR')}</div>
                                        </td>
                                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                            < td >
                                                <DeleteButton
                                                    callBack={showDeleteMovementPopup}
                                                    parameters={[order]}
                                                />
                                            </td>
                                        }
                                    </tr>)
                                }

                            </tbody>
                        </table>)
                        : (<h5>Vous n'avez aucune commande !</h5>)
                    : (<MidLoader />)
            }
        </>
    )
}
