import React, { createContext, useState, useContext, useEffect } from 'react';
import { NotificationContext } from 'Context/NotificationContext';
import axios from 'axios';

export const LLFCProductsContext = createContext();

function LLFCProductsContextProvider(props) {
    const [LLFCProducts, setLLFCProducts] = useState([]);
    const { addNotificationContent } = useContext(NotificationContext)

    useEffect(() => {
        axios('https://labofrancaisduchanvre.com/wp-json/llfc/products')
            .then((response) => {
                setLLFCProducts(response.data.data)
            })
            .catch((error) => {
                console.log({error});
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: 'Nous n\'avons pas réussi à charger les produits du site labofrancaisduchanvre.com'
                })
            })
    }, [])
    return (
        <LLFCProductsContext.Provider value={{ LLFCProducts }}>
            {props.children}
        </LLFCProductsContext.Provider>
    );
}

export default LLFCProductsContextProvider;
