import React, { useState, useContext } from 'react'
import UserFields from './UserFields'
import { useCallMSLApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'


export default function AddUser({ addUser }) {
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)
    const callMSLApi = useCallMSLApi()
    const [newUser, setNewUser] = useState({})

    const updateUser = (key, value) => {
        setNewUser({ ...newUser, [key]: value })
    }

    const sendUser = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            title: "En cours de création...",
            content: <MiniLoader />,
            infinit: true
        })
        callMSLApi(`wp_user`, { method: 'POST' }, newUser)
            .then(res => {
                console.log(res.data.data);
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: "L'utilisateur à bien été créé",
                })
                addUser({ ...res.data.data, pipedrive_id: newUser.pipedrive_id })
            })
            .catch(error => {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: JSON.stringify(error.response.data.errors),
                })
                console.log(error);
            })
    }

    return (
        <>
            <h2>Ajouter un utilisateur</h2>
            <form onSubmit={e => sendUser(e)}>
                <UserFields user={newUser} updateUserFunction={updateUser} />
                <button className='strong-button m-b-25 m-t-25'>Créer l'utilisateur</button>
            </form>
        </>
    )
}
