import DeleteButton from 'Components/DeleteButton/DeleteButton'
import Input from 'Components/FormElement/Input'
import SmallInput from 'Components/FormElement/SmallInput'
import React, { useEffect, useState } from 'react'
import { PencilIcon } from '@heroicons/react/outline';

interface Props {
    index: number,
    additionalData: {
        label: string
        value: string
    }
    setter: () => void
    deleteFunc: (index: number) => void
}

export default function AdditionalData({ index, additionalData, setter = () => { }, deleteFunc }: Props) {

    const [edit, setEdit] = useState<boolean>(false)

    useEffect(() => {
        setEdit(!additionalData.label)
    }, [])


    return (
        <>
            {edit
                ? <div>
                    <div className='custom_additionnal_data gap-1 end'>
                        {/* <DeleteButton alwaysDisplay={true} parameters={[index]} callBack={deleteFunc} /> */}
                        <Input onInput={setter} customParameters={['label', index]} label="label" value={additionalData.label} />
                        <Input onInput={setter} customParameters={['value', index]} label="value" value={additionalData.value} />
                        <div className='pointer' onClick={_ => setEdit(prv => !prv)}>
                            <PencilIcon width={'20px'} />
                        </div>
                    </div>
                    <div
                        className='pointer red right'
                        onClick={_ => deleteFunc(index)}
                    >
                        Delete
                    </div>
                </div>
                : <div className='flex relative'>
                    <SmallInput
                        readOnly={true}
                        label={additionalData.label}
                        value={additionalData.value}
                        className='full-width'
                    />
                    <div className='absolute pointer' style={{
                        right: '3px',
                        bottom: '4px',
                        backgroundColor: 'white'
                    }}
                        onClick={_ => setEdit(prv => !prv)}
                    >
                        <PencilIcon width={'20px'} />
                    </div>
                </div>
            }
        </>
    )
}
