import React, { useContext, useEffect, useState } from 'react'
import set from 'lodash/set.js';
import { useCallCBDPApi } from 'Functions';
import { BulkContext } from 'Context/CBDProtect/Bulk';
import { NotificationContext } from 'Context/NotificationContext';
import { PopupContext } from 'Context/PopupContext';
import BulkFields from './BulkFields'

export default function UpdateBulk({ bulk, setter }) {

    const callCBDPApi = useCallCBDPApi()
    const { setBulks } = useContext(BulkContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)

    const [updatedBulk, setUpdatedBulk] = useState(bulk)

    useEffect(() => {
        const formatedDDM = new Date(bulk.ddm)
        formatedDDM.setMonth(formatedDDM.getMonth() + 1)
        formatedDDM.setDate(0)
        setBulkKey('ddm', formatedDDM.toISOString().split('T')[0])
    }, [bulk])

    const setBulkKey = (keyPath, value) => {
        setUpdatedBulk(prvBulk => {
            const newBulk = prvBulk
            set(prvBulk, `${keyPath}`, value)
            return { ...newBulk }
        })
    }

    const sendUpdatedBulk = async (e) => {
        e.preventDefault()

        addNotificationContent({
            error: false,
            content: "modification en cours",
        })
        callCBDPApi(`bulk`, { method: "put" }, { ...updatedBulk, 'bulk-id': updatedBulk.ID })
            .then(res => {
                setBulks(prvBulks => {
                    return prvBulks.map(bulk => parseInt(bulk.ID) === parseInt(updatedBulk.ID)
                        ? { ...res.data.res, analyses_datas: bulk.analyses_datas }
                        : bulk
                    )
                })
                setter(prvBulks => {
                    return prvBulks.map(bulk => parseInt(bulk.ID) === parseInt(updatedBulk.ID)
                        ? { ...res.data.res, analyses_datas: bulk.analyses_datas }
                        : bulk
                    )
                })
                showPopup(false)
                addNotificationContent({ content: `Le bulk a été modifié` })
            })
            .catch(error => console.log(`erreur modification /bulk/${updatedBulk.ID}`, error))
    }

    return (
        <form onSubmit={e => sendUpdatedBulk(e)}>
            <h3>Modification du bulk n° {bulk.ID}</h3>
            <BulkFields
                bulk={updatedBulk}
                setter={setBulkKey}
            />
            <button className='strong-button'>Modifier</button>
        </form>
    )
}