import { appConstante } from "appConstante"
import { NotificationContext } from "Context/NotificationContext"
import { formatDateTimeToInput, getPreviousSunday, getNextSunday, useCallApi, removeDuplicates, useCallPipedriveApi, searchValueByKeyInObjectArray, findObjectInArrayByKey, removeDuplicatesObjectInArray } from "Functions"
import React, { createContext, useState, useEffect, useContext } from "react"
import { DealContext } from "../Commercial/DealContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"

export const PlanningContext = createContext()

function PlanningContextProvider(props) {

    const callApi = useCallApi()
    const callPipedriveApi = useCallPipedriveApi()
    const { addNotificationContent } = useContext(NotificationContext)

    const { getDealFromId } = useContext(DealContext)

    const delayBeforeLoad = 1000
    const [loadingSessions, setLoadingSession] = useState(false)
    const [reloadSteps, toggleReloadSteps] = useState(false)
    const [sessions, setSessions] = useState([])
    const [sessionsByCategories, setSessionsByCategories] = useState({
        planningSessions: [],
        warningSessions: [],
        commingSessions: [],
    })
    const [dateToLoadEvent, setDateToLoadEvent] = useState({
        start: formatDateTimeToInput(getPreviousSunday(new Date())),
        end: formatDateTimeToInput(getNextSunday(new Date())),
    })
    const [stepsToPlan, setStepsToPlan] = useState(null)
    const [steps, setSteps] = useState([])
    const [pipedriveProducts, setPipedriveProducts] = useState([])
    const [dealsToPlan, setDealsToPlan] = useState(null)

    /*** CHARGEMENT  ***/
    useEffect(() => {
        setTimeout(() => {
            setLoadingSession(true)
            Promise.all([callWarningSession(), callPlanningSessionApi()])
                .then(([warningSessionsResponse, callPlanningSessionApi]) => {
                    const allSessions = removeDuplicatesObjectInArray('id', [...warningSessionsResponse, ...callPlanningSessionApi])
                    const allFormatedSessions = allSessions.map(session => formatSession(session))
                    setLoadingSession(false)
                    setSessions(allFormatedSessions)
                })
        }, sessions ? 0 : delayBeforeLoad)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            loadStepToPlan()
        }, steps ? 0 : delayBeforeLoad)
    }, [reloadSteps])

    const refreshSteps = () => {
        setSteps(_ => null)
        setStepsToPlan(_ => null)
        setDealsToPlan(_ => null)
        toggleReloadSteps(!reloadSteps)
    }

    const refreshWarningSessions = () => {
        callWarningSession()
            .then(warningSessionsResponse => {
                const allFormatedSessions = warningSessionsResponse.map(session => formatSession(session))
                setSessions(prvSessions => removeDuplicatesObjectInArray('id', [...allFormatedSessions, ...prvSessions]))
            })
    }

    const refreshPlanningSessions = () => {
        setLoadingSession(true)
        callPlanningSessionApi()
            .then(planninSessions => {
                const allFormatedSessions = planninSessions.map(session => formatSession(session))
                setSessions(prvSessions => removeDuplicatesObjectInArray('id', [...allFormatedSessions, ...prvSessions]))
                setLoadingSession(false)
            })
    }

    const setDateRange = (dateRange) => {
        if (
            new Date(dateRange.start) < new Date(dateToLoadEvent.start)
            // || new Date(dateRange.end) > new Date(dateToLoadEvent.end)
        ) {
            const newStart = new Date(dateRange.start) < new Date(dateToLoadEvent.start) ? formatDateTimeToInput(getPreviousSunday(new Date(dateRange.start))) : dateToLoadEvent.start
            // const newEnd = new Date(dateRange.end) > new Date(dateToLoadEvent.end) ? formatDateTimeToInput(getNextSunday(new Date(dateRange.end))) : dateToLoadEvent.end
            setDateToLoadEvent({
                start: newStart,
                // end: newEnd
            })
        }
    }

    /************************** SESSION ******************************/
    const formatSession = (event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.planning_name ? event.planning_name : event.title,
        real_quantity: event.real_quantity,
        indicative_quantity: event.indicative_quantity,
        state: event.state.value,
        lock: event.lock,
        id: event.id,
    })

    const checkSession = (session) => {
        return session.step && session.deal && session.state ? true : false
    }

    /* LOAD ALL EVENTS TO SHOW */
    useEffect(() => {
        if (sessions) {
            const removedDuplicateSessions = removeDuplicatesObjectInArray('id', sessions)
            setSessionsByCategories({
                planningSessions: removedDuplicateSessions,
                warningSessions: removedDuplicateSessions.filter(session => session.state > 1),
                commingSessions: removedDuplicateSessions.filter(session => new Date(session.start) >= new Date()),
            })
        }
    }, [sessions])

    useEffect(() => {
        if (sessions) {
            setLoadingSession(true)
            callPlanningSessionApi()
                .then((newSessions) => {
                    setLoadingSession(false)
                    setSessions(removeDuplicatesObjectInArray('id', [...newSessions.map(session => formatSession(session)), ...sessions]))
                })
        }
    }, [dateToLoadEvent])
    const getSessionsByCategory = () => {
        return new Promise((resolve, reject) => {
            resolve(sessionsByCategories)
        })
    }

    const callPlanningSessionApi = () => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/sessions`, { method: "get" }, null, { start: dateToLoadEvent.start })
                .then((res) => {
                    resolve(res.data.data
                        .filter((event) => event.deal !== {})
                    )
                })
                .catch(() => {
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: "Nos serveurs présentent une erreur, contactez nous",
                    })
                })
        })
    }

    const callWarningSession = () => {
        //on arrête le carnage
        return new Promise((resolve, reject) => {
            resolve([])
        })

        // return new Promise((resolve, reject) => {
        //     callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/sessions`, { method: "get" }, null, { warning: true })
        //         .then((res) => {
        //             resolve(
        //                 res.data.data.filter((event) => event.deal !== {}) //à checker, pk ?
        //             )
        //         })
        //         .catch(() => {
        //             addNotificationContent({
        //                 error: true,
        //                 title: "Erreur",
        //                 content: "Nos serveurs présentent une erreur, contactez nous",
        //             })
        //         })
        // })
    }

    const postSession = (session) => {
        return new Promise((resove, reject) => {
            addNotificationContent({
                error: false,
                title: "Création de la session de production en cours",
                content: <MiniLoader />,
                infinit: true,
            })

            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/session`, { method: "post" }, null, session)
                .then((res) => {
                    resove(res.data.data)
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: "Votre session viens d'être créé",
                        infinit: false,
                    })
                    addPlanningSession(res.data.data)
                })
                .catch((error) => {
                    console.log({ error });
                    reject(error)
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: error.response.message,
                        infinit: false,
                    })
                })
        })
    }

    const addPlanningSession = (newSession) => {
        if (checkSession(newSession)) {
            console.log("[Valid]newSession", newSession);
            //
            setSessions([...sessions, formatSession(newSession)])
            updatePlanningStep(newSession.step)
        } else {
            console.log("[Bad]newSession", newSession);
            getSession(newSession.id)
                .then((newSession) => {
                    setSessions([...sessions, formatSession(newSession)])
                    updatePlanningStep(newSession.step)
                })
        }
    }

    const deleteSession = (sessionId) => {
        console.log('delete Session', sessionId);
        //
        setSessions(prvSessions => prvSessions.filter(session => {
            if (session.id !== sessionId) {
                return true
            } else {
                updatePlanningStep({ id: session.step_id })
                return false
            }
        }))
    }

    const isNewSession = sessionToCheck => {
        let newSession = true;
        sessions.forEach(session => session.id === sessionToCheck.id ? newSession = false : null)
        return newSession
    }

    const updateSession = updatedSession => {
        if (isNewSession(updatedSession)) {
            console.log("newSession", updatedSession);
            addPlanningSession(updatedSession)
        } else {
            if (checkSession(updatedSession)) {
                console.log("[Valide]updatedSession", updatedSession);
                setSessions(sessions.map((session) => updatedSession.id === session.id ? formatSession(updatedSession) : session))
                updatedSession.step.status === 'to_plan' && updatePlanningStep(updatedSession.step)
            } else {
                getSession(updatedSession.id)
                    .then((updatedSession) => {
                        console.log("[Bad]updatedSession", updatedSession);
                        setSessions(sessions.map((session) => updatedSession.id === session.id ? formatSession(updatedSession) : session))
                        updatePlanningStep(updatedSession.step)
                    })
            }
        }
    }

    const getSession = id => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${id}`)
                .then(res => res.data.data && resolve(res.data.data))
                .catch(error => {
                    console.log({ error });
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: error.response.error,
                    })
                })
        })
    }

    /** ****************** LOAD ALL STEPS TO PLAN ******************* */
    const loadStepToPlan = () => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/steps`, { method: "get" }, null, { to_plan: true })
                .then((res) => {
                    res.data.data && setSteps(_ => res.data.data)
                    resolve(res.data.data)
                })
        })
    }

    useEffect(() => {
        if (steps) {
            setStepsToPlan(_ => [...steps.filter(step => step.status === "to_plan")])
        }
    }, [steps])

    const checkSteps = step => {
        return (step.status && step.deal_id) ? true : false
    }

    const deletePlanningStep = (id) => {
        setSteps(prvSteps => prvSteps.filter(step => step.id !== id))
        setSessions(prvSessions => prvSessions.filter(session => session.step && session.step.id !== id))
    }

    const addPlanningStep = (newStep) => {
        console.log("newStep", newStep);
        if (checkSteps(newStep)) {
            setSteps(prvSteps => removeDuplicatesObjectInArray('id', [...prvSteps, newStep]))
            if (!findObjectInArrayByKey(dealsToPlan, 'id', newStep.deal_id)) {
                setDealsToPlan(async deals => [...deals, await getDealFromId(newStep.deal_id)])
            }
        } else {
            getStep(newStep.id)
                .then((newStep) => {
                    setSteps(prvSteps => removeDuplicatesObjectInArray('id', [...prvSteps, newStep]))
                    if (!findObjectInArrayByKey(dealsToPlan, 'id', newStep.deal_id)) {
                        setDealsToPlan(async deals => [...deals, await getDealFromId(newStep.deal_id)])
                    }
                })
        }
    }

    const addPlanningArrayStep = (stepArray) => {
        setSteps(prvSteps =>
            removeDuplicatesObjectInArray(
                'id', [...prvSteps, ...stepArray]
            ))
    }

    const createStep = (step) => {
        return new Promise((resolve, reject) => {
            callApi(
                `${appConstante.servers.PANORAMA_ENDPOINT}/erp/step`,
                { method: "post" },
                null,
                step
            )
                .then((res) => {
                    console.log(res);
                    addPlanningStep(res.data.data)
                    resolve(res.data.data)
                })
                .catch((error) => {
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: error.response.message,
                        infinit: false,
                    })
                    reject(false)
                })
        })
    }

    const isNewStep = stepToCheck => {
        let isNewStep = true;
        steps.forEach(step => step.id === stepToCheck.id ? isNewStep = false : null)
        return isNewStep
    }

    const updateSessionsFromStep = (step) => {
        console.log("updateSessionsFromStep", step);
        step.sessions && setSessions(prvSessions => prvSessions.map(session =>
            searchValueByKeyInObjectArray(step.sessions, 'id', session.id)
                ? formatSession({ ...session, ...findObjectInArrayByKey(step.sessions, 'id', session.id), step: step })
                : session
        ))
    }

    const putStep = async step => {
        try {
            const createdStepCall = await callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/step/${step.id}`, { method: 'put' }, null, step)
            updatePlanningStep(createdStepCall.data.data)
            return createdStepCall.data.data
        } catch (error) {
            console.log(error);
        }
    }

    const updatePlanningStep = (updatedStep) => {
        if (isNewStep(updatedStep)) {
            console.log("[New]updatedStep", updatedStep);
            addPlanningStep(updatedStep)
        } else {
            if (checkSteps(updatedStep)) {
                console.log("[Valide]updatedStep", updatedStep);
                updateSessionsFromStep(updatedStep)
                const relativeStep = steps.filter(step => step.id === updatedStep.id)
                JSON.stringify(updatedStep) !== JSON.stringify(relativeStep[0])
                    && setSteps(prvSteps => prvSteps.map((step) => step.id === updatedStep.id ? updatedStep : step))
            } else {
                console.log("[Bad]updatedStep", updatedStep);
                getStep(updatedStep.id)
                    .then((updatedStep) => {
                        updateSessionsFromStep(updatedStep)
                        setSteps(prvSteps => prvSteps.map((step) => step.id === updatedStep.id ? updatedStep : step))
                    })
            }
        }
    }

    const getStep = id => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/step/${id}`)
                .then(res => res.data.data && resolve(res.data.data))
                .catch(error => {
                    console.log({ error });
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: error.response.error,
                    })
                })
        })
    }

    /** ***************** PIPEDRIVE PRODUCT FROM STEP TO PLAN ****************** */
    useEffect(() => {
        const controller = new AbortController()
        if (dealsToPlan && dealsToPlan.length) {
            Promise.all(
                dealsToPlan?.map((deal) =>
                    getPipedriveDealProducts(deal.id, controller.signal)
                )
            )
                .then((pipedriveProducts) => {
                    console.log("Produits Pipedrive chargés")
                    setPipedriveProducts(prvPipedriveProducts => prvPipedriveProducts.concat(...pipedriveProducts))
                })
                .catch((error) => console.log({ error }))
        }
        return () => {
            controller.abort()
        }
    }, [dealsToPlan])

    useEffect(() => {
        if (stepsToPlan && stepsToPlan.length && !dealsToPlan) {
            setDealsToPlan(_ => [])
            const dealsId = removeDuplicates(stepsToPlan.map(step => step.deal_id))
            Promise.all(dealsId.map(async id => await getDealFromId(id)))
                .then(deals => setDealsToPlan(_ => deals))
        }
    }, [stepsToPlan, dealsToPlan])


    const getPipedriveDealProducts = async (deal_id, signalController) =>
        new Promise((resolve, reject) => {
            callPipedriveApi(`deals/${deal_id}/products`, {
                method: "get",
                signal: signalController,
            })
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((res) => {
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: "Pipedrive semble présenter une erreur",
                    })
                })
        })
    return (
        <PlanningContext.Provider
            value={{
                getSession,
                postSession,
                setDateRange,
                refreshPlanningSessions,
                refreshWarningSessions,
                refreshSteps,
                loadingSessions,
                deletePlanningStep,
                deleteSession,
                createStep,
                addPlanningStep,
                addPlanningArrayStep,
                putStep,
                updatePlanningStep,
                dealsToPlan,
                pipedriveProducts,
                sessionsByCategories,
                getSessionsByCategory,
                stepsToPlan,
                setStepsToPlan,
                updateSession,
                addPlanningSession,
                getStep
            }}
        >
            {props.children}
        </PlanningContext.Provider>
    )
}

export default PlanningContextProvider
