import React from 'react';
import './InputSmall.css';

interface Props {
  label?: string;
  value?: string;
  setter?: (...args: any[]) => void;
  customParameters?: any[];
  style?: React.CSSProperties;
  required?: boolean;
  disabled?: boolean;
  datalistId?: string;
  type?: 'text' | 'number' | 'email' | 'file';
  min?: string;
  step?: string;
  max?: string;
  className?: string;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  accept?: string;
  baliseType?: "input" | "textarea";
  border?: boolean;
  readOnly?: boolean;
}

export default function SmallInput({
  label = '',
  value = '',
  setter = () => { },
  customParameters = [],
  style = {},
  required = false,
  disabled = false,
  datalistId,
  type = 'text',
  min = '0',
  step = '0.01',
  max,
  className = '',
  placeholder = '',
  onKeyDown = () => { },
  accept = '',
  baliseType = "input",
  border = false,
  readOnly = false,
}: Props) {

  return (
    <div style={style} className={`w2p-input ${className} ${disabled ? "disabled" : ""} ${border ? "border" : ""}`}>
      <label>
        {
          label && (
            <div className="w2p-input-label">
              {label}
              {required && <span className='required-star'>*</span>}
            </div>
          )
        }
        {
          baliseType === 'textarea' ? (
            <textarea
              className=""
              value={value}
              onInput={e => setter(...customParameters, (e.target as HTMLTextAreaElement).value)}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              onKeyDown={e => onKeyDown && onKeyDown(e)}
              readOnly={readOnly}
            />
          ) : (
            <input
              className=""
              value={value}
              onInput={e => setter(
                ...customParameters,
                type === 'number'
                  ? parseFloat((e.target as HTMLInputElement).value)
                  : type === 'file'
                    ? e
                    : (e.target as HTMLInputElement).value
              )}
              required={required}
              list={datalistId}
              disabled={disabled}
              min={min}
              max={max}
              step={step}
              type={type}
              placeholder={placeholder}
              onKeyDown={e => onKeyDown && onKeyDown(e)}
              accept={accept}
              readOnly={readOnly}
            />
          )
        }
      </label>
    </div>
  );
}
