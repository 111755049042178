import TextEditor from "Components/GENERAL/Form/TextEditor/TextEditor"
import { formatNumber } from "Functions"
import React from "react"

export default function InvoiceProducts({ products, setProducts, organisationPennylane }) {
  const convertTVA = [
    ["exempt", "0%", 0],
    ["FR_55", "5.5%", 0.055],
    ["FR_200", "20%", 0.2],
    ["FR_09", "0.9%", 0.09],
    ["FR_21", "2.1%", 0.021],
    ["FR_85", "8.5%", 0.085],
    ["FR_100", "10%", 0.1],
    ["FR_130", "13%", 0.13],
  ]

  const plan_item_numbers = [
    { value: 707, name: "Marchandise" },
    { value: 706, name: "Service" },
    // {value: 706800000, name: "Livraison"},
  ]

  const updateProductKey = (id, key, value) => {
    setProducts(prvProduct => [...prvProduct.map(product => product.id === id ? { ...product, [key]: value } : product)])
  }

  const changeTVAonProduct = (id, TVA) => {
    const vat_rate = TVA.split(",")[0]
    const formated_vat = TVA.split(",")[1]
    const vat_value = parseFloat(TVA.split(",")[2])
    const newProducts = products.map((product) => {
      if (product.id === id) {
        product.vat_rate = vat_rate
        const real_unitPrice =
          product.price_no_tax * (1 + vat_value) * (1 - product.discount)
        product.unitPrice = real_unitPrice
        product.currency_amount =
          parseFloat(real_unitPrice * product.quantity).toFixed(2) * 1
        product.formated_vat = formated_vat
      }
      return product
    })
    setProducts([...newProducts])
  }
  const changeonPlanTtemNumberProduct = (id, plan_item_number) => {
    const newProducts = products.map((product) => {
      if (product.id === id) {
        product.plan_item_number = plan_item_number
      }
      return product
    })
    setProducts([...newProducts])
  }

  const isProductOk = (product) => {
    if (!product.plan_item_number) {
      return false
    }
    if (organisationPennylane.country_alpha2?.toLowerCase() === "fr" || organisationPennylane.country_alpha2?.toLowerCase() === "mc") {
      return product.vat_rate !== "exempt"
    } else {
      return true //product.vat_rate === "exempt"
    }
  }

  return (
    <>
      <h3 style={{ marginTop: "25px" }}>Produits facturés</h3>
      {/* <form> */}
      <table className="beautiful-array">
        <thead>
          <tr>
            <th>Nom</th>
            <th>quantité</th>
            <th>prix HT</th>
            <th>Taux de TVA</th>
            <th>Code comptable</th>
            <th>Discount<br />(%)</th>
            <th>Total TTC</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => {
            return (
              <tr
                key={product.id}
                style={isProductOk(product) ? {} : {
                  backgroundColor: "rgba(255,0,0,0.2)"
                }}>
                <td style={{ textAlign: "left" }}>
                  <div className="strong-2">{product.label}</div>
                  <TextEditor
                    value={product.description}
                    style={{ minWidth: "500px" }}
                    setterToHTML={false}
                    setter={updateProductKey}
                    customParameters={[product.id, "description"]}
                  />
                </td>
                <td>{product.quantity}</td>
                <td>{product.price_no_tax}</td>
                <td>
                  <select
                    style={{ width: "75px" }}
                    form="create-invoice"
                    value={product.formated_vat}
                    onChange={(e) =>
                      changeTVAonProduct(product.id, e.target.value)
                    }
                  >
                    <option value={!product.vat_rate ? "" : product.vat_rate}>
                      {product.formated_vat}
                    </option>
                    <option disabled>_________</option>
                    {convertTVA.map((tva) => (
                      <option key={tva[0]} value={[tva[0], tva[1], tva[2]]}>
                        {tva[1]}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    form="create-invoice"
                    style={{ width: "150px" }}
                    value={product.plan_item_number}
                    onChange={(e) =>
                      changeonPlanTtemNumberProduct(product.id, e.target.value)
                    }
                  >
                    <option value="">-- Sélectionner --</option>
                    {plan_item_numbers.map((pin) => (
                      <option key={pin.value} value={pin.value}>
                        {pin.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>{product.discount * 100}</td>
                <td>{formatNumber(product.currency_amount)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* </form> */}
    </>
  )
}
