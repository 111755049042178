import { useCallAnalysesApi } from "Functions"
import { createContext, useEffect, useState } from "react"

export const UsersContext = createContext()

function UsersContextProvider(props) {

    const callAnalysesApi = useCallAnalysesApi()
    const [users, setUsers] = useState([])

    useEffect(() => {
        const controller = new AbortController()
        callAnalysesApi(`users`, { method: "get", signal: controller.signal })
            .then(res => setUsers(res.data.data))
        return () => {
            controller.abort()
        }
    }, [])



    return (
        <UsersContext.Provider value={{ users }}>
            {props.children}
        </UsersContext.Provider>
    )
}

export default UsersContextProvider
