import React, { useContext, useEffect, useRef, useState } from 'react'
import { AnalyseAccessContext } from 'Context/Analyse/AnalyseAccessContext'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import { useCallAnalysesApi, useCurrentAppPermission } from 'Functions'
import { Link } from 'react-router-dom'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import Pagination from 'Components/Pagination/Pagination'
import MidLoader from 'Components/MidLoader/MidLoader'
import { NotificationContext } from 'Context/NotificationContext'
import AddAnalyses from 'Components/_ANALYSE/Analyse/AddAnalyses'
import Input from 'Components/FormElement/Input'
import UpdateAnalyse from 'Components/_ANALYSE/Analyse/UpdateAnalyse'
import DeleteAnalyse from 'Components/_ANALYSE/Analyse/DeleteAnalyse'
import AccordionTable from 'Components/AccordionTable/AccordionTable'

export default function AnalysesArray() {

    const callAnalysesApi = useCallAnalysesApi()
    const currentAppPermission = useCurrentAppPermission();
    const { analyseNonce } = useContext(AnalyseAccessContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { addPopupContent } = useContext(PopupContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [searchRefNumber, setSearchRefNumber] = useState('')
    const [searchBulkId, setSearchBulkId] = useState('')
    const [searchBatchId, setSearchBatchId] = useState('')
    const [analyses, setAnalyses] = useState(null)
    const [updatedAnalyse, setUpdatedAnalyse] = useState(null)
    const timeoutHandle = useRef()


    useEffect(() => {
        if (analyseNonce) {
            const controller = new AbortController()
            const delay = searchRefNumber || searchBulkId || searchBatchId ? 500 : 0
            clearTimeout(timeoutHandle.current)
            timeoutHandle.current = setTimeout(() => {
                setAnalyses(null)
                callAnalysesApi(`analyses`, { method: "get", signal: controller.signal }, {
                    page: currentPage,
                    num_reference: searchRefNumber,
                    bulk_id: searchBulkId,
                    batch_id: searchBatchId,
                    exclude: 1,
                })
                    .then((res) => {
                        setAnalyses(res.data.data)
                        setTotalPage(res.data.total_pages)
                        res.data.total_pages === 0 && setCurrentPage(1)
                        currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
                    })
                    .catch(err => {
                        addNotificationContent({
                            error: true,
                            title: 'Erreur',
                            content: 'Nous rencontrons une erreur avec nos serveurs',
                            infinit: false,
                        })
                    })
            }, delay)
            return () => {
                controller.abort()
            }
        }
    }, [analyseNonce, currentPage, searchRefNumber, searchBulkId, searchBatchId])

    useEffect(() => {
        if (updatedAnalyse) {
            callAnalysesApi(`analyse/${updatedAnalyse.ID}`, { method: "put" }, updatedAnalyse)
                .then(res => {
                    showPopup(false)
                    addNotificationContent({
                        error: false,
                        title: 'Succès',
                        content: "L'analyse a été modifiée",
                        infinit: false
                    })
                })
                .catch(err => {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: err.response.message,
                        infinit: false
                    })
                })
        }
    }, [updatedAnalyse])

    const setAnalysesFunc = (analyse) => {
        setAnalyses(prevAnalyseSate => {
            const newAnalyseState = [analyse, ...prevAnalyseSate]
            return newAnalyseState
        })
    }

    const changeExclureValue = (id, checked) => {
        setAnalyses(analyses.map(analyse => {
            if (analyse.ID === id) {
                if (checked === true) {
                    setUpdatedAnalyse({ ...analyse, exclure: 1 })
                    return { ...analyse, exclure: 1 }
                } else {
                    setUpdatedAnalyse({ ...analyse, exclure: 0 })
                    return { ...analyse, exclure: 0 }
                }
            } else {
                return analyse
            }
        }))
    }

    const deleteAnalyse = (id) => {
        setAnalyses(analyses.filter(analyse => analyse.ID !== id))
    }
    const showConfirmRemoveAnalyse = analyse => {
        addPopupContent(<DeleteAnalyse analyse={analyse} deleteAnalyse={deleteAnalyse} />)
    }

    return (
        <div>
            <div className='flex-center'>
                <div className='flex-center m-b-25'>
                    <Input label={'N° de référence'} value={searchRefNumber} onInput={setSearchRefNumber} />
                    <Input label={'N° de bulk'} value={searchBulkId} onInput={setSearchBulkId} />
                    <Input label={'N° de lot'} value={searchBatchId} onInput={setSearchBatchId} />
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPage={totalPage}
            />
            {analyses ?
                analyses.length ?
                    <AccordionTable
                        columns={[
                            { title: "", colSpan: currentAppPermission('analyses') > appConstante.parameters.CAN_READ ? 5 : 2 },
                            currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                            { title: "Exclure" },
                            { title: "Réf. Interne" },
                            { title: "Bulk ID" },
                            { title: "Batch ID" },
                            { title: "Commande ID" },
                            { title: "Client" },
                            { title: "Date de l'analyse" },
                            currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                            { title: "" },
                        ]}
                        datas={analyses.map((analyse, index) => {
                            return {
                                mainLine: [
                                    { element: (<div></div>), colSpan: 2 },
                                    {
                                        element: (
                                            <a
                                                target="_blank"
                                                href={analyse.certificate_url ?? null}
                                                rel="noopener noreferrer"
                                            >
                                                Voir
                                            </a>
                                        )
                                    },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<div>
                                            <button className='light-button' onClick={() => addPopupContent(<UpdateAnalyse analyse={analyse} setter={setAnalyses} />, "1220px")}>Modifier</button>
                                        </div>)
                                    },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<div>
                                            <button className='light-button' onClick={() => addPopupContent(<AddAnalyses analyse={analyse} setter={setAnalysesFunc} />, "1220px")} style={{ minWidth: "120px" }}>Ré-analyser</button>
                                        </div>)
                                    },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<div>
                                            <input className='erp-check' type='checkbox' checked={analyse.exclure === 1} onChange={e => changeExclureValue(analyse.ID, e.target.checked)}></input>
                                        </div>)
                                    },
                                    { element: (<div>{analyse.num_reference}</div>) },
                                    { element: (<div>{analyse.bulk_id}</div>) },
                                    { element: (<div>{analyse.batch_id}</div>) },
                                    { element: (<div>{analyse.commande?.ID}</div>) },
                                    {
                                        element: (<div>
                                            {analyse.client
                                                ? <>{analyse.client?.mail} < br /> ({analyse.client?.prenom} {analyse.client?.nom})</>
                                                : null}
                                        </div>)
                                    },
                                    { element: (<div>{new Date(analyse.date).toLocaleDateString('fr-FR')}</div>) },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    { element: (<DeleteButton callBack={showConfirmRemoveAnalyse} parameters={[analyse]} alwaysDisplay={true} />) }
                                ],
                                mainLineStyle: analyse.exclure === 1 ? { backgroundColor: 'rgb(255 129 129 / 60%)' } : {},
                                subLines: [
                                    analyse.certificate_urls?.length
                                        ? [{
                                            element: (
                                                <div className='strong-2'><a href={analyse.certificate_urls[analyse.certificate_urls.length - 1]} target='_blank'>
                                                    Certificats PDF</a></div>)
                                            , colSpan: 100
                                        }]
                                        : [
                                            {
                                                element: (
                                                    <div className='flex gap-1'>
                                                        {Object.entries(analyse.results)
                                                            .map(([key, value]) =>
                                                                <div key={key} className='flex column'>
                                                                    <div className='strong-2'>{key}</div>
                                                                    <div>{value}</div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                                , colSpan: 100
                                            },
                                        ],
                                    analyse?.produit &&
                                    [
                                        {
                                            element: (
                                                <div className='flex gap-1'>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Date de réception</div>
                                                        <div>{new Date(analyse.produit?.date_reception).toLocaleDateString('fr-FR')}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Analyse demandée</div>
                                                        <div>{analyse.produit?.type_analyse.split('<br/>').map((item, index) => (
                                                            <span key={index}>{item}{index < analyse.produit?.type_analyse.split('<br/>').length - 1 && <br />}</span>
                                                        ))}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Demandeur</div>
                                                        <div>{analyse.produit?.demandeur}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Dénomination</div>
                                                        <div>{analyse.produit?.denomination}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>N° lot du client</div>
                                                        <div>{analyse.produit?.num_lot}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Type de produit</div>
                                                        <div>{analyse.produit?.type_produit}</div>
                                                    </div>
                                                    <div className='flex column'>
                                                        <div className='strong-2'>Masse / quantité</div>
                                                        <div>{analyse.produit?.masse}</div>
                                                    </div>
                                                </div>
                                            )
                                            , colSpan: 100
                                        },
                                    ]
                                ]
                            }
                        }
                        )}
                    />
                    : <h5>Aucunes analyses correspondantes à votre recherche</h5>
                : <MidLoader />}
        </div >
    )
}
