import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "azureConfig";
import { useNavigate } from 'react-router-dom'


/**
 * Renders a button which, when selected, will open a popup for login
 */
export default function SigninButton() {
    const navigate = useNavigate();
    const { instance } = useMsal();

    const handleLogin = (instance) => {
        instance.loginRedirect(loginRequest)
            .then(() => navigate(`/mon-compte`))
    }
    return (
        <button className="strong-button" onClick={() => handleLogin(instance)}>Connexion</button>
    );
}
