import { NotificationContext } from 'Context/NotificationContext'
import React, { useContext, useEffect, useState } from 'react'
import { appConstante } from 'appConstante'
import { formatFormDataFromObject, useCallApi } from 'Functions'
import InputSmall from 'Components/FormElement/SmallInput'

export default function AddFile({ target, targetId, setter, className }) {

    const callApi = useCallApi()

    const [file, setFile] = useState({ description: '', file: '', target: target, target_id: targetId })

    useEffect(() => {
        setFile(prvFile => ({ ...prvFile, target: target, target_id: targetId }))
    }, [target, targetId])

    const updateFileProperty = (key, value) => {
        setFile(pvs => ({ ...pvs, [key]: value }))
    }
    const handleFileChange = (e) => {

        const currentFile = e.target.files[0]
        updateFileProperty("file", currentFile)
    }

    const sendFile = (e) => {
        e.preventDefault()
        const controller = new AbortController()
        const formatedFile = formatFormDataFromObject(file)
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/file`, { method: "post" }, controller.signal, formatedFile)
            .then(res => {
                setter(pvs => ([res.data.data, ...pvs]))
                setFile({ description: '', file: '', target: target, target_id: targetId })
            })
            .catch()
    }

    return (
        <div>
            <form className='flex column center m-b-10'>
                <input
                    id='fileInput'
                    type="file"
                    onChange={e => handleFileChange(e)}
                />
                <div className='flex-center m-t-10'>
                    <button className={className ? `${className} m-t-10 m-b-10` : 'strong-button m-t-10 m-b-10'} type='button' onClick={() => document.getElementById('fileInput').click()}>
                        Ajouter un fichier
                    </button>
                </div>

                {
                    file.file?.name
                        ? <div className='flex full-width gap-1'>
                            <div className='list-item full-width flex column gap-1'>
                                <InputSmall
                                    className='full-width'
                                    border={true}
                                    label='Description'
                                    value={file.description}
                                    setter={(value) => updateFileProperty("description", value)}
                                />
                                <div className='left flex gap-1'>
                                    <div>
                                        <div className='strong-2'>Nom du fichier</div>
                                        <div> {file.file.name}</div>
                                    </div>
                                    <button className='light-button self-end' onClick={e => sendFile(e)}>Sauvegarder</button>

                                </div>
                            </div>
                        </div>
                        : null
                }
            </form>
        </div>
    )
}
