import React, { useState, useRef, useEffect } from 'react'
import './ProductDetail.css'
import { computePrice } from "Functions"
// import { CartContext } from '../../Context/CartContext'
import { productDefaultQuantity } from 'appConstante'
import PackagingInProduct from './Packagings/PackagingInProduct'
import ProductTabs from './ProductTabs'
import ProductImages from './ProductImages'

export default function ProductDetail({ product }) {

    // const { addCartContent } = useContext(CartContext)

    const [variationDescritpion, setVariationDescription] = useState("")
    // const [optionDescritpion, setOptionDescription] = useState("")

    const [productQty, setProductQty] = useState(0)
    const [productPrice, setProductPrice] = useState("...")
    const [totalPrice, setTotalPrice] = useState(0)
    const [selectedVariation, setSelectedVariation] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState([])
    const [parameters, setParameters] = useState(false)
    const [priceCategory, setPriceCategory] = useState(false)

    // const [qtyScale, setQtyScale] = useState(1)
    const qtyScale = product.category !== 'Offres réglementaires' ? 50 : 1;

    const quantitySelector = useRef()

    const setVariationValue = (el, value) => {
        [...document.getElementsByClassName('variation-choise')].forEach((button) => {
            button.classList.remove('active');
        });
        el.classList.add('active');
        setSelectedVariation(value)
    }

    const ScrollQ = (value, el) => {
        let yCord = el.scrollLeft
        el.scroll({
            top: 0,
            left: yCord + value,
            behavior: 'smooth'
        });
    }

    const updateQty = qty => {
        const newQty = parseInt(qty)
        if (parameters) {
            if (newQty > parameters.qMin) {
                setProductQty(newQty)
            } else {
                setProductQty(parameters.qMin)
            }
        }
    }

    const changeQty = qty => {
        updateQty(productQty + qty)
    }

    const addSelectedOtpion = (option) => {
        const newSelectedOptions = [...selectedOptions]
        const removePreviousOption = newSelectedOptions.filter((selectedOption) => {
            return option.id != selectedOption.id
        })
        setSelectedOptions([...removePreviousOption, option])
    }

    useEffect(() => {   //Init produit variables
        if (product.type == 2 && selectedVariation) {
            setParameters({
                pMin: Number(selectedVariation.min_price),
                pMax: Number(selectedVariation.max_price),
                qMin: Number(selectedVariation.min_qty),
                qMax: Number(selectedVariation.min_price_qty),
            })
            setPriceCategory(selectedVariation.price_category)
            setVariationDescription(selectedVariation.description)
            setProductQty(Number(selectedVariation.min_qty))
        }
    }, [selectedVariation])

    useEffect(() => { //init produit simple
        if (product.type == 1) {
            setParameters({
                pMin: Number(product.price.min_price),
                pMax: Number(product.price.max_price),
                qMin: Number(product.price.min_qty),
                qMax: Number(product.price.min_price_qty),
            })
            setPriceCategory(product.price.price_category)
            setProductQty(Number(product.price.min_qty))
        }
    }, [])

    useEffect(() => {
        if (parameters && priceCategory) {
            if (productQty < parameters.qMin) {
                setProductQty(parameters.qMin)
            } else {
                setProductPrice(computePrice(productQty, parameters, priceCategory, selectedOptions).unitPrice)
                setTotalPrice(computePrice(productQty, parameters, priceCategory, selectedOptions).totalPrice)
            }
        }
    }, [parameters, priceCategory, productQty, selectedOptions])

    return (
        <>
            <div className='product-popup-content'>
                <h2>{product.name}</h2>
                {
                    Object.values(product.tabs).filter(value => value).length
                        ? <div className='product-infos'><ProductTabs tabs={product.tabs} /></div>
                        : null
                }
                {product.description && !Object.values(product.tabs).filter(value => value).length
                    ? <div className='product-infos'><ProductTabs tabs={{ description: product.description }} /></div>
                    : null
                }
                <div className='img-and-option-container wrap gap-2'>
                    {product.carrousel && <ProductImages carrousel={[product.product_picture, ...product.carrousel]} />}
                    {product.options && (
                        <div className='var-options-container'>
                            <div className='gap-2 product-options-container'>
                                {product.options.map(option => (
                                    <div key={option.id}>
                                        <div className='column m-t-10' style={{ marginBottom: '0px' }}>
                                            <label className='label-option'>{option.name}</label>
                                            <select className={'option-product'} onChange={e => addSelectedOtpion({ id: option.id, name: option.name, value: JSON.parse(e.target.value) })}>
                                                {option.values.map((value, index) => {
                                                    const currentOptionSet = selectedOptions.filter((selectedOption) => {
                                                        return option.id == selectedOption.id
                                                    })
                                                    currentOptionSet.length == 0 && index == 0 && addSelectedOtpion({ id: option.id, name: option.name, value: value })
                                                    return (
                                                        <option value={JSON.stringify(value)} key={value.value}>{value.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {option.description && <div className='option-description'>{option.description}</div>}
                                    </div>
                                ))}
                            </div>
                            {product.type == 2 && product.variation && (
                                <div className='m-t-25'>
                                    <div className='variation-selection'>
                                        <div className='variation-name'>{product.variation.name} : </div>
                                        <div className='wrap flex-start gap-1'>
                                            {product.variation.values.map((value, index) => {
                                                index == 0 && !selectedVariation && setSelectedVariation(value)
                                                return (
                                                    <div key={value.id} onClick={(e) => setVariationValue(e.target, value)} className={index == 0 ? "variation-choise active" : "variation-choise"}>{value.value}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {
                                        product.variation.description
                                            ? <div className='variation-description'>{product.variation.description}</div>
                                            : null
                                    }
                                    {variationDescritpion && (
                                        <div className='selected-variation-description'>{variationDescritpion}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {parameters ? (
                    <div className='product-quantity-price-section' style={{ padding: "20px" }}>
                        <div className={product.price?.price_category_id !== -1 && 'flex gap-1'}>
                            <div className='quantity-price-indicator'>
                                <h4 className='m-0'>Quantité minimum: {parameters.qMin}</h4>
                                <div className='global-qty-price-section'>
                                    <div className="qty-price-section current-qty-price">
                                        <div className='flex-center flex-start gap-1'>
                                            <div onClick={() => changeQty(-qtyScale)} id="mns-btn" className="input-number-btn mns-btn">-</div>
                                            <input className="product-qty" type="number" value={productQty} onInput={e => updateQty(e.target.value)} step="50" />
                                            <div onClick={() => changeQty(qtyScale)} id="pls-btn" className="input-number-btn pls-btn">+</div>
                                        </div>
                                        <div className="add-to-cart-section">
                                            {/* <button
                                                onClick={() => addCartContent(product, productQty, priceCategory, parameters, selectedVariation, selectedOptions)}
                                            >
                                                Ajouter à mon devis
                                            </button> */}
                                        </div>
                                    </div>
                                    {/* <div className="current-unity-price-container"> */}
                                    <div className="flex">
                                        <div>Unité <span style={{ fontSize: "13px" }}>(HT)</span>:</div>
                                        <div className='primary-color'>{productPrice}€</div>
                                    </div>

                                    <div className="flex">
                                        <div id="total-price-container">Total <span style={{ fontSize: "13px" }}>(HT)</span>:</div>
                                        <div className='primary-color'>{totalPrice && totalPrice}€</div>
                                    </div>
                                    {/* </div> */}
                                    {product.price_description &&
                                        <div>
                                            <h4 className='m-t-10 m-b-0'>Description</h4>
                                            <div>{product.price_description}</div>
                                        </div>}
                                </div>
                            </div>
                            {product.price?.price_category_id !== -1
                                ? <div className="quantity-selector">
                                    <h4 className='quantity-selector-description'>Quantité associée au prix unitaire du produit</h4>
                                    <div className="flex evenly">
                                        <button onClick={() => ScrollQ(-133, quantitySelector.current)} className="scroll-button"> &#10094; </button>
                                        <div className="select-qty-price-container" ref={quantitySelector}>
                                            {productDefaultQuantity.map(qty => {
                                                if (parameters && qty >= parameters.qMin && qty <= parameters.qMax) {
                                                    const currentPrice = parameters && priceCategory ? computePrice(qty, parameters, priceCategory, selectedOptions).unitPrice : "..."
                                                    return (
                                                        <div key={qty} className="qty-price-section flex">
                                                            <div onClick={() => updateQty(qty)} className="qty-button">{qty}</div>
                                                            <div><span className='primary-color unity-price'>{currentPrice}</span>€</div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <button onClick={() => ScrollQ(133, quantitySelector.current)} className="scroll-button"> &#10095; </button>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                ) : <h5>Sélectionnez une variation</h5>}

                {product.packagings &&
                    (<div className='packaging-section'>
                        <h2 className='packaging-annoncement'>Commander directement avec le LLFC les packagings de votre produit</h2>
                        {product.packagings.map((packaging, index) => (
                            <PackagingInProduct
                                key={packaging.id}
                                classNameProps={index % 2 !== 0 ? null : 'green-version'}
                                packaging={packaging}
                                product={product}
                                selectedVariation={selectedVariation}
                                selectedOptions={selectedOptions}
                            />
                        ))}
                    </div>)
                }
            </div >
        </>
    )
}

