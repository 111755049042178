import React, { useContext, useEffect, useState } from 'react'
import { OrdersContext } from 'Context/Analyse/OrdersContext'
import { productNameFromId, sampleClass } from 'AppContainer/ANALYSES/analysesConstantes'
import { useCallAnalysesApi } from 'Functions'
import set from 'lodash/set.js';
import DatalistOrders from './DatalistOrders'
import CustomSelect from 'Components/GENERAL/Form/CustomSelect/CustomSelect'
import MetaField from './MetaField'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function ProductFields({ product, setter, isNewProduct = false, isDuplicate = false }) {

    const { orders } = useContext(OrdersContext)
    const callAnalysesApi = useCallAnalysesApi()
    const [selectedOrderId, setSelectedOrderId] = useState('')
    const [currentOrder, setCurrentOrder] = useState([])

    const updateProductKey = (key, value) => {
        setter({ ...product, [key]: value })
    }

    const updateProductOrder = (keyPath, value) => {
        setter(pvsProduct => {
            const newProduct = pvsProduct
            set(newProduct, `product_order.${keyPath}`, value);
            return { ...newProduct }
        })
    }

    useEffect(() => {
        setSelectedOrderId(product.id_commande)
    }, [product.id_commande])

    useEffect(() => {
        setCurrentOrder([])
        if (selectedOrderId || selectedOrderId === 0) {
            updateProductKey('id_commande', selectedOrderId)
            const selectedCurrentOrder = orders.find(order => selectedOrderId == order.ID)

            if (selectedCurrentOrder?.subscription && isNewProduct) {
                const controller = new AbortController()
                callAnalysesApi(`woocommerce-products`, { method: "get", signal: controller.signal })
                    .then((res) => {
                        setCurrentOrder({ products: res.data, subscription: 1 })
                    })
                    .catch((err) => console.log(err))
                return () => {
                    controller.abort()
                }
            } else setCurrentOrder(selectedCurrentOrder)
        } else updateProductKey('product_order', '')
    }, [selectedOrderId])

    return (
        <div>
            <div className='flex'>
                <div className='half-m-width'>
                    <h3>Commande</h3>
                    <div className='m-b-25'>
                        <DatalistOrders
                            required={true}
                            defaultOrderId={product.id_commande}
                            onSelect={setSelectedOrderId}
                            onlyAvaible={isNewProduct}
                        />
                    </div>
                    <div className='flex column m-b-10'>
                        {currentOrder && (selectedOrderId || selectedOrderId === 0)
                            ? currentOrder.products
                                ? <div>
                                    {isDuplicate
                                        ? <div className='m-b-10 error'>Laissez vide le champ ci dessous pour concerver les options de l'échantillon de référence</div>
                                        : null
                                    }
                                    <label>Analyse demandée :</label>

                                    {currentOrder.subscription && isNewProduct
                                        ? <CustomSelect
                                            optionsList={
                                                currentOrder.products.map((prod, index) => ({
                                                    value: prod.id,
                                                    content: (
                                                        <div style={{ lineHeight: '16px' }} key={index} value={prod.id}>
                                                            <div>{prod.name}</div>
                                                        </div>
                                                    ),
                                                }))
                                            }
                                            value={product.product_order.product_id}
                                            defaultLabel='Sélectionnez une commande'
                                            onSelect={updateProductOrder}
                                            customParameters={['product_id']}
                                        />
                                        : <CustomSelect
                                            optionsList={Array.isArray(currentOrder.products)
                                                ? currentOrder.products.map((productOrder, index) => ({
                                                    value: productOrder.product_order_id,
                                                    content: (
                                                        <div style={{ lineHeight: '16px' }} key={index} value={productOrder.product_order_id}>
                                                            <div className='underline'>{productNameFromId(productOrder.product_id)}</div>
                                                            {Object.entries(productOrder.meta).map(([key, value], index) => (
                                                                <div key={index}>{key} : {value}</div>
                                                            ))}
                                                            <div>{currentOrder.subscription ? "Analyses déjà effectuées" : "Quantité restantes"} : {productOrder.quantity}</div>
                                                        </div>
                                                    ),
                                                }))
                                                : []
                                            }
                                            value={product.product_order_id}
                                            defaultLabel='Sélectionnez une commande'
                                            onSelect={updateProductKey}
                                            customParameters={['product_order_id']}
                                        />}
                                    {currentOrder.subscription && product.product_order.product_id
                                        ? <MetaField
                                            WCProduct={currentOrder.products.find(prod => prod.id === product.product_order.product_id)}
                                            setter={updateProductOrder}
                                        />
                                        : null
                                    }
                                </div>
                                : <MidLoader />
                            : null}
                    </div>
                </div>
                <div className='half-m-width'>
                    <h3>Échantillon</h3>
                    <div className='flex-center m-b-10'>
                        <label>Date de réception :</label>
                        <input
                            type='date'
                            value={product.date_reception ?? ''}
                            onInput={e => updateProductKey('date_reception', e.target.value)}
                            required
                        />
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>Dénomination :</label>
                        <input
                            type='text'
                            value={product.denomination ?? ''}
                            onInput={e => updateProductKey('denomination', e.target.value)}
                            placeholder='indiquée dans le formulaire'
                            required
                        />
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>N° de lot du client :</label>
                        <input
                            type='text'
                            value={product.num_lot ?? ''}
                            onInput={e => updateProductKey('num_lot', e.target.value)}
                            placeholder='indiqué dans le formulaire'
                        />
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>Nom du demandeur :</label>
                        <input
                            type='text'
                            value={product.demandeur ?? ''}
                            onInput={e => updateProductKey('demandeur', e.target.value)}
                            placeholder='Laisser vide si par défaut'
                        />
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>Masse :</label>
                        <input
                            type="text"
                            value={product.masse ?? ''}
                            onInput={e => updateProductKey('masse', e.target.value)}
                            placeholder='masse du produit envoyé'
                            required
                        />
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>Type de produit :</label>
                        <input value={product.type_produit ?? "default"} onChange={e => updateProductKey('type_produit', e.target.value)}
                            type='text'
                            list='product-type-list' />
                        <datalist id='product-type-list'>
                            <option value="default">Choisir un type</option>
                            {sampleClass.map((productType, index) => {
                                return (<option key={index} value={productType} />)
                            })}
                        </datalist>
                    </div>
                    <div className='flex-center m-b-10'>
                        <label>Classe d'échantillon :</label>
                        <select value={product.sample_class ?? "default"} onChange={e => updateProductKey('sample_class', e.target.value)}>
                            <option value="default">Choisir une classe</option>
                            {sampleClass.map((sampleClass, index) => {
                                return (<option key={index} value={sampleClass}>{sampleClass}</option>)
                            })}
                        </select>
                    </div>
                    {/* <div className='flex-end'>
                        <div className='input'>
                            <label className='m-r-10'>Notifier par mail:</label>
                            <input
                                className='erp-check'
                                type="checkbox"
                                checked={product.mail ?? ''}
                                onChange={e => updateProductKey('mail', e.target.checked)}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </div >
    )
}
