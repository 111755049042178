import React, { useEffect, useState } from 'react'
import { useCallAnalysesApi } from 'Functions'
import { AxiosResponse } from 'axios';
import Chart from 'react-google-charts';
import MidLoader from 'Components/MidLoader/MidLoader';
import Select from 'Components/FormElement/Select';
import { createImmediatelyInvokedFunctionExpression } from 'typescript';

interface RawAnalyses {
  ID: string,
  num_reference: string | null,
  bulk_id: string | null,
  exclure: string,
  date: string,
  humidity: string | null,
  batch_id: string | null,
  results: string,
  id_commande: string | null,
  denomination: string | null,
  num_lot: string | null,
  type_produit: string | null,
  masse: string | null,
  date_ajout: string | null,
  date_reception: string | null,
  date_envoie: string | null,
  etat: string,
  mail: string,
  demandeur: string | null,
  product_order_id: string | null,
  sample_class: string | null,
  emplacement: string | null
}

interface FormatedAnalyses {
  ID: number,
  num_reference: number | null,
  bulk_id: number | null,
  exclure: number,
  date: string,
  humidity: number | null,
  batch_id: number | null,
  results: string,
  id_commande: number | null,
  denomination: string | null,
  num_lot: string | null,
  type_produit: string | null,
  masse: string | null,
  date_ajout: string | null,
  date_reception: string | null,
  date_envoie: string | null,
  etat: number,
  mail: number,
  demandeur: string | null,
  product_order_id: number | null,
  sample_class: string | null,
  emplacement: string | null
}

interface DataSummary {
  total_analyses: number,
  total_lab_analyses: number,
  total_ordered_analyses: number,
  total_subcribers_analyses: number,
  percent_lab_analyses: number,
  percent_ordered_analyses: number,
  percent_subcribers_analyses: number,
}

type ApiResponseType = AxiosResponse<{ data: DashboardResponse }>;


interface DashboardResponse {
  data: RawAnalyses
}

function formatAnalyse(originalObj: RawAnalyses): FormatedAnalyses {
  return Object.keys(originalObj).reduce((newObj, key) => {
    const originalValue = originalObj[key];
    newObj[key] = typeof originalValue === 'string'
      ? parseFloat(originalValue)
      : originalValue;
    return newObj;
  }, {} as RawAnalyses);
}

function isAnalyseForLab(analyse: Record<"id_commande" | "batch_id" | "bulk_id", number | null>): boolean {
  return analyse.id_commande === 0 || analyse.batch_id !== null || analyse.bulk_id !== null
}

function isSubscriberAnalyse(analyse: Record<"id_commande", number | null>): boolean {
  return analyse.id_commande !== null && analyse.id_commande > 0 && analyse.id_commande < 50
}

export default function Analyse() {

  const callAnalysesApi = useCallAnalysesApi()

  const [cumulativeView, setCumulativeView] = useState<string>('false');
  const [rawAnalyses, setRawAnalyses] = useState<RawAnalyses | null>(null)
  const [formatedAnalyses, setFormatedAnalyses] = useState<FormatedAnalyses | null>(null)
  const [dataSummury, setDataSummury] = useState<DataSummary | null>(null)
  const [selectedYear, setSelectedYear] = useState<string>(`${(new Date().getFullYear())}`)

  useEffect(() => {

    setRawAnalyses(_ => null)
    setFormatedAnalyses(_ => null)

    callAnalysesApi('dashboard', { method: "get" }, { year: parseInt(selectedYear) })
      .then((res: ApiResponseType) => setRawAnalyses(_ => res.data.data))
  }, [selectedYear])

  const isCumulativeView = () => cumulativeView === 'true'


  useEffect(() => {
    if (rawAnalyses) {
      const formatAnalyses = {}

      let total_analyses = 0
      let total_lab_analyses = 0
      let total_ordered_analyses = 0
      let total_subcribers_analyses = 0

      rawAnalyses.forEach(analyses => {
        const date = new Date(analyses.date);
        const key: string = `${date.getFullYear()}-${date.getMonth() + 1}`;
        const formatedAnalyse = formatAnalyse(analyses)
        const analyses_for_lab = isAnalyseForLab(formatedAnalyse)

        total_analyses += 1
        total_lab_analyses += analyses_for_lab ? 1 : 0
        total_ordered_analyses += analyses_for_lab ? 0 : 1
        total_subcribers_analyses += isSubscriberAnalyse(formatedAnalyse) ? 1 : 0


        if (!formatAnalyses[key]) {
          formatAnalyses[key] = {
            total_analyses: 1,
            customers: analyses_for_lab ? 0 : 1,
            labo: analyses_for_lab ? 1 : 0,
            subscribers: isSubscriberAnalyse(formatedAnalyse) ? 1 : 0,
            key: key,
            date: new Date(key),
          }
        } else {
          formatAnalyses[key].labo += analyses_for_lab ? 1 : 0
          formatAnalyses[key].customers += analyses_for_lab ? 0 : 1
          formatAnalyses[key].subscribers += isSubscriberAnalyse(formatedAnalyse) ? 1 : 0
        }
      })

      setFormatedAnalyses(Object.values(formatAnalyses))

      setDataSummury({
        total_analyses: total_analyses,
        total_lab_analyses: total_lab_analyses,
        total_ordered_analyses: total_ordered_analyses,
        total_subcribers_analyses: total_subcribers_analyses,
        percent_lab_analyses: total_lab_analyses / total_analyses,
        percent_ordered_analyses: total_ordered_analyses / total_analyses,
        percent_subcribers_analyses: total_subcribers_analyses / total_analyses,
      })
    }
  }, [rawAnalyses])

  return (
    <>
      <div className='flex'>
        <Select
          value={cumulativeView}
          onChange={setCumulativeView}
          style={{ width: '300px' }}
        >
          <option value={'true'}>Différencier les abonnements</option>
          <option value={'false'}>Ne pas différencier les abonnements</option>
        </Select>
        <Select
          value={selectedYear}
          onChange={setSelectedYear}
        >
          <option value='2021'>2021</option>
          <option value='2022'>2022</option>
          <option value='2023'>2023</option>
          <option value='2024'>2024</option>
        </Select>
      </div>
      {formatedAnalyses && dataSummury
        ? <>
          <Chart
            chartType="ComboChart"
            data={[isCumulativeView()
              ? ["Date de gain", "Nombre total d'analyse", 'Commandes internet', 'Abonnements', "Laboratoire  -"]
              : ["Date de gain", "Nombre total d'analyse", "Clients", 'Laboratoire  -'],
            ...(isCumulativeView()
              ? formatedAnalyses.map((analyse) => [new Date(analyse.date), analyse.labo + analyse.customers, analyse.customers - analyse.subscribers, analyse.subscribers, analyse.labo])
              : formatedAnalyses.map((analyse) => [new Date(analyse.date), analyse.labo + analyse.customers, analyse.customers, analyse.labo])),
            ]}
            width="100%"
            height="400px"
            options={{
              title: "Nombre d'analyse efectuées par catégories par mois au cours de l'années sélectionné.",
              titleTextStyle: {
                fontSize: 18,
                color: '#3D553D',
                bold: true,
                marginBottom: 40,
              },
              colors: isCumulativeView()
                ? ['#453150', '#EAD7F9', '#5e5d5d', '#EFF4EE']
                : ['#453150', '#EAD7F9', '#5e5d5d', '#EFF4EE'],
              chartArea: {
                width: '100%',
              },
              legend: {
                padding: 0,
                position: 'top',
                alignment: 'end',
                textStyle: {
                  fontSize: 14,
                },
              },
              // isStacked: isCumulativeView(),
              bar: {
                groupWidth: '80%',
                maxWidth: '100',
                stroke: '#000',
                strokeWidth: 1,
              },
              animation: {
                duration: 250,
                easing: 'out'
              },
              seriesType: "bars",
              series: isCumulativeView()
                ? { 4: { type: "line" } }
                : { 5: { type: "line" } },
            }}
          />

          <div className='m-b-25'>Attention: le nombre d'analyse présenté ici n'est pas représentatif du chiffre d'affaire disponible sur l'ongle 'Chiffre d'affaire'</div>

          <div className='flex gap-1'>
            <div className='flex-1'>
              <Chart
                chartType="PieChart"
                data={[
                  ['catégorie', 'moyenne'],
                  ['% d\'analyses pour le labo', dataSummury.percent_lab_analyses * 100],
                  ...(isCumulativeView()
                    ? [
                      [`% d\'analyses des abonnements`, dataSummury.percent_subcribers_analyses * 100],
                      [`% d\'analyses des commandes du site`, (dataSummury.percent_ordered_analyses - dataSummury.percent_subcribers_analyses) * 100]
                    ]
                    : [
                      [`% d\'analyses des commandes (abonnements inclus)`, dataSummury.percent_ordered_analyses * 100]
                    ])
                ]}
                options={{
                  title: `Répartition de analyses en fonction du secteur depuis le début de l'année séléctionné`,
                  titleTextStyle: {
                    fontSize: 18, // Taille de la police du titre
                    color: '#3D553D', // Couleur du titre
                    bold: true,
                    marginBottom: 50,
                  },
                  slices: {
                    0: {
                      color: isCumulativeView() ? '#EFF4EE' : '#EAD7F9',
                      textStyle: { color: isCumulativeView() ? '#5e5d5d' : '#fff' }
                    },
                    1: { color: '#453150', textStyle: { color: '#EAD7F9' } },
                    2: { color: '#EAD7F9', textStyle: { color: '#453150' } },
                  },
                  pieHole: 0.4,
                  chartArea: {
                    width: '100%',
                  },
                  legend: {
                    padding: 0,
                    position: 'right',
                    textStyle: {
                      fontSize: 14
                    },
                  },
                }}
                width={"100%"}
                height={"350px"}
              />
            </div>
            <div className='flex-1'>
              <div className='list-item-bg'>
                <ul>
                  <div className='m-b-10 '>Nombre d'analyses total effectuées: {dataSummury.total_analyses}</div>
                  <li>Dont analyses pour les commandes internet: {dataSummury.total_ordered_analyses - dataSummury.total_subcribers_analyses}</li>
                  <li>Dont analyses pour les abonnements: {dataSummury.total_subcribers_analyses}</li>
                  <li>Dont analyses pour le laboratoire: {dataSummury.total_lab_analyses}</li>
                </ul>
              </div>
            </div>
          </div>
        </>
        : <MidLoader />

      }
    </>


  )
}
