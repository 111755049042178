import React, { useContext } from 'react'
import PlanDeals from "Components/_ERP/PlanDeal/PlanDeal"
import { PopupContext } from "Context/PopupContext.js"
import { appConstante } from 'appConstante';
import { useCurrentAppPermission } from 'Functions';
import DealDetails from '../DealDetails/DealDetails';
import CreateDeliverySheet from '../DeliverySheet/CreateDeliverySheet';
import CreateProductionSheets from 'AppContainer/ERP/SpecificationsAndRecipe/ProductionSheets/CreateProductionSheets';

/**
 * 
 * @param {Array} deals Devis Pipedrive + ERP à afficher sous formes de tableaux 
 * @param {Function} updateDeals fonction pour mettre à jour les deals, si une modificatione est faite
 * @returns 
 */

export default function ShowDealsArray({ deals, updateDeals, ableToPlan = true, showCreateDS = false }) {

    const { addPopupContent } = useContext(PopupContext)
    const currentAppPermission = useCurrentAppPermission()

    const addPlanification = (id) => {
        addPopupContent(<PlanDeals dealId={id} />, "1220px")
    }

    const creatDeliverySheet = (id) => {
        addPopupContent(<CreateDeliverySheet dealId={id} />, "1200px")
    }

    const showDetails = (id, maxWidth) => {
        addPopupContent(
            <DealDetails dealId={id} updateErpDeal={updateDeals} />,
            maxWidth
        )
    }
    const priorityToEmojy = {
        0: "⌛",
        1: "👌",
        2: "⚠️",
    }

    return (
        <>
            {deals.length === 0
                ? <h5>Aucune commande à afficher</h5>
                : <table className="beautiful-array">
                    <thead>
                        <tr style={{ height: "40px" }}>
                            <th />
                            <th />
                            <th>ID</th>
                            <th>Titre</th>
                            <th>Organisation</th>
                            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                <th>Montant HT</th>
                            }
                            <th>Date d'acceptation</th>
                            <th>
                                Date de réception
                                <br />
                                souhaité
                            </th>
                            {(currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && ableToPlan)
                                ? <th />
                                : null
                            }
                            {(currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && showCreateDS)
                                ? <th />
                                : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {deals.map(deal => (
                            <tr key={deal.id}>
                                <td>{priorityToEmojy[deal?.erp?.priority]}</td>
                                <td>
                                    <button onClick={() => showDetails(deal.id, "90%")}>
                                        Détails
                                    </button>
                                </td>
                                <td>
                                    <a href={`https://lelaboratoirefrancaisduchanvre-d03bb0.pipedrive.com/deal/${deal.id}`}>
                                        {deal.id}
                                    </a>
                                </td>
                                <td>{deal.title}</td>
                                <td>{deal.org_id && deal.org_id.name ? deal.org_id.name : 'Aucune organisation'}</td>
                                {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                    <td>{deal.value} €</td>
                                }
                                <td>
                                    {deal.won_time ? new Date(deal.won_time).toLocaleDateString(
                                        "fr-FR"
                                    ) : "Non accepté"}
                                </td>
                                <td>
                                    {deal?.erp?.due_date
                                        ? new Date(deal?.erp?.due_date).toLocaleDateString(
                                            "fr-FR"
                                        )
                                        : "Aucune"}
                                </td>
                                <>
                                    {(currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && ableToPlan)

                                        ? <td><button
                                            className="light-button"
                                            disabled={!deal.erp?.go_prod}
                                            onClick={() => addPlanification(deal.id)}
                                        >
                                            Planifier
                                        </button>
                                        </td>
                                        : null
                                    }
                                    {(currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && showCreateDS)
                                        ? <td><button
                                            className="light-button"
                                            disabled={!deal.erp?.go_expe}
                                            onClick={() => creatDeliverySheet(deal.id)}
                                        >
                                            Générer un bon de livraison
                                        </button></td>
                                        : null
                                    }
                                </>

                            </tr>
                        ))}
                    </tbody >
                </table >}
        </>
    )
}
