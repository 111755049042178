import React, {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ThirdNavBar from 'Components/NavBar/ThirdNavBar/ThirdNavBar';

export default function SpecificationProduct() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      location.pathname=== '/erp/specif-and-recipe/specifications-product' && navigate(`/erp/specif-and-recipe/specifications-product/my-specifications-product`); //Sous onglet par défaut
    }, [location.pathname])
  return (
    <ThirdNavBar menu={[
      { link: "/erp/specif-and-recipe/specifications-product/my-specifications-product", name: "Consulter", access: true},
      { link: "/erp/specif-and-recipe/specifications-product/add-specifications-product", name: "Ajouter", access: true },
    ]} />
  )
}