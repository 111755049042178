import { formatDateForInput } from "Functions"
import { emptyHistory } from "AppContainer/ERP/ERPConstantes"


export const ableToAddResults = (product_id) => {
    return product_id == 9348
}

export const productNameFromId = (product_id) => {
    const nameFromId = {
        9348: "Dosage de Cannabinoïdes",
        14613: "Dosage de métaux lourds",
        14608: "Dosage de terpènes",
        65: "Dosage solvants",
        63: "Dosage microbio",
        62: "Analyse des pesticides",
    }

    return nameFromId[product_id] ?? "inconnu"
}

export const sampleClass =
    [
        "Baume",
        "Fleurs",
        "Boisson",
        "Cacao",
        "Chewing-gum",
        "Chocolat",
        "Crème",
        "E-liquide",
        "Extrait - autre",
        "Extrait - broad",
        "Extrait - full",
        "Extrait - isolat CBD",
        "Extrait - isolat CBG",
        "Extrait - isolat CBN",
        "Fleurs",
        "Gummies",
        "Gélules",
        "Huile alimentaire",
        "Huile cosmétique",
        "Matcha",
        "Miel",
        "Plantes",
        "Résines",
        "Thé",
        "Tisane",
    ]

export const emptyAnalyse = {
    bulk_id: '',
    num_reference: '',
    date: formatDateForInput(new Date().getTime() - 24 * 60 * 60 * 1000),
    humidity: '',
    results: {},
    certificate_urls: []
}

// export const emptyProductOrder = {
//     product_id: '',
//     product_order_id: '',
//     meta: {},
// }

export const emptyProduct = {
    id_commande: '',
    product_order: '',
    denomination: '',
    num_lot: '',
    type_produit: '',
    sample_class: '',
    masse: '',
    date_reception: formatDateForInput(new Date()),
    demandeur: '',
    mail: 0,
}

export const analyseConsommables = [
    {
        ...emptyHistory,
        label: 'Tube pour centrifugeuse',
        batch_id: 1450,
        quantity: -1,
        type: "Automatique",
        history_type: 'remove',
    },
    {
        ...emptyHistory,
        label: 'Filtre pour seringue',
        batch_id: 1442,
        quantity: -1,
        type: "Automatique",
        history_type: 'remove',
    },
    {
        ...emptyHistory,
        label: 'seringue',
        batch_id: 1443,
        quantity: -1,
        type: "Automatique",
        history_type: 'remove',
    },
    {
        ...emptyHistory,
        label: 'bouchons pour vial',
        batch_id: 1463,
        quantity: -1,
        type: "Automatique",
        history_type: 'remove',
    },
    {
        ...emptyHistory,
        label: 'vial',
        batch_id: 1444,
        quantity: -1,
        type: "Automatique",
        history_type: 'remove',
    },
]


export const cannabinoidsResultFields = [
    {
        label: "CBDV :",
        key: "CBDV",
        condition: false,
        acide: false,
    },
    {
        label: "THCV :",
        key: "THCV",
        condition: false,
        acide: false,
    },
    {
        label: "CBDA :",
        key: "CBDA",
        condition: false,
        acide: true,
    },
    {
        label: "CBD :",
        key: "CBD",
        condition: false,
        acide: false,
    },
    {
        label: "CBD total:",
        key: "CBD TOT",
        condition: false,
        acide: true,
    },
    {
        label: "H4CBD :",
        key: "H4CBD",
        condition: true,
        acide: false,
    },
    {
        label: "CBC :",
        key: "CBC",
        condition: false,
        acide: false,
    },
    {
        label: "9R-HHC :",
        key: "9R-HHC",
        condition: true,
        acide: false,
        legalThreshold: 0,
    },
    {
        label: "9S-HHC :",
        key: "9S-HHC",
        condition: true,
        acide: false,
        legalThreshold: 0,
    },
    {
        label: "D8-THC :",
        key: "D8-THC",
        condition: true,
        acide: false,
        legalThreshold: 0,
    },
    {
        label: "D9-THCA :",
        key: "D9-THCA",

        condition: false,
        acide: true,
    },
    {
        label: "D9-THC :",
        key: "D9-THC",
        condition: false,
        acide: false,
        legalThreshold: 0.3,
    },
    {
        label: "D9-THC total:",
        key: "D9-THC TOT",
        condition: false,
        acide: true,
        legalThreshold: 0.3,
    },
    {
        label: "CBGA :",
        key: "CBGA",
        condition: false,
        acide: true,
    },
    {
        label: "CBG :",
        key: "CBG",
        condition: false,
        acide: false,
    },
    {
        label: "CBG total :",
        key: "CBG TOT",
        condition: false,
        acide: true,
    },
    {
        label: "CBN :",
        key: "CBN",
        condition: false,
        acide: false,
    },
    {
        label: "D8-THCP :",
        key: "D8-THCP",
        condition: true,
        acide: false
    },
    {
        label: "D9-THCP :",
        key: "D9-THCP",
        condition: true,
        acide: false
    },
    {
        label: "S-HHCPO :",
        key: "S-HHCPO",
        condition: true,
        acide: false
    },
    {
        label: "R-HHCPO :",
        key: "R-HHCPO",
        condition: true,
        acide: false
    },
    {
        label: "S-H4CBD :",
        key: "S-H4CBD",
        condition: true,
        acide: false
    },
    {
        label: "R-H4CBD :",
        key: "R-H4CBD",
        condition: true,
        acide: false
    },
    {
        label: "THCPO :",
        key: "THCPO",
        condition: true,
        acide: false
    },
]