import React, { useContext, useState, useEffect } from 'react'
import { PopupContext } from 'Context/PopupContext'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import Pagination from 'Components/Pagination/Pagination'
import MidLoader from 'Components/MidLoader/MidLoader'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import { RecipeContext } from 'Context/ERP/RecipeContext'
import Datalist from 'Components/Dataliste/Dataliste'
import { Link } from 'react-router-dom'
import SelectOrganization from '../../../../Components/_ERP/Brands/SelectOrganization'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function ProductSpecifications({ }) {
    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const { articles } = useContext(ArticleContext)
    const { recipeNames } = useContext(RecipeContext)
    const { addPopupContent, showPopup } = useContext(PopupContext)

    const articlesByCategory = articles && articles.filter(article => article.category == "Produit fini")

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedRecipe, setSelectedRecipe] = useState(null)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null)

    const [filteredProductSpec, setFilteredProductSpec] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)


    useEffect(() => {
        const controller = new AbortController()
        let filterAttributes = {};
        if (selectedProduct) { filterAttributes = { article_id: selectedProduct } }
        if (selectedRecipe) { filterAttributes = { recipe_id: selectedRecipe } }
        if (selectedOrganizationId) { filterAttributes = { organization_id: selectedOrganizationId } }

        callApi(
            `${appConstante.servers.PANORAMA_ENDPOINT}/erp/products-specifications`, { method: "get" }, controller.signal,
            {
                filter: filterAttributes,
                limit: 0,
            },
        )
            .then(res => {
                setFilteredProductSpec(res.data.data)
                setTotalPage(res.data.total_pages)
                res.data.total_pages === 0 && setCurrentPage(1)
                currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
            })
            .catch(error => {
                console.log(error)
            })

        return () => {
            controller.abort()
        }
    }, [selectedProduct, selectedRecipe, selectedOrganizationId, currentPage])


    const deleteProductSpecifitcation = (id) => {
        addNotificationContent({
            title: 'Suppression en cours',
            content: <MiniLoader />,
            infinit: true,
        })
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification/${id}`, { method: "delete" })
            .then(_ => {
                addNotificationContent({
                    content: 'Suppression réussi',
                })
                showPopup(false)
                setFilteredProductSpec(productSpef => productSpef.filter(p => p.id !== id))
            })
    }

    const showDeleteSpecificationProduct = (id) => {
        addPopupContent(<div>
            <h5>Attention, la suppression est irréversible</h5>
            <div className='flex gap-2'>
                <button onClick={_ => showPopup(false)} className='light-button'>Annuler</button>
                <button onClick={_ => deleteProductSpecifitcation(id)} className='error-strong-button'>Supprimer</button>
            </div>
        </div>, "550px")
    }

    return (
        <>
            <div className='wrap flex-center flex-start m-b-25'>
                <div className='m-r-25'>
                    <Datalist
                        placeholder={'Sélectionner un produit'}
                        items={articlesByCategory && articlesByCategory.map(article => ({ id: article.id, value: article.name }))}
                        onSelect={setSelectedProduct}
                        width='300px'
                    />
                </div>
                <div className='m-r-25'>
                    <Datalist
                        placeholder={'Sélectionner une recette'}
                        items={recipeNames && recipeNames.map(recipe => ({ id: recipe.id, value: recipe.name }))}
                        onSelect={setSelectedRecipe}
                        width='300px'
                    />
                </div>
                <div>
                    <SelectOrganization
                        setter={setSelectedOrganizationId}
                        width='300px'
                    />
                </div>
            </div>
            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
            {filteredProductSpec
                ? (filteredProductSpec.length > 0
                    ? <table className='beautiful-array m-t-10'>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Produit</th>
                                <th>Recette</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredProductSpec.map(productSpecification =>
                                    <tr key={productSpecification.id}>
                                        <td>
                                            <button className='light-button'>
                                                <Link to={`/erp/specif-and-recipe/specifications-product/edit/${productSpecification.id}`}>
                                                    Modifier
                                                </Link>
                                            </button>
                                        </td>
                                        <td>
                                            <button className='light-button'>
                                                <Link to={`/erp/specif-and-recipe/specifications-product/consult/${productSpecification.id}`}>
                                                    Consulter
                                                </Link>
                                            </button>
                                        </td>
                                        <td>
                                            <div className='strong-1'>{productSpecification.article.name}</div>
                                        </td>
                                        <td>
                                            <div className='strong-1'>{productSpecification?.recipe ? productSpecification.recipe.name : "Pas de recette"}</div>
                                        </td>
                                        <td>
                                            <DeleteButton
                                                callBack={showDeleteSpecificationProduct}
                                                parameters={[productSpecification.id]}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    : <h5>Aucune Spécification produit.</h5>)
                : <MidLoader />}
        </>
    )
}
