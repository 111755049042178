import React, { useState, useContext, useEffect } from "react"
import { CommentsContext } from "Context/CommentContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import './comments.css'
import TextEditor from "Components/GENERAL/Form/TextEditor/TextEditor"
import Skeleton from "Components/Skeleton/Skeleton"

export default function Comments({ target, target_id }) {
    const { addComment, getComments } = useContext(CommentsContext)
    const [comments, setComments] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getComments(target, target_id).then(comments => setComments(comments))
    }, [])

    const [newCommentContent, setNewCommentContent] = useState("")

    const addNewComment = () => {
        setLoading(_ => true)
        addComment({ content: newCommentContent, target: target, target_id: target_id })
            .then((comment) => {
                setComments([...comments, comment])
                setNewCommentContent('')
            })
            .finally(_ => setLoading(false))
    }

    return (
        <>
            <div className="flex gap-1">
                <div className="half-width flex-column">
                    {" "}
                    {/* Add newComment */}
                    <div className="full-width">
                        <label htmlFor="newComment" className="left">
                            Ajouter un commentaire:{" "}
                        </label>
                        <div>
                            <TextEditor
                                setter={setNewCommentContent}
                                value={newCommentContent}
                                placeholder="Nouveau commentaire..."
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => addNewComment()}
                        className="light-button m-t-10"
                        disabled={loading}
                    >
                        Ajouter
                    </button>
                </div>
                <div className="column-flex half-width">
                    <label className="left">Commentaires:</label>
                    <div id="comments-section">
                        {comments
                            ? comments.length > 0
                                ? comments.map((comment, index) => (
                                    <div key={index} className="flex column comment-line">
                                        <div className="flex">
                                            <div className="date-comment">{comment.user ? comment.user.display_name : comment.user_name}</div>
                                            <div className="date-comment">
                                                {`${new Date(comment.createdAt).toLocaleDateString("fr")} - ${new Date(comment.createdAt).toLocaleTimeString("fr")}`}
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: comment.content }} className="text-comment"></div>
                                    </div>
                                ))
                                : <div style={{ height: "100%" }} className="center full-width">
                                    Aucun commentaire
                                </div>
                            : <>
                                <Skeleton height={'60px'} />
                                <Skeleton height={'60px'} />
                            </>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}