import React, { createContext, useState, useEffect, useContext } from "react"
import { NotificationContext } from "Context/NotificationContext"
import { MslAccessContext } from "./MslAccessContext"
import { useCallMSLApi } from "Functions"

export const CustomProductsContext = createContext()

function CustomProducts(props) {
    const [customProducts, setCustomProducts] = useState(null)
    const [draft, setDraft] = useState(false)
    const [organizationId, setOrganizationId] = useState(null)
    const [productReferenceId, setProductReferenceId] = useState(null)

    const { mslNonce } = useContext(MslAccessContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const callMSLApi = useCallMSLApi()


    useEffect(() => {
        const controller = new AbortController()
        if (mslNonce) {
            setCustomProducts(null)
            const data = { 'raw-results': true }
            data.draft = draft
            productReferenceId && (data.product_reference_id = productReferenceId)
            organizationId && (data.organization_id = organizationId)

            callMSLApi(`custom-products`, { method: "get", signal: controller.signal }, data)
                .then(res => {
                    setCustomProducts(res.data.data.map(product => {
                        return ({
                            ...product,
                            value: product.name
                        })
                    }))
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: err.response.data.message,
                    })
                })
        }
        return () => {
            controller.abort()
        }
    }, [mslNonce, draft])

    const getProducts = async (referenceId, signal = null) => {
        return new Promise((resolve, reject) => {
            callMSLApi(`custom-products`, { method: "get", signal: signal }, { product_reference_id: referenceId })
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    return (
        <CustomProductsContext.Provider value={{
            customProducts,
            setCustomProducts,
            draft,
            setDraft,
            organizationId,
            setOrganizationId,
            productReferenceId,
            setProductReferenceId,
            getProducts,
        }}>
            {props.children}
        </CustomProductsContext.Provider>
    )
}

export default CustomProducts
