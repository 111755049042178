import React from 'react'
import Analyses from './Analyses'
import ComptesClients from './ComptesClientsItem/ComptesClients'
import ProductReception from './ProductReceptionItem/ProductReception'
import Bulk from './BulkItem/Bulk'
import RawMaterials from './RawMaterialsItem/RawMaterials'
import AnalysesArray from './AnalysesItem/AnalysesArray'
import MailResults from './MailResultsItem/MailResults'
import AnalyseSearch from './AnalyseSearchItem/AnalyseSearch'
import ConsultUserInfos from './ComptesClientsItem/ConsultUserInfos'
import DealsAnalyses from './Deals/DealsAnalyses'

export const AnalysesRoutes = [
    {
        path: "/analyses", element: <Analyses />, subRoutes: [
            { path: "/analyses/comptes-clients", element: <ComptesClients /> },
            { path: "/analyses/comptes-clients/:id", element: <ConsultUserInfos /> },
            { path: "/analyses/deals", element: <DealsAnalyses /> },
            { path: "/analyses/product-reception", element: <ProductReception /> },
            { path: "/analyses/bulk", element: <Bulk /> },
            { path: "/analyses/matieres-premieres", element: <RawMaterials /> },
            { path: "/analyses/analyses", element: <AnalysesArray /> },
            { path: "/analyses/resultats-mails", element: <MailResults /> },
            // { path: "/analyses/recherche", element: <AnalyseSearch /> }
        ]
    }
]