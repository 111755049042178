import React, { createContext, useState } from "react"

/**
 * use addPopupContent() to add popup content.
 * use showPopup() to show or hide popup
 */
export const PopupContext = createContext()

const emptyPopup = {
  fullWidth: false,
  content: null,
}
function PopupContextProvider(props) {
  // MAIN POPUP
  const [togglePopup, setTogglePopup] = useState(false)
  const [popupContent, setPopupContent] = useState(emptyPopup)

  /**
   *
   * @param {Boolean} toggle true or false to show or hide the popup
   * @param {String} maxWidth width of the popup as css property ex: '1200px'
   */
  const showPopup = (toggle, maxWidth, fullWidth) => {
    if (toggle === true) {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden"
    } else {
      document.getElementsByTagName("body")[0].style.overflowY = "auto"
    }

    maxWidth && (document.getElementById("popup").style.maxWidth = maxWidth)

    if (fullWidth) {
      document.getElementById("popup").classList.add("full-width-popup")
    } else {
      document.getElementById("popup").classList.remove("full-width-popup")
    }

    setTogglePopup(toggle)
    /* Suppression du contenu si masque de la popup */
    !toggle &&
      setTimeout(() => {
        setPopupContent(emptyPopup)
      }, 250)
  }

  const addPopupContent = (content, maxWidth, fullWidth = false) => {
    showPopup(true, maxWidth, fullWidth)
    setPopupContent({ content: content, fullWidth: fullWidth })
  }

  // SECOND POPUP
  const [togglePopup2, setTogglePopup2] = useState(false)
  const [popupContent2, setPopupContent2] = useState()

  const showPopup2 = (toggle) => {
    setTogglePopup2(toggle)
    /* Suppression du contenu si masque de la popup */
    !toggle &&
      setTimeout(() => {
        setPopupContent2("")
      }, 250)
  }

  const addPopupContent2 = (content) => {
    showPopup2(true)
    setPopupContent2(content)
  }

  return (
    <PopupContext.Provider
      value={{
        togglePopup,
        showPopup,
        popupContent,
        addPopupContent,
        togglePopup2,
        showPopup2,
        popupContent2,
        addPopupContent2,
      }}
    >
      {props.children}
    </PopupContext.Provider>
  )
}

export default PopupContextProvider