import { ErpBatchContext } from 'Context/ERP/ErpBatchContext'
import React, { useContext, useEffect, useState } from 'react'

export default function DeliverySheetProductItemV2({ passedProduct }) {

    const { getERPBatch } = useContext(ErpBatchContext)
    const [product, setProduct] = useState({ ...passedProduct })

    const getBatchData = async () => {
        const productWithBatchData = await Promise.all(product.batchs.map(async batch => {
            const ERPbatch = await getERPBatch(batch.id)
            return {...ERPbatch, sended_quantity: batch.quantity}

        }))
        setProduct(psProduct => { return { ...psProduct, batchs: productWithBatchData } })
    }
    useEffect(() => {
        getBatchData()
    }, [passedProduct])

    return (
        <tr>
            <td>
                <div>
                    <div style={{ fontSize: "15px", fontWeight: "600", textAlign: "left", marginBottom: "5px"}}>
                        {product.name}
                    </div>
                    <ul className='m-t-0 m-b-0' style={{ fontSize: "16px", fontWeight: "600", textAlign: "left", }}>
                        {/* Numéro(s) de lot */}
                        {product?.batchs?.map((batch, index) => {
                            return (
                                <li key={index}>
                                    <div className='left' style={{ fontSize: "14px", color: "rgb(80,80,80)" }}>
                                        Numéro de lot : {batch.batch_number}
                                    </div>
                                    <div className='left' style={{ fontSize: "14px", color: "rgb(80,80,80)" }}>
                                        Quantité envoyée: {batch.sended_quantity}
                                    </div>
                                    {batch.ddm
                                        ? <div className='left' style={{ fontSize: "14px", color: "rgb(80,80,80)" }}>
                                            DDM : {new Date(batch.ddm).toLocaleDateString()}
                                        </div>
                                        : null}
                                </li>
                            )
                        })}
                    </ul>
                    {product.bl_comment
                        ? <div className='left m-t-10' style={{ fontSize: "14px", color: "rgb(80,80,80)" }}>
                            Commentaire : {product.bl_comment}
                        </div>
                        : null}
                </div>
            </td>
            <td>{product.deal_quantity}</td>
            <td>{product.quantity}</td>
            <td>{product.to_sheep_quantity}</td>
        </tr>
    )
}
