import React from "react"
import "./Navbar.css"
import { NavLink } from "react-router-dom"

export default function NavBar({ menu }) {
  return (
    <div className="erp-main-navigation">
      {menu.map((menuItem, index) => (
        <NavLink key={index} to={menuItem.to} className="erp-navigation-button">
          {menuItem.name}
        </NavLink>
      ))}
    </div>
  )
}
