import React, { useState, useContext } from 'react'
import { PriceCategoriesContext } from 'Context/Commercial/PriceCategoriesContext'

export default function SelectPriceCategory({ setter, defaultValue, customParams = [], simplePrice = false }) {

  const { priceCategories } = useContext(PriceCategoriesContext)
  const [priceCategoryId, setPriceCategoryId] = useState(defaultValue)
  const updateCatId = (id) => {
    setter(...customParams, id)
    setPriceCategoryId(id)
  }

  return (
    <select value={priceCategoryId} onChange={e => updateCatId(parseInt(e.target.value))} required>
      <option value=""> --- SELECT --- </option>
      {simplePrice ? <option value="-1"> -- Prix simple -- </option> : null}
      {priceCategories.map(cat => (
        <option key={cat.id} value={cat.id}>{cat.value}</option>
      ))}
    </select>
  )
}
