import React, { createContext, useContext, useState } from "react"
import { useCallApi, useCallPipedriveApi } from "Functions"
import { NotificationContext } from "Context/NotificationContext"
import { appConstante } from 'appConstante'
import axios from 'axios'
import { UserInfosContexte } from "Context/UserInfosContexte"

export const DealContext = createContext()
export default function DealContextProvider(props) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { getToken } = useContext(UserInfosContexte)

    const callPipedriveApi = useCallPipedriveApi()
    const callApi = useCallApi()
    const [deals, setDeals] = useState([])

    const getDealFromId = async (id) => {
        const deal = deals.find(deal => deal.id === id)
        if (deal) {
            return deal
        } else {
            const resDeal = await getDealFromPipedrive(id)
            setDeals(prvDeals => [...prvDeals, resDeal])
            return resDeal
        }
    }

    const getDealFromPipedrive = (id) => {
        return new Promise((resolve, reject) => {
            callPipedriveApi(`deals/${id}`)
                .then(res => resolve(res.data.data))
                .catch(error => reject(error))
        })
    }

    const sendDeal = (deal, wording, mails) => {
        return new Promise((resolve, reject) => {
            if (deal.person_id.email[0]?.value) {
                addNotificationContent({
                    error: false,
                    content: "Envoie du devis en cours",
                    infinit: true,
                })
                const APIoptions = {
                    url: `${appConstante.servers.LLFC_ENDPOINT}/send-deal`,
                    method: "POST",
                }
                APIoptions.data = { deal: deal, wording: wording, mails: mails, key: appConstante.access.LLFC_KEY }
                axios(APIoptions)
                    // callApi(
                    //     `${appConstante.servers.LLFC_ENDPOINT}/send-deal`,
                    //     { method: "post" },
                    //     null,
                    //     { deal: deal, wording: wording, mails: mails }
                    // )
                    .then(res => {
                        resolve(res.data.data)
                        addNotificationContent({
                            content: `Le devis a été envoyé`
                        })
                    })
            } else {
                addNotificationContent({
                    error: true,
                    content: "Le devis n'est pas associé à une personne ou la personne ne possède pas de mail",
                })
                reject(false)
            }
        })
    }

    const getDealLink = (deal_id) => {
        return new Promise((resolve, reject) => {
            addNotificationContent({
                error: false,
                content: "Récupération de lien en cours",
                infinit: true,
            })
            const APIoptions = {
                url: `${appConstante.servers.LLFC_ENDPOINT}/deal-link?deal_id=${deal_id}&key=${appConstante.access.LLFC_KEY}`,
            }
            axios(APIoptions)
                // callApi(
                //     `${appConstante.servers.LLFC_ENDPOINT}/deal-link`,
                //     { method: "get" },
                //     null,
                //     { deal_id: deal_id }
                // )
                .then(res => {
                    resolve(res.data.data)
                    addNotificationContent({})
                })
        })
    }

    const updateDeal = async (updatedDeal) => {
        // FORMATED DEAL TO SEND
        const formatedUpdatedDeal = {
            "id": updatedDeal.id,
            [appConstante.pipedrive.COMMENT]: updatedDeal[appConstante.pipedrive.COMMENT],
            [appConstante.pipedrive.PAYMENT_TERMS]: updatedDeal[appConstante.pipedrive.PAYMENT_TERMS],
            [appConstante.pipedrive.EXPIRY_DATE]: updatedDeal[appConstante.pipedrive.EXPIRY_DATE],
            [appConstante.pipedrive.DEAL_DELIVERY_ADRESS]: updatedDeal[appConstante.pipedrive.DEAL_DELIVERY_ADRESS],
            [appConstante.pipedrive.CHARGING_POLE]: updatedDeal[appConstante.pipedrive.CHARGING_POLE].join(','),
        }

        const dealProductPropertiesToKeep = {
            'item_price': 'Number',
            'discount': 'Number',
            'discount_type': 'String',
            'quantity': 'Number',
            'tax': 'Number',
            'comments': 'String',
        }

        // UPDATE DEAL PRODUCTS
        const formatedDealProducts = Promise.all(updatedDeal.deal_products.map(async (dealProduct) => {
            const formatedDealProduct = Object.fromEntries(
                Object.entries(dealProduct)
                    .map(([key, value]) =>
                        dealProductPropertiesToKeep[key]
                            ? dealProductPropertiesToKeep[key] === 'Number'
                                ? [key, parseFloat(value)]
                                : [key, value]
                            : []
                    )
                    .filter(([key]) => key)
            )
            await callPipedriveApi(`deals/${updatedDeal.id}/products/${dealProduct.id}`, { method: "put" }, formatedDealProduct)
            return formatedDealProduct
        }))


        const APIoptions = {
            url: `${appConstante.servers.LLFC_ENDPOINT}/deals/${updatedDeal.id}`,
            method: "PUT",
        }
        APIoptions.data = {
            ...updatedDeal,
            key: appConstante.access.LLFC_KEY,
            prodcuts: formatedDealProducts,
            owner: {
                name: updatedDeal.user_id?.name,
                email: updatedDeal.user_id?.email,
            }
        }

        addNotificationContent({
            error: false,
            content: "modification en cours",
        })

        await axios(APIoptions)
        return new Promise((resolve, reject) => {

            callPipedriveApi(`deals/${updatedDeal.id}`, { method: "put" }, formatedUpdatedDeal)
                .then(res => {
                    resolve(res.data.data)
                    addNotificationContent({
                        content: `Le devis a été modifié`
                    })
                })
        })
    }

    return (
        <DealContext.Provider value={{ deals, getDealFromId, updateDeal, sendDeal, getDealLink }}>
            {props.children}
        </DealContext.Provider>
    )
}
