/************** STOCK *************/

import { formatDateForInput, getColorFromNumber } from "Functions"

export const emptyAdditionalDatas = {
    transplanting: {
        enable: 0,
        comment: '',
    },
    DDM: {
        enable: 0,
        comment: '',
    },
    batch: {
        enable: 0,
        comment: '',
    },
    long_description: "",
    custom: [],
}

export const emptyHistory = {
    batch_id: null,
    quantity: '',
    prvQuantity: 0, // Usefull when update - not sended in BE
    date: formatDateForInput(new Date()),
    comment: "",
    expiration_date: null,
    type: "Manuel",
    history_type: 'add',
    price: '',
    archive: 0,
    exclude_from_stats: 0,
    destocking: 0,
    product_id: null,
    article_id: null,
}

export const getBatchDDM = (batch) => {
    let firstDDM = null
    batch.history.forEach(history => {
        const currentDate = history.expiration_date ? new Date(history.expiration_date) : null
        if (currentDate && !firstDDM) {
            firstDDM = currentDate
        } else if (firstDDM && currentDate) {
            firstDDM = currentDate < firstDDM ? currentDate : firstDDM
        }
    })
    return firstDDM
}

export const getBatchStock = (batch) => {
    let stock = 0
    batch.history.forEach(history => {
        stock += history.quantity
    })
    return stock.toFixed(4) * 1
}


export const getDealColor = (id) => {
    return getColorFromNumber(id);
}

/**
 * 
 * @param {string} quantityTypeStart masse | volume
 * @param {string} quantityTypeEnd masse | volume
 * @returns 
 */
export const getDensityForm = (quantityTypeStart, quantityTypeEnd) => {
    try {
        if (quantityTypeStart.toLowerCase() === 'masse' && quantityTypeEnd.toLowerCase() === 'volume') {
            return (quantity, density) => {
                return quantity / density
            }
        } else if (quantityTypeStart.toLowerCase() === 'volume' && quantityTypeEnd.toLowerCase() === 'masse') {
            return (quantity, density) => {
                return quantity * density
            }
        } else {
            return (quantity) => quantity
        }
    } catch (e) {
        console.error(
            "parameter quantityTypeStart, quantityTypeEnd is invalid : \n",
            "quantityTypeStart :", quantityTypeStart, "\n",
            "quantityTypeEnd : ", quantityTypeEnd
        )
        return (quantity) => quantity
    }
}

export const emptyDeliverySheetItem = {
    batchs: [{ id: null, quantity: 0 }],
    batchs_names: '',
    bulks_names: '',
    bl_comment: '',
    comments: '',
    deal_quantity: 0,
    product_id: '',
    ignored: false,
    index: '',
    name: '',
    pipedrive_name: '',
    quantity: 0,
    shipped_qty: 0,
    status: 'Aucun',
}

export const addMargeToLabels = (quantity) => {
    return Math.ceil(quantity * 1.05 + 20)
}