import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { appConstante, categories, getStockUnitOfMeasure } from 'appConstante'
import Field from 'Components/_ERP/Field'
import { VariablesContext } from 'Context/ERP/VariablesContext'
import SelectBrand from 'Components/_ERP/Brands/SelectBrands'
import './articleFields.css'
import { emptyAdditionalDatas } from 'AppContainer/ERP/ERPConstantes'
import AdditionalData from '../../AdditionalData'
import InputSmall from 'Components/FormElement/SmallInput'
import { useCurrentAppPermission } from 'Functions'
/**
 * 
 * @param {Object} article article object to fill fields
 * @param {Function} setter setCurrentArticle to update fields
 * @returns 
 */

export default function ArticleFields({ article, setter, column = false }) {

    const { variables } = useContext(VariablesContext)
    const currentAppPermission = useCurrentAppPermission()
    const isDisabled = currentAppPermission('erp') <= appConstante.parameters.CAN_EDIT

    const [currenCategory, setCurrenCategory] = useState()
    const [currentArticle, setCurrentArticle] = useState({
        ...article,
        additional_datas: { ...emptyAdditionalDatas, ...article.additional_datas }
    })

    useEffect(() => {
        setCurrentArticle(prvArticle => ({
            ...prvArticle,
            category: article.category
        }))
    }, [article.category])

    const updateCurrentArticleProperty = (key, value) => {
        setCurrentArticle(prvArticle => ({ ...prvArticle, [key]: value }))
    }

    useEffect(() => {
        setter(currentArticle)
    }, [currentArticle])

    useEffect(() => {
        const filerCat = categories.filter(cat => (cat.name === currentArticle.category))
        setCurrenCategory(filerCat[0])
    }, [currentArticle.category])

    const setBrand = (brand_id) => {
        updateCurrentArticleProperty('brand_id', brand_id)
    }

    const setAdditionalDatas = (additionnalDatas) => {
        setCurrentArticle(prvArticle => ({ ...prvArticle, additional_datas: additionnalDatas }))
    }

    const updateAdditionalDataProperty = (key, value) => {
        updateCurrentArticleProperty("additional_datas", {
            ...currentArticle.additional_datas,
            [key]: value,
        })
    }

    const addCustomAdditionalsDatas = () => {
        updateCurrentArticleProperty('additional_datas', {
            ...currentArticle.additional_datas,
            custom: [...currentArticle.additional_datas.custom, {}]
        })
    }

    // update AdditionalData
    const updateCustomAdditionalData = (key, index, value) => {
        updateCurrentArticleProperty('additional_datas', {
            ...currentArticle.additional_datas,
            custom: currentArticle.additional_datas.custom.map((data, idx) =>
                idx === index
                    ? { ...data, [key]: value }
                    : data
            )
        })
    }
    // delete AdditionalData
    const deleteCutomAdditionalData = (index) => {
        updateCurrentArticleProperty('additional_datas', {
            ...currentArticle.additional_datas,
            custom: currentArticle.additional_datas.custom.filter((_, idx) => idx !== index)
        })
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateCurrentArticleProperty("image", file);
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <div className='flex column'>
            <div className={column ? 'flex column gap-1' : 'flex gap-1'}>
                <div className='flex-1'>
                    <h4 className='m-b-0'>Caractéristiques</h4>
                    {currenCategory && currenCategory.fields.map((categoryField, index) => (
                        <React.Fragment key={index}>
                            {categoryField.slug === 'brand_id'
                                ? <div className='m-b-10'>
                                    <SelectBrand
                                        defaultBrandId={currentArticle.brand_id
                                            ? currentArticle.brand_id
                                            : currentArticle.brand?.id
                                                ? currentArticle.brand.id
                                                : ""
                                        }
                                        setter={setBrand} />
                                </div>
                                : <Field
                                    label={categoryField.name}
                                    value={currentArticle[categoryField.slug]}
                                    setter={updateCurrentArticleProperty}
                                    field={categoryField.slug}
                                    variables={variables.filter(variable => (variable.field === categoryField.slug && variable.category === currenCategory.name))}
                                    type={categoryField.type}
                                    required={categoryField.required}
                                    key={categoryField.slug}
                                    border={column ? false : true}
                                    disabled={isDisabled}
                                />
                            }
                        </React.Fragment>
                    ))}
                    <div className="flex input relative hidden">
                        <InputSmall
                            label='image'
                            setter={handleImageChange}
                            type='file'
                            accept="image/*"
                            style={{ width: "100%" }}
                            disabled={isDisabled}
                        />
                    </div>
                    {currentArticle.image && (
                        <div className="flex-input">
                            <img
                                src={currentArticle.image}
                                alt="image actuelle"
                                style={{ height: 100, width: 100 }}
                            />
                        </div>
                    )}
                </div>
                <div className='flex-1' style={{ flexDirection: 'column' }}>
                    <div>
                        <h4 className='m-b-10'>Données additionnelles</h4>
                        {currenCategory &&
                            (currenCategory.id === 3
                                ? <>
                                    <label className='flex m-b-10 pointer'>
                                        <div>Repiquage</div>
                                        <input type='checkbox'
                                            checked={currentArticle.additional_datas.transplanting.enable === 1}
                                            onChange={e => setAdditionalDatas({
                                                ...currentArticle.additional_datas,
                                                transplanting: {
                                                    ...currentArticle.additional_datas.transplanting,
                                                    enable: e.target.checked ? 1 : 0
                                                }
                                            })}
                                            disabled={isDisabled}
                                        />
                                    </label>
                                    <div className='flex-start column m-b-10'>
                                        <div className='flex-center'>
                                            <input
                                                placeholder='Commentaire'
                                                type='text'
                                                style={{ height: "40px", width: '100%', border: 'none' }}
                                                value={currentArticle.additional_datas.transplanting.comment ?? ""}
                                                onChange={e => setAdditionalDatas({
                                                    ...currentArticle.additional_datas,
                                                    transplanting: {
                                                        ...currentArticle.additional_datas.transplanting,
                                                        comment: e.target.value
                                                    }
                                                })}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <label className='flex m-b-10' style={{ cursor: 'pointer' }}>
                                        <div>Inscription DDM</div>
                                        <input type='checkbox'
                                            checked={currentArticle.additional_datas.DDM.enable === 1}
                                            onChange={e => setAdditionalDatas({
                                                ...currentArticle.additional_datas,
                                                DDM: {
                                                    ...currentArticle.additional_datas.DDM,
                                                    enable: e.target.checked ? 1 : 0
                                                }
                                            })}
                                            disabled={isDisabled}
                                        />
                                    </label>
                                    <div className='flex-start column m-b-10'>
                                        <div className='flex-center'>
                                            <input type='text'
                                                placeholder='Commentaire'
                                                style={{ height: "40px", width: '100%', border: 'none' }}
                                                value={currentArticle.additional_datas.DDM.comment ?? ""}
                                                onChange={e => setAdditionalDatas({
                                                    ...currentArticle.additional_datas,
                                                    DDM: {
                                                        ...currentArticle.additional_datas.DDM,
                                                        comment: e.target.value
                                                    }
                                                })}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <label className='flex m-b-10' style={{ cursor: 'pointer' }}>
                                        <div>Inscription n° de lot</div>
                                        <input type='checkbox'
                                            checked={currentArticle.additional_datas.batch.enable === 1}
                                            onChange={e => setAdditionalDatas({
                                                ...currentArticle.additional_datas,
                                                batch: {
                                                    ...currentArticle.additional_datas.batch,
                                                    enable: e.target.checked ? 1 : 0
                                                }
                                            })}
                                            disabled={isDisabled}
                                        />
                                    </label>
                                    <div className='flex-start column m-b-10'>
                                        <div className='flex-center'>
                                            <input type='text'
                                                placeholder='Commentaire'
                                                style={{ height: "40px", width: '100%', border: 'none' }}
                                                value={currentArticle.additional_datas.batch.comment ?? ""}
                                                onChange={e => setAdditionalDatas({
                                                    ...currentArticle.additional_datas,
                                                    batch: {
                                                        ...currentArticle.additional_datas.batch,
                                                        comment: e.target.value
                                                    }
                                                })}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </div>
                                </>
                                : null)
                        }
                        <div>
                            {currentArticle.additional_datas && currentArticle.additional_datas.custom
                                ? currentArticle.additional_datas.custom.map((additionalData, index) => {
                                    return (
                                        <div key={index}>
                                            <AdditionalData
                                                index={index}
                                                additionalData={additionalData}
                                                setter={updateCustomAdditionalData}
                                                deleteFunc={deleteCutomAdditionalData}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    )
                                })
                                : null
                            }
                            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                                ? <button onClick={() => addCustomAdditionalsDatas()} type="button" className="light-button m-t-10 m-b-10">Ajouter info additionnelle</button>
                                : null}
                        </div>
                        <h4 className='m-b-0'>Paramètres</h4>
                        <div className='flex input relative'>
                            <InputSmall
                                label='Stock de sécurité'
                                type='number'
                                value={currentArticle.stock_min ?? ""}
                                setter={updateCurrentArticleProperty}
                                customParameters={["stock_min"]}
                                style={{ width: "100%" }}
                                border={column ? false : true}
                                disabled={isDisabled}
                            />
                            <div style={{ marginTop: "10px", marginLeft: "10px", position: 'absolute', right: '30px' }} className='strong-2'>{getStockUnitOfMeasure(currentArticle)}</div>
                        </div>
                        <label className='flex pointer'>
                            <div>Produit important</div>
                            <input type='checkbox'
                                checked={currentArticle.important === 1}
                                onChange={e => updateCurrentArticleProperty("important", e.target.checked ? 1 : 0)}
                                disabled={isDisabled}
                            />
                        </label>
                        {currenCategory && currenCategory.name === "Matière première" &&
                            <label className='flex m-t-10 pointer'>
                                <div>À analyser</div>
                                <input type='checkbox'
                                    checked={currentArticle.to_be_analyzed}
                                    onChange={e => updateCurrentArticleProperty("to_be_analyzed", e.target.checked ? 1 : 0)}
                                    disabled={isDisabled}
                                />
                            </label>
                        }
                    </div>
                    <label className='flex pointer m-t-10'>
                        <div style={{ color: 'red' }}>Archiver</div>
                        <input
                            type='checkbox'
                            checked={currentArticle.archive === 1 || currentArticle.archive}
                            onChange={e => updateCurrentArticleProperty("archive", e.target.checked ? 1 : 0)}
                            disabled={isDisabled}
                        />
                    </label>
                </div>
            </div>
            {
                currentArticle.additional_datas
                    ? <div>
                        <InputSmall
                            baliseType="textarea"
                            label="Description longue"
                            value={currentArticle.additional_datas.long_description}
                            setter={updateAdditionalDataProperty}
                            customParameters={["long_description"]}
                            border={column ? false : 'none'}
                            style={{ width: "100%" }}
                            disabled={isDisabled}
                        />
                    </div>
                    : null
            }
        </div >
    )
}
