import React, { useState, useEffect, useContext } from 'react'
import Input from 'Components/FormElement/Input';
import { useCallApi } from 'Functions';
import { appConstante } from 'appConstante';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import { PopupContext } from 'Context/PopupContext';
import { NotificationContext } from 'Context/NotificationContext'

export default function UpdateOrder({ order, updatedOrder }) {

    const [updateOrder, setUpdateOrder] = useState(order)
    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()

    const updateOrderKey = (key, value) => {
        setUpdateOrder({ ...updateOrder, [key]: value })
    }
    const sendOrder = (e) => {
        e.preventDefault();
        addNotificationContent({
            title: 'Modification en cours',
            content: <MiniLoader />,
        })

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-history/${updateOrder.id}`, { method: 'put' }, null, updateOrder)
            .then(res => {
                updatedOrder(res.data.data)
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "Commande modifiée",
                    infinit: false
                })
            }).catch((err) => {
                console.log(err);
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })

            });

        return () => {
            controller.abort()
        }

    }
    return (
        <>
            <h2>Modifier la commande de {updateOrder.name}</h2>
            <form onSubmit={e => sendOrder(e)}>
                <div className='flex'>
                    <div className='half-m-width'>
                        <Input type='number' min='0' step='0.0001' value={updateOrder.quantity}
                            onInput={updateOrderKey} customParameters={['quantity']}
                            label={`Quantité`} required={true}
                        />
                        <Input type='number' min='0' step='0.0001' value={updateOrder.price}
                            onInput={updateOrderKey} label='Prix (€)' customParameters={["price"]} required={false}
                        />
                    </div>
                    <div className='half-m-width'>

                        <Input type='date' value={updateOrder.date}
                            onInput={updateOrderKey}
                            label="Date de la commande" customParameters={['date']}
                            required={true}
                        />
                        <Input type='date' value={updateOrder.delivery_date}
                            onInput={updateOrderKey}
                            label="Date de livraison" customParameters={['delivery_date']}
                            required={true}
                        />
                        <Input label='Commentaire' value={updateOrder.comment} onInput={updateOrderKey} customParameters={['comment']} />
                    </div>
                </div>
                <button className='strong-button m-b-15'>Modifier</button>
            </form>
        </>
    )
}
