import React, { useState, useContext } from 'react'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import ProductFields from './Shared/ProductFields'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import { useCallApi, formatFormDataFromObject } from 'Functions'

/**
 * 
 * @param {article} article 'article parent au produit à ajouter'
 * @param {function} setter 'fonction permettant d'ajouter le produit instancier à l'article'
 * @returns component pour l'ajout d'un article
 */
export default function Addproduct({ article, setter }) {

    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)

    const [product, setProduct] = useState({})
    const updateProduct = (key, value) => {
        setProduct({ ...product, [key]: value })
    }

    const sendNewProduct = e => {
        e.preventDefault();
        addNotificationContent({
            error: false,
            title: "Ajout du fournisseur...",
            content: <MiniLoader white={true} />,
            infinit: true
        })
        const formatedProduct = formatFormDataFromObject({ ...product, article_id: article.id });

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product`, { method: 'post' }, null, formatedProduct, e)
            .then(res => {
                setter && setter(res.data.data)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: `Le fournisseur ${res.data.data.supplier} a été créé`,
                    infinit: false
                })
            })
            .catch(error => {
                console.log({ error });
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: error.data ? error.data.message : "une erreur a été rencontré",
                    infinit: false
                })
            })
    }

    return (
        <>
            <h2>Ajouter un fournisseur à l'article {article.name}</h2>
            <form onSubmit={e => sendNewProduct(e)}>
                <ProductFields product={product} setter={updateProduct} />
                <button className='strong-button m-b-25 m-t-25' >Ajouter le fournisseur</button>
            </form>
        </>
    )
}
