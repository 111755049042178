import React, { createContext, useState, useEffect, useContext } from "react"
import { Parameters } from "Components/_ERP/ProductSpecification/ProductSpectificationDetails"
import { useCallApi, useCallMSLApi } from "Functions"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { appConstante } from "appConstante"

interface Props {
    children: React.JSX.Element
}

interface PriceMatrix {
    parameters: Parameters
    rawMaterials: [any]
}

export const PriceMatrixContext = createContext()

function PriceMatrixContextProvider(props: Props) {
    const [priceMatrices, setPriceMatrices] = useState<null | [PriceMatrix]>(null)
    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)

    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/commercial/price-matrices`)
            .then(res => {
                setPriceMatrices(res.data.data ? res.data.data : [])
            })
            .catch(error => setPriceMatrices([]))
        return () => {
            controller.abort()
        }
    }, [])

    const formatedRawMaterial = (rawMat) => {


        const rawMaterialToSend = {
            quantity: parseFloat(rawMat.quantity),
            quantity_type: rawMat.quantity_type,
            showDtails: rawMat.showDtails,
            unit_of_measure: rawMat.unit_of_measure,
            cbd_concentration: parseFloat(rawMat.cbd_concentration),
            type: rawMat.type,
            density: parseFloat(rawMat.density),
            price: parseFloat(rawMat.price),
        }
        !rawMat.id && (rawMaterialToSend.name = rawMat.name)
        rawMat.id && ((rawMaterialToSend.id = rawMat.id))

        return rawMaterialToSend
    }

    const formatPriceMatrixData = (matrix) => {
        const formatedPriceMatrixData =
        {
            ...matrix,
            raw_materials: matrix?.raw_materials?.map(rawMat => {
                return formatedRawMaterial(rawMat)
            })
        }
        return formatedPriceMatrixData
    }

    const getPriceMatrix = (id) => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/commercial/price-matrix/${id}`, { method: "get" })
                .then(res => {
                    resolve(res.data.data ?? null)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    const postPriceMatrix = (PriceMatrix: PriceMatrix, e) => {
        e.preventDefault()

        return new Promise((resolve, reject) => {
            addNotificationContent({
                error: false,
                title: "Création de la matrice de prix...",
                content: <MiniLoader />,
                infinit: true
            })
            const priceMatricToSend = { ...PriceMatrix }
            delete priceMatricToSend.id

            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/commercial/price-matrix`, { method: "post" }, null, formatPriceMatrixData(priceMatricToSend))
                .then(res => {
                    resolve(res.data.data)
                    setPriceMatrices(prev => [res.data.data, ...prev])
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: `Matrice de prix ${priceMatricToSend.name} créée`,
                        infinit: false
                    })
                })
                .catch(error => {
                    reject(error)
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: `Il y a eu une erreur lors de la création de la matrice (${error.response?.data?.message})`,
                        infinit: false
                    })
                })
        })
    }
    const putPriceMatrix = (PriceMatrix, e) => {
        e.preventDefault()
        return new Promise((resolve, reject) => {
            addNotificationContent({
                error: false,
                title: "Création de la matrice de prix...",
                content: <MiniLoader />,
                infinit: true
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/commercial/price-matrix/${PriceMatrix.id}`, { method: "put" }, null, formatPriceMatrixData(PriceMatrix))
                .then(res => {
                    resolve(res.data.data)
                    setPriceMatrices(prev => {
                        return prev?.map(matrix => matrix.id === res.data.data.id ? res.data.data : matrix)
                    })
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: `Matrice de prix ${PriceMatrix.name} a été modifiée`,
                        infinit: false
                    })
                })
                .catch(error => {
                    reject(error)
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: `Il y a eu une erreur lors de la modification de la matrice (${error.response?.data?.message})`,
                        infinit: false
                    })
                })
        })
    }

    return (
        <PriceMatrixContext.Provider value={{ priceMatrices, getPriceMatrix, postPriceMatrix, putPriceMatrix }}>
            {props.children}
        </PriceMatrixContext.Provider>
    )
}

export default PriceMatrixContextProvider
