import React, { useState, useContext } from "react";
import BrandFields from "./Shared/BrandFields";
import { PopupContext } from "Context/PopupContext";
import { NotificationContext } from "Context/NotificationContext";
import MiniLoader from "Components/MiniLoader/MiniLoader";
import { useCallApi, formatFormDataFromObject } from "Functions";
import { appConstante } from "appConstante";

export default function UpdateBrands({ brand, updatedBrand }) {
    const callApi = useCallApi();
    const { addNotificationContent } = useContext(NotificationContext);
    const { showPopup } = useContext(PopupContext);

    const [newBrand, setNewBrand] = useState(brand);

    const updateNewBrand = (propriety, value) => {
        setNewBrand({ ...newBrand, [propriety]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        showPopup(false);

        addNotificationContent({
            title: "Modification en cours...",
            content: <MiniLoader />,
            infinit: true,
        });

        const formatedBrand = formatFormDataFromObject(newBrand)

        const uri = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/brand/${newBrand.id}`;
        const options = {
            method: "put",
        };

        callApi(uri, options, null, formatedBrand)
            .then((response) => {
                updatedBrand(response.data.data);
                addNotificationContent({
                    title: "Succès",
                    error: false,
                    content: "La marque a été modifiée...",
                    infinit: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <h2>Mettre à jour la marque {brand.name}</h2>
            <form onSubmit={handleSubmit}>
                <BrandFields updateBrand={updateNewBrand} brand={newBrand} />
                <button className="strong-button">Mettre à jour</button>
            </form>
        </>
    );
}
