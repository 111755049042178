import React, { useContext, useEffect, useState } from 'react'
import { CatalogueContext } from 'Context/Commercial/CatalogueContext'
import { PopupContext } from 'Context/PopupContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import LastNavBar from 'Components/NavBar/LastNavBar/LastNavBar'
import { removeDuplicates } from 'Functions'
import MidLoader from 'Components/MidLoader/MidLoader'
import ProductThumbnail from 'Components/_COMMERCIAL/Product/ProductThumbnail'
import AddProduct from 'Components/_COMMERCIAL/Product/AddProduct'

export default function Catalogue() {

    const { catalogueProducts, setCatalogueProducts, draft, setDraft, publicProduct, setPublicProduct } = useContext(CatalogueContext)
    const { addPopupContent } = useContext(PopupContext)

    const [avaibleCategories, setAvaibleCategories] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null)

    const addProduct = () => {
        addPopupContent(
            <AddProduct catalogue={true} />,
            '1200px'
        )
    }

    const deleteProduct = (id) => {
        setCatalogueProducts(catalogueProduct => {
            return catalogueProduct.filter(product => product.id !== id)
        })
    }

    const updateProduct = (productDatas) => {
        setCatalogueProducts(products => {
            return products.map(p => p.id === productDatas.id ? productDatas : p)
        })
    }

    useEffect(() => {
        if (catalogueProducts) {
            setAvaibleCategories(
                removeDuplicates(
                    catalogueProducts
                        .map(product => product.category)
                        .sort()
                )
            )
        }
    }, [catalogueProducts])
    
    return (
        <>
            <div className='flex flex-center m-b-25'>
                <button onClick={() => addProduct()} className='strong-button m-l-0'>Ajouter un produit</button>

                <div className='flex column'>
                    <div className='input'>
                        <label className='flex flex-center'>
                            <input style={{ marginLeft: "10px" }} type="checkbox" className='erp-check' checked={draft ? true : false} onChange={(e) => setDraft(e.target.checked)} />
                            Afficher les produits en brouillon
                        </label>
                    </div>
                    <div className='input'>
                        <label className='flex flex-center'>
                            <input style={{ marginLeft: "10px" }} type="checkbox" className='erp-check' checked={publicProduct ? true : false} onChange={(e) => setPublicProduct(e.target.checked ? true : null)} />
                            Afficher les produits du catalogue public
                        </label>
                    </div>
                </div>
            </div>

            {!catalogueProducts
                ? <MidLoader />
                : <>
                    {avaibleCategories
                        ? avaibleCategories.length > 0
                            ? <LastNavBar
                                items={
                                    [
                                        { label: 'Tous', active: true, onClick: setCurrentCategory, value: null },
                                        ...avaibleCategories.map((category, index) => ({
                                            label: category, active: false, onClick: setCurrentCategory, value: category
                                        }))
                                    ]
                                }
                            />
                            : <h5>Aucun produit dans le catalogue</h5>
                        : <MiniLoader />
                    }
                    <div className='flex flex center flex-start wrap gap-2'>
                        {catalogueProducts
                            .filter(product => currentCategory ? product.category === currentCategory : true)
                            .filter(product => draft ? product.state === 0 : 1)
                            .map(product =>
                                < React.Fragment key={product.id} >
                                    <ProductThumbnail product={product} deleteProduct={deleteProduct} updateProduct={updateProduct} />
                                </React.Fragment>
                            )}
                    </div>
                </>
            }
        </>
    )
}
