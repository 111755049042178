import React, { useState, useEffect, useContext } from "react"
import { appConstante } from "appConstante"
import DealDetails from "Components/_ERP/DealDetails/DealDetails"
import { PopupContext } from "Context/PopupContext.js"
import CreateInvoice from "AppContainer/Invoices/CreateInvoice/CreateInvoice"
import MidLoader from "Components/MidLoader/MidLoader"
import { useCallPipedriveApi } from "Functions"
import { useCurrentAppPermission } from 'Functions';

export default function ConvertDealToInvoice() {
  const currentAppPermission = useCurrentAppPermission()
  const callPipedriveApi = useCallPipedriveApi()

  /* CONTEXT */
  const { addPopupContent } = useContext(PopupContext)

  /* GESTION DE LA PAGINATION */
  const [currentPage, setCurrentPage] = useState(0)
  const changePage = (direction, value = false) => {
    if (value) {
      setCurrentPage(parseInt(value - 1))
    } else if (direction === -1 && currentPage > 0) {
      setCurrentPage(parseInt(currentPage + direction))
    } else if (direction === 1) {
      setCurrentPage(parseInt(currentPage + direction))
    }
  }

  /* RECUPERATION DES DEVIS SANS FACTURES SUR PIPEDRIVE */
  const [deals, setDeals] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    if (selectedDeal) {
      callPipedriveApi(`deals/${selectedDeal}`, {
        method: "get",
        signal: controller.signal,
      }).then((res) => {
        setDeals(res.data.data ? [res.data.data] : [])
      })
    } else {
      const offset = 20
      callPipedriveApi(
        "deals",
        { method: "get", signal: controller.signal },
        {
          start: currentPage * offset,
          limit: offset,
          sort: "won_time",
          filter_id: 169,
        }
      ).then((res) => {
        setDeals(res.data.data ? res.data.data : [])
      })
    }

    return () => {
      controller.abort()
    }
  }, [currentPage, selectedDeal, refresh])

  const startRefresh = () => {
    setRefresh(!refresh)
  }
  /* AFFICHAGE DU DETAILS DES DEVIS */
  const showDetails = (id, maxWidth) => {
    addPopupContent(<DealDetails dealId={id} />, maxWidth)
  }

  const createInvoice = (id) => {
    addPopupContent(
      <CreateInvoice dealId={id} refresh={startRefresh} />,
      "1250px"
    )
  }

  return (
    <>
      <div
        className="flex"
        style={{ justifyContent: "start", marginBottom: "25px" }}
      >
        <input
          style={{ maxWidth: "250px", height: "40px" }}
          placeholder="N° de devis"
          onChange={(e) => setSelectedDeal(e.target.value)}
        />
      </div>

      {deals ? (
        <>
          {deals.length > 0 ? (
            <>
              <div
                className="flex"
                style={{ justifyContent: "start", marginBottom: "5px" }}
              >
                <button
                  style={{ width: "50px" }}
                  onClick={() => changePage(-1)}
                >
                  &#10094;{" "}
                </button>
                <input
                  onInput={(e) => changePage(0, e.target.value)}
                  style={{ maxWidth: "50px", textAlign: "center" }}
                  min="1"
                  max=""
                  id="page-number"
                  type="text"
                  value={currentPage + 1}
                />
                <button style={{ width: "50px" }} onClick={() => changePage(1)}>
                  {" "}
                  &#10095;{" "}
                </button>
              </div>
              <table className="beautiful-array">
                <thead>
                  <tr style={{ height: "40px" }}>
                    <th />
                    <th>ID</th>
                    <th>Titre</th>
                    <th>Montant du devis</th>
                    <th>Date d'acceptation</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {deals.map((deal) => (
                    <tr key={deal.id}>
                      <td>
                        <button onClick={() => showDetails(deal.id, "90%")}>
                          Détails
                        </button>
                      </td>
                      <td>{deal.id}</td>
                      <td>{deal.title}</td>
                      <td>{deal.value} €</td>
                      <td>
                        {deal.won_time
                          ? new Date(deal.won_time).toLocaleDateString("fr-FR")
                          : "Non défini"}
                      </td>
                      <td>
                        {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                          <button
                            className="light-button"
                            onClick={() => createInvoice(deal.id)}
                          >
                            Facturer
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <h5>Vous n'avez aucun devis à facturer</h5>
          )}
        </>
      ) : (
        <MidLoader />
      )}
    </>
  )
}
