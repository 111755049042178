import { useCallAnalysesApi } from "Functions"
import { createContext } from "react"

export const SamplesContext = createContext()

function SamplesContextProvider(props) {

    const callAnalysesApi = useCallAnalysesApi()

    const getSample = (numRef) => {
        return new Promise((resolve, reject) => {
            callAnalysesApi(`products/${numRef}`, { method: "get" })
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    return (
        <SamplesContext.Provider value={{ getSample }}>
            {props.children}
        </SamplesContext.Provider>
    )
}

export default SamplesContextProvider
