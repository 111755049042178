import React from 'react'
import Subscription from './Subscription';
import NoSubscription from './NoSubscription';

export default function SoldeAnalyses({ userInfos }) {

    return (
        <>
            {userInfos && Object.entries(userInfos.credits_analyses).length
                ? <div>
                    <h3>Solde analyses</h3>
                    {userInfos.user_info.subscription == 1
                        ? <Subscription userInfos={userInfos} />
                        : <NoSubscription userInfos={userInfos} />
                    }
                </div>
                : <h5>Aucun solde d'analyses pour ce client</h5>
            }
        </>
    )
}
