import React, { useState, useEffect } from 'react'
import './lastNavBar.css'

/**
 * 
 * @param {Array} items Exemple:  [{ label: 'Label', active: true, onClick: setCurrentCategory, value: null }, ...]
 * active: default active item
 * onClick: callBack when item clicked
 * value: value to send in the callBack when clicked
 * @returns 
 */

export default function LastNavBar({ items }) {

    const [usedItems, setUsedItems] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        setUsedItems(
            items.map((itm, index) => activeIndex === index
                ? { ...itm, active: true }
                : { ...itm, active: false }
            )
        )
    }, [items, activeIndex])

    const navigate = (item, index) => {
        item.onClick(item.value)
        setActiveIndex(index)

    }

    return (
        <div className="archive-nav-section">
            {usedItems.map((item, index) =>
                <div
                    key={index}
                    onClick={_ => navigate(item, index)}
                    className={item.active ? "active archive-navigation-button" : "archive-navigation-button"}>
                    {item.label}
                </div>
            )}
        </div>
    )
}