import React, { useState, useEffect, useContext, useRef } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import SelectStep from "Components/_ERP/PlanDeal/SelectStep"
import EventDetail from "Components/_ERP/EventDetails/EventDetail"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import PlanProductForm from "Components/_ERP/PlanDeal/PlanProductForm"
import {
  findDuplicates,
  getColorFromNumber,
  useCallApi,
  useCurrentAppPermission,
} from "Functions"
import "./PlanningProd.css"
import { appConstante } from "appConstante"
import { PlanningContext } from "Context/ERP/PlanningContext"
import Select from "Components/FormElement/Select"
import { getDealColor } from "AppContainer/ERP/ERPConstantes"
import CustomSelect from "Components/GENERAL/Form/CustomSelect/CustomSelect"
import DealDetails from "../../../../Components/_ERP/DealDetails/DealDetails"

export default function PlanningEvents() {
  const calendarRef = useRef()
  const callApi = useCallApi()
  const currentAppPermission = useCurrentAppPermission()
  /* Context */
  const { addPopupContent } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)
  const {
    sessionsByCategories,
    stepsToPlan,
    updateSession,
    pipedriveProducts,
    dealsToPlan,
    setDateRange,
    loadingSessions,
    refreshSteps,
    refreshPlanningSessions,
    refreshWarningSessions
  } = useContext(PlanningContext)

  /* Constantes */
  const [selectedDealId, setDelectedDealId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const stepProductIdUniq = []

  // Sessions
  const [dealsFromSessions, setDealsFromEventsSessions] = useState(null)
  const [dealsFromCommingSessions, setDealsFromCommingSessions] = useState(null)
  const [filteredSessions, setFilteredSessions] = useState(null)
  const [commingSessionDealFilter, setCommingSessionDealFilter] = useState("")

  // Steps
  const [stepFromDealId, setStepFromDealId] = useState([])
  const [selectStepProductId, setSelectStepProductId] = useState(null)

  useEffect(() => {
    if (sessionsByCategories.planningSessions) {
      removeDuplicatesEvents()
    }
  }, [sessionsByCategories])


  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshPlanningSessions();
    }, 10 * 60 * 1000); // 10 minutes en millisecondes

    return () => clearInterval(intervalId); // Nettoie l'intervalle lorsque le composant est démonté
  }, [refreshPlanningSessions]);

  const refreshSessionFromButton = async () => {
    // addNotificationContent({
    //   error: false,
    //   title: "Rafraichissement pris en compte",
    //   content: "patienter quelques instants pendant le chargement des sessions de production",
    //   infinit: false,
    // })
    refreshPlanningSessions()
  }




  // const refreshWarningSessionsFromButton = async () => {
  //   addNotificationContent({
  //     error: false,
  //     title: "Rafraichissement pris en compte",
  //     content: "patienter quelques instants pendant le chargement des sessions avec anomalie",
  //     infinit: false,
  //   })
  //   refreshWarningSessions()
  // }

  const refreshStepsFromButton = async () => {
    addNotificationContent({
      error: false,
      title: "Rafraichissement pris en compte",
      content: "patienter quelques instants pendant le chargement des étapes de production",
      infinit: false,
    })
    refreshSteps()
  }

  const handleDateSelect = (selectInfo) => {
    if (currentAppPermission("erp") > 1) {
      if (pipedriveProducts) {
        addPopupContent(
          <SelectStep
            selectInfo={selectInfo}
          />,
          "1000px"
        )
      } else {
        addNotificationContent({
          title: "chargement en cours",
          content:
            "Un instant, nous sommes toujours en train de charger quelques informations",
          error: true,
        })
      }
    }
  }

  const handleEventClick = (clickInfo) => {
    const event = {
      ...clickInfo.event._def,
      ...clickInfo.event._def.extendedProps,
      start: clickInfo.event.start,
      end: clickInfo.event.end,
      id: parseInt(clickInfo.event._def.publicId),
    }
    showEventDetails(event)
  }

  const showEventDetails = (event) => {
    addPopupContent(
      <EventDetail
        event={event}
      />,
      "1200px"
    )
  }

  const handleDateChanges = (dateInfo) => {
    setDateRange({ start: dateInfo.start, end: dateInfo.end });
  }

  const handleUpdateEvent = (clickInfo) => {
    if (
      !currentAppPermission("erp") > 1 &&
      clickInfo.event._def.extendedProps.lock === 1
    ) {
      clickInfo.revert()
      addNotificationContent({
        error: true,
        title: "Opération impossible",
        content: "Vous n'avez pas l'autorisation de modifier cet événement",
        infinit: false,
      })
    } else {
      addNotificationContent({
        title: 'Mise à jour de la session...',
        content: <MiniLoader />,
        infinit: true,
      })
      setLoading(true)
      const start = new Date(clickInfo.event._instance.range.start).toISOString().slice(0, 19)
      const end = new Date(clickInfo.event._instance.range.end).toISOString().slice(0, 19)
      const newEvent = {
        start,
        end,
        id: parseInt(clickInfo.event._def.publicId),
      }

      calendarRef.current.render() // render pour le statut
      callApi(
        `${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${newEvent.id}`,
        { method: "put" },
        null,
        newEvent
      )
        .then(res => {
          addNotificationContent({
            content: 'Mise à jour réussi'
          })
          updateSession(res.data.data)
        })
        .catch((error) => {
          console.log(error);
          addNotificationContent({
            error: true,
            title: "Erreur",
            content: error.response.error,
            infinit: false,
          })
        })
        .finally(() => setLoading(false))
    }
  }


  const showPlanStep = (step, product, maxWidth) => {
    addPopupContent(
      <PlanProductForm
        dealId={step.id}
        passedProduct={product}
        selectInfo={{ step }}
      />,
      maxWidth
    )
  }

  const renderEventContent = (eventInfo) => {
    let color = "rgba(201, 201, 201)"
    const state = eventInfo.event._def.extendedProps.state
    if (state > 1) {
      color = "rgba(235, 155, 155)"
    } else if (state === 1) {
      color = "rgb(191, 200, 255)"
    }
    const fontWeight = eventInfo.event._def.extendedProps?.production_sheet?.id
      ? 400
      : 600

    const fontColor = getDealColor(eventInfo.event._def.extendedProps?.deal?.id)
    return (<>
      <div className='planning-event-container'
        style={{ height: "100%", backgroundColor: color, color: `${fontColor}`, fontWeight: fontWeight }}>
        <div>
          {/* {eventInfo.timeText}: */}
          <span style={{ fontSize: "15px" }}>{eventInfo.event.title}</span>
        </div>
        {eventInfo.event.title
          ? <div className="planning-event-tooltip">
            <div><span className="strong-2 underline">Session :</span><br />{eventInfo.event.title}</div>
            <div className="m-t-10"><span className="strong-2">Quantité prévue :</span> {eventInfo.event._def.extendedProps?.indicative_quantity}</div>
            <div className="m-t-10"><span className="strong-2 underline">Devis :</span> {eventInfo.event._def.extendedProps?.deal?.title}</div>
            <div className="m-t-10"><span className="strong-2 underline">Fiche de production :</span>
              {eventInfo.event._def.extendedProps?.production_sheet
                ? ` Fiche de production n°${eventInfo.event._def.extendedProps?.production_sheet?.id}`
                : " Aucune fiche de production"
              }
            </div>
            <div className="m-t-10"><span className="strong-2 underline">Informations produits :</span><br />
              {eventInfo.event._def.extendedProps?.product?.comments
                ? <span
                  dangerouslySetInnerHTML={{ __html: eventInfo.event._def.extendedProps?.product?.comments }} />
                : <span>Aucune précision sur ce proruits</span>
              }
            </div>
          </div>
          : null
        }
      </div >
    </>
    )
  }

  const removeDuplicatesEvents = () => {
    const calendarApi = calendarRef.current.getApi()
    const listId = calendarApi.getEvents().map((event) => event.id)
    const duplicates = findDuplicates(listId)
    listId.forEach((id, index) => {
      if (duplicates.includes(id)) {
        calendarApi.getEventById(id).remove()
        const index = duplicates.indexOf(id)
        if (index !== -1) {
          duplicates.splice(index, 1)
        }
      }
    })
  }

  useEffect(() => {
    if (sessionsByCategories.planningSessions) {
      setDealsFromEventsSessions(uniqDealsFromSessionArray(sessionsByCategories.planningSessions))
    }
  }, [sessionsByCategories.planningSessions])

  useEffect(() => {
    if (sessionsByCategories.commingSessions) {
      setDealsFromCommingSessions(uniqDealsFromSessionArray(sessionsByCategories.commingSessions))
    }
  }, [sessionsByCategories.commingSessions])

  const uniqDealsFromSessionArray = (sessionArr) => {
    const uniqDealId = []
    return sessionsByCategories.planningSessions
      .map((event) => {
        if (!uniqDealId.includes(event.deal.id)) {
          uniqDealId.push(event.deal.id)
          return event.deal
        }
      })
      .filter((event) => event)
  }

  /** ***** FILTERED EVENTS ****** */
  useEffect(() => {
    if (sessionsByCategories) {
      if (selectedDealId) {
        setFilteredSessions(
          sessionsByCategories.planningSessions.filter((event) => event.deal.id === selectedDealId)
        )
      } else {
        setFilteredSessions(sessionsByCategories.planningSessions)
      }
    }
  }, [selectedDealId, sessionsByCategories])

  // console.log(currentAppPermission("erp") > appConstante.parameters.CAN_EDIT);

  return !error ? (
    <>
      <div className="calendar-section-main">
        <div className="parameters-section flex">
          <div className="flex-center start m-b-25">
            <CustomSelect
              style={{ width: '300px' }}
              value={selectedDealId}
              onSelect={setDelectedDealId}
              defaultLabel='-- filtrer par devis --'
              optionsList={
                dealsFromSessions &&
                dealsFromSessions.sort((a, b) => a.id - b.id).map((deal) => (
                  {
                    value: deal.id,
                    content: <div
                      style={{ color: getDealColor(deal.id) }}
                      key={deal.id}
                    >{`[${deal.id}] ${deal.title}`}</div>
                  }
                ))}
            />
          </div>
          <button
            className="light-button"
            disabled={loading}
            onClick={() => refreshSessionFromButton()}
          >
            Rafraichir le planning
          </button>
        </div>
        <div
          id="planning-container"
          style={!currentAppPermission("erp") > 1 ? { minWidth: "100%" } : {}}
          className={loadingSessions ? "blur" : ""}
        >
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="timeGridWeek"
            editable={!loading && currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT}
            selectMirror
            dayMaxEvents
            locale="fr"
            weekends={false}
            events={filteredSessions}
            select={handleDateSelect}
            selectable={currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventDrop={handleUpdateEvent}
            eventResize={handleUpdateEvent}
            datesSet={handleDateChanges}
            slotMinTime="08:00:00"
            slotMaxTime="19:00:00"
            ref={calendarRef}
          />
        </div>
        {currentAppPermission("erp") > 1 && (
          <div className="calendar-section-sidebar">
            <div className="flex">
              <div className="flex-end full-width column">
                <div>
                  <h3 className="center">
                    Étapes créées non planifiées ({stepsToPlan && stepsToPlan.length})
                  </h3>
                  <div className="select-step-deal_id">
                    {Array.isArray(dealsToPlan)
                      ? <>
                        {dealsToPlan?.map((deal) => (
                          <div
                            className="m-b-10 pointer strong-2"
                            onClick={() => setStepFromDealId(deal.id)}
                            key={deal.id}
                            style={{
                              color: getDealColor(deal.id),
                              backgroundColor: "white",
                              width: '40%',
                              textAlign: 'center',
                              padding: '5px',
                              borderRadius: '8px',
                              alignSelf: 'stretch',
                            }}
                          >
                            {deal.id}
                            <br />
                            <span className="subtext">({deal?.org_id?.name} - {deal?.title})</span>
                            <br />
                            <button className='light-button m-t-10' onClick={_ => addPopupContent(<DealDetails dealId={deal.id} />)}>Détails</button>
                          </div>
                        ))}

                        {stepsToPlan
                          ? <div className="full-width m-t-25">
                            {stepFromDealId
                              ? pipedriveProducts && pipedriveProducts.map((product) => {
                                const productSteps = stepsToPlan.filter((step) => product.id === step.product_id)
                                if (productSteps && productSteps.length) {
                                  if (
                                    product.deal_id === stepFromDealId &&
                                    !stepProductIdUniq.includes(product.id)
                                  ) {
                                    stepProductIdUniq.push(product.id)
                                    return (
                                      <div className="select-product-step-id">

                                        <div
                                          key={product.id}
                                          className="select-product-step pointer"
                                          onClick={() => setSelectStepProductId(product.id)}
                                        >
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              textAlign: "left",
                                            }}
                                          >
                                            {product.name} ({productSteps.length})
                                          </div>
                                          {product.comments
                                            ? <div
                                              style={{
                                                fontSize: "14px",
                                                color: "rgb(80,80,80)",
                                                textAlign: "left",
                                              }}
                                              dangerouslySetInnerHTML={{ __html: product.comments }} />
                                            : null}
                                          {selectStepProductId === product.id && (
                                            <div
                                              style={{
                                                border: "1px solid rgba(0,0,0,0.6)",
                                                borderRadius: "5px",
                                                overflow: "hidden",
                                              }}
                                              className="m-b-10"
                                            >
                                              <table className="beautiful-array">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      className="left"
                                                      style={{ backgroundColor: "white" }}
                                                    >
                                                      Étapes
                                                    </th>
                                                    <th
                                                      className="center"
                                                      style={{ backgroundColor: "white" }}
                                                    >
                                                      Quantité restante à planifier
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {stepsToPlan
                                                    .filter(
                                                      (step) =>
                                                        step.deal_id === stepFromDealId &&
                                                        selectStepProductId ===
                                                        step.product_id
                                                    )
                                                    .map((step) => {
                                                      const product =
                                                        pipedriveProducts.filter(
                                                          (product) =>
                                                            product.id === step.product_id
                                                        )
                                                      return (
                                                        <tr
                                                          key={step.id}
                                                          className="pointer"
                                                          onClick={() => showPlanStep(step, product[0], "90%")}
                                                        >
                                                          <td className="left">
                                                            <div
                                                              style={{ fontWeight: "600" }}
                                                            >
                                                              [{step.deal_id}] {step.name}
                                                            </div>
                                                            <i>
                                                              {product[0]
                                                                ? product[0].name
                                                                : "..."}
                                                            </i>
                                                          </td>
                                                          <td style={{ fontWeight: "500" }}>
                                                            {step.todo_quantity -
                                                              step.plan_quantity}
                                                          </td>
                                                        </tr>
                                                      )
                                                    })}
                                                </tbody>
                                              </table>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  }
                                }
                              })
                              : null}
                          </div>
                          : <MiniLoader />}
                      </>
                      : <div style={{ paddingTop: "30px", width: "100%" }}>
                        <MiniLoader />
                      </div>
                    }
                  </div>
                </div>
                <button className="light-button center-block m-t-10" onClick={() => refreshStepsFromButton()}>Rafraichir les étapes</button>
              </div>
              {/* <div className="flex-end half-m-width column">
                <div>
                  <h3 className="center">Session de productions avec anomalies</h3>
                  <table
                    style={{ boxShadow: "0 0 0px 1px rgba(0,0,0, 0.6)" }}
                    className="beautiful-array"
                  >
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: "white" }} className="left">
                          Nom
                        </th>
                        <th style={{ backgroundColor: "white" }}>
                          Quantité prévue
                        </th>
                        <th style={{ backgroundColor: "white" }}>
                          Quantité produite
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sessionsByCategories.warningSessions ? (
                        sessionsByCategories.warningSessions.map((event, index) => (
                          <tr
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => showEventDetails(event)}
                          >
                            <td
                              className="left"
                              style={{ color: getDealColor(event.deal.id) }}
                            >{event.title}</td>
                            <td>{event.indicative_quantity}</td>
                            <td>
                              {event.real_quantity
                                ? event.real_quantity
                                : "Aucune production"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ paddingTop: "30px" }} colSpan={3}>
                            <MiniLoader />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <button className="light-button center-block m-t-25" onClick={() => refreshWarningSessionsFromButton()}>Rafraichir les sessions avec anomalies</button>
              </div> */}
            </div>
            {/* {dealsFromCommingSessions && sessionsByCategories.commingSessions ?
              <div className="m-t-25">
                <h3 className="center">
                  Évènement planifié à venir (
                  {sessionsByCategories.commingSessions && sessionsByCategories.commingSessions.length})
                </h3>
                <Select
                  label="Numéro de devis"
                  value={commingSessionDealFilter}
                  onChange={setCommingSessionDealFilter}
                >
                  <option value="">-- Filter par devis --</option>
                  {dealsFromCommingSessions.map(deal => <option key={deal.id} value={deal.id}>{`[${deal.id}] ${deal.title}`}</option>)}
                </Select>
                <table className="comming-sessions beautiful-array">
                  <thead>
                    <tr>
                      <th className="left">Nom</th>
                      <th>Quantité prévu</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessionsByCategories.commingSessions
                      .filter(event => commingSessionDealFilter ? event.deal.id == commingSessionDealFilter : true)
                      .map((event, index) => {
                        if (new Date() <= event.end) {
                          const dateStart = event.start.toLocaleDateString("fr")
                          const dateEnd = event.end.toLocaleDateString("fr")
                          const timeStart =
                            event.start.getMinutes() === 0
                              ? `${event.start.getHours()}h00`
                              : `${event.start.getHours()}h${event.start.getMinutes()}`
                          const timeEnd =
                            event.end.getMinutes() === 0
                              ? `${event.end.getHours()}h00`
                              : `${event.end.getHours()}h${event.end.getMinutes()}`
                          return (
                            <tr className="pointer" key={index} onClick={() => showEventDetails(event)}>
                              <td
                                className="left"
                                style={{ color: getDealColor(event.deal.id) }}
                              >{event.title}</td>
                              <td>{event.indicative_quantity}</td>
                              <td>
                                {`${dateStart}`}<br /> {`${timeStart}`} -{" "}
                                {dateEnd !== dateStart
                                  ? `${dateEnd} ${timeEnd}`
                                  : timeEnd}
                              </td>
                            </tr>
                          )
                        }
                      })}
                  </tbody>
                </table>
              </div>
              : null
            } */}
          </div>
        )}
      </div >
    </>
  ) : (
    <h5>{error}</h5>
  )
}
