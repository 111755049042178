import React, { } from 'react'
import ConsultFiles from 'Components/GENERAL/Files/ConsultFiles'

export default function BatchFields({ batch, setter }) {

    return (
        <div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>Produit</label>
                </div>
                <input
                    style={{ width: '600px' }}
                    value={batch.article.name ?? ''}
                    disabled={true} />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>Date de conditionnement</label>
                </div>
                <input
                    type="date"
                    value={batch.packaging_date ?? ''}
                    onInput={e => setter('packaging_date', e.target.value)}
                />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>N° de lot</label>
                </div>
                <input
                    type="text"
                    value={batch.visible_id ?? ''}
                    onInput={e => setter('visible_id', e.target.value)}
                />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>N° de bulk</label>
                </div>
                <input
                    type="number"
                    value={batch.bulk_id ?? ''}
                    onInput={e => setter('bulk_id', e.target.value)}
                />
            </div>
            <div className='flex-center m-b-10'>
                <div>
                    <label>Quantité cible</label>
                </div>
                <input
                    type="number"
                    value={batch.produced_quantity ?? ''}
                    onInput={e => setter('produced_quantity', e.target.value)}
                    step={50}
                />
            </div>
            <ConsultFiles
                target='batch'
                targetId={batch.ID}
                className='light-button'
            />
            <label className='flex-end center gap-1 m-t-25'>
                <span>À référencer sur le site</span>
                <input
                    type='checkbox'
                    className='erp-check'
                    checked={batch.cbdp ?? ''}
                    onChange={e => setter('cbdp', e.target.checked === true ? 1 : 0)}
                />
            </label>
        </div>
    )
}