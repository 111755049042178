export const emptyProduct = (catalogue) => ({
    reference_id: '',
    catalogue: catalogue ? 1 : 0,
    type: 1,
    state: 0,
    name: '',
    vat_rate: '',
    category: '',
    product_picture: '',
    carrousel: [],
    labels: [],
    short_description: '',
    tabs: {
        description: '',
        ingredients: '',
        personnalisations: '',
        utilisation: '',
    },
    price: {
        max_price: '',
        min_price: '',
        min_price_qty: '',
        min_qty: '',
        price_category_id: '',
    },
    price_description: '',
    purchase_link: '',
    options: [],
    use_ref: {
        option: 0,
        packaging: 0,
        description: 0,
        ingredients: 0,
        personnalisations: 0,
        utilisation: 0,
    },
    variation: {},
    packagings: [],
    organization_id: null,
    public: 0,
    commercial_name: '',
})