import React, { useEffect, useState } from 'react'
import { useCallAnalysesApi } from 'Functions'
import { useParams } from 'react-router-dom'
import LastNavBar from 'Components/NavBar/LastNavBar/LastNavBar'
import SoldeAnalyses from './SoldeAnalyses'
import Resultats from './Resultats'
import Commandes from './Commandes'
import Details from './Details'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function ConsultUserInfos() {

    let { id } = useParams()
    const callAnalysesApi = useCallAnalysesApi()
    const [userInfos, setUserInfos] = useState()
    const [currentItem, setCurrentItem] = useState()
    const [clientsAccountsItems, setClientsAccountsItems] = useState([])

    useEffect(() => {
        const controller = new AbortController()
        callAnalysesApi(`user/${id}`, { method: "get", signal: controller.signal })
            .then(res => {
                setUserInfos(res.data.data)
            })
        return () => {
            controller.abort()
        }
    }, [id])

    useEffect(() => {
        if (userInfos) {
            setClientsAccountsItems([
                { label: "Solde analyses", active: true, onClick: setCurrentItem, value: <SoldeAnalyses userInfos={userInfos} /> },
                { label: "Résultats", active: false, onClick: setCurrentItem, value: <Resultats userInfos={userInfos} /> },
                { label: "Commandes", active: false, onClick: setCurrentItem, value: <Commandes userInfos={userInfos} /> },
                { label: "Détails", active: false, onClick: setCurrentItem, value: <Details userInfos={userInfos} /> },
            ])
            setCurrentItem(<SoldeAnalyses userInfos={userInfos} />)
        }
    }, [userInfos])

    return (
        <>
            {userInfos
                ? <div className='m-b-25'>
                    <LastNavBar items={clientsAccountsItems} />
                    {currentItem}
                </div>
                : <MidLoader />
            }
        </>
    )
}
