import React, { createContext, useState, useEffect, useContext } from "react"
import { appConstante, getConversionUnitFactor } from "appConstante"
import { getUnitMeasureType, useCallApi } from "Functions"
import { ProductionSheetContext } from "./productionSheet/CreateProductionSheet"
import { useNavigate } from 'react-router-dom'
import { NotificationContext } from "Context/NotificationContext";
import MiniLoader from "Components/MiniLoader/MiniLoader";
import { getDensityForm } from "AppContainer/ERP/ERPConstantes"

export const RecipeContext = createContext()
export default function RecipeContextProvider(props) {
    const callApi = useCallApi()
    const navigate = useNavigate()
    const { addNotificationContent } = useContext(NotificationContext)
    const [recipeNames, setRecipes] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        callApi(
            `${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipes/names`,
            { method: "get" },
            controller.signal
        )
            .then(res => {
                setRecipes(res.data.data)
            })
            .catch(error => console.log(error))

        return () => {
            controller.abort()
        }
    }, [])

    const getRecipeById = (id) => {
        return new Promise((resolve, reject) => {
            const controller = new AbortController();
            callApi(
                `${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe/${id}`,
                { method: "get" },
                controller.signal
            )
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
            return () => {
                controller.abort()
            }
        })
    }

    // POST TO ADD A PRODUCTION SHEET
    const postRecipe = (e, recipe, setter) => {
        console.log(recipe);
        e.preventDefault()
        const formatedRecipe = formatRecipeToSend(recipe)
        if (formatedRecipe.success) {
            addNotificationContent({
                title: 'Ajout en cours',
                content: <MiniLoader />,
                infinit: true,
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe`, { method: 'post' }, null, formatedRecipe)
                .then(res => {
                    setter && setter(res.data.data)
                    addNotificationContent({
                        title: 'Succès',
                        content: "Recette créée",
                    })
                    setRecipes(recipe => [...recipe, res.data.data])
                    !setter && navigate('/erp/specif-and-recipe/recipes/my-recipes')
                }).catch((err) => {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: err.response?.data?.message ?? 'Une erreur est survenue',
                    })
                })
            return () => {
                controller.abort()
            }
        }
        else {
            addNotificationContent({
                error: true,
                title: 'Problème avec vos données',
                content: `${formatedRecipe?.error_message}`,
            })
        }
    }

    const formatRecipeToSend = (recipe) => {
        try {
            recipe.success = true;
            const formatedCannabinoids = recipe.cannabinoids?.length
                ? recipe.cannabinoids.map(ingr => ({
                    article_id: ingr.article_id,
                    quantity: ingr.quantity,
                    fixed_concentration: ingr.fixed_concentration,
                }))
                : []
            const formatedBases = recipe.bases?.length
                ? recipe.bases.map(ingr => ({ article_id: ingr.article_id, quantity: ingr.quantity }))
                : []
            const formatedOtherIngr = recipe.other_ingredients?.length
                ? recipe.other_ingredients.map(ingr => ({ article_id: ingr.article_id, quantity: ingr.quantity, quantity_type: ingr.quantity_type }))
                : []
            return { ...recipe, cannabinoids: formatedCannabinoids, bases: formatedBases, other_ingredients: formatedOtherIngr }
        } catch (err) {
            console.log({ err });
            return {
                success: false,
                error_message: 'Une erreur est survenue lors du formatage de la recette',
            }
        }
    }

    const addRecipe = (newRecipe) => {
        setRecipes(recipe => [...recipe, newRecipe])
    }

    /************************************** GESTION POUR LES FICHES DE PRODUCTIONS **************************************/

    const {
        productionSheet,
        formatIngredientArray,
    } = useContext(ProductionSheetContext)


    /** CANNABINOIDS **/
    /* Calcul de la moyenne pondéré d'un cannabinoid */
    const getCannabinoidConcentration = (cannabinoid) => {
        if (cannabinoid && cannabinoid.batchs) {
            let sommeConcentration = 0;
            let sommeQuantites = 0;
            cannabinoid.batchs.forEach(batch => {
                if (batch.real_quantity && batch.selected_cannabinoid) {
                    sommeConcentration += parseFloat(batch.selected_cannabinoid?.mean) * batch.real_quantity;
                    sommeQuantites += batch.real_quantity
                }
            })
            return isNaN((sommeConcentration) / (sommeQuantites))
                ? 0
                : (sommeConcentration) / (sommeQuantites);
        } else {
            return null
        }
    }

    const checkCannabinoids = () => {
        if (productionSheet?.recipe?.cannabinoids) {
            const okCanna = formatIngredientArray(productionSheet.recipe.cannabinoids)
            return okCanna ? true : false
        } else {
            return false
        }
    }


    /** compute quantities */

    const getRealQuantity = (ingredient) => {
        const margeToAdd = parseFloat(productionSheet.parameters.marge_to_add)
        let percentQuantityToAddIfInfusion = 1
        if (
            ingredient.infusion === 1
            || (productionSheet.recipe.other_ingredients.find(ingredient => ingredient.infusion) && ingredient.type === "bases")
        ) {
            percentQuantityToAddIfInfusion = 1 + (parseFloat(productionSheet.parameters?.marge_to_add_for_infusion ?? 0) / 100)
        }

        return (percentQtyToRealQty(ingredient) * (1 + margeToAdd / 100) * percentQuantityToAddIfInfusion).toFixed(2)
            * getConversionUnitFactor(productionSheet.article.unit_of_measure, 'g')
            * 1
    }

    // const getFiltrationMargeToAdd = (ingredient) => {
    //     const marge = 1 + (parseFloat(productionSheet.parameters.marge_to_add_for_infusion ?? 0) / 100)
    //     if (productionSheet.recipe.other_ingredients.find(ingr => ingr.infusion === 2)) {
    //         return marge
    //     } else if (productionSheet.recipe.other_ingredients.find(ingr => ingr.infusion === 1)) {
    //         return ingredient.type === "cannabinoids"
    //             ? 1
    //             : ingredient.infusion === 1 || ingredient.type === "bases"
    //                 ? marge
    //                 : 1
    //     }
    //     else {
    //         return 1
    //     }
    // }

    const percentQtyToRealQty = (ingredient) => {
        let ingredientQty = parseFloat(ingredient.quantity)
        if (ingredient.type === 'bases') {
            return getBaseQuantity(ingredient)
        }
        let density = parseFloat(ingredient.info?.density)

        if (!density) {
            return 0
        }

        let densityForm = () => { }
        let volume = parseFloat(productionSheet.article?.volume)
        const qtyToAdd = parseFloat(productionSheet.parameters.quantity_to_produce)

        if (ingredient.quantity_type === "masse/masse") {
            densityForm = getDensityForm(
                "masse",
                'masse'
            )

            const densityFormPF = getDensityForm(
                getUnitMeasureType(productionSheet.article?.unit_of_measure),
                'masse'
            )

            if (
                getUnitMeasureType(productionSheet.article?.unit_of_measure) === "volume"
                && !productionSheet.article?.density
            ) {
                return 0
            }

            volume = densityFormPF(parseFloat(productionSheet.article?.volume), parseFloat(productionSheet.article?.density))

        } else {
            densityForm = getDensityForm(
                ingredient.quantity_type
                    ? ingredient.quantity_type?.includes("masse")
                        ? "masse"
                        : "volume"
                    : getUnitMeasureType(ingredient.article?.unit_of_measure),
                'masse'
            )
        }


        return (
            // getFiltrationMargeToAdd(ingredient)
            // * volume
            volume
            * (densityForm((ingredientQty / 100), density)) * qtyToAdd
        ).toFixed(4) * 1
    }

    const getAllIngredientsQuantity = () => {
        let total = productionSheet.recipe.cannabinoids.reduce((acc, ingredient) => {
            return acc + percentQtyToRealQty(ingredient) / (getCannabinoidConcentration(ingredient) / 100)
        }, 0)

        total += productionSheet.recipe.other_ingredients.reduce((acc, ingredient) => {
            return acc + (ingredient.infusion === 0 ? percentQtyToRealQty(ingredient) : 0)
        }, 0)

        return total
    }

    const getBaseQuantity = (ingredient) => {
        // const recipe = productionSheet.recipe
        if (checkCannabinoids()) {

            // console.log("productionSheet", productionSheet);
            const densityFormPF = getDensityForm(
                getUnitMeasureType(productionSheet.article?.unit_of_measure),
                'masse'
            )
            if (
                getUnitMeasureType(productionSheet.article?.unit_of_measure) === "volume"
                && !productionSheet.article?.density
            ) {
                return 0
            }

            const massePF = densityFormPF(
                parseFloat(productionSheet.article?.volume),
                parseFloat(productionSheet.article?.density)
            ) * parseFloat(productionSheet.parameters.quantity_to_produce)

            const massAllIngredients = getAllIngredientsQuantity()

            const quantityPercentAllBases = massePF - massAllIngredients

            const massBaseTotal = quantityPercentAllBases * (parseFloat(ingredient.quantity) / 100)

            return massBaseTotal
        } else {
            return null;
        }
    }

    const getSumBatchsIngredientQty = (ingredient) => {
        const sum = Array.isArray(ingredient?.batchs)
            ? ingredient.batchs.reduce((total, batch) => {
                let quantity = batch.real_quantity && Number(batch.real_quantity)
                return total + (isNaN(quantity) ? 0 : quantity)
            }, 0)
            : 0
        return sum
    }

    const getSumAdaptedBatchsQty = (ingredient) => {
        const sum = ingredient?.batchs
            ? ingredient.batchs.reduce((total, batch) => {
                let quantity = batch.percent_real_quantity && Number(batch.percent_real_quantity)
                return total + (isNaN(quantity) ? 0 : quantity)
            }, 0)
            : 0
        return sum / 100
    }

    const getIngredientQuantityVariation = (ingredient) => {
        if (ingredient.type === "cannabinoids") {
            const sumAdaptedQtty = getSumAdaptedBatchsQty(ingredient)
            const realQuantity = getRealQuantity(ingredient)
            return sumAdaptedQtty
                ? (realQuantity * (1 - sumAdaptedQtty)).toFixed(2) * -1
                : 0
        } else {
            return (getSumBatchsIngredientQty(ingredient) - getRealQuantity(ingredient)).toFixed(2) * 1
        }
    }
    /********************************************************************************************************************/

    return (
        <RecipeContext.Provider value={{
            recipeNames,
            postRecipe,
            setRecipes,
            addRecipe,
            getRecipeById,
            getIngredientQuantityVariation,
            percentQtyToRealQty,
            getRealQuantity,
            checkCannabinoids,
        }} >
            {props.children}
        </RecipeContext.Provider>
    )
}