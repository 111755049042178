import { useCallAnalysesApi } from "Functions"
import { createContext } from "react"

export const AnalysesContext = createContext()

function AnalysesContextProvider(props) {

    const callAnalysesApi = useCallAnalysesApi()

    const getAnalyse = async (id, signal = null) => {
        return new Promise((resolve, reject) => {
            callAnalysesApi(`analyse/${id}`, { method: "get", signal: signal })
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    /**
     * 
     * @param {object} params accepted properties: {bulk_id: id | batch_id: id | id: id}
     * @param {*} signal 
     * @returns 
     */
    const getAnalyses = async (params = {}, signal = null) => {
        return new Promise((resolve, reject) => {
            callAnalysesApi(`analyses`, { method: "get", signal: signal }, params)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    return (
        <AnalysesContext.Provider value={{ getAnalyse, getAnalyses }}>
            {props.children}
        </AnalysesContext.Provider>
    )
}

export default AnalysesContextProvider
