import React, { useState, useEffect, useContext } from "react"
import Datalist from "Components/Dataliste/Dataliste"
import PlanProductForm from "./PlanProductForm"
import { PlanningContext } from "Context/ERP/PlanningContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"

export default function SelectStep({ selectInfo }) {

  const { stepsToPlan, pipedriveProducts } = useContext(PlanningContext)
  const [formatedSteps, setFormatedSteps] = useState(false)

  const getAssociatedStepProduct = (product_id, products) => {
    const product =
      products.length > 0 &&
      products.filter((product) => product.id === product_id)
    return product[0]
  }

  const getSelectedStepFromId = (id) => {
    const step = stepsToPlan.length && stepsToPlan.filter((step) => id === step.id)
    return step[0]
  }

  // console.log(formatedSteps);

  useEffect(() => {
    if (stepsToPlan && pipedriveProducts) {
      setFormatedSteps(
        stepsToPlan.map((step) => {
          const product = getAssociatedStepProduct(step.product_id, pipedriveProducts)
          if (product) {
            return {
              id: step.id,
              value: `[${step.deal_id}] ${step.name} - ${product ? product.name : "produit inconnu"
                }`,
            }
          }
        })
          .filter((formatedStep) => formatedStep)
      )
    }
  }, [stepsToPlan, pipedriveProducts])

  const [selectedStep, setSelectedStep] = useState(false)

  const setFormatedSelectedStep = (stepId) => {
    const step = getSelectedStepFromId(stepId)
    if (step) {
      const product = getAssociatedStepProduct(step.product_id, pipedriveProducts)
      setSelectedStep({
        step_id: stepId,
        deal_id: step.deal_id,
        product,
      })
      selectInfo.step = step
    }
  }
  return (
    <>
      {formatedSteps ?
        <div style={{ marginBottom: "10px" }}>
          <Datalist
            placeholder="Choisissez une étape"
            items={formatedSteps}
            onSelect={setFormatedSelectedStep}
            width="100%"
          />
        </div>
        : <MiniLoader />
      }
      {selectedStep && (
        <PlanProductForm
          dealId={selectedStep.deal_id}
          passedProduct={selectedStep.product}
          selectInfo={selectInfo}
        />
      )}
    </>
  )
}
