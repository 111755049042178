import React, { useState } from 'react'
import { useEffect } from 'react'
import './AccordionTable.css'

/**
 * 
 * @param {array} columns array of colnames  like :
 *  [{title: 'title', colSpal: 2, style: style}, ...] 
 * @param {array} datas array of mainLines and subLines 
 *  [{
 *      mainLine: [{ element: null, colSpan: 1 }, { element: null, colSpan: 1 }, ...], 
 *      subLines: [[{ element: null, colSpan: 1 }, { element: null, colSpan: 1 }], [], ...]}, {mainLine: [], subLines: [[], [], ...]}
 *  }]
 * You can add style with datas.mainLineStyle
 * @returns 
 */
export default function AccordionTable({ columns, datas }) {

    const [tableId, setTableId] = useState()
    useEffect(() => {
        const rdm = Math.floor(Math.random() * 9999999)
        setTableId(`accordion-${rdm}`)
    }, []);


    useEffect(() => {
        const avtiveIndexes = [];
        [...document.querySelectorAll(`#${tableId} .main-line.active`)].forEach(mainLine => {
            avtiveIndexes.push(mainLine.getAttribute('data-index'))
        });
        [...document.querySelectorAll(`#${tableId} .subRaw`)].forEach(subRaw => {
            if (avtiveIndexes.includes(subRaw.getAttribute('data-index'))) {
                subRaw.classList.add("active");
            }
        });
    }, [columns, datas])

    const toggleSubLines = (element, rawId, subLinesClass) => {
        const mainLine = document.querySelector(`#${tableId} #${rawId}`)
        if (element.tagName !== "BUTTON" && element.tagName !== "INPUT" && element.tagName !== "A") {
            if (mainLine.classList.contains("active")) {
                mainLine.classList.remove("active");
                [...document.querySelectorAll(`#${tableId} .${subLinesClass}`)].forEach(element => {
                    element.classList.remove("active");
                });
            } else {
                mainLine.classList.add("active");
                [...document.querySelectorAll(`#${tableId} .${subLinesClass}`)].forEach(element => {
                    element.classList.add("active");
                });
            }
        }
    }

    if (columns && datas) {
        return (
            <table id={tableId} className='accordion-table beautiful-array'>
                <thead>
                    <tr>
                        <th></th>
                        {columns.filter(column => column)
                            .map((column, index) => (
                                <th colSpan={column.colSpan ? column.colSpan : 1} style={column.style ? column.style : {}} key={index}>{column.title}</th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {datas
                        .filter(data => data)
                        .map((data, index) => {
                            const rawId = `raw-${index}`
                            const subLinesClass = `subRaw-${index}`
                            const subLinesIndex = index
                            return (
                                <React.Fragment key={index}>
                                    <tr style={data.mainLineStyle ? data.mainLineStyle : {}} key={index} data-index={index} id={rawId} className='main-line' onClick={e => toggleSubLines(e.target, rawId, subLinesClass)}>
                                        <td className='show-sublines'>▶</td>
                                        {data.mainLine
                                            ?.filter(mainLine => mainLine)
                                            ?.map((mainRow, index) => (
                                                <td colSpan={mainRow.colSpan} key={index}>{mainRow.element}</td>
                                            ))}
                                    </tr>
                                    {data.subLines
                                        ?.filter(subLine => subLine)
                                        ?.map((subLine, index) => {
                                            const className = `subRaw ${subLinesClass}`
                                            const lastIndex = (index + 1 === data.subLines.length)
                                            const bottomShadow = 'inset 0 -7px 10px -7px rgba(0,0,0,0.3)'
                                            const topShadow = 'inset 0 7px 10px -7px rgba(0,0,0,0.3)'
                                            let style = {}
                                            if (index === 0 && lastIndex) {
                                                style = { boxShadow: `${topShadow}, ${bottomShadow}` }
                                            } else if (index === 0) {
                                                style = { boxShadow: topShadow }
                                            } else if (lastIndex) (
                                                style = { boxShadow: bottomShadow }
                                            )
                                            return (
                                                <tr data-index={subLinesIndex} key={index} className={className} style={style}>
                                                    {subLine
                                                        ?.filter(subRow => subRow)
                                                        ?.map((subRow, index) =>
                                                            <td colSpan={subRow.colSpan} key={index}><div>{subRow.element}</div></td>
                                                        )}
                                                </tr>
                                            )
                                        })}
                                </React.Fragment>
                            )
                        })}
                </tbody>
            </table>
        )
    } else {
        return null
    }
}
