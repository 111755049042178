import React from 'react'
import VariablesContextProvider from "Context/ERP/VariablesContext"
import OrganizationsContextProvider from "Context/ERP/OrganizationsContext"
import PlanningContextProvider from './PlanningContext'
import RecipeContextProvider from './RecipeContext'
import ArticleContextProvider from './ArticleContext'
import DealContextProvider from '../Commercial/DealContext'
import ProductSpecificationContextProvider from './ProductSpecificationContext'
import ProductionSheetContextProvider from './productionSheet/CreateProductionSheet'
import DealProductsContextProvider from './deal/DealProduct'
import BatchContextProvider from '../CBDProtect/Batch'
import BulkContextProvider from '../CBDProtect/Bulk'
import ErpBatchContextProvider from './ErpBatchContext'
import AnalyseContextContainer from 'Context/Analyse/AnalyseContextContainer'
import AnalysesContextProvider from 'Context/Analyse/AnalysesContext'

export default function ErpContextContainer(props) {

    return (
        <VariablesContextProvider>
            <OrganizationsContextProvider>
                <DealContextProvider>
                    <PlanningContextProvider>
                        <ProductSpecificationContextProvider>
                            <AnalysesContextProvider>
                                <BulkContextProvider>
                                    <ArticleContextProvider>
                                        <BatchContextProvider>
                                            <ErpBatchContextProvider>
                                                <ProductionSheetContextProvider>
                                                    <RecipeContextProvider>
                                                        <DealProductsContextProvider>
                                                            {props.children}
                                                        </DealProductsContextProvider>
                                                    </RecipeContextProvider>
                                                </ProductionSheetContextProvider>
                                            </ErpBatchContextProvider>
                                        </BatchContextProvider>
                                    </ArticleContextProvider>
                                </BulkContextProvider>
                            </AnalysesContextProvider>
                        </ProductSpecificationContextProvider>
                    </PlanningContextProvider>
                </DealContextProvider>
            </OrganizationsContextProvider >
        </VariablesContextProvider>
    )
}
