import React from 'react'
import SigninButton from './SigninButton'

export default function Login() {
  return (
    <>
        <h5>Pour accéder à l'ensemble de vos applications, Connectez-vous</h5>
        <SigninButton />
    </>
  )
}
