import React from 'react'
import ThirdNavBar from 'Components/NavBar/ThirdNavBar/ThirdNavBar';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

export default function Products({ path }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    location.pathname.split('/').length === 4 && navigate(`${path}/import-export`); //Line par défaut
  }, [location.pathname])

  return (
    <>
      <ThirdNavBar
        menu={[
          { name: "Import & Export", link: `${path}/import-export`, access: true }, //tout le monde à accès
          { name: "Commandes", link: `${path}/commande`, access: true },
          { name: "Stock à écouler", link: `${path}/stock-a-ecouler`, access: true },
        ]}
      />
    </>
  )
}
