import { useCallApi } from 'Functions'
import { useState, useEffect, useContext } from 'react'
import { appConstante, getStockUnitOfMeasure } from 'appConstante'
import { PopupContext } from "Context/PopupContext";
import MidLoader from 'Components/MidLoader/MidLoader'
import Pagination from 'Components/Pagination/Pagination'
import AddHistory from '../ImportsAndExport/History/AddHistory'

export default function SurplusStock({ category }) {

    const callApi = useCallApi()
    const { addPopupContent } = useContext(PopupContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [batchs, setBatchs] = useState(null)
    const [selectedOrderOption, setSelectedOrderOption] = useState('price_at_ddm')
    const [articles, setArticles] = useState([])

    const updateBatch = () => {
        showPopup(false)
        loadBatch()
    }

    // APPEL API
    const loadBatch = (signal = null) => {
        setBatchs(null)
        const url = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/batchs`
        callApi(url, { method: "get" }, signal, {
            category: category,
            limit: 10,
            page: currentPage,
            only_available: 1,
            order_by: selectedOrderOption
        })
            .then((res) => {
                setBatchs(res.data.data)
                setTotalPage(res.data.total_pages)
                res.data.total_pages === 0 && setCurrentPage(1)
                currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
            })
            .catch(() => {
                setBatchs([])
            })
    }
    useEffect(() => {
        const controller = new AbortController()
        loadBatch(controller.signal)
        return () => {
            controller.abort()
        }
    }, [category, currentPage, selectedOrderOption])

    return (
        <div>
            <div className='flex-center'>
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPage={totalPage}
                />
                <div className='column'>
                    <label>Trier par</label>
                    <select className='m-b-25' value={selectedOrderOption} onChange={e => setSelectedOrderOption(e.target.value)} >
                        <option value="price_at_ddm">Prix total à la DDM</option>
                        <option value="quantity_at_ddm">Quantité à DDM</option>
                        <option value="expiration_date">DDM</option>
                        <option value="date">Date d'ajout</option>
                    </select>
                </div>
            </div>
            {
                !batchs
                    ? <MidLoader />
                    : batchs.length === 0
                        ? <h5>Aucun stock à écouler</h5>
                        : <table className='beautiful-array'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nom de l'article</th>
                                    <th>Quantité actuel</th>
                                    <th>Fournisseur</th>
                                    <th>Numéro de lot</th>
                                    <th>Consommation moyenne</th>
                                    <th>Quantité à DDM</th>
                                    <th>Prix du stock à DDM</th>
                                    <th>DDM</th>
                                </tr>
                            </thead>
                            {batchs.map(batch => {
                                const firstDDM = new Date(batch.ddm)
                                const currentDate = new Date()
                                const isWithinTwoMonths = firstDDM && firstDDM < new Date(new Date().getTime() + (2 * 30 * 24 * 60 * 60 * 1000)) && new Date() < firstDDM
                                const isOlderThanCurrentDate = firstDDM && firstDDM < currentDate;
                                return (
                                    <tbody key={batch.id}>
                                        <tr>
                                            <td>
                                                <button
                                                    className="light-button show-on-hover"
                                                    onClick={() => addPopupContent(
                                                        <AddHistory
                                                            defaultHistory={{
                                                                history_type: 'remove',
                                                                quantity: batch.quantity,
                                                                batch_id: batch.id,
                                                                article_id: batch.article.id,
                                                                product_id: batch.product_id,
                                                                destocking: true,
                                                            }}
                                                            updateArticle={updateBatch}
                                                        />)
                                                    }>Déstocker
                                                </button>
                                            </td>
                                            <td>{batch.article ? batch.article.name : 'Erreur lors de la récupération de l\'article'}</td>
                                            <td>{batch.quantity}</td>
                                            <td>{batch.product_supplier}</td>
                                            <td>{batch.batch_number}</td>
                                            <td>{batch.avg_day_consumption.toFixed(2) * 1} {getStockUnitOfMeasure(batch.article)}</td>
                                            <td>{(batch.quantity_at_ddm.toFixed(2) * 1) > 0 ? batch.quantity_at_ddm.toFixed(2) * 1 : 0} {getStockUnitOfMeasure(batch.article)}</td>
                                            <td>{((batch.quantity_at_ddm * batch.price).toFixed(2) * 1) > 0 ? ((batch.quantity_at_ddm * batch.price).toFixed(2) * 1) : 0}€</td>
                                            <td style={{ color: isWithinTwoMonths && '#f7a508' || isOlderThanCurrentDate && '#f10909' }}>
                                                <div className={isWithinTwoMonths || isOlderThanCurrentDate ? 'strong-2' : ''}>
                                                    {(firstDDM ? firstDDM.toLocaleDateString("fr") : null)}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>)
                            })}
                        </table>}
        </div >
    )
}
