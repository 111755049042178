import React from "react"
import ShowDealsArrayFromFilter from "Components/_ERP/ShowDealsArrayFromFilter"
import SearchDeal from "../SearchDeal/SearchDeal"

export default function DealToSend() {
  /* Constante */
  return (
    <>
      <h4 className="m-t-0 m-b-10">Commandes prêtes pour expéditon</h4>
      <ShowDealsArrayFromFilter
        filter_id={313}
        showCreateDS={true}
        ableToPlan={false}
      />
      <h4 className="m-t-25 m-t-25">Toutes les commandes en cours</h4>
      <ShowDealsArrayFromFilter
        filter_id={292}
        showCreateDS={true}
        ableToPlan={false}
      />


      <h4 className="m-t-50 p-t-50">Chercher une autre commande</h4>
      <SearchDeal />

    </>
  )
}
