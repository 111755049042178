import React from "react"
import LoaderContextProvider from "./LoaderContext"
import PopupContextProvider from "./PopupContext"
import NotificationContextProvider from "./NotificationContext"
import UserInfosContexteProvider from "./UserInfosContexte"
import DependenciesContextProvider from "./DependenciesContext"
import { useLocation } from "react-router"
import ErpContextContainer from "./ERP/ErpContextContainer"
import OrganizationsContextProvider from "./ERP/OrganizationsContext"
import { getCurrentAppName } from "Functions"
import CommercialContextContainer from "./Commercial/CommercialContextContainer"
import AnalyseContextContainer from "./Analyse/AnalyseContextContainer"
import CBDProtectContextContainer from "./CBDProtect/CBDProtectContextContainer"
import CommentsContextProvider from "./CommentContext"

export default function ContextContainer(props) {
  const location = useLocation();
  const findApps = { show: true }

  const showContext = (context) => {
    findApps.show = false
    return (context)
  }

  return (
    <LoaderContextProvider>
      <PopupContextProvider>
        <NotificationContextProvider>
          <UserInfosContexteProvider>
            {/* UserLoaded */}
            <DependenciesContextProvider>
              <CommentsContextProvider>
                {getCurrentAppName(location.pathname) === 'erp'
                  ? showContext(<ErpContextContainer {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'invoices'
                  ? showContext(<OrganizationsContextProvider {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'dashboard'
                  ? showContext(<OrganizationsContextProvider {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'reglementaire'
                  ? showContext(<OrganizationsContextProvider {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'commercial'
                  ? showContext(<CommercialContextContainer {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'analyses'
                  ? showContext(<AnalyseContextContainer {...props} />)
                  : null}
                {getCurrentAppName(location.pathname) === 'cbd-protect'
                  ? showContext(<CBDProtectContextContainer {...props} />)
                  : null}
                {findApps.show ? props.children : null}
              </CommentsContextProvider>
            </DependenciesContextProvider>
          </UserInfosContexteProvider>
        </NotificationContextProvider>
      </PopupContextProvider >
    </LoaderContextProvider >
  )
}
