const env = {
    production: {
        PANORAMA_ENDPOINT: "https://serveurllfc.synology.me",
        ANALYSE_ENDPOINT: "https://analyse-llfc.fr/wp-json/analyse/v2",
        FA_CBDP_ENDPOINT: "https://labofrancaisduchanvre.com/wp-json/cbdp",
        MSL_ENDPOINT: "https://marqueblanchecbd.fr/wp-json/msl",
        LLFC_ENDPOINT: "https://llfc.fr/wp-json/llfc",
    },
    dev: {
        PANORAMA_ENDPOINT: "http://localhost:4000",
        ANALYSE_ENDPOINT: "http://lelabofrancaisduchanvre.com/wp-json/analyse/v2",
        FA_CBDP_ENDPOINT: "http://myspace.local/wp-json/cbdp",
        MSL_ENDPOINT: "http://myspace.local/wp-json/msl",
        LLFC_ENDPOINT: "http://llfc.local//wp-json/llfc",
    },
}

export default env