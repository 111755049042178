import React, { useRef } from 'react'
import { useState, useEffect, useContext } from 'react'
import { appConstante, getStockUnitOfMeasure } from 'appConstante';
import { NotificationContext } from 'Context/NotificationContext';
import Select from 'Components/FormElement/Select';
import Input from 'Components/FormElement/Input';
import { useCallApi } from 'Functions';
import { PopupContext } from 'Context/PopupContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import SelectArticles from 'Components/_ERP/SelectArticles';
import Datalist from 'Components/Dataliste/Dataliste';
import { ArticleContext } from 'Context/ERP/ArticleContext';

export default function UpdateHistory({ movement, filterMovement }) {

    const callApi = useCallApi()
    const [newHistory, setNewHistory] = useState({ ...movement, delivery_date: new Date().toISOString().slice(0, 10) });
    const product = useRef();
    const [avaibleBatchs, setAvaibleBatchs] = useState([]);
    const [avaibleStock, setAvaibleStock] = useState();
    const [article, setArticle] = useState(null);
    const [showArticle, setShowArticle] = useState(false);
    const [typeOfHistory, setTypeOfHistory] = useState(movement.quantity >= 0 ? "add" : "remove");

    const { addNotificationContent } = useContext(NotificationContext)
    const { getArticle } = useContext(ArticleContext)
    const { showPopup2, showPopup } = useContext(PopupContext)

    useEffect(() => {
        if (newHistory.article_id) {
            getArticle(newHistory.article_id)
                .then(article => setArticle(article))
        }

    }, [newHistory.article_id])

    //Chargement du product
    useEffect(() => {
        const controller = new AbortController
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product/${newHistory.product_id}`,
            { method: 'get' }, controller.signal)
            .then(res => {
                if (res.data.data) {
                    product.current = res.data.data;
                    setAvaibleBatchs(res.data.data.history.filter(batch => batch.quantity > 0))
                }
            })
            .catch(error => {
                if (!controller.signal.aborted) {
                    console.error("Error fetching product:", error);
                }
            });
        return () => {
            controller.abort();
        };
    }, [newHistory.product_id])

    useEffect(() => {
        if (typeOfHistory === 'remove' && newHistory.quantity > avaibleStock) {
            addNotificationContent({
                error: true,
                title: 'Attention !',
                content: 'La quantité du stock à retirer est supérieur à celle disponible',
            })
        }
    }, [newHistory.quantity, avaibleStock, typeOfHistory])

    useEffect(() => {
        if (typeOfHistory === 'remove' && newHistory.batch_id) {
            const currentBatch = avaibleBatchs.find(batch => batch.id == newHistory.batch_id)
            currentBatch && setAvaibleStock(parseFloat(currentBatch.quantity))
        }
    }, [newHistory.batch_id, avaibleBatchs])


    const updateHistoryKey = (key, value) => {
        setNewHistory({ ...newHistory, [key]: value });
    }

    const sendHistory = e => {
        e.preventDefault()
        addNotificationContent({
            title: 'conversion en cours',
            content: <MiniLoader />,
            infinit: true,
        })

        if (!newHistory.batch_id) { //Création d'un nouveau batch
            newHistory.new_batch = {
                product_id: product.current.id,
                batch_number: newHistory.batch_number
            }
        }
        newHistory.createdAt = new Date(movement.date).toISOString(); //date de création du mouvement
        newHistory.date = newHistory.delivery_date // date de validation du mvt 
        delete newHistory.id
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/history`, { method: 'post' }, null, newHistory, e)
            .then(res => {
                callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/pre-history/${movement.id}`, { method: 'put' }, null, { ...movement, converted: 1 })
                    .then(result => {
                        filterMovement(movement.id)
                        showPopup2(false)
                        showPopup(false)
                        addNotificationContent({
                            title: 'Succès',
                            content: "L'historique a été crée",
                        })
                    }).catch((err) => {
                        addNotificationContent({
                            error: true,
                            title: 'Erreur',
                            content: err.response.message,
                        })

                    });
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })
            })
    }
    return (
        <>
            <h2>Convertir {movement.name} en historique</h2>
            {
                newHistory && product.current ?
                    (
                        <form onSubmit={e => sendHistory(e)}>
                            <div className='center m-t-25' style={{ maxWidth: '400px', margin: 'auto' }}>
                                <Select value={typeOfHistory} onChange={setTypeOfHistory} label="Nature de l'historique">
                                    <option value='remove'>Retrait</option>
                                    <option value='add'>Ajout</option>
                                </Select>
                            </div>
                            <div className='flex'>
                                <div className='half-m-width'>
                                    <button className='light-button m-t-25'
                                        type='button'
                                        onClick={_ => setShowArticle(prv => !prv)}>
                                        Changer d'article
                                    </button>
                                    {
                                        showArticle
                                            ? <>
                                                <SelectArticles
                                                    onSelect={updateHistoryKey}
                                                    customParameters={["article_id"]}
                                                />
                                                {
                                                    article
                                                        ? <Datalist
                                                            items={article.products?.map(product => ({ id: product.id, value: product.supplier }))}
                                                            onSelect={updateHistoryKey}
                                                            customParameters={["product_id"]}
                                                        />
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {typeOfHistory === 'add' ? (
                                        <>
                                            <Select value={newHistory.batch_id} onChange={updateHistoryKey}
                                                customParameters={['batch_id']} label='Numéro de lot'
                                            >
                                                <option value=''>Créer un nouveau lot</option>
                                                {avaibleBatchs.map(batch => <option key={batch.id} value={batch.id}>{batch.batch_number} (disponible: {batch.quantity})</option>)}
                                            </Select>
                                            {!newHistory.batch_id &&
                                                <Input value={newHistory.batch_number ? newHistory.batch_number : ''}
                                                    onInput={updateHistoryKey}
                                                    customParameters={['batch_number']}
                                                    label='Nouveau numéro de lot'
                                                />}
                                            <Input type='number' min='0' step='0.0001' value={newHistory.quantity}
                                                onInput={updateHistoryKey} customParameters={['quantity']}
                                                label={`Quantité à ajouter (${getStockUnitOfMeasure(product.current.article)})`} required={true}
                                            />

                                            <Input type='date' value={newHistory.expiration_date ? new Date(newHistory.expiration_date).toISOString().slice(0, 10) : ""}
                                                onInput={updateHistoryKey} required={false}
                                                label='DDM' customParameters={['expiration_date']}
                                            />
                                            <Input type='number' min='0' step='0.0001' value={newHistory.price ? newHistory.price : null}
                                                onInput={updateHistoryKey} label='Prix total (€)' customParameters={["price"]} required={false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Select value={newHistory.batch_id ? newHistory.batch_id : ''} onChange={updateHistoryKey}
                                                label='Numéro de lot' customParameters={["batch_id"]} required={true}>
                                                <option value="">-- Sélectionner un numéro de lot --</option>
                                                {avaibleBatchs.map(batch => <option key={batch.id} value={batch.id}>{batch.batch_number} (disponible: {batch.quantity})</option>)}
                                            </Select>
                                            <Input
                                                type='number' min='0' step='0.000001' value={newHistory.quantity < 0 ? newHistory.quantity * -1 : newHistory.quantity} onInput={updateHistoryKey}
                                                label={`Quantité à retirer (${getStockUnitOfMeasure(product.current.article)})`} customParameters={['quantity']} />
                                        </>
                                    )}
                                </div>
                                <div className='half-m-width'>
                                    <Input type='date' value={newHistory.delivery_date}
                                        onInput={updateHistoryKey}
                                        label="Date de validation de l'historique " customParameters={['delivery_date']}
                                        required={true}
                                    />
                                    <Select value={newHistory.type} onChange={updateHistoryKey}
                                        customParameters={['type']} label='Type'>
                                        <option value='Manuel'>Manuel</option>
                                        <option value='Automatique'>Automatique (depuis les recettes)</option>
                                    </Select>
                                    <Input label='Commentaire' value={newHistory.comment} onInput={updateHistoryKey} customParameters={['comment']} />
                                    <label className='flex m-b-25' style={{ cursor: 'pointer' }}>
                                        <div style={{ color: 'red' }}>Archiver</div>
                                        <input type='checkbox'
                                            className='erp-check'
                                            checked={newHistory.archive === 1}
                                            onChange={e => updateHistoryKey("archive", e.target.checked ? 1 : 0)}
                                        />
                                    </label>
                                </div>
                            </div>
                            <button className='strong-button m-b-25'>Valider</button>
                        </form>
                    ) : <MiniLoader />
            }
        </>
    )
}
