import React, { createContext, useEffect, useRef, useState } from "react"
import { useCallApi } from "Functions"
import { appConstante } from "appConstante"

interface Comment {
    content: string,
    target: Array<string>,
    target_id: number,
}

export const CommentsContext = createContext()

function CommentsContextProvider(props) {
    const callApi = useCallApi()


    const addComment = (comment: Comment) => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/comment`, { method: "post" }, null, comment)
                .then((res) => resolve(res.data.data))
        })
    }

    const getComments = (target: string, target_id: number) => {
        return new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/comments`, { method: "get" }, null, { target: target, target_id: target_id })
                .then((res) => resolve(res.data.data))
        })
    }


    return (
        <CommentsContext.Provider value={{ addComment, getComments }}>
            {props.children}
        </CommentsContext.Provider>
    )
}

export default CommentsContextProvider



