import React, { useContext, useEffect, useState, useRef } from 'react'
import { useCallAnalysesApi, useCallPipedriveApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import { appConstante } from 'appConstante'
import SelectOrganization from 'Components/_ERP/Brands/SelectOrganization'
import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { useCurrentAppPermission } from 'Functions'

export default function Details({ userInfos }) {

    // CONST, CONTEXTS...
    const currentAppPermission = useCurrentAppPermission();
    const callAnalysesApi = useCallAnalysesApi()
    const callPipedriveApi = useCallPipedriveApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [analysysForm, setAnalysysForm] = useState({ demandeur: userInfos.user_info.entreprise ?? "", mail: userInfos.user_info.mail ?? "" })
    const [invoiceForm, setInvoiceForm] = useState(userInfos.user_info.billing_info)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState()
    const [selectedPersonId, setSelectedPersonId] = useState(-1)
    const [searchedPerson, setSearchedPerson] = useState()
    const [persons, setPersons] = useState([])

    useEffect(() => {
        if (userInfos.user_info.person_id) {
            callPipedriveApi(`persons/${userInfos.user_info.person_id}`)
                .then((res) => {
                    setSearchedPerson(res.data.data.name)
                    setSelectedPersonId(res.data.data.id)
                })
        } else {
            setSelectedPersonId(null)
        }
    }, [])

    const setTimoutId = useRef()
    useEffect(() => {
        if (searchedPerson && searchedPerson.length >= 2) {
            clearTimeout(setTimoutId.current)
            setTimoutId.current = setTimeout(() => {
                callPipedriveApi(`persons/search`, { method: 'get' }, { term: encodeURIComponent(searchedPerson) })
                    .then((res) => {
                        setPersons(res.data.data.items)
                    })
                    .catch((err) => err)
            }, 500)
        }
    }, [searchedPerson])

    const formaInvoicesData = (data) => {
        const formatedData = {}
        Object.entries(invoiceForm).map(([key, value]) => {
            formatedData[key] = value.value
        })
        return formatedData
    }

    const updatePropertyForm = (setter, state, key, value) => {
        setter({ ...state, [key]: value })
    }

    const submitForm = (e, data) => {
        e.preventDefault()

        addNotificationContent({
            error: false,
            title: 'Sauvegarde en cours',
            content: <MiniLoader />,
            infinit: true,
        })
        callAnalysesApi(
            `user/${userInfos.user_info.ID}`,
            { method: 'PUT' },
            data,
            e
        )
            .then(res => {
                if (res.data.success) {
                    addNotificationContent({
                        error: false,
                        title: 'Mise à jour réussie',
                        content: data.message ?? "Les données ont été sauvegardées",
                        infinit: false,
                    })
                } else {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur lors de la sauvegarde',
                        content: data.message ?? "Une erreur a eu lieu lors de la sauvegarde",
                        infinit: false,
                    })
                }
            })
            .catch(error => addNotificationContent({
                error: true,
                title: 'Erreur lors de la sauvegarde',
                content: error.response.data.message ?? "Une erreur a eu lieu lors de la sauvegarde",
                inifinit: false,
            }))
    }

    return (
        <>
            {userInfos?.user_info
                ? <div>
                    <h3>Détails de compte</h3>
                    <div className='list-item'>
                        <h4 className='center m-t-10'>Résultats d'analyse</h4>
                        <form onSubmit={e => submitForm(e, { user_results_analyse: analysysForm })}>
                            <div className='flex-center m-b-10'>
                                <label>Nom de demandeur par défaut :</label>
                                <input
                                    type="text"
                                    value={analysysForm.demandeur}
                                    onChange={e => updatePropertyForm(setAnalysysForm, analysysForm, 'demandeur', e.target.value)}
                                    disabled={currentAppPermission('analyses') <= appConstante.parameters.CAN_EDIT}
                                />
                            </div>
                            <div className='flex-center m-b-10'>
                                <label>Adresse mail d'envoi des résultats :</label>
                                <input
                                    type="text"
                                    value={analysysForm.mail}
                                    onChange={e => updatePropertyForm(setAnalysysForm, analysysForm, 'mail', e.target.value)}
                                    disabled={currentAppPermission('analyses') <= appConstante.parameters.CAN_EDIT}
                                />
                            </div>
                            {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                <button className='strong-button m-t-10'>Modifier</button>
                            }
                        </form>
                    </div>
                    <div className='m-t-25 m-b-25 list-item'>
                        <h4 className='center m-t-10'>Connexion à Pipedrive</h4>
                        <form onSubmit={e => submitForm(e, { user_pipedrive_info: { FA_organization_id: selectedOrganizationId, FA_person_id: selectedPersonId } })}>
                            <div className='flex-start center gap-1 m-b-25 m-r-10'>
                                <SelectOrganization
                                    defaultOrganizationId={userInfos.user_info.organization_id}
                                    setter={setSelectedOrganizationId}
                                    placeholder={"Nom de l'organisation"}
                                    width={'100%'}
                                    disabled={currentAppPermission('analyses') <= appConstante.parameters.CAN_EDIT}
                                />
                                {!selectedPersonId || selectedPersonId !== -1
                                    ? <Datalist
                                        value={searchedPerson}
                                        defaultId={selectedPersonId ?? null}
                                        items={persons?.map(person => ({ id: person.item.id, value: person.item.name }))}
                                        onSelect={setSelectedPersonId}
                                        onInput={setSearchedPerson}
                                        placeholder={"Nom de la personne"}
                                        width={'100%'}
                                        disabled={currentAppPermission('analyses') <= appConstante.parameters.CAN_EDIT}
                                    />
                                    : <MiniLoader />}
                            </div>
                            {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                <button className='strong-button m-t-10'>Modifier</button>
                            }
                        </form>
                    </div>
                    <div className='list-item'>
                        <h4 className='center m-t-10'>Informations de facturation</h4>
                        <form onSubmit={e => submitForm(e, { user_billing_info: formaInvoicesData(invoiceForm) })}>
                            <div className='flex wrap'>
                                {Object.entries(invoiceForm).map(([key, value], index) => (
                                    <div key={index} className='m-b-10 half-m-width flex center'>
                                        <label>{value.label}</label>
                                        <input
                                            value={value.value}
                                            onInput={e => updatePropertyForm(setInvoiceForm, invoiceForm, key, { label: value.label, value: e.target.value })}
                                            disabled={currentAppPermission('analyses') <= appConstante.parameters.CAN_EDIT}
                                        />
                                    </div>
                                ))}
                                {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    <button className='strong-button m-t-10'>Modifier</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                : <h5>Aucunes informations pour ce client</h5>}
        </>
    )
}