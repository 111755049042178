import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { PriceMatrixContext } from 'Context/Commercial/PriceMatrixContext'
import React, { useContext, useEffect, useState } from 'react'

export default function SelectPriceMatrix({
    className,
    setter,
    customParameters = [],
    placeholder = "Charger une matrice",
    width,
    required,
    style,
    disabled
}) {

    const { priceMatrices } = useContext(PriceMatrixContext)
    const [defaultMatrix, setDefaultMatrix] = useState(null)

    return (
        <div className={className}>
            {priceMatrices
                ? <Datalist
                    value={defaultMatrix ?? ''}
                    items={priceMatrices?.map(matrix => ({ id: matrix.id, value: matrix.name }))}
                    placeholder={placeholder}
                    onSelect={setter}
                    customParameters={customParameters}
                    width={width}
                    required={required}
                    style={style}
                    disabled={disabled}
                />
                : <MiniLoader />}
        </div>
    )
}
