import React, { useContext, useEffect, useState } from 'react'
import { useCallApi, utf8decode } from 'Functions'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import AddFile from './AddFile'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import DeleteFile from './DeleteFile'

export default function ConsultFiles({ target, targetId, className }) {

    const callApi = useCallApi()
    const { addPopupContent2 } = useContext(PopupContext)
    const [files, setFiles] = useState([])

    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/file`, { method: "get" }, controller.signal, { target: target, target_id: targetId })
            .then(res => setFiles(res.data.data ?? []))
        return () => {
            controller.abort()
        }
    }, [])


    const deleteFile = (id) => {
        setFiles(files.filter(file => file.ID !== id))
    }

    const showConfirmRemoveFile = file => {
        addPopupContent2(<DeleteFile file={file} deleteFile={deleteFile} />)
    }

    return (
        <div key={`${targetId}-${target}`}>
            <AddFile
                target={target}
                targetId={targetId}
                setter={setFiles}
                className={className}
            />
            {/* <h3 className='m-t-25'>Liste des fichiers</h3> */}
            <div className='flex column gap-1'>
                {files
                    ? files.map((file, index) => {
                        return (
                            <div key={index} className='list-item-bg p-10 flex-center'>
                                <div className='flex column'>
                                    {file.description
                                        ? <div className='flex-start gap-1'>
                                            <div className='strong-2'>Description :</div>
                                            <div>{file.description}</div>
                                        </div>
                                        : null}
                                    <div className='flex-start gap-1'>
                                        <div className='strong-2'>Nom du fichier :</div>
                                        <a href={file.url} target='_blank'>{(utf8decode(file.url.split('/')[file.url.split('/').length - 1]))}</a>
                                    </div>
                                </div>
                                <DeleteButton callBack={showConfirmRemoveFile} parameters={[file]} alwaysDisplay={true} />
                            </div>
                        )
                    })
                    : null}
            </div>
        </div>
    )
}
