import React, { useState, useContext, useEffect, useRef } from "react"
import "./deliverySheet.css"
import { useCallPipedriveApi } from "Functions"
import { DealProductsContext } from "Context/ERP/deal/DealProduct"
import { BulkContext } from "Context/CBDProtect/Bulk"
import { BatchContext } from "Context/CBDProtect/Batch"
import { ErpBatchContext } from "Context/ERP/ErpBatchContext"
import Logo from "./LLFC_LOGO.svg"
import ReactToPrint from "react-to-print"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import DeliverySheetProductItem from "./DeliverySheetProductItem"
import DeliverySheetProductItemV2 from "./DeliverySheetProductItemV2"

export default function DeliverySheet({ deliverySheet }) {

  const callPipedriveApi = useCallPipedriveApi()
  const { getDealProduct } = useContext(DealProductsContext)
  const { getBulk, formatBulkName } = useContext(BulkContext)
  const { getBatch, formatBatchName } = useContext(BatchContext)
  const { getERPBatch } = useContext(ErpBatchContext)

  const pdfRef = useRef(null)

  /** ***************** GET DELIVERY SHEET FROM ERP ****************** */
  const [deal, setDeal] = useState(false)
  /* PIPEDRIVE: récupération de deal */
  useEffect(() => {
    const controller = new AbortController()
    callPipedriveApi(`deals/${deliverySheet.deal_id}`, { signal: controller.signal })
      .then((res) => setDeal(res.data.data))
    return () => {
      controller.abort()
    }
  }, [deliverySheet])

  // GET PRODUCTS FROM PIPEDRIVE
  const [formatedProducts, setFormatedProducts] = useState(false)
  const [totalProducts, setTotalProducts] = useState(0)

  const addDLProductsDatas = async (pipedriveProducts) => {

    setFormatedProducts(await Promise.all(deliverySheet.products.map(async (product) => {

      if (product.product_id) {
        const productDeal = await getDealProduct(product.product_id)
        const bulks = await Promise.all(productDeal.bulk_id.map(async (bulkId) => getBulk(bulkId)))
        const batchs = await Promise.all(productDeal.batch_id.map(async (batchId) => getBatch(batchId)))

        const ERPbatchs = product.batchs_ids ? await Promise.all(product.batchs_ids.map(async batchId => getERPBatch(batchId))) : []

        const pipedriveProduct = pipedriveProducts.find(pipedriveProd => product.product_id === pipedriveProd.id)

        if (pipedriveProduct) {
          return {
            batchs: ERPbatchs,
            pipedrive_name: pipedriveProduct.name,
            comments: pipedriveProduct.comments,
            deal_quantity: pipedriveProduct.quantity,
            bulks_names: bulks.map(bulk => formatBulkName(bulk)),
            batchs_names: batchs.map(batch => formatBatchName(batch)),
            ...product,
          }
        } else {
          return {}
        }
      } else {
        return product
      }
    })
    ))
  }

  useEffect(() => {
    callPipedriveApi(`deals/${deliverySheet.deal_id}/products`).then((res) => {
      addDLProductsDatas(res.data.data)
    })
  }, [])

  useEffect(() => {
    let total = 0
    formatedProducts &&
      formatedProducts.forEach((product) => {
        total = total += product.quantity
      })
    setTotalProducts(total)
  }, [formatedProducts])

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <button className="strong-button">Télécharger le PDF</button>
        )}
        content={() => pdfRef.current}
      />
      <div ref={pdfRef} id="delivery-sheet">
        <h3>
          Bon de livraison n°{deliverySheet.id} - Devis n°
          {deliverySheet.deal_id}
        </h3>
        <div className="flex" id="delivery-infos">
          <div id="llfc-infos">
            <div>
              <img src={Logo} />
            </div>
            <div className="organisation-name">LLFC</div>
            <div className="organisation-adress">70 avenue Henri Barbusse</div>
            <div className="organisation-adress">93000 Bobigny, FRANCE</div>
          </div>
          <div id="organisation-infos">
            <div className="organisation-name">{(deal && deal.org_id) ? deal.org_id.name : ""}</div>
            <div>{(deal && deal.person_id) ? deal.person_id.name : ""}</div>
            <div className="organisation-adress">
              {deliverySheet.parameters.adress}
            </div>
          </div>
        </div>

        <table className="beautiful-array">
          <thead>
            <tr style={{ height: "40px" }}>
              <th className="left">Produit</th>
              <th>Quantité du devis</th>
              <th>Quantité envoyée</th>
              <th>
                Quantité restante
                <br />à expédier
              </th>
            </tr>
          </thead>
          <tbody>
            {formatedProducts ? (
              formatedProducts.map((product, index) => (
                deliverySheet.parameters.version === 2
                  ? <DeliverySheetProductItemV2 key={index} passedProduct={product} />
                  : <DeliverySheetProductItem key={index} product={product} />
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <MiniLoader />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div id="delivery-total-products">
          Nombre de produit total dans les colis:{" "}
          {totalProducts && totalProducts}
          <br />
          Nombre total de colis: {deliverySheet.parameters["cardboard-number"]}
        </div>
        <div id="date">
          Bon de livraison généré le :{" "}
          {deliverySheet
            ? new Date(deliverySheet.date).toLocaleDateString("fr")
            : "..."}
        </div>
        <div id="cgv">
          CGV - LIVRAISON
          <br />
          5.1. Délais de livraison
          <br />
          Les délais stipulés dans le Bon de Commande n'ont qu'une valeur
          indicative et non contractuelle. Par suite, le Fournisseur ne saurait
          voir sa responsabilité engagée en cas de retard de livraison imputable
          ou non à l'Acheteur ou en cas de force majeure.
          <br />
          5.2. Lieu et exécution de la livraison La livraison sera effectuée au
          lieu indiqué dans le Bon de Commande, les Produits voyageant aux
          risques et périls ainsi qu'à la charge de l'Acheteur.
          <br />
          L'Acheteur reconnaît que c'est au transporteur qu'il appartient
          d'effectuer la livraison, le Fournisseur étant réputé avoir rempli son
          obligation de délivrance dès lors qu'il a remis les produits commandés
          au transporteur qui les a acceptées sans réserve même si le
          Fournisseur, en application du Bon de Commande, a offert les frais de
          livraison. Par suite, le contrat de transport des Produits est conclu
          entre l'Acheteur et le transporteur ; le Fournisseur reste tiers à
          cette relation.
          <br />
          L'Acheteur ne dispose donc d'aucun recours en garantie contre le
          Fournisseur en cas de défaut de livraison des Produits commandés, ni
          des dommages survenus en cours de transport ou de déchargement.
          <br />
          L'Acheteur est tenu de vérifier l'état apparent des Produits lors de
          la livraison et de refuser impérativement tout colis ou chargement
          abimé.
          <br />
          En cas de réserves concernant les Produits, l'Acheteur est tenu :
          <br />
          (i) d'inscrire sur le bordereau de livraison des réserves écrites,
          précises et quantifiées, datées et signées. Ledit bordereau devra
          impérativement être remis au transporteur et l'Acheteur devra en
          conserver une copie ; et
          <br />
          (ii) notifier lesdites réserves en adressant au Fournisseur, dans un
          délai de 48 heures par courrier recommandé avec demande d'avis de
          réception, une copie dudit bordereau. A défaut de réalisation de ces
          deux formalités cumulatives, les Produits sont réputés avoir été
          livrés en bon état et aucune réclamation ne pourra être formée par
          l'Acheteur. Il est expressément convenu que, le cas échéant, la
          signature numérisée apposée par l'Acheteur ou par toute personne
          désignée par ce dernier sur un terminal informatique de suivi (PDA)
          présenté par le transporteur lors de la livraison de la commande fera
          foi et pourra valablement lui être opposée dans le cadre de toute
          procédure, aux fins de preuve.
        </div>
      </div>
      {/* <button onClick={() => downloadToPDF()}>Télécharger le PDF</button> */}

      {/* <button onClick={() => downloadToPDF()}>Télécharger le PDF</button> */}
    </div>
  )
}
