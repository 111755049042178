import React from 'react'
import './ThirdNavBar.css'
import { NavLink, Outlet } from 'react-router-dom';

/**
 * 
 * @param {Array} menu tableaux de menu au format [{link : '....', name : '....'}, {link : '....', name : '....'}, ....]  
 * @returns 
 */
export default function ThirdNavBar({ menu }) {
    return (
        <>
            <div className='third-navbar'>
                {menu.filter(menu => menu).map((itemMenu, index) => <NavLink key={index} className="third-navbar-button" to={itemMenu.link}>{itemMenu.name}</NavLink>)}
            </div>
            <Outlet />
        </>
    )
}
