import React from 'react'
import ShowDealsArray from 'Components/_ERP/ShowDealsArray/ShowDealsArray'
import ShowDealsArrayFromFilter from 'Components/_ERP/ShowDealsArrayFromFilter'

export default function ReglementaireHome() {
    return (
        <>
            <h2>Devis réglementaire en cours</h2>
            {<ShowDealsArrayFromFilter filter_id={347} ableToPlan={false}/>}
        </>
    )
}
