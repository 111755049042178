import AccordionTable from 'Components/AccordionTable/AccordionTable';
import Datalist from 'Components/Dataliste/Dataliste';
import Select from 'Components/FormElement/Select';
import { isAnalyseDeal } from 'Functions';
import React, { useEffect, useState } from 'react';

export default function OrganizationsDeals({ defaultOrganizations = [] }) {

    console.log(defaultOrganizations);

    const [organizations, setOrganizations] = useState(defaultOrganizations)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null)
    const [order, setOrder] = useState('total_ca')
    const [selectedCategorie, setSelectedCategorie] = useState(null)

    const digitOptions = {
        style: 'decimal',
        maximumFractionDigits: 2, // Spécifiez le nombre de décimales souhaité
    };

    useEffect(() => {
        setOrganizations(defaultOrganizations)
    }, [defaultOrganizations])
    return (
        <>
            <h2>Chiffre d'affaire des organisations</h2>
            <div className='flex-start end gap-1 m-b-10'>
                <Datalist
                    placeholder={"Rechercher une organisation"}
                    items={organizations
                        ?.map(organisation => ({ id: organisation.id, value: organisation.name }))
                    }
                    onSelect={setSelectedOrganizationId}
                    width={'300px'}
                />
                <Select
                    onChange={setSelectedCategorie}
                    value={selectedCategorie} style={{ width: '300px' }}
                >
                    <option value={''}>Filtrer par catégorie</option>
                    <option value={'white_label'}>Marque blanche</option>
                    <option value={'analyse'}>Analyse</option>
                </Select>
                <Select onChange={setOrder} value={order} style={{ width: '300px' }}>
                    <option value={'total_ca'}>Afficher par CA total</option>
                    <option value={'mb_ca'}>Afficher par CA marque blanche</option>
                    <option value={'analyse_ca'}>Afficher par CA analyse</option>
                </Select>
            </div>
            <AccordionTable
                columns={[
                    { title: 'Organisation' },
                    { title: 'Ca total' },
                    { title: 'CA marque blanche' },
                    { title: 'CA analyse' },
                    { title: 'nombre de devis' },
                ]}
                datas={
                    organizations
                        ?.filter(organisation => selectedCategorie ? organisation[selectedCategorie] : true)
                        ?.filter(organisation => selectedOrganizationId ? organisation.id === selectedOrganizationId : true)
                        ?.sort((a, b) => b[order] - a[order])
                        ?.map(organsation => {
                            return {
                                mainLine: [
                                    { element: <div>{organsation.name}</div>, colSpan: 1 },
                                    { element: <div>{organsation.total_ca.toLocaleString('fr-FR', digitOptions)}€</div>, colSpan: 1 },
                                    { element: <div>{organsation.mb_ca.toLocaleString('fr-FR', digitOptions)}€</div>, colSpan: 1 },
                                    { element: <div>{organsation.analyse_ca.toLocaleString('fr-FR', digitOptions)}€</div>, colSpan: 1 },
                                    { element: <div>{organsation.deals.length}</div>, colSpan: 1 },
                                ],
                                subLines: [
                                    [
                                        { element: <div className='strong-2'>ID</div>, colSpan: 1 },
                                        { element: <div className='strong-2'>Devis</div>, colSpan: 2 },
                                        { element: <div className='strong-2'>Montant</div> },
                                        { element: <div className='strong-2'>Date de gain</div> },
                                        { element: <div className='strong-2'>Catégorie</div> },
                                    ],
                                    ...organsation.deals.map(deal => {
                                        return [
                                            { element: <div>{deal.id}</div>, colSpan: 1 },
                                            { element: <div>{deal.title}</div>, colSpan: 2 },
                                            { element: <div>{deal.value.toLocaleString('fr-FR', digitOptions)}€</div>, colSpan: 1 },
                                            { element: <div>{(new Date(deal.won_time)).toLocaleDateString('fr-FR')}</div>, colSpan: 1 },
                                            { element: <div>{isAnalyseDeal(deal) ? 'Analyse' : 'Marque blanche'}</div>, colSpan: 1 },
                                        ]
                                    })
                                ]
                            }
                        })
                }
            />
        </>

    )

}