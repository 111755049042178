import React, { useState, useEffect } from 'react'
import { useAddErpDataToPipedriveDeal, useCallPipedriveApi } from "Functions"
import ShowDealsArray from './ShowDealsArray/ShowDealsArray'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function ShowDealsArrayFromFilter({ filter_id, ableToPlan = true, showCreateDS = false }) {

    const callPipedriveApi = useCallPipedriveApi()
    const addErpDataToPipedriveDeal = useAddErpDataToPipedriveDeal()

    const [deals, setDeals] = useState(null)
    const [error, setError] = useState(null)

    const [refreshPipedriveDeals, toggleRefreshPipedriveDeals] = useState(false)

    const updateDeals = () => {
        toggleRefreshPipedriveDeals(!refreshPipedriveDeals)
    }

    useEffect(() => {
        const controller = new AbortController()
        callPipedriveApi(
            "deals",
            { signal: controller.signal },
            { sort: "won_time", filter_id: filter_id }
        ).then((res) => {
            res.data.data
                ? Promise.all(res.data.data.map(deal => addErpDataToPipedriveDeal(deal)))
                    .then((formatedDeals) => setDeals(formatedDeals))
                    .catch(() => setError("Une erreur est survenue"))
                : setDeals([])
        })
        return () => {
            controller.abort()
        }
    }, [refreshPipedriveDeals])

    return (
        <>
            {error
                ? <h5>{error}</h5>
                : !deals
                    ? <MidLoader />
                    : <>
                        <ShowDealsArray
                            updateDeals={updateDeals}
                            deals={deals}
                            ableToPlan={ableToPlan}
                            showCreateDS={showCreateDS}
                        />
                    </>
            }
        </>
    )
}
