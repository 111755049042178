import React, { useEffect } from 'react';
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { categories } from 'appConstante';

export default function Stocks() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    location.pathname === '/erp/stock' && navigate(`/erp/stock/${categories[0].path}/import-export`); //Sous onglet par défaut
  }, [location.pathname])
  return (
    <SubNavBar menu={[
      { path: "/erp/stock/marques", name: "Marques" },
      ...categories.map(category => (
        { path: `/erp/stock/${category.path}`, name: category.name }
      )),
      { path: "/erp/stock/mouvements-en-cours", name: "Mouvements en cours" },
    ]} />
  )
}