import React from 'react'

export default function UpdateParametersPS({ parameters, setter }) {

    return (
        <div>
            <h4 className='m-0 m-b-10'>Paramètres</h4>
            <div>
                <div className='flex column gap-1 m-b-10'>
                    {
                        parameters.date
                            ? <div className="flex column">
                                <label>Date</label>
                                <input
                                    type="date"
                                    onInput={(e) => setter('date', e.target.value)}
                                    value={parameters.date}
                                    required
                                />
                            </div>
                            : null
                    }
                    {
                        parameters.produced_quantity || parameters.produced_quantity === 0 || parameters.produced_quantity === ''
                            ? <div className="flex column">
                                <label>Quantité produite : </label>
                                <input
                                    type="text"
                                    placeholder="Quantité produite"
                                    onInput={(e) => setter('produced_quantity', e.target.value)}
                                    value={parameters.produced_quantity}
                                    required
                                />
                            </div>
                            : null
                    }
                    {
                        parameters.quantity_to_produce || parameters.quantity_to_produce === 0 || parameters.quantity_to_produce === ''
                            ? <div className='flex-start gap-1 '>
                                <div>Quantité à produire : </div>
                                <div className='strong-2'>
                                    {parameters.quantity_to_produce}
                                </div>
                            </div>
                            : null
                    }
                    {
                        parameters.marge_to_add || parameters.marge_to_add === 0 || parameters.marge_to_add === ''
                            ? <div className='flex-start gap-1 '>
                                <div>Marge à ajouter : </div>
                                <div className='strong-2'>
                                    {parameters.marge_to_add}
                                </div>
                            </div>
                            : null
                    }
                </div>
                {parameters.comment || parameters.comment === ''
                    ? <div>
                        <label htmlFor="">Commentaire</label>
                        <textarea
                            placeholder="Ajouter un commentaire"
                            style={{ width: '100%', minHeight: '40px' }}
                            onInput={(e) => setter('comment', e.target.value)}
                            value={parameters.comment}
                        />
                    </div>
                    : null}
            </div>
        </div>
    )
}