import React, { useEffect, useState, useContext } from "react"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import {
  useSendInvoiceToCustomer,
  formatCustomerFromPennyLane,
  useCallPennylaneApi,
  useCallPipedriveApi,
} from "Functions"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import CreateInvoice from "AppContainer/Invoices/CreateInvoice/CreateInvoice"
import { appConstante } from "appConstante"
import InvoiceCustomer from "AppContainer/Invoices/CreateInvoice/InvoiceCustomer"
import { useCurrentAppPermission } from "Functions"

export default function InvoiceDetails({ invoiceId }) {
  const [invoice, setInvoice] = useState(false)
  const [dealId, setDealId] = useState(false)
  const [customer, setCustomer] = useState(false)

  const { showPopup2, addPopupContent2 } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)

  const callPennylaneApi = useCallPennylaneApi()
  const callPipedriveApi = useCallPipedriveApi()
  const sendInvoiceToCustomer = useSendInvoiceToCustomer()
  const currentAppPermission = useCurrentAppPermission()
  // [PENNYLANE] CHARGEMENT DES INFORMATIONS DE FACTURATION
  useEffect(() => {

    callPennylaneApi(`customer_invoices/${invoiceId}`)
      .then((res) => {
        setCustomer(formatCustomerFromPennyLane(res.data.invoice.customer))
        setInvoice(res.data.invoice)
      })
      .catch((error) => {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: "Cette facture n'as pas été créé sur PennyLane",
        })
      })
  }, [])

  // [PIPEDRIVE] Recherche du devis correspndant à la facture
  useEffect(() => {
    callPipedriveApi(
      `itemSearch/field`, { method: 'get' },
      { term: invoiceId, field_type: 'dealField', field_key: 'a68ca62f0aee305694a2848754aab8463d370764', return_item_ids: 1 }
    )
      .then((res) => {
        setDealId(res.data.data[0].id)
      })
  })

  const confirmSendInvoiceToCustomer = () => {
    addPopupContent2(
      <>
        <h2>Envoyer la facture par mail</h2>
        <p>
          Êtes vous sûr d'envoyer la facture par mail au client (
          {customer.emails[0]}) ?
        </p>
        <div className="flex">
          <button
            type="button"
            onClick={() => showPopup2(false)}
            className="erp-button erp-big-button error-strong-button"
          >
            Annuler
          </button>
          <button
            className="erp-button erp-big-button"
            style={{ display: "block", margin: "auto" }}
            onClick={() => {
              sendInvoiceToCustomer(invoiceId)
              showPopup2(false)
            }}
          >
            Envoyer
          </button>
        </div>
      </>
    )
  }

  if (invoice) {
    return (
      <>
        {!invoice.draft ? (
          <h2>Détail de la facture n°{invoice.invoice_number}</h2>
        ) : (
          <h2>{invoice.pdf_invoice_subject} - BROUILLON</h2>
        )}
        <iframe
          title="Inline Frame Example"
          width="100%"
          height="950"
          allowFullScreen
          lazy="true"
          src={invoice.file_url}
        />
        {invoice.is_draft && dealId && <CreateInvoice dealId={dealId} />}
        {!invoice.is_draft && customer && (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&

          <>
            <p>Cette facture à déjà été émise, elle n'est plus modifiable</p>
            <button
              className="strong-button"
              onClick={() => {
                confirmSendInvoiceToCustomer()
              }}
            >
              Envoyer la facture
            </button>
            <div style={{ marginTop: "25px" }} />
            <InvoiceCustomer
              organisationPennylane={customer}
              createOrganisationPennylane={false}
              setOrganisationPennylane={setCustomer}
            />
          </>

        )}
      </>
    )
  }
  return <MiniLoader />
}
