import React, { useState, useContext, useEffect, useRef } from 'react'
import { NotificationContext } from 'Context/NotificationContext';
import MiniLoader from 'Components/MiniLoader/MiniLoader';
import { convertObjectToFormData, deleteEmptyObjectsField, useCallMSLApi } from "Functions";
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'

import Datalist from 'Components/Dataliste/Dataliste';

import ProductFields from './ProductFields';
import { CatalogueContext } from 'Context/Commercial/CatalogueContext';
import { useCheckVariation } from './Attributes/Variation/variationHelpers';
import { emptyProduct } from './productHelpers';
import { PopupContext } from 'Context/PopupContext';
import { CustomProductsContext } from 'Context/Commercial/CustomProductsContext';
import SelectOrganization from 'Components/_ERP/Brands/SelectOrganization';

export default function AddProduct({ catalogue = false, defautProduct = {} }) {

    const { addNotificationContent } = useContext(NotificationContext)
    const { organizations } = useContext(OrganizationsContext)
    const { catalogueProducts, setCatalogueProducts } = useContext(CatalogueContext)
    const { setCustomProducts } = useContext(CustomProductsContext)
    const { showPopup } = useContext(PopupContext)
    const [product, setProduct] = useState({ ...emptyProduct(catalogue), ...deleteEmptyObjectsField(defautProduct) })
    const [showForm, setShowForm] = useState(false)

    const callMSLApi = useCallMSLApi()
    const checkVariation = useCheckVariation();

    const setOrganizationId = (id) => {
        setProduct(product => ({
            ...product,
            organization_id: id
        }))
    }

    const setProductReferenceId = (id) => {
        const relatedProduct = catalogueProducts.find(product => product.id === id)
        relatedProduct && setProduct(product => {
            const packagings = relatedProduct && Array.isArray(relatedProduct.packagings)
                ? relatedProduct.packagings.map(packaging => ({ ...packaging, id: null, reference_id: packaging.id, use_reference: 1 }))
                : []
            const variationsValues = relatedProduct && Array.isArray(relatedProduct?.variation?.values)
                ? relatedProduct.variation.values.map(value => ({ ...value, id: null, reference_id: value.id, use_reference: 1 }))
                : []

            const options = relatedProduct && Array.isArray(relatedProduct.options)
                ? relatedProduct.options.map(option => ({ ...option, id: null, reference_id: option.id, use_reference: 1 }))
                : []

            return {
                ...product,
                ...relatedProduct,
                packagings: packagings,
                variation: {
                    ...relatedProduct.variation,
                    id: null,
                    product_id: null,
                    reference_id: relatedProduct.variation?.id,
                    use_reference: 1,
                    values: variationsValues
                },
                options: options,
                use_ref: {
                    option: 1,
                    packaging: 1,
                    description: 1,
                    ingredients: 1,
                    personnalisations: 1,
                    utilisation: 1,
                },
                catalogue: 0,
                organization_id: product.organization_id,
                reference_id: id,
                id: null,
            }
        })
    }

    const createProduct = e => {
        e.preventDefault()
        if (product.type == 2) {
            if (!checkVariation(product.variation)) {
                return
            }
        }
        if (product.id) {
            delete product.id
        }
        addNotificationContent({
            error: false,
            title: "création du produit en cours",
            content: <MiniLoader />,
            infinit: true,
        })
        const formData = convertObjectToFormData(product)
        const config = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
            }
        }

        callMSLApi(`product`, config, formData)
            .then(res => {
                if (res.data.success) {
                    addNotificationContent({
                        error: false,
                        title: "Succès",
                        content: "Le produit vient d'être créé",
                    })
                    showPopup(false)
                    res.data.product.catalogue && setCatalogueProducts(products => [res.data.product, ...products])
                    !res.data.product.catalogue && setCustomProducts(prvProducts => [res.data.product, ...prvProducts])
                } else {
                    addNotificationContent({
                        error: true,
                        title: "Erreur",
                        content: res.data.message,
                    })
                }
            })
            .catch(error => {
                console.log({ error });
                addNotificationContent({
                    error: true,
                    title: "Impossible de créer le produit",
                    content: error?.response?.data?.message ?? "essayer de recharger la page",
                })
            })
    }

    /* GESTION DE L'AFFICHAGE DU FORMULAIRE */
    useEffect(() => {
        if (!product.catalogue) {
            if (product.organization_id && product.reference_id) {
                setShowForm(true)
            } else {
                setShowForm(false)
            }
        } else {
            setShowForm(true)
        }
    }, [product.organization_id, product.reference_id])

    return (
        <>
            {catalogueProducts && catalogueProducts.length < 1 ? (
                <h5>Aucun produit actuellement disponible dans le catalogue</h5>
            ) : (
                <>
                    {!catalogue
                        ?
                        <div className='flex flex-center'>
                            {organizations ? (
                                <div className='flex flex-center input half-m-width'>
                                    {/* <Datalist
                                        placeholder="Organisation:"
                                        width='100%'
                                        items={organizations}
                                        onSelect={setOrganizationId}
                                    /> */}
                                    <SelectOrganization
                                        placeholder="Organisation:"
                                        width='100%'
                                        setter={setOrganizationId}
                                        updateOrganizationsFromPipedrive={true}
                                    />
                                </div>
                            ) : <MiniLoader />}
                            {console.log(catalogueProducts)}
                            {catalogueProducts.length ? (
                                <div className='flex flex-center input half-m-width'>
                                    <Datalist
                                        placeholder="Produit de référence:"
                                        width='100%'
                                        items={catalogueProducts.map(product => ({
                                            ...product,
                                            value: product.name
                                        }))
                                        }
                                        onSelect={setProductReferenceId}
                                    />
                                </div>
                            ) : <MiniLoader />}
                        </div>
                        : null
                    }
                </>
            )}
            {showForm && (
                <>
                    <h3 className='center'>Ajouter un produit{catalogue && (" au catalogue")}</h3>

                    <form id="sumit-product" onSubmit={e => createProduct(e)}>
                        <ProductFields product={product} setProduct={setProduct} />
                    </form>
                    <h4 className='center m-b-25'>Création</h4>
                    <div className='flex-center' style={{ maxWidth: "400px", margin: "0 auto 25px auto" }}>
                        <select value={product.state} onChange={e => setProduct(product => ({ ...product, state: e.target.value }))}>
                            <option value="0"> Brouillon </option>
                            <option value="1"> Publié</option>
                        </select>
                        <button form='sumit-product' className='strong-button center-block'>Créer le produit</button>
                    </div>
                </>
            )
            }
        </>
    )
}

