import React from 'react'

export default function ConsultParametersPS({ parameters }) {

    return (
        <div>
            <h4 className='m-0 m-b-10'>Paramètres</h4>
            <div>
                <div className='flex column gap-1 m-b-10'>
                    {
                        parameters.date
                            ? <div className='flex-start gap-1 '>
                                <div>Date : </div>
                                <div className='strong-2'>
                                    {new Date(parameters.date).toLocaleDateString()}
                                </div>
                            </div>
                            : null
                    }
                    {
                        parameters.produced_quantity || parameters.produced_quantity === 0 || parameters.produced_quantity === ''
                            ? <div className='flex-start gap-1 '>
                                <div>Quantité produite : </div>
                                <div className='strong-2'>
                                    {parameters.produced_quantity}
                                </div>
                            </div>
                            : null
                    }
                    {
                        parameters.quantity_to_produce || parameters.quantity_to_produce === 0 || parameters.quantity_to_produce === ''
                            ? <div className='flex-start gap-1 '  >
                                <div>Quantité à produire : </div>
                                <div className='strong-2'>
                                    {parameters.quantity_to_produce}
                                </div>
                            </div>
                            : null
                    }
                    {
                        parameters.marge_to_add || parameters.marge_to_add === 0 || parameters.marge_to_add === ''
                            ? <div className='flex-start gap-1 '  >
                                <div>Marge à ajouter : </div>
                                <div className='strong-2'>
                                    {parameters.marge_to_add}
                                </div>
                            </div>
                            : null
                    }
                </div>
                {parameters.comment || parameters.comment === ''
                    ? <div className='flex-1'>
                        <div>Commentaire : </div>
                        <div className='strong-2'>{parameters.comment}</div>
                    </div>
                    : null}
            </div>
        </div>
    )
}