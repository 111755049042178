import React, { useState } from 'react'

export default function Resultats({ userInfos }) {

    const [filteredResults, setFilteredResults] = useState(userInfos.user_analyses)

    const filter = (key, value) => {
        setFilteredResults(userInfos.user_analyses.filter(result => {
            return result[key].includes(value)
        }))
    }

    const bin2hex = (s) => {
        let i
        let l
        let o = ''
        let n
        s += ''
        for (i = 0, l = s.length; i < l; i++) {
            n = s.charCodeAt(i)
                .toString(16)
            o += n.length < 2 ? '0' + n : n
        }
        return o
    }

    const seeResults = (numReference, date) => {
        window.open(`https://labofrancaisduchanvre.com/resultatanalyse?id=${bin2hex(`numero_reference=${numReference}&date=${date}`)}`, '_blank').focus();
    }

    return (
        <>
            {filteredResults
                ? <div>
                    <h3>Résultats d'analyses</h3>
                    <div className='m-t-25 m-b-25'>
                        <input className='m-r-10' placeholder='Numéro de référence' onInput={e => filter('num_reference', e.target.value)} />
                        <input list='denomination-list' placeholder='Dénomination' onInput={e => filter('denomination', e.target.value)} />
                    </div>
                    {filteredResults.length
                        ? <table className='beautiful-array'>
                            <thead>
                                <tr>
                                    <th>N° de référence</th>
                                    <th>Dénomination</th>
                                    <th>N° de lot</th>
                                    <th>Date de réception</th>
                                    <th>Demandeur</th>
                                    <th>% de THC</th>
                                    <th>Certificat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredResults?.map((result, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{result.num_reference}</td>
                                            <td>{result.denomination}</td>
                                            <td>{result.num_lot}</td>
                                            <td>{result.date_reception}</td>
                                            <td>{result.demandeur}</td>
                                            <td>{result.THC}</td>
                                            <td>
                                                <button className='light-button' onClick={() => seeResults(result.num_reference, result.date)}>Télécharger</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : <h5>Aucuns résultats correspondants à votre recherche</h5>}
                </div>
                : <h5>Aucuns resultats d'analyses pour ce client</h5>
            }
        </>
    )
}
