import React, { useEffect, useState } from 'react';
import './customSelect.css';

/**
 * 
 * @param {Array} optionsList array like [{value: value, content: HTML content}] 
 * @returns 
 */
export default function CustomSelect({
    optionsList,
    onSelect,
    customParameters = [],
    defaultLabel = '',
    value = null,
    style = {},
    required = false,
}) {

    const [inputValue, setInputValue] = useState(<div>{defaultLabel}</div>)

    useEffect(() => {
        let found = false;

        optionsList?.forEach((option, index) => {
            if (value === option.value) {
                setInputValue(option.content);
                found = true;
            }
        });

        if (!found) {
            setInputValue(<div>{defaultLabel}</div>);
        }
    }, [value, optionsList]);

    return (
        <div className='custom-select-container'>
            <div
                tabIndex="1"
                style={style}
                className='custom-select-value'>
                {React.cloneElement(inputValue)}
            </div>
            <div className='custom-select-options'>
                <div
                    className='custom-select-option'
                    onClick={_ => onSelect && onSelect(...customParameters, "")}
                >
                    {defaultLabel}
                </div>
                {optionsList?.map((option, index) =>
                    <div
                        className={option.value === value ? 'custom-select-option selected' : 'custom-select-option'}
                        onClick={_ => onSelect && onSelect(...customParameters, option.value)}
                        key={index}>
                        {React.cloneElement(option.content, { key: option.key })}
                    </div>
                )}
            </div>
        </div>
    )

}