import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"

export default function IncludeProduct({ product, setter }) {
  const callApi = useCallApi()
  const { showPopup2, addPopupContent2 } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)

  const icludeProduct = (e) => {
    e.preventDefault()
    showPopup2(false)
    addNotificationContent({
      error: false,
      title: "Mise à jour en cours",
      content: <MiniLoader />,
      infinit: true,
    })
    const urlProducts = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}`
    callApi(urlProducts, { method: "put" }, null, { ignored: false }).then(
      (res) => {
        addNotificationContent({
          error: false,
          title: "Succès",
          content:
            "Le produit est à présent à prendre en compte pour la planification",
          infinit: false,
        })
        setter(res.data)
      }
    )
  }

  return (
    <>
      <h2>Inclure le produit :{product.name}</h2>
      <div className="flex">
        <p className="left" style={{ marginRight: "10px" }}>
          Attention, vous vous apprétez à réintégrer ce produit.
          <br />
          Ce dernier sera alors à prendre en compte pour la production
        </p>
        <form onSubmit={(e) => icludeProduct(e)}>
          <button className="erp-button erp-big-button error-strong-button">
            Réintégrer
          </button>
        </form>
      </div>
    </>
  )
}
