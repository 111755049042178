import React, { useState } from 'react'
import './ProductImages.css'

export default function ProductImages({ carrousel }) {

    const [mainImage, setMainImage] = useState(carrousel[0])

    return (
        <div className='flex flex-1 gap-1'>
            <div className='flex-start column gap-1'>
                {carrousel.map((imgSrc, index) => (
                    <img
                        className='carroussel-image'
                        key={index}
                        src={imgSrc}
                        onClick={() => setMainImage(imgSrc)}
                    />
                ))}
            </div>
            <div className='main-image-post'>
                <img
                    className='main-post-image'
                    src={mainImage}
                />
            </div>
        </div>
    )
}
