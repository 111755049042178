import { formatInputValue } from "Functions";
import React, { useContext, useState } from "react";
import SelectOrganization from "../../../../../Components/_ERP/Brands/SelectOrganization";
import { OrganizationsContext } from "Context/ERP/OrganizationsContext";

/**
 *
 * @param {Function} updateBrand permet de modifier les propriétés relatives à l'objet brand et prend 2 paramètres (la propriété et la valeur)
 * @param {Object} brand a quatre propriétés = (name, logo_url, organization_id, site_url)
 * @returns
 */
export default function BrandFields({ updateBrand, brand }) {
    const updateBrandOrganizationId = (organizationIdValue) => {
        updateBrand("organization_id", organizationIdValue);
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateBrand("logo_url", file);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div className="flex input">
                <label>Nom de la marque</label>
                <input
                    value={formatInputValue(brand.name)}
                    onInput={(e) => updateBrand("name", e.target.value)}
                ></input>
            </div>

            <div className="flex input">
                <label>Logo</label>
                <input type="file" accept="image/*" onChange={handleLogoChange} />
            </div>
            {brand.logo_url && (
                <div className="flex-end">
                    <img
                        src={brand.logo_url}
                        alt="Logo actuel"
                        style={{ height: 100, width: 100 }}
                    />
                </div>
            )}

            <SelectOrganization
                setter={updateBrandOrganizationId}
                defaultOrganizationId={brand.organization_id}
            />

            <div className="flex input">
                <label>Site internet</label>
                <input
                    value={formatInputValue(brand.site_url)}
                    onInput={(e) => updateBrand("site_url", e.target.value)}
                ></input>
            </div>
        </div>
    );
}
