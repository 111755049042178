import { NotificationContext } from 'Context/NotificationContext'
import React, { useContext } from 'react'

export const emptyVariationValue = {
    value: '',
    description: '',
    price_category_id: null,
    min_price: 0,
    max_price: 0,
    min_price_qty: 0,
    min_qty: 0,
    use_reference: false,
}

export const emptyVariation = {
    name: '',
    description: '',
    type: 0,
    values: [emptyVariationValue]
}

export const useCheckVariation = () => {
    const { addNotificationContent } = useContext(NotificationContext)

    const checkVariation = (variation) => {
        let valuesOk = true;
        if (variation.name && variation.values && variation.values.length > 0) {
            variation.values.forEach(v =>
                (
                    v.value === ""
                    || !v.price_category_id
                ) && (valuesOk = false)
            )
            if (!valuesOk) {
                addNotificationContent({
                    title: 'Attention',
                    error: true,
                    content: 'Un des choix de votre variation n\'est pas valide'
                })
                return false
            } else {
                return true
            }
        } else {
            addNotificationContent({
                title: 'Attention',
                error: true,
                content: 'Votre variation n\'est pas valide'
            })
            return false
        }
    }
    return checkVariation
}