import { useCallApi, formatFormDataFromObject } from "Functions";
import { appConstante } from "appConstante";
import { NotificationContext } from "Context/NotificationContext";
import React, { useState, useContext } from "react";
import BrandFields from "./Shared/BrandFields";
import MiniLoader from "Components/MiniLoader/MiniLoader";
import { PopupContext } from "Context/PopupContext";

export default function AddBrands({ addBrand }) {
    const [brand, setBrand] = useState({});
    const callApi = useCallApi();
    const { addNotificationContent } = useContext(NotificationContext);
    const { showPopup } = useContext(PopupContext);

    const updateBrand = (property, value) => {
        setBrand({ ...brand, [property]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        showPopup(false);

        addNotificationContent({
            title: "Ajout en cours",
            content: <MiniLoader />,
        });

        const uri = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/brand`;
        const options = {
            method: "post",
        };
        const formatedBrand = formatFormDataFromObject(brand)

        callApi(uri, options, null, formatedBrand, event)
            .then((response) => {
                addBrand(response.data.data);
                addNotificationContent({
                    title: "Succès",
                    error: false,
                    content: "Une nouvelle marque a été ajoutée",
                    infinit: false,
                });
            })
            .catch((error) => {
                console.log(error);
                addNotificationContent({
                    title: "Erreur",
                    error: false,
                    content: "Une erreur s'est produite",
                    infinit: false,
                });
            });
    };

    return (
        <>
            <h2>Ajouter une marque</h2>
            <form className="m-t-25" onSubmit={handleSubmit}>
                <BrandFields updateBrand={updateBrand} brand={brand} />
                <button className="strong-button m-b-25">Ajouter la marque</button>
            </form>
        </>
    );
}
