import React, { useState, useEffect, useContext } from "react"
import { appConstante, formatDealProductStatus } from "appConstante"
import { useCallApi, useCallPipedriveApi } from "Functions"
import { BulkContext } from "Context/CBDProtect/Bulk"
import { BatchContext } from "Context/CBDProtect/Batch"
import { ProductSpecificationContext } from "Context/ERP/ProductSpecificationContext"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import { emptyDeliverySheetItem } from "AppContainer/ERP/ERPConstantes"
import "./createDeliverySheet.css"
import DeliverySheet from "./DeliverySheet"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import set from 'lodash/set.js';
import DeliverySheetArticleItem from "./DeliverySheetArticleItem"
import DeliverySheetData from "./DeliverySheetData"

export default function CreateDeliverySheet({ dealId, passedDeliverySheet, updateDL }) {

  const callPipedriveApi = useCallPipedriveApi()
  const callApi = useCallApi()
  const { getBulk, formatBulkName } = useContext(BulkContext)
  const { getBatch, formatBatchName } = useContext(BatchContext)
  const { getProductSpecification } = useContext(ProductSpecificationContext)

  const [dealProducts, setDealProducts] = useState(null)
  const [formatedDealProducts, setFormatedDealProducts] = useState(null)

  // Pamarètres du formulaire
  const today = new Date()
  const [deliverySheet, setDeliverySheet] = useState(
    passedDeliverySheet
      ? { ...passedDeliverySheet }
      : {
        deal_id: dealId,
        date: today.toISOString().split("T")[0],
        type_of_mp: 'NON BIO',
        operator: "",
        products: [],
        parameters: {
          version: 2,
          adress: "",
          "cardboard-number": 1,
          "bio-or-not": { conformity: "Conforme", comment: "" },
        }
      }
  )

  useEffect(() => {
    if (deliverySheet) {
      const controller = new AbortController()
      callPipedriveApi(`deals/${deliverySheet.deal_id}/products`, {
        signal: controller.signal,
      }).then((res) => {
        setDealProducts(res.data.data)
      })
      return () => {
        controller.abort()
      }
    }
  }, [passedDeliverySheet, dealId])

  const updateDeliverySheet = (keyPath, value) => {
    setDeliverySheet(prvDS => {
      const newDS = prvDS
      set(newDS, keyPath, value);
      return { ...newDS };
    })
  }

  /** ***************** produt PIPEDRIVE FROM ERP ****************** */
  useEffect(() => {
    const controller = new AbortController()
    const loadData = async () => {
      const productsFromERP = await Promise.all(dealProducts.map((product) => addErpInfosToProducts(product, controller.signal)))
      const productsFromERPWithSpef = await Promise.all(productsFromERP.map(async (product) => {
        if (product.erp?.product_specification_id) {
          const product_specification = await getProductSpecification(product.erp.product_specification_id)
          product.erp.product_specification = product_specification
        }
        return product
      }))
      setFormatedDealProducts(productsFromERPWithSpef)
    }

    if (dealProducts) {
      loadData()
    }

    return () => {
      controller.abort()
    }
  }, [dealProducts])

  const addErpInfosToProducts = (product, signal) =>
    new Promise((resolve, reject) => {
      callApi(
        `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}`
      ).then((res) => {
        if (res.status !== 204) {
          resolve({ ...product, erp: res.data.data })
        } else {
          callApi(
            `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product`,
            { method: "post", signal },
            null,
            {
              id: product.id,
              deal_id: deliverySheet.deal_id,
              in_stock_quantity: 0,
              status: "to_plan",
              ignored: false,
            }
          )
          resolve({
            ...product,
            erp: {
              shipped_qty: 0,
              qty_ready_to_send: 0,
              status: "to_plan",
              ignored: false,
            },
          })
        }
      })
    })

  /** ************************************************************ */

  /* Context */
  const { addPopupContent } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)

  // FORMAT PRODUCTS FORM BASED ON ERP AND PIPEDRIVE
  useEffect(() => {
    if (formatedDealProducts && dealProducts) {
      formatProducts()
    }
  }, [formatedDealProducts, dealProducts])

  const formatProducts = async () => {
    let formatedProducts = await Promise.all(
      formatedDealProducts.map(async product => {
        const bulks = await Promise.all(product.erp?.bulk_id.map(bulkId => getBulk(bulkId)))
        const batchs = await Promise.all(product.erp?.batch_id.map(batchId => getBatch(batchId)))


        const formatedProductItem = {
          ignored: product.erp?.ignored,
          product_id: product.id,
          status: formatDealProductStatus(product.erp?.status),
          quantity: 0,
          qty_ready_to_send: product.erp?.qty_ready_to_send,
          shipped_qty: product.erp?.shipped_qty,
          prv_shipped_qty: 0,
          deal_quantity: product.quantity,
          comments: product.comments,
          bl_comment: '',
          batchs: [{ id: null, quantity: 0, }],
          sum_quantity: 0,
          bulks_names: bulks.map(bulk => formatBulkName(bulk)),
          batchs_names: batchs.map(batch => formatBatchName(batch)),
          pipedrive_name: product.name,
          name: product.erp?.product_specification?.article?.name ?? null,
          suppliers: product.erp?.product_specification?.article?.products ?? null,
        }

        if (passedDeliverySheet) {
          const passedDeliverySheetProduct = passedDeliverySheet.products?.find(p => p.product_id === product.id)
          const prv_quantity = passedDeliverySheetProduct?.batchs?.reduce((acc, val) => val.quantity + acc, 0)
          passedDeliverySheetProduct && (passedDeliverySheetProduct.shipped_qty = product.erp?.shipped_qty - prv_quantity)
          passedDeliverySheetProduct && (passedDeliverySheetProduct.prv_shipped_qty = product.erp?.shipped_qty - prv_quantity)

          return ({
            ...formatedProductItem,
            ...passedDeliverySheetProduct,
            ignored: passedDeliverySheetProduct ? false : true,
            isUpdating: true,
          })
        }
        else {
          return formatedProductItem
        }
      }))

    updateDeliverySheet('products', formatedProducts)
    updateDeliverySheet('isLoaded', true)
  }


  const sendDeliverySheet = (e) => {
    e.preventDefault()
    const deliverySheetProducts = deliverySheet.products
      .filter((product) => !product.ignored && product.batchs?.filter(batch => batch.id)?.length)
      .map((product) => {

        const toSheepQuantity = product.deal_quantity - (product.quantity + product.shipped_qty)

        return {
          product_id: product.product_id,
          batchs: product.batchs.map(batch => ({
            ...batch,
            id: parseFloat(batch.id),
            quantity: parseFloat(batch.quantity)
          })),
          name: product.name,
          bl_comment: product.bl_comment,
          quantity: product.quantity,
          deal_quantity: product.deal_quantity,
          to_sheep_quantity: toSheepQuantity < 0 ? 0 : toSheepQuantity,
        }
      })

    if (!deliverySheet.parameters.adress) {
      addNotificationContent({
        error: true,
        title: "Erreur",
        content: "Adresse invalide !",
        infinit: false,
      })
    } else {
      addNotificationContent({
        error: false,
        title: "Création du bon de livraison...",
        content: <MiniLoader />,
        infinit: true,
      }),

        callApi(passedDeliverySheet
          ? `${appConstante.servers.PANORAMA_ENDPOINT}/erp/delivery-sheet/${deliverySheet.id}`
          : `${appConstante.servers.PANORAMA_ENDPOINT}/erp/delivery-sheet`,
          { method: passedDeliverySheet ? "put" : "post" },
          null,
          { ...deliverySheet, products: deliverySheetProducts })
          .then((res) => {
            addNotificationContent({
              error: false,
              title: "Succès",
              content: `Votre bon de livraison à bien été ${passedDeliverySheet ? 'modifié' : 'généré'}`,
              infinit: false,
            })
            updateDL && updateDL({ ...passedDeliverySheet, ...res.data.data })
            addPopupContent(<DeliverySheet deliverySheet={res.data.data} />)
          })
          .catch((error) => {
            addNotificationContent({
              error: true,
              title: "Erreur",
              content: `Une erreur s\'est produite (${error.response.message})`,
              infinit: false,
            })
          })
    }
  }

  const addProduct = () => {
    setDeliverySheet(prvDS => ({ ...prvDS, products: [...prvDS.products, { ...emptyDeliverySheetItem }] }))
  }

  const setProduct = (newProduct) => {
    if (newProduct.index || newProduct.index === 0) {
      updateDeliverySheet(`products[${newProduct.index}]`, newProduct)
    }
  }

  return (
    <>
      {deliverySheet
        ? <form onSubmit={(e) => sendDeliverySheet(e)}>
          {passedDeliverySheet
            ? <h2> Modifier un bon de livraison pour le devis n° {deliverySheet.deal_id}</h2>
            : <h2> Générer un bon de livraison pour le devis n° {deliverySheet.deal_id}</h2>
          }
          <div className="center full-width">
            <div className="p-0">
              {deliverySheet?.products.length && deliverySheet.isLoaded
                ? <>
                  {deliverySheet.products.map((product, index) => (
                    <div key={product.product_id}>
                      <DeliverySheetArticleItem
                        passedProduct={{ ...product, index: index }}
                        setter={setProduct}
                      />
                    </div>
                  ))}
                  <button type="button" onClick={addProduct} className="strong-button m-b-10">Ajouter un autre article</button>
                </>
                : <MiniLoader />}
            </div>
          </div>
          <DeliverySheetData
            deliverySheet={deliverySheet}
            setter={updateDeliverySheet}
          />
          {passedDeliverySheet
            ? <button className="strong-button m-t-10">
              Modifier le bon de livraison
            </button>
            : <button className="strong-button m-t-10">
              Générer le bon de livraison
            </button>
          }
        </form>
        : null
      }
    </>
  )
}
