import React from 'react';
import PropTypes from 'prop-types';
import './skeleton.css'

const Skeleton = ({ width, height }) => {
    const skeletonStyle = {
        position: 'relative',
        width: width,
        height: height,
        backgroundColor: 'rgb(215,215,215)', // Fond gris clair classique
        borderRadius: '4px', // Arrondi pour un look plus esthétique
        marginBottom: '10px', // Espacement entre chaque Skeleton
        overflow: 'hidden',
    };

    const highlightStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 55%, rgba(255, 255, 255, 0) 70%)',
        animation: 'shimmer 3s infinite',
    };

    return (
        <div style={skeletonStyle}>
            <div style={highlightStyle}></div>
        </div>
    );
};

Skeleton.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Skeleton.defaultProps = {
    width: '100%', // Taille par défaut à 100%
    height: '20px', // Hauteur par défaut à 20px
};

export default Skeleton;
