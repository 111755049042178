import React, { useState, useContext, useEffect, useRef } from "react"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { appConstante } from "appConstante"
import { formatDateTimeToInput, formatInputValue, useCallApi } from "Functions"
import { PlanningContext } from "Context/ERP/PlanningContext"
import { useCurrentAppPermission } from 'Functions'
import './validedSession.css'
import CreateProductionSheets from "AppContainer/ERP/SpecificationsAndRecipe/ProductionSheets/CreateProductionSheets"

export default function ValidedSession({ session, showCreatePS = true, updateSessionFunction, deleteSessionFunction, openInfos = false }) {

    const isValidSession = (session.state === 1) || (session.state && session.state.value === 1)

    const currentAppPermission = useCurrentAppPermission()
    const { addPopupContent2 } = useContext(PopupContext)
    const [showSessionInfos, setShowSessionInfos] = useState(!isValidSession || openInfos)
    const { getStep } = useContext(PlanningContext)

    const showConfirmRemoveSession = () => {
        addPopupContent2(
            <ConfirmRemoveSession deleteSessionFunction={deleteSessionFunction} session={session} />
        )
    }

    const showUpdateEvent = () => {
        addPopupContent2(
            <UpdateSessionContent updateSessionFunction={updateSessionFunction} step={step} session={session} />
        )
    }

    const [step, setStep] = useState(session.step)

    useEffect(() => {
        getStepFromSession(session)
    }, [session.step])

    const getStepFromSession = async (session) => {
        if (session.step) {
            setStep(_ => session.step)
        }
        else {
            const step = await getStep(session.step_id)
            setStep(step)
        }
    }

    const sectionClassName = `session-section ${showSessionInfos ? 'session-section-shown' : ''}`

    const openProductionSheetInNewTab = (id) => {
        window.open(`${window.location.origin}/erp/specif-and-recipe/production-sheets/consult/${id}`, '_blank');
    }

    const validSession = () => {
        if (session === {}) {
            return false
        }
        return true
    }
    if (validSession()) {
        const headerStyle = isValidSession
            ? { backgroundColor: '#c3c6dd' }
            : { backgroundColor: '#e07576' }

        return (
            <div className={sectionClassName}>
                <div
                    style={headerStyle}
                    className="flex session-header"
                >
                    {deleteSessionFunction
                        ? (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                            <button
                                onClick={() => {
                                    showConfirmRemoveSession()
                                }}
                                className="erp-close-button step-remove"
                            >
                                &#x274C;
                            </button>)
                        : null
                    }
                    <div
                        className="session-title"
                        onClick={() => setShowSessionInfos(!showSessionInfos)}
                    >
                        <div className="main-session-title">
                            {
                                step
                                    ? `${step.name} (${session.real_quantity ? `${session.real_quantity} / ${session.indicative_quantity}` : `0 / ${session.indicative_quantity}`})`
                                    : "Erreur sur l'étape associée"
                            }
                            {session.lock && <span>🔒</span>}
                        </div>
                        {session.product && session.product.name
                            ? <div className="secondary-session-title"
                            >{session.product.name}</div>
                            : "Erreur sur le produit associée"
                        }
                    </div>
                    {
                        updateSessionFunction
                            ? (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                                <button
                                    onClick={() => {
                                        showUpdateEvent()
                                    }}
                                    className="erp-close-button step-edit"
                                >
                                    &#128393;
                                </button>)
                            : null
                    }
                </div>

                <div onClick={() => setShowSessionInfos(!showSessionInfos)} className="pointer center show-details">{showSessionInfos ? 'Réduire' : 'Détail...'}</div>
                {session.pre_production_comment && (
                    <div
                        className="center"
                        style={{ fontSize: "16px", padding: "20px 0px 15px" }}
                    >
                        <span className="strong-2">Commentaire pré production: </span>
                        {session.pre_production_comment}
                    </div>
                )}
                <table className="m-t-10 beautiful-array">
                    <thead>
                        <tr>
                            <th>
                                {session.real_quantity ? "Quantité" : "Quantité souhaité"}
                            </th>
                            <th>Début</th>
                            <th>Fin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {session.real_quantity
                                    ? `${session.indicative_quantity} planifiées / ${session.real_quantity} réalisées`
                                    : session.indicative_quantity}
                            </td>
                            <td>
                                {new Date(session.start).toLocaleDateString("fr")}
                                <br />
                                {new Date(session.start).toLocaleTimeString("fr")}
                            </td>
                            <td>
                                {new Date(session.end).toLocaleDateString("fr")}
                                <br />
                                {new Date(session.end).toLocaleTimeString("fr")}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {session.post_production_comment && (
                    <div
                        className="center"
                        style={{ fontSize: "16px", padding: "20px 0px 15px" }}
                    >
                        <span className="strong-2">Commentaire post production: </span>
                        {session.post_production_comment}
                    </div>
                )}
                {session.production_sheets
                    ? <>
                        {session.production_sheets.map((ps, index) => {
                            return <button key={index} className="light-button m-t-10 full-width" onClick={() => openProductionSheetInNewTab(ps.id)} >Voir la fiche de production {ps.id}</button>
                        })}
                    </>
                    : null
                }

                {!session.real_quantity && showCreatePS
                    ? <button
                        onClick={() => {
                            addPopupContent2(
                                <CreateProductionSheets
                                    sessionId={session.id}
                                />
                            )
                        }}
                        className="light-button center-block m-b-10"
                    >
                        Créer une fiche de production
                    </button>
                    : null}
            </div>
        )
    }
    return <h5>Session invalide</h5>
}

function ConfirmRemoveSession({ session, deleteSessionFunction }) {
    const callApi = useCallApi()
    const { showPopup2 } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { deleteSession } = useContext(PlanningContext)

    const deleteCurrentFunction = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            title: "Supression de la session en cours",
            content: <MiniLoader />,
            infinit: true,
        })
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${session.id}`, {
            method: "delete",
        })
            .then(() => {
                deleteSession(session.id)
                deleteSessionFunction(session.id)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: "Supresion de la session confirmé",
                    infinit: false,
                })
            })
            .catch((error) => {
                console.log({ error });
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: "La supresion de la session n'a pas abouti",
                    infinit: false,
                })
            })
        showPopup2(false)
    }
    return (
        <>
            <p>Etes vous sur de vouloir suppimer cette session de production ?</p>
            {/* <input value={session.id} hidden /> */}
            <div className="flex">
                <button
                    onClick={() => {
                        showPopup2(false)
                    }}
                    className="erp-button erp-big-button"
                >
                    Non
                </button>
                <button
                    onClick={(e) => deleteCurrentFunction(e)}
                    className="erp-button erp-big-button error-strong-button"
                >
                    Oui
                </button>
            </div>
        </>
    )
}

export function UpdateSessionContent({ session, step, updateSessionFunction }) {
    const callApi = useCallApi()
    const { showPopup2 } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const [newSession, setNewSession] = useState(session)

    const { updateSession, updatePlanningStep } = useContext(PlanningContext)

    const updateNewSessionObj = (key, value) => {
        setNewSession({ ...newSession, [key]: value })
    }

    const updateStart = (start) => {
        updateNewSessionObj("start", start)
        if (new Date(start).getTime() > new Date(newSession.end).getTime()) {
            updateNewSessionObj("end", start)
        }
    }

    const updateCurrentSession = (e) => {
        e.preventDefault()
        showPopup2(false)
        delete newSession.extendedProps
        delete newSession.product
        delete newSession.step
        callApi(
            `${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${newSession.id}`,
            { method: "put" },
            null,
            newSession
        )
            .then((res) => {
                updateSession(res.data.data)
                updateSessionFunction(res.data.data)
                updatePlanningStep(res.data.data.step)
            })
            .catch(error => {
                console.log({ error });
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: "La mise à jour de la session n'a pas abouti",
                    infinit: false,
                })
            })
    }

    console.log(step);
    return (
        <>
            <h2>Modifier la session de production de l'étape {step?.name}</h2>
            <form onSubmit={(e) => updateCurrentSession(e)}>
                <div style={{ maxWidth: "500px", margin: "30px auto" }}>
                    <div className="flex input left">
                        <label>
                            Quantité souhaité
                            <br />
                            pour cette session:
                        </label>
                        <input
                            type="number"
                            value={formatInputValue(newSession.indicative_quantity)}
                            onInput={(e) => {
                                updateNewSessionObj(
                                    "indicative_quantity",
                                    parseFloat(e.target.value)
                                )
                            }}
                            required
                        />
                    </div>
                    {newSession.real_quantity !== null
                        ? (
                            <div className="flex input left">
                                <label>
                                    Quantité réel
                                    <br />
                                    produite:
                                </label>
                                <input
                                    type="number"
                                    value={formatInputValue(newSession.real_quantity)}
                                    onInput={(e) => {
                                        updateNewSessionObj(
                                            "real_quantity",
                                            parseFloat(e.target.value)
                                        )
                                    }}
                                />
                            </div>
                        )
                        : null}
                    <div className="flex input">
                        <label>Date de début:</label>
                        <input
                            type="datetime-local"
                            step="1800"
                            onInput={(e) => updateStart(e.target.value)}
                            value={formatDateTimeToInput(newSession.start)}
                            required
                        />
                    </div>
                    <div className="flex input">
                        <label>Date de fin:</label>
                        <input
                            type="datetime-local"
                            step="1800"
                            onInput={(e) => updateNewSessionObj("end", e.target.value)}
                            value={formatDateTimeToInput(newSession.end)}
                            required
                        />
                    </div>
                    <div className="flex input">
                        <label>Commentaire pré-production:</label>
                        <input
                            type="textarea"
                            value={formatInputValue(newSession.pre_production_comment)}
                            onInput={(e) => {
                                updateNewSessionObj("pre_production_comment", e.target.value)
                            }}
                        />
                    </div>
                    {newSession.real_quantity !== null
                        ? <div className="flex input">
                            <label>Commentaire de post production:</label>
                            <input
                                type="textarea"
                                value={formatInputValue(newSession.post_production_comment)}
                                onInput={(e) => {
                                    updateNewSessionObj("post_production_comment", e.target.value)
                                }}
                            />
                        </div>
                        : null
                    }
                    <div className="flex input">
                        <label
                            className="flex"
                            style={{ width: "100%", alignItems: "center" }}
                        >
                            <div>Interdire la modification pour la production:</div>
                            <input
                                type="checkbox"
                                className="erp-check"
                                checked={newSession.lock}
                                onChange={(e) => {
                                    updateNewSessionObj("lock", e.target.checked)
                                }}
                            />
                        </label>
                    </div>
                </div>
                <div className="flex">
                    <button
                        type="button"
                        onClick={() => {
                            showPopup2(false)
                        }}
                        className="error-light-button center-block"
                    >
                        Annuler
                    </button>
                    <button className="light-button center-block">Mettre à jour</button>
                </div>
            </form>
        </>
    )
}
