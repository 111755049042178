import React, { useState, useContext, useEffect } from 'react'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import ProductFields from './Shared/ProductFields'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { useCallApi, formatFormDataFromObject, useCurrentAppPermission } from 'Functions'

/**
 * 
 * @param {article} article 'article parent au produit à mettre à jour'
 * @param {product} product 'produit à mettre à jour'
 * @param {function} setter 'fonction permettant de mettre à jour le produit via le front'
 * @returns component pour la mise à jour d'un article
 */
export default function UpdateProduct({ article, product, setter, column = false }) {

    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)
    const currentAppPermission = useCurrentAppPermission()
    const isDisabled = currentAppPermission('erp') <= appConstante.parameters.CAN_EDIT

    const [newProduct, setNewProduct] = useState(product)

    useEffect(() => {
        newProduct && newProduct.archive &&
            addNotificationContent({
                error: true,
                title: 'Attention !',
                content: "Lors de l'achivage d'un fournisseur, son stock n'est plus pris en compte dans le total du stock d'un article",
                infinit: false,
            })
    }, [newProduct])

    const updateProduct = (key, value) => {
        setNewProduct({ ...newProduct, [key]: value })
    }

    const sendNewProduct = e => {
        e.preventDefault();
        addNotificationContent({
            error: false,
            title: "Mise à jour du fournisseur...",
            content: <MiniLoader />,
            infinit: true
        })

        const formatedUpdatedProduct = formatFormDataFromObject({ ...product, ...newProduct });

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product/${product.id}`, { method: 'put' }, null, formatedUpdatedProduct)
            .then(res => {
                setter(article)
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: `Le fournisseur ${res.data.data.supplier} à été mis à jour`,
                    infinit: false
                })
            })
            .catch(error => {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: error.data ? error.data.message : "une erreur à été rencontré",
                    infinit: false
                })
            })
        e.preventDefault();
    }

    return (
        <>
            {column
                ? null
                : <h2>Ajouter un fournisseur à l'article {article.name}</h2>
            }
            <form onSubmit={e => sendNewProduct(e)}>
                <ProductFields product={newProduct} setter={updateProduct} column={column} />
                <label className='flex-start m-t-10' style={{ cursor: 'pointer' }}>
                    <div style={{ color: 'red' }}>Archiver</div>
                    <input type='checkbox'
                        checked={newProduct.archive}
                        onChange={e => updateProduct("archive", e.target.checked)}
                        disabled={isDisabled}
                    />
                </label>
                {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                    ? <button className='strong-button m-t-10'>Mettre à jour</button>
                    : null}
            </form>
        </>
    )
}
