import React, { createContext, useState, useEffect, useContext } from "react"
import { useCallCBDPApi } from "Functions"
import { NotificationContext } from 'Context/NotificationContext'
import { ArticleContext } from "Context/ERP/ArticleContext"

export const BatchContext = createContext()
export default function BatchContextProvider(props) {
    const callCBDPApi = useCallCBDPApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const { getArticle } = useContext(ArticleContext)
    const { articles } = useContext(ArticleContext)

    const [batchs, setBatchs] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        if (articles) {
            getBatchs(controller.signal)
        }

        return () => {
            controller.abort()
        }
    }, [articles])

    /************************************************************/
    /************************* API CALLS ************************/

    const getBatchs = (signal = null, data = null) => {
        setBatchs(_ => null)
        callCBDPApi('batch', { method: 'get', signal: signal }, data)
            .then((res) => {
                Promise.all(res.data.map(async batch => {
                    try {
                        const article = await getArticle(batch.article_id, 'raw')
                        return { ...batch, article: article }
                    } catch (error) {
                        return { ...batch, article: "Erreur sur l'article correspondant" }
                    }
                }))
                    .then(batchsWithArticles => {
                        setBatchs(_ => batchsWithArticles)
                    })
            })
            .catch((error) => console.log(error))
    }


    const getBatchsV2 = async (currentPage, controller) => {
        const res = await callCBDPApi('v2/batch', { method: 'get', signal: controller.signal }, { page: currentPage, limit: 10 })

        const formatedBatchs = await Promise.all(res.data.data.map(async batch => {
            try {
                const article = await getArticle(batch.article_id, 'raw')
                return { ...batch, article: article }
            } catch (error) {
                return { ...batch, article: "Erreur sur l'article correspondant" }
            }
        }))

        return { ...res.data, data: formatedBatchs }
    }

    const CBDPpostBatch = (batch) => {
        return new Promise((resolve, reject) => {
            callCBDPApi('batch', { method: 'post' }, batch)
                .then(res => {
                    addNotificationContent({
                        content: `Le batch n°${res.data.object.visible_id} vient d'être crée`
                    })
                    setBatchs(prvBatchs => [res.data.object, ...prvBatchs])
                    resolve(res.data.object)
                })
                .catch(err => {
                    console.log(err)
                    addNotificationContent({
                        error: true,
                        content: "Erreur lors de la création du numéro de lot"
                    })
                    reject(err)
                })
        })
    }

    const getBatch = (id) => {
        return new Promise((resolve, reject) => {
            const batchFromId = batchs?.find(batch => parseInt(batch.ID) === id)
            if (batchFromId) {
                resolve(batchFromId)
            } else {
                callCBDPApi(
                    `batch/${id}`, { method: "get" }, null)
                    .then(res => {
                        resolve(res.data)
                        setBatchs(prvBatchs => [res.data, ...prvBatchs ? prvBatchs : []])
                    })
                    .catch(error => {
                        console.log(`erreur récupération /batch/${id}`, error)
                        reject(error)
                    })
            }
        })
    }

    const getBatchVisibleID = async (signal = null, data = null) => {
        const res = await callCBDPApi('batch', { method: 'get', signal: signal }, data)

        const formatedBatchs = await Promise.all(res.data.map(async batch => {
            try {
                const article = await getArticle(batch.article_id, 'raw')
                return { ...batch, article: article }
            } catch (error) {
                return { ...batch, article: "Erreur sur l'article correspondant" }
            }
        }))

        return formatedBatchs
    }

    /************************************************************/
    /************************** HELPERS *************************/

    const formatBatchName = (batch) => {
        return `${batch.visible_id} - ${batch.article?.name}`
    }

    return (
        <BatchContext.Provider value={{ batchs, getBatchs, getBatchsV2, getBatch, getBatchVisibleID, CBDPpostBatch, formatBatchName }}>
            {props.children}
        </BatchContext.Provider>
    )
}
