import React, { useState, useContext, useEffect } from "react"
import { PopupContext } from "Context/PopupContext"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"
import { NotificationContext } from "Context/NotificationContext"
import { PlanningContext } from "Context/ERP/PlanningContext"
import { useCurrentAppPermission } from 'Functions'
import './validedSteps.css'
import MiniLoader from "Components/MiniLoader/MiniLoader"
import Skeleton from "Components/Skeleton/Skeleton"

export default function ValidedStep({ stepId, updateStepFunction, deleteStepFunction, openInfos = false }) {

  const currentAppPermission = useCurrentAppPermission()

  /* CONTEXT */
  const { addPopupContent2 } = useContext(PopupContext)
  const { getStep, updatePlanningStep } = useContext(PlanningContext)


  const [step, setStep] = useState(null)
  const [showStepInfos, setShowStepInfos] = useState(false)


  const isValidSession = step && step.todo_quantity <= step.plan_quantity

  useEffect(() => {
    const loadStep = async () => {
      const step = await getStep(stepId)
      setStep(step)
    }
    loadStep(stepId)
  }, [])

  useEffect(() => {
    if (step) {
      setShowStepInfos(!isValidSession || openInfos)
    }
  }, [step])

  const confirmRemoveStep = () => {
    addPopupContent2(
      <ConfirmRemoveStep step={step} deleteStepFunction={deleteStepFunction} />
    )
  }

  const updateStep = (step) => {
    updatePlanningStep(step)
    updateStepFunction(step)
    setStep(step)
  }

  const showUpdateStep = () => {
    addPopupContent2(
      <UpdateStep updateStepFunction={updateStep} step={step} />
    )
  }

  const headerStyle = isValidSession
    ? { backgroundColor: '#c3c6dd' }
    : { backgroundColor: '#e07576' }

  const stepClassName = `step-section ${showStepInfos ? 'step-infos-shown' : ''}`

  return (
    step
      ? <div className={stepClassName}>
        <div
          style={headerStyle}
          className="step-header"

        >
          {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
            <button
              onClick={() => {
                confirmRemoveStep()
              }}
              className="erp-close-button step-remove"
            >
              &#x274C;
            </button>
          }
          <div
            className="step-title"
            onClick={() => setShowStepInfos(!showStepInfos)}
          >{step.name}</div>
          {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
            <button
              onClick={() => {
                showUpdateStep()
              }}
              className="erp-close-button step-edit"
            >
              &#128393;
            </button>
          }
        </div>
        <div onClick={() => setShowStepInfos(!showStepInfos)} className="pointer center show-details">{showStepInfos ? 'Réduire' : 'Détail...'}</div>
        <table className="beautiful-array step-infos">
          <thead>
            <tr>
              <th>Quantité total</th>
              <th>
                Quantité restante
                <br />à planifier
              </th>
              <th>Quantité déjà planifiée</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{step.todo_quantity}</td>
              <td>
                {step.todo_quantity - step.plan_quantity >= 0
                  ? step.todo_quantity - step.plan_quantity
                  : 0}
              </td>
              <td>{step.plan_quantity ? step.plan_quantity : 0}</td>
            </tr>
          </tbody>
        </table>
        <div>{step.comment && `Remarque: ${step.comment}`}</div>
      </div>
      : <Skeleton width='100%' height={'75px'} />
  )
}

function ConfirmRemoveStep({ step, deleteStepFunction }) {
  const callApi = useCallApi()
  const { showPopup2 } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)
  const { deletePlanningStep } = useContext(PlanningContext)

  const deleteCurrentStep = (e) => {
    e.preventDefault()
    showPopup2(false)
    addNotificationContent({
      title: "Suppression de l'étape en cours",
      content: <MiniLoader />,
      infinit: true,
    })
    callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/step/${step.id}`, { method: "delete" })
      .then(() => {
        deleteStepFunction(step.id)
        deletePlanningStep(step.id)
        addNotificationContent({
          content: "Suppression réussi",
        })
      })
      .catch(error => {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: "La supression de l'étape n'a pas abouti",
          infinit: false,
        })
      })
  }

  return (
    <>
      <p>
        Etes vous sur de vouloir suppimer l'étape{" "}
        <span className="strong-2">{step.name}</span> ?
      </p>
      <p>
        La suppression de cette dernière entrainera également la suppression de
        la session de production et toutes les sessions qui lui sont associées
      </p>
      {/* <input value={step.id} hidden /> */}
      <div className="flex">
        <button
          onClick={() => {
            showPopup2(false)
          }}
          className="erp-button erp-big-button"
        >
          Non
        </button>
        <button
          onClick={(e) => {
            deleteCurrentStep(e)
          }}
          className="erp-button erp-big-button error-strong-button"
        >
          Oui
        </button>
      </div>
    </>
  )
}

function UpdateStep({ step, updateStepFunction }) {
  const callApi = useCallApi()
  const { showPopup2 } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)
  const [newStep, setNewStep] = useState(step)

  const updateNewStep = (key, value) => {
    setNewStep({ ...newStep, [key]: value })
  }

  const updateCurrentStep = (e) => {
    e.preventDefault()
    showPopup2(false)
    const stepToSend = { ...newStep }
    delete stepToSend.sessions
    delete stepToSend.other_steps
    delete stepToSend.product
    addNotificationContent({
      title: "Mise à jour de l'étape en cours",
      content: <MiniLoader />,
      infinit: true,
    })
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/step/${step.id}`,
      { method: "put" },
      null,
      stepToSend
    )
      .then((res) => {
        updateStepFunction(res.data.data)
        addNotificationContent({
          content: "Mise à jour réussi",
        })
      })
      .catch((error) => {
        console.log({ error });
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: "La mise à jour de l'étape n'a pas abouti",
          infinit: false,
        })
      })
  }

  return (
    <>
      <h2>Modifier l'étape {step.name_id}</h2>
      <form onSubmit={(e) => updateCurrentStep(e)}>
        <div style={{ maxWidth: "500px", margin: "auto" }}>
          <div className="flex input">
            <label>Nom de l'étape:</label>
            <input
              value={newStep.name}
              onInput={(e) => {
                updateNewStep("name", e.target.value)
              }}
              type="text"
              min="1"
              required
              placeholder="Etiquettage"
            />
          </div>
          <div className="flex input left">
            <label>
              Quantité total souhaité
              <br />
              pour cette étape:
            </label>
            <input
              type="number"
              value={newStep.todo_quantity}
              onInput={(e) => {
                updateNewStep("todo_quantity", e.target.value)
              }}
              required
            />
          </div>
          <div className="flex input">
            <label>Remarque:</label>
            <input
              type="textarea"
              value={newStep.comment}
              onInput={(e) => {
                updateNewStep("comment", e.target.value)
              }}
            />
          </div>
        </div>

        <div className="flex">
          <button
            type="button"
            onClick={() => {
              showPopup2(false)
            }}
            className="error-light-button center-block"
          >
            Annuler
          </button>
          <button className="light-button center-block">
            Mettre à jour l'étape
          </button>
        </div>
      </form>
    </>
  )
}
