import { appConstante } from 'appConstante'
import React, { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { NotificationContext } from 'Context/NotificationContext'

export const MslAccessContext = createContext()

function MslAccessContextProvider(props) {

  const [mslNonce, setMslNonce] = useState(null)
  const { addNotificationContent } = useContext(NotificationContext)
  useEffect(() => {
    const controller = new AbortController()
    axios.get(`${appConstante.servers.MSL_ENDPOINT}/nonce?api_key=${appConstante.access.MSL_KEY}`,
      {
        signal: controller.signal
      }
    )
      .then(res => {
        setMslNonce(res.data.data)
      })
      .catch(err => {
        err.response
          ? addNotificationContent({
            title: "Erreur",
            error: true,
            content: err.response.data.message,
          })
          : addNotificationContent({
            title: "Erreur",
            error: true,
            content: "Une erreur est survenue lors de la récupération de vos accès à l'espace pro",
          })
      })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <MslAccessContext.Provider value={{ mslNonce }}>
      {props.children}
    </MslAccessContext.Provider>
  )
}


export default MslAccessContextProvider