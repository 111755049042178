import React, { useEffect, useState, useContext } from 'react'
import Datalist from 'Components/Dataliste/Dataliste'
import Input from 'Components/FormElement/Input'
import { useCallApi } from 'Functions'
import { appConstante, getConversionUnitFactor } from 'appConstante'
import { getBatchStock } from 'AppContainer/ERP/ERPConstantes'
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import SelectArticles from 'Components/_ERP/SelectArticles'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { NotificationContext } from 'Context/NotificationContext'
import { ArticleContext } from 'Context/ERP/ArticleContext'

export default function ProductSpecificationConditioningItem({ packaging, defautlQuantity }) {
    const callApi = useCallApi()

    const { addNotificationContent } = useContext(NotificationContext)
    const { getArticle } = useContext(ArticleContext)

    const [currentPackaging, setCurrentPackaging] = useState({})
    const [suppliers, setSuppliers] = useState(packaging
        ? packaging.products
        : null
    )
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [supplierBatchNumbers, setSupplierBatchNumbers] = useState(null);

    const [erpArticle, setErpArticle] = useState(null)
    const {
        productionSheet,
        setProductionSheet,
    } = useContext(ProductionSheetContext)

    useEffect(() => {
        const loadArticle = async () => {
            const article = await getArticle(packaging.id)
            setErpArticle(article)
        }

        loadArticle()
    }, [])

    useEffect(() => {
        if (productionSheet.article) {
            if (erpArticle?.category === "Matière première") {
                const quantity = defautlQuantity * getConversionUnitFactor(productionSheet.article.unit_of_measure, packaging.unit_of_measure) * parseFloat(productionSheet.article.volume);
                setCurrentPackaging(_ => ({ ...packaging, quantity: quantity.toFixed(4) * 1 }))
            } else {
                setCurrentPackaging(_ => ({ ...packaging, quantity: defautlQuantity }))
            }
        } else if (!productionSheet.creating) {
            setCurrentPackaging({ ...packaging, quantity: defautlQuantity })
        }
    }, [packaging.id, productionSheet?.article, erpArticle?.category])

    useEffect(() => {
        if (packaging.products) {
            setSuppliers(_ => packaging.products)
            !selectedSupplier && setSelectedSupplier(packaging.products[0])
        }
    }, [packaging.products])

    const updateCurrentPackaging = (key, value) => {
        setCurrentPackaging(prvPackaging => ({ ...prvPackaging, [key]: value }))
    }


    useEffect(() => {
        productionSheet.creating
            && setProductionSheet(previousProductionSheet => {
                const newProductionSheet = { ...previousProductionSheet }
                newProductionSheet.conditioning[fomatCategory(packaging.category)] =
                    previousProductionSheet.conditioning[fomatCategory(packaging.category)]
                        ?.map((item, index) => index === currentPackaging.index ? currentPackaging : item)
                return { ...newProductionSheet }
            })
    }, [currentPackaging])

    const deleteItem = () => {
        if (confirm(`Êtes vous sûr de vouloir retirer le packaging ${currentPackaging.name} de votre fiche de production ? `)) {
            productionSheet.creating
                && setProductionSheet(previousProductionSheet => {
                    const newProductionSheet = { ...previousProductionSheet }
                    newProductionSheet.conditioning[fomatCategory(packaging.category)] =
                        previousProductionSheet.conditioning[fomatCategory(packaging.category)]
                            .filter((_, index) => index !== currentPackaging.index)
                    return { ...newProductionSheet }
                })
        }
    }

    const fomatCategory = (category) => {
        const formatedCategory =
            category === "Packaging primaire"
                ? 'primary'
                : category === "Packaging secondaire"
                    ? 'secondary'
                    : null
        return formatedCategory
    }

    useEffect(() => {
        const controller = new AbortController()
        if (currentPackaging && currentPackaging.id && !suppliers) {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article/${currentPackaging.id}`, { method: 'get' }, controller.signal)
                .then(res => {
                    setSuppliers(res.data.data.products)
                    setSelectedSupplier(res.data.data.products[0]?.id)
                    setCurrentPackaging(prvCP => ({ ...prvCP, ...res.data.data }))
                    addNotificationContent({})
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        return () => {
            controller.abort()
        }
    }, [packaging.products, currentPackaging.id])

    useEffect(() => {
        if (selectedSupplier) {
            const currentSupplier = suppliers.find(supplier => supplier.id == selectedSupplier)
            currentSupplier && setSupplierBatchNumbers(currentSupplier.history)
        }
    }, [selectedSupplier])

    return (
        <div key={packaging.id}>
            <div className='flex gap-1'>
                {!currentPackaging.id
                    ? <SelectArticles
                        // category={packaging.category}
                        refresh={false}
                        onSelect={updateCurrentPackaging}
                        customParameters={['id']}
                    />
                    : <div className='strong-2' >{currentPackaging.name}</div>
                }

                {productionSheet.creating
                    ? <DeleteButton
                        alwaysDisplay={true}
                        callBack={deleteItem}
                    />
                    : null}
            </div>
            <div className='flex-start wrap gap-1'>
                {(productionSheet.creating && suppliers)
                    ? <>
                        {suppliers.length
                            ? <Datalist
                                defaultId={suppliers && suppliers.length == 1 ? suppliers[0].id : selectedSupplier}
                                placeholder={'Sélectionner un fournisseur'}
                                items={suppliers && suppliers.map(supplier => ({ id: supplier.id, value: supplier.supplier }))}
                                onSelect={setSelectedSupplier}
                                width={"48%"}
                                required={true}
                            />
                            : <h5> aucun fournisseur </h5>}
                        {selectedSupplier
                            && <>
                                {supplierBatchNumbers
                                    ? supplierBatchNumbers.length
                                        ? <Datalist
                                            placeholder={'Sélectionner un n° de lot'}
                                            items={supplierBatchNumbers && supplierBatchNumbers
                                                .filter(batch => getBatchStock(batch) > 0)
                                                .map(batch => ({ id: batch.id, value: `${batch.batch_number} - (${getBatchStock(batch)} disponibles)` }))}
                                            onSelect={updateCurrentPackaging}
                                            customParameters={['batch_id']}
                                            required={true}
                                            width={"48%"}
                                            // value={currentPackaging.batch_id}
                                            defaultId={supplierBatchNumbers.length === 1 ? supplierBatchNumbers[0].id : null}
                                        />
                                        : <input type='text' placeholder='entrer un numéro de lot' value="" />
                                    : <h4> aucun batch de disponible ! </h4>
                                }
                            </>
                        }
                        <Input type='number'
                            value={currentPackaging.quantity}
                            onInput={updateCurrentPackaging}
                            label="quantité utilisé (perte comprise)"
                            customParameters={['quantity']}
                            required={true}
                            step={erpArticle?.category === "Matière première" ? "0.0001" : '1'}
                        />
                    </>
                    : null
                }
            </div>

            {currentPackaging.category === "Packaging secondaire" && currentPackaging.additional_datas ?
                <div className='flex m-t-25 gap-1'>
                    <div>
                        <div className='flex-start column'>
                            <div className='m-r-10'>Repiquage: </div>
                            <div className='strong-2'>{currentPackaging.additional_datas.transplanting.enable ? "Oui" : "Non"}</div>
                        </div>
                        {currentPackaging.additional_datas.transplanting.comment
                            ? <div className='flex-start wrap'>
                                <div className='m-r-10'>Commentaire : </div>
                                <div className='strong-2'>{currentPackaging.additional_datas.transplanting.comment}</div>
                            </div>
                            : null}
                    </div>
                    <div>
                        <div className='flex-start column'>
                            <div className='m-r-10'>Inscription DDM: </div>
                            <div className='strong-2'>{currentPackaging.additional_datas.DDM.enable ? "Oui" : "Non"}</div>
                        </div>
                        {currentPackaging.additional_datas.DDM.comment
                            ? <div className='flex-start column'>
                                <div className='m-r-10'>Commentaire : </div>
                                <div className='strong-2'>{currentPackaging.additional_datas.DDM.comment}</div>
                            </div>
                            : null
                        }
                    </div>
                    <div>
                        <div className='flex-start full-width column'>
                            <div className='m-r-10'>Inscription n° lot: </div>
                            <div className='strong-2'>{currentPackaging.additional_datas.batch.enable ? "Oui" : "Non"}</div>
                        </div>
                        {currentPackaging.additional_datas.batch.comment
                            ? <div className='flex-start column'>
                                <div className='m-r-10'>Commentaire : </div>
                                <div className='strong-2'>{currentPackaging.additional_datas.batch.comment}</div>
                            </div>
                            : null
                        }
                    </div>
                </div>
                : null}
        </div>
    )
}
