import React, { useContext } from 'react'
import { PopupContext } from 'Context/PopupContext'
import CreateBulk from './CreateBulk'

export default function CreateBulkBtn({ setter }) {

    const { addPopupContent2 } = useContext(PopupContext)

    return (
        <button type='button' className='light-button' onClick={_ => addPopupContent2(<CreateBulk setter={setter} />)}>
            Créer un bulk
        </button>
    )
}
