import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'
import { UsersContext } from 'Context/Analyse/UsersContext'
import Datalist from 'Components/Dataliste/Dataliste'
import Input from 'Components/FormElement/Input'
import MidLoader from 'Components/MidLoader/MidLoader'
import { appConstante } from 'appConstante'

export default function ComptesClients() {

    const { users } = useContext(UsersContext)
    const { getOrganizationFromId, organizations } = useContext(OrganizationsContext)
    const [searchId, setSearchId] = useState('')
    const [searchEmail, setSearchEmail] = useState(null)
    const [searchOrganization, setSearchOrganization] = useState(null)
    const [formatedUsers, setFormatedUsers] = useState(null)
    const [showSubscribers, setShowSubscribers] = useState(false)

    // GET ORGANIZATIONS NAME FROM ID 
    useEffect(() => {
        if (users && users.length && organizations.length) {
            Promise.all(users.map((user) => getOrganizationFromUser(user)))
                .then((userWithOrganization) => {
                    setFormatedUsers(userWithOrganization)
                })
        }
    }, [users, organizations])

    const getOrganizationFromUser = async (user) => {
        if (user.data.organization_id) {
            const responseOrganization = await getOrganizationFromId(
                parseInt(user.data.organization_id)
            )
            return {
                ...user, data: { ...user.data, organization: responseOrganization ?? { id: user.data.organization_id, name: false } }
            }
        } else {
            return user
        }
    }

    return (
        <div>
            {
                formatedUsers
                    ? <>
                        <div className='m-b-25 flex-start end gap-1'>
                            <Input
                                label={'ID'}
                                type={'number'}
                                value={searchId}
                                onInput={setSearchId}
                                style={{ width: '200px' }}
                            />

                            <Datalist
                                items={formatedUsers.map(user => ({ id: user.data.billing_email, value: user.data.billing_email }))}
                                onSelect={setSearchEmail}
                                placeholder={"Adresse email"}
                            />
                            <Datalist
                                items={
                                    formatedUsers.filter(user => user.data.organization)
                                        .map(user => ({ id: user.data.organization.name, value: user.data.organization.name }))}
                                onSelect={setSearchOrganization}
                                placeholder={"Organisation"}
                            />
                            <label className='flex-center'>
                                <input type="checkbox" className='erp-check' value={showSubscribers} onChange={setShowSubscribers} />
                                Afficher uniquement les comptes abonnés
                            </label>

                        </div>
                        {formatedUsers.length
                            ? <table className='beautiful-array'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Organisation</th>
                                        <th>ID dernier panier</th>
                                        <th>Entreprise de facturation</th>
                                        <th>Mail de facturation</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formatedUsers &&
                                        formatedUsers.filter(user => searchId ? user.ID == searchId : true)
                                            .filter(user => searchEmail ? user.data.billing_email == searchEmail : true)
                                            .filter(user => searchOrganization ? user.data?.organization?.name == searchOrganization : true)
                                            .filter(user => showSubscribers ? user.data?.customer_data?.subscription : true)
                                            .map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <a href={`${appConstante.urls.ANALYSE_ACCOUNT}?user_id=${user.data.ID}`} target='blank'>{user.data.ID}</a>
                                                        </td>
                                                        <td>
                                                            {user.data.organization
                                                                ? user.data.organization.name === false
                                                                    ? <div>Erreur lors de la récupération à Pipedrive</div>
                                                                    : <a
                                                                        href={`https://lelaboratoirefrancaisduchanvre-d03bb0.pipedrive.com/organization/${user.data.organization_id}`}
                                                                        target='blank'>{user.data.organization.name}
                                                                    </a>
                                                                : null
                                                            }
                                                        </td>
                                                        <td>{user.data.last_cart_id}</td>
                                                        <td>{user.data.billing_company ? user.data.billing_company : user.data.customer_data?.entreprise}</td>
                                                        <td>{user.data.billing_email ? user.data.billing_email : user.data.customer_data?.mail}</td>
                                                        <td>
                                                            <button className='light-button'>
                                                                <Link to={`/analyses/comptes-clients/${user.data.ID}`}>
                                                                    Consulter
                                                                </Link>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )

                                            })
                                    }
                                </tbody>
                            </table>
                            : <h5>Aucun compte client</h5>
                        }
                    </>
                    : <MidLoader />
            }
        </div>
    )
}
