import React, { useEffect, useContext, useState, useRef } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import Pagination from 'Components/Pagination/Pagination'
import MidLoader from 'Components/MidLoader/MidLoader'
import { RecipeContext } from 'Context/ERP/RecipeContext'
import Datalist from 'Components/Dataliste/Dataliste'
import { Link } from 'react-router-dom'
import Input from 'Components/FormElement/Input'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function Recipes() {
    const { addNotificationContent } = useContext(NotificationContext)
    const callApi = useCallApi()

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const { getRecipeById } = useContext(RecipeContext)
    const { recipeNames } = useContext(RecipeContext)
    const [recipes, setRecipes] = useState(null)
    const [filteredRecipes, setFilteredRecipes] = useState(null)
    const [selectedRecipe, setSelectedRecipe] = useState(null)
    const [isFiltered, setIsFiltered] = useState(false)
    const [search, setSearch] = useState('')
    const timeoutHandle = useRef()

    useEffect(() => {
        const controller = new AbortController()
        const delay = search ? 500 : 0
        clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            setRecipes(null)
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipes`, { method: 'get' }, controller.signal, {
                limit: 10,
                page: currentPage,
                search: search,
            })
                .then(res => {
                    setRecipes(res.data.data)
                    setTotalPage(res.data.total_pages)
                    res.data.total_pages === 0 && setCurrentPage(1)
                    currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
                })
                .catch(err => {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: 'Nous rencontrons une erreur avec nos serveurs',
                        infinit: false,
                    })
                })
        }, delay)
        return () => {
            controller.abort()
        }
    }, [currentPage, search])

    const deleteRecipe = (recipeId) => {

        if (confirm('Êtes vous sur de vouloir supprimer cette recettes ?')) {
            addNotificationContent({
                title: 'Suppression en cours',
                content: <MiniLoader />,
                infinit: true
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe/${recipeId}`, { method: 'delete' })
                .then(res => {
                    addNotificationContent({
                        error: false,
                        title: 'Succès',
                        content: "Recette supprimée",
                        infinit: false
                    })
                    setRecipes(recipes.filter(recipe => recipe.id !== recipeId))
                }).catch((err) => {
                    console.log(err);
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: err.response.message,
                        infinit: false
                    })
                })
        }
        return () => {
            controller.abort()
        }
    }

    useEffect(() => {
        if (selectedRecipe) {
            getRecipeById(selectedRecipe)
                .then(recipeData => {
                    setFilteredRecipes([recipeData])
                    setIsFiltered(true)
                }).catch(err => {
                    console.log(err)
                })
        } else {
            setIsFiltered(false)
        }
    }, [selectedRecipe])

    const recipesList = isFiltered ? filteredRecipes : recipes

    return (
        <>
            <div className='flex-center m-b-25'>
                <div>
                    <Datalist
                        placeholder={'Sélectionner une recette'}
                        items={recipeNames && recipeNames.map(recipe => ({ id: recipe.id, value: recipe.name }))}
                        onSelect={setSelectedRecipe}
                        width='300px'
                    />
                </div>
                <div>
                    <Input label={'Recherche'} value={search} onInput={setSearch} />
                </div>
            </div>
            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />

            {recipesList ?
                recipesList.length > 0 ?
                    (<table className='beautiful-array m-t-10'>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Nom</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {recipesList.map(recipe =>
                                <tr key={recipe.id}>
                                    <td>
                                        <button className='light-button'>
                                            <Link to={`/erp/specif-and-recipe/recipes/edit/${recipe.id}`}>Modifier</Link>
                                        </button>
                                    </td>
                                    <td>
                                        <button className='light-button'>
                                            <Link to={`/erp/specif-and-recipe/recipes/consult/${recipe.id}`}>Consulter</Link>
                                        </button>
                                    </td>
                                    <td>
                                        <div className="strong-1">{recipe.name}</div>
                                    </td>
                                    <td>
                                        <div className="strong-1"> {recipe.description} </div>
                                    </td>
                                    <td>
                                        <DeleteButton callBack={deleteRecipe} parameters={[recipe.id]} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table >)
                    : (<h5> Aucune recette disponible !</h5>)
                : (<MidLoader />)
            }

        </>
    )
}
