import React, { useContext } from "react"
import { appConstante } from "appConstante"
import { useCurrentAppPermission } from "Functions"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { deleteEmptyObjectsField, useCallPennylaneApi } from "Functions"
import { DependenciesContext } from "Context/DependenciesContext"
import Input from "Components/FormElement/Input"
import Select from "Components/FormElement/Select"

export default function InvoiceCustomer({
  organisationPennylane,
  setOrganisationPennylane,
  createOrganisationPennylane,
  setCreateOrganisationPennylane,
  pipedriveCustomerInfo,
}) {
  const { addNotificationContent } = useContext(NotificationContext)
  const currentAppPermission = useCurrentAppPermission()

  console.log(organisationPennylane);
  
  const callPennylaneApi = useCallPennylaneApi()

  const sendOrganisationOnPennylane = (e) => {
    e.preventDefault()
    const cleanCustomer = deleteEmptyObjectsField(organisationPennylane)
    const data = {
      customer: cleanCustomer,
    }
    const url = createOrganisationPennylane
      ? `customers`
      : `customers/${organisationPennylane.source_id}`
    const options = {
      method: createOrganisationPennylane ? "post" : "put",
    }
    if (createOrganisationPennylane) {
      addNotificationContent({
        title: "Création du profil de facturation en cours",
        error: false,
        content: <MiniLoader />,
        infinit: true,
      })
      callPennylaneApi(url, options, data)
        .then((res) => {
          addNotificationContent({
            title: "Profil créé",
            error: false,
            content: "Le profil de facturation viens d'être créé avec succès",
            infinit: false,
          })
          setCreateOrganisationPennylane(false)
        })
        .catch((error) => {
          error.response &&
            addNotificationContent({
              error: true,
              title: "Erreur",
              content: error.response.data.error,
              infinit: false,
            })
        })
    } else {
      addNotificationContent({
        title: "Modification du profil de facturation en cours",
        error: false,
        content: <MiniLoader />,
      })
      callPennylaneApi(url, options, data)
        .then((res) => {
          addNotificationContent({
            title: "Succès",
            error: false,
            content:
              "Le profil de facturation viens d'être mis à jour avec succès",
            infinit: false,
          })
        })
        .catch((error) => {
          error.response &&
            addNotificationContent({
              error: true,
              title: "Erreur",
              content: error.response.data.error,
              infinit: false,
            })
        })
    }
  }

  const updateOrganisationPennylane = (field, value) => {
    const formatedValue = field === "emails" ? [value] : value
    setOrganisationPennylane({
      ...organisationPennylane,
      [field]: formatedValue,
    })
  }

  return currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
    <div id="check-organisation">
      <h3>Profil de facturation</h3>
      <form
        id="create-organisation"
        onSubmit={(e) => sendOrganisationOnPennylane(e)}
      >
        {createOrganisationPennylane && (
          <p>
            Le client indiqué sur le devis n'existe pas encore sur PennyLane.
            <br />
            Vérifier les champ suivant pour le créer:
          </p>
        )}
        {!createOrganisationPennylane && (
          <p>
            Vérifier que les champs suivants sont à jour pour la facturation du
            client:
          </p>
        )}
        <div className="flex">
          <div className="half-m-width">
            {" "}
            {/* GAUCHE */}
            <div>
              <Select
                onChange={updateOrganisationPennylane}
                customParameters={["customer_type"]}
                label="Type de client"
                value={organisationPennylane.customer_type}
                required
              >
                <option value="company">Entreprise</option>
                <option value="individual">Personne</option>
              </Select>
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.customer_type}
                </div>
              )}
            </div>
            {organisationPennylane.customer_type === "company" && (
              <div className="m-t-25">
                <Input
                  onInput={updateOrganisationPennylane}
                  label="Nom de l'organisation"
                  value={organisationPennylane.name}
                  customParameters={["name"]}
                  required
                />
                {pipedriveCustomerInfo && (
                  <div className="pipedrive-info">
                    Pipedrive: {pipedriveCustomerInfo.name}
                  </div>
                )}
              </div>
            )}
            {organisationPennylane.customer_type === "individual" && (
              <>
                <div className="m-t-25">
                  <Input
                    onInput={updateOrganisationPennylane}
                    customParameters={["first_name"]}
                    value={organisationPennylane.first_name}
                    required
                    label="Prénom"
                  />
                  {pipedriveCustomerInfo && (
                    <div className="pipedrive-info">
                      Pipedrive: {pipedriveCustomerInfo.last_name}
                    </div>
                  )}
                </div>

                <div>
                  <Input
                    onInput={updateOrganisationPennylane}
                    customParameters={["last_name"]}
                    value={organisationPennylane.last_name}
                    required
                    label="Nom"
                  />
                  {pipedriveCustomerInfo && (
                    <div className="pipedrive-info">
                      Pipedrive: {pipedriveCustomerInfo.first_name}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="m-t-25">
              <Input
                onInput={updateOrganisationPennylane}
                customParameters={["phone"]}
                value={
                  organisationPennylane.phone ? organisationPennylane.phone : ""
                }
                label="Téléphone"
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.phone}
                </div>
              )}
            </div>
            <div className="m-t-25">
              <Input
                onInput={updateOrganisationPennylane}
                customParameters={["emails"]}
                value={
                  organisationPennylane.emails[0]
                    ? organisationPennylane.emails[0]
                    : ""
                }
                label="Email"
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.emails[0]}
                </div>
              )}
            </div>
            <div className="m-t-25">
              <Input
                onInput={updateOrganisationPennylane}
                customParameters={["recipient"]}
                value={
                  organisationPennylane.recipient
                    ? organisationPennylane.recipient
                    : ""
                }
                label="Destinataire"
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.recipient}
                </div>
              )}
            </div>
            <div className="flex input">
              {/* <label>Condition de paiements: </label> */}
              <div className="m-t-25">
                <Select
                  onChange={updateOrganisationPennylane}
                  customParameters={["payment_conditions"]}
                  label="Condition de paiements"
                  value={organisationPennylane.payment_conditions}
                >
                  {/* <option value={organisationPennylane.payment_conditions}>{organisationPennylane.payment_conditions ? organisationPennylane.payment_conditions : ''}</option>
                                    <option disabled>_________</option> */}
                  <option value="upon_receipt">Dès réception</option>
                  <option value="custom">Personnalisé</option>
                  <option value="15_days">Sous 15 jours</option>
                  <option value="30_days">Sous 30 jours</option>
                  <option value="45_days">Sous 45 jours</option>
                  <option value="60_days">Sous 60 jours</option>
                </Select>
                {pipedriveCustomerInfo && (
                  <div className="pipedrive-info">
                    Pipedrive:{" "}
                    {pipedriveCustomerInfo.payment_conditions
                      ? pipedriveCustomerInfo.payment_conditions
                      : ""}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="half-m-width">
            {/* DROITE */}
            <div className="m-t-25">
              <Input
                onInput={updateOrganisationPennylane}
                customParameters={["address"]}
                value={organisationPennylane.address}
                label="Adresse de facturation"
                required
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.address}
                </div>
              )}
            </div>
            <div className="m-t-25">
              <Input
                onInput={updateOrganisationPennylane}
                customParameters={["city"]}
                value={organisationPennylane.city}
                label="Ville"
                required
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.city}
                </div>
              )}
            </div>
            <div className="flex m-t-25">
              <div className="half-m-width">
                <Input
                  onInput={updateOrganisationPennylane}
                  customParameters={["postal_code"]}
                  value={organisationPennylane.postal_code}
                  label="Code postal"
                  required
                />
                {pipedriveCustomerInfo && (
                  <div className="pipedrive-info">
                    Pipedrive: {pipedriveCustomerInfo.postal_code}
                  </div>
                )}
              </div>
              <div className="half-m-width">
                <Input
                  onInput={updateOrganisationPennylane}
                  customParameters={["country_alpha2"]}
                  value={organisationPennylane.country_alpha2}
                  label="Pays (code 2 lettres - ex:FR)"
                  required
                />
                {pipedriveCustomerInfo && (
                  <div className="pipedrive-info">
                    Pipedrive: {pipedriveCustomerInfo.country_alpha2}
                  </div>
                )}
              </div>
            </div>
            <div className="m-t-25">
              <Input
                className="full-width"
                onInput={updateOrganisationPennylane}
                label="Adresse de livraison"
                customParameters={["delivery_address"]}
                value={organisationPennylane.delivery_address}
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.delivery_address}
                </div>
              )}
            </div>
            <div className="m-t-25">
              <Input
                className="full-width"
                onInput={updateOrganisationPennylane}
                label="Numéro de TVA"
                customParameters={["vat_number"]}
                value={organisationPennylane.vat_number}
              />
              {/* {pipedriveCustomerInfo && <div className='pipedrive-info'>Pipedrive: {pipedriveCustomerInfo.vat_number}</div>} */}
            </div>

            <div className="m-t-25">
              <Input
                className="full-width"
                onInput={updateOrganisationPennylane}
                label="Notes"
                customParameters={["notes"]}
                value={organisationPennylane.notes}
              />
              {pipedriveCustomerInfo && (
                <div className="pipedrive-info">
                  Pipedrive: {pipedriveCustomerInfo.notes}
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          style={{ margin: "auto", display: "block" }}
          className="light-button"
        >
          {createOrganisationPennylane
            ? "Créer le profil de facturation"
            : "Mettre à jour le profil de facturation"}
        </button>
      </form>
    </div>
}
