import React from 'react'
import Dashboard from './Dashboard.tsx'
import Analyse from './Analyse/Analyse.tsx'
import Revenue from './Revenue/Revenue.tsx'

export const DashboardRoutes = [
    {
        path: "/dashboard", element: <Dashboard />, subRoutes: [
            { path: "/dashboard/analyse", element: <Analyse /> },
            { path: "/dashboard/revenue", element: <Revenue /> },
        ]
    }
]