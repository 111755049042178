import Skeleton from 'Components/Skeleton/Skeleton'
import { BatchContext } from 'Context/CBDProtect/Batch'
import React, { useContext, useEffect, useState } from 'react'

export default function BatchDetailsSmall({ batch = null, id = null }) {

    const { getBatch } = useContext(BatchContext)
    const [currentBatch, setCurrentBatch] = useState(batch)


    useEffect(() => {
        if (id) {
            getBatch(id)
                .then(batch => setCurrentBatch(batch))
        }
    }, [id])

    currentBatch && console.log(currentBatch);

    if (!batch && !id) {
        return (null)
    }
    return (
        <div>
            {
                currentBatch
                    ? <div className="">
                        <div className="strong-2" > {currentBatch.visible_id}</div>
                    </div>
                    : <Skeleton height={'40px'} />
            }
        </div>
    )
}
