import React, { createContext, useState, useEffect, useContext } from "react"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"
import { useLocation } from "react-router-dom"

export const VariablesContext = createContext()

function VariablesContextProvider(props) {

  const callApi = useCallApi()
  const location = useLocation()

  const [variables, setVariables] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    if (location.pathname.slice(0, 10) === '/erp/stock' && !variables.length) {
      callApi(
        `${appConstante.servers.PANORAMA_ENDPOINT}/erp/variables`,
        { method: "get" },
        controller.signal
      ).then((res) => {
        setVariables(res.data)
      })
    }

    return () => {
      controller.abort()
    }
  }, [location])

  /**
   *
   * @param {Object} 'Nouvelle variable à ajouter'
   */
  const updateVariable = (newVariable) => {
    setVariables(
      variables.map((variable) => {
        if (variable.id === newVariable.id) {
          return newVariable
        }
        return variable
      })
    )
  }

  /**
   *
   * @param {int} id 'Identifiant de la vatiation'
   */
  const deleteVariable = (id) => {
    setVariables(variables.filter((variable) => variable.id !== id))
  }

  const addVariable = (newVariable) => {
    setVariables([newVariable, ...variables])
  }

  return (
    <VariablesContext.Provider
      value={{ variables, setVariables, updateVariable, addVariable, deleteVariable }}
    >
      {props.children}
    </VariablesContext.Provider>
  )
}

export default VariablesContextProvider
