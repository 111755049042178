import React from 'react'
import CBDProtect from './CBDProtect'
import Bulks from './Bulks'
import Batchs from './Batchs'

export const CBDProtectRoutes = [
    {
        path: "/cbd-protect", element: <CBDProtect />, subRoutes: [
            { path: "/cbd-protect/bulks/", element: <Bulks /> },
            { path: "/cbd-protect/products/", element: <Batchs /> },
        ]
    }
]