import React, { useState } from 'react'
import './openableComponent.css'

export default function OpenableComponent({ children, openLabel = "", closeLabel = "", position = "center" }) {

    const [open, setOpen] = useState(false)

    return (
        <div className={`${open ? 'openable' : 'openable closed'}`}>
            {
                openLabel
                    ? <div className={`open-label ${position} pointer`} onClick={() => setOpen(!open)}>{!open ? openLabel : closeLabel}</div>
                    : <div className={`arrow center ${open && 'rotate-arrow'}`} onClick={() => setOpen(!open)}>▼</div>
            }
            {children}
        </div >
    )
}
