import React, { useContext, useEffect, useState } from 'react'
import { getStockUnitOfMeasure } from 'appConstante'
import Select from 'Components/FormElement/Select'
import Input from 'Components/FormElement/Input'
import { NotificationContext } from 'Context/NotificationContext'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import Datalist from 'Components/Dataliste/Dataliste'
import { emptyHistory } from 'AppContainer/ERP/ERPConstantes'
import Addproduct from 'AppContainer/ERP/Stocks/ImportsAndExport/Product/Addproduct'
import { PopupContext } from 'Context/PopupContext'
import { formatDateForInput } from 'Functions'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import set from 'lodash/set.js';

export default function StockEntry({ defaultHistory = {}, setter }) {
    const [history, setHistory] = useState({ ...emptyHistory, batchs: [{}], ...defaultHistory })
    useEffect(() => {
        defaultHistory && setHistory(prvHistory => ({ ...prvHistory, ...defaultHistory }))
    }, [defaultHistory])

    const { getArticle } = useContext(ArticleContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { addPopupContent2, showPopup2 } = useContext(PopupContext)
    const [avaibleBatchs, setAvaibleBatchs] = useState([])

    const showAddSupplier = () => {
        addPopupContent2(<Addproduct
            article={history.article}
            setter={addNewSupplier}
        />)
    }

    useEffect(() => {
        if (avaibleBatchs.length === 1 && history.history_type === "remove") {
            updateHistoryKey("batch_id", avaibleBatchs[0].id)
        }
    }, [avaibleBatchs, history.history_type])


    useEffect(() => {
        if (history.article_id) {
            getArticle(history.article_id)
                .then(article => {
                    updateHistoryKey('article', article)
                })
        }
    }, [history.article_id])

    useEffect(() => {
        if (history.article && history.product_id) {
            const selectedProduct = history.article?.products?.find(product => product.id === history.product_id)
            selectedProduct && setAvaibleBatchs(selectedProduct.history)
        }
    }, [history.article, history.product_id])

    const updateHistoryKey = (keyPath, value) => {
        const newHistory = history
        set(newHistory, keyPath, value);
        setter(newHistory)
    }

    const addNewBatch = () => {
        updateHistoryKey("batchs", [...history.batchs, {}])
    }

    const updateBatchProperty = (index, key, value) => {
        updateHistoryKey(`batchs[${index}].${key}`, value)
    }

    const deleteBatch = (index) => {
        if (history.batchs.length === 1) {
            addNotificationContent({ content: "Au moins un lot doit être définis", error: true })
        } else {
            updateHistoryKey("batchs", history.batchs.filter((_, idx) => index !== idx))
        }
    }

    const addNewSupplier = (newSupplier) => {
        showPopup2(false)
        updateHistoryKey('article', {
            ...history.article,
            products: history.article.products.length
                ? [...history.article.products, newSupplier]
                : [newSupplier]
        })
    }

    return (
        <>
            <div className='flex'>
                <div className='half-m-width'>
                    {history?.article?.products
                        ? <>
                            <Datalist
                                placeholder="Sélectionner un fournisseur"
                                items={history.article.products.map(product => ({ id: product.id, value: product.supplier }))}
                                onSelect={updateHistoryKey}
                                customParameters={['product_id']}
                                required={true}
                                defaultId={history.product_id
                                    ? history.product_id
                                    : history.article.products.length === 1
                                        ? history.article.products[0].id : ''
                                }
                            />

                            <button
                                className='light-button m-t-10'
                                type='button'
                                onClick={_ => showAddSupplier()}
                            >
                                Ajouter un fournisseur
                            </button>
                        </>
                        : null}

                    {history.batchs.map((batch, index) =>
                        <div className='relative list-item-bg m-t-10' key={index}>
                            <div className='absolute' style={{ right: "5px" }}>
                                <DeleteButton alwaysDisplay={true} callBack={deleteBatch} parameters={[index]} />
                            </div>
                            <div className='flex end'>
                                <Select value={batch.batch_id} onChange={updateBatchProperty}
                                    customParameters={[index, 'batch_id']}
                                    label='Numéro de lot'
                                >
                                    <option value=''>Créer un nouveau lot</option>
                                    {
                                        avaibleBatchs
                                            .map(batch =>
                                                <option key={batch.id} value={batch.id}>{batch.batch_number} (disponible: {batch.quantity})</option>
                                            )
                                    }
                                </Select>
                                {!batch.batch_id &&
                                    <Input value={batch.batch_number ?? ''}
                                        onInput={updateBatchProperty}
                                        customParameters={[index, 'batch_number']}
                                        required
                                        label='Nouveau n° de lot'
                                    />}
                            </div>
                            {!batch.batch_id
                                ? <div className='subtext error m-t-10'>Merci de bien vérifier si le lot n'existe pas déjà dans la liste</div>
                                : null
                            }
                            <div className='flex-center'>
                                <Input
                                    type='number' min='0' step='0.0001' value={batch.quantity ?? ''}
                                    onInput={updateBatchProperty} customParameters={[index, 'quantity']}
                                    label={`Quantité à ajouter (${getStockUnitOfMeasure(history.article)})`} required={true}
                                />
                                <Input type='date' value={batch.expiration_date ? formatDateForInput(batch.expiration_date) : ""}
                                    onInput={updateBatchProperty} required={avaibleBatchs.find(b => b.id == batch.batch_id)?.ddm ? false : true}
                                    label='DDM' customParameters={[index, 'expiration_date']}
                                />
                            </div>
                        </div>
                    )}
                    <button
                        type='button'
                        className='light-button center-block m-t-10'
                        onClick={_ => addNewBatch()}
                    >
                        Ajouter un autre lot
                    </button>
                </div>
                <div className='half-m-width'>
                    <Input type='date' value={history.date}
                        onInput={updateHistoryKey}
                        label='Date' customParameters={['date']}
                        required={true}
                    />
                    <Select value={history.type} onChange={updateHistoryKey}
                        customParameters={['type']} label='Type'>
                        <option value='Manuel'>Manuel</option>
                        <option value='Automatique'>Automatique (depuis les recettes)</option>
                    </Select>
                    <Input
                        value={history.comment ? history.comment : ''}
                        onInput={updateHistoryKey}
                        label='Commentaire'
                        customParameters={['comment']}
                    />
                </div>
            </div>
        </>
    )
}
