import React, { useContext, useEffect, useState } from 'react'
import { useCallAnalysesApi } from 'Functions'
import { AnalyseAccessContext } from 'Context/Analyse/AnalyseAccessContext'
import { NotificationContext } from 'Context/NotificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { PopupContext } from 'Context/PopupContext'
import ProductFields from './ProductFields'
import { OrdersContext } from 'Context/Analyse/OrdersContext'

export default function UpdateProduct({ product, setter }) {

    const { updateOrderFromId } = useContext(OrdersContext)
    const { analyseNonce } = useContext(AnalyseAccessContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { showPopup } = useContext(PopupContext)
    const callAnalysesApi = useCallAnalysesApi()
    const [updatedProduct, setUpdatedProduct] = useState()

    useEffect(() => {
        if (product) {
            setUpdatedProduct(product)
        }
    }, [product])

    const sendUpdatedProduct = (e) => {
        e.preventDefault()
        const controller = new AbortController()
        if (analyseNonce) {
            addNotificationContent({
                title: 'Mise à jour en cours',
                content: <MiniLoader />,
                infinit: true,
            })
            callAnalysesApi(`products`, { method: "put", signal: controller.signal }, updatedProduct)
                .then(res => {
                    if (setter) {
                        setter(previousProducts => {
                            const newProducts = previousProducts.map(product => {
                                if (product.num_reference === res.data.data.num_reference) {
                                    updateOrderFromId(res.data.data.id_commande)
                                    return res.data.data
                                } else {
                                    return product
                                }
                            })
                            return newProducts
                        })
                    }
                    showPopup(false)
                    addNotificationContent({
                        error: false,
                        title: 'Succès',
                        content: "Le produit a été modifié",
                        infinit: false
                    })
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: err.response.data.message ?? 'Une erreur est survenue lors de la modification du produit',
                        infinit: false
                    })
                })
            return () => {
                controller.abort()
            }
        }
    }

    return (
        <div>
            <h2>Modifier le produit {product.num_reference}</h2>
            {updatedProduct
                ? <form onSubmit={e => sendUpdatedProduct(e)}>
                    <ProductFields
                        product={updatedProduct}
                        setter={setUpdatedProduct}
                        isNewProduct={false}
                    />
                    <div>
                        <button className='strong-button'>Modifier le produit</button>
                    </div>
                </form>
                : null}
        </div>
    )
}
