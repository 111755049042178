import { PlanningContext } from "Context/ERP/PlanningContext"
import { formatDateForInput, isNumber } from "Functions"
import React, { useContext, useEffect, useState } from "react"


export default function CreateSession({ defaultSession, steps, setter }) {

    const emptySession = {
        step_id: '',
        indicative_quantity: '',
        pre_production_comment: '',
        lock: false,
        start: `${formatDateForInput(new Date())}T08:00`,
        end: `${formatDateForInput(new Date())}T09:00`,
    }
    const [session, setSession] = useState(emptySession)

    const { postSession } = useContext(PlanningContext)

    const updateSession = (key, value) => {
        setSession(prvSession => {
            let end = prvSession.end
            if (key === 'start') {
                if (new Date(session.start) > new Date(session.end)) {
                    end = value
                }
            }
            return { ...prvSession, end: end, [key]: value }
        })
    }

    useEffect(() => {
        if (defaultSession) {
            setSession(prcSession => ({ ...prcSession, ...defaultSession }))
        }
    }, [defaultSession])

    const addSession = (e) => {
        e.preventDefault()
        postSession(session)
            .then(session => {
                setter && setter(session)
                // setSession(session)
            })
    }

    return (
        <form
            onSubmit={(e) => addSession(e)}
            className="list-item full-width p-10 p-l-25 p-r-25"
        >
            <h5 className='m-0 m-b-10'>Créer une session</h5>
            <div className="flex input">
                <label>Sélectionner une étape de production</label>
                <select
                    onChange={(e) => updateSession('step_id', isNumber(e.target.value) ? parseInt(e.target.value) : e.target.value)}
                    required
                    value={session.step_id}
                >
                    <option value="">-- Etapes --</option>
                    {steps.filter(step => step).map((step) => (
                        <option key={step.id} value={step.id}>{step.name}</option>
                    ))}
                </select>
            </div>
            <div className="flex input left">
                <label>
                    Quantitée souhaitée
                    <br />
                    pour cette session:
                </label>
                <input
                    type="number"
                    value={session.indicative_quantity}
                    onInput={(e) => {
                        updateSession('indicative_quantity', parseFloat(e.target.value))
                    }}
                    required
                />
            </div>
            <div className="flex input">
                <label>Date de début:</label>
                <input
                    type="datetime-local"
                    step="1800"
                    onInput={(e) => updateSession('start', e.target.value)}
                    value={session.start}
                    required
                />
            </div>
            <div className="flex input">
                <label>Date de fin:</label>
                <input
                    type="datetime-local"
                    step="1800"
                    onInput={(e) => updateSession('end', e.target.value)}
                    value={session.end}
                    required
                />
            </div>
            <div className="flex input">
                <label>Remarque:</label>
                <input
                    type="textarea"
                    value={session.pre_production_comment}
                    onInput={(e) => {
                        updateSession('pre_production_comment', e.target.value)
                    }}
                />
            </div>
            <div className="flex input">
                <label
                    className="flex"
                    style={{ width: "100%", alignItems: "center" }}
                >
                    <div>Interdire la modification pour la production:</div>
                    <input
                        type="checkbox"
                        value={session.lock}
                        onChange={(e) => {
                            updateSession('lock', e.target.checked)
                        }}
                    />
                </label>
            </div>
            <button className="strong-button">Créer la session</button>
        </form>
    )
}
