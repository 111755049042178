import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar'
import React, { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

export default function SpecificationAndRecipe() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        location.pathname === '/erp/specif-and-recipe' && navigate(`/erp/specif-and-recipe/specifications-product`); //Sous onglet par défaut
    }, [location.pathname])
    return (
        <>
            <SubNavBar menu={[
                { path: '/erp/specif-and-recipe/specifications-product', name: 'Spécifications produits' },
                { path: '/erp/specif-and-recipe/recipes', name: 'Recettes' },
                { path: '/erp/specif-and-recipe/production-sheets', name: 'Fiches production' }
            ]} />
        </>
    )
}
