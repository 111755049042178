import React, { useContext, useState, useEffect, useRef } from 'react'
import Pagination from 'Components/Pagination/Pagination'
import { Link } from 'react-router-dom'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import MidLoader from 'Components/MidLoader/MidLoader'
import Datalist from 'Components/Dataliste/Dataliste'
import Input from 'Components/FormElement/Input'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function ProductionSheets() {

    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [productionSheets, setProductionSheets] = useState(null)
    const [searchDealId, setSearchDealId] = useState('')
    const timeoutHandle = useRef()

    useEffect(() => {
        const controller = new AbortController()
        const delay = searchDealId ? 500 : 0
        clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            setProductionSheets(null)
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheets`, { method: 'get' }, controller.signal, {
                limit: 10,
                page: currentPage,
                deal: searchDealId,
            })
                .then(res => {
                    setProductionSheets(res.data.data)
                    setTotalPage(res.data.total_pages)
                    res.data.total_pages === 0 && setCurrentPage(1)
                    currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
                })
                .catch(err => {
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: 'Nous rencontrons une erreur avec nos serveurs',
                        infinit: false,
                    })
                })
        }, delay)
        return () => {
            controller.abort()
        }
    }, [currentPage, searchDealId])

    const deleteProductionSheet = id => {
        if (confirm('êtes vous certains de vouloir supprimer cette session de production ?')) {
            addNotificationContent({
                title: 'Suppression en cours',
                content: <MiniLoader />,
            })

            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet/${id}`, { method: 'delete' })
                .then(res => {
                    addNotificationContent({
                        content: 'Suppression réussi',
                    })
                    setProductionSheets(prvPS => prvPS.filter(ps => ps.id !== id))
                }
                )
                .catch(err => addNotificationContent({
                    content: 'La suppression n\a pas aboutie',
                    error: true,
                }))
        }
    }

    return (
        <>
            <div className='m-b-25' style={{ width: '200px' }}>
                <Input label={'N° devis'} type={'number'} value={searchDealId} onInput={setSearchDealId} />
            </div>
            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
            {productionSheets
                ? productionSheets.length > 0
                    ? <table className='beautiful-array m-t-10'>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>id</th>
                                <th>Session</th>
                                <th>Date de début</th>
                                <th>Date de fin</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {productionSheets.map(productionSheet =>
                            (<tr key={productionSheet.id}>
                                <td>
                                    <button className='light-button'>
                                        <Link
                                            to={`/erp/specif-and-recipe/production-sheets/edit/${productionSheet.id}`}>Modifier</Link>
                                    </button>
                                </td>
                                <td>
                                    <button className='light-button'>
                                        <Link
                                            to={`/erp/specif-and-recipe/production-sheets/consult/${productionSheet.id}`}>Consulter</Link>
                                    </button>
                                </td>
                                <td>{productionSheet.id}</td>
                                <td>
                                    <div className="strong-1">{productionSheet.session && productionSheet.session.title}</div>
                                </td>
                                <td>
                                    <div className="strong-1">{productionSheet.session && new Date(productionSheet.session.start).toLocaleString('fr-FR')}</div>
                                </td>
                                <td>
                                    <div className="strong-1">{productionSheet.session && new Date(productionSheet.session.end).toLocaleString('fr-FR')}</div>
                                </td>
                                <td>
                                    <DeleteButton callBack={deleteProductionSheet} parameters={[productionSheet.id]} />
                                </td>
                            </tr>))
                            }
                        </tbody>
                    </table >
                    : <h5>Aucune fiche de production</h5>
                : <MidLoader />
            }
        </>
    )
}
