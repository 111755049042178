import React, { useState, useContext, useEffect } from "react"
import { DealProductsContext } from "Context/ERP/deal/DealProduct"
import { BulkContext } from "Context/CBDProtect/Bulk"
import { BatchContext } from 'Context/CBDProtect/Batch'
import MiniLoader from "Components/MiniLoader/MiniLoader"
import BulkDetailsSmall from "Components/_CBDP/Bulk/BulkDetailsSmall"
import Skeleton from "Components/Skeleton/Skeleton"

/**
 * 
 * @param {Object} defaultProduct You can pass the dealProduct directly in the component
 * @param {Int} id You can pass the ID of the dealProduct to show. Required if you didn't passed 'defaultProduct' 
 * @returns 
 */
export default function DealProductDetails({ defaultProduct = null, id = null }) {

    const [product, setProduct] = useState(defaultProduct ? defaultProduct : null)
    const [formatedBatchs, setFormatedBatchs] = useState(null)
    const [formatedBulks, setFormatedBulks] = useState(null)

    const { getBatch } = useContext(BatchContext)
    const { getBulkAnalyse, getBulk, formatBulkName } = useContext(BulkContext)
    const { getDealProduct } = useContext(DealProductsContext)

    useEffect(() => {
        if (defaultProduct) {
            setProduct(defaultProduct)
        }
    }, [defaultProduct])

    useEffect(() => {
        if (id) {
            getDealProduct(id)
                .then(product => setProduct(product))
        }
    }, [id])

    useEffect(() => {
        if (product && product.bulk_id.length) {
            setFormatedBulks(null)
            Promise.all(product.bulk_id.map(async bulk_id => {
                const bulk = await getBulk(bulk_id)
                let bulkAnalyses = await getBulkAnalyse(bulk_id)
                return { ...bulk, analyses: bulkAnalyses }
            }))
                .then(bulks => {
                    setFormatedBulks(bulks)
                })
        } else {
            setFormatedBulks([])
        }
        if (product && product.batch_id.length) {
            setFormatedBatchs(null)
            Promise.all(product.batch_id.map(async batch_id => {
                const batch = await getBatch(batch_id)
                const bulk = await getBulk(batch.bulk_id)
                return { ...batch, ddm: bulk.ddm }
            }))
                .then(batchs => setFormatedBatchs(batchs))
        } else {
            setFormatedBatchs([])
        }
    }, [product])

    return (
        <>
            <div>
                {/* <table className="beautiful-array m-b-10 m-t-10">
                            <thead>
                                <tr>
                                    <th>Quantité produite</th>
                                    <th>Quantité expédié</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{product.qty_ready_to_send + product.shipped_qty}</td>
                                    <td>{product.shipped_qty}</td>
                                </tr>
                            </tbody>
                        </table > */}
                <div className="flex gap-2 m-t-25">
                    <div className="flex-1">
                        <div className="strong-2">Numéro(s) de Bulk :</div>
                        {product && formatedBulks
                            ? formatedBulks.length
                                ? <div>
                                    {formatedBulks.map((bulk, index) =>
                                        <div className="m-b-10 list-item-bg">
                                            <BulkDetailsSmall key={index} bulk={bulk} />
                                        </div>
                                    )}
                                </div>
                                : <ul><li>Il n'y a pas encore de numéro de bulk pour ce produit</li></ul>
                            : <Skeleton height={'50px'} />
                        }
                    </div>
                    <div className="flex-1">
                        <div className="strong-2">Numéro(s) de lot :</div>
                        {product && formatedBatchs
                            ? formatedBatchs.length
                                ? <ul>
                                    {formatedBatchs.map((batch, index) => {
                                        return (
                                            <li key={index}>{batch.visible_id} ({batch.bulk_id} - ddm : {batch.ddm})</li>
                                        )
                                    })}
                                </ul>
                                : <ul><li>Il n'y a pas encore de numéro de lot pour ce produit</li></ul>
                            : <Skeleton height={'50px'} />
                        }
                    </div>
                </div>
            </div >
        </>
    )
}