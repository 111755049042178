import React, { useEffect, useContext } from 'react'
import { SignOutButton } from './SignoutButton'
import Login from './Login';
import { Link } from 'react-router-dom';
import './MyAccount.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserInfosContexte } from 'Context/UserInfosContexte';

export default function MyAccount() {
    const { userInfo, avaibleApps } = useContext(UserInfosContexte);

    const navigate = useNavigate();
    const location = useLocation()
    useEffect(() => {
        location.pathname !== `/mon-compte` && navigate(`/mon-compte`)
    }, [])

    return (
        <>
            {userInfo ? (
                <>
                    <div className='flex-center'>
                        <h1>Bonjour {userInfo.display_name.split(' ')[0]}</h1>
                        <SignOutButton />
                    </div>
                    <p className='left'>Bienvenu sur votre espace.<br />
                        Ici, vous pourrez accéder à l'ensemble des applications dont vous aurez besoin pour travailler.</p>
                    <h3 className='m-t-25'>Mes applications</h3>
                    <div className='app-container'>
                        {avaibleApps && avaibleApps.length > 0 ? avaibleApps.map(app =>
                            <div key={app.slug}>
                                <Link style={{ backgroundColor: app.parameters.colors }}
                                    className='app-button'
                                    to={`/${app.slug}`}
                                >{app.name}</Link>
                            </div>
                        ) : <h5>Vous n'avez aucune application disponible, contactez le responsable IT</h5>}
                    </div>
                </>
            ) : (<Login />)}
        </>
    )
}
