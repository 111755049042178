import React, { useState, useEffect, useContext, useRef } from 'react'
import { appConstante, categories, getStockUnitOfMeasure } from 'appConstante'
import AccordionTable from 'Components/AccordionTable/AccordionTable'
import Pagination from 'Components/Pagination/Pagination'
import { PopupContext } from 'Context/PopupContext'
import { useCallApi, useCurrentAppPermission } from 'Functions'
import { deleteEmptyObjectsField } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import './ImportsAndExport.css'
import ArticleUpdate from 'AppContainer/ERP/Stocks/ImportsAndExport/Article/ArticleUpdate'
import AddArticle from 'AppContainer/ERP/Stocks/ImportsAndExport/Article/AddArticle'
import UpdateProduct from 'AppContainer/ERP/Stocks/ImportsAndExport/Product/UpdateProduct'
import Addproduct from 'AppContainer/ERP/Stocks/ImportsAndExport/Product/Addproduct'
import { VariablesContext } from 'Context/ERP/VariablesContext'
import ArticleDetails from 'AppContainer/ERP/Stocks/ImportsAndExport/History/ArticleDetails'
import MidLoader from 'Components/MidLoader/MidLoader'
import Input from 'Components/FormElement/Input'
import SelectBrand from 'Components/_ERP/Brands/SelectBrands'
import AddHistory from './History/AddHistory'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import DeleteArticle from './Article/DeleteArticle'
import Datalist from 'Components/Dataliste/Dataliste'

export default function ImportsAndExport({ articleCategory }) {
    const [articles, setArticles] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [search, setSearch] = useState("")
    const [currenCategory, setCurrenCategory] = useState()
    const [articleFilter, setArticleFilter] = useState({})
    const [showArchive, setShowArchive] = useState(false)
    const [suppliers, setSuppliers] = useState(null)
    const [selectedSupplier, setSelectedSupplier] = useState([])
    const { addPopupContent, showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { variables } = useContext(VariablesContext)

    const callApi = useCallApi();
    const currentAppPermission = useCurrentAppPermission();
    const timeoutHandle = useRef()

    useEffect(() => {
        const controller = new AbortController();
        if (articleCategory === "Matière première" || articleCategory === "Packaging primaire") {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/suppliers`, { method: 'get' }, controller.signal, { category: articleCategory })
                .then(res => setSuppliers(res.data.data))
        }

        return () => {
            controller.abort()
        }
    }, [articleCategory])

    useEffect(() => {
        const controller = new AbortController();
        if (search.length > 0 && search.length < 3) {
            return
        }
        const delay = search ? 500 : 0
        clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            setArticles(null)
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/articles`, { method: 'get' }, controller.signal, {
                data: 'advanced',
                supplier: selectedSupplier,
                filter: {
                    category: articleCategory,
                    archive: showArchive ? 1 : 0,
                    ...deleteEmptyObjectsField(articleFilter)
                },
                page: currentPage,
                search: search,
            })
                .then(res => {
                    setArticles(res.data.data)
                    setTotalPage(res.data.total_pages)
                    res.data.total_pages === 0 && setCurrentPage(1)
                    currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
                })
                .catch(err => {
                    setArticles([])
                    if (err.response && err.response.status === 401) {
                        addNotificationContent({
                            error: true,
                            title: "Erreur",
                            content: 'Vous n\'ête pas authorisé à accéder à cette ressource',
                            infinit: false
                        })
                    } else {
                        addNotificationContent({
                            error: true,
                            title: 'Erreur',
                            content: 'Nous rencontrons une erreur avec nos serveurs',
                            infinit: false,
                        })
                    }
                })
        }, delay)

        return () => {
            controller.abort()
        }
    }, [articleCategory, selectedSupplier, currentPage, search, articleFilter, showArchive])

    const updateArticleFilter = (key, value) => {
        setArticleFilter({ ...articleFilter, [key]: value })
    }

    useEffect(() => {
        const filerCat = categories.filter((cat) => (cat.name === articleCategory))
        setCurrenCategory(filerCat[0])
    }, [articleCategory])

    const addSupplier = (supplier) => {
        showPopup(false)
        updateArticle({ id: supplier.article_id })
    }

    const updateArticle = (newArticle) => {
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article/${newArticle.id}`)
            .then(res => {
                setArticles(articles
                    .map(article => article.id === res.data.data.id ? res.data.data : article)
                    .filter(article => (article.archive === showArchive))
                )
            })
    }

    const updateArticleFromHistory = (newHistory) => {
        newHistory?.batch?.article?.id && updateArticle(newHistory?.batch?.article)
        showPopup(false)
    }

    const showConfirmRemoveArticle = article => {
        addPopupContent(<DeleteArticle article={article} deleteArticle={deleteArticle} />)
    }

    const deleteArticle = articleId => {
        setArticles(articles.filter(article => article.id !== articleId))
    }

    const addArticle = (newArticle) => {
        showPopup(false)
        newArticle.category === articleCategory && setArticles([newArticle, ...articles])
    }


    return (
        <>
            {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                <div className='flex'>
                    <button
                        style={{ margin: "0 0 5px 0" }}
                        className='strong-button'
                        onClick={() => addPopupContent(<AddArticle setter={addArticle} defaultCategory={articleCategory} />, "1200px")}
                    >Ajouter un article
                    </button>
                </div>}

            <div className='flex m-b-10'>
                <div className='flex-start start wrap'>
                    {currenCategory && variables && currenCategory.fields
                        .filter(field => (field.filter === true))
                        .map((field, index) => {
                            if (field.slug !== 'brand_id') {
                                return (
                                    <div className='filter-container' key={index} >
                                        <Datalist
                                            items={variables &&
                                                variables.filter(variable => variable.field === field.slug && variable.category === currenCategory.name)
                                                    .map(variable => ({ value: variable.value, id: variable.value }))
                                            }
                                            placeholder={field.name}
                                            width='250px'
                                            value={articleFilter[field.slug] ? articleFilter[field.slug] : ""}
                                            onSelect={updateArticleFilter}
                                            customParameters={[field.slug]}
                                        />
                                    </div>
                                )

                            } else {
                                return (
                                    <div className='filter-container' key={index} >
                                        <SelectBrand
                                            setter={updateArticleFilter}
                                            customParameters={[field.slug]}
                                            placeholder='Marque'
                                        />
                                    </div>
                                )
                            }
                        })}

                    {currenCategory && suppliers && (currenCategory.path === "matieres-premieres" || currenCategory.path === "packagings-primaires")
                        ? <Datalist
                            onSelect={setSelectedSupplier}
                            items={suppliers && suppliers.map(supplier => ({ id: supplier.supplier, value: supplier.supplier }))}
                            placeholder='Fournisseurs'
                            width='250px'
                        />
                        : null
                    }

                </div>
                <div className='column' >
                    <Input label='Recherche' value={search} onInput={setSearch} />
                    <label className='flex-center'>
                        <input className="erp-check" type='checkbox' onChange={e => setShowArchive(e.target.checked)} />
                        Afficher les articles archivés
                    </label>
                    {/* //Permettra d'afficher les produits archivé, devrai être lié au backend */}
                    {/* <label className='flex-center'>
                        <input className="erp-check" type='checkbox' onChange={e => setShowArchiveProduct(e.target.checked)} />
                        Afficher les produits archivés
                    </label> */}
                </div>
            </div>

            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
            {articles === null ? (<MidLoader />) :
                articles && articles.length > 0 ?
                    (<AccordionTable
                        columns={[
                            { title: "" },
                            { title: "Article", style: { textAlign: 'left' } },
                            { title: "" },
                            { title: "Consommation sur 30 jours" },
                            { title: "Stock" },
                            { title: "Stock prévisionnel" },
                            { title: "" },
                        ]}

                        datas={articles.map(article => {
                            const products = article.products ? [...article.products.filter(product => !product.archive).map(product => {
                                if (currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT) {
                                    return [
                                        { element: <button onClick={() => addPopupContent(<AddHistory defaultHistory={{ article_id: article.id, product_id: product.id }} setter={updateArticleFromHistory} />)} className='light-button show-on-hover'>+ / -</button>, colSpan: 1 },
                                        { element: (<button onClick={() => addPopupContent(<ArticleDetails article={article} product={product} updateArticle={updateArticle} />, '5000px', true)} className='light-button show-on-hover'>détails</button>), colSpan: 1 },
                                        { element: (<>{product.supplier}</>), colSpan: 1 },
                                        { element: (<>{product.article_reference}</>), colSpan: 1 },
                                        { element: (<>{`${product.last_30_consumption} ${getStockUnitOfMeasure(article)}`}</>), colSpan: 1 },
                                        { element: (<>{product.formated_stock}</>), colSpan: 1 },
                                        { element: (<>{`${product.forecast_stock} ${getStockUnitOfMeasure(article)}`} </>), colSpan: 1 },
                                        { element: (<>{product.price}€</>), colSpan: 1 },
                                    ]
                                } else {
                                    return [
                                        { element: (<></>), colSpan: 1 },
                                        { element: (<></>), colSpan: 1 },
                                        { element: (<>{product.supplier}</>), colSpan: 1 },
                                        { element: (<>{product.article_reference}</>), colSpan: 1 },
                                        { element: (<>{`${product.last_30_consumption} ${getStockUnitOfMeasure(article)}`}</>), colSpan: 1 },
                                        { element: (<>{product.formated_stock}</>), colSpan: 1 },
                                        { element: (<>{`${product.forecast_stock} ${getStockUnitOfMeasure(article)}`}</>), colSpan: 1 },
                                        { element: (<>{product.price}€</>), colSpan: 1 },
                                    ]
                                }
                            })] : []
                            const stockAlert = article.stock_min
                                ? article.stock < article.stock_min
                                : false

                            return {
                                mainLine: [
                                    {
                                        element: (
                                            currentAppPermission('erp') >= appConstante.parameters.CAN_READ &&
                                            <button
                                                onClick={() => addPopupContent(<ArticleDetails article={article} updateArticle={updateArticle} />, '1200px', true)}
                                                className='light-button show-on-hover'
                                            >détails
                                            </button>
                                        ), colSpan: 1
                                    },
                                    { element: (<div className='strong-2 left'>{article.name}</div>), colSpan: 2 },
                                    { element: (<div className=''>{`${article.last_30_consumption ? article.last_30_consumption.toFixed(4) * 1 : 0} ${getStockUnitOfMeasure(article)}`}</div>), colSpan: 1 },
                                    { element: (<span className='strong-2'>{article.stock && article.stock.toFixed(4) * 1}{article.stock_min ? ` / ${article.stock_min}` : null}{` ${getStockUnitOfMeasure(article)}`}</span>), colSpan: 1 },
                                    { element: (<span className='strong-2'>{article.forecast_stock && article.forecast_stock} {` ${getStockUnitOfMeasure(article)}`}</span>), colSpan: 1 },
                                    { element: currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT ? <DeleteButton callBack={showConfirmRemoveArticle} parameters={[article]} /> : null, colSpan: 1 },
                                ],
                                mainLineStyle: stockAlert ? { color: 'rgba(2555,60,60)' } : {},

                                subLines: [
                                    products.length > 0 ?
                                        [
                                            { element: null, colSpan: 1 },
                                            { element: null, colSpan: 1 },
                                            { element: (<div className='strong-2'>Fournisseur</div>), colSpan: 1 },
                                            { element: (<div className='strong-2'>Référence Article</div>), colSpan: 1 },
                                            { element: (<div className=''>Consommation sur 30 jours</div>), colSpan: 1 },
                                            { element: (<div className='strong-2'>Stock</div>), colSpan: 1 },
                                            { element: (<div className='strong-2'>Stock prévisionnel</div>), colSpan: 1 },
                                            { element: (<div className='strong-2'>Prix unitaire</div>), colSpan: 1 },
                                        ]
                                        : [{
                                            element: (<div>Il n'y a actuellement aucun fournisseur pour cet article</div>),
                                            colSpan: 11
                                        }],
                                    ...products,
                                    currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT ? [{
                                        element: (
                                            <button onClick={() => addPopupContent(<Addproduct article={article} setter={addSupplier} />, '1200px')} className='light-button'>
                                                Ajouter un fournisseur
                                            </button>),
                                        colSpan: 11
                                    }] : []
                                ]
                            }
                        })}
                    />)
                    : <h5>Aucun article à présenter</h5>
            }

        </>
    )
}
