import React, { useEffect, useState, useContext } from "react";
import { useCallApi } from "Functions";
import { appConstante } from "appConstante";
import { PopupContext } from "Context/PopupContext";
import { NotificationContext } from "Context/NotificationContext";
import { OrganizationsContext } from "Context/ERP/OrganizationsContext";
import Pagination from "Components/Pagination/Pagination";
import SelectBrand from "Components/_ERP/Brands/SelectBrands";
import UpdateBrands from "./UpdateBrands";
import AddBrands from "./AddBrands";
import SelectOrganization from "../../../../Components/_ERP/Brands/SelectOrganization";
import MidLoader from "Components/MidLoader/MidLoader";
import DeleteBrands from "./DeleteBrands";
import DeleteButton from "Components/DeleteButton/DeleteButton";
import { useCurrentAppPermission } from "Functions";

export default function () {
  const callApi = useCallApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setselectedBrandId] = useState(null);
  const [SelectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [formatedBrands, setFormatedBrands] = useState(null);
  const { addPopupContent } = useContext(PopupContext);
  const { getOrganizationFromId } = useContext(OrganizationsContext);
  const { addNotificationContent } = useContext(NotificationContext);

  const currentAppPermission = useCurrentAppPermission();

  // AJOUTER BRAND //
  const addBrand = (newBrand) => setBrands([newBrand, ...brands]);

  // MODIFIER BRAND //
  const updatedBrand = (newBrand) =>
    setBrands(
      brands.map((brand) => (brand.id === newBrand.id ? newBrand : brand))
    );

  // DELETE POPUP //
  const filterBrand = (id) =>
    setBrands(brands.filter((brand) => id !== brand.id));

  const showDeleteBrandPopup = (brand, filterBrand) => {
    addPopupContent(<DeleteBrands brand={brand} filterBrand={filterBrand} />);
  };

  useEffect(() => {
    const linkOrganizationsToBrands = () => {
      Promise.all(brands.map((brand) => getOrganizationFromBrand(brand))).then(
        (brandsWithOrganization) => {
          setFormatedBrands(brandsWithOrganization);
        }
      );
    };
    linkOrganizationsToBrands();
  }, [brands]);

  const getOrganizationFromBrand = async (brand) => {
    if (brand.organization_id) {
      const responseOrganization = await getOrganizationFromId(
        brand.organization_id
      );
      return { ...brand, organization: responseOrganization };
    } else {
      return brand;
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const url = selectedBrandId
      ? `${appConstante.servers.PANORAMA_ENDPOINT}/erp/brand/${selectedBrandId}`
      : SelectedOrganizationId
        ? `${appConstante.servers.PANORAMA_ENDPOINT}/erp/brands?organization_id=${SelectedOrganizationId}`
        : `${appConstante.servers.PANORAMA_ENDPOINT}/erp/brands`;

    setFormatedBrands(null)
    callApi(url, { method: "get" }, controller.signal, {
      limit: 10,
      page: currentPage,
    })
      .then((res) => {
        res.status === 204 && setFormatedBrands([]);
        setBrands(
          Array.isArray(res.data.data) ? res.data.data : [res.data.data]
        );
        setTotalPage(res.data.total_pages);
        res.data.total_pages === 0 && setCurrentPage(1);
        currentPage > res.data.total_pages &&
          res.data.total_pages > 0 &&
          setCurrentPage(res.data.total_pages);
      })
      .catch((err) => {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: "Nous rencontrons une erreur avec nos serveurs",
          infinit: false,
        });
      });

    return () => {
      controller.abort();
    };
  }, [currentPage, selectedBrandId, SelectedOrganizationId]);
  return (
    <>
      {currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && (
        <button
          className="strong-button m-l-0"
          onClick={() => addPopupContent(<AddBrands addBrand={addBrand} />)}
        >
          Ajouter une marque
        </button>
      )}

      <div className="flex-center flex-start m-b-25">
        <div className="m-r-10">
          <SelectBrand setter={setselectedBrandId} width="300px" />
        </div>

        <SelectOrganization setter={setSelectedOrganizationId} width="300px" />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
      />
      {formatedBrands === null
        ? <MidLoader />
        : formatedBrands.length === 0
          ? <h5>Aucune marque à afficher pour cette organisation</h5>
          : <table className="beautiful-array">
            <thead>
              <tr>
                {currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && (
                  <th></th>
                )}
                <th>Nom de la marque</th>
                <th>Logo</th>
                <th>Identifiant Pipedrive</th>
                <th>Site internet</th>
                {currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && (
                  <th></th>
                )}
              </tr>
            </thead>
            <tbody>
              {formatedBrands.map((brand) => {
                return (
                  <tr key={brand.id}>
                    {currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && (
                      <td>
                        <button
                          className="light-button show-on-hover"
                          onClick={() =>
                            addPopupContent(
                              <UpdateBrands
                                brand={brand}
                                updatedBrand={updatedBrand}
                              />
                            )
                          }
                        >
                          Update
                        </button>
                      </td>
                    )}

                    <td>{brand.name}</td>
                    <td>
                      <img src={brand.logo_url} style={{ height: "60px" }} />
                    </td>
                    <td>{brand.organization && brand.organization.name}</td>
                    <td>{brand.site_url && <a href={brand.site_url} target="_blank">{brand.site_url}</a>}</td>
                    {currentAppPermission("erp") >= appConstante.parameters.CAN_EDIT && (
                      <td>
                        <DeleteButton
                          callBack={showDeleteBrandPopup}
                          parameters={[brand, filterBrand]}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
      }
    </>
  );
}
