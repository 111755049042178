import { appConstante } from 'appConstante'
import { VariablesContext } from 'Context/ERP/VariablesContext'
import { NotificationContext } from 'Context/NotificationContext'
import { PopupContext } from 'Context/PopupContext'
import { useCallApi } from 'Functions'
import { useContext, useState } from 'react'
import ExportArticles from './ExportArticles'

export default function Parameters() {

    const callApi = useCallApi()


    const { addNotificationContent } = useContext(NotificationContext)
    const { addPopupContent } = useContext(PopupContext)
    const { setVariables } = useContext(VariablesContext)

    const [loadingExport, setLoadingExport] = useState(false)

    const showExportArticles = () => {
        addPopupContent(<ExportArticles setLoadingExport={setLoadingExport} />, "800px")
    }


    const reloadVariables = () => {
        addNotificationContent({
            error: false,
            title: 'Mise à jour des variables',
            content: 'Les variables seront mise à jours dans un cours instant'
        })
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/variables/reload`)
            .then(res => setVariables(res.data))
            .catch(err => addNotificationContent({
                error: true,
                title: 'Erreur',
                content: `Erreur lors de la mise à jours des variables (${err.response.data.message})`,
            })
            )
    }
    return (
        <>
            <h1>Paramètres</h1>
            <h3>Gestions des stocks</h3>
            <div className='flex'>
                <p className='left'>Actualiser les variables disponibles pour le filtre des produits</p>
                <button style={{ margin: "0 0 5px 0" }}
                    className='light-button'
                    onClick={() => reloadVariables()}
                >Actualiser les variables
                </button>
            </div>
            <div className='flex'>
                <p className='left'>Exporter la catégorie d'article désirée dans un fichiers excel</p>
                <button disabled={loadingExport} style={{ margin: "0 0 5px 0" }}
                    className='light-button'
                    onClick={() => showExportArticles()}
                >Exporter
                </button>
            </div>
        </>
    )
}
