import React, { useState } from 'react'
import './option.css';
import OptionFields from './OptionFields';
import { useCheckOption, emptyOption } from './optionHelpers';

export default function AddOption({ addOption }) {

    const checkOption = useCheckOption();

    const [option, setOption] = useState(emptyOption)


    const addCurrentOption = () => {
        if (checkOption(option)) {
            setOption(emptyOption)
            addOption(option)
        }
    }

    return (
        <div className="form-option-container" >
            <OptionFields option={option} setOption={setOption} />
            <button onClick={() => addCurrentOption()} type='button' className='strong-button center-block m-t-25'>Ajouter l'option</button>
        </div>
    )
}
