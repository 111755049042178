import React, { useContext, useEffect, useState } from 'react'
import { getUnitMeasureType, useCallAnalysesApi, useCallApi } from 'Functions'
import { getConversionUnitFactor } from 'appConstante'
import Datalist from 'Components/Dataliste/Dataliste'
import Input from 'Components/FormElement/Input'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { getBatchStock, getDensityForm } from 'AppContainer/ERP/ERPConstantes'
import { ArticleContext } from 'Context/ERP/ArticleContext'

export default function BatchsIngredient({ passedBatch, setIngredient, ingredient }) {

    const [batch, setBatch] = useState(passedBatch)
    const { getBatch } = useContext(ArticleContext)

    useEffect(() => {
        setBatch(passedBatch)
    }, [passedBatch.cannabinoids, passedBatch.ingredient_total_mass])

    const [analysesResults, setAnalyseResults] = useState([])
    const [reloadResults, setReloadResults] = useState(false)


    const deleteBatch = (batchIndex) => {
        setIngredient(prvIngredient => {
            const newIngredient = { ...prvIngredient }
            newIngredient.batchs = newIngredient.batchs.filter((_, idx) => idx !== batchIndex)
            return newIngredient
        })
    }

    const [selectedSupplierId, setSelectedSupplierId] = useState(ingredient?.article?.products?.length === 1 ? ingredient.article.products[0].id : '')
    const [currentProduct, setCurrendProduct] = useState()
    const [availableBatchs, setAvailableBatchs] = useState(null)

    const callAnalysesApi = useCallAnalysesApi()

    useEffect(() => {
        if (currentProduct && currentProduct.history.length === 1) {
            updateBatchPropriety("batch_id", currentProduct.history[0].id)
        }
    }, [currentProduct])

    const getBatchsAnalysesResults = async (batchIds, signal) => {
        const results = await Promise.all(batchIds.map(batchId => {
            return callAnalysesApi(`analyses`, { method: 'get', signal: signal }, { batch_id: batchId })
        }))
        return results && (results.map(result => result.data.summary))
    }

    useEffect(() => {
        const controller = new AbortController()
        if (currentProduct && currentProduct.history && passedBatch.type === "cannabinoids") {
            setAnalyseResults([])
            const batchIds = currentProduct.history
                // .filter(batch => batch.analyses.length > 0)
                .filter(batch => batch.quantity > 0)
                .map(batch => ({ ...batch, id: batch.id, value: batch.batch_number }))
            getBatchsAnalysesResults(batchIds.map(batch => batch.id), controller.signal)
                .then(results => {
                    setAnalyseResults(results)
                    setAvailableBatchs(batchIds)
                }).catch((err) => {
                    setAnalyseResults(null)
                });
        } else if (currentProduct && currentProduct.history) {
            setAvailableBatchs(currentProduct.history
                .filter(batch => getBatchStock(batch))
                .map(batch => {
                    const stock = getBatchStock(batch);
                    return {
                        id: batch.id,
                        value: `${batch.batch_number} (qty: ${stock} ${ingredient?.article?.unit_of_measure}) ${batch.ddm ? `ddm : ${new Date(batch.ddm).toLocaleDateString('fr')}` : ''}`
                    }
                }))
        }

        return () => {
            controller.abort()
        }

    }, [currentProduct, reloadResults])

    useEffect(() => {
        setCurrendProduct(ingredient?.article?.products?.find(product => product.id === selectedSupplierId))
        passedBatch.type === "cannabinoids" && setAvailableBatchs(null)
    }, [selectedSupplierId])

    const updateBatchPropriety = (key, value) => {
        setBatch(oldBatch => ({ ...oldBatch, [key]: value }))
    }

    useEffect(() => {
        if (batch.real_quantity) {
            const densityForm = getDensityForm('masse', getUnitMeasureType(ingredient?.article?.unit_of_measure))
            const density = ingredient?.info?.density
            if (densityForm && density) {
                const convertedQuantity = densityForm(batch.real_quantity, density) * getConversionUnitFactor('g', ingredient?.article?.unit_of_measure)
                updateBatchPropriety('converted_real_quantity', convertedQuantity.toFixed(4))
            }
        }
    }, [batch.real_quantity])

    useEffect(() => {
        if (batch.real_quantity) {
            if (batch.adapted_quantity) {
                setBatch(oldBatch => ({ ...oldBatch, "percent_real_quantity": (batch.real_quantity / batch.adapted_quantity) * 100 }))
            }
        }
    }, [batch.real_quantity, batch.adapted_quantity])

    useEffect(() => {
        if (batch.batch_id) {
            getBatch(batch.batch_id)
                .then(batch => updateBatchPropriety("datas", batch))
        }
    }, [batch.batch_id])

    useEffect(() => {
        if (batch.type === "cannabinoids") {
            if (batch.batch_id && analysesResults) {
                const results = analysesResults.find(result => result.batch_id == batch.batch_id)
                if (results) {
                    updateBatchPropriety("results", results)
                    if (results[batch.cannabinoids]) {
                        const mean = parseFloat(results[batch.cannabinoids].mean)
                        batch.cannabinoids && updateBatchPropriety("selected_cannabinoid", results[batch.cannabinoids])
                        batch.ingredient_total_mass && batch.cannabinoids && updateBatchPropriety("adapted_quantity", (batch.ingredient_total_mass / (mean / 100)).toFixed(4) * 1)
                    }
                }
            }
        } else {
            batch.ingredient_total_mass && updateBatchPropriety("adapted_quantity", (batch.ingredient_total_mass).toFixed(4) * 1)
        }
    }, [batch.batch_id, batch.selected_cannabinoid])

    useEffect(() => {
        setIngredient(prvIngredient => {
            const newIngredient = { ...prvIngredient }
            newIngredient.batchs = newIngredient.batchs.map((ingredientBatch, idx) =>
                idx === batch.index
                    ? batch
                    : ingredientBatch
            )
            return newIngredient
        })
    }, [batch])


    return (
        <div className='batch-ingredient'>
            <div className='flex-center gap-1 batch-ingredient'>
                <div className='flex start gap-1'>
                    <div>
                        <Datalist
                            defaultId={selectedSupplierId}
                            placeholder={'fournisseur'}
                            items={ingredient?.article?.products?.map(product => ({ id: product.id, value: product.supplier }))}
                            onSelect={setSelectedSupplierId}
                            style={{ marginRight: '0 px' }}
                            required={true}
                            width='100%'
                        />
                    </div>
                    {ingredient?.article?.products && selectedSupplierId
                        ? availableBatchs
                            ? availableBatchs.length > 0
                                ? <div >
                                    <Datalist
                                        placeholder={'n° de lot'}
                                        items={availableBatchs}
                                        onSelect={updateBatchPropriety}
                                        customParameters={['batch_id']}
                                        required={true}
                                    />
                                </div>
                                : <div className='strong-2 self-center'>Aucun batch disponible</div>
                            : <>
                                {passedBatch.type === "cannabinoids"
                                    ? analysesResults
                                        ? <MiniLoader />
                                        : <button
                                            type="button"
                                            onClick={e => setReloadResults(prvReloadedAnalyses => !prvReloadedAnalyses)}
                                            className='light-button self-center m-l-10'>
                                            Charger les analyses
                                        </button>
                                    : <MiniLoader />
                                }
                            </>
                        : null
                    }
                    {selectedSupplierId && batch.batch_id
                        ? <div style={{
                            padding: '5px',
                            backgroundColor: (batch.percent_real_quantity < 101 && batch.percent_real_quantity > 99)
                                || (((ingredient.info.quantity_variation / ingredient.info.marged_absolute_mass_quantity) * 100 < 2)
                                    && ((ingredient.info.quantity_variation / ingredient.info.marged_absolute_mass_quantity) * 100 > -2))
                                ? 'rgba(0,0,0,0)'
                                : '#ff99a2',
                        }}>
                            <Input
                                type='text'
                                value={passedBatch.real_quantity ?? ''}
                                onInput={updateBatchPropriety}
                                label="quantité pesée"
                                customParameters={['real_quantity']}
                                required={true} />
                        </div>
                        : null
                    }
                </div>
                <DeleteButton alwaysDisplay={true} callBack={deleteBatch} parameters={[passedBatch.index]} />
            </div>
            <div>
                {
                    ingredient.type === "cannabinoids" && batch.batch_id
                        ? batch.selected_cannabinoid?.mean
                            ? <div className='flex-start'>
                                <div className='m-r-10'>concentration pour {batch.cannabinoids} :</div>
                                <div className='strong-2'>{batch.selected_cannabinoid.mean}%</div>
                            </div>
                            : <p>Pas de résultats pour ce cannabinoid</p>
                        : null
                }
            </div>
        </div>
    )
}
