import React from 'react'
import './option.css';
import { emptyValue } from './optionHelpers';


export default function OptionFields({ option, setOption }) {

    const updateOption = (key, value) => {
        setOption(option => {
            return { ...option, [key]: value }
        })
    }

    const addValue = () => {
        setOption(option => {
            return { ...option, values: [...option.values, emptyValue] }
        })
    }

    const updateValue = (index, key, value) => {
        setOption(option => {
            return {
                ...option,
                values: [
                    ...option.values.map((o, idx) =>
                        idx === index
                            ? { ...o, [key]: value }
                            : o
                    )
                ]
            }
        })
    }
    const deleteValue = (index, name = "") => {
        window.confirm(`Supprimer l'option ${name}?`)
            && setOption(option => {
                return {
                    ...option,
                    values: [
                        ...option.values.filter((_, idx) => idx !== index)
                    ]
                }
            })
    }

    return (
        <>
            <div className='flex input'>
                <label>Nom de l'option</label>
                <input placeholder='Gout...' value={option.name} onInput={e => updateOption('name', e.target.value)} />
            </div>
            <div className='column'>
                <label className='left'>Description de l'option</label>
                <textarea value={option.description} onInput={e => updateOption("description", e.target.value)} />
            </div>
            <div className='flex-center wrap m-t-10 gap-1'>
                {option.values && Array.isArray(option.values) &&
                    option.values.map((value, index) =>
                        <div className="option-value half-m-width" key={index}>
                            <div className='delete-value' onClick={() => deleteValue(index, value.value)}>×</div>
                            <div className='flex input'>
                                <label>Valeur</label>
                                <input className='half-m-width' placeholder='Menthe' value={value.value} onInput={e => updateValue(index, 'value', e.target.value)} />
                            </div>
                            <div className='flex'>
                                <label>Prix</label>
                                <div className='column m-b-10'>
                                    <input type='number' placeholder='20' value={value.price} onInput={e => updateValue(index, 'price', e.target.value)} />
                                    {value.price != 0 &&
                                        <div>
                                            <select value={value.type} onChange={e => updateValue(index, 'type', e.target.value)}>
                                                <option value={'0'}>Somme fix</option>
                                                <option value={'1'}>% du prix</option>
                                            </select>
                                        </div>}
                                </div>
                            </div>
                            <div className='column'>
                                <label className='left'>Description de la valeur de l'option</label>
                                <textarea value={value.description} onInput={e => updateValue(index, 'description', e.target.value)} />
                            </div>
                        </div>
                    )}
                <div className='half-m-width'>
                    <button onClick={() => addValue()} type='button' className='light-button center-block'>+</button>
                </div>
            </div>
        </>
    )
}
