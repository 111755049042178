import React, { createContext, useState, useEffect, useContext } from "react"
import { NotificationContext } from "Context/NotificationContext"
import { MslAccessContext } from "./MslAccessContext"
import { useCallMSLApi } from "Functions"

export const CatalogueContext = createContext()

function CatalogueContextProvider(props) {
    const [catalogueProducts, setCatalogueProducts] = useState(null)
    const [draft, setDraft] = useState(false)
    const [publicProduct, setPublicProduct] = useState(null)

    const { mslNonce } = useContext(MslAccessContext)

    const callMSLApi = useCallMSLApi()

    const { addNotificationContent } = useContext(NotificationContext)
    useEffect(() => {
        const controller = new AbortController()
        if (mslNonce) {
            setCatalogueProducts(null)
            callMSLApi(`catalogue`, { method: "get", signal: controller.signal }, { draft: draft, 'raw-results': true, public: publicProduct })
                .then(res => {
                    setCatalogueProducts(res.data.data.map(product => {
                        return ({
                            ...product,
                            value: product.name
                        })
                    }))
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: err.response.data.message,
                    })
                })
        }

        return () => {
            controller.abort()
        }
    }, [mslNonce, draft, publicProduct])

    return (
        <CatalogueContext.Provider value={{ catalogueProducts, setCatalogueProducts, draft, setDraft, publicProduct, setPublicProduct }}>
            {props.children}
        </CatalogueContext.Provider>
    )
}

export default CatalogueContextProvider
