import React from 'react'
import { useState, useEffect } from 'react'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import 'AppContainer/DASHBOARD/chart.css'
import Comments from 'Components/GENERAL/Comments/Comments'
import ConsultProductSpecification from 'AppContainer/ERP/SpecificationsAndRecipe/ProductSpecification/ConsultProductSpecification'
import LastNavBar from 'Components/NavBar/LastNavBar/LastNavBar'
import ConsultHistory from 'AppContainer/ERP/Stocks/ImportsAndExport/History/ConsultHistory'
import ArticleUpdate from '../Article/ArticleUpdate'
import UpdateProduct from '../Product/UpdateProduct'
import ConsultFiles from 'Components/GENERAL/Files/ConsultFiles'
import CreateProductSpecification from 'AppContainer/ERP/SpecificationsAndRecipe/ProductSpecification/CreateProductSpecification'

export default function ArticleDetails({ article, product, updateArticle }) {

  const [specifProd, setSpecifProd] = useState()
  const [currentCategory, setCurrentCategory] = useState('Historique')

  const callApi = useCallApi();

  const getArticleSpecif = (articleId, SignalController = null) => {
    return new Promise((resolve, reject) => {
      callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification`, { method: 'get' }, SignalController, { article_id: articleId })
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  useEffect(() => {
    const controller = new AbortController()
    getArticleSpecif(article.id, controller.signal)
      .then(specifProd => { setSpecifProd(specifProd) })
    return () => {
      controller.abort
    }
  }, [article])

  const itemsNavBar = [
    { label: 'Historique', active: true, onClick: setCurrentCategory, value: "Historique" },
    { label: 'Spécification produit', active: false, onClick: setCurrentCategory, value: "Spécification produit" },
    { label: 'Fichiers', active: false, onClick: setCurrentCategory, value: "Fichiers" },
  ]

  console.log(article);
  return (
    <>
      {product
        ? <h2>fournisseur {product.supplier} de l'article {article.name}</h2>
        : <h2 className='m-0'>Détails de {article.name}</h2>}
      <>
        <div className='m-b-25 flex m-t-10 gap-1'>
          <div>
            <div className='flex-center primary-color-bg'>
              <ArticleUpdate article={article} setter={updateArticle} column={true} />
            </div>
            {
              article?.products?.length
                ? <div className='flex column m-t-10'>
                  {product
                    ? <div className='primary-color-bg m-b-10'>
                      <UpdateProduct article={article} setter={updateArticle} product={product} column={true} />
                    </div>
                    : article.products && article.products.length
                      ? article.products.map((prod, index) => {
                        return (
                          <div key={index} className='primary-color-bg m-b-10'>
                            <UpdateProduct article={article} setter={updateArticle} product={prod} column={true} />
                          </div>
                        )
                      })
                      : null}
                </div>
                : null
            }
          </div>

          <div className='full-width'>
            <LastNavBar items={article.category === "Produit fini" ? itemsNavBar : itemsNavBar.filter(item => item.label !== 'Spécification produit')} />
            {currentCategory === "Historique"
              ? <ConsultHistory article={article} setter={updateArticle} product={product} />
              : currentCategory === "Fichiers"
                ? <ConsultFiles target='article' targetId={article.id} />
                : currentCategory === "Spécification produit"
                  ? specifProd
                    ? <ConsultProductSpecification
                      id={specifProd.id}
                      defaultSteps={{ primaryConditionning: true, secondaryConditionning: true, recipe: true }}
                    />
                    : <>
                      <p className='m-t-50 m-b-50'>Il n'existe pas encore de spécification pour cette article. Merci d'en ajouter une</p>
                      <CreateProductSpecification
                        defaultSpectif={{
                          article_id: article.id
                        }}
                      />
                    </>
                  : null}
            <div className='m-t-25 m-b-25'>
              <Comments target="Article" target_id={article.id} />
            </div>
          </div>
        </div>
      </>
    </>
  )
}
