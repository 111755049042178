import React, { useContext, useEffect } from 'react'
import './ProductThumbnail.css'
import ProductDetail from './ProductDetail'
import UpdateProduct from './UpdateProduct'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { PopupContext } from 'Context/PopupContext'
import { NotificationContext } from 'Context/NotificationContext'
import { removeDuplicates, useCallMSLApi } from 'Functions'
import AddProduct from './AddProduct'
import { CustomProductsContext } from 'Context/Commercial/CustomProductsContext'
import { appConstante } from 'appConstante'
import { OrganizationsContext } from 'Context/ERP/OrganizationsContext'
import { CatalogueContext } from 'Context/Commercial/CatalogueContext'

export default function ProductThumbnail({ product, updateProduct }) {

    const minQty = product.price.min_qty ?? 1
    const minPrice = product.price.min_price ?? product.price.max_price ?? "-"

    const { addPopupContent, showPopup } = useContext(PopupContext);
    const { addNotificationContent } = useContext(NotificationContext);
    const { setCustomProducts, getProducts } = useContext(CustomProductsContext);
    const { getOrganizationFromId } = useContext(OrganizationsContext)
    const { setCatalogueProducts } = useContext(CatalogueContext)

    const showProduct = () => {
        addPopupContent(<ProductDetail product={product} />, '1200px')
    }

    const callMSLApi = useCallMSLApi()

    const deleteCurrentProduct = (product, customProducts) => {
        const config = {
            method: 'delete',
        }
        addNotificationContent({
            error: false,
            title: "Suppression en cours",
            content: <MiniLoader />,
            infinit: true,
        })
        showPopup(false)

        callMSLApi(`product`, config, { id: product.id })
            .then(res => {
                addNotificationContent({
                    error: false,
                    title: "Succès",
                    content: `Le produit ${product.name} à été supprimé avec succès`,
                })
                setCatalogueProducts(products => products.filter(p => p.id !== product.id))
                setCustomProducts(products => products.filter(p => p.reference_id !== product.id))
            })
            .catch(error => {
                addNotificationContent({
                    error: true,
                    title: "Erreur",
                    content: `Le produit n\'a pas pu être supprimé : ${error.response.data.message}`,
                })
            })
    }

    const showDeleteProduct = async (product) => {
        const customProducts = await getProducts(product.id)

        const customProdOrganizations = await Promise.all(customProducts.map(async customProd => {
            const organization = await getOrganizationFromId(customProd.organization_id)
            return organization?.name
        }))

        const formatedCustomProdOrganizations = customProdOrganizations.filter(customProd => customProd)
        const uniqueOrganizations = removeDuplicates(formatedCustomProdOrganizations)

        addPopupContent(
            <>
                {
                    formatedCustomProdOrganizations
                        ? <>
                            <h2>Suppression du produit '{product.name}'</h2>
                            {
                                product.catalogue
                                    ? <>
                                        <p className='error strong-2 m-b-10'>
                                            Attention ! En supprimant ce produit,
                                            vous supprimerez aussi {formatedCustomProdOrganizations.length} produits personnalisés
                                            {
                                                uniqueOrganizations && uniqueOrganizations.length
                                                    ? ' associés aux organisations suivantes :'
                                                    : null
                                            }
                                        </p>
                                        {uniqueOrganizations && uniqueOrganizations.length
                                            ? <div className='flex-start wrap gap-1 m-b-25'>
                                                {uniqueOrganizations.map((org, index) => {
                                                    return (
                                                        <label key={index} className='primary-color-bg'>
                                                            {org}
                                                        </label>
                                                    )
                                                })}
                                            </div>
                                            : null
                                        }
                                    </>
                                    : null
                            }
                            <div className='flex' style={{ maxWidth: '750px', margin: "auto" }}>
                                <button onClick={() => showPopup(false)} className='light-button'>Annuler</button>
                                <button onClick={() => deleteCurrentProduct(product, customProducts)} className='light-button error-strong-button'>Supprimer</button>
                            </div>
                        </>
                        : <MiniLoader />}
            </>
            , "900px")
    }
    const showUpdateProduct = (product) => {
        addPopupContent(
            <UpdateProduct productToUpdate={product} updateProduct={updateProduct} />
            , "1200px")
    }

    const showDuplicateProduct = (product) => {
        addPopupContent(
            <AddProduct defautProduct={product} catalogue={true} />
            , "1200px")
    }

    return (
        <div className={`product-thumbnail product-${product.id}`}>
            <div onClick={() => showProduct()} className='product-abstract-container'>
                {product.public && product.catalogue
                    ? <img className="public-logo" src={appConstante.parameters.LOGO_URL} />
                    : null}
                <div className='product-picture-container'>
                    <img className='product-picture' src={product.product_picture} />
                </div>
                <div className='product-abstract-categorie'>{product.category}</div>
                <div className='product-abstract-title'>{product.name}</div>
                <div className='product-details'>
                    <div className='product-moq'>Quantité minimum: <strong>{minQty}</strong></div>
                    <div className='product-start-price'>À partir de <span className="product-price-thumbnails"><strong>{minPrice}</strong>€</span></div>
                </div>
            </div>
            <div className='flex gap-1'>
                <button onClick={() => showDeleteProduct(product)} className='light-button delete-button strong-2 half-m-width'>✕</button>
                <button onClick={() => showDuplicateProduct(product)} className='light-button strong-2 half-m-width'>➕</button>
                <button onClick={() => showUpdateProduct(product)} className="light-button strong-2 half-m-width">&#128393;</button>
            </div>
        </div>
    )
}
