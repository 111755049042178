import React, { useState } from 'react'
import { cannabinoidsResultFields } from '../analysesConstantes'

export default function ResultsFields({ analyse, setter }) {
    const [showAcide, setShowAcide] = useState(false)

    // console.log(analyse);

    return (
        <>
            <label className='flex-center flex-start gap-1'>
                <input className='erp-check' type={"checkbox"} checked={showAcide} onChange={e => setShowAcide(e.target.checked)} />
                Afficher les formes acides
            </label>
            <div className='flex-basis wrap m-t-10 m-b-10'>
                {
                    cannabinoidsResultFields
                        .filter(field => showAcide ? true : !field.acide)
                        .filter(field => field.condition
                            ? (analyse?.produit?.option && analyse.produit.option[field.key]
                                || analyse.produit?.type_analyse?.includes(`${field.key} `)
                                || analyse.produit?.type_analyse?.includes(`${field.key}<`)
                            )
                            : true)
                        .map(field =>
                            <div key={field.label} className='half-m-width flex-center m-b-10'>
                                <label>{field.label}</label>
                                <input
                                    type="number"
                                    value={analyse.results[field.key]}
                                    onInput={e => setter(e.target.value, 'results', field.key)}
                                    placeholder={`% ${field.label}`}
                                    step={0.001}
                                    required
                                />
                            </div>
                        )
                }
                <div className='half-m-width flex-center m-b-10'>
                    <div>
                        <label>Humidité :</label>
                        <br />
                        <div>Laisser vide si non mesuré</div>
                    </div>
                    <input
                        type="number"
                        value={analyse.humidity ?? ''}
                        onInput={e => setter(e.target.value, 'humidity')}
                        placeholder="% d'humidité"
                        step={0.001}
                    />
                </div>
            </div>
        </>
    )
}
