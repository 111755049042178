import React, { useContext } from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from 'Context/NotificationContext'
import { useCallAnalysesApi } from 'Functions'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function DeleteAnalyseProduct({ product, deleteAnalyseProduct }) {

    const callAnalysesApi = useCallAnalysesApi()
    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const removeAnalyseProduct = () => {
        addNotificationContent({
            title: 'Suppression en cours',
            content: <MiniLoader />,
        })
        callAnalysesApi(`products`, { method: 'delete' }, { num_ref: product.num_reference })
            .then(res => {
                deleteAnalyseProduct(product.num_reference)
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "Le produit a été supprimé",
                    infinit: false
                })
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response?.data?.message ?? "Une erreur est survenue",
                    infinit: false
                })
            })
    }
    return (
        <>
            <h2>Supprimer le produit de référence : {product.num_reference}</h2>
            <p>Attention, cette suppression est irréversible. Les données associés ne serons plus accessibles</p>
            <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
                <button
                    type="button"
                    onClick={() => showPopup(false)}
                    className="erp-button erp-big-button"
                >
                    Annuler
                </button>
                <button
                    className="erp-button erp-big-button error-strong-button"
                    style={{ display: "block", margin: "auto" }}
                    onClick={() => removeAnalyseProduct()}
                >
                    Supprimer
                </button>
            </div>
        </>
    )
}
