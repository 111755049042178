import React, { useEffect, useContext, useState } from 'react'
import { PopupContext } from 'Context/PopupContext'
import { formatNumber, getUnitMeasureType, useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import Input from 'Components/FormElement/Input';
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import AddArticle from 'AppContainer/ERP/Stocks/ImportsAndExport/Article/AddArticle'
import '../Recipe.css'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import IngredientField from './Ingredient/IngredientField'

export default function RecipeFields({ recipe, setRecipe }) {
    const callApi = useCallApi()
    const { addPopupContent2, showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const [articles, setArticles] = useState(null)
    const [newStep, setNewStep] = useState({
        name: "",
        description: ""
    })

    const [selectedSteps, setSelectedSteps] = useState(recipe.steps)

    useEffect(() => {
        setRecipe({ ...recipe, steps: selectedSteps })
    }, [selectedSteps])

    useEffect(() => {
        if (recipe.bases.length) {
            let totalBases = 0
            recipe.bases.forEach(base => {
                totalBases += parseFloat(base.quantity)
            });

            parseFloat(totalBases) != 100
                ? addNotificationContent({
                    error: true,
                    content: `La répartition total des bases doit être égale à 100% (${formatNumber(100-(totalBases))} restant)`,
                    infinit: true
                })
                : addNotificationContent({})
        }
    }, [recipe.bases])

    const emptyIngredients = { article_id: '', quantity: '' }


    const updateRecipe = (key, value) => {
        setRecipe({ ...recipe, [key]: value })
    }

    const addEmptyIngredient = (key) => () => {
        setRecipe(prevRecipe => {
            if (!prevRecipe.hasOwnProperty(key)) {
                return prevRecipe
            };
            let newIngredient = emptyIngredients

            if (key === "bases" && prevRecipe[key].length === 0) {
                newIngredient.quantity = 100
            }
            const updatedArray = [...prevRecipe[key], newIngredient]
            return { ...prevRecipe, [key]: updatedArray }
        })
    }

    const updateElement = (key) => (index, subkey, value) => {
        setRecipe(prevRecipe => {
            if (!prevRecipe.hasOwnProperty(key)) return prevRecipe;
            const updatedArray = prevRecipe[key].map((element, idx) =>
                idx === index
                    ? { ...element, [subkey]: value }
                    : element
            )
            return { ...prevRecipe, [key]: updatedArray }
        })
    }

    const deleteElement = (key) => (index) => {
        setRecipe(prevRecipe => {
            if (!prevRecipe.hasOwnProperty(key)) return prevRecipe;
            const updatedArray = prevRecipe[key].filter((_, idx) => idx !== index)
            return { ...prevRecipe, [key]: updatedArray }
        })
    }

    // cannabinoids
    const addRecipeCannabinoids = addEmptyIngredient("cannabinoids")
    const updateRecipeCannabinoids = updateElement("cannabinoids")
    const deleteRecipeCannabinoids = deleteElement("cannabinoids")

    // bases
    const addRecipeBases = addEmptyIngredient("bases")
    const updateRecipeBases = updateElement("bases")
    const deleteRecipeBases = deleteElement("bases")

    // other ingredients
    const addRecipeOtherIngredients = addEmptyIngredient("other_ingredients")
    const updateRecipeOtherIngredients = updateElement("other_ingredients")
    const deleteRecipeOtherIngredients = deleteElement("other_ingredients")

    // steps
    const updateNewStep = (key, value) => {
        setNewStep({ ...newStep, [key]: value })
    }

    const deleteRecipeStep = (stepIndex) => {
        setSelectedSteps(selectedSteps.filter((step, index) => stepIndex !== index))
    }

    const addRecipeSteps = () => {
        (newStep.name || newStep.description) && setSelectedSteps([...selectedSteps, { name: newStep.name, description: newStep.description }])
        setNewStep({ name: '', description: '' })
    }

    const changeStepIndex = (index, newIndex) => {
        const newSelectSteps = [...selectedSteps]
        if (newIndex >= 0 && newIndex <= selectedSteps.length - 1) {
            newSelectSteps[index] = selectedSteps[newIndex]
            newSelectSteps[newIndex] = selectedSteps[index]
            setSelectedSteps([...newSelectSteps])
        }
    }

    const updateRecipeSteps = (index, key, value) => {
        setSelectedSteps(prvSteps => prvSteps.map((step, idx) =>
            idx === index
                ? { ...step, [key]: value }
                : step
        ))
    }

    useEffect(() => {
        const controller = new AbortController();
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/articles`, { method: 'get' }, controller.signal, {
            data: 'raw',
            filter: {
                category: 'Matière première',
                archive: 0,
            },
            limit: 0
        })
            .then(res => {
                res.data.data ? setArticles(res.data.data) : setArticles([])
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: 'Nous rencontrons une erreur avec nos serveurs',
                    infinit: false,
                })
            })
        return () => {
            controller.abort()
        }
    }, [])

    const setNewArticle = (newArticle) => {
        showPopup(false)
        setArticles([newArticle, ...articles])
    }

    return (
        <>
            <div>
                <h3>Informations générales</h3>
                <div className='flex-center'>
                    <Input label='Nom' value={recipe.name ? recipe.name : ""} onInput={updateRecipe} customParameters={['name']} required />
                    <Input label='Description' type="textarea" value={recipe.description ? recipe.description : ""} onInput={updateRecipe} customParameters={['description']} />
                </div>
            </div>
            <div className='m-t-25'>
                <h3>Ingrédients</h3>
                {articles
                    ? <>
                        <div className='column start'>
                            <h4>Cannabinoides</h4>
                            <div className='column gap-1'>
                                {recipe.cannabinoids.length
                                    ? recipe.cannabinoids.map((cannabinoid, index) =>
                                        <React.Fragment key={index}>
                                            <IngredientField articles={articles.filter(article => article.to_be_analyzed == 1)}
                                                ingredient={{ ...cannabinoid, type: 'cannabinoid' }}
                                                updateRecipeIngredient={updateRecipeCannabinoids}
                                                index={index}
                                                deleteIngredient={deleteRecipeCannabinoids} />
                                        </React.Fragment >)
                                    : null
                                }
                            </div>

                            <div className='flex flex-center full-width m-t-25'>
                                {articles && <button type='button' className='light-button m-l-10'
                                    onClick={() => addRecipeCannabinoids()} > Ajouter un cannabinoide </button>}
                                <button type='button' className='m-l-10'
                                    onClick={() => addPopupContent2(<AddArticle defaultCategory={"Matière première"}
                                        setter={setNewArticle} />)}> Créer un cannabinoide </button>
                            </div>
                        </div>

                        <div className='column start'>
                            <h4>Bases</h4>
                            <div className='column gap-1'>
                                {recipe.bases.length ?
                                    recipe.bases.map((base, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <IngredientField articles={articles.filter(article => !article.to_be_analyzed)}
                                                    ingredient={{ ...base, type: 'base' }}
                                                    updateRecipeIngredient={updateRecipeBases} index={index}
                                                    deleteIngredient={deleteRecipeBases} />
                                            </React.Fragment>
                                        )
                                    }) : null
                                }
                            </div>

                            <div className='flex-center full-width m-t-25'>
                                <button type='button' className='light-button m-l-10' onClick={() => addRecipeBases()} > Ajouter une base </button>
                                <button
                                    type='button'
                                    className='m-l-10'
                                    onClick={() => addPopupContent2(<AddArticle defaultCategory={"Matière première"} setter={setNewArticle} />)}
                                >
                                    Créer une base
                                </button>
                            </div>
                        </div>

                        <div className='column start'>
                            <h4>Autres ingrédients</h4>
                            <p className='m-t-0'>Les quantités doivent être ajoutées en % de masse par volume (ou masse si produit finis en masse)</p>
                            <div className='column gap-1'>
                                {
                                    recipe.other_ingredients.length ?
                                        recipe.other_ingredients.map((otherIngredient, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <IngredientField articles={articles.filter(article => article.to_be_analyzed == 0)}
                                                        ingredient={{ ...otherIngredient, type: 'otherIngredient' }}
                                                        updateRecipeIngredient={updateRecipeOtherIngredients} index={index}
                                                        deleteIngredient={deleteRecipeOtherIngredients} />
                                                </React.Fragment>
                                            )
                                        }) : null

                                }
                            </div>
                            <div className='flex-center full-width m-t-25'>
                                {articles && <button type='button' className='light-button m-l-10' onClick={() => addRecipeOtherIngredients()} > Ajouter un autre ingrédient </button>}
                                <button type='button' className='m-l-10'
                                    onClick={() => addPopupContent2(<AddArticle defaultCategory={"Matière première"} setter={setNewArticle} />)}> Créer un autre ingrédient </button>
                            </div>
                        </div>
                    </>
                    : <MiniLoader />
                }

            </div >
            <div className='m-t-25'>
                <h3>Étapes</h3>
                <div className='flex column'>
                    <div className='flex-center'>
                        <div style={{ width: '49%' }}>
                            <Input label='Nom' value={newStep.name} onInput={updateNewStep} customParameters={['name']} />
                        </div>
                        <div style={{ width: '31%' }}>
                            <Input type="textarea" value={newStep.description} onInput={updateNewStep} customParameters={['description']} label='Description' />
                        </div>
                        <div style={{ width: '16%' }} className='self-end'>
                            <button type='button' className='light-button' onClick={() => addRecipeSteps()}> Créer l'étape </button>
                        </div>
                    </div>
                    <div className='flex column'>
                        {selectedSteps.length ?
                            selectedSteps.map((step, index) => {
                                return (
                                    <div key={index} className='m-w-1220 recipe-step gap-1'>
                                        <div className='flex column'>
                                            <span className='pointer' onClick={() => changeStepIndex(index, index - 1)}>▲</span>
                                            <span className='pointer' onClick={() => changeStepIndex(index, index + 1)}>▼</span>
                                        </div>
                                        <input label='Nom' value={step.name} onInput={(e) => updateRecipeSteps(index, 'name', e.target.value)} customParameters={['name']} />
                                        <textarea label='Description' value={step.description} onInput={(e) => updateRecipeSteps(index, 'description', e.target.value)} />
                                        <DeleteButton alwaysDisplay={true} callBack={deleteRecipeStep} parameters={[index]} />
                                    </div>
                                )
                            }) : null
                        }
                    </div>
                </div>
            </div>
        </>

    )
}
