import { useCallPipedriveApi } from 'Functions'
import React, { useState, useEffect } from 'react'

export default function DeliverySheetData({ deliverySheet, setter }) {

    const callPipedriveApi = useCallPipedriveApi()
    const [showCustomAdress, setShowCustomAdress] = useState(false)

    // GET DEAL & ORGANISATION FROM PIPEDRIVE
    const [deal, setDeal] = useState(false)
    const [organisation, setOrganisation] = useState(false)

    useEffect(() => {
        if (deliverySheet) {
            if (deliverySheet.parameters.adress
                && (deliverySheet.parameters.adress === deal["882c1972b932518a53a2ba497117a98b7efc98d6"]
                    || deliverySheet.parameters.adress === organisation["710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"]
                    || deliverySheet.parameters.adress === organisation.address)
            ) {
                setShowCustomAdress(prv => false)
            } else {
                setShowCustomAdress(prv => true)
            }
        }
    }, [deliverySheet])

    useEffect(() => {
        const controller = new AbortController()
        callPipedriveApi(`deals/${deliverySheet.deal_id}`, { signal: controller.signal })
            .then(
                (res) => {
                    setDeal(res.data.data)
                    const orgId = Object.keys(res.data.related_objects.organization)[0]
                    callPipedriveApi(`organizations/${orgId}`).then((res) => {
                        setOrganisation(res.data.data)
                    })
                }
            )
        return () => {
            controller.abort()
        }
    }, [])

    const changeAdress = (adressValue) => {
        if (adressValue == 0 || adressValue === "default") {
            setter('parameters.adress', '')
        } else {
            setter('parameters.adress', adressValue)
        }
    }

    return (
        <div className='primary-color-bg'>
            <div className="flex-center m-b-25">
                <label>Adresse d'expédition</label>
                <div className="flex column gap-1 full-width">
                    <select
                        className="full-width"
                        value={deliverySheet.parameters.adress}
                        onChange={(e) => changeAdress(e.target.value)}
                        required
                    >
                        <option value="default">Sélectionnez une adresse de livraison</option>
                        {deal["882c1972b932518a53a2ba497117a98b7efc98d6"] && (
                            <option value={deal["882c1972b932518a53a2ba497117a98b7efc98d6"]}>
                                Adresse du devis:{" "}
                                {deal["882c1972b932518a53a2ba497117a98b7efc98d6"]}
                            </option>
                        )}
                        {organisation["710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"] && (
                            <option value={organisation["710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"]}>
                                Adresse de livraison de l'orga:{" "}
                                {organisation["710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"]}
                            </option>
                        )}
                        {organisation.address && (
                            <option value={organisation.address}>
                                Adresse de facturation de l'orga: {organisation.address}
                            </option>
                        )}
                        <option value="0">Autre</option>
                    </select>
                    {showCustomAdress &&
                        <input
                            className="full-width"
                            placeholder="Adresse de livraison"
                            value={deliverySheet.parameters.adress}
                            onChange={e => setter(`parameters.adress`, e.target.value)}
                            required
                        />
                    }
                </div>
            </div>
            <div
                className='flex'
            // style={{ maxWidth: "500px", margin: "10px auto 25px" }}
            >
                <div className='full-width'>
                    <div className="flex input">
                        <label>Date</label>
                        <input
                            type="date"
                            onInput={(e) => setter('date', e.target.value)}
                            value={deliverySheet.date}
                            required
                        />
                    </div>
                    <div className="flex input">
                        <label>Opérateur</label>
                        <input
                            type="text"
                            placeholder="Nom de l'opérateur"
                            onInput={(e) => setter('operator', e.target.value)}
                            value={deliverySheet.operator}
                            required
                        />
                    </div>
                    <div className="flex input">
                        <label>Nombre de colis</label>
                        <input
                            type="number"
                            onInput={(e) => setter('parameters.cardboard-number', e.target.value)}
                            value={deliverySheet.parameters["cardboard-number"]}
                        />
                    </div>
                </div>
                <div className='full-width'>
                    <div className="flex column input">
                        <label>Type de matière première</label>
                        <div style={{ textAlign: "left", width: "200px" }}>
                            <label>
                                <input
                                    type="radio"
                                    value="BIO"
                                    checked={deliverySheet.type_of_mp === "BIO"}
                                    onChange={() => setter('type_of_mp', "BIO")}
                                />
                                BIO
                            </label>
                            <br />
                            <label>
                                <input
                                    type="radio"
                                    value="NON BIO"
                                    checked={deliverySheet.type_of_mp === "NON BIO"}
                                    onChange={() => setter('type_of_mp', "NON BIO")}
                                />
                                NON BIO
                            </label>
                            <br />
                            <label>
                                <input
                                    type="radio"
                                    value="BIO & NON BIO"
                                    checked={deliverySheet.type_of_mp === "BIO & NON BIO"}
                                    onChange={() => setter('type_of_mp', "BIO & NON BIO")}
                                />
                                BIO & NON BIO
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <table className="center full-width">
                    <thead>
                        <tr>
                            <th />
                            <th>Conforme</th>
                            <th>Non conforme</th>
                            <th>Remarques</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "16px", textAlign: "right" }}>
                                Si BIO et NON BIO:
                                <br />
                                séparation BIO/NON BIO
                            </td>
                            <td>
                                <input
                                    type="radio"
                                    name="separation-bio-nb"
                                    checked={deliverySheet.parameters["bio-or-not"].conformity === "Conforme"}
                                    onChange={() => setter("parameters,bio-or-not,conformity", "Conforme")}
                                />
                            </td>
                            <td>
                                <input
                                    type="radio"
                                    name="separation-bio-nb"
                                    checked={deliverySheet.parameters["bio-or-not"].conformity === "Non conforme"}
                                    onChange={() => setter("parameters.bio-or-not.conformity", "Non conforme")}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    name="separation-bio-nb-comment"
                                    value={deliverySheet.parameters["bio-or-not"].comment ?? ''}
                                    onChange={e => setter("parameters.bio-or-not.comment", e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
