import React, { createContext, useState, useEffect, useContext } from "react"
import { NotificationContext } from "Context/NotificationContext"
import { MslAccessContext } from "./MslAccessContext"
import { useCallMSLApi } from "Functions"

export const PriceCategoriesContext = createContext()

function PriceCategoriesContextProvider(props) {
    const [priceCategories, setPriceCategories] = useState(null)
    const { mslNonce } = useContext(MslAccessContext)

    const callMSLApi = useCallMSLApi()

    const { addNotificationContent } = useContext(NotificationContext)
    useEffect(() => {
        const controller = new AbortController()
        if (mslNonce) {
            setPriceCategories(null)
            callMSLApi(`categories`)
                .then(res => {
                    setPriceCategories(res.data.result.map(cat => {
                        return { id: cat.id, value: cat.name }
                    }))
                })

                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: err.response.data.message,
                    })
                })
        }
        return () => {
            controller.abort()
        }
    }, [mslNonce])


    return (
        <PriceCategoriesContext.Provider value={{ priceCategories, setPriceCategories }}>
            {props.children}
        </PriceCategoriesContext.Provider>
    )
}

export default PriceCategoriesContextProvider
