import React, { useState, useContext, useEffect } from "react"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import DeliverySheet from "Components/_ERP/DeliverySheet/DeliverySheet"
import { PopupContext } from "Context/PopupContext"
import "./DealDetails.css"
import { appConstante, formatedState } from "appConstante"
import { NotificationContext } from "Context/NotificationContext"
import {
  useCallApi,
  useCallPipedriveApi,
  useCallPennylaneApi,
  removeDuplicatesObjectInArray,
} from "Functions"
import { OrganizationsContext } from "Context/ERP/OrganizationsContext"
import ValidedSession from "Components/_ERP/PlanDeal/Sessions/ValidedSession"
import Datalist from "Components/Dataliste/Dataliste"
import { useCurrentAppPermission } from 'Functions';
import AccordionTable from 'Components/AccordionTable/AccordionTable'
import DealProductsStockGraph from "./DealProductsStockGraph"
import PlanDeals from "Components/_ERP/PlanDeal/PlanDeal"
import { CommentsContext } from "Context/CommentContext"
import Comments from "Components/GENERAL/Comments/Comments"
import CreateDeliverySheet from "../DeliverySheet/CreateDeliverySheet"

export default function DealDetails({ dealId, updateErpDeal }) {
  const { addPopupContent2, addPopupContent } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)
  const { getOrganizationFromId } = useContext(OrganizationsContext)
  const { addComment } = useContext(CommentsContext)

  const callApi = useCallApi()
  const callPipedriveApi = useCallPipedriveApi()
  const callPennylaneApi = useCallPennylaneApi()
  const currentAppPermission = useCurrentAppPermission()

  /* DEAL PARAMETERS FOR ERP */
  const [error, setError] = useState(null)
  const [priority, setPriority] = useState("")
  const [goProd, setGoProd] = useState("")
  const [goExpe, setGoExpe] = useState("")
  const [dueDate, setDueDate] = useState("")

  const [stageId, setStageId] = useState("")
  const [filteredProductId, setFilteredProductId] = useState(null)
  const [currentDealProductionSheets, setCurrentDealProductionSheets] = useState([])

  /* DEAL PARAMETERS FOR PIPEDRIVE */
  const [dealInfos, setDealInfos] = useState(false)
  const [organisationInfos, setOrganisationInfos] = useState(false)

  const creatDeliverySheet = (id) => {
    addPopupContent(<CreateDeliverySheet dealId={id} />, "1200px")
  }

  /* PIPEDRIVE: récupération de deal */
  useEffect(() => {
    const controller = new AbortController()
    callPipedriveApi(`deals/${dealId}`, { signal: controller.signal }).then(
      (res) => {
        setDealInfos(res.data.data)
        setStageId(res.data.data.stage_id)
        const orgId = Object.keys(res.data.related_objects.organization)[0]
        getOrganizationFromId(parseInt(orgId))
          .then((orga) => setOrganisationInfos(orga))
          .catch((err) => "Pas d'organisation")
      }
    )

    return () => {
      controller.abort()
    }
  }, [dealId])

  /* PENNYLANE: récupération des factures */
  const [invoice, setinvoice] = useState(false)
  useEffect(() => {
    const controller = new AbortController()
    if (dealInfos && dealInfos[appConstante.pipedrive.INVOICE_NUMBER]) {
      callPennylaneApi(
        "customer_invoices",
        { signal: controller.signal },
        {
          filter: JSON.stringify([
            {
              field: "invoice_number",
              operator: "eq",
              value: dealInfos[appConstante.pipedrive.INVOICE_NUMBER],
            },
          ]),
        }
      ).then((res) => {
        res.data.invoices && setinvoice(res.data.invoices[0])
      })
    }
    return () => {
      controller.abort()
    }
  }, [dealInfos[appConstante.pipedrive.INVOICE_NUMBER]])


  /* ERP: HISTORIQUE DE PRODUCTIONS */
  const [dealSessions, setDealSessions] = useState(false)
  useEffect(() => {
    const controller = new AbortController()
    const urlDealEvents = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}/sessions`
    callApi(urlDealEvents, { method: "get" }, controller.signal)
      .then((res) => {
        setDealSessions(res.data.data ?? [])
      })
      .catch((err) => setDealSessions([]))

    return () => {
      controller.abort()
    }
  }, [dealId])


  /** ***************** produt PIPEDRIVE FROM ERP ****************** */

  /* PIPEDRIVE: liste des produits */
  const [pipedriveProductsArray, setPipedriveProductsArray] = useState(false)
  useEffect(() => {
    const controller = new AbortController()
    callPipedriveApi(`deals/${dealId}/products`, {
      signal: controller.signal,
    }).then((res) => {
      setPipedriveProductsArray(res.data.data)
    })
    return () => {
      controller.abort()
    }
  }, [dealId])


  const [erpProducts, setErpProducts] = useState([])
  useEffect(() => {
    const controller = new AbortController()
    pipedriveProductsArray &&
      Promise.all(
        pipedriveProductsArray.map((product) =>
          new Promise((resolve, reject) => {
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}`, controller.signal)
              .then((res) => {
                if (res.status === 204) {
                  const newProduct = {
                    id: product.id,
                    deal_id: dealId,
                    in_stock_quantity: 0,
                    status: "to_plan",
                    ignored: 0,
                  }
                  postErpProduct(newProduct)
                  resolve({ ...newProduct, pipedrive: product })
                } else {
                  resolve({ ...res.data.data, pipedrive: product })
                }
              })
          })
        )
      ).then((erpProducts) => setErpProducts(erpProducts))

    return () => {
      controller.abort()
    }
  }, [pipedriveProductsArray])

  const postErpProduct = (product) => {
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product`,
      { method: "post" },
      null,
      product
    )
  }

  useEffect(() => {
    const controller = new AbortController()
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}`,
      { method: "get" },
      controller.signal
    )
      .then((res) => {
        if (res.status === 204) {
          setPriority(1)
          setDueDate("")
          setGoExpe(0)
          setGoProd(0)
        } else {
          setPriority(res.data.data.priority)
          setDueDate(res.data.data.due_date ? res.data.data.due_date : "")
          setGoExpe(res.data.data.go_expe ? 1 : 0)
          setGoProd(res.data.data.go_prod ? 1 : 0)
        }
      })
      .catch((err) => {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: "Nos serveurs présentent une erreur",
        })
        setError("Paramètres indisponibles")
      })

    return () => {
      controller.abort()
    }
  }, [dealId])

  const updategoProd = (goProdValue) => {
    setGoProd(goProdValue)
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}`,
      { method: "put" },
      null,
      { go_prod: goProdValue }
    ).then((res) => {
      updateErpDeal && updateErpDeal(res.data.data)
    })
  }
  const updategoExpe = (goExpeValue) => {
    setGoExpe(goExpeValue)
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}`,
      { method: "put" },
      null,
      { go_expe: goExpeValue }
    ).then((res) => {
      updateErpDeal && updateErpDeal(res.data.data)
    })
  }
  const updateStageId = (stageIdValue) => {
    setStageId(stageIdValue)
    callPipedriveApi(
      `deals/${dealId}`,
      { method: "put" },
      { stage_id: stageIdValue }
    ).then((res) => updateErpDeal && updateErpDeal(res.data.data))
  }
  const updateDealPole = (dealPole) => {
    let dealPoleValue = dealInfos[appConstante.pipedrive.DEALPOLE] ? dealInfos[appConstante.pipedrive.DEALPOLE].split(",") : []
    if (dealPoleValue.includes(dealPole)) {
      dealPoleValue = dealPoleValue.filter(item => item !== dealPole);
    } else {
      dealPoleValue.push(dealPole)
    }

    dealPoleValue = dealPoleValue.length ? dealPoleValue.join(',') : ""

    setDealInfos({ ...dealInfos, [appConstante.pipedrive.DEALPOLE]: dealPoleValue })
    callPipedriveApi(
      `deals/${dealId}`,
      { method: "put" },
      { [appConstante.pipedrive.DEALPOLE]: dealPoleValue }
    ).then((res) => updateErpDeal && updateErpDeal(res.data.data))
  }
  const updatePriority = (priorityValue) => {
    setPriority(priorityValue)
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}`,
      { method: "put" },
      null,
      { priority: priorityValue }
    ).then((res) => {
      updateErpDeal && updateErpDeal(res.data.data)
    })
  }

  const updateDueDate = (value) => {
    setDueDate(value)
    const urlProducts = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}`
    callApi(urlProducts, { method: "put" }, null, { due_date: value }).then(
      (res) => {
        updateErpDeal && updateErpDeal(res.data.data)
      }
    )
  }

  // Call for production sheets relating to the current estimate
  useEffect(() => {
    const controller = new AbortController()
    const productionSheets = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheets`
    callApi(productionSheets, { method: 'get' }, controller.signal, {
      deal: dealId,
    })
      .then(res => {
        setCurrentDealProductionSheets(Array.isArray(res.data.data) ? res.data.data : [])
      })
      .catch(error => {
        addNotificationContent({
          error: true,
          title: 'Erreur',
          content: 'Nous rencontrons une erreur avec nos serveurs',
          infinit: false,
        })
      })
    return () => {
      controller.abort()
    }
  }, [dealId])

  /****************** ERP: HISTORIQUE D'EXPEDITIONS ******************/
  const [deliveryHistory, setDeliveryHistory] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    callApi(
      `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${dealId}/delivery-sheets`,
      { method: "get" },
      controller.signal
    )
      .then((res) => {
        res.data.data
          ? setDeliveryHistory(res.data.data)
          : setDeliveryHistory(res.data)
      })
      .catch((err) => setDeliveryHistory([]))
    return () => {
      controller.abort()
    }
  }, [])

  // const [formatedHistoryProductsQuantity, setFormatedHistoryProductsQuantity] = useState(false)
  // useEffect(() => {
  //   if (erpProducts.length && deliveryHistory && dealInfos) {

  //     let productsOrder = erpProducts
  //       .filter(product => !product.ignored)
  //       .map(product => ({
  //         name: product.pipedrive.name,
  //         id: product.id,
  //         qty_ready_to_send: product.qty_ready_to_send,
  //         quantity: product.in_stock_quantity
  //       }))

  //     const data = [
  //       ["Date", ...productsOrder.flatMap(product => [product.name, { role: 'tooltip', type: 'string', p: { html: true } }])],
  //       // Exemple de données pour deux courbes
  //       [
  //         new Date(dealInfos.first_won_time ?? dealInfos.won_time),
  //         ...productsOrder.flatMap(product => [
  //           product.quantity,
  //           `<div style="font-size: 16px; padding: 7px">Initialisation de la commande<br/>
  //           <strong>date : </strong>${new Date(dealInfos.won_time).toLocaleDateString('fr')} <br/>
  //           <strong>quantité </strong> : ${product.quantity}<div>`
  //         ]),
  //       ]
  //     ]

  //     const steps = erpProducts
  //       .filter(product => !product.ignored)
  //       .reduce((result, product) => {
  //         const lastSteps = product?.steps[product.steps?.length - 1];
  //         if (lastSteps) {
  //           lastSteps.sessions?.forEach(session => {
  //             result.push({
  //               date: new Date(session.start),
  //               products: [{
  //                 quantity: session.real_quantity,
  //                 product_id: product.id,
  //                 label: `${session.title}`
  //               }]
  //             });
  //           });
  //         }
  //         return result;
  //       }, []);

  //     const productsEvent = [
  //       ...steps,
  //       ...deliveryHistory.map(deliverySheet => ({
  //         date: new Date(deliverySheet.date),
  //         products: deliverySheet.products.map(product => ({
  //           ...product,
  //           quantity: parseFloat(product.quantity) * -1,
  //           label: `Bon de livraison n°${deliverySheet.id}`
  //         }))
  //       }))
  //     ]

  //     console.log("productsEvent", productsEvent);

  //     /*** Delivery Sheet ***/
  //     productsEvent
  //       .sort((a, b) => a.date - b.date)
  //       .forEach(productEvent => {
  //         const date = new Date(productEvent.date)
  //         productsOrder = productsOrder
  //           .map(product => {
  //             const eventProduct = productEvent.products?.find(dsProduct => dsProduct.product_id === product.id)
  //             const newQuantity = eventProduct ? product.quantity + eventProduct.quantity : product.quantity

  //             return {
  //               ...product,
  //               currentProduct: eventProduct ? true : false,
  //               quantity: newQuantity,
  //               tooltipe: eventProduct
  //                 ? `<div style="font-size: 16px; padding: 7px">${eventProduct.label}<br/>
  //                   <strong>date : </strong>${date.toLocaleDateString('fr')} <br/>
  //                   <strong>quantité : </strong>${eventProduct.quantity}
  //                 <div>`
  //                 : 'RAA'
  //             }
  //           })
  //         data.push([new Date(date), ...productsOrder.flatMap(product => product.currentProduct ? [product.quantity, product.tooltipe] : [null, null])])
  //       })
  //     // data.push([new Date(), ...productsOrder.flatMap(product => [product.qty_ready_to_send, ]),])
  //     console.log("data", data);
  //     setFormatedHistoryProductsQuantity(_ => data)
  //   }
  // }, [erpProducts, deliveryHistory])

  return (
    <>
      <h2>Détail du deal: {dealId}</h2>
      <div id="main-section-container" >
        <div id="dd-org-section">
          {dealInfos && (
            <div className="half-m-width">
              <h3>Informations du devis</h3>
              <div className="flex  info-section">
                <div className="strong-2">Date de gain:</div>
                <div className="right">
                  {new Date(dealInfos.won_time).toLocaleDateString("fr-FR")}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Personne de contact:</div>
                <div className="right">
                  {dealInfos.person_id &&
                    dealInfos.person_id.name &&
                    dealInfos.person_id.name}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Email:</div>
                <div className="right">
                  {dealInfos.person_id &&
                    dealInfos.person_id.email &&
                    dealInfos.person_id.email.map((email) => (
                      <div key={email.value}>{email.value}</div>
                    ))}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Téléphone:</div>
                <div className="right">
                  {dealInfos.person_id &&
                    dealInfos.person_id.phone.length &&
                    dealInfos.person_id.phone.map((phone) => (
                      <div key={phone.value}>{phone.value}</div>
                    ))}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Adresse de livraison du devis:</div>
                <div className="right">
                  {dealInfos["882c1972b932518a53a2ba497117a98b7efc98d6"]
                    ? dealInfos["882c1972b932518a53a2ba497117a98b7efc98d6"]
                    : "Par défaut"}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Informations de livraisons du devis:</div>
                <div className="right">
                  {dealInfos["11bed6c610ff6d9d8f48523fb54203ad12d8a923"]
                    ? dealInfos["11bed6c610ff6d9d8f48523fb54203ad12d8a923"]
                    : "Par défaut"}
                </div>
              </div>
              <div className="flex info-section">
                <div className="strong-2">Condition de paiement:</div>
                <div className="right">
                  {dealInfos["09fb39f27867cda67db347ae5cfdac0b3a808afe"]
                    ? dealInfos["09fb39f27867cda67db347ae5cfdac0b3a808afe"]
                    : "Celui de l'organisation par défaut"}
                </div>
              </div>
              <div className="flex info-section">
                <div className="strong-2">Paiement reçu:</div>
                <div className="right">
                  {invoice
                    ? `${(
                      invoice.currency_amount - invoice.remaining_amount
                    ).toFixed(2) * 1
                    }€ / ${invoice.currency_amount}€ (${100 -
                    (
                      (invoice.remaining_amount * 100) /
                      invoice.currency_amount
                    ).toFixed(2) *
                    1
                    }%)`
                    : "devis sans facture"}
                </div>
              </div>
            </div>
          )}

          {organisationInfos && (
            <div className="half-m-width">
              <h3>Informations de l'organisations</h3>
              <div className="flex  info-section">
                <div className="strong-2">Organisation:</div>
                <div className="right">{organisationInfos.name}</div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Adresse de livraison:</div>
                <div className="right">
                  {organisationInfos["710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"]
                    ? organisationInfos[
                    "710bb4c63de816f9fcf15a73a36d2eeca1c7b5f8"
                    ]
                    : organisationInfos.address}
                </div>
              </div>
              {organisationInfos.fff0b69a51de5303b6b7004b5f33a354513bbcbb && (
                <div className="flex  info-section">
                  <div className="strong-2">Informations de livraison:</div>
                  <div className="right">
                    {organisationInfos.fff0b69a51de5303b6b7004b5f33a354513bbcbb}
                  </div>
                </div>
              )}
              <div className="flex  info-section">
                <div className="strong-2">Adresse de facturation:</div>
                <div className="right">{organisationInfos.address}</div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Condition de paiement:</div>
                <div className="right">
                  {organisationInfos["25b164c96d293be04d4a018be5ea306c56c0b4dd"]
                    ? organisationInfos[
                    "25b164c96d293be04d4a018be5ea306c56c0b4dd"
                    ]
                    : "A la commande (par défaut)"}
                </div>
              </div>
              <div className="flex  info-section">
                <div className="strong-2">Responsable:</div>
                <div className="right">
                  {organisationInfos && organisationInfos.owner_id.name}
                </div>
              </div>
            </div>
          )}
        </div>

        <div id="dd-table-section">
          <AccordionTable
            columns={[
              { title: 'Nom du produit' },
              { title: 'Quantité' },
              currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT
                ? { title: 'Valeur' }
                : null,
              { title: 'Statut' },
              { title: 'Quantité produite' },
              { title: 'Quantité expédié' },
            ]}
            datas={
              pipedriveProductsArray &&
              pipedriveProductsArray.map((product) => {
                const productInf = erpProducts.find(productInf => productInf.id === product.id)
                const produced_quantity = productInf
                  ? productInf.qty_ready_to_send + productInf.shipped_qty
                  : "..."
                const shipped_quantity = productInf
                  ? productInf.shipped_qty
                  : "..."
                return {
                  mainLine: [{
                    element: <div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textAlign: "left",
                        }}
                      >
                        {product.name}
                      </div>
                      {product.comments
                        ? <div
                          className="comment-deal-detail"
                          dangerouslySetInnerHTML={{
                            __html: product.comments
                          }}
                        />
                        : null}
                    </div>
                  },
                  { element: <div className="strong-2">{product.quantity}</div> },
                  currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT ? { element: <div>{product.sum_formatted}</div> } : null,
                  {
                    element: <div className="strong-2">{productInf
                      ? formatedState[productInf.status]
                      : "..."}</div>
                  },
                  {
                    element: <div className={produced_quantity >= product.quantity ? "ok-quantity" : "not-ok-quantity"}>
                      {produced_quantity ? produced_quantity : "..."}
                    </div>
                  },
                  {
                    element: <div className={shipped_quantity >= product.quantity ? "ok-quantity" : "not-ok-quantity"}>
                      {shipped_quantity ?? "..."}
                    </div>
                  },
                  ],
                  subLines: productInf
                    ? productInf?.steps && productInf?.steps?.length
                      ? [
                        [
                          {},
                          { element: <div className="strong-2 left">Étape</div> },
                          { element: <div className="strong-2">Quantité à produire</div> },
                          { element: <div className="strong-2">Quantité planifié</div> },
                          { element: <div className="strong-2">Quantité produite</div> },
                          { element: <div className="strong-2">Statut</div> },
                        ],
                        ...productInf.steps.map(step => ([
                          {},
                          { element: <div className="strong-2 left">{step.name}</div> },
                          { element: <div className="">{step.todo_quantity}</div> },
                          { element: <div className="">{step.plan_quantity}</div> },
                          { element: <div className="">{step.done_quantity}</div> },
                          { element: <div className="">{formatedState[step.status]}</div> },
                          {}
                        ]))]
                      : [[{ element: <div className="strong-2">Pas d'étape pour ce produit</div>, colSpan: 7 }]]
                    : [[{ element: <MiniLoader />, colSpan: 7 }]]
                }
              })
            }
          />
        </div>
        <div className="right">
          <button
            className="light-button"
            disabled={!goProd}
            onClick={() => addPopupContent(<PlanDeals dealId={dealId} />
              , "1220px")}>
            Planifier
          </button>
        </div>
      </div>
      {
        error
          ? <h5>{error}</h5>
          : priority !== null ?
            <div id="custom-deal-parameters">
              {currentAppPermission('erp') >= appConstante.parameters.CAN_EDIT &&
                <div className="flex">
                  <div className="half-m-width">
                    {/* GAUCHE  */}
                    <div className="flex-center m-b-10">
                      <label htmlFor="goProd">Ok pour production:</label>
                      <select
                        value={goProd}
                        id="goProd"
                        onChange={(e) => updategoProd(e.target.value)}
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </div>
                    <div className="flex-center">
                      <label htmlFor="goExpe">Ok pour expédition:</label>
                      <select
                        value={goExpe}
                        id="goExpe"
                        onChange={(e) => updategoExpe(e.target.value)}
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </div>
                  </div>
                  <div className="half-m-width">
                    {/* DROITE  */}
                    {dealInfos
                      ? <div className="m-b-10">
                        <div className="m-b-10">
                          <label className="left full-widh block">
                            Pôle en charge du devis:
                          </label>
                        </div>
                        <label className="flex flex-center regular" >
                          Production:
                          <input
                            type="checkbox"
                            className="erp-check"
                            value={"260"}
                            checked={dealInfos[appConstante.pipedrive.DEALPOLE] ? dealInfos[appConstante.pipedrive.DEALPOLE].split(',').includes("260") : false}
                            onChange={(e) => updateDealPole(e.target.value)}
                          />
                        </label>
                        <label className="flex flex-center regular" >
                          Réglementaire:
                          <input
                            type="checkbox"
                            className="erp-check"
                            value={"261"}
                            checked={dealInfos[appConstante.pipedrive.DEALPOLE] ? dealInfos[appConstante.pipedrive.DEALPOLE].split(',').includes("261") : false}
                            onChange={(e) => updateDealPole(e.target.value)}
                          />
                        </label>
                        <label className="flex flex-center regular" >
                          Analyse:
                          <input
                            type="checkbox"
                            className="erp-check"
                            value={"262"}
                            checked={dealInfos[appConstante.pipedrive.DEALPOLE] ? dealInfos[appConstante.pipedrive.DEALPOLE].split(',').includes("262") : false}
                            onChange={(e) => updateDealPole(e.target.value)}
                          />
                        </label>
                      </div>
                      : null
                    }

                    <div className="flex-center m-b-10">
                      <div>
                        <label className="left full-widh block" htmlFor="stage">
                          Etape:
                        </label>
                        <div style={{ fontSize: "12px", fontWeight: "300" }}>
                          L'étape du devis se met automatiquement à jour lors
                          d'évènement
                        </div>
                      </div>
                      <select
                        value={stageId}
                        id="stage"
                        onChange={(e) => updateStageId(e.target.value)}
                      >
                        <option value="">-- Changer d'étape --</option>
                        <option value={175}>Commande en attente</option>
                        <option value={170}>Commande reçu par la production</option>
                        <option value={174}>
                          Commande en cours de planification
                        </option>
                        <option value={171}>Commande en cours de production</option>
                        <option value={173}>Production terminée</option>
                        <option value={172}>Commande expédiée</option>
                      </select>
                    </div>

                    <div className="flex-center m-b-10">
                      <label htmlFor="priority">Priorité:</label>
                      <select
                        value={priority}
                        id="priority"
                        onChange={(e) => updatePriority(e.target.value)}
                      >
                        <option disabled value="">
                          Autre
                        </option>
                        <option value={1}>Normal</option>
                        <option value={0}>Basse</option>
                        <option value={2}>Haute</option>
                      </select>
                    </div>
                    <div className="flex-center">
                      <label htmlFor="due_date">
                        Date de réception client souhaité:
                      </label>
                      <input
                        onChange={(e) => updateDueDate(e.target.value)}
                        value={dueDate}
                        id="due_date"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              }
              <div className="m-t-50">
                <Comments
                  target={'Deal'}
                  target_id={dealId}
                />
              </div>
            </div>
            : <MiniLoader />
      }

      {/* <div>
        {formatedHistoryProductsQuantity
          ? <DealProductsStockGraph data={formatedHistoryProductsQuantity} />
          : null
        }
      </div> */}

      <div className="flex">
        <div style={{ width: "48%" }}>
          <h3>Historique de production</h3>
          {!dealSessions
            ? <MiniLoader />
            : <>
              {
                dealSessions.length === 0
                  ? <h5>Aucun historique de production</h5>
                  : <>
                    <div className="m-b-25">
                      <Datalist
                        placeholder="Filtrer par produit"
                        onSelect={setFilteredProductId}
                        items={removeDuplicatesObjectInArray("value",
                          dealSessions.filter(session => session.product).map(session => ({
                            id: session.product.id,
                            value: session.product.name,
                          })))}
                      />
                    </div>
                    {currentDealProductionSheets && dealSessions.filter(session => filteredProductId
                      ? session.product && session.product.id === filteredProductId
                      : true)
                      .map(session => {
                        const productsionSheets = currentDealProductionSheets.filter(prodSheet => prodSheet.session_id === session.id)
                        return (
                          <div className="m-b-25" key={session.id}>
                            <ValidedSession
                              session={{ ...session, production_sheets: productsionSheets }}
                            />
                          </div>
                        )
                      })}
                  </>
              }
            </>
          }
        </div>
        <div style={{ width: "48%" }}>
          <h3>Historique d'éxpéditions</h3>
          <>
            {deliveryHistory
              ? deliveryHistory.length > 0
                ? <table className="beautiful-array center full-width">
                  <thead>
                    <tr>
                      <th>N° de bon</th>
                      <th>Date de création</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryHistory.map((delivery) => (
                      <tr
                        key={delivery.id}
                        className="pointer"
                        onClick={() =>
                          addPopupContent2(
                            <DeliverySheet deliverySheet={delivery} />
                          )
                        }
                      >
                        <td>{delivery.id}</td>
                        <td>{new Date(delivery.date).toLocaleDateString("fr")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                : <h5>Aucun bon de livraison généré pour ce devis</h5>
              : deliveryHistory && <MiniLoader />
            }
            {dealInfos
              ? <button
                className="light-button center-block m-t-10"
                disabled={!goExpe}
                onClick={() => creatDeliverySheet(dealId)}
              >
                Générer un bon de livraison
              </button>
              : null
            }
          </>
        </div>
      </div>
    </>
  )
}
