import React from "react"

export default function InvoiceParameters({
  invoiceParameters,
  setInvoiceParameters,
  won_time,
}) {
  const updateInvoiceParameters = (field, value) => {
    const newParameters = invoiceParameters
    newParameters[field] = value
    setInvoiceParameters({ ...newParameters })
  }
  return (
    <>
      <h3 style={{ marginTop: "25px" }}>Paramètres de facturations</h3>
      <div>
        <div className="flex input">
          <label>date: </label>
          <input
            onInput={(e) => updateInvoiceParameters("date", e.target.value)}
            type="date"
            value={invoiceParameters.date}
            required
          />
        </div>
        <div className="flex input">
          <label>Date de première relance: </label>
          <input
            onInput={(e) => updateInvoiceParameters("deadline", e.target.value)}
            type="date"
            value={invoiceParameters.deadline}
            required
          />
        </div>
        <div className="flex input column start">
          <label>Commentaire: </label>
          <div className="full-width">
            <textarea
              className="full-width"
              onInput={(e) =>
                updateInvoiceParameters("pdf_invoice_free_text", e.target.value)
              }
              value={invoiceParameters.pdf_invoice_free_text ?? ""}
            />
            <div className="pipedrive-info">
              Devis signé le{" "}
              {won_time
                ? new Date(won_time).toLocaleDateString("fr-FR")
                : "Non défini"}
            </div>
          </div>
        </div>
        <div className="flex input column start">
          <label>Conditions de paiements: </label>
          <textarea
            className="full-width"
            onInput={(e) =>
              updateInvoiceParameters("special_mention", e.target.value)
            }
            value={
              invoiceParameters.special_mention
                ? invoiceParameters.special_mention
                : "À la commande"
            }
          />
        </div>
        {/* <div className='flex input'>
                    <label>Brouillon: </label>
                    <select onChange={(e) => updateInvoiceParameters("draft", e.target.value)} >
                        <option value={invoiceParameters.draft}>{invoiceParameters.draft ? "Oui" : "Non"}</option>
                        <option disabled>_________</option>
                        <option value='false'>Non</option>
                        <option value='true'>Oui</option>
                    </select>
                </div> */}
      </div>
    </>
  )
}
