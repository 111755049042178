import React, { createContext, useEffect, useRef, useState } from "react"
import { useCallApi } from "Functions"
import { appConstante } from "appConstante"

/**
 * PIPEDRIVE_ENDPOINT |
 * PIPEDRIVE_KEY |
 * PENYLANE_ENDPOINT |
 * PENYLANE_KEY |
 */
export const DependenciesContext = createContext()

function DependenciesContextProvider(props) {
  const callApi = useCallApi()

  const checkDependencies = dependencies => {
    return dependencies
      && dependencies.PIPEDRIVE_ENDPOINT
      && dependencies.PIPEDRIVE_KEY
      && dependencies.PENYLANE_ENDPOINT
      && dependencies.PENYLANE_KEY
  }
  useEffect(() => {
    if (!localStorage.getItem('dependencies')) {
      callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/dependencies`, {
        method: "get",
      })
        .then((res) => {
          localStorage.setItem('dependencies', JSON.stringify(res.data));
        })
        .catch((error) => console.error(error))
    }
  }, [])


  const getDependencies = async () => {
    if (localStorage.getItem('dependencies')) {
      return JSON.parse(localStorage.getItem('dependencies'))
    } else {
      setTimeout(() => getDependencies(), 250)
    }
  }


  return (
    <DependenciesContext.Provider value={{ getDependencies }}>
      {props.children}
    </DependenciesContext.Provider>
  )
}

export default DependenciesContextProvider
