import React from "react"
import ShowDealsArrayFromFilter from "Components/_ERP/ShowDealsArrayFromFilter"

export default function NewDeal() {
  return (
    <>
      <h4 className="m-t-0 m-b-10">Commandes à planifier</h4>
      <ShowDealsArrayFromFilter
        filter_id={155}
      />
      <h4 className="m-t-25 m-b-10">Commandes planifiées</h4>
      <ShowDealsArrayFromFilter
        filter_id={158}
      />
      <h4 className="m-t-25 m-b-10">Commandes en attente</h4>
      <ShowDealsArrayFromFilter
        filter_id={368}
      />
    </>
  )
}