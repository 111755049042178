import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import Datalist from "Components/Dataliste/Dataliste"
import { NotificationContext } from "Context/NotificationContext"
import { appConstante } from "appConstante"
import { OrganizationsContext } from "Context/ERP/OrganizationsContext"
import { useCallPipedriveApi } from "Functions"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import MidLoader from "Components/MidLoader/MidLoader"
import Pagination from "Components/Pagination/Pagination"

export default function UpdateCAPerClient() {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageOrganizations, setCurrentPageOrganization] = useState(false)
  const [searchOrganizationsId, setSearchOrganizationsId] = useState(false)
  const [filterAnalyses, setFilterAnalyses] = useState(false)

  const [filterId, setFilterId] = useState(null)
  const [filters, setFilters] = useState(null)

  const { addNotificationContent } = useContext(NotificationContext)
  const callPipedriveApi = useCallPipedriveApi()

  const { organizations } = useContext(OrganizationsContext)

  useEffect(() => {
    let uri = ""
    const offset = 20
    const data = {}
    if (searchOrganizationsId) {
      uri = `organizations/${searchOrganizationsId}`
    } else if (filterAnalyses) {
      uri = '/organizations/'
      data.start = (currentPage - 1) * offset
      data.limit = offset
      data.filter_id = 175
    } else {
      uri = '/organizations/'
      data.start = (currentPage - 1) * offset
      data.limit = offset
      filterId && (data.filter_id = filterId)
    }
    setCurrentPageOrganization(null)
    callPipedriveApi(uri, { method: "get" }, data)
      .then((res) => {
        if (res.data.data) {
          if (res.data.data.items) {
            Array.isArray(res.data.data.items)
              ? setCurrentPageOrganization(res.data.data.items)
              : setCurrentPageOrganization([res.data.data.items])
          } else {
            Array.isArray(res.data.data)
              ? setCurrentPageOrganization(res.data.data)
              : setCurrentPageOrganization([res.data.data])
          }
        } else {
          setCurrentPageOrganization(false)
        }
      })
  }, [searchOrganizationsId, filterAnalyses, currentPage, filterId])

  useEffect(() => {
    const abortController = new AbortController()
    callPipedriveApi('filters', { signal: abortController.signal }, { type: 'org' })
      .then((response) => {
        setFilters(response.data.data)
      })

    return () => {
      abortController.abort()
    }
  }, [])


  const changeSelectedOrg = (id) => {
    setSearchOrganizationsId(id)
  }

  const updateOrganisation = async (orga) =>
    new Promise((resolve, reject) => {
      const urlUpdateOrga = `organizations/${orga.id}`

      callPipedriveApi(urlUpdateOrga, { method: "put" }, orga)
        .then(() => {
          resolve(false)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const updateDealOrganisation = async (deal, orga) =>
    new Promise((resolve, reject) => {
      const urlUpdateDeal = `deals/${deal.id}`

      callPipedriveApi(urlUpdateDeal, { method: "put" }, { org_id: orga.id })
        .then(() => {
          resolve(false)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const updateCaFunction = async (orga, index = false, notification = true) => {
    addNotificationContent({
      title: 'Actualisation en cours',
      content: <MiniLoader />,
    })

    await updateOrganisationsDeal(orga)
    const {
      CA_TOTAL,
      CA_2021,
      CA_2022,
      CA_2023,
      CA_analyses,
      total_analyses,
      mean_price_analyses,
      last_order_date,
    } = await computeOrganisationData(orga, notification)

    // Update que si le CA à changé
    if (orga[appConstante.pipedrive.CA_TOTAL] !== CA_TOTAL) {
      const newOrga = { id: orga.id, name: orga.name }
      newOrga[appConstante.pipedrive.CA_TOTAL] = CA_TOTAL
      newOrga[appConstante.pipedrive.CA_2021] = CA_2021
      newOrga[appConstante.pipedrive.CA_2022] = CA_2022
      newOrga[appConstante.pipedrive.CA_2023] = CA_2023
      newOrga[appConstante.CA_ANALYSE] = CA_analyses
      newOrga[appConstante.pipedrive.NB_TOTAL_ANALYSES] = total_analyses
      newOrga[appConstante.pipedrive.MEAN_PRICE_ANALYSES] = mean_price_analyses
      newOrga[appConstante.pipedrive.LAST_ORDER_DATE] = last_order_date

      const requestError = await updateOrganisation(newOrga)
      if (requestError) {
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: requestError.response.error,
          infinit: false,
        })
      } else {
        notification &&
          addNotificationContent({
            error: false,
            title: "Succès",
            content: `l'organisation ${orga.name} à été mis à jour`,
            infinit: false,
          })
        if (index) {
          const newOrgaArray = [...currentPageOrganizations]
          newOrgaArray[index] = newOrga
          setCurrentPageOrganization(newOrgaArray)
        }
      }
    } else {
      notification &&
        addNotificationContent({
          error: false,
          title: "Super!",
          content: `L'organisation ${orga.name} est déjà à jour.`,
          infinit: false,
        })
    }
  }

  const updateOrganisationsDeal = async (orga) => {
    const uri = `organizations/${orga.id}/deals`
    callPipedriveApi(uri, { method: "get" })
      .then(async (res) => {
        if (res.data.data) {
          res.data.data.forEach(async (deal) => {
            if (deal.org_id === null) {
              const requestError = await updateDealOrganisation(deal, orga)
              if (requestError) {
                addNotificationContent({
                  error: true,
                  title: "Erreur",
                  content: requestError.response.error,
                  infinit: false,
                })
              }
            }
          })
        }
      })
      .catch(error => { console.log(error) })
  }

  const getDealPorducts = (dealId) =>
    new Promise((resolve) => {
      const urlDealProducts = `deals/${dealId}/products`
      callPipedriveApi(urlDealProducts, { method: "get" })
        .then((res) => {
          if (res.data.data) {
            resolve(res.data.data)
          } else {
            resolve([])
          }
        })
    })

  const getDealPorductsValueNoTax = (allOrgaProducts, dealId) => {
    const dealProducts = allOrgaProducts.filter(
      (product) => product.dealId === dealId
    )
    if (dealProducts[0].products) {
      const ProductsValueNoTax = dealProducts[0].products.map(
        (product) =>
          (product.item_price / (1 + product.tax / 100)) *
          product.quantity *
          (1 - product.discount_percentage / 100)
      )
      return (
        ProductsValueNoTax.reduce((acc, val) => acc + val, 0).toFixed(2) * 1
      )
    }
    return 0
  }

  const getValidDealPorductsQty = (productsFromWonDeal, dealId) => {
    const dealProducts = productsFromWonDeal.filter(
      (product) => product.dealId === dealId
    )
    if (dealProducts[0].products) {
      const ProductsQuantity = dealProducts[0].products
        .filter((product) => product.item_price > 0)
        .map((product) => product.quantity)
      return ProductsQuantity.reduce((acc, val) => acc + val, 0).toFixed(2) * 1
    }
    return 0
  }

  const computeOrganisationData = async (orga, notification) =>
    new Promise((resolve) => {
      const uri = `organizations/${orga.id}/deals`
      callPipedriveApi(uri, { method: "get" })
        .then(async (res) => {
          if (res.data.data) {
            /* DEVIS GAGNE POUR LE CA TOTAL */
            const wonDeals = res.data.data.filter(
              (deal) =>
                deal.status === "won" || deal.add_time > new Date(2023, 0, 0)
            )
            const productsFromWonDeal = await Promise.all(
              wonDeals.map(
                async (deal, index) =>
                  new Promise(async (resolve, reject) => {
                    setTimeout(async () => {
                      resolve({
                        dealId: deal.id,
                        products: await getDealPorducts(deal.id),
                      })
                    }, 250 * (index + 1))
                  })
              )
            )

            const AllDealsValue = await Promise.all(
              wonDeals.map((deal) =>
                getDealPorductsValueNoTax(productsFromWonDeal, deal.id)
              )
            )

            /* CA TOTAL 2021 */
            const AllDealsWon2021 = res.data.data.filter((deal) => {
              const addTime = new Date(deal.add_time)
              const date2022 = new Date(2022, 0, 0)
              const date2021 = new Date(2021, 0, 0)
              return (
                deal.status === "won" && addTime > date2021 && addTime < date2022
              )
            })
            const AllDealsValue2021 = await Promise.all(
              AllDealsWon2021.map((deal) =>
                getDealPorductsValueNoTax(productsFromWonDeal, deal.id)
              )
            )

            /* CA TOTAL 2022 */
            const AllDealsWon2022 = res.data.data.filter((deal) => {
              const addTime = new Date(deal.add_time)
              const date2022 = new Date(2022, 0, 0)
              return deal.status === "won" && addTime > date2022
            })
            const AllDealsValue2022 = await Promise.all(
              AllDealsWon2022.map((deal) =>
                getDealPorductsValueNoTax(productsFromWonDeal, deal.id)
              )
            )

            /* CA TOTAL 2023 */
            const AllDealsWon2023 = res.data.data.filter((deal) => {
              const addTime = new Date(deal.won_time)
              const date2023 = new Date(2023, 0, 0)
              return deal.status === "won" && addTime > date2023
            })
            const AllDealsValue2023 = await Promise.all(
              AllDealsWon2023.map((deal) =>
                getDealPorductsValueNoTax(productsFromWonDeal, deal.id)
              )
            )

            /* LAST ORDER DATE */
            const lastOrderDate = res.data.data.reduce((latest, deal) => {
              const dealDate = deal.won_time;
              if (dealDate && (!latest || new Date(dealDate) > latest)) {
                return new Date(dealDate);
              }
              return latest;
            }, null);

            const last_order_date = lastOrderDate
              ? new Date(lastOrderDate + (2 * 60 * 60 * 1000))
              : null;

            /** ******* ANALYSES ******** */
            /* DEVIS GAGNE POUR LE CA ANALYSES */
            const AnalysesDeals = res.data.data.filter(
              (deal) =>
                deal.status === "won" &&
                deal.pipeline_id === 10 /* 96: catégorie analyse */
            )
            const AnalysesDealsValue = await Promise.all(
              AnalysesDeals.map((deal) =>
                getDealPorductsValueNoTax(productsFromWonDeal, deal.id)
              )
            )

            /* Nombre de produit d'analyses vendu */
            const AnalysesProductCount = res.data.data
              .filter(
                (deal) =>
                  deal.status === "won" &&
                  deal.pipeline_id === 10 /* 96: catégorie analyse */
              )
              .map((deal) => deal.products_count)

            /* PRIX MOYEN ANALYSES 6 LAST MOUNTHS */
            const dealAnalysisFrom6Mounths = res.data.data.filter((deal) => {
              const addTime = new Date(deal.add_time)
              const today = new Date()
              const sinceSixMounth = today.setMonth(today.getMonth() - 6)
              return (
                deal.status === "won" &&
                deal.pipeline_id === 10 &&
                deal.value > 0 &&
                addTime > sinceSixMounth
              )
            })
            const meanPriceAnalyses = await Promise.all(
              dealAnalysisFrom6Mounths.map(
                (deal) =>
                  getDealPorductsValueNoTax(productsFromWonDeal, deal.id) /
                  getValidDealPorductsQty(productsFromWonDeal, deal.id)
              )
            )

            const CA_TOTAL = AllDealsValue.reduce(
              (acc, val) => acc + val,
              0
            ).toFixed(2)
            const CA_2021 = AllDealsValue2021.reduce(
              (acc, val) => acc + val,
              0
            ).toFixed(2)
            const CA_2022 = AllDealsValue2022.reduce(
              (acc, val) => acc + val,
              0
            ).toFixed(2)
            const CA_2023 = AllDealsValue2023.reduce(
              (acc, val) => acc + val,
              0
            ).toFixed(2)
            const CA_analyses = AnalysesDealsValue.reduce(
              (acc, val) => acc + val,
              0
            ).toFixed(2)
            const total_analyses = AnalysesProductCount.reduce(
              (acc, val) => acc + val,
              0
            )
            const mean_price_analyses = (
              meanPriceAnalyses.reduce((a, b) => a + b, 0) /
              meanPriceAnalyses.length
            ).toFixed(2)
            resolve({
              CA_TOTAL,
              CA_2021,
              CA_2022,
              CA_2023,
              CA_analyses,
              total_analyses,
              mean_price_analyses,
              last_order_date,
            })
          } else {
            notification &&
              addNotificationContent({
                error: true,
                title: "Erreur",
                content: `L'organisation ${orga.name} n'a aucun deal associé.`,
                infinit: false,
              })
          }
        })
    })

  const updateAllCaOnPage = () => {
    currentPageOrganizations &&
      currentPageOrganizations.forEach(async (organization, index) => {
        setTimeout(() => {
          updateCaFunction(organization, index, false)
          addNotificationContent({
            content: <div>Mise à jour de l'organisation <br />
              <span className="strong-2"> {organization.name}</span> ({index + 1} / {currentPageOrganizations.length})</div>
          })
        }, 2000 * (index + 1))
      })
  }

  const updateAllCa = () => {
    const orgaLength = organizations.length
    organizations &&
      organizations.map((organization, index) => {
        setTimeout(() => {
          addNotificationContent({
            content: <div>Mise à jour de l'organisation <br />
              <span className="strong-2"> {organization.name}</span> ({index + 1} / {orgaLength})</div>
          })
          updateCaFunction(organization, false, false)
        }, 2000 * (index + 1))
      })
  }

  return (
    <>
      <button
        className="strong-button m-l-0"
        onClick={() => updateAllCa()}
      >
        Actualiser les données de toute les organisations
      </button>
      <div className="flex">
        <div className="flex-center flex-start gap-2 m-b-25">
          <Datalist
            placeholder="Nom de l'entreprise"
            items={organizations}
            onSelect={changeSelectedOrg}
            width={'250px'}
          />
          {filters
            ? <Datalist
              placeholder="Filtre Pipedrive"
              items={filters.map(f => ({ ...f, value: f.name }))}
              onSelect={setFilterId}
              width={'250px'}
            />
            : <MiniLoader />
          }
          <label
            className="flex-center m-t-25"
            style={{ fontSize: "18px", fontWeight: "500" }}
          >
            <input
              className="erp-check"
              type="checkbox"
              checked={filterAnalyses}
              onChange={(e) => setFilterAnalyses(e.target.checked)}
            />
            Afficher uniquement les clients analyses
          </label>
        </div>
        <button
          style={{
            fontSize: "30px",
            fontWeight: "900",
            width: "60px",
            height: "60px",
          }}
          onClick={() => updateAllCaOnPage()}
        >
          &#x21bb;
        </button>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {
        currentPageOrganizations
          ? <>
            <table className="beautiful-array">
              <thead>
                <tr style={{ height: "40px" }}>
                  <th>ID</th>
                  <th>Nom</th>
                  {/* <th>Personnes de contacts</th> */}
                  <th>Date de la dernière commande</th>
                  <th>CA total généré</th>
                  <th>CA 2022</th>
                  <th>CA 2023</th>
                  <th>CA analyse généré</th>
                  <th>Nb d'analyses commandées</th>
                  <th>
                    Prix moyen des analyses
                    <br />
                    sur les 6 derniers mois
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  currentPageOrganizations.map((organization, index) => (
                    <tr key={organization.id}>
                      <td>
                        <a
                          target="_blank"
                          href={`https://lelaboratoirefrancaisduchanvre-d03bb0.pipedrive.com/organization/${organization.id}`}
                          rel="noreferrer"
                        >
                          {organization.id}
                        </a>
                      </td>
                      <td>{organization.name}</td>
                      <td>{organization[appConstante.pipedrive.LAST_ORDER_DATE]
                        ? (new Date(organization[appConstante.pipedrive.LAST_ORDER_DATE])).toLocaleDateString('fr-FR')
                        : null
                      }</td>
                      <td>{organization[appConstante.pipedrive.CA_TOTAL]}</td>
                      <td>{organization[appConstante.pipedrive.CA_2022]}</td>
                      <td>{organization[appConstante.pipedrive.CA_2023]}</td>
                      <td>{organization[appConstante.pipedrive.CA_ANALYSE]}</td>
                      <td>{organization[appConstante.pipedrive.NB_TOTAL_ANALYSES]}</td>
                      <td>{organization[appConstante.pipedrive.MEAN_PRICE_ANALYSES]}</td>
                      <td>
                        <button
                          className="light-button"
                          onClick={() => updateCaFunction(organization, index)}
                        >
                          Actualiser les données
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
          : <MidLoader />
      }
    </>
  )
}
