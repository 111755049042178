import React from 'react'
import ShowDealsArrayFromFilter from "Components/_ERP/ShowDealsArrayFromFilter"

export default function DealsAnalyses() {
    return (
        <>
            <h4>Devis relatifs aux analyses</h4>
            <div className='subtext'>Recense tous les devis relatifs à des analyses annexes aux cannabinoïdes</div>
            <ShowDealsArrayFromFilter
                filter_id={370}
                showCreateDS={false}
                ableToPlan={false}
            />
        </>
    )
}
