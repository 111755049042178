import React, { useEffect } from 'react';
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Invoices() {
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    location.pathname==="/invoices" && navigate("/invoices/devis-a-facturer"); //Sous onglet par défaut
  }, [])
  return (
    <>
      <SubNavBar menu={[
        { path: "/invoices/devis-a-facturer", name: "Devis à facturer" },
        { path: "/invoices/factures", name: "Mes factures" }
      ]} />
    </>
  );
}