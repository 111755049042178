import { useCallAnalysesApi } from "Functions"
import { createContext, useEffect, useState } from "react"

export const OrdersContext = createContext()

function OrdersContextProvider(props) {

    const [orders, setOrders] = useState([])
    const callAnalysesApi = useCallAnalysesApi()

    useEffect(() => {
        const controller = new AbortController()
        callAnalysesApi('orders', { method: "get", signal: controller.signal }, { limit: -1 })
            .then(res => {
                setOrders(res.data.data)
            })
        return () => {
            controller.abort()
        }
    }, [])

    const updateOrder = (updatedOrder) => {
        setOrders(pvs => pvs.map(order =>
            order.id === order.id
                ? updatedOrder
                : order
        ))
    }

    const updateOrderFromId = async (orderId) => {
        const updatedOrder = await getOrder(orderId)
        setOrders(pvs => pvs.map(order => order.ID === orderId
            ? updatedOrder
            : order
        ))
    }

    const getOrder = (orderId) => {
        return new Promise((resolve, reject) => {
            callAnalysesApi(`order/${orderId}`, { method: "get" })
                .then(res => {
                    resolve(res.data)
                })
                .catch(res => {
                    console.log("catch", res);
                    reject(null)
                })
        })
    }

    return (
        <OrdersContext.Provider value={{ orders, updateOrder, updateOrderFromId }}>
            {props.children}
        </OrdersContext.Provider>
    )
}

export default OrdersContextProvider
