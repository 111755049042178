import React, { useContext, useEffect, useRef, useState } from 'react'
import './oldMatriceDePrix.css'
import ProductSpectificationDetails, { emptyParameters } from 'Components/_ERP/ProductSpecification/ProductSpectificationDetails'
import set from 'lodash/set.js';
import { PriceMatrixContext } from 'Context/Commercial/PriceMatrixContext';
import SelectProductSpecification from 'Components/_ERP/ProductSpecification/SelectProductSpecification'
import Select from 'Components/FormElement/Select';
import Input from 'Components/FormElement/Input';
import SelectPriceMatrix from 'Components/_COMMERCIAL/PriceMatrix/SelectPriceMatrix';
import { priceParameters } from 'appConstante';

const emptyPriceMatrix = {
    id: null,
    name: '',
    raw_materials: null,
    parameters: {
        ...emptyParameters,
        show_pricing: true,
        editable: true,
        loaded: false,
    }
}

export default function NewMatriceDePrix() {

    const { getPriceMatrix, postPriceMatrix, putPriceMatrix } = useContext(PriceMatrixContext)

    // SPECIFICATION PRODUIT //
    const [selectedProdSpefId, setSelectedProdSpefId] = useState(null)
    const [priceMatrix, setPriceMatrix] = useState(emptyPriceMatrix)

    const openMatrice = () => {
        selectProductSpefId(null)
        setPriceMatrix(emptyPriceMatrix)
        setParametersKey("loaded", true)
    }

    const selectProductSpefId = (id) => {
        if (id) {
            setParametersKey("loaded", true)
            setSelectedProdSpefId(id)
        } else {
            if (selectedProdSpefId && window.confirm('Êtes-vous sûr de vouloir perdre les modifications en cours ?')) {
                setParametersKey("loaded", false)
                setSelectedProdSpefId(null)
            }
        }
    }

    const selectMatrixId = (id) => {
        if (id) {
            getLoadedMatrix(id)
            setSelectedProdSpefId(null)
        } else {
            if (priceMatrix.id && window.confirm('Êtes-vous sûr de vouloir perdre les modifications en cours ?')) {
                setParametersKey("loaded", false)
            }
        }
    }

    const setName = (value) => {
        setPriceMatrix(prevMatrix => {
            const newMatrix = prevMatrix
            set(newMatrix, 'name', value);
            return { ...newMatrix };
        })
    }

    const setRawMaterials = (value) => {
        setPriceMatrix(prevMatrix => {
            const newMatrix = prevMatrix
            set(newMatrix, 'raw_materials', value);
            return { ...newMatrix };
        })
    }

    const setParametersKey = (keyPath, value) => {
        setPriceMatrix(prevMatrix => {
            const newMatrix = prevMatrix
            set(newMatrix, `parameters.${keyPath}`, value);
            return { ...newMatrix };
        })
    }

    const getLoadedMatrix = async (id) => {
        const loadedMatrix = await getPriceMatrix(id)
        console.log("loadedMatrix", loadedMatrix);

        setPriceMatrix({ ...loadedMatrix, parameters: { ...loadedMatrix.parameters, loaded: true } })
    }

    return (
        <div>
            <div className='flex-v-end gap-2 m-b-25'>
                <SelectProductSpecification
                    setter={selectProductSpefId}
                    reload={false}
                    className='flex-1'
                />
                <div className='strong-2 center' style={{ fontSize: "18px" }}>OU</div>
                <SelectPriceMatrix
                    className="flex-1"
                    setter={selectMatrixId}
                />
            </div>
            {
                <div className='primary-color-bg'>
                    {!priceMatrix.parameters.loaded
                        ? <button onClick={_ => openMatrice()} className='light-button center-block'>Partir de zéro</button>
                        : <>
                            <strong>Paramètres du produit finis</strong>
                            <div className='flex-start flex-v-end gap-1'>
                                <div className={`flex-1 ${!priceMatrix.parameters.article.type_product ? "error" : ""}`}>
                                    <Select
                                        label='Type de produit'
                                        value={priceMatrix.parameters.article.type_product}
                                        onChange={setParametersKey}
                                        customParameters={['article.type_product']}
                                    >
                                        <option value={''}>Sélectionner le type de produit</option>

                                        {priceParameters.product_type.map(product => <option value={product.value}>{product.label}</option>)}

                                    </Select>
                                </div>
                                <div className='flex-1'>
                                    <Input
                                        value={priceMatrix.parameters.article.quantity}
                                        type='number'
                                        onInput={setParametersKey}
                                        customParameters={['article.quantity']}
                                        label={"Masse / volume du produit finis"}
                                        step='1'
                                    />
                                </div>
                                <div className='flex-1'>
                                    <Select
                                        value={priceMatrix.parameters.article.unit_of_measure}
                                        onChange={setParametersKey}
                                        customParameters={['article.unit_of_measure']}
                                        label='Unité de mesure du volume / masse'
                                    >
                                        <option value={'mL'}>mL</option>
                                        <option value={'L'}>L</option>
                                        <option value={'g'}>g</option>
                                        <option value={'kg'}>kg</option>
                                    </Select>
                                </div>
                            </div>
                            <div className='p-b-25 m-t-25'>
                                <ProductSpectificationDetails
                                    productSpecificationId={selectedProdSpefId}
                                    defaultParameters={priceMatrix.parameters}
                                    defaultRawMaterials={priceMatrix?.raw_materials}
                                    setterRawMaterials={setRawMaterials}
                                    setterParameters={setParametersKey}
                                />
                            </div>
                        </>}
                </div>
            }
            {
                priceMatrix.parameters.loaded
                    ? <form className='flex-start gap-1 m-t-25' onSubmit={e => priceMatrix.id ? putPriceMatrix(priceMatrix, e) : postPriceMatrix(priceMatrix, e)}>
                        <input className='full-width' value={priceMatrix.name ?? ''} onInput={e => setName(e.target.value)} placeholder='Nommer la matrice de prix' required />
                        <div className='flex column gap-1'>
                            <button className='strong-button full-width'>{priceMatrix.id ? 'Mettre à jour' : 'Sauvegarder'}</button>
                            {priceMatrix.id
                                ? <button
                                    type='button'
                                    onClick={e => postPriceMatrix(priceMatrix, e)}
                                    className='light-button full-width'>Sauvegarder une nouvelle matrice</button>
                                : null}
                        </div>
                    </form>
                    : null
            }
        </div>
    )
}