import React from 'react';
import BulkContextProvider from './Bulk';
import AnalysesContextProvider from 'Context/Analyse/AnalysesContext';
import BatchContext from 'Context/ERP/ErpBatchContext';
import BatchContextProvider from './Batch';
import ArticleContextProvider from 'Context/ERP/ArticleContext';

export default function CBDProtectContextContainer(props) {
    return (
        <AnalysesContextProvider>
            <BulkContextProvider>
                <ArticleContextProvider>
                    <BatchContextProvider>
                        {props.children}
                    </BatchContextProvider>
                </ArticleContextProvider>
            </BulkContextProvider>
        </AnalysesContextProvider>
    );
}

