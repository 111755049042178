import React, { useContext } from "react"
import "./Popup.css"
import { PopupContext } from "Context/PopupContext"

export default function Popup(props) {
  return (
    <div id="popup" className="popup">
      {props.children}
    </div>
  )
}

export function Popup2(props) {
  const { showPopup2, togglePopup2 } = useContext(PopupContext)
  const hidePopup2 = (element) => {
    element.classList.contains("popup2") && showPopup2(false)
  }

  return (
    <div
      onMouseDown={(e) => hidePopup2(e.target)}
      id="popup2"
      className={togglePopup2 ? "popup2" : "hidden-overlapping-element popup2"}
    >
      <div className="popup2Content">{props.children}</div>
    </div>
  )
}