import React, { useState, useEffect, useRef } from 'react'
import { useAddErpDataToPipedriveDeal, useCallPipedriveApi } from "Functions"
import ShowDealsArray from 'Components/_ERP/ShowDealsArray/ShowDealsArray'
import MidLoader from 'Components/MidLoader/MidLoader'
import SelectOrganization from 'Components/_ERP/Brands/SelectOrganization'
import Input from 'Components/FormElement/Input'
import Pagination from 'Components/Pagination/Pagination'

export default function SearchDeal() {

    const callPipedriveApi = useCallPipedriveApi()
    const addErpDataToPipedriveDeal = useAddErpDataToPipedriveDeal()

    const [currentPage, setCurrentPage] = useState(1)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [searchDealId, setSearchDealId] = useState('')
    const [deals, setDeals] = useState(null)
    const [error, setError] = useState(null)
    const [filterDeals, setFilterDeals] = useState(false)

    const timeoutHandle = useRef()

    const [refreshPipedriveDeals, toggleRefreshPipedriveDeals] = useState(false)

    const updateDeals = () => {
        toggleRefreshPipedriveDeals(!refreshPipedriveDeals)
    }

    const getDeals = (signalController = null) => {
        const offset = 20
        let urlDeals = ""
        const filter = {}
        setError(null)
        if (searchDealId) {
            urlDeals = `deals/${searchDealId}`
        } else if (selectedOrg) {
            urlDeals = `organizations/${selectedOrg}/deals`
            filter.start = currentPage * offset
            filter.limit = offset
            filter.sort = "id DESC"
        } else {
            urlDeals = `deals`
            filter.start = currentPage * offset
            filter.limit = offset
            filter.sort = "id DESC"
            filter.filter_id = 292
        }

        callPipedriveApi(urlDeals, { signal: signalController }, filter)
            .then((res) => {
                let curentDeals = null
                if (res.data.data) {
                    if (res.data.data.items) {
                        curentDeals = Array.isArray(res.data.data.items)
                            ? res.data.data.items
                            : [res.data.data.items]
                    } else {
                        curentDeals = Array.isArray(res.data.data)
                            ? res.data.data
                            : [res.data.data]
                    }
                    Promise.all(curentDeals.map((deal) => addErpDataToPipedriveDeal(deal)))
                        .then(formatedDeals => setDeals(formatedDeals))
                        .catch(() => setError("Une erreur est survenue"))
                } else {
                    setDeals([])
                }
            })
            .catch((error) => {
                error.status === 404
                    ? setDeals([])
                    : setError("Une erreur est survenue")
            })
    }

    useEffect(() => {
        const controller = new AbortController()
        const delay = searchDealId ? 400 : 0
        setDeals(null)
        clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            getDeals(controller.signal)
        }, delay)

        return () => {
            controller.abort()
        }
    }, [currentPage, selectedOrg, searchDealId])

    useEffect(() => {
        getDeals()
    }, [refreshPipedriveDeals])

    return (
        <>
            <div className='flex-center flex-start m-b-25'>
                <div className="filter-container m-b-10">
                    <SelectOrganization
                        setter={setSelectedOrg}
                        width='300px'
                    />
                </div>

                <div className="filter-container m-b-10" style={{ width: "300px" }}>
                    <Input
                        label={'Numéro de devis'}
                        value={searchDealId}
                        onInput={setSearchDealId}
                    />
                </div>

                <div className="flex-center m-b-25" style={{ justifyContent: "start" }}>
                    <label
                        className="flex-center m-t-25 m-l-25"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                        <input
                            className="erp-check"
                            type="checkbox"
                            checked={filterDeals}
                            onChange={(e) => setFilterDeals(e.target.checked)}
                        />
                        Devis gagnés
                    </label>
                </div>

            </div>

            {error
                ? <h5>{error}</h5>
                : !deals
                    ? <MidLoader />
                    : <>
                        <Pagination
                            currentPage={currentPage}
                            totalPage={null}
                            setCurrentPage={setCurrentPage}
                        />
                        <ShowDealsArray
                            updateDeals={updateDeals}
                            deals={filterDeals
                                ? deals.filter(deal => {
                                    if (deal.status === "won") {
                                        return deal
                                    } else {
                                        return !deal
                                    }
                                })
                                : deals}
                        />
                    </>
            }
        </>
    )
}
