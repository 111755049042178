import React, { useContext, useEffect, useState, useRef } from "react"
import "./Notification.css"
import { NotificationContext } from "Context/NotificationContext"

export default function Notification() {


  const { notificationContent } = useContext(NotificationContext)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationClass, setNotificationClass] = useState('')

  const timeoutHandle = useRef(0)
  useEffect(() => {
    setShowNotification(true)
    clearTimeout(timeoutHandle.current)
    if (!notificationContent.infinit) {
      timeoutHandle.current = setTimeout(() => {
        setShowNotification(false)
      }, 5000)
    }
  }, [notificationContent]);


  useEffect(() => {
    let classes = "notification pointer"
    if (notificationContent.error) classes += " error"
    if (!showNotification) classes += " hidden-overlapping-element"
    setNotificationClass(classes)
  }, [showNotification, notificationContent])

  return (
    <>
      {notificationContent.content || notificationContent.title
        ? <div className={notificationClass} onClick={_ => setShowNotification(false)}>
          {notificationContent.title
            && <div className="flex notification-header">
              <div className="notification-title">{notificationContent.title}</div>
            </div>
          }
          <div className="notification-content">{notificationContent.content}</div>
        </div>
        : null}
    </>
  )
}
