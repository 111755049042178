import React, { createContext, useState, useEffect, useContext } from "react"
import { NotificationContext } from 'Context/NotificationContext'
import { useCallCBDPApi } from "Functions"
import { AnalysesContext } from "Context/Analyse/AnalysesContext"

export const BulkContext = createContext()
export default function BulkContextProvider(props) {
    const callCBDPApi = useCallCBDPApi()

    const { addNotificationContent } = useContext(NotificationContext)
    const { getAnalyses } = useContext(AnalysesContext)

    const [bulks, setBulks] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        getBulks(controller.signal)

        return () => {
            controller.abort()
        }
    }, [])

    /************************************************************/
    /************************* API CALLS ************************/

    const getBulks = (signal = null) => {
        setBulks(null)
        callCBDPApi('bulk', { method: 'get', signal: signal })
            .then((res) => {
                setBulks(res.data)
            })
            .catch((error) => console.log(error))
    }

    const getBulksV2 = async (currentPage, controller) => {
        try {
            const res = await callCBDPApi(`v2/bulks`, { method: "get", signal: controller.signal }, { page: currentPage, limit: 10, })
            return res.data
        } catch (error) {
            return []
        }
    }

    const postBulk = (bulk) => {
        return new Promise((resolve, reject) => {
            callCBDPApi('bulk', { method: 'post' }, bulk)
                .then(res => {
                    addNotificationContent({
                        content: `Le bulk n°${res.data.object.ID} vient d'être crée`
                    })
                    setBulks(prvBulks => [res.data.object, ...prvBulks])
                    resolve(res.data.object)
                })
                .catch(err => {
                    console.log(err)
                    addNotificationContent({
                        error: true,
                        content: "Erreur lors de la création du bulk"
                    })
                    reject(err)
                })
        })
    }

    const getBulk = (id, analyses = false) => {
        const bulkFromId = bulks?.find(bulk => parseInt(bulk.ID) === parseInt(id))
        return new Promise(async (resolve, reject) => {
            if (bulkFromId) {
                if (analyses) {
                    let bulkAnalyses = await getBulkAnalyse(id)
                    console.log(bulkAnalyses);
                    bulkFromId.analyses = bulkAnalyses ? bulkAnalyses : []
                }
                resolve(bulkFromId)
            } else {
                callCBDPApi(
                    `bulk/${id}`, { method: "get" }, null)
                    .then(async res => {
                        const bulk = res.data

                        if (analyses) {
                            let bulkAnalyses = await getBulkAnalyse(id)
                            console.log(bulkAnalyses);
                            bulk.analyses = bulkAnalyses ? bulkAnalyses : []
                        }
                        resolve(bulk)
                        setBulks(prvBulks => [res.data, ...prvBulks ? prvBulks : []])
                    })
                    .catch(error => {
                        console.log(`erreur récupération /bulk/${id}`, error)
                        reject(error)
                    })
            }
        })
    }

    const getBulkAnalyse = (bulkId) => {
        return new Promise(async (resolve, reject) => {

            const bulkAnalyses = await getAnalyses({ bulk_id: bulkId, limit: 100 })
            if (bulkAnalyses) {
                resolve(bulkAnalyses?.data ? bulkAnalyses?.data : [])
            } else {
                reject(error => console.log(error))
            }
        })

    }

    /************************************************************/
    /************************** HELPERS *************************/

    const formatBulkName = (bulk) => {
        return bulk && bulk.ID && bulk.product && bulk.ddm
            ? `[${bulk.ID}] ${bulk.product} (DDM : ${new Date(bulk.ddm).toLocaleDateString()})`
            : 'erreur sur le bulk'
    }

    return (
        <BulkContext.Provider value={{ bulks, setBulks, getBulks, getBulksV2, getBulk, getBulkAnalyse, postBulk, formatBulkName }}>
            {props.children}
        </BulkContext.Provider>
    )
}
