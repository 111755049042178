import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallApi, addPropertyToObjectsInArray } from 'Functions'
import { appConstante } from 'appConstante'
import MidLoader from 'Components/MidLoader/MidLoader'
import ConsultRecipe from '../Recipe/ConsultRecipe'
import ProductSpecificationConditioningItem from './Shared/ProductSpecificationConditioningItem'
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet'
import StockEntry from '../ProductionSheets/StockEntry'
import { PopupContext } from 'Context/PopupContext'
const _ = require('lodash');


export default function ConsultProductSpecification({
    id,
    defaultSteps,
    defaultProductSpecification,
    canEdit = true
}) {
    const params = useParams()
    useEffect(() => {
        if (params.id && !id) {
            id = params.id
            setSteps({
                primaryConditionning: true,
                secondaryConditionning: true,
                recipe: true,
            })
        }
    }, [])

    const {
        productionSheet,
        setProductionSheet,
        updateProductionSheetPropriety,
    } = useContext(ProductionSheetContext)

    const { showPopup } = useContext(PopupContext)

    const Navigate = useNavigate()

    const callApi = useCallApi()
    const [specificationProduct, setSpecificationProduct] = useState(defaultProductSpecification
        ? defaultProductSpecification
        : null
    )

    const emptySteps = {
        primaryConditionning: false,
        secondaryConditionning: false,
        recipe: false,
    }
    const [steps, setSteps] = useState(defaultSteps
        ? { ...emptySteps, ...defaultSteps }
        : { ...emptySteps }
    )

    useEffect(() => {
        if (productionSheet.creating) {
            setSteps({ ...emptySteps, ...productionSheet.parameters.steps })
        }
    }, [productionSheet.creating])


    useEffect(() => {
        productionSheet.creating && setProductionSheet(prvPS => ({ ...prvPS, steps: steps }))
    }, [steps])

    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/product-specification/${id}`, { method: 'get' }, controller.signal)
            .then(res => {
                if (productionSheet.creating) {
                    let formatedSpecificationProduct = { ...res.data.data }
                    if (res.data.data.recipe) {
                        const modifiedRecipeBases = addPropertyToObjectsInArray(res.data.data.recipe.bases, 'batchs', [{ batch_id: null, real_quantity: null }])
                        const modifiedRecipeCannabinoids = addPropertyToObjectsInArray(res.data.data.recipe.cannabinoids, 'batchs', [{ batch_id: null, real_quantity: null }])
                        const modifiedRecipeOtherIngredients = addPropertyToObjectsInArray(res.data.data.recipe.other_ingredients, 'batchs', [{ batch_id: null, real_quantity: null }])
                        formatedSpecificationProduct = {
                            ...formatedSpecificationProduct,
                            recipe: {
                                ...res.data.data.recipe,
                                cannabinoids: modifiedRecipeCannabinoids,
                                bases: modifiedRecipeBases,
                                other_ingredients: modifiedRecipeOtherIngredients
                            }
                        }
                    }
                    setProductionSheet(prev => ({ ...prev, ...formatedSpecificationProduct, product_specification_id: res.data.data.id }))
                    setSpecificationProduct(formatedSpecificationProduct)
                } else {
                    setSpecificationProduct(res.data.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        return () => {
            controller.abort()
        }
    }, [id])

    const goToUpdateSpecif = () => {
        Navigate(`/erp/specif-and-recipe/specifications-product/edit/${params.id ?? id}`)
        showPopup(false)
    }

    const updateStockEntry = (newHistory) => {
        updateProductionSheetPropriety(`parameters.stock_entries`, newHistory)
    }

    return (
        <div>
            {!specificationProduct
                ? <MidLoader />
                :
                <>
                    <h5 className='m-t-25 m-b-10'>Produit fini : {specificationProduct.article?.name}</h5>
                    <div className='m-b-10'>
                        <h4>Informations à afficher</h4>
                        <div className='flex-center wrap gap-1'>
                            <label className='flex-start flex-center'>
                                <input
                                    type="checkbox"
                                    className='erp-check'
                                    onChange={e => setSteps(prvSteps => ({ ...prvSteps, primaryConditionning: e.target.checked }))}
                                    value={steps.primaryConditionning}
                                    checked={steps.primaryConditionning}
                                />
                                <div>Conditionnement primaire</div>
                            </label>
                            <label className='flex-start flex-center'>
                                <input type="checkbox" className='erp-check'
                                    onChange={e => setSteps(prvSteps => ({ ...prvSteps, secondaryConditionning: e.target.checked }))}
                                    value={steps.secondaryConditionning}
                                    checked={steps.secondaryConditionning}
                                />
                                <div>Conditionnement secondaire</div>
                            </label>
                            <label className='flex-start flex-center'>
                                <input type="checkbox" className='erp-check'
                                    onChange={e => setSteps(prvSteps => ({ ...prvSteps, recipe: e.target.checked }))}
                                    value={steps.recipe}
                                    checked={steps.recipe}
                                />
                                <div>Informations de la production (recette)</div>
                            </label>
                            <label className='flex-start flex-center'>
                                <input type="checkbox" className='erp-check'
                                    onChange={e => setSteps(prvSteps => ({ ...prvSteps, stock: e.target.checked }))}
                                    value={steps.stock}
                                    checked={steps.stock}
                                />
                                <div>Entrée en stock
                                    <div className='subtext'>Déclaration de fin de production</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className='flex-column gap-1'>
                        {(steps.primaryConditionning || steps.secondaryConditionning)
                            ? <div className='m-b-25'>
                                {
                                    specificationProduct.conditioning
                                        ? <>
                                            <div>
                                                <h3>Conditionnement</h3>
                                                <div className='flex gap-1'>
                                                    {
                                                        steps.primaryConditionning
                                                            ? <div className='column gap-2'>
                                                                <h4 className='m-b-10 center'>Primaire</h4>
                                                                {specificationProduct.conditioning.primary && specificationProduct.conditioning.primary.length !== 0 ? (
                                                                    specificationProduct.conditioning.primary.map((packaging, index) => (
                                                                        <div key={packaging.id} className='list-item'>
                                                                            <ProductSpecificationConditioningItem
                                                                                packaging={{
                                                                                    ...packaging,
                                                                                    index: index,
                                                                                    category: "Packaging primaire",
                                                                                }}
                                                                                defautlQuantity={productionSheet.creating ? productionSheet?.parameters?.quantity_to_produce : 1}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <h4>Aucun packaging primaire pour cette spécification produit</h4>
                                                                )}
                                                                {
                                                                    productionSheet.creating
                                                                        ? <button
                                                                            type='button'
                                                                            className='light-button'
                                                                            onClick={
                                                                                _ => {
                                                                                    setSpecificationProduct(prvSP => {
                                                                                        const newSP = prvSP
                                                                                        newSP.conditioning.primary = [...newSP.conditioning.primary, {}]
                                                                                        return { ...newSP }
                                                                                    })
                                                                                }
                                                                            }>Ajouter un Packaging primaire</button>
                                                                        : null
                                                                }

                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        steps.secondaryConditionning
                                                            ? <div className='column gap-2'>
                                                                <h4 className='m-b-10 center'>Secondaire</h4>
                                                                {specificationProduct.conditioning.secondary && specificationProduct.conditioning.secondary.length !== 0 ? (
                                                                    specificationProduct.conditioning.secondary.map((packaging, index) => (
                                                                        <div key={packaging.id} className='list-item'>
                                                                            <ProductSpecificationConditioningItem
                                                                                packaging={{ ...packaging, index: index, category: "Packaging secondaire" }}
                                                                                defautlQuantity={productionSheet ? productionSheet?.parameters?.quantity_to_produce : 1}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <h4 className='center'>Aucun packaging secondaire pour cette spécification produit</h4>
                                                                )}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : <h5 className='center'>Aucun packaging pour cette spécification produit</h5>
                                }
                            </div>
                            : null}
                        {steps.recipe
                            ? <>{
                                specificationProduct.recipe_id
                                    ? <div>
                                        <h3>Production</h3>
                                        <ConsultRecipe
                                            defaultRecipe={specificationProduct.recipe}
                                            currentArticle={specificationProduct.article}
                                        />
                                    </div>
                                    : <h5>La spécification produit ne contient pas de recette.</h5>
                            }</>
                            : null}

                        {steps.stock && productionSheet.article
                            ? <div className='m-b-25'>
                                <h4> {productionSheet.article.name}</h4>
                                {productionSheet.parameters.stock_entries
                                    ? <StockEntry
                                        defaultHistory={{
                                            type: "Automatique",
                                            comment: `Entrée en stock durant la session ${productionSheet.session?.title}`,
                                            quantity: productionSheet.parameters.quantity_to_produce,
                                            ...productionSheet.parameters.stock_entries,
                                            history_type: 'add',
                                            article_id: productionSheet.article_id,
                                        }}
                                        setter={updateStockEntry} />
                                    : null
                                }
                            </div>
                            : null
                        }
                    </div>
                    {(params.id || id) && canEdit
                        ? <button
                            className='strong-button m-t-10'
                            onClick={e => goToUpdateSpecif()}
                        >
                            Modifier la spécification
                        </button>
                        : null
                    }
                </>
            }
        </div>
    );
}
