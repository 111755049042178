import { NotificationContext } from 'Context/NotificationContext'
import React, { useContext } from 'react'

export const emptyValue = {
    value: '',
    description: '',
    price: 0,
    type: 'add',
}

export const emptyOption = {
    name: '',
    type: 1,
    description: '',
    values: [emptyValue]
}

export const useCheckOption = () => {
    const { addNotificationContent } = useContext(NotificationContext)

    const checkOption = (option) => {
        let valuesOk = true;
        if (option.name && option.values && option.values.length > 0) {
            option.values.forEach(v => v.value === "" && (valuesOk = false))
            if (!valuesOk) {
                addNotificationContent({
                    title: 'Attention',
                    error: true,
                    content: 'Un des choix de votre option n\'est pas valide'
                })
                return false
            } else {
                return true
            }
        } else {
            addNotificationContent({
                title: 'Attention',
                error: true,
                content: 'Votre option n\'est pas valide'
            })
            return false
        }
    }
    return checkOption
}