import React, { useContext, useState } from 'react'
import { NotificationContext } from 'Context/NotificationContext'
import { BulkContext } from 'Context/CBDProtect/Bulk'
import { formatDateToInput } from 'Functions'
import Input from 'Components/FormElement/Input'

export default function CreateBulk({ setter }) {

    const { postBulk } = useContext(BulkContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const [bulk, setBulk] = useState({
        product: '',
        'production-date': formatDateToInput(new Date()),
        'ddm-date': '',
    })

    const updateBulk = (key, value) => setBulk(prvBulk => ({ ...prvBulk, [key]: value }))

    const createBulk = (e) => {
        e.preventDefault()
        addNotificationContent({
            error: false,
            content: "Ajout en cours",
        })
        postBulk(bulk)
            .then(bulk => {
                addNotificationContent({ content: `Le bulk n°${bulk.ID} vient d'être crée` })
                setter(bulk)
            })
            .catch(err => {
                console.log(err)
                addNotificationContent({
                    error: true,
                    content: "Erreur lors de la création du bulk"
                })
            })
    }
    return (
        <form
            className='column gap-1'
            onSubmit={e => createBulk(e)}
        >
            <h3 className='m-b-0'>Création d'un nouveau bulk</h3>
            <Input
                label='Type de produit'
                value={bulk.product}
                onInput={updateBulk}
                customParameters={['product']}
                required={true}
            />
            <div className='flex start gap-1'>
                <Input
                    label='Date de production'
                    type='date'
                    value={bulk['production-date']}
                    onInput={updateBulk}
                    customParameters={['production-date']}
                    style={{ width: '250px' }}
                    required={true}
                />
                <div>
                    <Input
                        label='DDM'
                        type='date'
                        value={bulk['ddm-date']}
                        onInput={updateBulk}
                        customParameters={['ddm-date']}
                        required={true}
                    />
                    <div className='subtext m-t-10 left'>
                        La DDM est de un an pour les huiles sublinguale base noisettes et base chanvre<br />
                        La DDM est de 2 an pour les huiles sublinguale base MCT
                    </div>
                </div>
            </div>
            <button className='strong-button m-t-10'>
                Créer le bulk
            </button>
        </form >
    )
}
