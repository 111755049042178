import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import { formatDateToInput, useCallApi } from 'Functions'
import { PopupContext } from 'Context/PopupContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import HistoryFields from './HistoryFields'
import { emptyHistory } from 'AppContainer/ERP/ERPConstantes'

export default function AddHistory({ defaultHistory = {}, setter }) {

    const callApi = useCallApi()
    const { addNotificationContent } = useContext(NotificationContext)
    const [newHistory, setNewHistory] = useState({
        ...emptyHistory,
        ...defaultHistory
    })

    useEffect(() => {
        setNewHistory(prvHistory => ({ ...prvHistory, ...defaultHistory }))
    }, [defaultHistory])

    const sendHistory = e => {
        e.preventDefault()
        const historyToSend = newHistory
        if (newHistory.history_type === 'remove') {
            historyToSend.quantity = Number(newHistory.quantity) * -1   //Passage en quantité négative
        }
        if (newHistory.history_type === 'add') {
            historyToSend.quantity = Number(newHistory.quantity)
            historyToSend.price = Number(newHistory.price)
            historyToSend.expiration_date = newHistory.expiration_date
            if (!newHistory.batch_id) { //Création d'un nouveau batch
                historyToSend.new_batch = {
                    product_id: Number(newHistory.product_id),
                    batch_number: newHistory.batch_number
                }
            }
        }
        addNotificationContent({
            title: 'Ajout en cours',
            content: <MiniLoader />,
            infinit: true,
        })
        delete historyToSend.article
        
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/history`, { method: 'post' }, null, historyToSend, e)
            .then(res => {
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "L'historique a été ajouté",
                    infinit: false
                })
                setter && setter(res.data.data)
            })
            .catch(err => {
                console.log(err);
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })
            })
    }

    return (
        <>
            <h2>Ajout de l'historique</h2>
            <form onSubmit={e => sendHistory(e)}>
                <HistoryFields
                    history={newHistory}
                    setter={setNewHistory}
                />
                <button className='strong-button m-b-25 m-t-25'>Ajouter l'historique</button>
            </form>
        </>
    )
}
