import React, { useState, useEffect, useContext } from "react"
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import MiniLoader from "Components/MiniLoader/MiniLoader"
import { appConstante } from "appConstante"
import { useCallApi } from "Functions"

export default function SetProductInStock({ product, setter }) {
  const callApi = useCallApi()
  const { showPopup2 } = useContext(PopupContext)
  const { addNotificationContent } = useContext(NotificationContext)

  const [productInStockQty, setProductInStockQty] = useState(
    product.erp.in_stock_quantity ? product.erp.in_stock_quantity : 0
  )

  const addInStock = (e) => {
    e.preventDefault()
    showPopup2(false)
    const urlProducts = `${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal-product/${product.id}`
    callApi(urlProducts, { method: "put" }, null, {
      in_stock_quantity: productInStockQty,
    })
      .then((res) => setter(res.data))
      .catch((error) =>
        addNotificationContent({
          error: true,
          title: "Erreur",
          content: error.response
            ? error.response.message
            : "Aie, contactez le responsable IT",
          infinit: false,
        })
      )
  }

  return (
    <>
      <h2>Déclarer en stock le produit: {product.name}</h2>
      <div className="flex">
        <p className="left" style={{ marginRight: "10px" }}>
          Indiquez la quantité déja en stock pour ce produit. Cette quantité ne
          sera pas à planifier pour la production
        </p>
        <form onSubmit={(e) => addInStock(e)}>
          <div className="input">
            <input
              value={productInStockQty}
              onInput={(e) => setProductInStockQty(parseInt(e.target.value))}
              max={product.quantity}
              placeholder="Quantité déjà en stock"
              required
            />
            <button className="erp-button erp-medium-button">Confirmer</button>
          </div>
        </form>
      </div>
    </>
  )
}
