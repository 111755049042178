import React, { useContext, useEffect, useState } from 'react'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import SelectArticles from '../SelectArticles'
import BatchItem from './BatchItem'
import set from 'lodash/set.js';
import { v4 as uuidv4 } from "uuid";

export default function DeliverySheetArticleItem({ passedProduct, setter }) {

    const { getArticle } = useContext(ArticleContext)
    const [availableBatch, setAvailableBatch] = useState([])
    const [product, setProduct] = useState(null)
    const [warning, setWarning] = useState('')

    useEffect(() => {
        if (product) {
            setter && setter(product)
        }
    }, [product])

    useEffect(() => {

        setProduct({
            ...passedProduct,
            shipped_qty: passedProduct.shipped_qty,
            batchs: passedProduct?.batchs.map(batch => ({ ...batch, prv_id: batch.id, prv_quantity: batch.quantity, frontId: uuidv4() })) ?? [],
            ignored: passedProduct.isUpdating
                ? passedProduct.ignored
                : passedProduct.status === "Expédié" || passedProduct.ignored
                    ? true
                    : false
        })
    }, [])

    const updateProductKey = (keyPath, value) => {
        setProduct(prvProduct => {
            const newProduct = prvProduct
            set(newProduct, keyPath, value);

            const sumQuantity = newProduct?.batchs?.reduce((acc, current) => acc + parseFloat(current.quantity), 0);
            return { ...newProduct, quantity: sumQuantity };
        })
    }

    useEffect(() => {
        if (product?.suppliers && product?.suppliers.length) {
            setWarning('')
            const batchs = product.suppliers.reduce((batchs, batch) => [...batchs, ...batch.history.map(batch => batch)], [])
            // const filteredBatchs = batchs.filter(batch => batch.quantity > 0 || product.batchs.find(productBatch => productBatch.id === batch.id))
            if (batchs && batchs.length) {
                setAvailableBatch(batchs)
            } else {
                setWarning("Il n'y a pas de numéro de lot disponible pour cet article")
            }
        } else {
            setWarning("Il n'y a pas de fournisseur(s) pour cet article")
            setAvailableBatch([])
        }
    }, [product?.suppliers, product?.ignored])

    const getSelectedArticle = async (id) => {
        if (id) {
            const currentArticle = await getArticle(id)
            updateProductKey('suppliers', currentArticle.products)
            updateProductKey('name', currentArticle.name)
        }
    }

    const resetArticle = () => {
        updateProductKey('suppliers', [])
        updateProductKey('name', '')
    }

    const updateBatch = (updatedBatch) => {
        updateProductKey('batchs', product.batchs.map((batch, index) => {
            if (index === updatedBatch.index) {
                return updatedBatch
            } else {
                return batch
            }
        }))
    }

    const deleteBatch = (batchFrontId) => {
        updateProductKey('batchs', product.batchs.filter((batch) => batch.frontId !== batchFrontId))
    }

    return (
        <>
            {product
                ? <div
                    className='list-item-2 m-b-10'
                    style={{ opacity: product.status === "sended" || product.ignored ? 0.6 : 1, backgroundColor: product.status === "in_stock" || product.status === "to_send" ? "rgba(166, 214, 144, 0.25)" : "" }}
                >
                    <div className='flex-center gap-1 list-item-bg'>
                        <label className='flex-start'>
                            <input
                                className="erp-check"
                                type="checkbox"
                                onChange={(e) => updateProductKey('ignored', !e.target.checked)}
                                checked={!product.ignored}
                            />
                            <div className='flex-center gap-1'>
                                {product.name
                                    ? <>
                                        <h4 className="left m-l-10 m-0">{product.name}</h4>
                                        <button
                                            className='light-button'
                                            type="button"
                                            onClick={_ => resetArticle()}
                                        >Changer
                                        </button>
                                    </>
                                    : <h4 className="left m-l-10 m-0">{product.pipedrive_name}</h4>}
                            </div>
                        </label>
                        <div className='flex-center gap-1'>
                            <div className="flex-center column">
                                <div className="strong-2">Statut</div>
                                <div>{product.status}</div>
                            </div>
                            <div className="flex-center column">
                                <div className="strong-2">Qté du devis</div>
                                <div>{product.deal_quantity}</div>
                            </div>
                            <div className="flex-center column">
                                <div className="strong-2">Qté déjà expédiée</div>
                                <div>{product.shipped_qty}</div>
                            </div>
                        </div>
                    </div>
                    {/* {!product.name &&
                        <SelectArticles
                            width="500px"
                            onSelect={getSelectedArticle}
                            required={product.ignored ? false : true}
                            disabled={product.ignored}
                            refresh={false}
                        />} */}
                    {!product.ignored &&
                        <div className='flex gap-2'>
                            <div className='full-width'>
                                {product.name
                                    ? <div className='full-width'>
                                        {warning
                                            ? <div className='error m-t-25 m-b-10 left'>{warning}</div>
                                            : product.batchs?.length
                                                ? product.batchs.map((batch, index) => {
                                                    return (
                                                        <div key={`${batch.frontId}`}>
                                                            <BatchItem
                                                                passedBatch={{ ...batch, index: index }}
                                                                availableBatch={availableBatch}
                                                                setter={updateBatch}
                                                                deleteBatch={deleteBatch}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                : null}
                                        {availableBatch.length > 1 &&
                                            <div className='left'>
                                                <button
                                                    className='light-button'
                                                    type='button'
                                                    onClick={() => updateProductKey("batchs", [...product.batchs, { id: availableBatch[0].id, quantity: 0, index: product.batchs.length, frontId: uuidv4() }])}
                                                >Ajouter un n° lot
                                                </button>
                                            </div>}
                                    </div>
                                    : <SelectArticles
                                        width="500px"
                                        onSelect={getSelectedArticle}
                                        required={product.ignored ? false : true}
                                        disabled={product.ignored}
                                        refresh={true}
                                    />}
                            </div>
                            {!product.ignored && product.name && <div className="full-width m-t-10">
                                <textarea
                                    placeholder="Ajouter un commentaire"
                                    style={{ width: '100%', minHeight: '40px' }}
                                    onInput={(e) => updateProductKey('bl_comment', e.target.value)}
                                    value={product.bl_comment}
                                    disabled={product.ignored}
                                />
                                {product.comments &&
                                    <div id="product-comments" dangerouslySetInnerHTML={{ __html: product.comments }} />}
                            </div>}
                        </div>}
                </div>
                : null}
        </>
    )
}
