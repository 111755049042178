import React, { useEffect, useState, useContext } from 'react'
import { appConstante } from 'appConstante'
import { Link } from 'react-router-dom'
import { NotificationContext } from 'Context/NotificationContext'
import { removeDuplicatesObjectInArray, useCallApi } from 'Functions'
import { useCallPipedriveApi } from "Functions"
import { ProductionSheetContext } from 'Context/ERP/productionSheet/CreateProductionSheet'
import { PopupContext } from 'Context/PopupContext'
import { BulkContext } from 'Context/CBDProtect/Bulk'
import { BatchContext } from 'Context/CBDProtect/Batch'
import ConsultProductSpecification from '../ProductSpecification/ConsultProductSpecification'
import SelectBatch from 'Components/_CBDP/Batch/SelectBatch'
import SelectBulk from 'Components/_CBDP/Bulk/SelectBulk'
import MidLoader from 'Components/MidLoader/MidLoader'
import Datalist from 'Components/Dataliste/Dataliste'
import Input from 'Components/FormElement/Input'
import CreateBulkBtn from 'Components/_CBDP/Bulk/CreateBulkBtn'
import CreateBatchBtn from 'Components/_CBDP/Batch/CreateBatchBtn'
import SelectProductSpecification from 'Components/_ERP/ProductSpecification/SelectProductSpecification'

export default function CreateProductionSheets({ sessionId, onCreate }) {
    const callApi = useCallApi()

    const { formatBulkName } = useContext(BulkContext)
    const { formatBatchName } = useContext(BatchContext)
    const { showPopup2 } = useContext(PopupContext)
    const {
        emptyProcutionSheet,
        productionSheet,
        setProductionSheet,
        updateProductionSheetPropriety,
        addProductionSheet
    } = useContext(ProductionSheetContext)

    const [productSpecifications, setProductSpecifications] = useState(null)
    const { addNotificationContent } = useContext(NotificationContext)
    const [specificationProductId, setSpecificationProductId] = useState(null)
    const [session, setSession] = useState(null)
    const [selectedSessionId, setSelectedSessionId] = useState(null)
    const [selectedBulkValue, setSelectedBulkValue] = useState('')
    const [selectedBatchValue, setSelectedBatchValue] = useState('')

    useEffect(() => {
        setSelectedSessionId(sessionId ?? null)
    }, [sessionId])

    useEffect(() => {
        updateProductionSheetPropriety('creating', true)
        return () => {
            setProductionSheet(emptyProcutionSheet)
        }
    }, [])

    // CALL SESSIONS
    useEffect(() => {
        if (selectedSessionId) {
            updateProductionSheetPropriety('session_id', sessionId)
            const controller = new AbortController();
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/session/${selectedSessionId}`, { method: 'get' }, controller.signal)
                .then(res => {
                    const responseSession = res.data.data
                    setSession(responseSession)
                    const defautlQuantity = responseSession?.real_quantity ?? responseSession?.indicative_quantity
                    updateProductionSheetPropriety('parameters.quantity_to_produce', defautlQuantity)
                    updateProductionSheetPropriety('parameters.produced_quantity', defautlQuantity)
                    setSpecificationProductId(responseSession.product.product_specification_id)
                    if (responseSession.step) {
                        if (responseSession.step.name.toUpperCase() === 'PRODUCTION') {
                            updateProductionSheetPropriety('parameters.steps.recipe', true)
                        } else if (responseSession.step.name.toUpperCase() === 'CONDITIONNEMENT') {
                            updateProductionSheetPropriety('parameters.steps.primaryConditionning', true)
                        } else if (responseSession.step.name.toUpperCase() === 'ETIQUETAGE') {
                            updateProductionSheetPropriety('parameters.steps.secondaryConditionning', true)
                        } else if (responseSession.step.name.toUpperCase() === 'mise en boite'
                            || responseSession.step.name.toLowerCase() === "mise en boîte") {
                            updateProductionSheetPropriety('parameters.steps.secondaryConditionning', true)
                        } else if (responseSession.step.is_last_step === true || responseSession.step.name.toLowerCase() === "colisage") {
                            updateProductionSheetPropriety('parameters.steps.stock', true)
                            updateProductionSheetPropriety('parameters.stock_entries', {})
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: 'Nous rencontrons une erreur avec nos serveurs SESSSION ID',
                        infinit: false,
                    })
                })
            return () => {
                controller.abort()
            }
        }
    }, [selectedSessionId])

    const createProductionSheet = (e) => {
        e.preventDefault()
        addProductionSheet()
            .then(_ => onCreate && onCreate())
    }

    const callPipedriveApi = useCallPipedriveApi()
    const [deals, setDeals] = useState(null)
    const [searchDealId, setSearchDealId] = useState('')
    const [dealSessions, setDealSessions] = useState([])
    const [dealSessionsProduct, setDealSessionsProduct] = useState(null)
    const [productSessions, setProductSessions] = useState(null)
    const [selectedProductId, setSelectedProductId] = useState('')

    useEffect(() => {
        if (searchDealId) {
            const controller = new AbortController()
            const currentDeal = deals.find(deal => deal.id === searchDealId)
            const organizationId = currentDeal.org_id.value
            if (organizationId) {
                callApi(
                    `${appConstante.servers.PANORAMA_ENDPOINT}/erp/products-specifications`, { method: "get" }, controller.signal,
                    {
                        filter: { organization_id: organizationId },
                        limit: 0,
                    },
                ).then((res) => {
                    setProductSpecifications(res.data.data)

                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }, [searchDealId])

    // CALL PIPEDRIVE DEALS

    useEffect(() => {
        if (!selectedSessionId) {
            const controller = new AbortController()
            const filter = {
                limit: 500,
                sort: "id DESC",
                filter_id: 292,
            }
            callPipedriveApi("deals", { signal: controller.signal }, filter)
                .then((res) => {
                    setDeals(res.data.data)
                })
                .catch((error) => {
                    console.log(error)
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: "Une erreur s'est produite",
                        infinit: false,
                    })
                })
            return () => {
                controller.abort()
            }
        }
    }, [])

    // CALL SESSIONS OF THE SELECTED DEAL
    useEffect(() => {
        if (searchDealId) {
            const controller = new AbortController()
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/deal/${searchDealId}/sessions`, { method: 'get' }, controller.signal)
                .then(res => {
                    setDealSessions(res.data.data)
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        error: true,
                        title: 'Erreur',
                        content: 'Nous rencontrons une erreur avec nos serveurs SESSIONNNNSSS',
                        infinit: false,
                    })
                })
            return () => {
                controller.abort()
            }
        } else {
            setDealSessions(null)
            setSelectedProductId('')
        }
    }, [searchDealId])

    // GET THE PRODUCTS OF DEALSESSIONS
    useEffect(() => {
        if (dealSessions) {
            const productList = dealSessions
                .filter(session => session.hasOwnProperty('product'))
                .map(session => ({ id: session.product.id, value: session.product.name }))
            setDealSessionsProduct(removeDuplicatesObjectInArray("id", productList))
        } else {
            setDealSessionsProduct(null)
        }
    }, [dealSessions])

    useEffect(() => {
        if (dealSessions) {
            const selectedSessionWithProductPropriety = dealSessions.filter(session => session.hasOwnProperty('product'))
            setProductSessions(selectedSessionWithProductPropriety.filter(session => session.product.id === selectedProductId))
        }
    }, [selectedProductId])

    const selectCreatedBulk = (bulk) => {
        showPopup2(false)
        setSelectedBulkValue(formatBulkName(bulk))
    }

    const selectCreatedBatch = (batch) => {
        showPopup2(false)
        setSelectedBatchValue(formatBatchName(batch))
    }

    console.log(productionSheet.steps);

    return (
        <form onSubmit={e => createProductionSheet(e)} className='list-item'>
            <h2 className="flex justify-center" >Création d'une fiche de production</h2>
            <div className='flex wrap center m-b-25'>
                {!sessionId
                    ? deals
                        ? <Datalist
                            value={""}
                            placeholder={"Sélectionner un devis"}
                            items={deals && deals.map(deal => ({ id: deal.id, value: `${deal.id} ${deal.title} (${deal.org_name})` }))}
                            onSelect={setSearchDealId}
                            width={'48%'}
                            inputWidth={"100%"}
                        />
                        : <MidLoader />
                    : null}
                {searchDealId
                    ? dealSessionsProduct
                        ? dealSessionsProduct.length > 0
                            ? <Datalist
                                value=""
                                placeholder="Sélectionner un produit"
                                items={dealSessionsProduct}
                                onSelect={setSelectedProductId}
                                width={'48%'}
                            />
                            : <h5 className='half-width'>Aucun produit avec spécification pour ce devis</h5>
                        : <MidLoader />
                    : null
                }
                {selectedProductId && searchDealId
                    ? productSessions
                        ? productSessions.length > 0
                            ? <Datalist
                                value=""
                                placeholder="Sélectionner une session"
                                items={productSessions.map(session => ({
                                    id: session.id,
                                    value:
                                        `[${session.step.name}] Qty : ${session.indicative_quantity} (${new Date(session.start).toLocaleDateString("fr-FR")} ${new Date(session.start).toLocaleTimeString("fr-FR")} - ${new Date(session.end).toLocaleDateString("fr-FR")} ${new Date(session.end).toLocaleTimeString("fr-FR")})`
                                }))}
                                onSelect={setSelectedSessionId}
                                width={'48%'}
                            />
                            : <h5>Aucune session pour ce produit</h5>
                        : <MidLoader />
                    : null
                }
                {selectedSessionId && searchDealId && (session && !session.product?.product_specification_id)
                    ? productSpecifications
                        ? productSpecifications.length > 0
                            ? <Datalist
                                value={""}
                                defaultId={specificationProductId}
                                placeholder={'Sélectionner une specification'}
                                items={productSpecifications && productSpecifications.map(specifProduct => ({ id: specifProduct.id, value: specifProduct.article.name }))}
                                onSelect={setSpecificationProductId}
                                width='48%'
                            />
                            : <div className='m-t-25' style={{ width: '48%' }}>
                                <button className='light-button'>
                                    <Link
                                        to={`/erp/specif-and-recipe/specifications-product/add-specifications-product`}>Créer une spécification produit</Link>
                                </button>
                            </div>
                        : <MidLoader />
                    : null
                }
            </div>
            {session
                ? specificationProductId
                    ? <>
                        <div className='m-t-25'>
                            <h4 className='m-t-0'>Informations de la fiche de prod</h4>
                            <div className='flex-start flex-center'>
                                <Input type='date'
                                    label='Date de création de la fiche de production'
                                    required={true}
                                    value={productionSheet.parameters.date}
                                    onInput={updateProductionSheetPropriety}
                                    customParameters={['parameters.date']}
                                />
                                <Input
                                    type='number'
                                    label='Quantité à produire'
                                    required={true}
                                    value={productionSheet.parameters.quantity_to_produce}
                                    onInput={updateProductionSheetPropriety}
                                    customParameters={['parameters.quantity_to_produce']}
                                />
                                {/* <div className='flex basis'>
                                <Input type='texte'
                                    label='Numéro de lot CBD Protect'
                                    required={true}
                                />
                                <button >
                                    Générer un numéro de lot
                                </button>
                            </div> */}
                            </div>
                        </div>

                        <ConsultProductSpecification
                            id={specificationProductId}
                            defaultSteps={productionSheet.steps}
                            canEdit={false}
                        />

                        <label>
                            <div>
                                Commentaire / remarques
                            </div>
                            <textarea
                                value={productionSheet.parameters.comment}
                                onInput={e => updateProductionSheetPropriety('parameters.comment', e.target.value)}
                                style={{ width: '100%', minHeight: '100px' }}
                                placeholder='Opérateur, difficultées, remarque'
                            />
                        </label>
                        <div className='flex-center gap-2 column gap-1ull-width m-t-25 m-b-10'>
                            <div className='flex flex-wrap gap-1'>
                                {productionSheet.steps?.recipe
                                    ? <>
                                        <SelectBulk
                                            onSelect={updateProductionSheetPropriety}
                                            customParameters={['bulk_id']}
                                            width='300px'
                                            value={selectedBulkValue}
                                        />
                                        <div className='flex-end'>
                                            <CreateBulkBtn setter={selectCreatedBulk} />
                                        </div>
                                    </>
                                    : null}
                                {productionSheet.steps?.secondaryConditionning
                                    ? <div className='flex gap-1 end'>
                                        <SelectBatch
                                            setter={updateProductionSheetPropriety}
                                            customParameters={['batch_id']}
                                            required={true}
                                            value={selectedBatchValue}
                                            width={'500px'}
                                        />
                                        <div>
                                            <CreateBatchBtn defaultArticleId={productionSheet.article_id} setter={selectCreatedBatch} />
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <div className='flex-center end gap-2 p-t-25'>
                                <Input
                                    label='Produits réalisé pour cette session'
                                    type='number'
                                    value={productionSheet.parameters.produced_quantity}
                                    onInput={updateProductionSheetPropriety}
                                    customParameters={['parameters.produced_quantity']}
                                    style={{ width: '300px' }}
                                    step='1'
                                />
                                {/* <select value={productionSheet.state} onChange={e => updateProductionSheetPropriety('state', e.target.value)}>
                                <option value={2}>Terminé</option>
                                <option value={1}>Brouillon</option>
                            </select> */}
                                <button className='strong-button m-0'> Ajouter la fiche de production</button>
                            </div>
                        </div>
                    </>
                    : <SelectProductSpecification setter={setSpecificationProductId} />
                : <MidLoader />
            }

        </form>
    )
}
