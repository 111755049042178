import React, { useEffect, useState, useContext } from 'react'
import Datalist from 'Components/Dataliste/Dataliste'
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import MidLoader from 'Components/MidLoader/MidLoader'
import { ProductSpecificationContext } from 'Context/ERP/ProductSpecificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'


export default function SelectProductSpecification({
    defaultId = null,
    defaultValue = '',
    setter,
    className = "",
    customParameters = [],
    placeholder = 'Choisir une spécification produit',
    width = '100%',
    style,
    reload = true
}) {

    const { productSpecifications, reloadProductSpecification } = useContext(ProductSpecificationContext)
    const [defaultProductSpecValue, setDefaultProductSpecValue] = useState(defaultValue)

    useEffect(() => {
        if (productSpecifications && defaultId) {
            const formatedProductSpecifications = productSpecifications.map(productSpec => ({ id: productSpec.id, value: productSpec.article?.name }))
            const defaultProductSpec = formatedProductSpecifications.find(productSpec => productSpec.id === defaultId);
            defaultProductSpec
                ? setDefaultProductSpecValue(defaultProductSpec.value)
                : setDefaultProductSpecValue('')
            defaultProductSpec && setter(...customParameters, defaultProductSpec.id)
        }
    }, [productSpecifications, defaultId])

    return (
        <div className={`flex-basis gap-1 ${className}`} >
            {
                productSpecifications
                    ? <>
                        < Datalist
                            style={style}
                            value={defaultProductSpecValue ? defaultProductSpecValue : ''}
                            placeholder={placeholder}
                            items={productSpecifications.map(productSpec => ({ id: productSpec.id, value: productSpec?.article?.name }))}
                            onSelect={setter}
                            customParameters={customParameters}
                            width={width}
                            required={true}
                        />
                        {
                            reload
                                ? <button className='light-button'
                                    onClick={e => reloadProductSpecification()
                                    } >
                                    Actualiser
                                </button >
                                : null

                        }
                    </>
                    : <MiniLoader />
            }
        </div >
    )
}
