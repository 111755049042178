import React from 'react'

export default function DeliverySheetProducts({ product }) {
    return (
        <tr>
            <td>
                <div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            textAlign: "left",
                        }}
                    >
                        {product.pipedrive_name}
                    </div>
                    {product.comments
                        ? <div
                            style={{
                                fontSize: "14px",
                                color: "rgb(80,80,80)",
                                textAlign: "left",
                            }}
                            dangerouslySetInnerHTML={{ __html: product.comments }} />
                        : null}
                    {product.bulks_names?.length
                        ? <div className="m-t-10" style={{ fontSize: "14px", color: "rgb(80,80,80)", textAlign: "left", }}>
                            N° de bulk : {product.bulks_names.map((bulkId, index) => <div key={index}>{bulkId}</div>)}
                        </div>
                        : null}
                    {product.batchs_names?.length
                        ? <div className="m-t-10" style={{ fontSize: "14px", color: "rgb(80,80,80)", textAlign: "left", }}>
                            N° de lot : {product.batchs_names.map((batchId, index) => <div key={index}>{batchId}</div>)}
                        </div>
                        : null}
                </div>
            </td>
            <td>{product.deal_quantity}</td>
            <td>{product.quantity}</td>
            <td>{product.to_sheep_quantity}</td>
        </tr>
    )
}
