import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ContextContainer from "./Context/ContextContainer"
import { msalConfig } from "./azureConfig"

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <ContextContainer>
        <App />
      </ContextContainer>
    </BrowserRouter>
  </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
