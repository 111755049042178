import React, { useContext } from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from 'Context/NotificationContext'
import { useCallAnalysesApi } from 'Functions'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function DeleteArticle({ analyse, deleteAnalyse }) {

    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const callAnalysesApi = useCallAnalysesApi()

    const removeAnalyse = () => {
        addNotificationContent({
            title: 'Suppression en cours',
            content: <MiniLoader />,
        })
        callAnalysesApi(`analyse/${analyse.ID}`, { method: 'delete' })
            .then(res => {
                deleteAnalyse(analyse.ID)
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "L'analyse a été supprimée",
                    infinit: false
                })
            })
            .catch(err => {
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.res.message,
                    infinit: false
                })
            })
    }
    return (
        <>
            <h2>Supprimer l'analyse {analyse.ID}</h2>
            <p>Attention, cette suppression est irréversible. Les données associés ne serons plus accessibles</p>
            <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
                <button
                    type="button"
                    onClick={() => showPopup(false)}
                    className="erp-button erp-big-button"
                >
                    Annuler
                </button>
                <button
                    className="erp-button erp-big-button error-strong-button"
                    style={{ display: "block", margin: "auto" }}
                    onClick={() => removeAnalyse()}
                >
                    Supprimer
                </button>
            </div>
        </>
    )
}
