import React, { useContext, useEffect, useState } from 'react'
import ProductSpecificationArticles from './ProductSpecificationArticles/ProductSpecificationArticles'
import { ProductSpecificationContext, ProductSpecificationInterface } from 'Context/ERP/ProductSpecificationContext'
import Input from 'Components/FormElement/Input'
import set from 'lodash/set.js';
import PriceList from 'Components/_ERP/ProductSpecification/PriceList';
import MiniLoader from 'Components/MiniLoader/MiniLoader';

export interface Parameters {
    quantity: number | string, //quantité du produit
    article: {
        quantity: number | string /// volume / masse du produit
        unit_of_measure: string
        type_product: string,
        cbd_concentration: string,
    },
    pricing: {
        inboxing: boolean,
        conditioning: boolean,
        packing: boolean,
        labeling: boolean,
    },
    marge: number | string,
    show_pricing: boolean,
    sale_marge: number | string,
    loaded: boolean,
    editable: boolean,
}

interface Props {
    productSpecificationId: number
    defaultParameters: Parameters
    defaultRawMaterials: null | Array<any>
    setterRawMaterials: Function | null
    setterParameters: Function | null
}

export const emptyParameters: Parameters = {
    quantity: '100',
    article: {
        quantity: '10',
        unit_of_measure: 'mL',
        type_product: 'Huile sublinguale',
        cbd_concentration: ''
    },
    pricing: {
        inboxing: true,
        conditioning: true,
        packing: true,
        labeling: true,
    },
    marge: '2',
    sale_marge: '4',
    show_pricing: false,
    loaded: false,
    editable: false,
}

export default function ProductSpectificationDetails({
    productSpecificationId,
    defaultParameters = emptyParameters,
    defaultRawMaterials = null,
    setterRawMaterials = null,
    setterParameters = null
}: Props) {

    const { getProductSpecification } = useContext(ProductSpecificationContext)

    const [productSpecification, setProductSpecification] = useState<ProductSpecificationInterface | null>(null)
    const [pricing, setPricing] = useState({
        materials_tot: 0,
        materials_unit: 0
    })


    // useEffect(() => {
    //     console.log("productSpecificationId", productSpecificationId)
    //     console.log("parameters", parameters)
    //     console.log("defaultRawMaterials", defaultRawMaterials)
    //     console.log("setterRawMaterials", setterRawMaterials)
    // }, [])


    const [parameters, setParameters] = useState<Parameters>({
        ...emptyParameters,
        ...defaultParameters,
        article: productSpecificationId
            ? {
                quantity: '',
                unit_of_measure: '',
                type_product: '',
                cbd_concentration: ''

            }
            : defaultParameters.article
                ? defaultParameters.article
                : emptyParameters.article,
    })

    // useEffect(() => {
    //     if (defaultParameters) {
    //         setParameters(prv => ({ ...prv, article: { ...defaultParameters.article } }))
    //     }
    // }, [
    //     defaultParameters.article.type_product,
    //     defaultParameters.article.quantity,
    //     defaultParameters.article.unit_of_measure,
    //     defaultParameters.article.cbd_concentration,
    // ])


    const setParametersKey = (keyPath: string, value: any) => {
        setParameters(prvParameters => {
            const newParameters = prvParameters
            set(newParameters, keyPath, value);
            setterParameters && setterParameters(keyPath, value)
            return { ...newParameters };
        })
    }

    useEffect(() => {
        getProductSpecification(productSpecificationId)
            .then((productSpecification: ProductSpecificationInterface) => {
                setProductSpecification(productSpecification)
            })
    }, [productSpecificationId])

    useEffect(() => {
        if (productSpecification?.article) {
            setParametersKey(
                'article',
                {
                    quantity: parseFloat(productSpecification.article.volume),
                    unit_of_measure: productSpecification.article.unit_of_measure,
                    type_product: productSpecification.article?.type_product,
                    cbd_concentration: productSpecification.article.cbd_concentration,
                }
            )
            setParametersKey('loaded', true)
        }
    }, [productSpecification?.article])

    useEffect(() => {
        if (!productSpecificationId && parameters.article?.quantity && parameters.article?.unit_of_measure) {
            setParametersKey('loaded', true)
        }
    }, [parameters.article?.quantity, parameters.article?.unit_of_measure])

    return (
        <div>
            {parameters.loaded
                ? <>
                    <strong>Paramètres de production</strong>
                    <div className='flex-start gap-1'>
                        <div className='flex-1'>
                            <Input
                                value={parameters.quantity}
                                type='number'
                                onInput={setParametersKey}
                                customParameters={['quantity']}
                                label={"Quantité à produire"}
                                step='1'
                            />
                        </div>
                        <div className='flex-1'>
                            <Input
                                value={parameters.marge}
                                type='number'
                                onInput={setParametersKey}
                                customParameters={['marge']}
                                label={"Marge des MP (pour production)"}
                                step='1'
                            />
                        </div>
                        {parameters.show_pricing
                            ? <div className='flex-1'>
                                <Input
                                    value={parameters.sale_marge}
                                    type={'number'}
                                    onInput={setParametersKey}
                                    customParameters={['sale_marge']}
                                    label={'Marge de vente'}
                                    step='0.1'
                                />
                            </div>
                            : null
                        }
                    </div>
                    {parameters.show_pricing
                        ? <div className='m-t-25'>
                            <PriceList
                                parameters={parameters}
                                pricing={pricing}
                                setterParameters={setterParameters}
                            />
                        </div>
                        : null
                    }
                    <ProductSpecificationArticles
                        productSpecificationId={productSpecificationId}
                        parameters={parameters}
                        defaultRawMaterials={defaultRawMaterials}
                        setterPricing={setPricing}
                        setterRawMaterials={setterRawMaterials}
                    />
                </>
                : <MiniLoader />
            }
        </div>
    )
}
