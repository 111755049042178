import React from 'react'

export default function Commandes({ userInfos }) {

    return (
        <>
            {userInfos?.user_orders.length
                ? <div>
                    <h3>Commandes</h3>
                    <table className='beautiful-array'>
                        <thead>
                            <tr>
                                <th>Commande</th>
                                <th>Date</th>
                                <th>N° de devis</th>
                                <th>Facture</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userInfos?.user_orders?.map((order, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{order.title}</td>
                                        <td>{(new Date(order.won_time)).toLocaleDateString()}</td>
                                        <td>{order.id}</td>
                                        {order['083c6b85db7a7b60f271271d3329eb3a7a3997a1']
                                            ? <td><a target='_blank' href={order['083c6b85db7a7b60f271271d3329eb3a7a3997a1']}>
                                                {order['cc3bddb9b08ee9ccfeded5485cf582f311558f1c']}
                                            </a></td>
                                            : <td>Facture disponible prochainement</td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : <h5>Aucunes commandes pour ce client</h5>
            }
        </>
    )
}
