import React from 'react'
import './DeleteButton.css'

export default function DeleteButton({ callBack, color = "#f25c66", parameters = [], alwaysDisplay = false }) {
    return (
        <button type='button' className={alwaysDisplay ? "close-container always-display" : "close-container"} onClick={() => callBack(...parameters)}>
            <span style={{ backgroundColor: color }} className="leftright"></span>
            <span style={{ backgroundColor: color }} className="rightleft"></span>
        </button>
    )
}
