import React, {useEffect} from 'react';
import SubNavBar from 'Components/NavBar/SubNavBar/SubNavBar';
import { useNavigate, useLocation } from 'react-router-dom';
import ThirdNavBar from 'Components/NavBar/ThirdNavBar/ThirdNavBar';

export default function Recipe() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      location.pathname==='/erp/specif-and-recipe/recipes' && navigate(`/erp/specif-and-recipe/recipes/my-recipes`); //Sous onglet par défaut
    }, [location.pathname])
  return (
    <ThirdNavBar menu={[
      { link: "/erp/specif-and-recipe/recipes/my-recipes", name: "Consulter", access: true},
      { link: "/erp/specif-and-recipe/recipes/add-recipe", name: "Ajouter", access: true },
    ]} />
  )
}