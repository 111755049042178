import React, { useState } from 'react'
import LastNavBar from 'Components/NavBar/LastNavBar/LastNavBar'
import './ProductTabs.css'

/**
 *
 * @param {object} tabs object of titles propertys and html values in the format {title1: '<html/>', title2: '<html/>'...}
*/

export default function ProductTabs({ tabs }) {

    const subProductItems = [
        {
            slug: 'description',
            label: 'Description'
        },
        {
            slug: 'ingredients',
            label: 'Ingrédients'
        },
        {
            slug: 'personnalisations',
            label: 'Personnalisations'
        },
        {
            slug: 'utilisation',
            label: 'Utilisation'
        },
    ]
    const [currentProductItem, setCurrentProductItem] = useState('description')

    return (
        <div className='product-items m-b-25'>
            <LastNavBar
                items={
                    subProductItems
                        .filter(item => tabs[item.slug])
                        .map((item, index) => ({
                            label: item.label, active: index === 0 ? true : false, onClick: setCurrentProductItem, value: item.slug
                        }))
                }
            />
            <div>
                {currentProductItem && tabs[currentProductItem] && (
                    <div dangerouslySetInnerHTML={{ __html: tabs[currentProductItem] }} />
                )}
            </div>
        </div>
    )
}
