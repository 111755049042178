import React, { useContext, useEffect } from "react"
import "App.css"
import Overlay from "Components/Overlay/Overlay"
import Popup, { Popup2 } from "Components/Popup/Popup"
import Notification from "Components/Notification/Notification"
import Loader from "Components/Loader/Loader"
import { PopupContext } from "Context/PopupContext"
import { Routes, Route } from "react-router-dom"

import NotFound from "AppContainer/NotFound"

import Invoices from "AppContainer/Invoices/Invoices"
import MyInvoices from "AppContainer/Invoices/MyInvoices/MyInvoices"
import ConvertDealToInvoice from "AppContainer/Invoices/ConvertDealToInvoice/ConvertDealToInvoice"

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react"

import MyAccount from "AppContainer/HomePage/Login/MyAccount"
import MainNavBar from "Components/NavBar/MainNavbar/MainNavBar"
import { ErpRoutes } from "AppContainer/ERP/ErpRoutes"
import { reglementaireRoutes } from "AppContainer/REGLEMENTAIRE/routes"
import { CommercialRoutes } from "AppContainer/COMMERCIAL/CommercialRoutes"
import { DashboardRoutes } from "AppContainer/DASHBOARD/DashboardRoutes.tsx"
import { AnalysesRoutes } from "AppContainer/ANALYSES/AnalysesRoutes"
import { CBDProtectRoutes } from "AppContainer/CBD PROTECT/CBDProtectRoutes"

function App() {
  /* CONTEXT */
  const { popupContent, popupContent2 } = useContext(PopupContext)

  const printRoute = (routesArr) => {
    return routesArr.map((route, index) => {
      if (route.subRoutes) {
        return (
          <Route key={index} {...route}>
            {printRoute(route.subRoutes)}
          </Route>
        )
      } else {
        return <Route key={index} {...route} />
      }
    })
  }

  return (
    <div className="App">
      <MainNavBar />
      <div id="page-container">
        <div id="content">
          <Routes>
            <Route path="/" element={<MyAccount />} />
            <Route path="/login" element={<MyAccount />} />
            <Route path="/mon-compte" element={<MyAccount />} />

            {printRoute(ErpRoutes)}
            {printRoute(DashboardRoutes)}
            {printRoute(CommercialRoutes)}
            {printRoute(AnalysesRoutes)}
            {printRoute(reglementaireRoutes)}
            {printRoute(CBDProtectRoutes)}

            <Route path="/invoices" element={<Invoices />}>
              <Route
                path="/invoices/devis-a-facturer"
                element={<ConvertDealToInvoice />}
              />
              <Route path="/invoices/factures" element={<MyInvoices />} />
              <Route
                path="/invoices/statistiques"
                element={<div>....</div>}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <Overlay>
        <Popup>{popupContent.content}</Popup>
      </Overlay>
      <Popup2>{popupContent2}</Popup2>
      <Notification />
      <Loader />
    </div>
  )
}

export default App
