import React, { createContext, useState, useRef, useEffect } from "react"

/**
 * use addNotificationContent() to show a notification
 */
export const NotificationContext = createContext()

function NotificationContextProvider(props) {
  const [notificationContent, setNotificationContent] = useState({
    error: false,
    title: "",
    content: "",
    infinit: false,
  })

  /**
   * @param {object} content Contenu de la notification sous forme de JSON {error: boolean, title: string, content: string, infinit: boolean}
   */
  const addNotificationContent = (content) => {
    setNotificationContent(_ => content);
  }


  return (
    <NotificationContext.Provider
      value={{
        notificationContent,
        addNotificationContent,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}

export default NotificationContextProvider
