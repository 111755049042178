import React, { useEffect, useContext, useState } from 'react'
import { removePropertyFromObjectsInArray, useCallApi } from 'Functions'
import { useParams } from 'react-router-dom'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import './Recipe.css'
import RecipeFields from './shared/RecipeFields'
import { PopupContext } from 'Context/PopupContext'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function EditRecipe() {
    let { id } = useParams();
    const callApi = useCallApi()
    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const [newRecipe, setNewRecipe] = useState(null)

    useEffect(() => {
        const controller = new AbortController()
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe/${id}`, { method: 'get' }, controller.signal)
            .then(res => {
                setNewRecipe(res.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
        return () => {
            controller.abort()
        }
    }, [id])


    const sendRecipe = (e) => {
        e.preventDefault();
        addNotificationContent({
            title: 'Modification en cours',
            content: <MiniLoader />,
            infinit: true
        })
        const newBases = removePropertyFromObjectsInArray(newRecipe.bases, 'article')
        const newCannabinoids = removePropertyFromObjectsInArray(newRecipe.cannabinoids, 'article')
        const newOtherIngredients = removePropertyFromObjectsInArray(newRecipe.other_ingredients, 'article')

        const recipeToSend = {
            ...newRecipe,
            bases: newBases,
            cannabinoids: newCannabinoids,
            other_ingredients: newOtherIngredients
        }

        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/recipe/${newRecipe.id}`, { method: 'put' }, null, recipeToSend)
            .then(res => {
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "Recette mise à jour",
                    infinit: false
                })
            }).catch((err) => {
                console.log(err);
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response.message,
                    infinit: false
                })
            })
        return () => {
            controller.abort()
        }
    }
    return (
        <>
            {!newRecipe ? <MidLoader />
                : <>
                    <h2 className="flex justify-center m-b-25" >Mise à jour d'une recette</h2>
                    <form onSubmit={e => sendRecipe(e)} className='m-w-1220'>
                        <RecipeFields recipe={newRecipe} setRecipe={setNewRecipe} />
                        <button className='strong-button m-b-15 m-t-50'>Modifier la recette</button>
                    </form>
                </>}
        </>
    )
}

