import Datalist from "Components/Dataliste/Dataliste";
import MiniLoader from "Components/MiniLoader/MiniLoader";
import { OrdersContext } from 'Context/Analyse/OrdersContext'
import { UsersContext } from "Context/Analyse/UsersContext";
import { useContext, useEffect, useState } from "react";

export default function DatalistOrders({
    style = { minWidth: '300px' },
    placeholder = 'Filtrer par commande',
    required = false,
    onSelect,
    defaultOrderId = null,
    onlyAvaible = false,
}) {

    const { users } = useContext(UsersContext)
    const { orders } = useContext(OrdersContext)
    const [ordersWithUserInfos, setOrdersWithUserInfos] = useState([])

    // PUT USER INFOS IN ORDER ARRAY TO SHOW //
    useEffect(() => {
        if (users && orders && users.length) {
            const userDatas = users.map(user => user.data)
            setOrdersWithUserInfos(orders
                .filter(order => onlyAvaible
                    ? (order.available || order.subscription)
                    : true
                )
                .reverse()
                .map(order => ({
                    ...order,
                    user_infos: userDatas.find(user => user.ID == order.id_client) ?? {}
                })))
        }
    }, [users, orders])

    return (
        <>
            {ordersWithUserInfos.length
                ? <Datalist
                    style={style}
                    placeholder={placeholder}
                    required={required}
                    defaultId={defaultOrderId}
                    onSelect={onSelect}
                    items={ordersWithUserInfos.map(order => {
                        return {
                            id: order.ID,
                            value:
                                `${order.ID} (${order.user_infos.billing_company
                                    ? order.user_infos.billing_company
                                    : order.user_infos.user_nicename} - ${order.user_infos.billing_email
                                        ? order.user_infos.billing_email
                                        : order.user_infos.user_email})`
                        }
                    })}
                />
                : <MiniLoader />
            }
        </>
    )
}
