import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import React, { useContext } from 'react'
import { BulkContext } from 'Context/CBDProtect/Bulk'

export default function SelectBulk({
    refresh = true,
    placeholder = 'Sélectionner un numéro bulk',
    width = '300px',
    required = true,
    onSelect,
    customParameters,
    style,
    value = '',
    defaultId = null
}) {

    //states
    const { bulks, getBulks, formatBulkName } = useContext(BulkContext)

    /******************************************************/
    return (
        <div className='flex end gap-1'>
            {bulks
                ? <>
                    <Datalist
                        defaultId={defaultId}
                        placeholder={placeholder}
                        items={bulks.map(bulk => ({ id: parseInt(bulk.ID), value: formatBulkName(bulk) }))}
                        onSelect={onSelect}
                        customParameters={customParameters}
                        width={width}
                        required={required}
                        style={style}
                        value={value}
                    />
                    {refresh
                        ? <button
                            type='button'
                            onClick={_ => getBulks()} className='light-button'>actualiser</button>
                        : null
                    }
                </>
                : <Skeleton height={'50px'} />
            }
        </div>
    )
}
