import React, { useContext, useEffect, useState } from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from 'Context/NotificationContext'
import { deleteEmptyObjectsField, useCallAnalysesApi, useCallApi } from 'Functions'
import AnalyseFields from 'AppContainer/ANALYSES/AnalysesItem/AnalyseFields'
import { ableToAddResults, analyseConsommables, emptyAnalyse } from 'AppContainer/ANALYSES/analysesConstantes'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { appConstante } from 'appConstante'

export default function AddAnalyses({ product, analyse, setter, origin }) {

    const { showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const callAnalysesApi = useCallAnalysesApi()
    const callApi = useCallApi()
    const [newAnalyse, setNewAnalyse] = useState({ ...emptyAnalyse, ...analyse })
    const [addHistory, setAddHistory] = useState(true)

    useEffect(() => {
        if (product) {
            setNewAnalyse(prvAnalyse => ({ ...prvAnalyse, produit: { ...product, emplacement: "Frigo" } }))
        }
    }, [product])

    useEffect(() => {
        if (origin) {
            setNewAnalyse(prvAnalyse => ({ ...prvAnalyse, ...origin }))
        }
    }, [origin])

    const sendAnalyse = async (e) => {
        e.preventDefault()
        // return
        const controller = new AbortController()
        if (addHistory && ableToAddResults(product?.product_order?.product_id)) {
            // addNotificationContent({
            //     title: 'Retrait des consommables en cours',
            //     content: <MiniLoader />,
            //     infinit: true,
            // })

            //Pas d'attente pour retirer les consomable
            Promise.all(analyseConsommables.map(history =>
                callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/history`,
                    { method: 'POST' },
                    null,
                    {
                        ...history, comment: `Analyse effectuée pour ${newAnalyse.num_reference
                            ? `le numéro de référence ${newAnalyse.num_reference}`
                            : newAnalyse.bulk_id
                                ? `le numéro de bulk ${newAnalyse.bulk_id}`
                                : `le numéro de batch ${newAnalyse.batch_id}`
                            }`
                    },
                    null //ne surtout pas donner e sinon il ne fait qu'une requête, car il bloque les doublons.
                )
            ))
        }
        addNotificationContent({
            title: 'Ajout en cours',
            content: <MiniLoader />,
            infinit: true,
        })
        callAnalysesApi(`analyses`, { method: "POST", signal: controller.signal }, deleteEmptyObjectsField(newAnalyse), e)
            .then((res) => {
                setter && setter(res.data.data)
                showPopup(false)
                addNotificationContent({
                    error: false,
                    title: 'Succès',
                    content: "L'analyse a été ajoutée",
                    infinit: false
                })
            })
            .catch(err => {
                console.log(err);
                addNotificationContent({
                    error: true,
                    title: 'Erreur',
                    content: err.response?.data?.message ?? "Une erreur est survenue",
                    infinit: false
                })
            })
        return () => {
            controller.abort()
        }
    }

    return (
        <div>
            {newAnalyse.num_reference
                ? <h2>Ajouter une analyse</h2>
                : newAnalyse.bulk_id
                    ? <h2>Ajouter une analyse pour le n° de bulk {newAnalyse.bulk_id}</h2>
                    : newAnalyse.batch_id
                    && <h2> Ajouter une analyse pour le n° de batch {newAnalyse.batch_id}</h2>
            }

            <form onSubmit={e => sendAnalyse(e)}>
                <AnalyseFields
                    setter={setNewAnalyse}
                    analyse={newAnalyse}
                />
                {ableToAddResults(product?.product_order?.product_id) ?
                    < div className='flex-center column'>
                        <label className='flex-start flex-center'>
                            <input
                                type='checkbox'
                                className='erp-check'
                                checked={addHistory}
                                onChange={e => setAddHistory(e.target.checked)}
                            />
                            Déstocker les consommables</label>
                        <button className='strong-button'>Ajouter une analyse</button>
                    </div>
                    : <button className='strong-button'>Ajouter une analyse</button>}
            </form >

        </div >
    )
}
