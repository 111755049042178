import React, { useContext } from 'react'
import './MainNavBar.css'
import { useMsal } from "@azure/msal-react";
import { NavLink } from 'react-router-dom'
import { UserInfosContexte } from 'Context/UserInfosContexte';
import { appConstante } from 'appConstante';
import SigninButton from 'AppContainer/HomePage/Login/SigninButton';

export default function MainNavBar() {

    const { avaibleApps } = useContext(UserInfosContexte)
    const { accounts } = useMsal();

    return (
        <div className='main-nav-bar'>
            <img src={appConstante.parameters.LOGO_URL} />
            <div>
                {avaibleApps && avaibleApps.length > 0 ? (
                    <>
                        {avaibleApps.map(app => <NavLink key={app.slug} className='main-nav-button' to={`/${app.slug}`}>{app.name}</NavLink>)}
                    </>
                ) : <div>Aucune application disponible</div>}
            </div>
            <div>
                {accounts[0]
                    ? <NavLink to='/mon-compte' className='light-button my-account-button'>{accounts[0].name}</NavLink>
                    : <SigninButton />
                }
            </div>
        </div>
    )
}
