import React, {useContext} from 'react'
import { PopupContext } from "Context/PopupContext"
import { NotificationContext } from "Context/NotificationContext"
import { useCallApi } from 'Functions'
import { appConstante } from 'appConstante'

export default function DeleteArticle({ article, deleteArticle }) {
    const callApi = useCallApi()

    const { addPopupContent, showPopup } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const removeArticle = () => {
        callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/article/${article.id}`, {method: 'delete'})
        .then(res => {
            deleteArticle(article.id)
            showPopup(false)
        })
    }
    return (
        <>
            <h2>Supprimer l'article {article.name}</h2>
            <p>Attention, cette suppression est irréversible. Les données des fournisseurs associés ne serons plus accéssibles</p>
            <div className="flex" style={{ maxWidth: "750px", margin: "auto" }}>
                <button
                    type="button"
                    onClick={() => showPopup(false)}
                    className="erp-button erp-big-button"
                >
                    Annuler
                </button>
                <button
                    className="erp-button erp-big-button error-strong-button"
                    style={{ display: "block", margin: "auto" }}
                    onClick={() => removeArticle()}
                >
                    Supprimer
                </button>
            </div>
        </>
    )
}
