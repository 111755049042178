import React, { createContext, useState, useEffect, useContext } from "react"
import { appConstante } from "appConstante"
import { formatDateForInput, isNumber, useCallApi } from "Functions"
import set from 'lodash/set.js';
import { cloneDeep } from 'lodash';
import { NotificationContext } from "Context/NotificationContext";
import MiniLoader from "Components/MiniLoader/MiniLoader";
import { PlanningContext } from "../PlanningContext";

export const ProductionSheetContext = createContext()
export default function ProductionSheetContextProvider(props) {
    const callApi = useCallApi()

    const { getSession } = useContext(PlanningContext)
    const { addNotificationContent } = useContext(NotificationContext)
    const { updateSession } = useContext(PlanningContext)

    const emptyProcutionSheet = {
        product_specification_id: null,
        batch_id: '',
        bulk_id: '',
        conditioning: {
            packaging_primaire: [],
            packaging_secondaire: [],
        },
        recipe: {
            bases: [],
            cannabinoids: [],
            other_ingredients: [],
        },

        parameters: {
            date: formatDateForInput(),
            quantity_to_produce: 0,
            produced_quantity: 0,
            marge_to_add: 0,
            stock_entries: {},
            steps: {
                primaryConditionning: false,
                secondaryConditionning: false,
                recipe: false,
            },
        },
        session_id: null,
        creating: false,
        state: 2
    }
    const [productionSheet, setProductionSheet] = useState(emptyProcutionSheet)


    const updateProductionSheetPropriety = (keyPath, value) => {
        setProductionSheet(prevProductionSheet => {
            const newProductionSheet = { ...prevProductionSheet };
            set(newProductionSheet, keyPath, isNumber(value) ? parseFloat(value) : value);
            return newProductionSheet;
        });
    };

    //Mise à jour automatique de quantity to produce et de produced_quantity
    useEffect(() => {
        if (productionSheet.parameters.quantity_to_produce) {
            updateProductionSheetPropriety('parameters.produced_quantity', productionSheet.parameters.quantity_to_produce)
        }
    }, [productionSheet.parameters.quantity_to_produce])

    useEffect(() => {
        console.log('---- productionSheet ----', productionSheet);
    }, [productionSheet])

    useEffect(() => {
        getSession(productionSheet.session_id)
            .then(session => updateProductionSheetPropriety('session', session))
    }, [productionSheet.session_id])


    // POST TO ADD A PRODUCTION SHEET
    const addProductionSheet = () => {
        return new Promise((resolve, reject) => {
            const formatedProductionSheet = formatProductionSheetData(cloneDeep(productionSheet))

            formatedProductionSheet.id = null;
            if (formatedProductionSheet.success) {
                addNotificationContent({
                    title: "Ajout en cours",
                    error: false,
                    content: <MiniLoader />,
                    infinit: true,
                })
                callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet`, { method: 'post' }, null, formatedProductionSheet)
                    .then((res) => {
                        resolve(res.data.data)
                        updateSession(res.data?.data?.session)
                        addNotificationContent({
                            title: "Succès",
                            error: false,
                            content: "La fiche de production a bien a été crée",
                            infinit: false,
                        })

                    })
                    .catch((error) => {
                        reject(error?.response?.data)
                        addNotificationContent({
                            title: "Erreur",
                            error: true,
                            content: "Une erreur s'est produite",
                            infinit: false,
                        })
                    })
                return () => {
                    controller.abort()
                }
            } else {
                console.log("formatedProductionSheet", formatedProductionSheet)
                console.log("productionSheet", productionSheet)
                addNotificationContent({
                    error: true,
                    title: 'Problème avec vos données',
                    content: `${formatedProductionSheet?.error_message}`,
                })
            }
        })
    }

    // UPDATE PRODUCTION SHEET 
    const updateProductionSheet = (updatingPS) => {
        return new Promise((resolve, reject) => {

            const formatedPS = { ...updatingPS }
            delete formatedPS.session //payload
            addNotificationContent({
                title: "Modification en cours",
                error: false,
                content: <MiniLoader />,
                infinit: true,
            })
            callApi(`${appConstante.servers.PANORAMA_ENDPOINT}/erp/production-sheet/${formatedPS.id}`, { method: 'put' }, null, formatedPS)
                .then((res) => {
                    resolve(res.data.data)
                    addNotificationContent({
                        title: "Succès",
                        error: false,
                        content: "La fiche de production a bien a été modifié",
                        infinit: false,
                    })
                })
                .catch((error) => {
                    reject(error?.response?.data)
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: "Une erreur s'est produite",
                        infinit: false,
                    })
                })
            return () => {
                controller.abort()
            }
        })
    }

    const formatConditionningProductArray = (productArray) => {
        if (!productArray || !productArray.length) {
            return false
        }

        for (const product of productArray) {
            if (!formatConditionningProduct(product)) {
                return false;
            }
        }

        const formatedProductArray = productArray
            .map(product => {
                return formatConditionningProduct(product)
            })
        return formatedProductArray
    }

    const formatConditionningProduct = (product) => {
        if (!product || !product.quantity || !product.batch_id) {
            return false
        }
        return {
            quantity: parseFloat(product.quantity),
            batch_id: product.batch_id,
            info: product.info ?? {},
        }
    }

    const formatIngredientArray = (ingredientArray) => {
        if (!ingredientArray) {
            return false
        }

        if (!ingredientArray.length) { //pas d'ingrédient
            return true
        }

        for (const ingredient of ingredientArray) {
            if (!formatIngredient(ingredient)) {
                return false;
            }
        }

        const formatedIngredientArray = ingredientArray
            .map(ingredient => {
                return formatIngredient(ingredient)
            })

        return formatedIngredientArray
    }

    const formatIngredient = (ingredient) => {
        if (!ingredient || !ingredient.batchs || !ingredient.info || !ingredient.quantity || !ingredient.type) {
            return false
        }

        for (const batch of ingredient.batchs) {
            if (!formatBatch(batch)) {
                return false;
            }
        }

        const formatedBatchs = ingredient.batchs
            .map(batch => {
                return formatBatch(batch)
            })

        return {
            batchs: formatedBatchs,
            info: ingredient.info,
            quantity: parseFloat(ingredient.quantity),
            type: ingredient.type,
            cannabinoids: ingredient.cannabinoids,
        }
    }

    const formatBatch = (batch) => {
        if (!batch || !batch.real_quantity || !batch.batch_id) {
            return false
        }

        const formattedBatch = { ...batch }
        delete formattedBatch.datas
        return formattedBatch
    }

    const formatProductionSheetData = (productionSheet) => {
        try {
            productionSheet.success = true;
            if (productionSheet.steps.primaryConditionning) {
                const formatedProducts = formatConditionningProductArray(productionSheet.conditioning.primary)
                formatedProducts
                    ? productionSheet.conditioning.primary = formatedProducts
                    : productionSheet.success = false
            } else {
                productionSheet.conditioning.primary = []
            }
            if (productionSheet.steps.secondaryConditionning) {
                const formatedProducts = formatConditionningProductArray(productionSheet.conditioning.secondary)
                formatedProducts
                    ? productionSheet.conditioning.secondary = formatedProducts
                    : productionSheet.success = false
            } else {
                productionSheet.conditioning.secondary = []
            }

            if (productionSheet.steps.stock) {
                if (!productionSheet.parameters.stock_entries) {
                    productionSheet.success = false
                    productionSheet.error_message = "L'entrée en stock n'est pas définie";
                } else {
                    delete productionSheet.parameters.stock_entries.article
                }
            }

            if (productionSheet.steps.recipe) {
                let formatedProducts = null
                if (productionSheet.recipe.bases.length) {
                    let formatedProducts = formatIngredientArray(productionSheet.recipe.bases)
                    if (formatedProducts) {
                        productionSheet.recipe.bases = formatedProducts
                    } else {
                        productionSheet.success = false;
                        productionSheet.error_message = 'Un numéro de lot semble érroné pour un article de type bases';
                    }
                }

                if (productionSheet.recipe.cannabinoids.length) {
                    formatedProducts = formatIngredientArray(productionSheet.recipe.cannabinoids)
                    if (formatedProducts) {
                        productionSheet.recipe.cannabinoids = formatedProducts
                    } else {
                        productionSheet.success = false;
                        productionSheet.error_message = 'Un numéro de lot semble érroné pour un article de type cannabinoids';
                    }
                }

                if (productionSheet.recipe.other_ingredients.length) {
                    formatedProducts = formatIngredientArray(productionSheet.recipe.other_ingredients)
                    if (formatedProducts) {
                        productionSheet.recipe.other_ingredients = formatedProducts
                    } else {
                        productionSheet.success = false;
                        productionSheet.error_message = 'Un numéro de lot semble érroné pour un article de type autres ingrédients';
                    }
                }

            } else {
                productionSheet.recipe = {}
            }
            productionSheet.parameters.marge_to_add = parseFloat(productionSheet.parameters.marge_to_add)

            delete productionSheet.session
            delete productionSheet.article

            return productionSheet

        } catch (err) {
            console.log({ err });
            return {
                success: false,
                error_message: 'Une erreur est survenue lors du formatage de la spécification produit',
            }
        }
    }

    return (
        <ProductionSheetContext.Provider value={{
            emptyProcutionSheet,
            productionSheet,
            updateProductionSheet,
            setProductionSheet,
            updateProductionSheetPropriety,
            addProductionSheet,
            formatIngredientArray,
        }}>
            {props.children}
        </ProductionSheetContext.Provider>
    )
}
