import React from 'react';
// import CartContextProvider from './CartContext';
import OrganizationsContextProvider from 'Context/ERP/OrganizationsContext';
import CatalogueContextProvider from './CatalogueContext';
import CustomProductsContextProvider from './CustomProductsContext';
import LLFCProductsContextProvider from './LlfcProductsContext';
import MslAccessContextProvider, { MslAccessContext } from './MslAccessContext';
import PriceCategoriesContextProvider from './PriceCategoriesContext'
import UsersContextProvider from './Users';
import DealContextProvider from 'Context/Commercial/DealContext';
import ProductSpecificationContextProvider from 'Context/ERP/ProductSpecificationContext';
import ArticleContextProvider from 'Context/ERP/ArticleContext';
import PriceMatrixContextProvider from './PriceMatrixContext';

export default function CommercialContextContainer(props) {
    return (
        <DealContextProvider>
            <OrganizationsContextProvider>
                <MslAccessContextProvider >
                    <CatalogueContextProvider>
                        <CustomProductsContextProvider>
                            <PriceCategoriesContextProvider>
                                <LLFCProductsContextProvider>
                                    <PriceMatrixContextProvider>
                                        <ArticleContextProvider>
                                            <ProductSpecificationContextProvider>
                                                <UsersContextProvider>
                                                    {/* <CartContextProvider> */}
                                                    {props.children}
                                                    {/* </CartContextProvider> */}
                                                </UsersContextProvider>
                                            </ProductSpecificationContextProvider>
                                        </ArticleContextProvider>
                                    </PriceMatrixContextProvider>
                                </LLFCProductsContextProvider>
                            </PriceCategoriesContextProvider>
                        </CustomProductsContextProvider>
                    </CatalogueContextProvider>
                </MslAccessContextProvider>
            </OrganizationsContextProvider>
        </DealContextProvider>
    );
}

