import React, { useContext, useEffect, useState } from 'react'
import { useCallCBDPApi, useCurrentAppPermission } from 'Functions'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import Input from 'Components/FormElement/Input'
import MidLoader from 'Components/MidLoader/MidLoader'
import AddAnalyses from 'Components/_ANALYSE/Analyse/AddAnalyses'

export default function Bulk() {

    const currentAppPermission = useCurrentAppPermission();
    const callCBDPApi = useCallCBDPApi()
    const { addPopupContent } = useContext(PopupContext)
    const [bulks, setBulks] = useState([])
    const [searchBulkId, setSearchBulkId] = useState('')
    const [showAllBulks, setShowAllBulks] = useState(false)

    useEffect(() => {
        setBulks(null)
        const controller = new AbortController()
        callCBDPApi(`bulk`, { method: "get", signal: controller.signal }, {
            no_analyses: !showAllBulks ? 1 : 0,
            bulk_id: searchBulkId,
        })
            .then((res) => {
                setBulks(res.data)
            })
        return () => {
            controller.abort()
        }
    }, [showAllBulks, searchBulkId])

    const setterBulkFunc = (analyse) => {
        setBulks(psBulks => {
            psBulks.map(bulk => bulk.ID === analyse.bulk_id ? { ...bulk, analyses: [...bulk.analyses, analyse.ID] } : bulk)
        })
    }

    return (
        <div>
            <Input
                label={'N° de bulk'}
                value={searchBulkId}
                onInput={setSearchBulkId}
                style={{ width: '300px' }}
            />
            <div className='flex-center m-t-25 m-b-25'>
                <div className='input'>
                    <label className='flex flex-center'>
                        <input
                            className='erp-check m-r-10'
                            type="checkbox"
                            checked={showAllBulks}
                            onChange={(e) => setShowAllBulks(e.target.checked)}
                        />
                        Afficher tous les Bulks
                    </label>
                </div>
            </div>
            {bulks
                ? <table className='beautiful-array'>
                    <thead>
                        <tr>
                            {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                <th></th>
                            }
                            <th>N° Bulk</th>
                            <th>Date de création</th>
                            <th>DDM</th>
                            <th>Nombre d'analyses</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bulks && bulks
                            .filter(bulk => showAllBulks ? true : bulk.analyses.length === 0)
                            .map((bulk, index) => {
                                return (
                                    <tr key={index} className={bulk.analyses.length > 0 ? '' : 'not-analized'}>
                                        {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                            <td><button className='light-button' onClick={() => addPopupContent(<AddAnalyses origin={{ bulk_id: bulk.ID }} setter={setterBulkFunc} />, "1220px")}>Analyser</button></td>
                                        }
                                        <td>{bulk.ID}</td>
                                        <td>{new Date(bulk.created_date).toLocaleDateString()}</td>
                                        <td>{bulk.ddm}</td>
                                        <td>{bulk.analyses && bulk.analyses.length}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                : <MidLoader />}
        </div>
    )
}
