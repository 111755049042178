import React, { useContext, useEffect, useState } from 'react'
import { useCallAnalysesApi, useCurrentAppPermission } from 'Functions'
import { appConstante } from 'appConstante'
import { AnalyseAccessContext } from 'Context/Analyse/AnalyseAccessContext'
import { PopupContext } from 'Context/PopupContext'
import { NotificationContext } from 'Context/NotificationContext'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import Pagination from 'Components/Pagination/Pagination'
import MidLoader from 'Components/MidLoader/MidLoader'
import Input from 'Components/FormElement/Input'
import DeleteAnalyseProduct from 'Components/_ANALYSE/Product/DeleteAnalyseProduct'
import AddAnalyses from 'Components/_ANALYSE/Analyse/AddAnalyses'
import AddProduct from 'Components/_ANALYSE/Product/AddProduct'
import UpdateProduct from 'Components/_ANALYSE/Product/UpdateProduct'
import DatalistOrders from 'Components/_ANALYSE/Product/DatalistOrders'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import { productNameFromId } from '../analysesConstantes'
import AccordionTable from 'Components/AccordionTable/AccordionTable'

export default function ProductReception() {

    const currentAppPermission = useCurrentAppPermission();
    const callAnalysesApi = useCallAnalysesApi()
    const { analyseNonce } = useContext(AnalyseAccessContext)
    const { addPopupContent } = useContext(PopupContext)
    const { addNotificationContent } = useContext(NotificationContext)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const [products, setProducts] = useState(null)
    const [analized, setAnalized] = useState(false)
    const [selectedNumRefId, setSelectedNumRefId] = useState('')
    const [selectedOrderId, setSelectedOrderId] = useState('')

    // GET THE ANALYSE PRODUCTS //
    useEffect(() => {
        if (analyseNonce) {
            setProducts(null)
            const controller = new AbortController()
            callAnalysesApi(`products`, { method: "get", signal: controller.signal }, {
                page: currentPage,
                num_reference: selectedNumRefId,
                analyzed: analized,
                id_commande: selectedOrderId
            })
                .then((res) => {
                    setProducts(Array.isArray(res.data.data) ? res.data.data : [res.data.data])
                    setTotalPage(res.data.total_pages)
                    res.data.total_pages === 0 && setCurrentPage(1)
                    currentPage > res.data.total_pages && res.data.total_pages > 0 && setCurrentPage(res.data.total_pages)
                })
            return () => {
                controller.abort()
            }
        }
    }, [analyseNonce, currentPage, selectedNumRefId, selectedOrderId, analized])

    const setProductsFunc = (productToRemove) => {
        setProducts(prevProductsState => {
            const newProductsState = prevProductsState.filter(product => product.num_reference !== productToRemove.num_reference)
            return newProductsState
        })
    }

    const deleteAnalyseProduct = (numRef) => {
        setProducts(products.filter(product => product.num_reference !== numRef))
    }

    const showConfirmRemoveAnalyseProduct = product => {
        addPopupContent(<DeleteAnalyseProduct product={product} deleteAnalyseProduct={deleteAnalyseProduct} />)
    }

    const notificationReception = () => {
        addNotificationContent({
            content: <MiniLoader />,
            infinit: true
        })
        callAnalysesApi('notification-reception', { method: 'post' })
            .then(res => {
                addNotificationContent({
                    content: 'Vos mails ont bien été envoyé'
                })
                setProducts(prvProducts => prvProducts.map(product => ({ ...product, mail: 1 })))
            })
            .catch(_ => addNotificationContent({
                error: true,
                content: 'Une erreur est survenu, vos mails n\'ont pas été envoyés',
            }))
    }

    return (
        <div>
            {currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                <div className='flex gap-1'>
                    <button className='strong-button m-l-0' onClick={() => addPopupContent(<AddProduct setter={setProducts} />, "1220px")}>Réception d'un produit</button>
                    <button className='light-button' onClick={_ => notificationReception()}>Notifier la réception des produits</button>
                </div>
            }
            <div className='flex-start gap-1 end m-b-25'>
                <div style={{ minWidth: '300px' }} className='m-r-10'>
                    <Input
                        type='number'
                        label='N° de référence'
                        value={selectedNumRefId}
                        onInput={setSelectedNumRefId}
                    />
                </div>
                <div>
                    <DatalistOrders onSelect={setSelectedOrderId} />
                </div>
                <div className='input'>
                    <label className='flex flex-center'>
                        <input
                            className='erp-check m-r-10'
                            type="checkbox"
                            checked={analized}
                            onChange={(e) => setAnalized(e.target.checked)}
                        />
                        Afficher les produits analysés
                    </label>
                </div>
            </div>
            <div>
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPage={totalPage}
                />
            </div>
            {products
                ? products.length > 0
                    ? <AccordionTable
                        columns={[
                            { title: "", colSpan: currentAppPermission('analyses') > appConstante.parameters.CAN_READ ? 5 : 2 },
                            currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                            { title: "Réf. Interne" },
                            { title: "Commande ID" },
                            { title: "Date de réception" },
                            { title: "Demandeur" },
                            currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                            { title: "" },
                        ]}
                        datas={products.map((product, index) => {

                            let sublines = [
                                [
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ ?
                                        { element: (<div></div>), colSpan: 3 } : null,
                                    { element: (<div className='strong-2'>Dénomination</div>), colSpan: 2 },
                                    { element: (<div className='strong-2'>N° lot du client</div>), colSpan: 2 },
                                    { element: (<div className='strong-2'>Type de produit</div>), colSpan: 1 },
                                    { element: (<div className='strong-2'>Analyse demandée</div>), colSpan: 1 },
                                    { element: (<div className='strong-2'>Masse / quantité</div>), colSpan: 1 },
                                ],
                                [
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ ?
                                        { element: (<div></div>), colSpan: 3 } : null,
                                    { element: (<div>{product.denomination}</div>), colSpan: 2 },
                                    { element: (<div>{product.num_lot}</div>), colSpan: 2 },
                                    { element: (<div>{product.type_produit}</div>), colSpan: 1 },
                                    {
                                        element: (<div>
                                            <div>{productNameFromId(product.product_order?.product_id)}</div>
                                            <div className='subtext'>
                                                {product.type_analyse.split('<br/>').map((item, index) => (
                                                    <span key={index}>
                                                        {item} {index < product.type_analyse.split('<br/>').length - 1 && <br />}
                                                    </span>))}
                                            </div>
                                        </div>)
                                    },
                                    { element: (<div>{product.masse}</div>), colSpan: 1 },
                                ],
                            ]

                            product?.results?.length
                                ? product.results.forEach(analyse => {
                                    if (analyse.certificate_urls && analyse.certificate_urls.length) {
                                        sublines.push([{
                                            element: <a
                                                className='strong-2'
                                                target="_blank"
                                                href={analyse.certificate_urls}
                                            >
                                                Afficher le certificat d'analyse
                                            </a>, colSpan: 100
                                        }])
                                    } else {
                                        sublines.push(
                                            [{
                                                element: <div className='flex flex-center'>
                                                    {analyse.certificate_url
                                                        ? <div><a
                                                            className='strong-2'
                                                            target="_blank"
                                                            href={analyse.certificate_url}
                                                        >
                                                            Résultats
                                                        </a></div>
                                                        : null
                                                    }
                                                    {
                                                        Object.entries(analyse.results)
                                                            .map(([key, value], index) => <div key={index}><div className='strong-2'>{key}</div>{value}</div>)
                                                    }</div>,
                                                colSpan: 100
                                            }]
                                        )
                                    }
                                })
                                : sublines.push([{
                                    element: <div className='strong-2'>Il n'y pas de résultats pour ce produit</div>, colSpan: 100
                                }])

                            return {
                                mainLine: [
                                    { element: (<div></div>), colSpan: 2 },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (
                                            <button className='light-button' onClick={() => addPopupContent(<AddProduct product={product} setter={setProducts} />, "800px")}>Dupliquer</button>
                                        )
                                    },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<div>
                                            <button className='light-button' onClick={() => addPopupContent(<AddAnalyses origin={{ num_reference: product.num_reference }} product={product} setter={setProductsFunc} />, "1220px")}>Analyser</button>
                                        </div>)
                                    },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<div>
                                            <button className='light-button' onClick={() => addPopupContent(<UpdateProduct product={product} setter={setProducts} />, "800px")} >Modifier</button>
                                        </div>)
                                    },
                                    { element: (<div>{product.num_reference}</div>) },
                                    { element: (<div>{product.id_commande}</div>) },
                                    { element: (<div>{new Date(product.date_reception).toLocaleDateString('fr-FR')}</div>) },
                                    { element: (<div>{product.demandeur}</div>) },
                                    currentAppPermission('analyses') > appConstante.parameters.CAN_READ &&
                                    {
                                        element: (<DeleteButton
                                            callBack={showConfirmRemoveAnalyseProduct}
                                            parameters={[product]}
                                            alwaysDisplay={true}
                                        />)
                                    },
                                ],
                                mainLineStyle: {
                                    fontWeight: product.mail ? '500' : '600',
                                    color: product.mail ? '#000000' : 'rgba(220, 120, 120)',
                                    backgroundColor: product.time_state?.value === 0 || product.time_state?.value === 3
                                        ? 'none'
                                        : product.time_state?.value === 1
                                            ? 'rgba(252, 221, 154, 0.git 5)'
                                            : 'rgba(250, 172, 172, 0.5)'
                                },
                                subLines: sublines
                            }
                        })}
                    />
                    : <h5>Aucunes analyses correspondantes à votre recherche</h5>
                : <MidLoader />}
        </div>
    )
}
