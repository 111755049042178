import Input from 'Components/FormElement/Input'
import TextEditor from 'Components/GENERAL/Form/TextEditor/TextEditor'
import { DealContext } from 'Context/Commercial/DealContext'
import { appConstante } from 'appConstante'
import React, { useContext, useState } from 'react'

export default function SendDealMail({ deal }) {

    const { sendDeal } = useContext(DealContext)

    const [subject, setSubject] = useState(`[LLFC] Consulter votre devis n°${deal["id"]}`)

    const [mails, setMails] = useState([deal.person_id?.email[0]?.value])

    const [beforeLinkContent, setBeforeLinkContent] = useState(`Pour donner suite à votre demande de devis, nous vous prions de trouver ci-joint notre proposition commerciale effectuée après examen de votre demande.
    <br/><br/>Nous attirons votre attention sur le fait que ce devis est valable jusqu’au ${new Date(deal[appConstante.pipedrive.EXPIRY_DATE]).toLocaleDateString('fr-FR')}.`)

    const [afterLinkContent, setAfterLinkContent] = useState(`Nous nous tenons à votre disposition pour toute interrogation ou demande d’informations complémentaires. 
    <br/><br/>Nous vous souhaitons une bonne réception, et vous prions de croire en l’assurance de nos salutations distinguées.`)

    const setMail = (index, newMail) => {
        setMails(prvMails => prvMails.map((mail, idx) => idx === index ? newMail : mail))
    }

    const deleteMail = (index) => {
        setMails(prvMails => prvMails.filter((_, idx) => idx !== index))
    }

    const addMail = () => {
        setMails(prvMails => [...prvMails, ""])
    }

    const sendMail = (e) => {
        e.preventDefault()
        sendDeal(deal, {
            subject: subject,
            beforeLinkContent: beforeLinkContent,
            afterLinkContent: afterLinkContent,
        }, mails)
    }

    return (
        <form onSubmit={e => sendMail(e)}>
            <h2>Envoyer le devis</h2>
            <div className='full-width m-b-25'>
                <Input
                    label='Sujet du mail'
                    required={true}
                    value={subject}
                    onInput={SendDealMail}
                    style={{ with: '100%' }}
                />
            </div>
            <div className='m-b-25'>
                <div className='strong-2'>Texte avant le lien d'accès au devis</div>
                <TextEditor
                    value={beforeLinkContent}
                    setter={setBeforeLinkContent}
                />
            </div>
            <div className='m-b-10'>
                <div className='strong-2'>Texte après le lien d'accès au devis</div>
                <TextEditor
                    value={afterLinkContent}
                    setter={setAfterLinkContent}
                />
            </div>

            <div className='full-width m-b-25 flex column gap-1'>
                {mails.map((mail, index) =>
                    <div key={`${index}`} className='flex-start flex-baseline gap-1'>
                        <Input
                            label='Mail'
                            required={true}
                            value={mail}
                            onInput={setMail}
                            customParameters={[index]}
                            style={{ with: '100%' }}
                        />
                        {index !== 0 ? <button type='button' onClick={_ => deleteMail(index)}>retirer</button> : null}
                    </div>)}
                <button onClick={_ => addMail()} type='button' className='light-button m-t-10'>Ajouter un destinataire</button>
            </div>

            <button className='strong-button'>Envoyer le devis</button>
        </form >
    )
}
