import React, { createContext, useState, useEffect, useContext } from "react"
import { NotificationContext } from "Context/NotificationContext"
import { MslAccessContext } from "./MslAccessContext"
import { useCallMSLApi } from "Functions"

export const UsersContext = createContext()

function UsersContextProvider(props) {
    const [users, setUsers] = useState(null)

    const { mslNonce } = useContext(MslAccessContext)

    const callMSLApi = useCallMSLApi()

    const { addNotificationContent } = useContext(NotificationContext)
    useEffect(() => {
        const controller = new AbortController()
        if (mslNonce) {
            setUsers(null)
            callMSLApi(`users`, { method: "get", signal: controller.signal })
                .then(res => {
                    setUsers(res.data.data)
                })
                .catch(err => {
                    console.log(err);
                    addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: err?.response?.data?.message ?? "Une erreur est survenue lors de la récupération des comptes clients",
                    })
                })
        }

        return () => {
            controller.abort()
        }
    }, [mslNonce])


    return (
        <UsersContext.Provider value={{ users, setUsers }}>
            {props.children}
        </UsersContext.Provider>
    )
}

export default UsersContextProvider
