import React, { useEffect, useState } from 'react'

export default function MetaField({ WCProduct, setter }) {

    const [metaKeys, setMetaKeys] = useState([])

    useEffect(() => {
        if (WCProduct?.attributes) {
            const metaKeysArray = WCProduct.attributes.map(attribute =>
            ({
                ...attribute,
                value: attribute.is_variation ? '' : []
            }))
            setMetaKeys(metaKeysArray)
        }
    }, [WCProduct?.attributes])

    const updateMetaKeys = (metaKey, metaValue) => {
        setMetaKeys(pvs => pvs.map(attribute => {
            if (attribute.name === metaKey.name) {
                const updatedValue = metaKey.is_variation
                    ? metaValue
                    : attribute.value.includes(metaValue)
                        ? attribute.value.filter(value => value !== metaValue)
                        : [...attribute.value, metaValue]
                return { ...attribute, value: updatedValue }
            }
            return attribute
        }))
    }

    useEffect(() => {
        // console.log(metaKeys);
        setter('meta', metaKeys)
    }, [metaKeys])

    return (
        <>
            {
                metaKeys && metaKeys.length
                    ? metaKeys.map((metaKey, index) =>
                        <div key={index} className='m-t-10 m-b-10'>
                            <label className='strong-2 m-b-10'>{metaKey.name}</label>
                            <div className='flex-start gap-1'>
                                {metaKey.options.map((option, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className={Array.isArray(metaKey.value) && metaKey.value.includes(option) || metaKey.value === option
                                                ? 'meta-option meta-active'
                                                : 'meta-option'}
                                            type='button'
                                            // disabled={ }
                                            onClick={() => updateMetaKeys(metaKey, option)}
                                        >
                                            {option}
                                        </button>
                                    )
                                }
                                )}
                            </div>
                        </div >
                    )
                    : null
            }
        </>
    )
}
