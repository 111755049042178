import axios from 'axios'
import React, { createContext, useState, useEffect, useContext } from 'react'
import { appConstante } from 'appConstante'
import { NotificationContext } from 'Context/NotificationContext'
import { UserInfosContexte } from 'Context/UserInfosContexte'

export const AnalyseAccessContext = createContext()

function AnalyseAccessContextProvider(props) {

    const [analyseNonce, setAnalyseNonce] = useState(null)
    const { getToken } = useContext(UserInfosContexte)

    const callAnalyseNonce = (signal = null) => {
        return new Promise((resolve, reject) => {
            getToken()
                .then(token => {
                    axios.get(
                        `${appConstante.servers.ANALYSE_ENDPOINT}/nonce?token=${token}`,
                        { signal: signal }
                    )
                        .then(res => resolve(res.data.data))
                        .catch(err => reject(err))
                })
        })
    }

    const getAnalyseNonce = async () => {
        return new Promise((resolve, reject) => {
            if (analyseNonce) {
                resolve(analyseNonce)
            }
            else {
                callAnalyseNonce()
                    .then(res => resolve(res))
            }
        })
    }

    const { addNotificationContent } = useContext(NotificationContext)
    useEffect(() => {
        const controller = new AbortController()

        callAnalyseNonce(controller.signal)
            .then(res => {
                setAnalyseNonce(res)
            })
            .catch(err => {
                err.response
                    ? addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: err.response.data.message,
                    })
                    : addNotificationContent({
                        title: "Erreur",
                        error: true,
                        content: "Une erreur est survenue lors de la récupération de vos accès à l'espace pro",
                    })
            })

        return () => {
            controller.abort()
        }
    }, [])

    return (
        <AnalyseAccessContext.Provider value={{ analyseNonce, getAnalyseNonce }}>
            {props.children}
        </AnalyseAccessContext.Provider>
    )
}


export default AnalyseAccessContextProvider