import React, { useContext, useEffect, useState } from 'react'
import { useCallAnalysesApi } from 'Functions'
import { NotificationContext } from 'Context/NotificationContext'
import MidLoader from 'Components/MidLoader/MidLoader'
import MiniLoader from 'Components/MiniLoader/MiniLoader'
import Input from 'Components/FormElement/Input'
import OrdersTable from './OrdersTable'
import DatalistOrders from 'Components/_ANALYSE/Product/DatalistOrders'

export default function MailResults() {

  const callAnalysesApi = useCallAnalysesApi()
  const { addNotificationContent } = useContext(NotificationContext)

  const [orders, setOrders] = useState()
  const [ordersToShow, setOrdersToShow] = useState(orders)

  const [selectedOrderId, setSelectedOrderId] = useState('')
  const [searchedOrders, setSearchedOrders] = useState()

  const [showArchived, setShowArchived] = useState(false)

  // GET ORDERS //
  useEffect(() => {
    const controller = new AbortController()
    if (selectedOrderId) {
      setSearchedOrders(null)
    }
    callAnalysesApi(`results-to-send`, { method: 'get', signal: controller.signal }, { order_id: selectedOrderId ?? null })
      .then(res => {
        if (selectedOrderId) {
          setSearchedOrders(res.data.data)
        } else {
          setOrders(res.data.data)
        }
      })
    return () => {
      controller.abort()
    }
  }, [selectedOrderId])

  // ADD ORDERS THAT HAVE AT LEAST 1 PRODUCT WITH STATE AT 1 OR 3
  useEffect(() => {
    if (orders) {
      setOrdersToShow(orders.filter(order => order.products.filter(product => product.etat === 1 || product.etat === 3).length))
    }
  }, [orders])

  useEffect(() => {
    if (orders) {
      if (showArchived) {
        setOrdersToShow(orders)
      } else {
        setOrdersToShow(orders.filter(order => order.order.archive !== 1))
      }
    }
  }, [showArchived, orders])

  // POST OF RESULTS //
  const sendOrderMails = (orderId) => {
    const controller = new AbortController()
    addNotificationContent({
      error: false,
      title: 'Envoi des résultats en cours',
      content: <MiniLoader />,
      infinit: true,
    })
    callAnalysesApi(`send-mails`, { method: 'post', signal: controller.signal }, { order_id: orderId })
      .then(res => {
        addNotificationContent({
          error: false,
          title: res.data.message ?? 'Les résultats ont été envoyés'
        })
        setOrders(previousOrders => {
          const newOrders = previousOrders.filter(order => order.order.ID != orderId)
          return newOrders
        })
      })
      .catch(error => addNotificationContent({
        error: true,
        title: "Erreur lors de l'envoi",
        content: error.response.data.message ?? "Une erreur a eu lieu lors de l'envoi",
        inifinit: false,
      }))
    return () => {
      controller.abort()
    }
  }

  const sendNumRefMails = (numRefArray) => {
    const controller = new AbortController()
    if (numRefArray.length) {
      addNotificationContent({
        error: false,
        title: 'Envoi des résultats en cours',
        content: <MiniLoader />,
        infinit: true,
      })
      callAnalysesApi(`send-mails`, { method: 'post', signal: controller.signal }, { num_ref_array: numRefArray })
        .then(res => {
          addNotificationContent({
            error: false,
            title: res.data.message ?? 'Les résultats ont étés envoyés'
          })
          setOrders(previousOrders => {
            const newOrders = previousOrders.map(order => {
              return { ...order, products: order.products.filter(product => !numRefArray.includes(product.num_reference)) }
            })
            return newOrders
          })
        })
        .catch(error => addNotificationContent({
          error: true,
          title: "Erreur lors de l'envoi",
          content: error.response.data.message ?? "Une erreur a eu lieu lors de l'envoi",
          inifinit: false,
        }))
    } else {
      addNotificationContent({
        content: "Vous n'avez pas sélectionné de produit à envoyer",
        error: true
      })
    }
    return () => {
      controller.abort()
    }
  }

  const updateOrders = (id, isArchived) => {
    addNotificationContent({
      title: "Mise à jour de la commande...",
      content: <MiniLoader />,
    })
    callAnalysesApi(`order/${id}`, { method: "PUT" }, {
      archive: isArchived ? 1 : 0,
    })
      .then(res => {
        if (res.data.success) {
          setOrders(orders.map(order => (id === order.order.ID
            ? { ...order, order: { ...order.order, archive: isArchived ? 1 : 0 } }
            : order
          )))
          if (id === selectedOrderId) {
            setSearchedOrders(searchedOrders.map(order => (order.order.ID === id
              ? { ...order, order: { ...order.order, archive: isArchived ? 1 : 0 } }
              : order
            )))
          }
          addNotificationContent({
            title: "Succès",
            content: "La commande a été modifiée",
          })
        }
      })
      .catch(err => {
        addNotificationContent({
          title: "Erreur",
          error: false,
          content: "Une erreur s'est produite",
          infinit: false,
        });
      })
  }

  return (
    <>
      <div className='m-b-25'>
        {orders
          ? orders.length
            ? <>
              <h4>Résultats à envoyer</h4>
              <label className='flex-start center gap-1 m-t-10 m-b-10' >
                <input type='checkbox' className='erp-check' checked={showArchived} onChange={e => setShowArchived(e.target.checked)} />
                <span>Afficher les commandes archivés</span>
              </label>
              {ordersToShow
                ? ordersToShow.length
                  ?
                  <OrdersTable
                    ordersArray={ordersToShow}
                    updateOrders={updateOrders}
                    sendNumRefMails={sendNumRefMails}
                    sendOrderMails={sendOrderMails}
                  />
                  : <h5>Aucuns résultats à envoyer</h5>
                : <MidLoader />
              }

            </>
            : <h5>Aucuns résultats à envoyer</h5>
          : <MidLoader />
        }
      </div>

      <div className='p-t-25'>
        <h4 className='m-t-25'>Rechercher une commande</h4>
        <div className='m-b-25'>
          <DatalistOrders
            placeholder={'N° de commande'}
            onSelect={setSelectedOrderId}
            style={{ width: '300px' }}
          />
        </div>
        {selectedOrderId
          ? searchedOrders
            ? searchedOrders.length
              ? <OrdersTable
                ordersArray={searchedOrders}
                updateOrders={updateOrders}
                sendNumRefMails={sendNumRefMails}
                sendOrderMails={sendOrderMails}
              />
              : <h5>Aucuns résultats à envoyer</h5>
            : <MidLoader />
          : null}
      </div>

    </>
  )
}
