import React, { useState } from 'react';
import './SubNavbar.css';
import { NavLink, Outlet } from 'react-router-dom';

/**
 * 
 * @param {Array <object>} menu tableau d'objet au format [{path: 'menuElementPath', name: 'menuElementName'}, ...] 
 * @returns 
 */
export default function SubNavBar({ menu }) {
    const [open, toggleOpen] = useState(true);

    return (
        <div className='flex gap-1 m-t-25'>
            <div className={open ? "sub-navigation open" : "sub-navigation"}>
                {menu.map(menuElement => <NavLink key={menuElement.path} to={menuElement.path} className="sub-navigation-button" >{menuElement.name}</NavLink>)}
                <div
                    className='sub-navigation-toggle'
                    onClick={() => toggleOpen(!open)}
                >{open ? '❮' : '❯'}
                </div>
            </div>
            <div className="outlet-content">
                <Outlet />
            </div>
        </div>
    );
}
