import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCurrentAppPermission } from 'Functions'
import { appConstante } from 'appConstante'
import { PopupContext } from 'Context/PopupContext'
import { BatchContext } from 'Context/CBDProtect/Batch'
import { Link } from 'react-router-dom'
import Input from 'Components/FormElement/Input'
import MidLoader from 'Components/MidLoader/MidLoader'
import CreateBatchBtn from 'Components/_CBDP/Batch/CreateBatchBtn'
import Pagination from 'Components/Pagination/Pagination'
import UpdateBatch from 'Components/_CBDP/Batch/UpdateBatch'
import { ArticleContext } from 'Context/ERP/ArticleContext'

export default function Batchs() {

    const currentAppPermission = useCurrentAppPermission()
    const { getBatchsV2, getBatchVisibleID } = useContext(BatchContext)
    const { addPopupContent } = useContext(PopupContext)
    const { getArticle } = useContext(ArticleContext)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const [searchBatchId, setSearchBatchId] = useState('')
    const [batchs, setBatchs] = useState(null)
    const setTimeoutId = useRef()

    useEffect(() => {
        setBatchs(null)
        const controller = new AbortController()

        clearTimeout(setTimeoutId.current)
        setTimeoutId.current = setTimeout(async () => {

            if (searchBatchId) {
                getBatchVisibleID(controller.signal, { visible_id: searchBatchId })
                    .then(async batch => {
                        setBatchs(Array.isArray(batch) ? batch : [batch])
                        setTotalPage(1)
                        setCurrentPage(1)
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                            setBatchs([])
                            console.log(error)
                        }
                    })
            } else {
                getBatchsV2(currentPage, controller)
                    .then(res => {
                        setBatchs(res.data)
                        setTotalPage(res.total_pages)
                        res.total_pages === 0 && setCurrentPage(1)
                        currentPage > res.total_pages && res.total_pages > 0 && setCurrentPage(res.total_pages)
                    })
            }

        }, searchBatchId ? 500 : 0)

        return () => {
            controller.abort()
        }
    }, [currentPage, searchBatchId])

    const addCreatedBatch = async (newBatch) => {
        const batchArticle = await getArticle(newBatch.article_id)
        const formatedNewBatch = { ...newBatch, article: batchArticle }

        setBatchs(prvBatchs => [formatedNewBatch, ...prvBatchs])
    }

    return (
        <div>
            <CreateBatchBtn setter={addCreatedBatch} />
            <div className='m-b-10'>
                <Input
                    label={'N° de lot'}
                    value={searchBatchId}
                    onInput={setSearchBatchId}
                    style={{ width: '300px' }}
                />
            </div>
            <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPage={totalPage}
            />
            {
                batchs
                    ? batchs.length
                        ? <table className='beautiful-array'>
                            <thead>
                                <tr>
                                    {currentAppPermission('cbd-protect') >= appConstante.parameters.CAN_EDIT &&
                                        <th></th>
                                    }
                                    <th></th>
                                    <th></th>
                                    <th>Numéro de lot</th>
                                    <th>N° Bulk</th>
                                    <th>Nom du produit</th>
                                    <th>Quantité cible</th>
                                </tr>
                            </thead>
                            <tbody>
                                {batchs
                                    .map((batch, index) => {
                                        return (
                                            <tr key={index}>
                                                {currentAppPermission('cbd-protect') >= appConstante.parameters.CAN_EDIT &&
                                                    <td>
                                                        <button
                                                            className='light-button'
                                                            onClick={() => addPopupContent(<UpdateBatch batch={batch} setter={setBatchs} />, '800px')}
                                                        >
                                                            Modifier
                                                        </button>
                                                    </td>
                                                }
                                                <td>
                                                    <Link to={batch.certificat_url} target='blank'>Résultats PDF</Link>
                                                </td>
                                                <td>
                                                    <Link to={batch.result_url} target='blank'>Voir</Link>
                                                </td>
                                                <td>{batch.visible_id}</td>
                                                <td>{batch.bulk_id}</td>
                                                <td>{batch.article.name}</td>
                                                <td>{batch.produced_quantity}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        : <h5>Aucun batch {`${searchBatchId ? 'correspondant à votre recherche' : 'à afficher'}`}</h5>
                    : <MidLoader />
}
        </div >
    )
}
