import React, { useContext, useEffect, useState } from 'react'
import { getStockUnitOfMeasure } from 'appConstante'
import Select from 'Components/FormElement/Select'
import Input from 'Components/FormElement/Input'
import { NotificationContext } from 'Context/NotificationContext'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import Datalist from 'Components/Dataliste/Dataliste'
import MidLoader from 'Components/MidLoader/MidLoader'

export default function HistoryFields({ history, setter }) {

    const { articles, getArticle } = useContext(ArticleContext)

    const { addNotificationContent } = useContext(NotificationContext)
    const [avaibleBatchs, setAvaibleBatchs] = useState([])
    const [avaibleStock, setAvaibleStock] = useState()
    const [formatedHistory, setFormatedHistory] = useState(history)

    useEffect(() => {
        setter(formatedHistory)
    }, [formatedHistory])

    const updateHistoryKey = (key, value) => {
        setFormatedHistory(prvHistory => ({ ...prvHistory, [key]: value }))
        // setter(prvHistory => ({ ...prvHistory, [key]: value }))
    }

    const getComment = () => {
        if (avaibleBatchs && avaibleBatchs.length > 0) {
            const currentDate = new Date()
            const currentBatch = avaibleBatchs.find(b => formatedHistory.batch_id === b.id)
            const isOlderThanCurrentDate = currentBatch && currentBatch.ddm < currentDate
            return isOlderThanCurrentDate ? 'déstockage : DDM' : 'déstockage : perte'
        } else {
            return ""
        }
    }

    useEffect(() => {
        if (history.article_id) {
            getArticle(history.article_id)
                .then(article => {
                    setFormatedHistory(prvHistory => ({ ...prvHistory, 'article': article }))
                })
        }
    }, [history.article_id])

    useEffect(() => {
        if (avaibleBatchs.length === 1 && formatedHistory.history_type === "remove") {
            updateHistoryKey("batch_id", avaibleBatchs[0].id)
        }
    }, [avaibleBatchs, formatedHistory.history_type])

    useEffect(() => {
        if (formatedHistory.destocking) {
            updateHistoryKey("exclude_from_stats", 1)
            if (!formatedHistory.comment) {
                updateHistoryKey("comment", getComment())
            }
            if (formatedHistory.batch_id && formatedHistory.history_type === 'remove') {
                const currentBatch = avaibleBatchs.find(b => Number(formatedHistory.batch_id) === b.id)
                currentBatch && updateHistoryKey('quantity', currentBatch.quantity ? currentBatch.quantity : 0 + formatedHistory.prvQuantity)
            }
        } else {
            if (formatedHistory.comment === getComment()) {
                updateHistoryKey("comment", ``)
            }
        }
    }, [formatedHistory.destocking, formatedHistory.batch_id, formatedHistory.history_type])

    useEffect(() => {
        if (formatedHistory.quantity) {
            if (formatedHistory.quantity < 0) {
                updateHistoryKey("quantity", formatedHistory.quantity * -1)
            }
            if (formatedHistory.history_type === 'remove' && (parseFloat(formatedHistory.quantity) - parseFloat(formatedHistory.prvQuantity)) > avaibleStock) {
                //Quantité disponible + quantité total pour ne notifier que en cas
                //D'excédent de retrait de stock lors de la modification de l'historique
                addNotificationContent({
                    error: true,
                    title: 'Attention !',
                    content: 'La quantité du stock à retirer est supérieur à celle disponible',
                })
            } else {
                addNotificationContent({})
            }
        }
    }, [formatedHistory.quantity, formatedHistory.history_type, avaibleStock])

    useEffect(() => {
        if (formatedHistory.history_type === 'remove' && formatedHistory.batch_id && avaibleBatchs) {
            const currentBatch = avaibleBatchs.find(batch => batch.id === Number(formatedHistory.batch_id))
            currentBatch && setAvaibleStock(parseFloat(currentBatch.quantity))
        }
    }, [formatedHistory.batch_id, formatedHistory.history_type, avaibleBatchs])

    useEffect(() => {
        if (formatedHistory.article && formatedHistory.product_id) {
            const selectedProduct = formatedHistory.article?.products?.find(product => product.id === history.product_id)
            selectedProduct && setAvaibleBatchs(selectedProduct.history)
        }
    }, [formatedHistory, formatedHistory.article])

    return (
        <>
            {avaibleBatchs && articles
                ? <>
                    <div className='center m-t-25' style={{ maxWidth: '400px', margin: 'auto' }}>
                        <Select
                            value={formatedHistory.history_type}
                            onChange={updateHistoryKey}
                            customParameters={['history_type']}
                            label="Nature de l'historique"
                        >
                            <option value='remove'>Retrait</option>
                            <option value='add'>Ajout</option>
                        </Select>
                    </div>
                    <div className='flex'>
                        <div className='half-m-width'>
                            <Datalist
                                placeholder="Sélectionner l'article"
                                items={articles.map(article => ({ id: article.id, value: article.name }))}
                                onSelect={updateHistoryKey}
                                customParameters={['article_id']}
                                defaultId={formatedHistory.article_id}
                            />
                            {formatedHistory?.article?.products
                                ? <Datalist
                                    placeholder="Sélectionner un fournisseur"
                                    items={formatedHistory.article.products.map(product => ({ id: product.id, value: product.supplier }))}
                                    onSelect={updateHistoryKey}
                                    customParameters={['product_id']}
                                    defaultId={formatedHistory.product_id
                                        ? formatedHistory.product_id
                                        : formatedHistory.article.products.length === 1
                                            ? formatedHistory.article.products[0].id : ''
                                    }
                                />
                                : null}
                            {formatedHistory.history_type === 'add'
                                ? <>
                                    <Select
                                        value={formatedHistory.batch_id}
                                        onChange={updateHistoryKey}
                                        customParameters={['batch_id']} label='Numéro de lot'
                                    >
                                        <option value=''>Créer un nouveau lot</option>
                                        {
                                            avaibleBatchs
                                                .map(batch =>
                                                    <option key={batch.id} value={batch.id}>{batch.batch_number} (disponible: {batch.quantity})</option>
                                                )
                                        }
                                    </Select>
                                    {!formatedHistory.batch_id &&
                                        <Input value={formatedHistory.batch_number ?? ''}
                                            onInput={updateHistoryKey}
                                            customParameters={['batch_number']}
                                            label='Nouveau numéro de lot'
                                            required
                                        />}
                                    <Input type='number' min='0' step='0.0001' value={formatedHistory.quantity ?? ''}
                                        onInput={updateHistoryKey} customParameters={['quantity']}
                                        label={`Quantité à ajouter (${getStockUnitOfMeasure(formatedHistory.article)})`} required={true}
                                    />

                                    <Input type='date' value={formatedHistory.expiration_date ? new Date(formatedHistory.expiration_date).toISOString().slice(0, 10) : ""}
                                        onInput={updateHistoryKey} required={false}
                                        label='DDM' customParameters={['expiration_date']}
                                    />
                                    <Input type='number' min='0' step='0.0001' value={formatedHistory.price ?? ""}
                                        onInput={updateHistoryKey} label='Prix total (€)' customParameters={["price"]} required={false}
                                    />
                                </>
                                : <>
                                    <Select value={formatedHistory.batch_id} onChange={updateHistoryKey}
                                        label='Numéro de lot' customParameters={["batch_id"]} required={true}>
                                        {avaibleBatchs.length > 1
                                            ? <>
                                                <option value="">-- Sélectionner un numéro de lot --</option>
                                                {avaibleBatchs
                                                    .filter(batch =>
                                                        formatedHistory.history_type === 'remove'
                                                            ? batch.quantity + formatedHistory.prvQuantity > 0
                                                            : true)
                                                    .map(batch => <option key={batch.id} value={batch.id}>{batch.batch_number} (disponible: {batch.quantity})</option>
                                                    )}
                                            </>
                                            : avaibleBatchs.map(batch => {
                                                return (
                                                    <option
                                                        key={batch.id}
                                                        value={batch.id}
                                                    >
                                                        {batch.batch_number} (disponible: {batch.quantity})
                                                    </option>
                                                )
                                            })}
                                    </Select>
                                    <Input
                                        type='number'
                                        min='0'
                                        step='0.000001'
                                        value={formatedHistory.quantity}
                                        onInput={updateHistoryKey}
                                        label={`Quantité à retirer (${getStockUnitOfMeasure(formatedHistory.article)})`}
                                        customParameters={['quantity']}
                                        required={true}
                                    />
                                    <div className='input m-t-25'>
                                        <label className='flex flex-center'>
                                            <input type="checkbox" className='erp-check' checked={formatedHistory.destocking} onChange={e => updateHistoryKey("destocking", e.target.checked)} />
                                            Déstockage
                                        </label>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='half-m-width'>
                            <Input type='date' value={formatedHistory.date}
                                onInput={updateHistoryKey}
                                label='Date' customParameters={['date']}
                                required={true}
                            />
                            <Select value={formatedHistory.type} onChange={updateHistoryKey}
                                customParameters={['type']} label='Type'>
                                <option value='Manuel'>Manuel</option>
                                <option value='Automatique'>Automatique (depuis les recettes)</option>
                            </Select>
                            <Input
                                value={formatedHistory.comment ? formatedHistory.comment : ''}
                                onInput={updateHistoryKey}
                                label='Commentaire'
                                customParameters={['comment']}
                            />
                            <label className='flex m-t-25' style={{ cursor: 'pointer' }}>
                                <div>Ne pas comptabiliser dans les statistiques</div>
                                <input type='checkbox'
                                    className='erp-check'
                                    checked={formatedHistory.exclude_from_stats === 1}
                                    onChange={e => updateHistoryKey("exclude_from_stats", e.target.checked ? 1 : 0)}
                                />
                            </label>
                            <div className='m-b-25 subtext'>Considérer comme perte</div>
                            <label className='flex m-b-25 m-t-25' style={{ cursor: 'pointer' }}>
                                <div style={{ color: 'red' }}>Archiver</div>
                                <input type='checkbox'
                                    className='erp-check'
                                    checked={formatedHistory.archive === 1}
                                    onChange={e => updateHistoryKey("archive", e.target.checked ? 1 : 0)}
                                />
                            </label>
                        </div>
                    </div>
                </>
                : <MidLoader />}
        </>
    )
}
