import React, { useContext, useEffect } from 'react'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import Datalist from 'Components/Dataliste/Dataliste'
import MiniLoader from 'Components/MiniLoader/MiniLoader'

export default function SelectArticles({
    refresh = true,
    category = false,
    placeholder = 'Sélectionner un article',
    width = '300px',
    required = true,
    onSelect,
    customParameters,
    style,
    value = '',
    disabled = false,
    brandId = null,
}) {

    //states
    const { articles, getArticles, formatArticleName } = useContext(ArticleContext)

    /******************************************************/

    return (
        <div className='flex-start gap-1'>
            {
                articles
                    ? <>
                        <Datalist
                            placeholder={placeholder}
                            items={articles
                                .filter(article => !article.archive)
                                .filter(article => brandId ? article.brand_id === brandId : true)
                                .filter(article => category ? article.category === category : true)
                                .map(article => ({ id: article.id, value: formatArticleName(article) }))
                            }
                            onSelect={onSelect}
                            customParameters={customParameters}
                            width={width}
                            required={required}
                            style={style}
                            value={value}
                            disabled={articles.length ? false : true}
                        />
                        {
                            refresh
                                ? <button
                                    className='light-button m-t-25'
                                    type='button'
                                    onClick={_ => getArticles()}
                                    disabled={disabled}
                                    style={{ maxHeight: "45px" }}
                                >
                                    actualiser
                                </button >
                                : null
                        }
                    </>
                    : <MiniLoader />
            }
        </div>
    )
}
