import React, { useContext, useState, useEffect } from 'react'
import { PopupContext } from 'Context/PopupContext'
import AddArticle from 'AppContainer/ERP/Stocks/ImportsAndExport/Article/AddArticle'
import ArticleUpdate from 'AppContainer/ERP/Stocks/ImportsAndExport/Article/ArticleUpdate'
import DeleteButton from 'Components/DeleteButton/DeleteButton'
import Datalist from 'Components/Dataliste/Dataliste'
import AddRecipe from 'AppContainer/ERP/SpecificationsAndRecipe/Recipe/AddRecipe'
import { RecipeContext } from 'Context/ERP/RecipeContext'
import { ArticleContext } from 'Context/ERP/ArticleContext'
import MidLoader from 'Components/MidLoader/MidLoader'
import MiniLoader from "Components/MiniLoader/MiniLoader"
import SelectArticles from 'Components/_ERP/SelectArticles'


export default function
    ProductSpecificationFields({
        productSpecification,
        setProductSpecification
    }) {

    const { addPopupContent } = useContext(PopupContext)

    //TOOLS
    const { showPopup } = useContext(PopupContext)
    const { recipeNames } = useContext(RecipeContext)

    const { articles } = useContext(ArticleContext)

    const [finalProducts, setFinalProducts] = useState(null)

    useEffect(() => {
        if (articles) {
            setFinalProducts(articles.filter(article => article.category == "Produit fini"))
            setPrimaryPackagings(articles.filter(article => article.category == "Packaging primaire"))
        }
    }, [articles])


    const [primaryPackagings, setPrimaryPackagings] = useState([])
    const [secondaryPackagings, setSecondaryPackagings] = useState([])
    const [createdFinalProduct, setCreatedFinalProduct] = useState({})
    const [createdRecipe, setCreatedRecipe] = useState({})

    const [primaryPackagingValue, setPrimaryPackagingValue] = useState('')
    const [secondaryPackagingValue, setSecondaryPackagingValue] = useState('')

    const setArticleId = (value) => {
        setProductSpecification({ ...productSpecification, article_id: value })
    }
    const setRecipeId = (value) => {
        setProductSpecification({ ...productSpecification, recipe_id: value })
    }

    const addFinalProductInDatalist = (newProduct) => {
        setFinalProducts([newProduct, ...finalProducts])
        setCreatedFinalProduct(newProduct)
    }

    const showAddRecipe = () => {
        addPopupContent(<AddRecipe setter={createRecipe} />)
    }

    const createRecipe = (newRecipe) => {
        showPopup(false)
        setCreatedRecipe(newRecipe)
    }

    //------------------------------------------------------PACKAGINGS PRIMAIRES ------------------------------------

    // AJOUTER UN PACKAGING PRIMAIRE DANS LA DATALISTE
    const addPrimaryPackagingInDataList = (id) => {
        if (id) {
            const newProductSpecification = productSpecification
            newProductSpecification.conditioning.primary = [
                articles.find(packaging => packaging.id === id),
                ...newProductSpecification.conditioning.primary
            ]
            setProductSpecification({ ...newProductSpecification })
            setPrimaryPackagingValue('')
        }
    }

    // AJOUTER UN NOUVEAU PACKAGING PRIMAIRE DANS LE TABLEAU DES PACKAGINGS PRIMAIRES
    const addPackagingPrimaire = (newPrimaire) => {
        showPopup(false)
        setPrimaryPackagings(pp => [newPrimaire, ...pp])
        setPrimaryPackagingValue(newPrimaire.name)
    }

    // MODIFIER UN PACKAGING PRIMAIRE
    const updatePackagingPrimaire = (newArticle) => {
        const currentArticleUpdate = productSpecification.conditioning.primary.map(packaging => packaging.id !== newArticle.id ? packaging : newArticle)
        setProductSpecification({ ...productSpecification, conditioning: { ...productSpecification.conditioning, primary: currentArticleUpdate } })
        setPrimaryPackagings(primaryPackagings.map(packaging => packaging.id !== newArticle.id ? packaging : newArticle))
    }
    const deletePrimaire = (itemIndex) => {
        const currentPrimaryList = productSpecification.conditioning.primary.filter((listItem, index) => index !== itemIndex)
        setProductSpecification({ ...productSpecification, conditioning: { ...productSpecification.conditioning, primary: currentPrimaryList } })
    }

    //------------------------------------------PACKAGINGS SECONDAIRES -------------------------------------------------

    // APPEL PACKAGING SECONDAIRE
    useEffect(() => {
        const controller = new AbortController()
        if (finalProducts && finalProducts.length > 0) {
            if (productSpecification.article_id) {
                const currentArticle = finalProducts.find(article => article.id === productSpecification.article_id)

                currentArticle && setSecondaryPackagings(articles.filter(article => article.category == "Packaging secondaire" && article.brand_id == currentArticle.brand_id))
            }
        } else {
            <MidLoader />
        }
        return () => {
            controller.abort()
        }
    }, [productSpecification])

    const addSecondaryPackagingInDataList = (id) => {
        if (id) {
            const newProductSpecification = productSpecification
            newProductSpecification.conditioning.secondary = [
                secondaryPackagings.find(packaging => packaging.id === id),
                ...newProductSpecification.conditioning.secondary
            ]
            setProductSpecification({ ...newProductSpecification })
            setSecondaryPackagingValue('')
        }
    }
    const deleteSecondaire = (itemIndex) => {
        const currentSecondaryList = productSpecification.conditioning.secondary.filter((listItem, index) => index !== itemIndex)
        setProductSpecification({ ...productSpecification, conditioning: { ...productSpecification.conditioning, secondary: currentSecondaryList } })
    }

    // AJOUTER UN NOUVEAU PACKAGING SECONDAIRE DANS LE TABLEAU DES PACKAGINGS SECONDAIRES
    const addPackagingSecondaire = (newSecondaire) => {
        showPopup(false)
        if (newSecondaire.brand_id === secondaryPackagings[0].brand_id) {
            setSecondaryPackagings([newSecondaire, ...secondaryPackagings])
            setSecondaryPackagingValue(newSecondaire.name)
        }
    }

    // MODIFIER UN PACKAGING SECONDAIRE
    const updatePackagingSecondaire = (newArticle) => {
        console.log(newArticle);
        const newSecondaryPackagings = productSpecification.conditioning.secondary.map(packaging => packaging.id !== newArticle.id ? packaging : newArticle)
        setProductSpecification({ ...productSpecification, conditioning: { ...productSpecification.conditioning, secondary: newSecondaryPackagings } })
        setSecondaryPackagings(secondaryPackagings.map(packaging => packaging.id !== newArticle.id ? packaging : newArticle))
    }

    // RESET LES LISTES QUAND UN L'ARTICLE SELECTIONNÉ CHANGE
    const resetList = () => {
        setProductSpecification({
            ...productSpecification,
            conditioning: {
                primary: [],
                secondary: [],
            }
        })
    }

    return (
        <>
            {finalProducts
                ? <div className="flex-start center">
                    <Datalist
                        defaultId={productSpecification && productSpecification.article_id}
                        style={{ minWidth: "150px", maxWidth: "unset", width: '100%', marginBottom: "10px" }}
                        placeholder={'Sélectionnez un article'}
                        items={finalProducts.map(produit => ({ id: produit.id, value: produit.name }))}
                        onSelect={setArticleId}
                        onInput={resetList}
                        value={createdFinalProduct.name ? createdFinalProduct.name : ""}
                    />
                    <button
                        type='button'
                        className='light-button'
                        style={{ minWidth: "210px", height: "45px", margin: "18px 0 0 18px" }}
                        onClick={() => addPopupContent(<AddArticle setter={addFinalProductInDatalist} defaultCategory={'Produit fini'} />)}
                    >Créer un nouveau produit
                    </button>
                </div>
                : <MidLoader />
            }

            {productSpecification.article_id !== null && productSpecification.article_id !== '' ? (
                <>
                    <h3 style={{ marginTop: "40px" }}>Recette</h3>
                    <div className="flex-start center">
                        {recipeNames
                            ? <Datalist
                                defaultId={productSpecification.recipe_id}
                                style={{ minWidth: "150px", maxWidth: "unset", width: '100%', marginBottom: "10px" }}
                                placeholder={'Sélectionner une recette'}
                                items={[
                                    { id: null, value: 'Pas de recette' },
                                    ...recipeNames.map(recipe => (
                                        { id: recipe.id, value: recipe.name }
                                    ))]}
                                onSelect={setRecipeId}
                                value={createdRecipe.name ? createdRecipe.name : ""}
                            />
                            : <MiniLoader />}
                        <button
                            type='button'
                            className='light-button'
                            style={{ minWidth: "220px", height: "45px", margin: "18px 0 0 18px" }}
                            onClick={() => showAddRecipe()}
                        >Créer une nouvelle recette
                        </button>
                    </div>

                    {<>
                        <h3 style={{ marginTop: "40px" }} >Conditionnement</h3>

                        <h4 style={{ margin: "50px 0 0" }}>Primaire</h4>
                        <div className="flex-start center">
                            <Datalist
                                style={{ minWidth: "150px", maxWidth: "450px", width: '50%', marginBottom: "10px" }}
                                placeholder={'Sélectionnez un packaging primaire'}
                                items={primaryPackagings && primaryPackagings.map(packaging => ({ id: packaging.id, value: packaging.name }))}
                                onSelect={addPrimaryPackagingInDataList}
                                value={primaryPackagingValue}
                                onInput={setPrimaryPackagingValue}
                            />
                            <button
                                type='button'
                                className='light-button'
                                style={{ minWidth: "210px", height: "45px", margin: "18px 0 0 18px" }}
                                onClick={() => addPopupContent(<AddArticle setter={addPackagingPrimaire} defaultCategory={'Packaging primaire'} />)}
                            >Créer packaging primaire
                            </button>
                            <button
                                type='button'
                                className='light-button'
                                style={{ minWidth: "210px", height: "45px", margin: "18px 0 0 18px" }}
                                onClick={() => addPopupContent(<SelectArticles onSelect={addPrimaryPackagingInDataList} defaultCategory={'Packaging primaire'} />)}
                            >Chercher d'autres produits
                            </button>
                        </div>
                        {productSpecification.conditioning && productSpecification.conditioning.primary ?
                            productSpecification.conditioning.primary.map((packaging, index) => {
                                return (
                                    <div
                                        className='flex-center m-b-10'
                                        style={{ boxShadow: '0 2px 12px rgba(0,40,117,.06)', maxWidth: '1000px', width: 'unset', listStyleType: "none", margin: '6px 0', marginRight: "auto", marginLeft: "auto", padding: "16px", borderRadius: "5px", fontSize: "16px", backgroundColor: "white" }}
                                        key={index}
                                    >
                                        <div>{packaging.name}</div>
                                        <div className='flex-center' style={{ width: "150px" }}>
                                            <button
                                                type='button'
                                                className='light-button'
                                                onClick={() => addPopupContent(<ArticleUpdate article={packaging} setter={updatePackagingPrimaire} />)}
                                            >Modifier
                                            </button>
                                            <DeleteButton
                                                callBack={deletePrimaire}
                                                parameters={[index]}
                                                alwaysDisplay={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }) : null
                        }

                        <h4 style={{ margin: "50px 0 0" }}>Secondaire</h4>
                        <div className="flex-start center">
                            <Datalist
                                style={{ minWidth: "150px", maxWidth: "450px", width: '48%', marginBottom: "10px" }}
                                placeholder={'Sélectionnez un packaging secondaire'}
                                items={secondaryPackagings && secondaryPackagings.map(packaging => ({ id: packaging.id, value: packaging.name }))}
                                onSelect={addSecondaryPackagingInDataList}
                                onInput={setSecondaryPackagingValue}
                                value={secondaryPackagingValue}
                            />
                            <button
                                type='button'
                                className='light-button'
                                style={{ minWidth: "210px", height: "45px", margin: "18px 0 0 18px" }}
                                onClick={() => addPopupContent(<AddArticle setter={addPackagingSecondaire} defaultCategory={'Packaging secondaire'} />)}
                            >Créer packaging secondaire
                            </button>
                        </div>

                        {productSpecification.conditioning && productSpecification.conditioning.secondary ?
                            productSpecification.conditioning.secondary.map((packaging, index) => {
                                return (
                                    <div
                                        className='flex-center m-b-10'
                                        style={{ boxShadow: '0 2px 12px rgba(0,40,117,.06)', maxWidth: '1000px', width: 'unset', listStyleType: "none", margin: '6px', marginRight: "auto", marginLeft: "auto", padding: "16px", borderRadius: "5px", fontSize: "16px", backgroundColor: "white" }}
                                        key={index}
                                    >
                                        <div>{packaging.name}</div>
                                        <div className="flex-center" style={{ width: "150px" }}>
                                            <button
                                                type='button'
                                                className='light-button'
                                                onClick={() => addPopupContent(<ArticleUpdate article={packaging} setter={updatePackagingSecondaire} />)}
                                            >Modifier
                                            </button>
                                            <DeleteButton
                                                callBack={deleteSecondaire}
                                                parameters={[index]}
                                                alwaysDisplay={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }) : null
                        }
                    </>}
                </>
            ) : null}
        </>
    )
}
