import React, { useState, useContext, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import set from 'lodash/set.js';

import './productFields.css'
import SelectPriceCategory from '../PriceCategory/SelectPriceCategory';
import AttributeOption from './Attributes/Option/AttributeOption';
import AddOption from './Attributes/Option/AddOption';
import { downElement, upElement } from 'Functions';
import { PopupContext } from 'Context/PopupContext';
import UpdateOption from './Attributes/Option/UpdateOption';
import AddVariation from './Attributes/Variation/AddVariation';
import ValidePackagingParameters from './Packagings/validePackagingParameters';
import AddPackagingParameters from './Packagings/AddPackagingParameters';
import TextEditor from 'Components/GENERAL/Form/TextEditor/TextEditor';

export default function ProductFields({ product, setProduct }) {

    const { addPopupContent2, showPopup2 } = useContext(PopupContext)

    const [productPictureSRC, setProductPictureSRC] = useState(product.product_picture ? product.product_picture : `/images/products/fr-default-large_default.jpg`)
    const [carrouselPictures, setCarrouselPictures] = useState(product.carrousel)


    const addNewOption = (option) => {
        setProduct(product => {
            return {
                ...product,
                options: [...product.options, option]
            }
        })
    }

    const reOrderOptions = (options) => {
        setProduct(product => {
            return {
                ...product,
                options: options
            }
        })
    }

    const setVariation = (variation) => {
        setProduct(product => {
            return {
                ...product,
                variation: variation
            }
        })
    }

    /**
     * 
     * @param {string} keyPath string of the keypath to achieve the value (ex: key.subkey.color)
     * @param {*} value value to set
     */
    const updateProductPropriety = (keyPath, value) => {
        setProduct(prevProduct => {
            const updatedProduct = { ...prevProduct };
            set(updatedProduct, keyPath, value);
            return updatedProduct;
        });
    };

    const loadProductPicture = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setProductPictureSRC(e.target.result);
            updateProductPropriety('product_picture', event.target.files[0]);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const loadCarrouselPicture = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setCarrouselPictures(pictures => [...pictures, e.target.result]);
            updateProductPropriety('carrousel', [...product.carrousel, event.target.files[0]]);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const deleteCarrouselPicture = index => {
        setCarrouselPictures(pictures => pictures.filter((_, idx) => idx !== index));
        updateProductPropriety('carrousel', product.carrousel.filter((_, idx) => idx !== index));
    }

    const deleteLabel = index => {
        updateProductPropriety('labels', product.labels.filter((_, idx) => idx !== index))
    }
    const updateLabel = (index, value) => {
        setProduct(product => {
            return {
                ...product,
                labels: product.labels.map((lab, idx) =>
                    idx === index
                        ? value
                        : lab
                )
            }
        })

    }
    const addNewLabel = () => {
        setProduct(product => {
            return {
                ...product,
                labels: [...product.labels, ""]
            }
        })
    }

    const deleteOption = (index, name = "") => {
        window.confirm(`Êtes vous sur de vouloir supprimer l'option ${name} ?`)
            && setProduct(product => {
                return {
                    ...product,
                    options: product.options.filter((_, idx) => idx !== index)
                }
            })
    }

    const updateOption = (index, option) => {
        setProduct(product => {
            return {
                ...product,
                options: product.options.map((o, idx) =>
                    idx === index
                        ? option
                        : o
                )
            }
        })
        showPopup2(false)
    }

    const showUpdateOption = (index, option) => {
        addPopupContent2(<UpdateOption optionIndex={index} parentOption={option} updateParentOption={updateOption} />)
    }

    /* PACKAGING */
    const addNewPackaging = (packaging) => {
        setProduct(product => {
            return {
                ...product,
                packagings: [...product.packagings, packaging]
            }
        })
    }
    const updatePackagingProperty = (index, key, value) => {
        setProduct(product => {
            const newPackagings = [...product.packagings]
            newPackagings[index] = { ...newPackagings[index], [key]: value }
            return {
                ...product,
                packagings: newPackagings
            }
        })
    }

    const deletePackaging = (index) => {
        window.confirm(`Êtes vous sur de vouloir supprimer ce packaging ?`)
            && setProduct(product => {
                return {
                    ...product,
                    packagings: product.packagings.filter((_, idx) => idx !== index)
                }
            })
    }

    const initEditor = {
        height: 200,
    }

    return (
        <div className="product-fields">
            <input required className='full-width-input m-b-25' placeholder='Nom du produit...' value={product.name} onChange={e => updateProductPropriety('name', e.target.value)} />
            {product.catalogue
                ? < div className='m-b-25'>
                    <label className='flex-center flex-start'>
                        <input className="erp-check" type="checkbox" checked={product.public ? true : false} onChange={e => updateProductPropriety('public', e.target.checked ? 1 : 0)} />
                        Afficher ce produit sur le catalogue du site labofrancaisduchanvre.com
                    </label>
                    {product.public
                        ? <div className='flex gap-1'>
                            <input required className='full-width-input' placeholder='Nom commercial du produit...' value={product.commercial_name} onChange={e => updateProductPropriety('commercial_name', e.target.value)} />
                            <input className='full-width-input' placeholder='Description en une phrase du produit...' value={product.short_description} onChange={e => updateProductPropriety('short_description', e.target.value)} />
                        </div>
                        : null
                    }
                </div>
                : null}

            <div className='flex-start m-b-25'>
                <div className='half-m-width'>
                    <div id="popup-product-picture">
                        {productPictureSRC ? (
                            <img style={{ borderRadius: "8px", maxWidth: "300px" }} src={productPictureSRC} />
                        ) : null}
                        <label className="change-picture-button">
                            <input type="file" accept=".jpg,.jpeg,.png,.webp"
                                // value={product.product_picture}
                                onChange={(e) => { loadProductPicture(e); }}
                            />
                            Changer l'image
                        </label>
                    </div>
                    <h4 className='m-b-10'>Images supplémentaires</h4>
                    <div className='flex-start wrap gap-1 m-b-10'>
                        {Array.isArray(carrouselPictures) && carrouselPictures.map((picutre, index) =>
                            <div key={index} className='carrousel-picture'>
                                <div onClick={() => deleteCarrouselPicture(index)} className='delete-picture'>×</div>
                                <img style={{ borderRadius: "8px", maxWidth: "200px" }} src={picutre} />
                            </div>
                        )}
                    </div>
                    <label className="change-picture-button">
                        <input type="file" accept=".jpg,.jpeg,.png,.webp"
                            // value={product.product_picture}
                            onChange={(e) => { loadCarrouselPicture(e); }}
                        />
                        Ajouter une image
                    </label>
                </div>
                <div className='half-m-width'>
                    <div className='m-b-10'>
                        <label>Catégorie du produit</label>
                        <select value={product.category} required onChange={e => updateProductPropriety('category', e.target.value)}>
                            <option value="">-- catégorie --</option>
                            <option value='Huiles sublinguales'>Huiles sublinguales</option>
                            <option value='Cosmétiques'>Cosmétiques</option>
                            <option value='Alimentaires'>Alimentaires</option>
                            <option value='Animaliers'>Produits pour animaux</option>
                            <option value='Matières premières'>Matières premières</option>
                            <option value='Offres réglementaires'>Offres réglementaires</option>
                            <option value='Champignons fonctionnels'>Champignons fonctionnels</option>
                        </select>
                    </div>
                    <div className='flex input m-b-10'>
                        <label>TVA</label>
                        <select value={product.vat_rate} required onChange={e => updateProductPropriety('vat_rate', parseInt(e.target.value))}>
                            <option value=''>-- TVA --</option>
                            <option value='1055'>5.5%</option>
                            <option value='1200'>20%</option>
                        </select>
                    </div>
                    <div className='flex input m-b-10'>
                        <label>Type du produit</label>
                        <select value={product.type} onChange={e => updateProductPropriety('type', e.target.value)}>
                            <option value={2}>Variable</option>
                            <option value={1}>Simple</option>
                        </select>
                    </div>
                    {product.type == 1 && (
                        <>
                            <div className='flex input m-b-10'>
                                <label>Fonction du prix</label>
                                <SelectPriceCategory simplePrice={true} defaultValue={product.price.price_category_id} setter={updateProductPropriety} customParams={["price.price_category_id"]} />
                            </div>
                            {product.price.price_category_id &&
                                product.price.price_category_id > 0
                                ? <>
                                    <div className='flex input'>
                                        <label>Prix maximum</label>
                                        <input required type='number' placeholder='20' step='0.001' value={product.price.max_price} onChange={e => updateProductPropriety("price.max_price", e.target.value)} />
                                    </div>
                                    <div className='flex input'>
                                        <label>Prix minimum</label>
                                        <input type='number' placeholder='12' step='0.001' value={product.price.min_price} onChange={e => updateProductPropriety("price.min_price", e.target.value)} />
                                    </div>
                                    <div className='flex input'>
                                        <label>Quantité minimum</label>
                                        <input type='number' placeholder='100' value={product.price.min_qty} onChange={e => updateProductPropriety("price.min_qty", e.target.value)} />
                                    </div>
                                    <div className='flex input'>
                                        <label>Quantité pour le prix minimum</label>
                                        <input type='number' placeholder='3000' value={product.price.min_price_qty} onChange={e => updateProductPropriety("price.min_price_qty", e.target.value)} />
                                    </div>
                                </>
                                : <div>
                                    <div className='flex input'>
                                        <label>Prix fix</label>
                                        <input type='number' placeholder='20' value={product.price.max_price} onChange={e => updateProductPropriety("price.max_price", e.target.value)} />
                                    </div>
                                    <div className='flex input'>
                                        <label>Description du prix (optionnel):</label>
                                        <input placeholder='Prix pour un lot de 5' value={product.price_description} onChange={e => updateProductPropriety("price_description", e.target.value)} />
                                    </div>
                                    <div className='flex input'>
                                        <label>Lien vers une page de paiement externe (optionnel):</label>
                                        <input placeholder='https://llfc1.gumroad.com/l/hotline-reglementaire' value={product.purchase_link} onChange={e => updateProductPropriety("purchase_link", e.target.value)} />
                                    </div>
                                </div>
                            }

                        </>
                    )}
                </div>
            </div>
            <div className='m-b-25'>
                <h4 className='m-b-10'>Labels</h4>
                {product.labels && product.labels.length > 0
                    ? <div className='flex-start flex-center gap-1'>
                        {product.labels.map((label, index) =>
                            <div key={index} className='flex-center'>
                                <input type='text' placeholder='label' value={label} onChange={e => updateLabel(index, e.target.value)} />
                                <div
                                    className='pointer delete-button'
                                    onClick={() => deleteLabel(index)}
                                    style={{ position: 'relative', left: '-20px' }}
                                >✕</div>
                            </div>
                        )}
                        <button type='button' className='light-button' onClick={() => addNewLabel()}>ajouter un label</button>
                    </div>
                    : <div className='column gap-1'>
                        <div className='flex-start flex-center gap-1'>
                            <h5 className='m-t-0 m-b-0'>Aucun label</h5>
                            <button type='button' style={{ maxWidth: '150px' }} className='light-button' onClick={() => addNewLabel()}>ajouter un label</button>
                        </div>
                    </div>
                }
            </div>

            <div className='flex m-b-25'>
                <div className='column half-m-width gap-2'>
                    <div>
                        <div style={{ opacity: product.use_ref.description ? 0.6 : 1 }}>
                            <div className='flex'>
                                <label className='left'>Description du produit</label>
                                {!product.catalogue && (
                                    <div>
                                        <label>
                                            <input type="checkbox"
                                                checked={product.use_ref.description ? true : false}
                                                onChange={() => updateProductPropriety('use_ref.description', product.use_ref.description ? 0 : 1)}
                                            />
                                            <span> Conserver celle du parent (auto update)</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <TextEditor
                                value={product.tabs.description}
                                setter={updateProductPropriety}
                                customParameters={['tabs.description']}
                            />
                            {/* <Editor
                                value={product.tabs.description}
                                onEditorChange={(newText) => updateProductPropriety('tabs.description', newText)}
                                init={initEditor}
                            /> */}
                        </div>
                    </div>
                    <div>
                        <div style={{ opacity: product.use_ref.personnalisations ? 0.6 : 1 }}>
                            <div className='flex'>
                                <label className='left'>Personnalisations</label>
                                {!product.catalogue && (
                                    <div>
                                        <label>
                                            <input type="checkbox"
                                                checked={product.use_ref.personnalisations ? true : false}
                                                onChange={() => updateProductPropriety('use_ref.personnalisations', product.use_ref.personnalisations ? 0 : 1)}
                                            />
                                            <span> Concerver celle du parent (auto update)</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <TextEditor
                                value={product.tabs.personnalisations}
                                setter={updateProductPropriety}
                                customParameters={['tabs.personnalisations']}
                            />
                            {/* <Editor
                                value={product.tabs.personnalisations}
                                onEditorChange={(newText) => updateProductPropriety('tabs.personnalisations', newText)}
                                init={initEditor}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className='column half-m-width gap-2'>
                    <div style={{ opacity: product.use_ref.ingredients ? 0.6 : 1 }}>
                        <div className='flex'>
                            <label className='left'>Ingrédients</label>
                            {!product.catalogue && (
                                <div>
                                    <label>
                                        <input type="checkbox"
                                            checked={product.use_ref.ingredients ? true : false}
                                            onChange={() => updateProductPropriety('use_ref.ingredients', product.use_ref.ingredients ? 0 : 1)}
                                        />
                                        <span> Concerver celle du parent (auto update)</span>
                                    </label>
                                </div>
                            )}
                        </div>
                        <TextEditor
                            value={product.tabs.ingredients}
                            setter={updateProductPropriety}
                            customParameters={['tabs.ingredients']}
                        />
                        {/* <Editor
                            value={product.tabs.ingredients}
                            onEditorChange={(newText) => updateProductPropriety('tabs.ingredients', newText)}
                            init={initEditor}
                        /> */}
                    </div>
                    <div style={{ opacity: product.use_ref.utilisation ? 0.6 : 1 }}>
                        <div className='flex'>
                            <label className='left'>Utilisation</label>
                            {!product.catalogue && (
                                <div>
                                    <label>
                                        <input type="checkbox"
                                            checked={product.use_ref.utilisation ? true : false}
                                            onChange={() => updateProductPropriety('use_ref.utilisation', product.use_ref.utilisation ? 0 : 1)}
                                        />
                                        <span> Concerver celle du parent (auto update)</span>
                                    </label>
                                </div>
                            )}
                        </div>
                        <TextEditor
                            value={product.tabs.utilisation}
                            setter={updateProductPropriety}
                            customParameters={['tabs.utilisation']}
                        />
                        {/* <Editor
                            value={product.tabs.utilisation}
                            onEditorChange={(newText) => updateProductPropriety('tabs.utilisation', newText)}
                            init={initEditor}
                        /> */}
                    </div>
                </div>
            </div>
            <h3 className='center m-b-25'>Attributs (options{product.type == 2 ? <> & variations</> : null})</h3>
            <div className='flex m-b-25'>
                {/* className={product.type === 2 ? "half-m-width" : "full-width"} */}
                <div className="half-m-width">
                    <h4 className='m-b-10'>Options</h4>
                    {product.options &&
                        product.options.length > 0
                        ? (
                            <div style={{ opacity: product.use_ref.option ? 0.6 : 1 }}>
                                {!product.catalogue && (
                                    <div className='m-b-10'>
                                        <label>
                                            <input type="checkbox"
                                                checked={product.use_ref.option ? true : false}
                                                onChange={() => updateProductPropriety('use_ref.option', product.use_ref.option ? 0 : 1)}
                                            />
                                            <span> Copier toutes les options du parent (auto update)</span>
                                        </label>
                                    </div>
                                )}
                                {product.options.map((option, index) => {
                                    const notEditable = product.use_ref.option || option.use_reference
                                    return (
                                        <div key={index} className='variation-value'>
                                            {!product.catalogue && (
                                                <div className='m-b-10'>
                                                    <label>
                                                        <input type="checkbox"
                                                            disabled={product.use_ref.option}
                                                            checked={notEditable ? true : false}
                                                            onChange={() => updateOption(index, {
                                                                ...option,
                                                                use_reference: option.use_reference ? 0 : 1
                                                            })}
                                                        />
                                                        <span> Conserver les valeurs défaut (auto update)</span>
                                                    </label>
                                                </div>
                                            )}
                                            <div className='flex-center  gap-1 m-b-10' style={{ opacity: notEditable ? 0.6 : 1 }}>
                                                <div className='flex column' style={{ marginRight: "5px" }}>
                                                    <div onClick={() => !notEditable && upElement(product.options, index, reOrderOptions)} className='m-b-10 pointer'>▲</div>
                                                    <div onClick={() => !notEditable && downElement(product.options, index, reOrderOptions)} className='pointer'>▼</div>
                                                </div>
                                                <div className='full-width flex gap-1'>
                                                    <div className='full-width'>
                                                        <AttributeOption option={option} catalogue={product.catalogue} />
                                                    </div>
                                                    <div className='flex column flex-center-h'>
                                                        <button type="button" onClick={() => !notEditable && deleteOption(index, option.name)} className='m-b-10'>✕</button>
                                                        <button type="button" onClick={() => !notEditable && showUpdateOption(index, option)} className="">&#128393;</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : <h5 className='m-t-0 m-b-0'>Aucune option</h5>}
                    {product.use_ref.option
                        ? null
                        : <AddOption addOption={addNewOption} />
                    }
                </div>
                {product.type == 2 &&
                    <div className="half-m-width">
                        <h4>Variations</h4>
                        <AddVariation catalogue={product.catalogue} productVariation={product.variation} setProductVariation={setVariation} />
                    </div>
                }
            </div>
            <h4 className='center m-b-25'>Packagings</h4>
            {!product.catalogue && (
                <div className='m-b-10'>
                    <label>
                        <input type="checkbox"
                            checked={product.use_ref.packaging ? true : false}
                            onChange={() => updateProductPropriety('use_ref.packaging', product.use_ref.packaging ? 0 : 1)}
                        />
                        <span> Copier tous les packaging du parent (auto update)</span>
                    </label>
                </div>
            )}
            <div className='valide-packaging-container m-b-25' style={{ opacity: (!product.catalogue && product.use_ref.packaging) ? 0.6 : 1 }}>
                {Array.isArray(product.packagings) && product.packagings.map((packaging, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ValidePackagingParameters catalogue={product.catalogue ? true : false} updateCurrentPackaging={updatePackagingProperty} index={index} packaging={packaging} deleteCurrentPackaging={deletePackaging} />
                        </React.Fragment>
                    )
                })}
            </div>
            {product.use_ref.packaging
                ? null
                : <button onClick={() => addPopupContent2(<AddPackagingParameters addNewPackaging={addNewPackaging} />, '700px')} type='button' className='light-button center-block m-b-25'>Ajouter un packaging</button>
            }

        </div >
    )
}
